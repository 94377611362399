import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddPeople = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={16}
      fill="none"
      viewBox="0 0 24 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22 5V3h-2v2h-2v2h2v2h2V7h2V5zM8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4M8 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4M12.51.05A6.02 6.02 0 0 1 14 4c0 1.51-.57 2.89-1.49 3.95C14.47 7.7 16 6.04 16 4S14.47.3 12.51.05M16.53 9.83C17.42 10.66 18 11.7 18 13v3h2v-3c0-1.45-1.59-2.51-3.47-3.17"
      />
    </svg>
  );
};
export default SvgAddPeople;
