import React, { CSSProperties, ReactNode } from 'react';
import cx from 'classnames';
import { Icon } from '@components/UI/Icon';
import './Tag.scss';

export type TagTypes =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'danger-light'
  | 'info'
  | 'secondary'
  | 'white'
  | 'tertiary'
  | 'dashed'
  | 'violet'
  | 'amber';

export type TagProps = {
  type?: TagTypes;
  size?: 'mini' | 'small' | 'medium' | 'big';
  full?: boolean;
  outline?: boolean;
  closable?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
};

export default function Tag({
  children,
  type = 'primary',
  size = 'small',
  closable,
  onClose,
  className,
  outline,
  full,
  ...rest
}: TagProps) {
  return (
    <span
      className={cx(['rm-tag', className, type, size], {
        closable,
        outline,
        full,
      })}
      {...rest}
      data-testid={type}
    >
      {children || type}
      {closable && (
        <span className={cx(['icon'])} onClick={onClose}>
          <Icon name="Close" size="mini" />
        </span>
      )}
    </span>
  );
}
