import React from 'react';
import { Icon } from '@components/UI/Icon';
import { Link } from 'react-router-dom';
import Logo from '@assets/img/logo/Logo.svg';
import { useService } from '@stores/hooks';
import './TopbarLeft.scss';

export default function TopbarLeft() {
  const { toggleSidebar } = useService('LayoutService');

  return (
    <div className="topbar-left">
      <div onClick={toggleSidebar} className="burger p-2 ml-1 mr-2 rounded-1">
        <Icon name="Menu" className="text-white cursor-pointer" />
      </div>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
    </div>
  );
}
