import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPicSecurity = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={240}
      height={240}
      fill="none"
      role="img"
      {...props}
    >
      <path
        fill="#3B82F6"
        d="M189.36 77.76s1.8-24.269-23.371-40.45c-25.171-16.18-61.128-13.036-85.402-.45C56.314 49.444 59.914 78.191 51.84 96.62c-8.074 18.426-26.52 21.599-25.171 46.746s17.083 37.306 35.04 41.352c17.957 4.047 34.613-.897 50.793 1.796 16.181 2.692 38.655 8.092 64.724-2.247 26.068-10.339 31.012-38.654 27.417-61.128S189.36 92.578 189.36 77.76"
      />
      <path
        fill="#fff"
        d="M189.36 77.76s1.8-24.269-23.371-40.45c-25.171-16.18-61.128-13.036-85.402-.45C56.314 49.444 59.914 78.191 51.84 96.62c-8.074 18.426-26.52 21.599-25.171 46.746s17.083 37.306 35.04 41.352c17.957 4.047 34.613-.897 50.793 1.796 16.181 2.692 38.655 8.092 64.724-2.247 26.068-10.339 31.012-38.654 27.417-61.128S189.36 92.578 189.36 77.76"
        opacity={0.7}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M165.168 173.338H72.634l7.305-7.052h77.924z"
      />
      <path
        fill="#3B82F6"
        d="M154.887 169.008c0 1.44-16.757 2.626-37.44 2.626s-37.44-1.176-37.44-2.626 16.757-2.63 37.44-2.63 37.44 1.176 37.44 2.63"
      />
      <path
        fill="#fff"
        d="M154.887 169.008c0 1.44-16.757 2.626-37.44 2.626s-37.44-1.176-37.44-2.626 16.757-2.63 37.44-2.63 37.44 1.176 37.44 2.63"
        opacity={0.5}
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M165.168 173.338H72.634v6.532h92.534z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M171.716 186.917H66.086l6.548-7.047h92.535z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M171.696 186.917H66.106v6.873h105.59z"
      />
      <path
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M117.879 103.646V59.712h16.718V41.227m-10.186 18.279V41.227m-5.284 69.423H196.1V99.706h14.712m-95.459 10.564H42.158V98.578H26.313m76.59-69.048v16.603h-9.432v10.56h11.697V92.16m49.426-13.584v-23.77h6.413V44.621h5.284M76.873 76.315v-18.11l-5.285-5.285V39.715m97.344 47.165h13.204v-8.678h8.679v-4.906h7.545M63.663 89.9H49.705V78.954H30.087m46.406 70.177v9.811H70.08v10.944H51.59m95.458-19.243v10.186h14.712v-4.147h4.152v8.673h12.83"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M135.672 41.227a1.074 1.074 0 0 1-2.13.203 1.07 1.07 0 0 1 .458-1.102 1.075 1.075 0 0 1 1.672.9Zm-10.147 0a1.073 1.073 0 0 1-1.287 1.05 1.075 1.075 0 1 1 1.287-1.05m-21.538-12.009a1.079 1.079 0 0 1-1.845.762 1.079 1.079 0 0 1 .77-1.842 1.077 1.077 0 0 1 1.075 1.08m-31.315 9.955a1.075 1.075 0 1 1-2.15-.01 1.075 1.075 0 0 1 2.15.01m-41.51 39.782a1.08 1.08 0 1 1-2.16.01 1.08 1.08 0 0 1 2.16-.01m-3.739 19.589a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0m25.339 71.136a1.08 1.08 0 0 1-.668.998 1.077 1.077 0 0 1-1.472-.79 1.083 1.083 0 0 1 1.477-1.206 1.08 1.08 0 0 1 .663.998m127.603-4.536a1.07 1.07 0 0 1-.18.601 1.08 1.08 0 1 1-.9-1.676 1.08 1.08 0 0 1 .763.313 1.08 1.08 0 0 1 .317.762m32.069-65.65a1.07 1.07 0 0 1-.664.994 1.08 1.08 0 0 1-1.172-.233 1.077 1.077 0 0 1 .163-1.655 1.076 1.076 0 0 1 1.673.894m-12.49-26.428a1.076 1.076 0 1 1-2.152 0 1.076 1.076 0 0 1 2.152 0m-31.882-28.474a1.076 1.076 0 0 1-1.835.76 1.073 1.073 0 0 1 .163-1.654 1.076 1.076 0 0 1 1.672.894"
      />
      <path
        fill="#fff"
        d="M131.962 158.64v-9.36h-28.167v9.36a13.38 13.38 0 0 1-2.995 8.4l-1.234 1.526h36.615l-1.229-1.526a13.36 13.36 0 0 1-2.99-8.4"
      />
      <path
        fill="#3B82F6"
        d="M102.067 165.197h31.623a13.3 13.3 0 0 1-1.728-6.557v-9.36h-28.167v9.36a13.3 13.3 0 0 1-1.728 6.557"
      />
      <path
        fill="#fff"
        d="M102.067 165.197h31.623a13.3 13.3 0 0 1-1.728-6.557v-9.36h-28.167v9.36a13.3 13.3 0 0 1-1.728 6.557"
        opacity={0.3}
      />
      <path
        fill="#1E293B"
        d="M102.067 155.328h31.623a13.3 13.3 0 0 1-1.728-6.557v-9.36h-28.167v9.36a13.3 13.3 0 0 1-1.728 6.557"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M131.962 158.64v-9.36h-28.167v9.36a13.38 13.38 0 0 1-2.995 8.4l-1.234 1.526h36.615l-1.229-1.526a13.36 13.36 0 0 1-2.99-8.4"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M170.405 69.115H65.353a4.07 4.07 0 0 0-4.07 4.07v74.972a4.07 4.07 0 0 0 4.07 4.07h105.052a4.07 4.07 0 0 0 4.071-4.07V73.186a4.07 4.07 0 0 0-4.071-4.07Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M61.282 139.31h113.194v8.847a4.07 4.07 0 0 1-4.071 4.07H65.353a4.07 4.07 0 0 1-4.07-4.07zm107.496-65.02H66.975v58.718h101.803z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M119.967 147.84a2.095 2.095 0 0 0-1.259-1.96 2.092 2.092 0 0 0-2.874 1.493 2.09 2.09 0 0 0 .88 2.157c.344.231.75.355 1.165.355a2.07 2.07 0 0 0 2.088-2.045"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M136.19 168.581H99.571v1.67h36.619z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.032 71.242c-5.117 4.8-18.24 9.6-33.509 19.819 0 0 .96 34.56 33.509 53.429z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.032 71.242c5.122 4.8 18.24 9.6 33.514 19.819 0 0-.96 34.56-33.514 53.429z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.033 74.184c-6.192 4.291-16.892 8.376-31.052 17.851 0 0 .888 32.016 31.052 49.517 30.168-17.501 31.056-49.517 31.056-49.517-14.16-9.475-24.826-13.56-31.056-17.851"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M149.088 92.035c-14.16-9.47-24.825-13.56-31.056-17.851v67.368c30.168-17.501 31.056-49.517 31.056-49.517"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M174.826 108.984H60.932v7.373h113.894z"
      />
      <path
        fill="#fff"
        d="M63.173 110.155a.761.761 0 0 1-1.298.559.763.763 0 0 1 .825-1.253.75.75 0 0 1 .413.405.7.7 0 0 1 .06.289"
      />
      <path
        fill="#1E293B"
        d="M61.973 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M66.169 110.155a.769.769 0 1 1-.768-.749.75.75 0 0 1 .707.46c.038.091.06.19.06.289Z"
      />
      <path
        fill="#1E293B"
        d="M64.964 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.6.6 0 0 0-.153-.389 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M69.16 110.155a.768.768 0 0 1-1.532 0 .768.768 0 0 1 1.531 0Z"
      />
      <path
        fill="#1E293B"
        d="M67.954 110.568a.614.614 0 0 0 1.003-.182.57.57 0 0 0-.106-.62 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M72.15 110.155a.76.76 0 0 1-.904.765.76.76 0 0 1-.607-.596.763.763 0 0 1 1.037-.863.75.75 0 0 1 .414.405q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M70.944 110.568a.617.617 0 0 0 .945-.075.6.6 0 0 0 .111-.338.6.6 0 0 0-.153-.389q-.479.371-.903.802"
      />
      <path
        fill="#fff"
        d="M75.14 110.155a.762.762 0 1 1-.764-.749.76.76 0 0 1 .704.46q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M73.92 110.568a.62.62 0 0 0 .442.187.6.6 0 0 0 .566-.368.6.6 0 0 0 .048-.232.6.6 0 0 0-.154-.389 10 10 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M78.13 110.155a.762.762 0 1 1-.763-.749.76.76 0 0 1 .703.46.7.7 0 0 1 .06.289"
      />
      <path
        fill="#1E293B"
        d="M76.93 110.568a.61.61 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M81.12 110.155a.768.768 0 1 1-.767-.749.75.75 0 0 1 .707.46c.039.091.06.19.06.289"
      />
      <path
        fill="#1E293B"
        d="M79.92 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M84.115 110.155a.761.761 0 0 1-1.297.559.763.763 0 0 1 .824-1.253.75.75 0 0 1 .413.405q.06.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M82.911 110.568a.614.614 0 0 0 1.004-.182.57.57 0 0 0-.106-.62 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M87.106 110.155a.761.761 0 0 1-1.297.559.763.763 0 0 1 .824-1.253.75.75 0 0 1 .414.405q.058.14.06.289Z"
      />
      <path
        fill="#1E293B"
        d="M85.92 110.568a.617.617 0 0 0 .945-.075.6.6 0 0 0 .11-.338.6.6 0 0 0-.153-.389 10 10 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M90.097 110.155a.762.762 0 1 1-.764-.749.76.76 0 0 1 .704.46q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M88.892 110.568a.62.62 0 0 0 .675.144.6.6 0 0 0 .332-.325.6.6 0 0 0 .049-.232.6.6 0 0 0-.154-.389 10 10 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M93.087 110.155a.76.76 0 0 1-.461.716.76.76 0 0 1-1.05-.547.763.763 0 0 1 1.038-.863.75.75 0 0 1 .413.405.7.7 0 0 1 .06.289"
      />
      <path
        fill="#1E293B"
        d="M91.887 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M96.082 110.155a.77.77 0 0 1-.908.775.77.77 0 0 1-.57-1.048.77.77 0 0 1 .715-.476.76.76 0 0 1 .703.46q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M94.877 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.57.57 0 0 0-.153-.389 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M99.072 110.155a.762.762 0 1 1-.763-.749.76.76 0 0 1 .704.46q.057.14.06.289Z"
      />
      <path
        fill="#1E293B"
        d="M97.867 110.568a.616.616 0 0 0 1.056-.413.6.6 0 0 0-.153-.389q-.479.371-.903.802"
      />
      <path
        fill="#fff"
        d="M102.062 110.155a.76.76 0 0 1-1.297.559.77.77 0 0 1-.172-.833.765.765 0 0 1 .996-.42.74.74 0 0 1 .414.405.7.7 0 0 1 .059.289"
      />
      <path
        fill="#1E293B"
        d="M100.857 110.568a.63.63 0 0 0 .442.187.6.6 0 0 0 .432-.172.6.6 0 0 0 .182-.428.59.59 0 0 0-.153-.389 10 10 0 0 0-.903.802"
      />
      <path
        fill="#fff"
        d="M63.173 114.912a.76.76 0 0 1-.9.77.76.76 0 0 1-.57-1.038.76.76 0 0 1 .706-.476.754.754 0 0 1 .764.744"
      />
      <path
        fill="#1E293B"
        d="M61.973 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M66.168 114.912a.766.766 0 0 1-.902.78.77.77 0 0 1-.617-.597.768.768 0 0 1 .751-.927.757.757 0 0 1 .768.744"
      />
      <path
        fill="#1E293B"
        d="M64.964 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M69.159 114.912a.77.77 0 0 1-.459.727.768.768 0 1 1-.305-1.471.753.753 0 0 1 .764.744"
      />
      <path
        fill="#1E293B"
        d="M67.954 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M72.15 114.912a.762.762 0 1 1-.764-.744.753.753 0 0 1 .763.744Z"
      />
      <path
        fill="#1E293B"
        d="M70.944 115.33a.62.62 0 0 0 .668.144.62.62 0 0 0 .388-.562.6.6 0 0 0-.153-.384q-.478.37-.903.802"
      />
      <path
        fill="#fff"
        d="M75.14 114.912a.76.76 0 0 1-.9.77.76.76 0 0 1-.57-1.038.76.76 0 0 1 .706-.476.754.754 0 0 1 .763.744Z"
      />
      <path
        fill="#1E293B"
        d="M73.92 115.33a.617.617 0 0 0 1.008-.185.6.6 0 0 0 .048-.233.6.6 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M78.13 114.912a.762.762 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M76.93 115.33a.6.6 0 0 0 .436.187.62.62 0 0 0 .433-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M81.12 114.912a.767.767 0 0 1-1.518.183.768.768 0 0 1 .75-.927.757.757 0 0 1 .768.744"
      />
      <path
        fill="#1E293B"
        d="M79.92 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M84.115 114.912a.762.762 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M82.911 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M87.106 114.912a.76.76 0 0 1-.9.77.76.76 0 0 1-.57-1.038.76.76 0 0 1 .707-.476.754.754 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M85.92 115.33a.62.62 0 0 0 .668.144.62.62 0 0 0 .388-.562.6.6 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M90.097 114.912a.762.762 0 1 1-.764-.744.753.753 0 0 1 .764.744"
      />
      <path
        fill="#1E293B"
        d="M88.892 115.33a.617.617 0 0 0 1.007-.185.6.6 0 0 0 .049-.233.6.6 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M93.087 114.912a.762.762 0 1 1-.764-.744.753.753 0 0 1 .764.744"
      />
      <path
        fill="#1E293B"
        d="M91.887 115.33a.6.6 0 0 0 .436.187.62.62 0 0 0 .433-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M96.082 114.912a.77.77 0 0 1-.459.727.767.767 0 1 1-.305-1.471.753.753 0 0 1 .764.744"
      />
      <path
        fill="#1E293B"
        d="M94.877 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M99.072 114.912a.762.762 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M97.867 115.33a.62.62 0 0 0 .668.144.62.62 0 0 0 .388-.562.6.6 0 0 0-.153-.384q-.478.37-.903.802"
      />
      <path
        fill="#fff"
        d="M102.062 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M100.857 115.33a.63.63 0 0 0 .442.187.63.63 0 0 0 .432-.175.6.6 0 0 0 .182-.43.58.58 0 0 0-.153-.384 9 9 0 0 0-.903.802"
      />
      <path
        fill="#fff"
        d="M135.207 110.155a.762.762 0 0 1-.903.765.764.764 0 1 1 .43-1.459.75.75 0 0 1 .473.694"
      />
      <path
        fill="#1E293B"
        d="M134.007 110.568a.6.6 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9.5 9.5 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M138.202 110.155a.76.76 0 0 1-.462.724.77.77 0 0 1-.844-.156.77.77 0 0 1-.172-.841.77.77 0 0 1 1.005-.421.75.75 0 0 1 .473.694"
      />
      <path
        fill="#1E293B"
        d="M136.997 110.568a.607.607 0 0 0 .943-.074.6.6 0 0 0 .108-.339.56.56 0 0 0-.153-.389 10 10 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M141.192 110.155a.76.76 0 0 1-1.297.559.76.76 0 0 1-.172-.833.765.765 0 0 1 1.41-.015q.058.14.059.289"
      />
      <path
        fill="#1E293B"
        d="M139.987 110.568a.62.62 0 0 0 .666.145.61.61 0 0 0 .39-.558.59.59 0 0 0-.153-.389q-.478.371-.903.802"
      />
      <path
        fill="#fff"
        d="M144.183 110.155a.77.77 0 0 1-.461.716.765.765 0 0 1-1.009-.99.765.765 0 0 1 .997-.42.75.75 0 0 1 .473.694"
      />
      <path
        fill="#1E293B"
        d="M142.978 110.568a.617.617 0 0 0 1.007-.181.6.6 0 0 0 .049-.232.6.6 0 0 0-.154-.389 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M147.173 110.155a.762.762 0 0 1-.903.765.76.76 0 0 1-.608-.596.77.77 0 0 1 .322-.788.768.768 0 0 1 1.129.33q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M145.973 110.568a.6.6 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M150.169 110.155a.766.766 0 0 1-1.304.569.774.774 0 0 1 .107-1.187.77.77 0 0 1 1.135.329c.039.091.06.19.062.289"
      />
      <path
        fill="#1E293B"
        d="M148.964 110.568a.6.6 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9.5 9.5 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M153.159 110.155a.76.76 0 0 1-.462.724.77.77 0 0 1-.844-.156.77.77 0 0 1-.172-.841.77.77 0 0 1 1.005-.421.75.75 0 0 1 .473.694"
      />
      <path
        fill="#1E293B"
        d="M151.954 110.568a.61.61 0 0 0 1.051-.413.56.56 0 0 0-.153-.389 10 10 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M156.149 110.155a.76.76 0 0 1-1.297.559.76.76 0 0 1-.172-.833.765.765 0 0 1 1.41-.015q.058.14.059.289"
      />
      <path
        fill="#1E293B"
        d="M154.944 110.568a.62.62 0 0 0 .666.145.6.6 0 0 0 .279-.22.6.6 0 0 0 .111-.338.59.59 0 0 0-.153-.389q-.478.371-.903.802"
      />
      <path
        fill="#fff"
        d="M159.14 110.155a.77.77 0 0 1-.461.716.765.765 0 0 1-1.009-.99.765.765 0 0 1 .997-.42.75.75 0 0 1 .473.694"
      />
      <path
        fill="#1E293B"
        d="M157.92 110.568a.6.6 0 0 0 .442.187.6.6 0 0 0 .566-.368.6.6 0 0 0 .048-.232.6.6 0 0 0-.154-.389 10 10 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M162.13 110.155a.762.762 0 0 1-.903.765.76.76 0 0 1-.608-.596.77.77 0 0 1 .322-.788.768.768 0 0 1 1.129.33q.058.14.06.289"
      />
      <path
        fill="#1E293B"
        d="M160.93 110.568a.6.6 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M165.121 110.155a.767.767 0 1 1-.768-.749.755.755 0 0 1 .706.46c.04.091.06.19.062.289"
      />
      <path
        fill="#1E293B"
        d="M163.92 110.568a.6.6 0 0 0 .664.147.61.61 0 0 0 .387-.56.6.6 0 0 0-.154-.389 9.5 9.5 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M168.115 110.155a.76.76 0 0 1-1.297.559.75.75 0 0 1-.213-.39.77.77 0 0 1 .747-.918.76.76 0 0 1 .763.749"
      />
      <path
        fill="#1E293B"
        d="M166.911 110.568a.6.6 0 0 0 .442.187.6.6 0 0 0 .561-.369.565.565 0 0 0-.105-.62 10 10 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M171.106 110.155a.76.76 0 0 1-1.297.559.76.76 0 0 1-.172-.833.765.765 0 0 1 .996-.42.74.74 0 0 1 .414.405q.058.14.059.289"
      />
      <path
        fill="#1E293B"
        d="M169.92 110.568a.614.614 0 0 0 1.056-.413.6.6 0 0 0-.154-.389 10 10 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M174.097 110.155a.77.77 0 0 1-.461.716.763.763 0 1 1-.303-1.465.76.76 0 0 1 .538.216.77.77 0 0 1 .226.533"
      />
      <path
        fill="#1E293B"
        d="M172.892 110.568a.6.6 0 0 0 .441.187.6.6 0 0 0 .432-.172.6.6 0 0 0 .183-.428.6.6 0 0 0-.154-.389 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M135.207 114.912a.765.765 0 0 1-1.296.566.76.76 0 0 1-.174-.834.76.76 0 0 1 .707-.476.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M134.007 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.58.58 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M138.202 114.912a.772.772 0 0 1-1.304.574.765.765 0 0 1-.175-.841.765.765 0 0 1 .715-.477.76.76 0 0 1 .703.456.8.8 0 0 1 .061.288"
      />
      <path
        fill="#1E293B"
        d="M136.997 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M141.192 114.912a.765.765 0 0 1-1.296.566.77.77 0 0 1-.174-.834.76.76 0 0 1 .707-.476.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M139.987 115.33a.62.62 0 0 0 .668.144.615.615 0 0 0 .388-.562.6.6 0 0 0-.153-.384q-.478.37-.903.802"
      />
      <path
        fill="#fff"
        d="M144.182 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M142.978 115.33a.617.617 0 0 0 1.007-.185.6.6 0 0 0 .049-.233.6.6 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M147.173 114.912a.765.765 0 0 1-1.296.566.76.76 0 0 1-.174-.834.76.76 0 0 1 .706-.476.76.76 0 0 1 .703.456.8.8 0 0 1 .061.288"
      />
      <path
        fill="#1E293B"
        d="M145.973 115.33a.6.6 0 0 0 .436.187.62.62 0 0 0 .433-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M150.168 114.912a.768.768 0 1 1-.768-.744.755.755 0 0 1 .768.744"
      />
      <path
        fill="#1E293B"
        d="M148.964 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.58.58 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M153.159 114.912a.772.772 0 0 1-1.304.574.765.765 0 0 1-.175-.841.765.765 0 0 1 .715-.477.76.76 0 0 1 .703.456.8.8 0 0 1 .061.288"
      />
      <path
        fill="#1E293B"
        d="M151.954 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M156.149 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M154.944 115.33a.62.62 0 0 0 .668.144.615.615 0 0 0 .388-.562.58.58 0 0 0-.153-.384q-.478.37-.903.802"
      />
      <path
        fill="#fff"
        d="M159.139 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M157.92 115.33a.6.6 0 0 0 .442.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.58.58 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M162.13 114.912a.765.765 0 0 1-1.296.566.76.76 0 0 1-.174-.834.76.76 0 0 1 .706-.476.76.76 0 0 1 .703.456.8.8 0 0 1 .061.288"
      />
      <path
        fill="#1E293B"
        d="M160.93 115.33a.6.6 0 0 0 .436.187.62.62 0 0 0 .433-.175.62.62 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M165.121 114.912a.76.76 0 0 1-.458.726.76.76 0 0 1-.844-.15.76.76 0 0 1-.178-.84.77.77 0 0 1 .712-.48.76.76 0 0 1 .768.744"
      />
      <path
        fill="#1E293B"
        d="M163.92 115.33a.6.6 0 0 0 .437.187.62.62 0 0 0 .432-.175.62.62 0 0 0 .182-.43.58.58 0 0 0-.154-.384 9 9 0 0 0-.897.802"
      />
      <path
        fill="#fff"
        d="M168.115 114.912a.765.765 0 0 1-1.296.566.77.77 0 0 1-.174-.834.76.76 0 0 1 .707-.476.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M166.911 115.33a.61.61 0 0 0 1.051-.418.56.56 0 0 0-.153-.384 9 9 0 0 0-.898.802"
      />
      <path
        fill="#fff"
        d="M171.106 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M169.92 115.33a.616.616 0 0 0 .947-.078.6.6 0 0 0 .109-.34.58.58 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        d="M174.096 114.912a.763.763 0 1 1-.763-.744.753.753 0 0 1 .763.744"
      />
      <path
        fill="#1E293B"
        d="M172.892 115.33a.6.6 0 0 0 .441.187.62.62 0 0 0 .433-.175.61.61 0 0 0 .182-.43.6.6 0 0 0-.154-.384 9 9 0 0 0-.902.802"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M129.797 106.464h-23.141a1.92 1.92 0 0 1-1.92-1.882V93.907c0-7.281 6.053-13.21 13.493-13.21s13.498 5.929 13.498 13.21v10.675a1.92 1.92 0 0 1-1.93 1.882m-21.221-3.744h19.296v-8.813a9.648 9.648 0 1 0-19.296 0z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M132.763 101.318h-29.462v24.375h29.462z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M121.099 111.442a3.21 3.21 0 0 0-5.435-2.19 3.2 3.2 0 0 0-.982 2.19 3.14 3.14 0 0 0 1.684 2.764l-.537 5.535h4.123l-.538-5.535c.506-.264.931-.66 1.227-1.148a3.13 3.13 0 0 0 .458-1.616"
      />
      <path
        fill="#3B82F6"
        d="M184.56 209.76c12.592 0 22.8-2.257 22.8-5.04s-10.208-5.04-22.8-5.04-22.8 2.256-22.8 5.04c0 2.783 10.208 5.04 22.8 5.04"
      />
      <path
        fill="#fff"
        d="M184.56 209.76c12.592 0 22.8-2.257 22.8-5.04s-10.208-5.04-22.8-5.04-22.8 2.256-22.8 5.04c0 2.783 10.208 5.04 22.8 5.04"
        opacity={0.5}
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M172.637 199.565s-5.53 3.11-5.53 4.608 3.413.576 5.645-.48 4.003-1.843 4.003-3.917-4.118-.211-4.118-.211Zm26.472 0s5.529 3.11 5.529 4.608-3.413.576-5.645-.48-4.003-1.843-4.003-3.917 4.119-.211 4.119-.211Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m174.873 148.651-2.356 51.509a3.12 3.12 0 0 0 2.356.691 3.07 3.07 0 0 0 1.882-1.037l8.002-42.043h1.646l8.477 42.831c.506.28 1.069.444 1.646.48.821 0 2.117-1.037 2.117-1.037l-2.707-51.36z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m183.11 117.437-10.709 4.373 2.823 24.883h20.472l3.768-25.805-11.65-3.682z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m184.287 121.003 1.291 2.074-2.117 17.856 2.587 3.801 2.237-4.147-1.766-17.741 1.531-2.414-2.237-.979z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m185.813 119.453-1.718 2.942-3.063-4.08 1.714-2.04z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m185.812 119.453 2.084 3.24 2.51-4.618-1.469-1.862zm-4.156 6.965h-7.215v2.457l3.61 1.383 3.605-1.536zm14.433 0h-7.214v2.457l3.605 1.383 3.609-1.536z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M195.936 146.232h-21.062v2.419h21.062z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m174.519 148.19-3.648 1.498c-.06 1.043.019 2.089.235 3.11.355 1.383.826 4.378 1.176 4.954.351.576 2.122 1.728 2.357 1.382.235-.345 1.411-10.483 1.411-10.483z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M182.746 112.075v4.2l3.067 3.178 3.125-3.24v-4.2zm-2.141-3.897s-.552-1.263-.921-.48c-.37.782.062 1.977.614 2.457a1.97 1.97 0 0 0 1.406.36l-.307-1.8zm9.983 0s.552-1.263.922-.48-.062 1.977-.614 2.457a1.98 1.98 0 0 1-1.412.36l.308-1.8z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M181.646 99.84s-2.578 1.08-2.578 2.88.37 3.84.984 4.622c.615.783 1.1 1.32 1.1 1.32l.124-2.582 1.776-3.058s2.943 1.858 4.412 1.858a15 15 0 0 0 2.452-.178l.552 3.058.797-2.218v-2.04s1.469-1.382 1.162-2.28-5.28-3.72-8.333-3-2.448 1.618-2.448 1.618Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M180.418 105.898s.734 4.497 1.349 5.697a16.7 16.7 0 0 0 2.942 3.6c.427.298 2.021.058 2.635.058.615 0 2.4-3.6 2.693-4.258s.48-6.297.48-6.297l-.537-1.018s-2.636.662-4.719-.178a7.2 7.2 0 0 1-2.942-1.982s-.183 2.04-.922 3.24-.979 1.138-.979 1.138Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M185.136 106.378a5.3 5.3 0 0 0-2.4-.6 4.5 4.5 0 0 0-2.084.417s0 2.04.677 2.823 2.635.537 3.187-.12c.552-.658.62-2.52.62-2.52Zm.859 0a5.3 5.3 0 0 1 2.4-.6 4.5 4.5 0 0 1 2.083.417s0 2.04-.676 2.823c-.677.782-2.636.537-3.183-.12-.547-.658-.624-2.52-.624-2.52Z"
      />
      <path stroke="#1E293B" strokeMiterlimit={10} d="M185.136 106.378h.859" />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m172.517 144.389 3.883-.115 2.943 2.649a1.44 1.44 0 0 1-.351.922c-.355.345-1.296.115-1.296.115s-.705 1.728-2.001 1.728-4.8-2.4-4.8-2.4z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m172.402 121.81-13.061 17.164 11.529 8.295 1.647-2.88-3.528-6.797 4.824-4.378z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m171.576 142.43-1.997 3.572m-.59-8.41-1.531-3.917"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m199.464 144.389-3.883-.115-2.942 2.649c.006.34.132.666.355.922.35.345 1.291.115 1.291.115s.706 1.728 2.002 1.728 4.824-2.4 4.824-2.4z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m199.464 120.888 13.176 18.086-11.529 8.295-1.647-2.88 3.528-6.797-5.529-4.493z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m200.405 142.43 1.997 3.572m.59-8.41 1.531-3.917"
      />
      <path
        fill="#3B82F6"
        d="M51.12 209.76c12.592 0 22.8-2.257 22.8-5.04s-10.208-5.04-22.8-5.04-22.8 2.256-22.8 5.04c0 2.783 10.208 5.04 22.8 5.04"
      />
      <path
        fill="#fff"
        d="M51.12 209.76c12.592 0 22.8-2.257 22.8-5.04s-10.208-5.04-22.8-5.04-22.8 2.256-22.8 5.04c0 2.783 10.208 5.04 22.8 5.04"
        opacity={0.5}
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M39.274 199.565s-5.53 3.11-5.53 4.608 3.413.576 5.65-.48c2.236-1.056 3.998-1.843 3.998-3.917s-4.118-.211-4.118-.211Zm26.486 0s5.53 3.11 5.53 4.608-3.408.576-5.645-.48-4.003-1.843-4.003-3.917 4.118-.211 4.118-.211Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m41.51 148.651-2.352 51.509a3.12 3.12 0 0 0 2.352.691 3.07 3.07 0 0 0 1.882-1.037l8.001-42.043h1.647l8.472 42.85c.506.28 1.069.444 1.646.48.826 0 2.117-1.037 2.117-1.037l-2.707-51.36z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m49.747 117.437-10.709 4.373 2.823 24.883h20.476l3.763-25.805-11.65-3.682z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M62.573 146.232H41.511v2.419h21.062z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m41.155 148.19-3.648 1.498c-.06 1.043.019 2.089.235 3.11.355 1.383.826 4.378 1.18 4.954.356.576 2.117 1.728 2.353 1.382.235-.345 1.41-10.483 1.41-10.483z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M49.387 112.075v4.2l3.062 3.178 3.125-3.24v-4.2zm-2.145-3.897s-.552-1.263-.922-.48.063 1.977.615 2.457c.407.295.912.424 1.41.36l-.306-1.8zm9.983 0s.552-1.263.922-.48-.063 1.977-.615 2.457a1.97 1.97 0 0 1-1.406.36l.302-1.8z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M48.283 99.84s-2.573 1.08-2.573 2.88.365 3.84.96 4.622c.595.783 1.104 1.32 1.104 1.32l.12-2.582 1.776-3.058s2.942 1.858 4.411 1.858a15 15 0 0 0 2.453-.178l.552 3.058.797-2.218v-2.04s1.469-1.382 1.161-2.28c-.307-.897-5.28-3.72-8.332-3s-2.43 1.618-2.43 1.618Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M47.04 105.898s.74 4.497 1.349 5.697a16.8 16.8 0 0 0 2.971 3.605c.427.298 2.02.058 2.635.058s2.4-3.6 2.698-4.258c.297-.658.48-6.298.48-6.298l-.533-1.022s-2.635.662-4.718-.178a7.2 7.2 0 0 1-2.943-1.982s-.182 2.04-.917 3.24c-.734 1.2-1.022 1.138-1.022 1.138Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M51.772 106.378a5.3 5.3 0 0 0-2.4-.6 4.5 4.5 0 0 0-2.083.417s0 2.04.672 2.823 2.635.537 3.187-.12c.552-.658.624-2.52.624-2.52Zm.86 0a5.3 5.3 0 0 1 2.4-.6 4.5 4.5 0 0 1 2.083.417s0 2.04-.677 2.823-2.63.537-3.182-.12c-.552-.658-.624-2.52-.624-2.52Z"
      />
      <path stroke="#1E293B" strokeMiterlimit={10} d="M51.773 106.378h.859" />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m50.923 121.003 1.291 2.074-2.116 17.856 2.592 3.801 2.232-4.147-1.762-17.741 1.527-2.414-2.237-.979z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m52.45 119.453-1.719 2.942-3.062-4.08 1.718-2.04z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m52.45 119.453 2.082 3.24 2.51-4.618-1.468-1.862-3.125 3.24Zm-13.412 2.357-13.06 17.164 11.53 8.295 1.65-2.88-3.532-6.797 4.824-4.378z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m39.158 144.389 3.879-.115 2.942 2.649a1.44 1.44 0 0 1-.35.922c-.355.345-1.296.115-1.296.115s-.706 1.728-2.002 1.728-4.8-2.4-4.8-2.4z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m38.217 142.43-2.001 3.572m-.59-8.41-1.526-3.917"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m66.101 120.888 13.176 18.086-11.53 8.295-1.646-2.88 3.528-6.797-5.53-4.493z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m66.101 144.389-3.883-.115-2.943 2.649c.006.34.132.666.356.922.35.345 1.29.115 1.29.115s.706 1.728 2.002 1.728 4.8-2.4 4.8-2.4z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m67.042 142.43 2.002 3.572m.585-8.41 1.531-3.917"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="M48.292 126.418h-7.214v2.457l3.61 1.383 3.604-1.536zm14.434 0h-7.214v2.457l3.61 1.383 3.604-1.536z"
      />
    </svg>
  );
};
export default SvgPicSecurity;
