import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRemedmini = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={24}
      fill="none"
      viewBox="0 0 21 24"
      role="img"
      {...props}
    >
      <path
        fill="#0D47A1"
        d="m5.718 10.48.925-2.46a.876.876 0 0 1 1.643.011l1.684 4.61 1.663-7.731a.885.885 0 0 1 .832-.693.87.87 0 0 1 .873.63l1.612 5.634H21v-.041C21 4.672 16.301 0 10.5 0S0 4.672 0 10.44v.04z"
      />
      <path
        fill="#0D47A1"
        d="M14.284 12.227a.87.87 0 0 1-.842-.63l-.832-2.894-1.559 7.266a.88.88 0 0 1-.8.692h-.063a.87.87 0 0 1-.821-.578l-1.913-5.23-.301.816a.88.88 0 0 1-.822.569H.156c.832 4.806 4.98 8.486 10.011 8.64V24l6.196-4.9 1.28-1.012a10.5 10.5 0 0 0 1.642-1.943c.769-1.168 1.3-2.491 1.549-3.907h-6.55z"
      />
    </svg>
  );
};
export default SvgRemedmini;
