import { action, makeObservable, observable } from 'mobx';

type AnalysesServiceParams = {
  service: ServiceResponse;
  biomaterialType: BiomaterialType | null;
  packageService: PatientPkgServiceService | null;
};

export class AnalysesService {
  @observable public service: ServiceResponse;
  @observable public packageService: PatientPkgServiceService | null = null;
  @observable public biomaterialType: BiomaterialType | null = null;
  @observable public deadline = false;
  @observable public comment = '';

  constructor({
    service,
    biomaterialType,
    packageService,
  }: AnalysesServiceParams) {
    this.service = service;
    this.biomaterialType = biomaterialType;
    this.packageService = packageService;
    makeObservable(this);
  }

  @action
  public setDeadline = (value: boolean) => {
    this.deadline = value;
  };

  @action
  public setComment = (value: string) => {
    this.comment = value;
  };
}
