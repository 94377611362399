import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDollar = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667m.733 13.133v1.033H9.275v-1.075c-.617-.15-1.992-.641-2.517-2.466l1.375-.559c.05.184.484 1.742 2 1.742.775 0 1.65-.4 1.65-1.342 0-.8-.583-1.216-1.9-1.691-.916-.325-2.791-.859-2.791-2.759 0-.083.008-2 2.183-2.466v-1.05h1.458V5.2c1.534.267 2.092 1.492 2.217 1.858l-1.317.559A1.61 1.61 0 0 0 10.05 6.5c-.583 0-1.508.308-1.508 1.158 0 .792.716 1.092 2.2 1.584 2 .691 2.508 1.708 2.508 2.875 0 2.191-2.083 2.608-2.517 2.683"
      />
    </svg>
  );
};
export default SvgDollar;
