import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPrinter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.833 6.667H4.167a2.497 2.497 0 0 0-2.5 2.5v5H5V17.5h10v-3.333h3.333v-5c0-1.384-1.116-2.5-2.5-2.5m-2.5 9.166H6.667v-4.166h6.666zm2.5-5.833A.836.836 0 0 1 15 9.167c0-.459.375-.834.833-.834s.833.375.833.834a.836.836 0 0 1-.833.833M15 2.5H5v3.333h10z"
      />
    </svg>
  );
};
export default SvgPrinter;
