import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBook = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <mask
        id="a"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#6B7280"
          d="M5.625 18.333a2.28 2.28 0 0 1-1.615-.635 2.07 2.07 0 0 1-.677-1.573V4.5q0-.792.49-1.417.489-.624 1.281-.791l8.23-1.625V14l-7.897 1.583a.54.54 0 0 0-.312.198.54.54 0 0 0-.125.344q0 .23.187.385.187.157.438.157H15V3.333h1.667v15zm.208-4.52 1.667-.334V3.521l-1.667.333z"
        />
      </g>
    </svg>
  );
};
export default SvgBook;
