import { ButtonProps } from '@components/UI/Button';
import { TextProps } from '@components/UI/Text';

const createTitle = (text: string): TextProps => ({
  tag: 'h3',
  children: text,
});

const createMsg = (message?: string): TextProps => ({
  body: 'body-2',
  className: 'mb-3',
  children: message || '',
});

const drawerButton = {
  close: ({ close }: DrawerActions): ButtonProps => {
    return {
      type: 'primary',
      children: 'OK',
      onClick: () => close(),
      full: true,
    };
  },
};

export const drawerComponent = {
  default: (
    params: DrawerDefaultProps,
    actions: DrawerActions,
  ): DrawerProps => {
    const { title, message, buttons, icon, imageSrc } = params;
    return {
      component: 'Default',
      title: createTitle(title ?? 'Info'),
      icon: icon,
      imageSrc: imageSrc,
      message: createMsg(message ?? 'Message'),
      buttons: buttons ?? [drawerButton.close(actions)],
      actions,
    };
  },
  confirm: (props: DrawerConfirmProps, actions: DrawerActions): DrawerProps => {
    const { title, message, callbacks } = props;
    return {
      component: 'Default',
      title: createTitle(title ?? 'Confirm?'),
      message: createMsg(message),
      buttons: [
        {
          align: 'right',
          children: 'Yes',
          className: 'w-4/12',
          onClick: () => callbacks.onConfirm(),
        },
        {
          children: 'No',
          type: 'warning',
          className: 'w-4/12',
          onClick: () => callbacks.onCancel(),
        },
      ],
      actions,
    };
  },
  createUser: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateUserDrawer',
      title: createTitle('create_user.title'),
      actions,
    };
  },
  tableColumnSettingsDrawer: (
    params: DrawerTableColumnSettings,
    actions: DrawerActions,
  ): DrawerProps<DrawerTableColumnSettings['data']> => {
    const { callbacks, data } = params;
    return {
      component: 'TableColumnSettingsDrawer',
      title: createTitle('user_table.title'),
      buttons: [
        {
          children: 'user_table.apply',
          className: 'mr-2',
          onClick: callbacks?.onConfirm,
          id: 'confirm',
        },
        {
          children: 'user_table.close',
          type: 'info',
          onClick: actions.close,
        },
      ],
      actions,
      data,
    };
  },
  createRole: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateRoleDrawer',
      icon: 'PicAddUser',
      title: createTitle('create_role.title'),
      message: createMsg('create_role.message'),
      actions,
    };
  },
  createSpecialization: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateSpecializationDrawer',
      icon: 'PicAddUser',
      title: createTitle('create_specialization.title'),
      message: createMsg('create_specialization.message'),
      actions,
    };
  },
  createPosition: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreatePositionDrawer',
      icon: 'PicAddUser',
      title: createTitle('create_position.title'),
      message: createMsg('create_position.message'),
      actions,
    };
  },
  createHFT: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateHFTDrawer',
      icon: 'PicAddUser',
      title: createTitle('create_hft.title'),
      message: createMsg('create_hft.message'),
      actions,
    };
  },
  createHealthFacility: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateHFDrawer',
      title: createTitle('create_hf.title'),
      message: createMsg('create_hf.message'),
      actions,
    };
  },
  createCabinetType: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateCabinetTypeDrawer',
      icon: 'PicAddUser',
      title: createTitle('create_cabinet_type.title'),
      message: createMsg('create_cabinet_type.message'),
      actions,
    };
  },
  createCabinet: (
    actions: DrawerActions,
    data?: DrawerCreateCabinetProps,
  ): DrawerProps => {
    return {
      component: 'CreateCabinetDrawer',
      title: createTitle('create_cabinet.title'),
      message: createMsg('create_cabinet.message'),
      actions,
      data,
    };
  },
  createService: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateServiceDrawer',
      title: createTitle('create_service.title'),
      message: createMsg('create_service.message'),
      actions,
    };
  },
  createServicePerformer: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateServicePerformerDrawer',
      title: createTitle('create_service_performer.title'),
      message: createMsg('create_service_performer.message'),
      actions,
    };
  },
  createServiceCategory: (
    actions: DrawerActions,
    data?: DrawerCreateServiceCategory,
  ): DrawerProps => {
    return {
      component: 'CreateServiceCategoryDrawer',
      title: createTitle('create_service_category.title'),
      message: createMsg('create_service_category.message'),
      actions,
      data,
    };
  },
  addServiceUser: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddServiceUserDrawer',
      title: createTitle('add_service_user.title'),
      size: 'medium',
      actions,
    };
  },
  addServiceCabinet: (
    actions: DrawerActions,
    data: DrawerAddCabinetServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddServiceCabinetDrawer',
      title: createTitle('add_service_cabinet.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  addServiceRelatedService: (
    actions: DrawerActions,
    data: DrawerAddRelatedServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddServiceRelatedServiceDrawer',
      title: createTitle('add_service_related_service.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  createScheduleEvents: (
    actions: DrawerActions,
    props?: DrawerCreateScheduleEvents,
  ): DrawerProps => {
    return {
      component: 'CreateScheduleEventsDrawer',
      title: createTitle(
        props?.isCreation
          ? 'create_schedule_events.title_create'
          : 'create_schedule_events.title_update',
      ),
      actions,
      data: props,
    };
  },
  addUserService: (
    actions: DrawerActions,
    data: DrawerAddUserServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddUserServiceDrawer',
      title: createTitle('add_user_service.title'),
      size: 'full',
      actions,
      data,
    };
  },
  addUserLabService: (
    actions: DrawerActions,
    data: DrawerAddUserLabServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddUserLabServiceDrawer',
      title: createTitle('add_user_lab_service.title'),
      size: 'full',
      actions,
      data,
    };
  },
  createPatient: (
    actions: DrawerActions,
    props?: DrawerCreatePatientProps,
  ): DrawerProps => {
    return {
      component: 'CreatePatientDrawer',
      title: createTitle('create_patient.title'),
      actions,
      data: props,
    };
  },
  addServicesToReception: (
    actions: DrawerActions,
    data: DrawerAddServiceToReceptionProps,
  ): DrawerProps => {
    return {
      component: 'AddServicesToReceptionDrawer',
      title: createTitle('add_services_to_reception.title'),
      size: 'full',
      actions,
      data,
    };
  },
  addServicesToOrder: (
    actions: DrawerActions,
    data: DrawerAddServiceToOrderProps,
  ): DrawerProps => {
    return {
      component: 'AddServicesToOrderDrawer',
      title: createTitle('add_services_to_order.title'),
      size: 'full',
      actions,
      data,
    };
  },
  createCalendarEvent: (
    data: DrawerCreateAppointmentProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateCalendarEvent',
      title: createTitle('create_calendar_event.title'),
      actions,
      data,
    };
  },
  createCalendarEventEdit: (
    data: DrawerCreateAppointmentProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateCalendarEventEdit',
      title: createTitle('create_calendar_event.title'),
      actions,
      data,
    };
  },
  createPrice: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreatePrice',
      title: createTitle('create_price.title'),
      actions,
    };
  },
  addUserRole: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddUserRole',
      title: createTitle('add_user_role.title'),
      size: 'medium',
      actions,
    };
  },
  addIUModuleToRole: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddIUModuleToRole',
      title: createTitle('add_ui_module_to_role.title'),
      actions,
    };
  },
  addEntityToRole: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddEntityToRoleDrawer',
      title: createTitle('add_entity_to_role.title'),
      actions,
    };
  },
  addUserToRole: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddUserToRoleDrawer',
      title: createTitle('add_user_to_role.title'),
      actions,
    };
  },
  importPriceById: (
    actions: DrawerActions,
    data?: DrawerImportPriceProps,
  ): DrawerProps => {
    return {
      component: 'ImportPriceDrawer',
      title: createTitle('import_price.title'),
      message: createMsg('import_price.message'),
      actions,
      data,
    };
  },
  addServiceToDesktopEvent: (
    actions: DrawerActions,
    data: DrawerAddServiceToDesktopEventProps,
  ): DrawerProps => {
    return {
      component: 'AddServiceToDesktopEvent',
      title: createTitle('add_service_to_desktop_event.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  addServicesToCabinet: (
    actions: DrawerActions,
    data: DrawerAddServiceToCabinetProps,
  ): DrawerProps => {
    return {
      component: 'AddServicesToCabinetDrawer',
      title: createTitle('add_services_to_cabinet.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  addServicesToPrice: (
    actions: DrawerActions,
    data: DrawerAddServiceToPriceProps,
  ): DrawerProps => {
    return {
      component: 'AddServicesToPriceDrawer',
      title: createTitle('add_services_to_price.title'),
      size: 'full',
      actions,
      data,
    };
  },
  createDocChapters: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateDocChaptersDrawer',
      title: createTitle('create_doc_chapters.title'),
      size: 'medium',
      actions,
    };
  },
  createDocElementGroup: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateDocElementGroupDrawer',
      title: createTitle('create_doc_element_group.title'),
      actions,
    };
  },
  createDocTemplates: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateDocTemplatesDrawer',
      title: createTitle('create_doc_templates_drawer.title'),
      size: 'small',
      actions,
    };
  },
  createDocElement: (
    actions: DrawerActions,
    callback?: DrawerToggleDocElementsProps['onCrudAction'],
  ): DrawerProps => {
    return {
      component: 'CreateDocElementsDrawer',
      title: createTitle('create_doc_elements.title'),
      size: 'medium',
      actions,
      data: { callback },
    };
  },
  createDocElementList: (
    actions: DrawerActions,
    data?: DrawerCreateDocElementProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocElementListDrawer',
      title: createTitle(
        data?.id
          ? 'create_doc_element_list.title_edit'
          : 'create_doc_element_list.title_create',
      ),
      actions,
      data,
    };
  },
  createDocElementString: (
    actions: DrawerActions,
    data?: DrawerCreateDocElementProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocElementStringDrawer',
      title: createTitle(
        data?.id
          ? 'create_doc_elements_string.title_edit'
          : 'create_doc_elements_string.title_create_string',
      ),
      actions,
      data: { ...data, type: 2 },
    };
  },
  createDocElementNumber: (
    actions: DrawerActions,
    data?: DrawerCreateDocElementProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocElementStringDrawer',
      title: createTitle(
        data?.id
          ? 'create_doc_elements_string.title_edit'
          : 'create_doc_elements_string.title_create_number',
      ),
      actions,
      data: { ...data, type: 3 },
    };
  },
  createDocElementMKX: (
    actions: DrawerActions,
    data?: DrawerCreateDocElementProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocElementMKX',
      title: createTitle(
        data?.id
          ? 'create_doc_elements_mkx_10.title_edit'
          : 'create_doc_elements_mkx_10.title_create',
      ),
      actions,
      data: { ...data, type: 5 },
    };
  },
  createDocElementICPC2: (
    actions: DrawerActions,
    data?: DrawerCreateDocElementProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocElementICPC2',
      title: createTitle(
        data?.id
          ? 'create_doc_elements_icpc_2.title_edit'
          : 'create_doc_elements_icpc_2.title_create',
      ),
      actions,
      data: { ...data, type: 4 },
    };
  },
  createDocChapterGroup: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateDocChapterGroupDrawer',
      title: createTitle('create_doc_chapter_group.title'),
      actions,
    };
  },

  addDocChapterElements: (
    actions: DrawerActions,
    data?: DrawerToggleDocElementsProps,
  ): DrawerProps => {
    return {
      component: 'AddDocChapterElementsDrawer',
      title: createTitle('add_doc_chapter_elementes.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  createDocTemplateGroup: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateDocTemplateGroup',
      title: createTitle('create_doc_template_group.title'),
      actions,
    };
  },
  addDocTemplateChapters: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddDocTemplateChapters',
      title: createTitle('add_doc_template_chapters.title'),
      size: 'medium',
      actions,
    };
  },
  addDocToService: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddDocToServiceDrawer',
      title: createTitle('add_doc_to_service.title'),
      size: 'medium',
      actions,
    };
  },
  createMedCardType: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateMedCardTypeDrawer',
      title: createTitle('create_med_card_type.title'),
      actions,
    };
  },
  createMedCard: (actions: DrawerActions, medCard?: MedCard): DrawerProps => {
    return {
      component: 'CreateMedCardDrawer',
      title: createTitle(
        medCard ? 'create_med_card.title_view' : 'create_med_card.title_add',
      ),
      size: 'medium',
      actions,
      data: { medCard },
    };
  },
  createMedEpisode: (
    actions: DrawerActions,
    data: DrawerCreateMedEpisodeProps,
  ): DrawerProps => {
    return {
      component: 'CreateMedEpisodeDrawer',
      title: createTitle('create_med_episode.title'),
      actions,
      data,
    };
  },
  addMedDiagnose: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddMedDiagnoseToEpisode',
      title: createTitle('add_med_diagnose_to_episode.title'),
      actions,
    };
  },
  createDocument: (
    actions: DrawerActions,
    data?: DrawerCreateDocumentProps,
  ): DrawerProps => {
    return {
      component: 'CreateDocumentDrawer',
      title: createTitle('create_document.title'),
      actions,
      data,
    };
  },
  createUrgentEvent: (
    data: DrawerCreateAppointmentProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateUrgentEventDrawer',
      title: createTitle(
        !data.isUpdate
          ? 'create_calendar_event.title'
          : 'create_calendar_event.title_update',
      ),
      actions,
      data,
    };
  },
  viewAppointmentInfo: (
    data: ViewAppointmentHistoryProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'ViewAppointmentInfoDrawer',
      title: createTitle('view_appointment.title'),
      actions,
      data,
    };
  },
  createInsuranceCompany: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateInsuranceCompanyDrawer',
      title: createTitle('create_insurance_company.title'),
      actions,
    };
  },
  createGuarantor: (
    data: CreateCompanyGuarantorProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateGuarantorDrawer',
      title: createTitle('create_guarantor.title'),
      actions,
      data,
    };
  },
  createInsurancePolicy: (
    data: CreateInsurancePolicyProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateInsurancePolicyDrawer',
      title: createTitle('create_insurance_policy.title'),
      actions,
      data,
    };
  },
  createGuarantee: (
    data: CreateGuaranteeProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'CreateGuaranteeDrawer',
      title: createTitle('create_guarantee.title'),
      actions,
      data,
    };
  },
  createConsumable: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateConsumableDrawer',
      title: createTitle('create_consumable.title'),
      actions,
    };
  },
  createConsumableType: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateConsumableTypeDrawer',
      title: createTitle('create_consumable_type.title'),
      actions,
    };
  },
  createBiomaterialType: (
    actions: DrawerActions,
    data?: DrawerCreateBiomaterialType,
  ): DrawerProps => {
    return {
      component: 'CreateBiomaterialTypeDrawer',
      title: createTitle('create_biomaterial_type.title'),
      actions,
      data,
    };
  },
  addLabServiceToAnalysis: (
    data: AddLabServiceToAnalysisProps,
    actions: DrawerActions,
  ): DrawerProps => {
    return {
      component: 'AddLabServiceToAnalysis',
      title: createTitle('add_lab_service_to_analysis.title'),
      size: 'full',
      actions,
      data,
    };
  },
  analysisDetailed: (
    actions: DrawerActions,
    data: DrawerAnalysisDetailedProps,
  ): DrawerProps => {
    return {
      component: 'AnalysisDetailedDrawer',
      title: createTitle('analysis_detailed.title'),
      actions,
      data,
    };
  },
  createTown: (
    actions: DrawerActions,
    data: DrawerCreateTownProps,
  ): DrawerProps => {
    return {
      component: 'CreateTownDrawer',
      title: createTitle('create_town.title'),
      actions,
      data,
    };
  },
  createUserDocTemplate: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateUserDocTemplateDrawer',
      title: createTitle('create_user_doc_template.title'),
      size: 'medium',
      actions,
    };
  },
  xReport: (actions: DrawerActions, data?: DrawerXReportProps): DrawerProps => {
    return {
      component: 'XReportDrawer',
      title: createTitle('x_report.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  zReport: (actions: DrawerActions, data?: DrawerZReportProps): DrawerProps => {
    return {
      component: 'ZReportDrawer',
      title: createTitle('z_report.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  officialRemoval: (
    actions: DrawerActions,
    data: DrawerOfficialRemovalProps,
  ): DrawerProps => {
    return {
      component: 'OfficialRemovalDrawer',
      title: createTitle('official_removal.title'),
      actions,
      data,
    };
  },
  officialEntry: (
    actions: DrawerActions,
    data: DrawerOfficialEntryProps,
  ): DrawerProps => {
    return {
      component: 'OfficialEntryDrawer',
      title: createTitle('official_entry.title'),
      actions,
      data,
    };
  },
  createCertificate: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateCertificateDrawer',
      title: createTitle('create_certificate.title'),
      actions,
    };
  },
  editCertificate: (
    actions: DrawerActions,
    data: DrawerEditCertificateProps,
  ): DrawerProps => {
    return {
      component: 'EditCertificateDrawer',
      title: createTitle('edit_certificate.title'),
      actions,
      data,
    };
  },
  createCashPlace: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreateCashPlaceDrawer',
      title: createTitle('create_cash_place.title'),
      actions,
    };
  },
  refundDrawer: (
    actions: DrawerActions,
    data: RefundDrawerProps,
  ): DrawerProps => {
    return {
      component: 'RefundDrawer',
      title: createTitle('refund_drawer.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  orderInfo: (
    actions: DrawerActions,
    data: OrderInfoDrawerProps,
  ): DrawerProps => {
    return {
      size: 'medium',
      component: 'OrderInfoDrawer',
      title: createTitle('order_info_drawer.title'),
      actions,
      data,
    };
  },
  createLabService: (
    actions: DrawerActions,
    data: DrawerCreateLabServiceProps,
  ): DrawerProps => {
    return {
      component: 'CreateLabServiceDrawer',
      title: createTitle('create_lab_service.title'),
      message: createMsg('create_service.message'),
      actions,
      data,
    };
  },
  createClinicService: (
    actions: DrawerActions,
    data: DrawerCreateClinicServiceProps,
  ): DrawerProps => {
    return {
      component: 'CreateClinicServiceDrawer',
      title: createTitle('create_clinic_service.title'),
      message: createMsg('create_service.message'),
      actions,
      data,
    };
  },
  createCollectionService: (
    actions: DrawerActions,
    data: DrawerCreateClinicServiceProps,
  ): DrawerProps => {
    return {
      component: 'CreateCollectionServiceDrawer',
      title: createTitle('create_collection_service.title'),
      actions,
      data,
    };
  },
  addConsumable: (
    actions: DrawerActions,
    data: DrawerAddConsumablesDrawer,
  ): DrawerProps => {
    return {
      component: 'AddConsumableDrawer',
      title: createTitle('add_consumable.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  createPreferentialCategory: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'CreatePreferentialCategory',
      title: createTitle('create_preferential_category.title'),
      actions,
    };
  },
  addPreferentialService: (
    actions: DrawerActions,
    props: DrawerAddPreferentialServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddPreferentialServiceDrawer',
      title: createTitle('add_preferential_service.title'),
      size: 'medium',
      actions,
      data: props,
    };
  },
  createServiceProperty: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertyDrawer',
      title: createTitle('create_service_property.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  createServicePropertyNumber: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertyNumberDrawer',
      title: createTitle('create_service_property_number.title'),
      actions,
      data,
    };
  },
  createServicePropertyText: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertyTextDrawer',
      title: createTitle('create_service_property_text.text'),
      actions,
      data,
    };
  },
  createServicePropertySelect: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertySelectDrawer',
      title: createTitle('create_service_property_select.title'),
      actions,
      data,
    };
  },
  createServicePropertyCalc: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertyCalcDrawer',
      title: createTitle('create_service_property_calc.title'),
      actions,
      data,
    };
  },
  createServicePropertySeparator: (
    actions: DrawerActions,
    data?: DrawerCreateServicePropertyProps,
  ): DrawerProps => {
    return {
      component: 'CreateServicePropertySeparatorDrawer',
      title: createTitle('create_service_property_separator.title'),
      actions,
      data,
    };
  },
  createDirection: (
    actions: DrawerActions,
    data: DrawerCreateDirectionProps,
  ): DrawerProps => {
    return {
      component: 'CreateDirectionDrawer',
      title: createTitle('create_direction.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  addPropertyToService: (
    actions: DrawerActions,
    data: DrawerAddPropertyToServiceProps,
  ): DrawerProps => {
    return {
      component: 'AddPropertyToServiceDrawer',
      title: createTitle('add_property_to_service.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  refIntervalsNumber: (
    actions: DrawerActions,
    data: DrawerRefIntervalsProps,
  ): DrawerProps => {
    return {
      component: 'RefIntervalsNumDrawer',
      title: createTitle('reverence_intervals.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  refIntervalsText: (
    actions: DrawerActions,
    data: DrawerRefIntervalsProps,
  ): DrawerProps => {
    return {
      component: 'RefIntervalsTextDrawer',
      title: createTitle('reverence_intervals.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  uploadArchiveDoc: (
    actions: DrawerActions,
    data: DrawerUploadArchiveDocProps,
  ): DrawerProps => ({
    component: 'UploadArchiveDocDrawer',
    title: createTitle('upload_archive_doc.title'),
    actions,
    data,
  }),
  startAppointment: (
    actions: DrawerActions,
    data: DrawerStartAppointmentProps,
  ): DrawerProps => ({
    component: 'StartAppointmentDrawer',
    title: createTitle('start_appointment.title'),
    actions,
    data,
  }),
  combineOrders: (
    actions: DrawerActions,
    data: DrawerCombineOrdersProps,
  ): DrawerProps => ({
    component: 'CombineOrdersDrawer',
    title: createTitle('combine_orders.title'),
    size: 'medium',
    actions,
    data,
  }),
  insurancePriceServices: (
    actions: DrawerActions,
    data: DrawerInsurancePriceServicesProps,
  ): DrawerProps => {
    return {
      component: 'InsurancePriceServicesDrawer',
      title: createTitle('insurance_price_services.title'),
      actions,
      data,
      size: 'medium',
    };
  },
  settingLabWorkplace: (
    actions: DrawerActions,
    storageKey: StorageKey,
  ): DrawerProps => ({
    component: 'SettingLabWorkplaceDrawer',
    title: createTitle('setting_lab_workplace.title'),
    size: 'medium',
    actions,
    data: storageKey,
  }),
  sortSampleByBarcode: (
    actions: DrawerActions,
    data: DrawerSortSamplesByBarcodeProps,
  ): DrawerProps => ({
    component: 'SortSamplesByBarcodeDrawer',
    title: createTitle('sort_samples_by_service_code.title'),
    size: 'medium',
    actions,
    data,
  }),
  createDevice: (
    actions: DrawerActions,
    data: DrawerDeviceProps,
  ): DrawerProps => ({
    component: 'CreateDeviceDrawer',
    title: createTitle(
      data.device
        ? `create_device.update ${
            data.type === 'printer'
              ? 'create_device.printer'
              : 'create_device.analiser'
          }`
        : data.type === 'printer'
          ? 'create_device.add_printer'
          : 'create_device.add_analiser',
    ),
    actions,
    data,
  }),
  createDocDirection: (
    actions: DrawerActions,
    data: DrawerCreateDocDirectionProps,
  ): DrawerProps => {
    return {
      component: 'CreateDirectionDrawer',
      title: createTitle('create_doc_direction.title'),
      actions,
      data,
    };
  },
  editDocDirection: (
    actions: DrawerActions,
    data: DrawerEditDocDirectionProps,
  ): DrawerProps => ({
    component: 'EditDocDirectionDrawer',
    title: createTitle(
      data.direction.service.type === 'clinic_service'
        ? 'edit_doc_direction.title_consult'
        : 'edit_doc_direction.title_analysis',
    ),
    actions,
    data,
  }),
  patientDailyPayments: (
    actions: DrawerActions,
    data: DrawerPatientDailyPaymentsProps,
  ): DrawerProps => ({
    component: 'PatientDailyPaymentsDrawer',
    title: createTitle('patient_daily_payments.title'),
    actions,
    data,
    size: 'medium',
  }),
  confirmGuarantee: (
    actions: DrawerActions,
    data: DrawerConfirmGuaranteeProps,
  ): DrawerProps => ({
    component: 'ConfirmGuaranteeDrawer',
    title: createTitle('confirm_guarantee.title'),
    actions,
    data,
  }),
  chooseGuarantee: (
    actions: DrawerActions,
    data: DrawerChooseGuaranteeProps,
  ): DrawerProps => ({
    component: 'ChooseGuaranteeDrawer',
    title: createTitle('choose_guarantee.title'),
    actions,
    data,
  }),
  createDirectionWithGuarantee: (
    actions: DrawerActions,
    data: DrawerCreateDirectionWithGuaranteeProps,
  ): DrawerProps => ({
    component: 'CreateDirectionWithGuaranteeDrawer',
    title: createTitle('create_direction.title'),
    size: 'medium',
    actions,
    data,
  }),
  replaceService: (
    actions: DrawerActions,
    data: DrawerReplaceServiceProps,
  ): DrawerProps => ({
    size: 'medium',
    component: 'ReplaceServiceDrawer',
    title: createTitle('replace_service.title'),
    actions,
    data,
  }),
  createNotExistImportData: (
    actions: DrawerActions,
    data: DrawerCreateNotExistImportData,
  ): DrawerProps => ({
    component: 'CreateNotExistImportDataDrawer',
    title: createTitle('create_not_exist_import_data.title'),
    message: createMsg('create_not_exist_import_data.description'),
    actions,
    data,
  }),
  addPatientConfidant: (actions: DrawerActions): DrawerProps => ({
    size: 'medium',
    component: 'AddPatientConfidantDrawer',
    title: createTitle('add_patient_confidant.title'),
    actions,
  }),
  addConfidantPersonFor: (actions: DrawerActions): DrawerProps => ({
    size: 'medium',
    component: 'AddConfidantPersonForDrawer',
    title: createTitle('add_confidant_person_for.title'),
    actions,
  }),
  createPayer: (
    actions: DrawerActions,
    data: DrawerCreatePayerProps,
  ): DrawerProps => ({
    component: 'CreatePayerDrawer',
    title: createTitle('create_payer.title'),
    actions,
    data,
  }),
  createCounterparty: (
    actions: DrawerActions,
    data: DrawerCreateCounterpartyProps,
  ): DrawerProps => ({
    component: 'CreateCounterpartyDrawer',
    title: createTitle(
      data.id ? 'create_counterparty.update' : 'create_counterparty.create',
    ),
    actions,
    data,
  }),
  createReferralDoctor: (
    actions: DrawerActions,
    data: DrawerCreateReferralDoctorProps,
  ): DrawerProps => ({
    component: 'CreateReferralDoctorDrawer',
    title: createTitle('create_ref_doctor.title'),
    actions,
    data,
  }),
  combinePatients: (
    actions: DrawerActions,
    data: DrawerCombinePatientsProps,
  ): DrawerProps => ({
    component: 'CombinePatientsDrawer',
    title: createTitle('combine_patients.title'),
    actions,
    data,
    size: 'medium',
  }),
  bindingRemedEpisode: (actions: DrawerActions): DrawerProps => ({
    size: 'medium',
    component: 'BindingRemedEpisodeDrawer',
    title: createTitle('online_episodes.title'),
    actions,
  }),
  addScheduledCall: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddScheduledCallDrawer',
      title: createTitle('add_schedule_call.title'),
      actions,
    };
  },
  addServiceToCollectionPoint: (
    actions: DrawerActions,
    data: DrawerServiceToColPointProps,
  ): DrawerProps => ({
    size: 'medium',
    component: 'AddServicesToCollectionPointDrawer',
    title: createTitle(
      data.secondary
        ? 'add_service_to_collection_point.secondary_title'
        : 'add_service_to_collection_point.title',
    ),
    actions,
    data,
  }),
  onlineRecordsDrawer: (actions: DrawerActions): DrawerProps => ({
    size: 'small',
    component: 'OnlineRecordsDrawer',
    title: createTitle('online_records.title'),
    actions,
  }),
  onlineVisitTip: (actions: DrawerActions): DrawerProps => ({
    component: 'OnlineVisitsTipDrawer',
    title: createTitle('online_visits_tip.title'),
    actions,
  }),
  createServicePackage: (
    actions: DrawerActions,
    data?: PackageService,
  ): DrawerProps => {
    return {
      component: 'CreateServicePackageDrawer',
      title: createTitle(
        data
          ? 'create_service_packages.edit'
          : 'create_service_packages.create',
      ),
      actions,
      data,
    };
  },
  addServiceToPackage: (
    actions: DrawerActions,
    data?: DrawerAddServiceToPackage,
  ): DrawerProps => {
    return {
      component: 'AddServiceToPackageDrawer',
      title: createTitle(
        data?.isOptional
          ? 'add_service_packages.add_choice_service'
          : 'add_service_packages.add_service',
      ),
      size: 'medium',
      actions,
      data,
    };
  },
  addPackageServiceToPatient: (
    actions: DrawerActions,
    data: DrawerAddServicePackageToPatient,
  ): DrawerProps => {
    return {
      component: 'AddPackageServiceToPatientDrawer',
      title: createTitle('add_package_service_to_patient.title'),
      size: 'medium',
      actions,
      data,
    };
  },
  onSendAllBioResults: (
    actions: DrawerActions,
    data: DrawerOnSendAllBioResults,
  ): DrawerProps => ({
    size: 'medium',
    component: 'OnSendAllBioResultsDrawer',
    title: createTitle('on_send_all_bio_results.title'),
    actions,
    data,
  }),
  addPackageServiceOnCalendar: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddPackageServicesOnCalendar',
      title: createTitle('add_package_service_on_calendar.title'),
      size: 'medium',
      actions,
    };
  },
  addPackageServiceOnWorkplace: (actions: DrawerActions): DrawerProps => {
    return {
      component: 'AddPackageServicesOnWorkplace',
      title: createTitle('add_package_service_on_workplace.title'),
      size: 'medium',
      actions,
    };
  },
  addPackageServiceOnLabPlanning: (
    actions: DrawerActions,
    data: DrawerAddPackageServiceToLabProps,
  ): DrawerProps => {
    return {
      component: 'AddPackageServicesOnLabPlanning',
      title: createTitle('add_package_service_on_lab_planning.title'),
      size: 'medium',
      actions,
      data,
    };
  },
};
