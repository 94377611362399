import { action, makeObservable, observable, toJS } from 'mobx';
import { cloneDeep, isEqual, pick, sortBy } from 'lodash';
import { DocChapterItem } from '@modules/Documents/DocChapter';
import { DocTemplateApi } from '@modules/Api/methods';

type DocTemplateKey = keyof DocTemplate;
type DocApi = DocTemplateApi['getDocElement'];
type Values = ValueOf<DocTemplateType & HelperType>;

type HelperType = {
  _origin: Readonly<DocTemplateType>;
  _elements: Readonly<DocTemplateType>;
  _chapters: DocChapterItem[];
  _setChapters: (api: DocApi) => void;
};
type DocTemplateTypeOrigin = DocTemplateType & {
  isDone: boolean;
  _chapters: DocChapterItem[];
};

export class DocTemplate implements DocTemplateType {
  public id;
  @observable public title;
  @observable public active;
  @observable public canBeEdit;
  @observable public group;
  @observable public isDone;
  @observable public chapters: DocTemplateChapter[] = [];
  @observable public _chapters: DocChapterItem[] = [];
  @observable public delete_marked;
  @observable public latest_version;
  @observable public compiled;
  public _origin: Readonly<Partial<DocTemplateTypeOrigin>> = {};

  constructor(template: DocTemplateType, api: DocApi, isDone = false) {
    makeObservable(this);
    this.id = template.id;
    this.title = template.title;
    this.active = template.active;
    this.canBeEdit = template.canBeEdit;
    this.group = template.group;
    this.delete_marked = template.delete_marked;
    this.latest_version = template.latest_version;
    this.compiled = template.compiled;
    this.isDone = isDone;
    this._setChapters(template.chapters, api);
    this._setOrigin({ ...template, _chapters: toJS(this._chapters), isDone });
  }

  public get isDirty() {
    const COMPARISON_KEYS = [
      'title',
      'canBeEdit',
      'group',
      '_chapters',
      'compiled',
      'isDone',
    ];
    const filtered = pick(toJS(this), COMPARISON_KEYS);
    const origin = pick(toJS(this._origin), COMPARISON_KEYS);
    // console.log(filtered, origin, !isEqual(origin, filtered));
    return !isEqual(origin, filtered);
  }

  @action
  public update = (keys: DocTemplateKey[] | DocTemplateKey, value: Values) => {
    if (!Array.isArray(keys)) keys = [keys];
    keys.forEach((key) => {
      if (key === 'update') return;
      if (key === 'id') return;
      if (key === 'isDirty') return;
      if (key === '_origin') return;
      this[key] = value as never;
    });
  };

  @action
  public updateChapter = (props: Partial<DocTemplateChapter>) => {
    const chapter = this._chapters.find((e) => e.id === props.id);
    if (chapter) {
      if (props.position !== undefined) chapter.position = props.position;
      if (props.isEmail !== undefined) chapter.isEmail = props.isEmail;
      if (props.printTitle !== undefined) chapter.printTitle = props.printTitle;
      if (props.isArchived !== undefined) chapter.isArchived = props.isArchived;
    }
  };

  @action
  public addChapter = (chapter: DocChapter, api: DocApi) => {
    this._chapters = [...this._chapters, new DocChapterItem(chapter, api)];
    this.setOrderDisable();
  };

  @action
  public removeChapter = (id: number) => {
    const _chapters = this._chapters.filter((e) => e.id !== id);
    this._chapters = sortBy(_chapters, 'position');
    this.setOrderDisable();
  };

  @action
  public setOrderDisable = () => {
    this._chapters.forEach((e, index) => {
      e.isFirstEl = index === 0;
      e.isLastEl = index === this._chapters.length - 1;
    });
  };

  private _setChapters = (chapters: DocTemplateChapter[], api: DocApi) => {
    const sortedChapters = sortBy(chapters, 'position');
    chapters.forEach((e) => {
      e.chapter.elems = sortBy(e.chapter.elems, 'position');
    });

    this.chapters = sortedChapters;
    this._chapters = sortedChapters.map(
      ({ chapter, position, printTitle, isEmail, isArchived }) =>
        new DocChapterItem(
          { ...chapter, printTitle, isEmail, isArchived },
          api,
          { isDone: this.isDone, position },
        ),
    );
    this.setOrderDisable();
  };

  private _setOrigin = (data: DocTemplateTypeOrigin) => {
    this._origin = cloneDeep<DocTemplateTypeOrigin>(toJS(data));
  };
}
