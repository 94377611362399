import { DateService } from '@modules/Format';
import { LStorage } from '@modules/Storage';
import { Store } from '@stores';
import { ERROR_REFRESH_TOKEN, SUCCESS_REFRESH_TOKEN } from '../consts';
import { EXPIRED_OFFSET_MIN } from './const';

class TokenInterceptor {
  private _executor?: Promise<any>;

  public isExpiredToken() {
    const expiredDate = LStorage.getItem('EXP');

    if (!expiredDate) return true;

    const exp = DateService.parseTokenDate(expiredDate);
    const now = DateService.createDate()
      .add(EXPIRED_OFFSET_MIN, 'minute')
      .unix();

    return now >= exp.unix;
  }

  private _refreshToken = () => {
    const isExpired = this.isExpiredToken();

    if (!isExpired) {
      return Promise.resolve(SUCCESS_REFRESH_TOKEN);
    }

    const refreshToken = LStorage.getItem<string>('REFRESH_TOKEN');

    if (!refreshToken) {
      return this._logoutExecutor();
    }

    return this._refreshExecutor(refreshToken);
  };

  private _refreshExecutor = (token: string): Promise<HttpResponse<any>> => {
    const promise = Store.AuthService.refreshTokenReq(token);
    this._executor = promise;

    return promise.then((res) => {
      this._executor = undefined;
      if (!res.success) {
        Store.AuthService.setLogout();
      }
      return res;
    });
  };

  private _logoutExecutor = (): Promise<HttpResponse<any>> => {
    const promise = Store.AuthService.logoutReq();
    this._executor = promise;

    return promise.then(() => {
      this._executor = undefined;
      return ERROR_REFRESH_TOKEN;
    });
  };

  public run = async (): Promise<HttpResponse<any>> => {
    if (this._executor) {
      return await this._executor;
    }
    return this._refreshToken();
  };
}

export default new TokenInterceptor();
