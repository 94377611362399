import { AuthStore } from './auth.store';

export class AuthService extends AuthStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  public loginReq = async (params: FormData) => {
    const res = await this._store.AuthApi.login(params);
    if (res.success && res.data) {
      this.setToken(res.data);
      await this._store.ProfileService.getProfileReq();
    }
    return res;
  };

  public logoutReq = async () => {
    const refreshToken = this._store.AuthService.refreshToken;
    if (!refreshToken) {
      this.setLogout();
      return;
    }

    try {
      const res = await this._store.AuthApi.logout(refreshToken);
      if (!res.success) {
        this._store.ModalService.modal.error(res.errors);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setLogout();
    }
  };

  public changePasswordReq = async (params: ChangePasswordReq) => {
    const res = await this._store.ApiService.changePassword(params);
    if (res.success) {
      this._store.StorageService.LStorage.removeItem('TOKEN');
      this._store.StorageService.LStorage.removeItem('REFRESH_TOKEN');
      this._store.StorageService.LStorage.removeItem('PROFILE_META');
    }
    return res;
  };

  public refreshTokenReq = async (refreshToken: string) => {
    const res = await this._store.AuthApi.refreshToken(refreshToken);
    if (res.success && res.data) {
      this.setNewToken(res.data);
    }
    if (!res.success) {
      await this.logoutReq();
    }
    return res;
  };
}
