import { FetchService } from '@modules/Api';

export class SpecialisationApi extends FetchService {
  public getSpecializations = (query?: GetSpecializationsQuery) => {
    return this.request<
      'SPECIALIZATION',
      Specialization[],
      GetSpecializationsQuery
    >('/specialization', 'GET_SPECIALIZATIONS', {
      query,
      permit: 'specializations',
    });
  };

  public createSpecialization = (params: CreateSpecializationReq) => {
    return this.request<
      'SPECIALIZATION',
      Specialization,
      CreateSpecializationReq
    >('/specialization', 'CREATE_SPECIALIZATION', {
      method: 'POST',
      params,
      permit: 'specializations',
    });
  };

  public removeSpecialization = (specId: number) => {
    return this.request<'SPECIALIZATION'>(
      '/specialization',
      'REMOVE_SPECIALIZATION',
      {
        method: 'DELETE',
        pathParam: specId,
        permit: 'specializations',
      },
    );
  };

  public updateSpecialization = (specId: number, title: string) => {
    return this.request<'SPECIALIZATION', Specialization>(
      '/specialization',
      'UPDATE_SPECIALIZATION',
      {
        method: 'PUT',
        pathParam: specId,
        params: { title },
        permit: 'specializations',
      },
    );
  };
}
