import React from 'react';
import { Button } from '@components/UI/Button';

type TabProps = {
  tab: UiTab;
  isActive?: boolean;
  onMouseOver?: () => void;
  onClick?: (tab: UiTab) => void;
};

export function Tab({ tab, isActive, onClick, onMouseOver }: TabProps) {
  return (
    <li
      data-testid="ui-tab"
      className="mr-2 hover:bg-blueGray-200 rounded-1"
      onMouseOver={onMouseOver}
      onClick={() => onClick?.(tab)}
    >
      <Button testID="ui-tab-button" type={isActive ? 'black' : 'text'}>
        {tab.label}
      </Button>
    </li>
  );
}
