import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWarningCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#991B1B"
          d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.334 8.333 8.334S18.333 14.6 18.333 10 14.6 1.667 10 1.667m.833 12.5H9.167V12.5h1.666zm0-3.333H9.167v-5h1.666z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgWarningCircle;
