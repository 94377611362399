export const HF_COLOR_CONFIG = <const>{
  'red': {
    light: '#EF4444',
    lighter: 'rgba(239, 68, 68, 0.05)',
    dark: '#7F1D1D',
  },
  'orange': {
    light: '#F97316',
    lighter: 'rgba(249, 115, 22, 0.05)',
    dark: '#7C2D12',
  },
  'amber': {
    light: '#F59E0B',
    lighter: 'rgba(245, 158, 11, 0.05)',
    dark: '#78350F',
  },
  'yellow': {
    light: '#EAB308',
    lighter: 'rgba(234, 179, 8, 0.05)',
    dark: '#713F12',
  },
  'lime': {
    light: '#84CC16',
    lighter: 'rgba(132, 204, 22, 0.05)',
    dark: '#365314',
  },
  'green': {
    light: '#22C55E',
    lighter: 'rgba(34, 197, 94, 0.05)',
    dark: '#14532D',
  },
  'emerald': {
    light: '#10B981',
    lighter: 'rgba(16, 185, 129, 0.05)',
    dark: '#064E3B',
  },
  'teal': {
    light: '#14B8A6',
    lighter: 'rgba(20, 184, 166, 0.05)',
    dark: '#134E4A',
  },
  'cyan': {
    light: '#06B6D4',
    lighter: 'rgba(6, 182, 212, 0.05)',
    dark: '#164E63',
  },
  'sky': {
    light: '#0EA5E9',
    lighter: 'rgba(14, 165, 233, 0.05)',
    dark: '#0C4A6E',
  },
  'blue': {
    light: '#3B82F6',
    lighter: 'rgba(59, 130, 246, 0.05)',
    dark: '#1E3A8A',
  },
  'indigo': {
    light: '#6366F1',
    lighter: 'rgba(99, 102, 241, 0.05)',
    dark: '#312E81',
  },
  'violet': {
    light: '#8B5CF6',
    lighter: 'rgba(139, 92, 246, 0.05)',
    dark: '#4C1D95',
  },
  'purple': {
    light: '#A855F7',
    lighter: 'rgba(168, 85, 247, 0.05)',
    dark: '#581C87',
  },
  'fuchsia': {
    light: '#D946EF',
    lighter: 'rgba(217, 70, 239, 0.05)',
    dark: '#701A75',
  },
  'pink': {
    light: '#EC4899',
    lighter: 'rgba(236, 72, 153, 0.05)',
    dark: '#831843',
  },
  'gray': {
    light: '#d1d1d1',
    lighter: '#ccc',
    dark: '#616161',
  },
  '': {
    light: '',
    lighter: '',
    dark: '',
  },
};
