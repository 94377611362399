export class AppointmentsApi {
  public _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public getAppointments = (
    params?: GetAppointmentsReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.get_list',
        params,
        storage,
      },
      'GET_APPOINTMENTS',
    );
  };

  public getAppointment = (id: number, storage?: { id: string | number }) => {
    this._store.WsService.send(
      {
        method: 'appointments.get',
        params: { id },
        storage,
      },
      'GET_APPOINTMENT',
    );
  };

  public getAppointmentHistory = (
    params: GetAppointmentHistoryReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.get_history',
        params,
        storage,
      },
      'GET_APPOINTMENT_HISTORY',
    );
  };

  public createAppointment = (
    params: CreateAppointmentReq,
    storage: number,
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.create',
        params,
        storage,
      },
      'CREATE_APPOINTMENT',
    );
  };

  public updateAppointment = (
    params: UpdateAppointmentReq,
    storage?: { id: number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.update',
        params,
        storage,
      },
      'UPDATE_APPOINTMENT',
    );
  };

  public updateBatchAppointment = (
    params: UpdateBatchAppointmentReq,
    storage?: { id: number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.confirm_batch',
        params,
        storage,
      },
      'CONFIRM_BATCH_APPOINTMENT',
    );
  };

  public changeStatusAppointment = (
    params: ChangeStatusAppointmentReq,
    id?: number | string,
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.change_status',
        params,
        storage: { id },
      },
      'APPOINTMENT_CHANGE_STATUS',
    );
  };

  public deleteAppointment = (id: number, storage: number) => {
    this._store.WsService.send(
      {
        method: 'appointments.delete',
        params: { id },
        storage,
      },
      'DELETE_APPOINTMENT',
    );
  };

  public subscribeAppointments = () => {
    this._store.WsService.send(
      {
        method: 'appointments.subscribe',
      },
      'APPOINTMENTS_SUBSCRIBE',
    );
  };

  public unsubscribeAppointments = (storage?: number | string) => {
    this._store.WsService.send(
      {
        method: 'appointments.unsubscribe',
        storage,
      },
      'APPOINTMENTS_UNSUBSCRIBE',
    );
  };

  public createAppointmentSecondary = (
    params?: CreateAppointmentSecondaryReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.create_secondary',
        params,
        storage,
      },
      'CREATE_APPOINTMENT_SECONDARY',
    );
  };

  public toggleAppointmentServices = (
    params: ToggleAppointmentServicesReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.add_or_remove_service',
        params,
        storage,
      },
      'APPOINTMENTS_TOGGLE_SERVICES',
    );
  };

  public updateAppointmentServiceCount = (
    params: UpdateAppointmentServiceCountReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.update_service_count',
        params,
        storage,
      },
      'UPDATE_APPOINTMENT_SERVICE_COUNT',
    );
  };

  public replaceAppointmentService = (
    params: ReplaceAppointmentServiceReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.replace_service',
        params,
        storage,
      },
      'APPOINTMENTS_REPLACE_SERVICE',
    );
  };

  public resetReferringDoctor = (
    params: ResetReferringDoctorReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.reset_referring_doctor',
        params,
        storage,
      },
      'APPOINTMENTS_RESET_REFERRAL',
    );
  };

  public appointmentApprove = (
    params: ApproveAppointmentReq,
    storage?: { id: string | number },
  ) => {
    this._store.WsService.send(
      {
        method: 'appointments.outside_approve',
        params,
        storage,
      },
      'APPOINTMENT_OUTSIDE_APPROVE',
    );
  };
}
