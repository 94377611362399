import React from 'react';
import { Text } from '@components/UI/Text';
import { useService } from '@stores/hooks';
import { Button } from '@components/UI/Button';
import Tag from '@components/UI/Tag';
import { useTranslation } from 'react-i18next';

type Props = {
  event: DoctorWorkplace.VisitEvent;
};

export default function VisitServices({ event }: Props) {
  const { t } = useTranslation(['workplace', 'layout']);
  const { drawer } = useService('DrawerService');
  const { notify } = useService('NotifyService');
  const { cutString } = useService('StringService');
  const { once } = useService('WsService');
  const { api } = useService('DoctorWorkplace');

  function updateServiceCountReq(params: UpdateAppointmentServiceCountReq) {
    return new Promise<WsResponse<AppointmentType>>((resolve) => {
      const storage = Date.now();
      once('appointments.update_service_count', (res) => {
        if (res.storage.id === storage) resolve(res);
      });
      api.updateAppointmentServiceCountReq(params, { id: storage });
    });
  }

  async function addServiceReq(service: ServiceResponse) {
    return await api
      .toggleServiceReq({
        id: event.visit.id,
        service_id: service.id,
        action: 'add',
      })
      .then((res) => res.result)
      .then((res) => {
        if (res.success) {
          notify.success({
            title: t('layout:notify_status.add_service_confirm'),
            message: cutString(service.title),
          });
        }
        return res.success;
      });
  }

  async function onAdd(
    service: ServiceResponse,
    toggle: (value: boolean) => void,
    loading: (value: boolean) => void,
  ) {
    loading(true);
    const success = await addServiceReq(service);
    if (success) toggle(true);
    loading(false);
  }

  async function onIncrease(
    service: ServiceResponse,
    new_count: number,
    loading: (value: boolean) => void,
  ) {
    loading(true);
    const res = await updateServiceCountReq({
      id: event.visit.id,
      service_id: service.id,
      new_count,
    });
    if (res.result.success) {
      notify.success({
        title: t('layout:notify_status.add_service_confirm'),
        message: cutString(service.title),
      });
    }
    loading(false);
  }

  function openDrawer() {
    const selectedIds = event.services.map((service) => service.id);
    drawer.addServiceToDesktopEvent({
      onAdd,
      onIncrease,
      selectedIds,
    });
  }

  return (
    <div data-testid="active-visit-services" className="mt-4">
      <Button full icon="Add" type="info" size="mini" onClick={openDrawer}>
        {t('workplace:active_visit.add_services')}
      </Button>
      <div className="mt-2">
        {event.services.map((service, idx) => (
          <div
            key={`${service.code}_${idx}`}
            className="bg-coolGray-100 py-2 px-3 mb-2 rounded-1"
          >
            <div className="flex">
              <Text className="text-coolGray-900 mr-2" tag="h4">
                {service.code}
              </Text>
              <Text className="text-coolGray-500 mr-2" tag="h4">
                {service.short_title}
              </Text>
            </div>

            {Boolean(service.templates?.length) && (
              <div className="mt-2">
                {service.templates?.map((t) => (
                  <Tag
                    key={t.id}
                    size="medium"
                    type="white"
                    className="mr-1 mt-1"
                  >
                    {t.title}
                  </Tag>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
