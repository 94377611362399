import { FetchService } from '@modules/Api';
import { EnvService } from '@modules/Env';

export class DocIcdApi extends FetchService {
  protected readonly _url = EnvService.env.api.url;

  /*
   |----------------------------------------------------------------------------
   | DOC_ICD
   |----------------------------------------------------------------------------
   */
  public getDocIcd = (query?: GetDocIcdQuery) => {
    return this.request<'DOC_ICD', DocIcd[], null, GetDocIcdQuery>(
      '/icd',
      'GET_DOC_ICD_LIST',
      { method: 'GET', query },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | DOC_ICD
   |----------------------------------------------------------------------------
   */
  public getDocIcpc = (query?: GetDocIcpcQuery) => {
    return this.request<'DOC_ICPC', DocIcpc[], null, GetDocIcpcQuery>(
      '/icpc',
      'GET_DOC_ICPC_LIST',
      { query },
    );
  };
}
