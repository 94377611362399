import { DocTemplateStore } from '@modules/Documents/DocTemplate/DocTemplate.store';
import { DocTemplate } from '@modules/Documents/DocTemplate/mapper/DocTemplate';

export class DocTemplateService extends DocTemplateStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  public getDocTemplatesReq = async (query?: GetDocTemplatesQuery) => {
    const res = await this._store.DocTemplateApi.getDocTemplates(query);
    if (res.success && res.data) {
      this.setDocTemplates(res.data, res.meta);
    }
    return res;
  };

  public getDocTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocTemplate(id);
    if (res.success && res.data) {
      this.setDocTemplate(res.data);
    }
    return res;
  };

  public createDocTemplateReq = async (params: CreateDocTemplate) => {
    const res = await this._store.DocTemplateApi.createDocTemplate(params);
    if (res.success && res.data) {
      this.setDocTemplate(res.data, true);
    }
    return res;
  };

  public updateDocTemplateReq = async (
    id: number,
    params: UpdateDocTemplateReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateDocTemplate(id, params);
    if (res.success && res.data) {
      this.updateDocTemplate(res.data);
    }
    return res;
  };

  public deleteDocTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocTemplate(id);
    if (res.success) {
      this.deleteDocTemplate(id);
    }
    return res;
  };

  public activateDocTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.activateDocTemplate(id);
    if (res.success && res.data) {
      this.updateDocTemplate(res.data, true);
    }
    return res;
  };

  public deactivateDocTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deactivateDocTemplate(id);
    if (res.success && res.data) {
      this.updateDocTemplate(res.data, true);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | TEMPLATE SERVICE
   |----------------------------------------------------------------------------
   */
  public setDocTemplateServiceReq = async (params: SetTemplateServiceReq) => {
    const res = await this._store.DocTemplateApi.setDocTemplateService(params);
    if (res.success && res.data) {
      this._store.ServicesService.addDocTmplToService({
        template_id: params.template,
        service_id: res.data.service,
        record_id: res.data.id,
      });
    }
    return res;
  };

  public deleteDocTemplateServiceReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocTemplateService(id);
    if (res.success) {
      this._store.ServicesService.deleteDocTmplToService(id);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | TEMPLATE GROUP
   |----------------------------------------------------------------------------
   */
  public getDocTemplateGroupsReq = async (
    query?: GetDocTemplateGroupsQuery,
  ) => {
    const res = await this._store.DocTemplateApi.getDocTemplateGroups(query);
    if (res.success && res.data) {
      this.setDocTemplateGroups(res.data, res.meta);
    }
    return res;
  };

  public getDocTemplateGroupReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocTemplateGroup(id);
    if (res.success && res.data) {
      //
    }
    return res;
  };

  public createDocTemplateGroupReq = async (params: CreateDocTemplateGroup) => {
    const res = await this._store.DocTemplateApi.createDocTemplateGroup(params);
    if (res.success && res.data) {
      this.setTemplateGroup(res.data);
    }
    return res;
  };

  public updateDocTemplateGroupReq = async (
    id: number,
    params: UpdateDocTemplateGroup,
  ) => {
    const res = await this._store.DocTemplateApi.updateDocTemplateGroup(
      id,
      params,
    );
    if (res.success && res.data) {
      this.updateTemplateGroup(res.data);
    }
    return res;
  };

  public deleteDocTemplateGroupReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocTemplateGroup(id);
    if (res.success) {
      this.deleteTemplateGroup(id);
    }
    return res;
  };

  public compileForSave = (docTemplate: DocTemplate) => {
    const saveData = {
      ...docTemplate,
      chapters: docTemplate?.chapters.map((e, index) => {
        return {
          ...e,
          chapter: {
            ...e.chapter,
            content: docTemplate?._chapters[index].content,
            elems: e.chapter.elems?.map((e, i) => ({
              ...e,
              element: {
                ...e.element,
                value: docTemplate?._chapters[index].elements[i].value,
                compProps: {
                  ...docTemplate?._chapters[index].elements[i].compProps,
                },
                children: docTemplate?._chapters[index].elements[i].children,
                isDone: undefined,
              },
            })),
          },
        };
      }),
      isDone: undefined,
      _origin: undefined,
      _setChapters: undefined,
      _chapters: undefined,
    };
    // console.log(saveData);

    return JSON.stringify(saveData);
  };
}
