import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInClinic = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <rect width={28} height={28} fill="#7C3AED" rx={4} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M14 9.833V6.5H5.667v15h16.666V9.833zm-5 10H7.333v-1.666H9zM9 16.5H7.333v-1.667H9zm0-3.333H7.333V11.5H9zm0-3.334H7.333V8.167H9zm3.333 10h-1.667v-1.666h1.667zm0-3.333h-1.667v-1.667h1.667zm0-3.333h-1.667V11.5h1.667zm0-3.334h-1.667V8.167h1.667zm8.333 10H14v-1.666h1.666V16.5H14v-1.667h1.666v-1.666H14V11.5h6.666zM19 13.167h-1.667v1.666H19zm0 3.333h-1.667v1.667H19z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h20v20H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgInClinic;
