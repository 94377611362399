import { DocumentStore } from '@modules/Documents/Document/Document.store';

export class DocumentService extends DocumentStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getDocumentsReq = async (query?: GetDocumentsQuery) => {
    const res = await this._store.DocumentsApi.getDocuments(query);
    if (res.success && res.data) {
      this.setMedDocuments(res.data, res.meta);
    }
    return res;
  };

  public getDocumentReq = async (id: number) => {
    const res = await this._store.DocumentsApi.getDocument(id);
    if (res.success && res.data) {
      this.setMedDocument(res.data);
    }
    return res;
  };

  public createDocumentReq = async (params: CreateDocument) => {
    const res = await this._store.DocumentsApi.createDocument(params);
    if (res.success && res.data) {
      this.setMedDocument(res.data, true);
    }
    return res;
  };

  public updateDocumentReq = async (id: number, params: UpdateDocument) => {
    const res = await this._store.DocumentsApi.updateDocument(id, params);
    if (res.success && res.data) {
      this.updateMedDocument(res.data);
    }
    return res;
  };

  public closeDocumentReq = async (id: number) => {
    const res = await this._store.DocumentsApi.closeDocument(id);
    if (res.success && res.data) {
      this.updateMedDocument(res.data);
    }
    return res;
  };

  public getDocMacrosReq = async () => {
    const res = await this._store.DocumentsApi.getDocMacros();
    if (res.success && res.data) {
      this.setDocMacros(res.data);
    }
    return res;
  };

  public getAllDocumentsFinalizationReq = async (
    params: RefinalizationAllDocument,
  ) => {
    const res =
      await this._store.DocumentsApi.getAllDocumentsFinalization(params);
    if (res.success && res.data?.length) {
      res.data.forEach((doc) => {
        if (doc.status) this.deleteDocument(doc.id);
      });
    }
    return res;
  };

  public deleteDocumentReq = async (id: number) => {
    const res = await this._store.DocumentsApi.deleteDocument(id);
    if (res.success) {
      this.deleteDocument(id);
    }
    return res;
  };
}
