import React from 'react';
import NotifyBell from '@assets/img/notifications/bell.svg';
import OverlayPanel from '@components/UI/OverlayPanel';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import Avatar from '@components/UI/Avatar';
import { computed } from 'mobx';
import { Tooltip } from '@components/UI/Tooltip';
import { useTranslation } from 'react-i18next';
import './UserInfo.scss';

function UserInfo() {
  const { profile } = useService('ProfileService');
  const { goToRoute } = useService('RoutingService');
  const { t } = useTranslation('layout');

  const userName = computed(() => {
    if (!profile) return 'Guest';
    return `${profile.last_name} ${profile.first_name}`;
  }).get();

  return (
    <div className="user-info">
      <div className="divider" />

      <div className="details relative">
        <div className="info">
          <Avatar
            className="top-bar-user cursor-pointer"
            name={userName}
            onlyInitials
            onClick={() => goToRoute('/profile')}
          />
          <div className="wrap">
            <div
              className="name cursor-pointer"
              onClick={() => goToRoute('/profile')}
            >
              {userName}
            </div>
            <div className="role">{profile?.position?.title}</div>
          </div>
        </div>

        <OverlayPanel
          position="left"
          disabled
          trigger={(options) => (
            <div
              data-testid="notifyBtn"
              className="notify"
              onClick={options.toggle}
            >
              <Tooltip content={t('topbar_right.message')}>
                <img src={NotifyBell} alt="pic" />
              </Tooltip>
            </div>
          )}
          panel={() => (
            <div className="text-sidebar border border-coolGray-300 bg-white p-4 ">
              Some content
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default observer(UserInfo);
