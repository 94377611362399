import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUnblock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9 .667a8.333 8.333 0 1 0 0 16.666A8.333 8.333 0 0 0 9 .667m5 8.166a.75.75 0 0 1-.083.417l-1.75 4.083a1.12 1.12 0 0 1-1.084.667H6.5a1.667 1.667 0 0 1-1.667-1.667V8.167a1.07 1.07 0 0 1 .334-.834L9 3.167l.575.575a.9.9 0 0 1 .25.583v.167l-.483 2.841h3.825a.833.833 0 0 1 .833.834z"
      />
    </svg>
  );
};
export default SvgUnblock;
