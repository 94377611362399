import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEcgHeart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_29692_184"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_29692_184)">
        <path
          d="M13.4375 2.5C14.8263 2.5 15.9895 3.01389 16.927 4.04167C17.8645 5.06944 18.3333 6.29167 18.3333 7.70833C18.3333 7.95833 18.3194 8.20486 18.2916 8.44792C18.2638 8.69097 18.2152 8.93056 18.1458 9.16667H12.9375L11.5208 7.04167C11.4513 6.93056 11.3541 6.84028 11.2291 6.77083C11.1041 6.70139 10.9722 6.66667 10.8333 6.66667C10.6527 6.66667 10.4895 6.72222 10.3437 6.83333C10.1979 6.94444 10.0972 7.08333 10.0416 7.25L8.91663 10.625L8.18746 9.54167C8.11802 9.43056 8.02079 9.34028 7.89579 9.27083C7.77079 9.20139 7.63885 9.16667 7.49996 9.16667H1.85413C1.78468 8.93056 1.73607 8.69097 1.70829 8.44792C1.68051 8.20486 1.66663 7.96528 1.66663 7.72917C1.66663 6.29861 2.1319 5.06944 3.06246 4.04167C3.99302 3.01389 5.15274 2.5 6.54163 2.5C7.20829 2.5 7.83677 2.63194 8.42704 2.89583C9.01732 3.15972 9.54163 3.52778 9.99996 4C10.4444 3.52778 10.9618 3.15972 11.552 2.89583C12.1423 2.63194 12.7708 2.5 13.4375 2.5ZM9.99996 17.5C9.74996 17.5 9.51038 17.4549 9.28121 17.3646C9.05204 17.2743 8.84718 17.1389 8.66663 16.9583L3.08329 11.3542C2.99996 11.2708 2.92357 11.1875 2.85413 11.1042C2.78468 11.0208 2.71524 10.9306 2.64579 10.8333H7.04163L8.45829 12.9583C8.52774 13.0694 8.62496 13.1597 8.74996 13.2292C8.87496 13.2986 9.0069 13.3333 9.14579 13.3333C9.32635 13.3333 9.49302 13.2778 9.64579 13.1667C9.79857 13.0556 9.90274 12.9167 9.95829 12.75L11.0833 9.375L11.7916 10.4583C11.875 10.5694 11.9791 10.6597 12.1041 10.7292C12.2291 10.7986 12.3611 10.8333 12.5 10.8333H17.3333L16.9166 11.3333L11.3125 16.9583C11.1319 17.1389 10.9305 17.2743 10.7083 17.3646C10.4861 17.4549 10.25 17.5 9.99996 17.5Z"
          fill="#6B7280"
        />
      </g>
    </svg>
  );
};
export default SvgEcgHeart;
