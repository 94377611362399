import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowDownward = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="none"
      viewBox="0 0 14 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m13.667 7-1.175-1.175-4.659 4.65V.333H6.167v10.142l-4.65-4.658L.333 7 7 13.667z"
      />
    </svg>
  );
};
export default SvgArrowDownward;
