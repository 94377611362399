import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutlinelaboratory = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={19}
      fill="none"
      viewBox="0 0 14 19"
      role="img"
      {...props}
    >
      <path
        fill="#fff"
        d="M2 17c-1.1 0-2 .9-2 2h14c0-1.1-.9-2-2-2H8v-2h3c1.1 0 2-.9 2-2H5c-1.66 0-3-1.34-3-3 0-1.09.59-2.04 1.46-2.56C3.17 7.03 3 6.54 3 6c0-.21.04-.42.09-.62A5.01 5.01 0 0 0 0 10c0 2.76 2.24 5 5 5v2z"
      />
      <path
        fill="#fff"
        d="M5.56 3.51C6.91 3.54 8 4.64 8 6c0 .75-.33 1.41-.85 1.87l.59 1.62.94-.34.34.94 1.88-.68-.34-.94.94-.34L8.76.6l-.94.34L7.48 0 5.6.68l.34.94-.94.35z"
      />
      <path fill="#fff" d="M5.5 7.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
    </svg>
  );
};
export default SvgOutlinelaboratory;
