import { action, makeObservable, observable } from 'mobx';
import { KANBAN_COLUMNS } from '@modules/Administrator/consts';
import { KanbanEvent } from '@modules/Administrator/Kanban/mapper/KanbanEvent';
import sortBy from 'lodash/sortBy';

export class KanbanStore {
  @observable public columns = KANBAN_COLUMNS;

  constructor() {
    makeObservable(this);
  }

  @action
  public toggleColumn = (index: number) => {
    this.columns[index].isOpen = !this.columns[index].isOpen;
  };

  @action
  public clearAllEvents = () => {
    this.columns.forEach((column) => (column.events = []));
  };

  @action
  public setEvents = (events: AppointmentType[]) => {
    this.clearAllEvents();
    events.forEach((event) => {
      const column = this.columns.find((e) => e.status === event.status);
      if (column) {
        column.events = sortBy(
          [...column.events, new KanbanEvent(event)],
          'start_time',
        );
      }
    });
  };

  @action
  public changeStatus = (updatedEvent: AppointmentType) => {
    const columns = this.columns.filter(
      (e) => e.status !== updatedEvent.status,
    );
    // remove
    if (columns) {
      columns.forEach((col) => {
        if (col.events.find((e) => e.id === updatedEvent.id)) {
          col.events = col.events.filter((e) => e.id !== updatedEvent.id);
        }
      });
    }
    // add
    const column = this.columns.find((e) => e.status === updatedEvent.status);
    if (column) {
      if (column.events.map((e) => e.id).includes(updatedEvent.id)) return;
      column.events = sortBy(
        [...column.events, new KanbanEvent(updatedEvent, true)],
        'start_time',
      );
    }
  };

  @action
  public createEvent = (newEvent: AppointmentType) => {
    const column = this.columns.find((e) => e.status === newEvent.status);
    if (column) {
      column.events = sortBy(
        [...column.events, new KanbanEvent(newEvent)],
        'start_time',
      );
    }
  };

  @action
  public updateEvent = (updatedEvent: AppointmentType) => {
    this.columns.forEach((col) => {
      const event = col.events.find((e) => e.id === updatedEvent.id);
      if (event) {
        Object.assign(event, new KanbanEvent(updatedEvent));
      }
    });
  };

  @action
  public deleteEvent = (delEvent: AppointmentType) => {
    this.columns.forEach((col) => {
      if (col.events.map((e) => e.id).includes(delEvent.id)) {
        col.events = col.events.filter((e) => e.id !== delEvent.id);
      }
    });
  };
}
