import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowUpward = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="none"
      viewBox="0 0 14 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m.333 7 1.175 1.175 4.659-4.65v10.142h1.666V3.525l4.65 4.658L13.667 7 7 .333z"
      />
    </svg>
  );
};
export default SvgArrowUpward;
