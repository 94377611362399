import { ReferenceValuesStore } from './referent-values.store';

export class ReferenceValuesService extends ReferenceValuesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getReferenceValuesReq = async (query?: GetReferenceValuesQuery) => {
    const res = await this._store.ReferenceValuesApi.getReferentValues(query);

    if (res.success && res.data) {
      this.setReferentValues(res.data);
    }

    return res;
  };

  public getReferenceValueReq = async (id: number) => {
    const res = await this._store.ReferenceValuesApi.getReferentValue(id);

    if (res.success && res.data) {
      this.setReferentValue(res.data);
    }

    return res;
  };

  public createReferenceValueReq = async (params: CreateReferenceValue) => {
    const res =
      await this._store.ReferenceValuesApi.createReferentValue(params);

    if (res.success && res.data) {
      this.addReferentValue(res.data);
    }

    return res;
  };

  public updateReferenceValueReq = async (
    id: number,
    params: UpdateReferenceValueParams,
  ) => {
    const res = await this._store.ReferenceValuesApi.updateReferentValue(
      id,
      params,
    );

    if (res.success && res.data) {
      this.updateReferentValue(res.data);
    }

    return res;
  };

  public deleteReferenceValueReq = async (id: number) => {
    const res = await this._store.ReferenceValuesApi.deleteReferentValue(id);

    if (res.success && res.data) {
      this.removeReferentValue(id);
    }

    return res;
  };
}
