import { action, autorun, makeObservable, observable } from 'mobx';
import { LStorage } from '@modules/Storage';
import { jwtDecode } from 'jwt-decode';

export class AuthStore {
  protected _store: Store;
  @observable public accessToken = LStorage.getItem('TOKEN');
  @observable public refreshToken = LStorage.getItem('REFRESH_TOKEN');
  @observable public expiredDate = LStorage.getItem('EXP');
  @observable public isAuth = !!this.refreshToken;
  @observable public isFirstEntry = false;

  constructor(store: Store) {
    makeObservable(this);
    this._store = store;
    autorun(() => {
      LStorage.sync('TOKEN', this.accessToken);
      LStorage.sync('REFRESH_TOKEN', this.refreshToken);
      LStorage.sync('EXP', this.expiredDate);
    });
  }

  @action
  public setToken = (data: LoginRes) => {
    this.accessToken = data.access_token;
    this.refreshToken = data.refresh_token;
    this.expiredDate = data.exp;
    this._decodeToken(data.access_token);
  };

  @action
  public setNewToken = (data: RefreshTokenRes) => {
    this.accessToken = data.access_token;
    this.expiredDate = data.exp;
    this._decodeToken(data.access_token);
  };

  @action
  public setLogout = () => {
    this.accessToken = null;
    this.refreshToken = null;
    this.expiredDate = null;
    this.isAuth = false;
    this.isFirstEntry = false;
  };

  @action
  private _decodeToken = (token: string) => {
    const jwtData: JwtData = jwtDecode(token);
    this.isAuth = !!token;
    this.isFirstEntry = jwtData.isFirstEntry;
    this._store.ProfileService.setUserMeta(jwtData);
  };
}
