import { PreloadLazy } from '@router/utils';

export const WORKPLACE_ROUTES: PrivateRoute = {
  group: 'WORKPLACE',
  name: 'workplace',
  path: '/workplace',
  exact: false,
  sidebar: true,
  icon: 'OutlineMedicalServices',
  activeIcon: 'MedicalServices',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'workplace',
      path: '/workplace',
      exact: true,
      component: PreloadLazy(() => import('@views/Workplace')),
    },
    {
      name: 'workplace_desktop',
      path: '/workplace/desktop',
      ws: true,
      exact: false,
      component: PreloadLazy(() => import('@views/Workplace/Desktop')),
      imagePath: () => import('@assets/img/preview/users.svg'),
      bgColor: 'bg-blue-50',
    },
    {
      name: 'workplace_incomplete_docs',
      path: '/workplace/documents',
      exact: false,
      component: PreloadLazy(() => import('@views/Workplace/Documents')),
      imagePath: () => import('@assets/img/preview/iteraction_section.svg'),
    },
    {
      name: 'workplace_templates',
      path: '/workplace/user-patterns',
      exact: true,
      component: PreloadLazy(() => import('@views/Workplace/Templates')),
      imagePath: () => import('@assets/img/preview/medcard_type.svg'),
    },
    {
      name: 'workplace_template',
      path: '/workplace/user-patterns/:id',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Workplace/Template')),
    },
    {
      name: 'workplace_template',
      path: '/workplace/user-patterns/:id/print',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Workplace/Template/Print')),
    },
  ],
};
