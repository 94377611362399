import { FetchService } from '@modules/Api';

export class PayersApi extends FetchService {
  public getPayers = (query?: GetPayersQuery) => {
    return this.request<'PAYERS', Payer[]>('/payers', 'GET_PAYERS', {
      query,
    });
  };

  public getPayer = (id: number) => {
    return this.request<'PAYERS', Payer>('/payers', 'GET_PAYER', {
      pathParam: `/${id}`,
    });
  };

  public deletePayer = (id: number) => {
    return this.request<'PAYERS'>('/payers', 'DELETE_PAYER', {
      method: 'DELETE',
      pathParam: `/${id}`,
    });
  };

  public createPayer = (params: CreatePayerParams) => {
    return this.request<'PAYERS', Payer>('/payers', 'CREATE_PAYER', {
      method: 'POST',
      params,
    });
  };

  public updatePayer = (id: number, params: UpdatePayerParams) => {
    return this.request<'PAYERS', Payer>('/payers', 'UPDATE_PAYER', {
      method: 'PUT',
      params,
      pathParam: `${id}`,
    });
  };

  public activatePayer = (id: number) => {
    return this.request<'PAYERS', Payer>('/payers', 'ACTIVATE_PAYER', {
      method: 'PUT',
      pathParam: `${id}/activate`,
    });
  };

  public deactivatePayer = (id: number) => {
    return this.request<'PAYERS', Payer>('/payers', 'DEACTIVATE_PAYER', {
      method: 'PUT',
      pathParam: `${id}/deactivate`,
    });
  };

  public removePayerFromPrefCategory = (id: number) => {
    return this.request<'PAYERS', Payer>(
      '/payers',
      'REMOVE_PAYER_FROM_CATEGORY',
      {
        method: 'PUT',
        pathParam: `${id}/remove_from_pref_categories`,
      },
    );
  };
}
