import React, { ReactNode } from 'react';
import './Content.scss';

type Props = {
  children: ReactNode;
  pageNav: React.JSX.Element;
};

export default function Content({ children, pageNav }: Props) {
  return (
    <main className="app-content">
      {pageNav}
      <div className="page-content">{children}</div>
    </main>
  );
}
