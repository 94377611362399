import { AppStore } from '@modules/App/app.store';

export class AppService extends AppStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public init = async () => {
    await this._getInitSys();
    await this._getProfile();
    this.setInitialization(true);
  };

  public appInit = async () => {
    this.setInitialization(true, 'appInitialized');
  };

  private _getInitSys = async () => {
    if (this._store.AuthService.isAuth) {
      this.setInitialization(true, 'sysInitialized');
      return;
    }

    const res = await this._store.ApiService.checkInitSystem();
    this.setInitialization(res.success, 'sysInitialized');

    if (res.success && res.data) {
      this._store.CurrencyService.setCurrency(res.data.currencyName);
    }
    if (res.errors.length && res.errors.some((e) => e.status === 500)) {
      this.setServerError(true);
    }
  };

  private _getProfile = async () => {
    if (this._store.AuthService.isAuth) {
      await this._store.ProfileService.getProfileReq();
    }
  };
}
