import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMoney = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="#9CA3AF"
        d="M4.167 6.667h1.666v6.666H4.167zm5.833 0H7.5a.836.836 0 0 0-.833.833v5c0 .458.375.833.833.833H10a.836.836 0 0 0 .833-.833v-5A.836.836 0 0 0 10 6.667m-.834 5h-.833V8.333h.833zm5.834-5h-2.5a.836.836 0 0 0-.834.833v5c0 .458.375.833.834.833H15a.836.836 0 0 0 .833-.833v-5A.836.836 0 0 0 15 6.667m-.834 5h-.833V8.333h.833z"
      />
      <path
        fill="#9CA3AF"
        d="M1.667 3.333v13.334h16.666V3.333zM3.333 15V5h13.334v10z"
      />
    </svg>
  );
};
export default SvgMoney;
