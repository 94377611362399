import moment from 'moment-timezone';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/en-au';
import i18next from '../i18next';

import('dayjs/locale/uk');
import('dayjs/locale/ru');
import('dayjs/locale/en');

// moment.tz.setDefault('Europe/Kiev');
moment.locale(localStorage.getItem('i18nextLng') || 'uk');

dayjs.extend(duration);

i18next.on('languageChanged', (lng) => {
  moment.locale(lng);
  dayjs.locale(lng);
});
