import { PreloadLazy } from '@router/utils';

export const DESKTOP_ROUTES: PrivateRoute = {
  group: 'DESKTOP',
  name: 'desktop',
  path: '/',
  exact: true,
  preload: true,
  sidebar: true,
  icon: 'OutlineDashboard',
  activeIcon: 'Dashboard',
  component: PreloadLazy(() => import('@views/Dashboard')),
};
