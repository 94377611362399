import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGroups = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={10}
      fill="none"
      viewBox="0 0 20 10"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 5.625c1.358 0 2.558.325 3.533.75.9.4 1.467 1.3 1.467 2.275V10H5V8.658a2.47 2.47 0 0 1 1.467-2.275A8.7 8.7 0 0 1 10 5.625m-6.667.208C4.25 5.833 5 5.083 5 4.167 5 3.25 4.25 2.5 3.333 2.5c-.916 0-1.666.75-1.666 1.667 0 .916.75 1.666 1.666 1.666m.942.917a6 6 0 0 0-.942-.083c-.825 0-1.608.175-2.316.483A1.68 1.68 0 0 0 0 8.692V10h3.75V8.658c0-.691.192-1.341.525-1.908m12.392-.917c.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667C15.75 2.5 15 3.25 15 4.167c0 .916.75 1.666 1.667 1.666M20 8.692c0-.675-.4-1.275-1.017-1.542a5.8 5.8 0 0 0-2.316-.483c-.325 0-.634.033-.942.083a3.75 3.75 0 0 1 .525 1.908V10H20zM10 0c1.383 0 2.5 1.117 2.5 2.5S11.383 5 10 5a2.497 2.497 0 0 1-2.5-2.5C7.5 1.117 8.617 0 10 0"
      />
    </svg>
  );
};
export default SvgGroups;
