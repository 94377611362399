import { FetchService } from '@modules/Api';

export class UserTemplateApi extends FetchService {
  public getUserTemplates = (query?: GetUserTemplatesQuery) => {
    return this.request<'USER_TEMPLATES', UserDocTemplate[]>(
      '/user_patterns',
      'GET_USER_DOC_TEMPLATES',
      {
        query,
        permit: 'user_patterns',
      },
    );
  };

  public getUserTemplate = (id: number) => {
    return this.request<'USER_TEMPLATES', UserDocTemplate>(
      '/user_patterns',
      'GET_USER_DOC_TEMPLATE',
      {
        permit: 'user_patterns',
        pathParam: id,
      },
    );
  };

  public createUserTemplate = (params: CreateUserTemplateParams) => {
    return this.request<
      'USER_TEMPLATES',
      UserDocTemplate,
      CreateUserTemplateParams
    >('/user_patterns', 'CREATE_USER_DOC_TEMPLATE', {
      method: 'POST',
      params,
      permit: 'user_patterns',
    });
  };

  public updateUserTemplate = (id: number, params: UpdateUserTemplate) => {
    return this.request<'USER_TEMPLATES', UserDocTemplate, UpdateUserTemplate>(
      '/user_patterns',
      'UPDATE_USER_DOC_TEMPLATE',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'user_patterns',
      },
    );
  };

  public deleteUserDocument = (id: number) => {
    return this.request<'USER_TEMPLATES'>(
      '/user_patterns',
      'DELETE_USER_DOC_TEMPLATE',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'user_patterns',
      },
    );
  };
}
