import React, { createElement, Suspense } from 'react';
import { Components } from '@components/UI/Drawer/registration';
import { Text } from '@components/UI/Text';
import { ContentLoader } from '@components/UI/ContentLoader';
import ErrorBoundary from '@components/UI/ErrorBoundary';

/**
 * https://codesandbox.io/s/dynamic-components-from-json-with-react-ybq27?from-embed=&file=/src/index.js
 */
export default function DrawerComponent(props: DrawerProps<never>) {
  if (props.component && typeof Components[props.component] !== 'undefined') {
    return (
      <Suspense fallback={<ContentLoader />}>
        <ErrorBoundary>
          {createElement(Components[props.component] as any, props)}
        </ErrorBoundary>
      </Suspense>
    );
  }
  return (
    <Text type="danger" tag="blockquote">
      Error created component: {props.component}
    </Text>
  );
}
