import { CashOperationStore } from './cash-operation.store';

export class CashOperationService extends CashOperationStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getCashOperationOrdersReq = async (query?: GetCashOperationQuery) => {
    const res = await this._store.CashPlaceApi.getCashOrders(query);
    if (res.success && res.data) {
      this.setCashOrders(res.data, res.meta);
    }
    return res;
  };

  public downloadCashOperationOrderReportReq = (id: number) => {
    return this._store.CashPlaceApi.downloadCashOperationOrder(id);
  };
}
