import { CompTemplateStore } from './CompTemplate.store';

export class CompTemplateService extends CompTemplateStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getCompDocTemplatesReq = async (query?: GetDocumentsQuery) => {
    const res = await this._store.DocTemplateApi.getCompDocTemplates(query);
    if (res.success && res.data) {
      this.setCompDocTemplates(res.data, res.meta);
    }
    return res;
  };

  public getCompTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getCompTemplate(id);
    if (res.success && res.data) {
      this.setCompDocTemplate(res.data);
    }
    return res;
  };

  public getCurrentCompTemplateReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getCurrentCompTemplate(id);
    if (res.success && res.data) {
      this.setCurrentDocTemplate(res.data);
    }
    return res;
  };

  public createDocumentReq = async (params: CreateCompDocTemplate) => {
    const res = await this._store.DocTemplateApi.createCompTemplate(params);
    if (res.success && res.data) {
      this.setCompDocTemplate(res.data, true);
    }
    return res;
  };
}
