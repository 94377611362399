import { FetchService } from '@modules/Api';

export class PhoneCodesApi extends FetchService {
  public getPhoneCodes = (query?: GetPhoneCodesQuery) => {
    return this.request<'PHONE_CODES', PhoneCode[]>(
      '/phone_code',
      'GET_PHONE_CODES',
      {
        query,
      },
    );
  };
}
