import { ConstructPhoneArgs, Name } from '@modules/String/typings';
import isString from 'lodash/isString';

class StringService {
  public getNameInitials = (name: string): string => {
    if (!name || !isString(name)) return '';
    return (
      name
        // split string into array of strings (example: "fiRsT reSPoNsE AcAdEmY" ==> ["fiRsT", "reSPoNsE", "AcAdEmY"])
        .split(' ')
        // map over words and return a capitalized first letter of each word (example: ["fiRsT", "reSPoNsE", "AcAdEmY"] ==> ["F", "R", "A"])
        .map((c) => c.charAt(0).toUpperCase())
        // join letters to single string (example: ["F", "R", "A"] ==> "FRA")
        .join('')
        // append second letter of first word to this new string (example: "FRA" ==> "FRAI")
        .concat(name.charAt(1).toUpperCase())
        // limit this new string to 2 characters (example: "FRAI" ==> "FR")
        .substring(0, 2)
    );
  };

  public cutString = (string: string, limit = 38) => {
    return string?.length > limit
      ? string.substring(0, limit - 3) + '...'
      : string;
  };

  public upFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  private _short = ({
    first_name = '',
    last_name = '',
    patronymic = '',
  }: Name) => {
    return [last_name, first_name, patronymic]
      .filter(Boolean)
      .map((name, index) => (index === 0 ? name : `${name[0]}.`))
      .join(' ');
  };

  private _full = ({
    first_name = '',
    last_name = '',
    patronymic = '',
  }: Name) => {
    return [last_name, first_name, patronymic].filter(Boolean).join(' ');
  };

  public patientShortName = <T extends Name>(patient: T | null) => {
    if (!patient) return '';
    return this._short(patient);
  };

  public patientFullName = <T extends Name>(patient?: T | null) => {
    if (!patient) return '';
    return this._full(patient);
  };

  public userShortName = <T extends Name>(user?: T | null) => {
    if (!user) return '';
    return this._short(user);
  };

  public userFullName = <T extends Name>(user?: T | null) => {
    if (!user) return '';
    return this._full(user);
  };

  public downloadFile = ({ file, filename }: BlobResponse) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    link.remove();
  };

  public cutTel = (value: string | number = ''): string => {
    const phone = value.toString();
    const maxLength = phone.startsWith('0') ? 10 : 9;

    return phone.length > maxLength ? phone.substring(0, maxLength) : phone;
  };

  public constructTel = ({ code, phone }: ConstructPhoneArgs) => {
    if (!code && !phone) return null;
    const values = [];
    if (code) {
      values.push('+');
      if (typeof code === 'number') {
        values.push(code);
      } else {
        values.push(code.phonecode);
      }
    }
    if (phone) values.push(phone);
    return values.join('');
  };
}

export default new StringService();
