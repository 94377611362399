import LabAppointmentsStore from './lab-appointment.store';

export default class LabAppointmentsService extends LabAppointmentsStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public subscribe = () => {
    this._store.WsService.on(
      'appointments.get_list',
      this._getAppointmentsSetter,
    );

    this._store.WsService.on('appointments.get', this._getAppointmentSetter);

    this._store.WsService.on(
      'appointments.create',
      this._createAppointmentSetter,
    );

    this._store.WsService.on(
      'appointments.update',
      this._updateAppointmentSetter,
    );

    this._store.WsService.on(
      'appointments.change_status',
      this._changeStatusAppointmentSetter,
    );
  };

  public unsubscribe = () => {
    this._store.WsService.off(
      'appointments.get_list',
      this._getAppointmentsSetter,
    );

    this._store.WsService.off('appointments.get', this._getAppointmentSetter);

    this._store.WsService.off(
      'appointments.create',
      this._createAppointmentSetter,
    );

    this._store.WsService.off(
      'appointments.update',
      this._updateAppointmentSetter,
    );

    this._store.WsService.off(
      'appointments.change_status',
      this._changeStatusAppointmentSetter,
    );
  };

  //
  public getAppointmentsRequest = (query?: GetAppointmentsReq) => {
    this._store.WsApi.appointments.getAppointments(query);
  };

  private _getAppointmentsSetter = ({
    result,
  }: WsResponse<AppointmentType[]>) => {
    if (result.success && result.data) {
      this.setAppointments(result.data, result.meta);
    }
  };
  //

  //
  public getAppointmentRequest = (id: number) => {
    this._store.WsApi.appointments.getAppointment(id);
  };

  private _getAppointmentSetter = ({ result }: WsResponse<AppointmentType>) => {
    if (result.success && result.data) {
      this.setAppointment(result.data);
    }
  };
  //

  //
  public updateAppointmentRequest = (data: UpdateAppointmentReq) => {
    this._store.WsApi.appointments.updateAppointment(data);
  };

  private _updateAppointmentSetter = ({
    result,
  }: WsResponse<AppointmentType>) => {
    if (result.success && result.data) {
      this.updateAppointment(result.data);
    }
  };
  //

  //
  public createAppointmentRequest = (
    data: CreateAppointmentReq,
    storage: number,
  ) => {
    this._store.WsApi.appointments.createAppointment(data, storage);
  };

  private _createAppointmentSetter = ({
    result,
  }: WsResponse<AppointmentType>) => {
    if (result.success && result.data) {
      this.addAppointment(result.data);
    }
  };
  //

  //
  public changeStatusAppointmentRequest = (
    data: ChangeStatusAppointmentReq,
    storage: number,
  ) => {
    this._store.WsApi.appointments.changeStatusAppointment(data, storage);
  };

  private _changeStatusAppointmentSetter = ({
    result,
  }: WsResponse<AppointmentType>) => {
    if (result.success && result.data) {
      this.updateAppointment(result.data);
    }
  };
  //
}
