export class CalendarApi {
  public _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public getCalendarTable = (params: GetCalendarTableReq) => {
    this._store.WsService.send(
      { method: 'calendar.get_calendar_table', params },
      'GET_CALENDAR',
    );
  };

  public subscribeCalendar = () => {
    this._store.WsService.send(
      { method: 'calendar.subscribe' },
      'CALENDAR_SUBSCRIBE',
    );
  };

  public unsubscribeCalendar = () => {
    this._store.WsService.send(
      { method: 'calendar.unsubscribe' },
      'CALENDAR_UNSUBSCRIBE',
    );
  };
}
