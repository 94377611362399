import { LStorage } from '@modules/Storage';
import { action, computed, makeObservable, observable } from 'mobx';
import { UIModuleMapper } from './mapper/UIModuleMapper';

export class InitSystemStore {
  protected _store: Store;

  @observable public password = '';
  @observable public organization: OrganizationInfo = {
    currency: '',
    name: '',
  };

  @observable public selectedEntities: string[] = [];
  @observable public selectedUIModules: string[] = [];
  @observable private _entities: InitEntities = {
    en: [],
    ua: [],
  };
  @observable private _uiModules: InitUIModules = {
    en: [],
    ua: [],
  };

  constructor(store: Store) {
    this._store = store;
    this.selectedEntities = LStorage.getItem<string[]>('ENTITIES', true) || [];
    this.selectedUIModules =
      LStorage.getItem<string[]>('UI_MODULES', true) || [];

    const org = LStorage.getItem<OrganizationInfo>('REGISTER_INFO', true);

    if (org) {
      this.organization = org;
    }

    makeObservable(this);
  }

  @computed
  public get uiModules() {
    const uiModules = this._uiModules.ua;

    return {
      modulesTree: uiModules,
      modulesCollection: uiModules.reduce(
        (a, c) => [...a, c, ...c.allChildren],
        [] as UIModuleMapper[],
      ),
    };
  }

  @computed
  public get entities() {
    return this._entities.ua;
  }

  @computed
  public get firstStepValid() {
    return (
      Boolean(this.organization.currency.length) &&
      Boolean(this.organization.name.length) &&
      Boolean(this.password.length)
    );
  }

  @computed
  public get secondStepValid() {
    return Boolean(this.selectedEntities.length);
  }

  @computed
  public get thirdStepValid() {
    return Boolean(this.selectedUIModules.length);
  }

  @action
  public setUiModules = (modules: InitUIModules) => {
    this._uiModules = {
      ua: modules.ua.map((module) => new UIModuleMapper(module)),
      en: [],
      // en: modules.en.map((module) => new UIModuleMapper(module)),
    };
  };

  @action
  public syncUIModules = () => {
    this.selectedUIModules = this.uiModules.modulesCollection
      .filter((m) => m.isSelected)
      .map((m) => m.name);
  };

  @action
  public setEntities = (entities: InitEntities) => {
    this._entities = entities;
  };

  @action
  public addEntity = (sys_name: string) => {
    this.selectedEntities.push(sys_name);
  };

  @action
  public addAllEntities = () => {
    this.selectedEntities = this.entities.map((e) => e.sys_name);
  };

  @action
  public removeEntity = (sys_name: string) => {
    this.selectedEntities = this.selectedEntities.filter((s) => s !== sys_name);
  };

  @action
  public removeAllEntities = () => {
    this.selectedEntities = [];
  };

  @action
  public toggleEntity = (sys_name: string) => {
    this.selectedEntities.includes(sys_name)
      ? this.removeEntity(sys_name)
      : this.addEntity(sys_name);
  };

  @action
  public toggleAllEntities = () => {
    if (this.selectedEntities.length === this.entities.length) {
      this.removeAllEntities();
      return;
    }
    this.addAllEntities();
  };

  @action
  public setPassword = (value: string) => {
    this.password = value;
  };

  @action
  public setOrganization = (value: OrganizationInfo) => {
    this.organization = value;
  };

  @action
  public clearData = () => {
    this.setOrganization({ currency: '', name: '' });
    this.setPassword('');

    this.uiModules.modulesCollection.forEach((m) => m.uncheck());
    this.syncUIModules();

    this.removeAllEntities();
  };
}
