import { FetchService } from '@modules/Api';

export class CounterpartyApi extends FetchService {
  public getCounterparties = (query?: GetCounterpartiesQuery) => {
    return this.request<
      'COUNTERPARTY',
      Counterparty[],
      null,
      GetCounterpartiesQuery
    >('/counterparty', 'GET_COUNTERPARTIES', { query });
  };

  public getCounterparty = (id: number) => {
    return this.request<'COUNTERPARTY', Counterparty>(
      '/counterparty',
      'GET_COUNTERPARTY',
      { pathParam: `${id}` },
    );
  };

  public createCounterparty = (params: CreateCounterpartyParams) => {
    return this.request<'COUNTERPARTY', Counterparty>(
      '/counterparty',
      'CREATE_COUNTERPARTY',
      { method: 'POST', params },
    );
  };

  public updateCounterparty = (
    id: number,
    params: UpdateCounterpartyParams,
  ) => {
    return this.request<'COUNTERPARTY', Counterparty>(
      '/counterparty',
      'UPDATE_COUNTERPARTY',
      { method: 'PUT', pathParam: `${id}`, params },
    );
  };

  public activateCounterparty = (id: number) => {
    return this.request<'COUNTERPARTY', Counterparty>(
      '/counterparty',
      'ACTIVATE_COUNTERPARTY',
      { method: 'PUT', pathParam: `${id}/activate` },
    );
  };

  public deactivateCounterparty = (id: number) => {
    return this.request<'COUNTERPARTY', Counterparty>(
      '/counterparty',
      'DEACTIVATE_COUNTERPARTY',
      { method: 'PUT', pathParam: `${id}/deactivate` },
    );
  };
}
