import { action, computed, makeObservable, observable } from 'mobx';
import { Candidate } from './factory/Candidate';
import { MAX_APPOINTMENTS } from './consts';
import { availableGroupId } from './utils';
import { PackageFactory } from './factory/PackageFactory';

export class CalendarStore {
  @observable public isOpenSidebar = true;
  @observable public isReplanning = false;
  @observable public candidates: Candidate[] = [];
  @observable public directions: DocDirection[] = [];
  @observable public guarantees: Guarantee[] = [];
  @observable public packages: PackageFactory[] = [];
  @observable public patient: Patient | AppointmentPatient | null = null;
  @observable public replanEvent: CalendarMappedEvent | null = null;

  constructor(protected _store: Store) {
    makeObservable(this);
  }

  @computed
  public get groupIds() {
    return new Array(MAX_APPOINTMENTS).fill(1).map((_, i) => i);
  }

  @computed
  public get canAddCandidate() {
    if (this.isReplanning) {
      return !this.candidates.length;
    }

    return this.candidates.length < MAX_APPOINTMENTS;
  }

  @computed
  public get candidateQuery() {
    const candidates = this.candidates
      .filter((candidate) => candidate.duration)
      .filter((candidate) => candidate.candidateServices.length)
      .map((candidate) => ({
        duration_time: candidate.duration,
        group_id: candidate.groupId,
        service_ids: candidate.candidateServices,
      }));

    if (!candidates.length) return null;

    return candidates;
  }

  @computed
  public get guaranteesByService() {
    const guaranteeService = this._store.InsuranceService.guarantee;

    const guaranteesByService = guaranteeService.groupedGuaranteeByService(
      this.guarantees,
    );

    return new Map(
      guaranteesByService.map((data) => [data.service.id, data.guarantees]),
    );
  }

  @computed
  public get candidatesData() {
    const data: Array<{
      candidate: Candidate;
      guarantees: Map<number, Guarantee[][]>;
      directions: DocDirection[];
    }> = [];

    const availableDirections = Array.from(this.directions);

    const findDirection = (service: CandidateService) => {
      const directions: DocDirection[] = [];

      for (const direction of availableDirections) {
        if (direction.service.id === service.id) {
          const idx = availableDirections.indexOf(direction);
          directions.push(...availableDirections.splice(idx, 1));
          break;
        }
      }

      return directions;
    };

    const findGuarantee = (service: CandidateService) => {
      const guaranteeService = this._store.InsuranceService.guarantee;

      return guaranteeService.groupGuaranteeByValues(
        this.guaranteesByService.get(service.id) ?? [],
      );
    };

    for (const candidate of this.candidates) {
      const candidateGuarantees = new Map<number, Guarantee[][]>();
      const candidateDirections = [];

      for (const service of candidate.allServices) {
        // Guarantee should not apply to package service
        if (!service.servicePackageId) {
          candidateGuarantees.set(service.id, findGuarantee(service));
        }

        if (!service.directionId && !service.referralDoctorId) {
          const directions = findDirection(service);
          candidateDirections.push(...directions);
        }
      }

      data.push({
        candidate,
        guarantees: candidateGuarantees,
        directions: candidateDirections,
      });
    }

    return data;
  }

  @computed
  public get directionsWithGuarantee() {
    const data: Array<{
      direction: DocDirection;
      guarantees: Guarantee[][];
    }> = [];

    for (const direction of this.directions) {
      const guarantees = this.guaranteesByService.get(direction.service.id);

      data.push({
        direction,
        guarantees:
          this._store.InsuranceService.guarantee.groupGuaranteeByValues(
            guarantees ?? [],
          ),
      });
    }

    return data;
  }

  @action
  public setReplanEvent = (event: CalendarMappedEvent | null) => {
    this.replanEvent = event;
    if (!event) this._store.RoutingService.removeQuery('replan_id');
  };

  @action
  public createCandidate = (appointment?: CandidateAppointment) => {
    const groupId = availableGroupId(this.candidates);

    const defaultAppointment = {
      duration: 0,
      originDuration: 0,
      services: [],
    };

    const candidate = new Candidate(
      appointment
        ? { ...appointment, groupId }
        : { ...defaultAppointment, groupId },
    );

    this.candidates.push(candidate);
  };

  @action
  public deleteCandidate = (groupId: number) => {
    this.candidates = this.candidates.filter((c) => c.groupId !== groupId);
  };

  @action
  public deleteAllCandidates = () => {
    this.candidates = [];
  };

  @action
  public setDirections = (directions: DocDirection[]) => {
    this.directions = directions;
  };

  @action
  public setPackages = (data: PatientPkgService[]) => {
    this.packages = data
      .map((pkg) => new PackageFactory({ pkg }))
      .filter((pkg) => pkg.clinicServices.length);
  };

  @action
  public setGuarantees = (data: Guarantee[]) => {
    this.guarantees = data;
  };

  @action
  public addGuarantees = (data: Guarantee[]) => {
    this.guarantees = this.guarantees.concat(data);
  };

  @action
  public setPatient = (patient: Patient | AppointmentPatient | null) => {
    this.directions = [];
    this.guarantees = [];
    this.packages = [];

    this.candidates.forEach((candidate) => {
      candidate.clearDirections();
      candidate.clearPackageServices();
    });

    this.patient = patient;
  };

  @action
  public setOpenSidebar = (open: boolean, toggle?: boolean) => {
    if (toggle) {
      this.isOpenSidebar = !this.isOpenSidebar;
      return;
    }
    this.isOpenSidebar = open;
  };

  @action
  public setIsReplanning = (value: boolean) => {
    this.isReplanning = value;
  };

  @action
  public clear = () => {
    this.candidates = [];
    this.directions = [];
    this.guarantees = [];
    this.packages = [];
    this.isReplanning = false;
  };
}
