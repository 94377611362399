import { action, makeObservable, observable } from 'mobx';

export class PreferentialCategoriesStore {
  @observable public preferentialCategories: PreferentialCategory[] = [];
  @observable public preferentialCategory?: PreferentialCategory;
  @observable public patientsForCategory: Patient[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setPreferentialCategories = (data: PreferentialCategory[]) => {
    this.preferentialCategories = data;
  };

  @action
  public setPreferentialCategory = (data?: PreferentialCategory) => {
    this.preferentialCategory = data;
  };

  @action
  public patientsPreferentialCategory = (data: Patient[]) => {
    this.patientsForCategory = data;
  };

  @action
  public updatePreferentialCategory = (cat: PreferentialCategory) => {
    const category = this.preferentialCategories.find((e) => e.id === cat.id);

    if (category) {
      Object.assign(category, cat);
    }

    if (this.preferentialCategory && this.preferentialCategory.id === cat.id) {
      this.setPreferentialCategory(cat);
    }
  };

  @action
  public addPreferentialCategory = (cat: PreferentialCategory) => {
    this.preferentialCategories = [cat, ...this.preferentialCategories];
  };

  @action
  public deletePreferentialCategory = (id: number) => {
    this.preferentialCategories = this.preferentialCategories.filter(
      (e) => e.id !== id,
    );
  };
}
