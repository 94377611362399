import { LStorage } from '@modules/Storage';
import { action, computed, observable, makeObservable, autorun } from 'mobx';

type CashPlaceSelectType = SelectType<number | string> | null;

class CashPlaceFilterState {
  @observable private _cashPlaceSelected: CashPlaceSelectType = null;

  constructor() {
    makeObservable(this);
    this._setInitial();

    autorun(() => {
      LStorage.setItem('CASH_PLACE_FILTER', this._cashPlaceSelected);
    });
  }

  @computed
  public get cp() {
    return this._cashPlaceSelected;
  }

  @action
  private _setInitial = () => {
    const storage_cp = LStorage.getItem<CashPlaceSelectType>(
      'CASH_PLACE_FILTER',
      true,
    );

    if (storage_cp) this.setCashPlaceSelected(storage_cp);
  };

  @action
  public setCashPlaceSelected = (data: CashPlaceSelectType) => {
    this._cashPlaceSelected = data;
  };
}

export default new CashPlaceFilterState();
