import React from 'react';
import cn from 'classnames';
import OverlayPanel, { OverlayOptions } from '@components/UI/OverlayPanel';
import { Button } from '@components/UI/Button';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/UI/Tooltip';
import { langOptions, LangOption } from './consts';
import './LanguageSwitcher.scss';

const Trigger = ({ options }: { options: OverlayOptions }) => {
  const { t, i18n } = useTranslation('layout');

  return (
    <Tooltip content={t('topbar_right.lang_selection')} position="left">
      <Button endIcon="ArrowDropDown" onClick={options.toggle}>
        {langOptions.find((e) => e.code === i18n.language)?.labelShot}
      </Button>
    </Tooltip>
  );
};

const Panel = ({ options }: { options: OverlayOptions }) => {
  const { i18n } = useTranslation();

  function onChange(lang: LangOption) {
    options.close();
    i18n.changeLanguage(lang.code);
  }

  return (
    <ul
      data-testid="lng-switcher-list"
      className="lang-list bg-white text-black"
    >
      {langOptions.map((lang) => {
        return (
          <li
            onClick={() => onChange(lang)}
            className={cn(['lang-item'], {
              active: lang.code === i18n.language,
            })}
            key={lang.code}
            data-testid="lng-switcher-list-item"
          >
            {lang.label}
          </li>
        );
      })}
    </ul>
  );
};

export default function LanguageSwitcher() {
  return (
    <div data-testid="lng-switcher" className="flex items-center">
      <div className="rm-lang-switcher">
        <OverlayPanel
          trigger={(options) => <Trigger options={options} />}
          panel={(options) => <Panel options={options} />}
        />
      </div>
      <div className="devider" />
    </div>
  );
}
