import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSecurityConfirmed = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={14}
      fill="none"
      viewBox="0 0 12 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6 .333-5.333 2v4.06C.667 9.76 2.94 12.9 6 13.667c3.06-.767 5.333-3.907 5.333-7.274v-4.06zM5.293 9.36 2.933 7l.94-.94 1.414 1.413 2.826-2.826.94.94z"
      />
    </svg>
  );
};
export default SvgSecurityConfirmed;
