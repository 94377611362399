import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHistory = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.833 2.5a7.5 7.5 0 0 0-7.5 7.5h-2.5l3.242 3.242.058.116L7.5 10H5a5.83 5.83 0 0 1 5.833-5.833A5.83 5.83 0 0 1 16.667 10a5.83 5.83 0 0 1-5.834 5.833 5.79 5.79 0 0 1-4.116-1.716L5.533 15.3a7.46 7.46 0 0 0 5.3 2.2 7.5 7.5 0 0 0 0-15M10 6.667v4.166l3.567 2.117.6-1.008-2.917-1.734V6.667z"
      />
    </svg>
  );
};
export default SvgHistory;
