import { PreloadLazy } from '@router/utils';

export const CASHPLACE_ROUTES: PrivateRoute = {
  group: 'CASHPLACE',
  name: 'cashplace',
  path: '/cashplace',
  exact: false,
  sidebar: true,
  icon: 'OutlinePayments',
  activeIcon: 'Payments',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'cashplace',
      path: '/cashplace',
      exact: true,
      component: PreloadLazy(() => import('@views/Cashplace')),
    },
    {
      name: 'cashplace_desktop',
      path: '/cashplace/desktop',
      ws: true,
      exact: false,
      component: PreloadLazy(() => import('@views/Cashplace/CashplaceDesktop')),
      imagePath: () => import('@assets/img/preview/cashplace.svg'),
      bgColor: 'bg-blue-50',
    },
    {
      name: 'cashplace_balance',
      path: '/cashplace/balance',
      exact: true,
      component: PreloadLazy(() => import('@views/Cashplace/Balance')),
      imagePath: () => import('@assets/img/preview/prices.svg'),
    },
    {
      name: 'cashplace_refund',
      path: '/cashplace/refund',
      exact: true,
      component: PreloadLazy(() => import('@views/Cashplace/Refund')),
      imagePath: () => import('@assets/img/preview/financial_report.svg'),
    },
    {
      group: 'CASHPLACE_REPORTS',
      name: 'cashplace_reports',
      path: '/cashplace/reports',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/settings_mis.svg'),
      routes: [
        {
          name: 'cashplace_reports',
          path: '/cashplace/reports',
          exact: true,
          component: PreloadLazy(() => import('@views/Cashplace/Reports')),
        },
        {
          name: 'cashplace_reports_payments',
          path: '/cashplace/reports/payments',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Payments'),
          ),
          imagePath: () => import('@assets/img/preview/financial_report.svg'),
        },
        // {
        //   name: 'cashplace_reports_payments',
        //   path: '/cashplace/reports/payments/:id',
        //   exact: true,
        //   hidden: true,
        //   ws: true,
        //   component: PreloadLazy(
        //     () => import('@views/Reports/Financial/Order'),
        //   ),
        // },
        {
          name: 'cashplace_reports_orders',
          path: '/cashplace/reports/orders',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Orders'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
        // !!! disabled until redesign of the backend
        // {
        //   name: 'provided_services_report',
        //   path: '/cashplace/reports/services',
        //   exact: true,
        //   component: PreloadLazy(
        //     () => import('@views/Reports/Financial/Services'),
        //   ),
        //   imagePath: () => import('@assets/img/preview/financial_report.svg'),
        // },

        // {
        //   name: 'cashplace_reports_orders',
        //   path: '/cashplace/reports/orders/:id',
        //   exact: true,
        //   hidden: true,
        //   ws: true,
        //   component: PreloadLazy(
        //     () => import('@views/Reports/Financial/Order'),
        //   ),
        // },

        {
          name: 'cashplace_reports_operations',
          path: '/cashplace/reports/operations',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Operations'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
        {
          name: 'cash_operation',
          path: '/cashplace/reports/cash',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/CashOperations'),
          ),
          imagePath: () => import('@assets/img/preview/security.png'),
        },
      ],
    },
    {
      name: 'cashplace_payments',
      path: '/cashplace/payments',
      exact: true,
      component: PreloadLazy(() => import('@views/Cashplace/Payments')),
      imagePath: () => import('@assets/img/preview/financial_report.svg'),
    },
  ],
};
