import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExcelWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m3.359 2.877 12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L3.358 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .86-.99M17.5 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4zm-6.8 9 2.8-4h-2.4l-1.6 2.286L7.9 8H5.5l2.8 4-2.8 4h2.4l1.6-2.286L11.1 16h2.4z"
      />
    </svg>
  );
};
export default SvgExcelWhite;
