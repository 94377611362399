import { DoctorWorkplaceState } from './doctor-workplace.state';
import { DoctorWorkplaceUtils } from './doctor-workplace.utils';
import { DoctorWorkplaceApi } from './doctor-workplace.api';
import { UnregisterCallback } from 'history';
import { QueryParams } from '@modules/DoctorWorkplace/typings';

export class DoctorWorkplaceService {
  private _store: Store;
  private _unsubscribeHistory?: UnregisterCallback;

  public readonly debug = false;
  public readonly state: DoctorWorkplaceState;
  public readonly utils: DoctorWorkplaceUtils;
  public readonly api: DoctorWorkplaceApi;

  constructor(store: Store) {
    this._store = store;
    this.state = new DoctorWorkplaceState(store);
    this.utils = new DoctorWorkplaceUtils(store);
    this.api = new DoctorWorkplaceApi(store);
  }

  public init = () => {
    this.state.setInitLoading(true);
    this._subscribeWs();

    this._getStarted()
      .then(() => {
        this._subscribeHistory();
      })
      .finally(() => {
        this.state.setInitLoading(false);
      });
  };

  public unMount = () => {
    this.state.reset();
    this._unsubscribeWs();
    this._unsubscribeHistory?.();
  };

  private _subscribeWs = () => {
    const on = this._store.WsService.on;
    on('appointments.change_status', this._changeStatusRes);
    on('appointments.create', this._createAppointmentRes);
    on('appointments.update', this._updateAppointmentRes);
    on('appointments.delete', this._deleteAppointmentRes);
    on('appointments.replace_service', this._updateAppointmentRes);
    on('appointments.add_or_remove_service', this._updateAppointmentRes);
    on('appointments.update_service_count', this._updateAppointmentRes);
    on('appointments.reset_referring_doctor', this._updateAppointmentRes);
    on('appointments.unsubscribe', this._unsubscribeWs);
  };

  private _unsubscribeWs = () => {
    const off = this._store.WsService.off;
    off('appointments.change_status', this._changeStatusRes);
    off('appointments.create', this._createAppointmentRes);
    off('appointments.update', this._updateAppointmentRes);
    off('appointments.delete', this._deleteAppointmentRes);
    off('appointments.replace_service', this._updateAppointmentRes);
    off('appointments.add_or_remove_service', this._updateAppointmentRes);
    off('appointments.update_service_count', this._updateAppointmentRes);
    off('appointments.reset_referring_doctor', this._updateAppointmentRes);
    off('appointments.unsubscribe', this._unsubscribeWs);
  };

  private _changeStatusRes = ({
    result,
    error,
  }: WsResponse<AppointmentType>) => {
    if (!result.success) this._store.ModalService.modal.wsError(error);
    if (result.success && result.data.appointment_type === 'outpatient') {
      this.state.changeEventStatus(result.data);
    }
  };

  private _createAppointmentRes = ({
    result,
    error,
  }: WsResponse<AppointmentType>) => {
    if (!result.success) this._store.ModalService.modal.wsError(error);
    if (result.success && result.data.appointment_type === 'outpatient') {
      this.state.addEvent(result.data);
    }
  };

  private _updateAppointmentRes = ({
    result,
    error,
  }: WsResponse<AppointmentType>) => {
    if (!result.success) this._store.ModalService.modal.wsError(error);
    if (result.success && result.data.appointment_type === 'outpatient') {
      this.state.updateEvent(result.data);
    }
  };

  private _deleteAppointmentRes = ({
    result,
    error,
  }: WsResponse<AppointmentType>) => {
    if (!result.success) this._store.ModalService.modal.wsError(error);
    if (result.success && result.data.appointment_type === 'outpatient') {
      this.state.removeEvent(result.data.id);
    }
  };

  /*
   |----------------------------------------------------------------------------
   | LOGIC...
   |----------------------------------------------------------------------------
   */
  private _getStarted = async () => {
    const queryObj = this._store.RoutingService.getQuery<QueryParams>();
    const eventId = Number(queryObj?.appointmentId);
    if (!queryObj.date) this.addQueryParam(this.state.defaultDate);
    if (eventId && !isNaN(eventId)) await this._getSelectedEventReq(eventId);
    await this._getAllEventsReq();
  };

  private _getAllEventsReq = async () => {
    const { result, error } = await this.api.getAppointmentsReq(
      this.state._eventsQuery,
    );
    if (result.success) this.state.setEvents(result.data);
    if (error) this._store.ModalService.modal.wsError(error);
  };

  private _getSelectedEventReq = async (id: number) => {
    const { result, error } = await this.api.getAppointmentReq(id);
    if (error) this._store.ModalService.modal.wsError(error);
    this.state.setSelectedEvent(result.data);
    if (
      this.state.selectedEvent &&
      this.state.selectedEvent.visit.status === 'active_visit'
    ) {
      await this._setActiveEventReq(this.state.selectedEvent);
    }
  };

  private _setActiveEventReq = async (event: DoctorWorkplace.VisitEvent) => {
    const { isToday } = this._store.DateService;
    if (!event.visit.patient) return;
    if (event.visit.status !== 'active_visit' && !isToday(event.visit.date)) {
      return;
    }

    const res = await this._getPatientEpisodesReq(event.visit.patient.id);
    if (!res) return;
    this.state.setActiveEvent(event, res.episodes, res.episode);
  };

  private _getPatientEpisodesReq = async (patientId: number) => {
    const { LStorage } = this._store.StorageService;
    const episodeId = LStorage.getItem('ACTIVE_EPISODE');
    if (!episodeId) return null;
    if (isNaN(+episodeId)) return null;
    const res = await this._store.MedCardApi.getMedEpisodes({
      'filter[active]': true,
      'filter[patient_id]': patientId,
    });
    if (!res.success) return null;
    if (!res.data?.length) return null;
    const episode = res.data?.find((e) => e.id === +episodeId);
    if (!episode) return null;
    return { episodes: res.data, episode };
  };

  private _subscribeHistory = () => {
    const { history, getQuery } = this._store.RoutingService;
    const { date, appointmentId } = getQuery<QueryParams>();

    let prevQuery: QueryParams = { date, appointmentId };

    this._unsubscribeHistory = history.listen(async ({ search }) => {
      if (!search) return;

      const currentQuery = getQuery<QueryParams>();
      this.state.setFilterDate(new Date(currentQuery.date));

      if (!prevQuery) {
        prevQuery = currentQuery;
        return;
      }

      if (prevQuery) {
        const diff = this._getDiffQuery(prevQuery, currentQuery);
        if (diff.appointmentId || diff.date) prevQuery = currentQuery;

        if (diff.date && diff.appointmentId) {
          await this._getAllEventsReq();
          this.state.setSelectedEventById(diff.appointmentId);
          return;
        }
        if (diff.date) {
          await this._getAllEventsReq();
          return;
        }
        if (diff.appointmentId) {
          this.state.setSelectedEventById(diff.appointmentId);
        }
      }
    });
  };

  private _getDiffQuery = (
    prev: QueryParams,
    current: QueryParams,
  ): Partial<QueryParams> => {
    const keys = new Set([...Object.keys(prev), ...Object.keys(current)]);

    return Array.from(keys).reduce((acc, key) => {
      const _key = key as keyof QueryParams;
      if (prev[_key] !== current[_key]) {
        return { ...acc, [_key]: current[_key] };
      }
      return acc;
    }, {} as Partial<QueryParams>);
  };

  public addQueryParam = (date?: Date, id?: number) => {
    const { formatDate, isValidDate } = this._store.DateService;
    const { getQuery } = this._store.RoutingService;
    const currentQuery = getQuery<QueryParams>();
    const _date = isValidDate(date)
      ? formatDate(date, 'YYYY_MM_DD')
      : currentQuery.date;
    const appointmentId = id ?? currentQuery.appointmentId;
    this._store.RoutingService.addQuery(
      { date: _date, appointmentId },
      'services',
    );
  };

  public findPackageServiceByServiceId = (id: number) => {
    const { createDate } = this._store.DateService;

    let packageExpired = null;
    let service = null;

    for (const pkg of this.state.event.packages.packages) {
      for (const servicePackage of pkg.services) {
        if (servicePackage.service.service.id === id && servicePackage.canAdd) {
          if (!packageExpired) {
            packageExpired = pkg.pkg.expired_date;
            service = servicePackage;
            continue;
          }

          const expiredSooner = createDate(pkg.pkg.expired_date).isBefore(
            packageExpired,
          );

          if (expiredSooner) {
            packageExpired = pkg.pkg.expired_date;
            service = servicePackage;
          }
        }
      }
    }
    return service;
  };

  public findPackageServiceById = (id: number) => {
    for (const pkg of this.state.event.packages.packages) {
      for (const servicePackage of pkg.services) {
        if (servicePackage.service.id === id) {
          return servicePackage;
        }
      }
    }
    return null;
  };
}
