import { action, makeObservable, observable } from 'mobx';
import { User } from '@modules/Users/mappers/User';

export default class UsersStore {
  @observable public user: User | null = null;
  @observable public users: User[] = [];
  @observable public usersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public userServices: UserServiceResponse[] = [];
  @observable public userServicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public importUsersData: ImportUsersResponse = {
    specializations_not_finds: [],
    positions_not_finds: [],
    roles_not_finds: [],
    users: [],
  };
  @observable public remedDoctor: RemedDoctor | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setRemedDoctor = (doctor: RemedDoctor | null) => {
    this.remedDoctor = doctor;
    if (this.user?.id === Number(doctor?.outlink_id)) {
      this.user?.setValue('outlink_id', doctor?.id);
    }
  };

  @action
  public setUsers = (users: UserResponse[], meta: ResponseMeta): void => {
    this.users = users.map((e) => new User(e));
    this.usersMeta = meta;
  };

  @action
  public updateUser = (updatedUser: UserResponse): void => {
    const user = this.users.find((e) => e.id === updatedUser.id);
    if (user) {
      Object.assign(user, updatedUser);
    }
    if (this.user?.id === updatedUser.id) {
      Object.assign(this.user, updatedUser);
    }
  };

  @action
  public updateStatus = (updatedUser: UserResponse): void => {
    const user = this.users.find((e) => e.id === updatedUser.id);
    if (user) {
      user.setUserStatus(updatedUser);
    }
    if (this.user?.id === updatedUser.id) {
      this.user.setUserStatus(updatedUser);
    }
  };

  @action
  public setUserSpecializations = (
    userId: number,
    params: Specialization,
  ): void => {
    const user = this.users.find((e) => e.id === userId);
    if (user) {
      user.specializations.push(params);
    }
    if (this.user?.id === userId) {
      this.user.specializations.push(params);
    }
  };

  @action
  public removeUserSpecializations = (
    params: RemoveUserSpecializationReq,
  ): void => {
    const user = this.users.find((e) => e.id === params.user_id);
    if (user) {
      user.specializations = user.specializations.filter(
        (e) => e.id !== params.specialization_id,
      );
    }
    if (this.user?.id === params.user_id) {
      this.user.specializations = this.user.specializations.filter(
        (e) => e.id !== params.specialization_id,
      );
    }
  };

  @action
  public updateUserRoles = (updatedUser: UserResponse): void => {
    const user = this.users.find((e) => e.id === updatedUser.id);
    if (user) {
      user.roles = new User(updatedUser).roles;
    }
    if (this.user?.id === updatedUser.id) {
      this.user.roles = new User(updatedUser).roles;
    }
  };

  @action
  public setUser = (user: UserResponse | null): void => {
    this.user = user ? new User(user) : user;
  };

  @action
  public addNewUser = (user: UserResponse): void => {
    this.users = [new User(user), ...this.users];
  };

  @action
  public removeUser = (id: number): void => {
    this.users = this.users.filter((e) => e.id !== id);
  };

  @action
  public removeUserRole = (params: RoleToUserReq): void => {
    const user = this.users.find((e) => e.id === params.user_id);
    if (user) {
      user.roles = user.roles.filter((e) => e.id !== params.role_id);
    }
    if (this.user) {
      this.user.roles = this.user?.roles.filter((e) => e.id !== params.role_id);
    }
  };

  @action
  public removeAllRoles = (userId: number): void => {
    const user = this.users.find((e) => e.id === userId);
    if (user) user.roles = [];
    if (this.user) this.user.roles = [];
  };

  @action
  public setUserServices = (
    services: UserServiceResponse[],
    meta: ResponseMeta,
  ): void => {
    this.userServices = services;
    this.userServicesMeta = meta;
  };

  @action
  public addUserNewService = (data: AddServiceUserResponse): void => {
    this.userServices = [data.service, ...this.userServices];
  };

  @action
  public updateUserService = (data: UpdateServiceUserRes): void => {
    this.userServices = this.userServices.map((service) => {
      if (service.id === data.service.id) {
        return { ...data.service, is_online_service: data.is_online_service };
      }

      return service;
    });
  };

  @action
  public removeUserService = (serviceId: number): void => {
    this.userServices = this.userServices.filter((e) => e.id !== serviceId);
  };

  // FOR USER EXPANDER ROLES
  @action
  public setUserRoleData = (
    data: unknown,
    roleId: number,
    key: 'uiModules' | 'permissions',
  ): void => {
    if (!this.user) return;
    const role = this.user.roles.find((e) => e.id === roleId);
    if (!role) return;
    role.setValue(key, data);
  };

  @action
  public setUserRoleLoading = (
    roleId: number,
    key: 'uiModules' | 'permissions',
    value: boolean,
  ): void => {
    if (!this.user) return;
    const role = this.user.roles.find((e) => e.id === roleId);
    if (!role) return;
    role.setLoading(key, value);
  };

  @action
  public setImportUsers = (data: ImportUsersResponse) => {
    this.importUsersData.roles_not_finds = data.roles_not_finds;
    this.importUsersData.positions_not_finds = data.positions_not_finds;
    this.importUsersData.specializations_not_finds =
      data.specializations_not_finds;
    this.importUsersData.users = data.users?.map((e) => {
      return this._adaptUser(e);
    });
  };

  @action
  public updateImportUserField = (
    id: number,
    field: keyof ImportUserType,
    value: any,
  ) => {
    const user = this.importUsersData.users?.find((e) => e.id === id);
    if (user) {
      user[field] = value as never;
      user.isValid = this._adaptUser(user).isValid;
    }
  };

  private _adaptUser = (user: ImportUserType) => {
    const keys = Object.keys(user).filter(
      (e) =>
        e !== 'isValid' &&
        e !== 'canBeLoggedIn' &&
        e !== 'cant_give_access' &&
        e !== 'find_role_status' &&
        e !== 'find_specialization_status' &&
        e !== '_position' &&
        e !== '_role' &&
        e !== '_specialization' &&
        e !== 'find_position_status',
    );
    user.isValid = keys.every((e) => {
      return !!user[e as keyof ImportUserType];
    });
    return user;
  };
}
