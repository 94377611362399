import { action, computed, makeObservable, observable } from 'mobx';
import { PackageServiceAdapter } from './PackageService.adapter';

export class PackageAdapter {
  @observable public pkg: PatientPkgService;
  @observable public services: PackageServiceAdapter[];
  @observable public serviceLimit: number;
  @observable public serviceLimitUsed: number;
  @observable public hasServiceWithLimit: boolean;

  constructor({ pkg }: { pkg: PatientPkgService }) {
    makeObservable(this);

    this.pkg = pkg;
    this.hasServiceWithLimit = Boolean(this.pkg.service_limit_for_use);
    this.serviceLimit = this.pkg.service_limit_for_use;

    this.serviceLimitUsed = this.pkg.services
      .filter((s) => s.participates_in_the_selection)
      .reduce((sum, s) => (sum += s.frizzed_count), 0);

    this.services = pkg.services.map(
      (service) => new PackageServiceAdapter({ service, pkg: this }),
    );
  }

  @computed
  public get laboratoryServices() {
    return this.services.filter(
      (s) => s.service.service.type === 'lab_secondary',
    );
  }

  @computed
  public get clinicServices() {
    return this.services.filter(
      (s) => s.service.service.type === 'clinic_service',
    );
  }

  @action
  public setServiceLimit = (action: 'add' | 'remove', value = 1) => {
    this.serviceLimitUsed =
      action === 'add'
        ? this.serviceLimitUsed + value
        : this.serviceLimitUsed - value;
  };
}
