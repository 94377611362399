import { UserDocTemplatesStore } from '@modules/Documents/UserDocTemplates/user-templates.store';

export class UserDocTemplatesService extends UserDocTemplatesStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  public getTemplatesRequest = async (query?: GetUserTemplatesQuery) => {
    const res = await this._store.UserTemplateApi.getUserTemplates(query);
    if (res.success && res.data) {
      this.setTemplates(res.data, res.meta);
    }
    return res;
  };

  public getTemplateRequest = async (id: number) => {
    const res = await this._store.UserTemplateApi.getUserTemplate(id);
    if (res.success && res.data) {
      this.setTemplate(res.data);
      this._store.DocumentsService.template.setDocTemplate(res.data.body);
    }
    return res;
  };

  public createTemplateRequest = async (params: CreateUserTemplateParams) => {
    const res = await this._store.UserTemplateApi.createUserTemplate(params);
    if (res.success && res.data) {
      this.addTemplate(res.data);
    }
    return res;
  };

  public updateTemplateRequest = async (
    id: number,
    params: UpdateUserTemplate,
  ) => {
    const res = await this._store.UserTemplateApi.updateUserTemplate(
      id,
      params,
    );

    if (res.success && res.data) {
      this.updateTemplate(res.data);
      this._store.DocumentsService.template.setDocTemplate(res.data.body);
    }
    return res;
  };

  public deleteTemplateRequest = async (id: number) => {
    const res = await this._store.UserTemplateApi.deleteUserDocument(id);

    if (res.success) {
      this.deleteTemplate(id);
    }

    return res;
  };
}
