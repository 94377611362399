import { PreferentialCategoriesStore } from './preferential-categories.store';

export class PreferentialCategoriesService extends PreferentialCategoriesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPreferentialCategoriesReq = async (
    query?: GetPreferentialCategories,
  ) => {
    const res =
      await this._store.PreferentialCategoriesApi.getPreferentialCategories(
        query,
      );
    if (res.success && res.data) {
      this.setPreferentialCategories(res.data);
    }
    return res;
  };

  public getPreferentialCategoryReq = async (id: number) => {
    const res =
      await this._store.PreferentialCategoriesApi.getPreferentialCategory(id);
    if (res.success && res.data) {
      this.setPreferentialCategory(res.data);
    }
    return res;
  };

  public updatePreferentialCategoryReq = async (
    id: number,
    params: UpdatePreferentialCategory,
  ) => {
    const res =
      await this._store.PreferentialCategoriesApi.updatePreferentialCategory(
        id,
        params,
      );
    if (res.success && res.data) {
      this.updatePreferentialCategory(res.data);
    }
    return res;
  };

  public createPreferentialCategoryReq = async (
    params: CreatePreferentialCategory,
  ) => {
    const res =
      await this._store.PreferentialCategoriesApi.createPreferentialCategory(
        params,
      );
    if (res.success && res.data) {
      this.addPreferentialCategory(res.data);
    }
    return res;
  };

  public deletePreferentialCategoryReq = async (id: number) => {
    const res =
      await this._store.PreferentialCategoriesApi.deletePreferentialCategory(
        id,
      );
    if (res.success) {
      this.deletePreferentialCategory(id);
    }
    return res;
  };

  public addPreferentialCategoryReq = async (
    id: number,
    params: AddPreferentialService,
  ) => {
    const res =
      await this._store.PreferentialCategoriesApi.addPreferentialService(
        id,
        params,
      );
    if (res.success && res.data) {
      this.setPreferentialCategory(res.data);
    }
    return res;
  };

  public patientsPreferentialCategoryReq = async (id: number) => {
    const res =
      await this._store.PreferentialCategoriesApi.patientsPreferentialService(
        id,
      );
    if (res.success && res.data) {
      this.patientsPreferentialCategory(res.data);
    }
    return res;
  };

  public removePreferentialCategoryReq = async (
    id: number,
    params: RemovePreferentialService,
  ) => {
    const res =
      await this._store.PreferentialCategoriesApi.removePreferentialService(
        id,
        params,
      );
    if (res.success && res.data) {
      this.setPreferentialCategory(res.data);
    }
    return res;
  };
}
