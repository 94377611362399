import { InitSystemStore } from '@modules/Init/init-system.store';

export class InitSystemService extends InitSystemStore {
  protected _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  public getEntities = async () => {
    const response = await this._store.ApiService.getEntities();
    if (response.success && response.data) {
      this.setEntities(response.data);
    }
  };

  public getUIModules = async () => {
    const response = await this._store.ApiService.getUIModules();
    if (response.success && response.data) {
      this.setUiModules(response.data);
    }
  };

  public initSystem = async (params: InitReq) => {
    const response = await this._store.ApiService.initSystemReq(params);
    if (response.success) {
      this._store.AppService.init();
    }
    return response;
  };
}
