export const USER_STATUS = <const>{
  ACTIVE: 1,
  NOT_ACTIVATED: 2,
  NO_ACCESS: 3,
  // BLOCKED: 4,
  REMOVED: 5,
  FIRED: 6,
};

export const USER_STATUS_SETTINGS = <const>{
  ACTIVE: {
    label: 'statuses.active',
    value: USER_STATUS.ACTIVE,
    bgColor: '#0D9488',
    className: undefined,
  },
  NOT_ACTIVATED: {
    label: 'statuses.not_activated',
    value: USER_STATUS.NOT_ACTIVATED,
    bgColor: '#fff',
    className: 'text-black',
  },
  NO_ACCESS: {
    label: 'statuses.no_access',
    value: USER_STATUS.NO_ACCESS,
    bgColor: '#D97706',
    className: undefined,
  },
  FIRED: {
    label: 'statuses.fired',
    value: USER_STATUS.FIRED,
    bgColor: '#E5E7EB',
    className: 'text-black border-0',
  },
  REMOVED: {
    label: 'statuses.removed',
    value: USER_STATUS.REMOVED,
    bgColor: '#475569',
    className: undefined,
  },
};
