import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExportIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.167 16.667h11.666V15H4.167zM15.833 7.5H12.5v-5h-5v5H4.167L10 13.333z"
      />
    </svg>
  );
};
export default SvgExportIcon;
