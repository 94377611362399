import { FetchService } from '@modules/Api';

export class DocumentsApi extends FetchService {
  public getDocuments = (query?: GetDocumentsQuery) => {
    return this.request<'DOCUMENTS', MedDocument[], GetDocumentsQuery>(
      '/documents',
      'GET_DOCUMENTS',
      { query, permit: 'documents' },
    );
  };

  public getDocument = (id: number) => {
    return this.request<'DOCUMENTS', MedDocument>(
      '/documents',
      'GET_DOCUMENT',
      {
        pathParam: id,
        permit: 'documents',
      },
    );
  };

  public createDocument = (params: CreateDocument) => {
    return this.request<'DOCUMENTS', MedDocument>(
      '/documents',
      'CREATE_DOCUMENT',
      { method: 'POST', params, permit: 'documents' },
    );
  };

  public updateDocument = (id: number, params: UpdateDocument) => {
    return this.request<'DOCUMENTS', MedDocument>(
      '/documents',
      'UPDATE_DOCUMENT',
      { method: 'PUT', params, pathParam: id, permit: 'documents' },
    );
  };

  public closeDocument = (id: number) => {
    return this.request<'DOCUMENTS', MedDocument>(
      '/documents',
      'CLOSE_DOCUMENT',
      {
        method: 'PUT',
        pathParam: `${id}/close`,
        permit: 'documents',
      },
    );
  };

  public getDocumentRefinalization = (
    id: number,
    params: RefinalizationDocument,
  ) => {
    return this.request<'DOCUMENTS', MedDocument, RefinalizationDocument>(
      '/documents_refinalization',
      'DOCUMENT_REFINALIZATION',
      {
        method: 'PUT',
        params,
        pathParam: `${id}/`,
        permit: 'documents',
      },
    );
  };

  public getAllDocumentsFinalization = (params: RefinalizationAllDocument) => {
    return this.request<'DOCUMENTS', MedDocument[], RefinalizationAllDocument>(
      '/documents',
      'DOCUMENTS_ALL_FINALIZATION',
      {
        method: 'PUT',
        params,
        pathParam: `finalization_all`,
        permit: 'documents',
      },
    );
  };

  public getDocMacros = () => {
    return this.request<'DOCUMENTS', DocMacros[]>('/macros', 'GET_MACROS');
  };

  public deleteDocument = (id: number) => {
    return this.request<'DOCUMENTS'>('/documents', 'DELETE_DOCUMENT', {
      method: 'DELETE',
      pathParam: `${id}`,
      permit: 'documents',
    });
  };
}
