import { PreloadLazy } from '@router/utils';

export const PATIENTS_ROUTES: PrivateRoute = {
  group: 'PATIENTS',
  name: 'patients',
  path: '/patients',
  exact: false,
  sidebar: true,
  icon: 'OutlineFaceBlack',
  activeIcon: 'FaceBlack',
  divider: true,
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'patients',
      path: '/patients',
      exact: true,
      component: PreloadLazy(() => import('@views/Patients')),
    },
    {
      name: 'patients_table',
      path: '/patients/table',
      exact: true,
      component: PreloadLazy(() => import('@views/Patients/PatientsTable')),
      imagePath: () => import('@assets/img/preview/patients_table.svg'),
    },
    {
      name: 'patients_table',
      path: '/patients/table/import',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Patients/PatientsImport')),
    },
    {
      name: 'patients_table',
      path: '/patients/table/join',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Patients/PatientsCombining')),
    },
    {
      name: 'patients_table',
      path: '/patients/table/:id',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Patients/Patient')),
    },

    {
      name: 'patients_table',
      path: '/patients/table/:id/episodes/:episodeId',
      exact: true,
      hidden: true,
      component: PreloadLazy(
        () => import('@views/Patients/Patient/Tabs/Episodes/Episode'),
      ),
    },
    {
      name: 'patients_table',
      path: '/patients/table/:id/documents/:docId',
      exact: true,
      hidden: true,
      component: PreloadLazy(
        () => import('@views/Patients/Patient/Tabs/Documents/Document'),
      ),
    },
    {
      name: 'patients_table',
      path: '/patients/table/:id/documents/:docId/print',
      exact: true,
      hidden: true,
      component: PreloadLazy(
        () => import('@views/Patients/Patient/Tabs/Documents/Document/Print'),
      ),
    },
    {
      name: 'patients_parameters',
      path: '/patients/:id/parameters',
      exact: true,
      hidden: true,
      component: PreloadLazy(() => import('@views/Patients/Parameters')),
    },
  ],
};
