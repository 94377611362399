import React, { useEffect, useRef } from 'react';
import Portal from '@components/UI/Portal';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useService } from '@stores/hooks';
import { useOutsideClick } from '@utils/hooks';
import DrawerComponent from './DrawerComponent';
import { observer } from 'mobx-react';
import './Drawer.scss';

function DrawerProvider() {
  const { isOpen, close, drawerProps } = useService('DrawerService');
  const pRef = useRef<HTMLDivElement>(null);
  useOutsideClick(pRef, outsidePClick);

  function outsidePClick() {
    close();
  }

  const closeOnKey = React.useCallback(
    (e: KeyboardEvent) => {
      if (isOpen && e.key === 'Escape') {
        close();
      }
    },
    [close, isOpen],
  );

  useEffect(() => {
    document.addEventListener('keydown', closeOnKey);
    return () => {
      document.removeEventListener('keydown', closeOnKey);
    };
  }, [closeOnKey]);

  return (
    <Portal>
      <div className="rm-drawer-wrap">
        <div className={cn({ overlay: isOpen })} />
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames={
            drawerProps?.size
              ? `${drawerProps.size}-transition`
              : 'small-transition'
          }
          unmountOnExit
        >
          <div
            ref={pRef}
            className={cn([
              'rm-drawer',
              drawerProps.component,
              drawerProps?.size ? drawerProps.size : 'small',
            ])}
            data-testid="drawer"
          >
            {DrawerComponent(drawerProps as DrawerProps<never>)}
          </div>
        </CSSTransition>
      </div>
    </Portal>
  );
}

export default observer(DrawerProvider);
