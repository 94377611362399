import { action, makeObservable, observable } from 'mobx';
import { Appointment } from './mapper/AppointmentMapper';
import { AppointmentHistory } from './mapper/AppointmentHistory';

export class AppointmentStore {
  @observable public appointments: Appointment[] = [];
  @observable public appointmentsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public appointmentHistory: AppointmentHistory[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setAppointments = (
    appointments: AppointmentType[],
    meta: ResponseMeta,
  ) => {
    this.appointments = appointments.map(
      (appointment) => new Appointment(appointment),
    );
    this.appointmentsMeta = meta;
  };

  @action
  public changeStatus = (appointment: AppointmentType) => {
    const appoint = this.appointments.find((a) => a.id === appointment.id);

    if (appoint) {
      appoint.setData(appointment);
    }
  };

  @action
  public createAppointment = (appointment: AppointmentType) => {
    this.appointments = this.appointments.concat(new Appointment(appointment));
  };

  @action
  public updateAppointment = (appointment: AppointmentType) => {
    const appoint = this.appointments.find((a) => a.id === appointment.id);

    if (appoint) {
      appoint.setData(appointment);
    }
  };

  @action
  public updateBatchAppointment = (appointment: AppointmentType) => {
    const appoint = this.appointments.find((a) => a.id === appointment.id);

    if (appoint) {
      appoint.setData(appointment);
    }
  };

  @action
  public deleteAppointment = (appointment: AppointmentType) => {
    this.appointments = this.appointments.filter(
      (a) => a.id !== appointment.id,
    );
  };

  @action
  public setAppointmentHistory = (
    appointmentHistory: AppointmentHistoryResponse[],
  ) => {
    this.appointmentHistory = appointmentHistory
      .sort((a, b) => a.created - b.created)
      .map((h) => new AppointmentHistory(h));
  };

  @action
  public clearAppointmentHistory = () => {
    this.appointmentHistory = [];
  };

  @action
  public clearAppointments = () => {
    this.appointments = [];
    this.appointmentsMeta = {
      totalPages: 0,
      totalRecords: 0,
    };
  };
}
