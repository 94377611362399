import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCounterparty = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <mask
        id="a"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#7C3AED"
          d="M8.334 17.5v-1.583q0-.438.218-.823a1.7 1.7 0 0 1 .594-.615 8.4 8.4 0 0 1 2.01-.854 8.1 8.1 0 0 1 2.178-.292q1.124 0 2.177.292t2.01.854q.375.23.594.615t.219.823V17.5zm-6.667 0v-10l6.667-5 4.479 3.375a4 4 0 0 0-2.604 1.365A4.03 4.03 0 0 0 9.167 10q0 .645.177 1.219.177.573.51 1.073-.416.165-.802.343-.385.178-.76.407a3.2 3.2 0 0 0-1.188 1.218 3.34 3.34 0 0 0-.437 1.657V17.5zm11.667-5a2.4 2.4 0 0 1-1.771-.73 2.4 2.4 0 0 1-.73-1.77q0-1.042.73-1.77a2.4 2.4 0 0 1 1.77-.73 2.4 2.4 0 0 1 1.771.73q.73.728.73 1.77t-.73 1.77a2.4 2.4 0 0 1-1.77.73"
        />
      </g>
    </svg>
  );
};
export default SvgCounterparty;
