import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronFirst = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      fill="none"
      viewBox="0 0 13 12"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12.41 10.59 7.82 6l4.59-4.59L11 0 5 6l6 6zM0 0h2v12H0z"
      />
    </svg>
  );
};
export default SvgChevronFirst;
