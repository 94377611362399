export class DoctorWorkplaceUtils {
  protected _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public getEventDuration = (event: DoctorWorkplace.VisitEvent) => {
    const { formatDate } = this._store.DateService;
    const { start_time, end_time } = event.visit;
    const format = (d: number) => formatDate(d, 'HH_mm');
    return `${format(start_time)} - ${format(end_time)}`;
  };

  public goToPatient = ({ patient }: AppointmentType) => {
    if (!patient) return;
    const { goToRoute } = this._store.RoutingService;
    goToRoute('/patients/table/:id', { id: patient.id });
  };

  public goToPatientMedCards = ({ visit }: DoctorWorkplace.VisitEvent) => {
    if (!visit.patient) return;
    const { goToRoute } = this._store.RoutingService;
    goToRoute(
      '/patients/table/:id',
      { id: visit.patient.id },
      null,
      null,
      false,
      'med-cards',
    );
  };

  public hasOpenMedCard = ({ visit }: DoctorWorkplace.VisitEvent) => {
    return visit.patient?.medical_cards?.some((m) => m.isOpen) ?? false;
  };
}
