import { FetchService } from '@modules/Api';

export class MedCardsApi extends FetchService {
  /*
  |----------------------------------------------------------------------------
  | MED CARDS
  |----------------------------------------------------------------------------
  */
  public getMedCards = (query?: GetMedCardsQuery) => {
    return this.request<'MEDICAL_CARD', MedCard[], null, GetMedCardsQuery>(
      '/medical_cards',
      'GET_MED_CARDS',
      {
        query,
        permit: 'medical_cards',
      },
    );
  };

  public getMedCard = (id: number) => {
    return this.request<'MEDICAL_CARD', MedCard>(
      '/medical_cards',
      'GET_MED_CARDS',
      {
        pathParam: id,
        permit: 'medical_cards',
      },
    );
  };

  public createMedCard = (params: CreateMedCard) => {
    return this.request<'MEDICAL_CARD', MedCard, CreateMedCard>(
      '/medical_cards',
      'CREATE_MED_CARD',
      { method: 'POST', params, permit: 'medical_cards' },
    );
  };

  public updateMedCard = (id: number, params: UpdateMedCard) => {
    return this.request<'MEDICAL_CARD', MedCard, UpdateMedCard>(
      '/medical_cards',
      'UPDATE_MED_CARD',
      { method: 'PUT', params, pathParam: id, permit: 'medical_cards' },
    );
  };

  public closeMedCard = (id: number) => {
    return this.request<'MEDICAL_CARD', MedCard>(
      '/medical_cards',
      'CLOSE_MED_CARD',
      { method: 'PUT', pathParam: `${id}/close`, permit: 'medical_cards' },
    );
  };

  public deleteMedCard = (id: number) => {
    return this.request<'MEDICAL_CARD'>('/medical_cards', 'DELETE_MED_CARD', {
      method: 'DELETE',
      pathParam: id,
      permit: 'medical_cards',
    });
  };

  /*
  |----------------------------------------------------------------------------
  | TYPES
  |----------------------------------------------------------------------------
  */
  public getMedCardTypes = (query?: GetMedCardTypesQuery) => {
    return this.request<
      'MEDICAL_CARD',
      MedCardType[],
      null,
      GetMedCardTypesQuery
    >('/types_of_medical_card', 'GET_MED_CARD_TYPES', {
      query,
      permit: 'types_of_medical_card',
    });
  };

  public getMedCardType = (id: number) => {
    return this.request<'MEDICAL_CARD', MedCardType>(
      '/types_of_medical_card',
      'GET_MED_CARD_TYPE',
      { pathParam: id, permit: 'types_of_medical_card' },
    );
  };

  public createMedCardType = (params: CreateMedCardType) => {
    return this.request<'MEDICAL_CARD', MedCardType, CreateMedCardType>(
      '/types_of_medical_card',
      'CREATE_MED_CARD_TYPE',
      { method: 'POST', params, permit: 'types_of_medical_card' },
    );
  };

  public updateMedCardType = (id: number, params: UpdateMedCardType) => {
    return this.request<'MEDICAL_CARD', MedCardType, UpdateMedCardType>(
      '/types_of_medical_card',
      'UPDATE_MED_CARD_TYPE',
      { method: 'PUT', params, pathParam: id, permit: 'types_of_medical_card' },
    );
  };

  public deleteMedCardType = (id: number) => {
    return this.request<'MEDICAL_CARD'>(
      '/types_of_medical_card',
      'DELETE_MED_CARD_TYPE',
      { method: 'DELETE', pathParam: id, permit: 'types_of_medical_card' },
    );
  };

  /*
  |----------------------------------------------------------------------------
  | EPISODES
  |----------------------------------------------------------------------------
  */
  public getMedEpisodes = (query?: GetMedEpisodesQuery) => {
    return this.request<
      'MEDICAL_CARD',
      MedEpisode[],
      null,
      GetMedEpisodesQuery
    >('/episodes', 'GET_MED_EPISODES', {
      query,
      permit: 'episodes',
    });
  };

  public getMedEpisode = (id: number) => {
    return this.request<'MEDICAL_CARD', MedEpisode>(
      '/episodes',
      'GET_MED_EPISODE',
      { pathParam: id, permit: 'episodes' },
    );
  };

  public createMedEpisode = (params: CreateMedEpisode) => {
    return this.request<'MEDICAL_CARD', MedEpisode, CreateMedEpisode>(
      '/episodes',
      'CREATE_MED_EPISODE',
      { method: 'POST', params, permit: 'episodes' },
    );
  };

  public updateMedEpisode = (id: number, params: UpdateMedEpisodes) => {
    return this.request<'MEDICAL_CARD', MedEpisode, UpdateMedEpisodes>(
      '/episodes',
      'UPDATE_MED_EPISODE',
      { method: 'PUT', params, pathParam: id, permit: 'episodes' },
    );
  };

  public closeMedEpisode = (id: number, params: CloseMedEpisode) => {
    return this.request<'MEDICAL_CARD', MedEpisode, CloseMedEpisode>(
      '/episodes',
      'CLOSE_MED_EPISODE',
      {
        method: 'PUT',
        params,
        pathParam: `${id}/close`,
        permit: 'episodes',
      },
    );
  };

  public importMedCards = (params: FormData) => {
    return this.request<'MEDICAL_CARD', ImportMedCard[], FormData>(
      '/medical_cards/import',
      'IMPORT_MED_CARDS',
      {
        method: 'POST',
        params,
      },
    );
  };

  public saveImportMedCards = (params: SaveImportMedCards) => {
    return this.request<'MEDICAL_CARD', ImportPatient[]>(
      '/medical_cards/import/save',
      'SAVE_IMPORT_MED_CARDS',
      {
        method: 'POST',
        params,
      },
    );
  };
}
