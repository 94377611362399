import { FetchService } from '@modules/Api/fetch.service';

/**
 * Patients Api
 */
export class PatientsApi extends FetchService {
  public getPatients = (query?: GetPatientsQuery) => {
    return this.request<'PATIENTS', Patient[], GetPatientsQuery>(
      '/patients',
      'GET_PATIENTS',
      { query: query, permit: 'patients' },
    );
  };

  public getPatient = (id: number) => {
    return this.request<'PATIENTS', Patient>('/patients', 'GET_PATIENT', {
      pathParam: id,
      permit: 'patients',
    });
  };

  public getPatientContacts = (id: number) => {
    return this.request<'PATIENTS', PatientContacts>(
      '/patients',
      'GET_PATIENT_CONTACTS',
      {
        pathParam: `${id}/contact`,
        permit: 'patient_contact',
      },
    );
  };

  public getPatientMedData = (id: number) => {
    return this.request<'PATIENTS', PatientMedData>(
      '/patients',
      'GET_PATIENT_MED_DATA',
      {
        pathParam: `${id}/medical_data`,
        permit: 'patient_medical_data',
      },
    );
  };

  public createPatient = (params: CreatePatient) => {
    return this.request<'PATIENTS', Patient, CreatePatient>(
      '/patients',
      'CREATE_PATIENT',
      { method: 'POST', params, permit: 'patients' },
    );
  };

  public updatePatient = (id: number, params: UpdatePatient) => {
    return this.request<'PATIENTS', Patient, UpdatePatient>(
      '/patients',
      'UPDATE_PATIENT',
      { method: 'PUT', params, pathParam: id, permit: 'patients' },
    );
  };

  public updatePatientContacts = (
    id: number,
    params: UpdatePatientContacts,
  ) => {
    return this.request<'PATIENTS', PatientContacts, UpdatePatientContacts>(
      '/patients',
      'UPDATE_PATIENT_CONTACTS',
      {
        method: 'PUT',
        params,
        pathParam: `${id}/contact`,
        permit: 'patient_contact',
      },
    );
  };

  public updatePatientMedData = (id: number, params: UpdatePatientMedData) => {
    return this.request<'PATIENTS', PatientMedData, UpdatePatientMedData>(
      '/patients',
      'UPDATE_PATIENT_MED_DATA',
      {
        method: 'PUT',
        params,
        pathParam: `${id}/medical_data`,
        permit: 'patient_medical_data',
      },
    );
  };

  public importPatients = (params: FormData) => {
    return this.request<'PATIENTS', ImportPatient[], FormData>(
      '/patients/import',
      'IMPORT_USERS',
      {
        method: 'POST',
        params,
      },
    );
  };

  public saveImportPatients = (params: SaveImportPatients) => {
    return this.request<'PATIENTS', ImportPatient[]>(
      '/patients/import/save',
      'SAVE_IMPORT_PATIENTS',
      {
        method: 'POST',
        params,
      },
    );
  };

  public updatePatientCreditTrust = (id: number, params: UpdateCreditTrust) => {
    return this.request<'PATIENTS', Patient, UpdateCreditTrust>(
      '/patients',
      'UPDATE_PATIENT_CREDIT_TRUST',
      {
        method: 'PUT',
        params,
        pathParam: `${id}/set_credit_trust`,
      },
    );
  };

  public getPatientOrdersReportHeader = (
    id: number,
    query?: GetPatientReportHeaderQuery,
  ) => {
    return this.request<'ORDERS', PatientPaymentReportHeader>(
      '/orders',
      'GET_PATIENT_ORDERS_REPORT_HEADER',
      { pathParam: `${id}/get_report/header`, query },
    );
  };

  public getPatientOrdersReportData = (
    id: number,
    query?: GetPatientReportDataQuery,
  ) => {
    return this.request<
      'ORDERS',
      PatientPaymentReportData[],
      null,
      GetPatientReportDataQuery
    >('/orders', 'GET_PATIENT_ORDERS_REPORT_DATA', {
      pathParam: `${id}/get_report/data`,
      query,
    });
  };

  public getPatientPaymentsReportDaily = (
    id: number,
    query: GetPatientReportDailyQuery,
  ) => {
    return this.request<
      'ORDERS',
      PatientPaymentReportDaily,
      null,
      GetPatientReportDailyQuery
    >('/orders', 'GET_PATIENT_ORDERS_REPORT_DAILY', {
      pathParam: `${id}/get_report/daily`,
      query,
    });
  };

  public joinPatients = (toPatient: number, fromPatient: number) => {
    return this.request<'PATIENTS', JoinPatientsRes>(
      '/join_patients',
      'JOIN_PATIENTS',
      {
        method: 'POST',
        pathParam: `${toPatient}/${fromPatient}`,
      },
    );
  };

  public deletePatient = (id: number) => {
    return this.request<'PATIENTS'>('/patients', 'DELETE_PATIENT', {
      method: 'DELETE',
      pathParam: `${id}`,
    });
  };
}
