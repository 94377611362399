import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCashFile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M14.5 2h-8c-1.1 0-1.99.9-1.99 2L4.5 20c0 1.1.89 2 1.99 2H18.5c1.1 0 2-.9 2-2V8zm1 10h-4v1h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1v1h-2v-1h-2v-2h4v-1h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1V9h2v1h2zm-2-4V3.5L18 8z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCashFile;
