import UsersStore from '@modules/Users/users.store';
import { SalaryService } from '@modules/Users/salary/salary.service';

export class UsersService extends UsersStore {
  protected readonly _store: Store;
  public salary: SalaryService;

  constructor(store: Store) {
    super();
    this._store = store;
    this.salary = new SalaryService(store);
  }

  public getUsersReq = async (query?: GetUsersQuery) => {
    const res = await this._store.UsersApi.getUsers(query);
    if (res.success && res.data) {
      this.setUsers(res.data, res.meta);
    }
    return res;
  };

  public getUserServicesReq = async (
    id: number,
    query?: GetUserServicesQuery,
  ) => {
    const res = await this._store.UsersApi.getUserServices(id, query);
    if (res.success && res.data) {
      this.setUserServices(res.data, res.meta);
    }
    return res;
  };

  public getUserReq = async (id: number) => {
    const res = await this._store.UsersApi.getUser(id);
    if (res.success && res.data) {
      this.setUser(res.data); // TODO ???
    }
    return res;
  };

  public createUserReq = async (params: CreateUserReq) => {
    const res = await this._store.UsersApi.createUser(params);
    if (res.success && res.data) {
      this.addNewUser(res.data);
    }
    return res;
  };

  public updateUserReq = async (id: number, params: UpdateUserReq) => {
    const res = await this._store.UsersApi.updateUser(id, params);
    if (res.success && res.data) {
      this.updateUser(res.data);
    }
    return res;
  };

  public changeUserLoginReq = async (
    id: number,
    params: ChangeUserLoginReq,
  ) => {
    const res = await this._store.UsersApi.changeUserLogin(id, params);
    if (res.success && res.data) {
      this.updateUser(res.data);
    }
    return res;
  };

  public setUserSpecializationsReq = async (
    params: SetUserSpecializationReq,
  ) => {
    const res = await this._store.UsersApi.setUserSpecializations({
      user_id: params.user_id,
      specialization_id: params.specialization_id,
    });

    if (res.success && res.data) {
      this.setUserSpecializations(params.user_id, res.data); // FIXME:  ARRAY!!!
    }
    return res;
  };

  public removeUserSpecializationsReq = async (
    params: RemoveUserSpecializationReq,
  ) => {
    const res = await this._store.UsersApi.removeUserSpecializations(params);
    if (res.success) {
      this.removeUserSpecializations(params);
    }
    return res;
  };

  public removeUserReq = async (id: number) => {
    const res = await this._store.UsersApi.removeUser(id);
    if (res.success) {
      this.removeUser(id);
    }
    return res;
  };

  public setUserPositionReq = async (params: SetUserPositionReq) => {
    const res = await this._store.UsersApi.setUserPosition(params);
    if (res.success && res.data) {
      this.updateUser(res.data);
    }
    return res;
  };

  public setUserRoleReq = async (params: RoleToUserReq) => {
    const res = await this._store.UsersApi.setUserRole(params);
    if (res.success && res.data) {
      this.updateUserRoles(res.data);
    }
    return res;
  };

  public setUserLockReq = async (userId: number) => {
    const res = await this._store.UsersApi.setUserLock(userId);
    if (res.success && res.data) {
      this.updateStatus(res.data);
    }
    return res;
  };

  public setUserUnLockReq = async (userId: number) => {
    const res = await this._store.UsersApi.setUserUnLock(userId);
    if (res.success && res.data) {
      this.updateStatus(res.data);
    }
    return res;
  };

  public grantingUserAccessReq = async (
    id: number,
    params: GrantingUserAccessReq,
  ) => {
    const res = await this._store.UsersApi.grantingUserAccess(id, params);
    if (res.success && res.data) {
      this.updateStatus(res.data);
      this.updateUser(res.data);
    }
    return res;
  };

  public removeUserRoleReq = async (params: RoleToUserReq) => {
    const res = await this._store.UsersApi.removeUserRole(params);
    if (res.success) {
      this.removeUserRole(params);
    }
    return res;
  };

  public removeAllUserRolesReq = async (userId: number) => {
    const res = await this._store.UsersApi.removeAllUserRoles(userId);
    if (res.success) {
      this.removeAllRoles(userId);
    }
    return res;
  };

  public setUserServiceReq = async (params: AddServiceUserReq) => {
    const res = await this._store.ServicesApi.addServiceUser(params);
    if (res.success && res.data) {
      this.addUserNewService(res.data);
    }
    return res;
  };

  public updateUserServiceReq = async (params: UpdateServiceUserReq) => {
    const res = await this._store.ServicesApi.updateServiceUser(params);

    if (res.success && res.data) {
      this.updateUserService(res.data);
    }

    return res;
  };

  public removeUserServiceReq = async (serviceId: number, userId: number) => {
    const res = await this._store.ServicesApi.removeServiceUser(
      serviceId,
      userId,
    );
    if (res.success) {
      this.removeUserService(serviceId);
    }
    return res;
  };

  public firedUserReq = async (id: number) => {
    const res = await this._store.UsersApi.fireUser(id);
    if (res.success && res.data) {
      this.updateStatus(res.data);
    }
    return res;
  };

  public renewalUserReq = async (id: number) => {
    const res = await this._store.UsersApi.renewalUser(id);
    if (res.success && res.data) {
      this.updateStatus(res.data);
    }
    return res;
  };

  // FOR USER EXPANDER ROLES
  public getRolePermissionsReq = async (roleId: number) => {
    const res = await this._store.RoleApi.getRolePermissions(roleId);
    if (res.success && res.data) {
      this.setUserRoleData(res.data.permissions, roleId, 'permissions');
    }
    this.setUserRoleLoading(roleId, 'permissions', false);
    return res;
  };

  public getRoleUiModulesReq = async (roleId: number) => {
    const res = await this._store.RoleApi.getRoleUiModules(roleId);
    if (res.success && res.data) {
      this.setUserRoleData(res.data.ui_module_right, roleId, 'uiModules');
    }
    this.setUserRoleLoading(roleId, 'uiModules', false);
    return res;
  };

  public importUsersReq = async (params: FormData) => {
    const res = await this._store.UsersApi.importUsers(params);
    if (res.success && res.data) {
      this.setImportUsers(res.data);
    }
    return res;
  };

  public saveImportUsersReq = async (users: ImportUserType[]) => {
    const res = await this._store.UsersApi.saveImportUsers({ users });
    if (res.success) {
      this.setImportUsers({ users: [] });
    }
    return res;
  };

  public updateUserOutsideRequest = async (
    id: number,
    params: UpdateOutsideRequestParams,
  ) => {
    const { updateUserOutsideRequest } = this._store.UsersApi;

    const res = await updateUserOutsideRequest(id, params);
    if (res.success && res.data) this.updateUser(res.data);
    return res;
  };

  public isValidImportUsers = () => {
    // console.log(JSON.stringify(this.importUsersData.users, null, 2));
    return this.importUsersData.users?.every(
      (e) => e.last_name && e.first_name && e.patronymic && e.date_of_birth,
    );
  };
}
