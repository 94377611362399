import { DateService } from '@modules/Format';
import { Candidate } from './factory/Candidate';
import { MAX_APPOINTMENTS } from './consts';

export const appointmentToCalendarEvent = (
  appointment: AppointmentType,
): CalendarMappedEvent => {
  return {
    ...appointment,
    appointmentId: appointment.id,
    resourceId: appointment.user.id,
    start: DateService.unixToDate(appointment.start_time),
    end: DateService.unixToDate(appointment.end_time),
  };
};

export const convertDateEvents = <
  T extends BaseObject<'start_time' | 'end_time', number>,
>(
  events: T[],
) => {
  return events.map((event) => convertDateEvent(event));
};

export const convertDateEvent = <
  T extends BaseObject<'start_time' | 'end_time', number>,
>(
  event: T,
) => {
  return Object.assign(event, {
    start: DateService.unixToDate(event.start_time),
    end: DateService.unixToDate(event.end_time),
  });
};

export const availableGroupId = (candidates: Candidate[]) => {
  const groupIds = new Array(MAX_APPOINTMENTS).fill(1).map((_, i) => i);

  const appliedGroupIds = candidates.map((c) => c.groupId);

  const availableGroupIds = groupIds.filter((id) => {
    return !appliedGroupIds.includes(id);
  });

  return availableGroupIds[0];
};

export const findDirectionByServiceId = (
  serviceId: number,
  directions: DocDirection[],
) => {
  for (const direction of directions) {
    if (direction.service.id === serviceId) return direction;
  }
  return null;
};

export const fromDirectionToCandidateService = (
  direction: DocDirection,
): CandidateService => {
  return {
    id: direction.service.id,
    title: direction.service.title,
    code: direction.service.code,
    duration: direction.service.duration_independent,
    price: direction.service.cost,
    directionId: null,
    count: 1,
    referralDoctorId: null,
  };
};

export const fromGuaranteeToCandidateService = (
  guarantee: Guarantee,
): CandidateService => {
  return {
    id: guarantee.service.id,
    title: guarantee.service.title,
    code: guarantee.service.code,
    duration: guarantee.service.duration_independent,
    price: guarantee.service.base_price_cost,
    directionId: null,
    count: 1,
    referralDoctorId: null,
  };
};

export const fromServiceResponseToCandidateService = (
  service: ServiceResponse,
): CandidateService => {
  return {
    id: service.id,
    title: service.title,
    code: service.code,
    duration: service.duration_independent,
    price: service.cost,
    directionId: null,
    count: 1,
    referralDoctorId: null,
  };
};

export const fromAppServiceToCandidateService = (
  service: AppointmentService,
): CandidateService => {
  return {
    id: service.id,
    title: service.title,
    code: service.code,
    duration: service.duration_independent,
    price: service.base_price_cost,
    directionId: service.direction_id,
    count: service.count,
    referralDoctorId: service.referral_doctor_id,
  };
};

export const fromPackageServiceToCandidateService = ({
  id,
  service,
}: PatientPkgServiceService): CandidateService => {
  return {
    id: service.id,
    title: service.title,
    code: service.code,
    duration: service.duration_independent,
    price: 0,
    count: 1,
    servicePackageId: id,
    directionId: null,
    referralDoctorId: null,
  };
};
