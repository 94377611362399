import * as Sentry from '@sentry/react';
import { EnvService } from '@modules/Env';
import { LStorage } from '@modules/Storage';
import html2canvas from 'html2canvas';

class SentryLogger {
  protected _marker = '*****';
  protected _links: string[] = [];
  protected _user = LStorage.getItem<JwtData>('PROFILE_META', true);
  private _noScreenKeys: LoadingKey[] = ['LOGIN', 'CHANGE_PASSWORD'];

  constructor() {
    Sentry.init({
      enabled: EnvService.isProd,
      dsn: EnvService.env.sentry.dsn,
      environment: EnvService.env.sentry.env,
      integrations: [Sentry.browserTracingIntegration()],
      attachStacktrace: true,
      release: `${EnvService.COMMIT_HASH}: ${EnvService.BUILD_DATE}`,
      // Performance Monitoring
      // Capture 100% of the transactions, reduce in production!
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      beforeSend: async (event, hint) => {
        const res = await this._beforeSend(event, hint);
        return res as Sentry.ErrorEvent;
      },
    });
  }

  // private _tracingOrigin = () => {
  //   const api = Object.values(EnvService.env.api);
  //   const ws = Object.values(EnvService.env.ws);
  //   return [...api, ...ws].filter(isString);
  // };

  private _beforeSend = (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
    if (event.request != null && event.request.url != null) {
      event.request.url = this._hideParamsFromUrl(event.request.url);
    }
    if (this._noScreenKeys.includes(event.tags?.key as LoadingKey)) {
      return {
        ...event,
        user: {
          publicId: this._user?.publicId,
          username: this._user?.username,
          email: this._user?.email,
          position: this._user?.position,
        },
      };
    }

    return html2canvas(document.body, {
      logging: false,
      ignoreElements: (element) => {
        const tagName = element.tagName.toLowerCase();
        return ['video', 'audio', 'iframe', 'script', 'img', 'canvas'].includes(
          tagName,
        );
      },
    })
      .then(async (canvas) => {
        const screenshotData = this.convertDataURIToBinary(
          canvas.toDataURL('image/jpeg', 0.3),
        );
        hint.attachments = [
          { filename: 'screenshot.jpg', data: screenshotData },
        ];
        return {
          ...event,
          user: {
            publicId: this._user?.publicId,
            username: this._user?.username,
            email: this._user?.email,
            position: this._user?.position,
          },
        };
      })
      .catch(() => {
        return {
          ...event,
          user: {
            publicId: this._user?.publicId,
            username: this._user?.username,
            email: this._user?.email,
            position: this._user?.position,
          },
        };
      });
  };

  public sendBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
    Sentry.addBreadcrumb(breadcrumb);
  };

  public sendMessage = (title: string, details: LoggerErrorDetails) => {
    Sentry.captureMessage(title, details);
  };

  public sendException = (error: Error, details: LoggerErrorDetails) => {
    Sentry.captureException(error, details);
  };

  private _hideParamsFromUrl = (url: string) => {
    let urlUpdate = url;

    for (let i = 0; i < this._links.length; i++) {
      const strRegExp =
        this._links[i]
          .replace(/\//g, '\\/')
          .replace(this._marker, '([0-9A-Za-z]*)') + '(\\/*)?(\\?.*)?$';

      const regExp = new RegExp(strRegExp);
      const matchResult = url?.match(regExp);

      if (matchResult != null && matchResult[1] != null) {
        urlUpdate = url.replace(matchResult[1], this._marker);
        break;
      }
    }

    return urlUpdate;
  };

  public convertDataURIToBinary = (dataURI: string) => {
    const base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  };
}

export default new SentryLogger();
