import { action, makeObservable, observable } from 'mobx';
// import { MOCK } from '@modules/Patients/MOCK';

/**
 * Patients Store
 */
export class PatientsStore {
  @observable public patient: Patient | null = null;
  @observable public patients: Patient[] = [];
  @observable public patientsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public patientContacts: PatientContacts | null = null;
  @observable public patientMedData: PatientMedData | null = null;
  @observable public importPatients: ImportPatient[] = [];
  @observable public remedPatient: RemedPatient | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setRemedPatient = (patient: RemedPatient | null) => {
    this.remedPatient = patient;
    if (patient && this.patient) {
      this.patient.outlink_id = String(patient.id);
    }
  };

  @action
  public setPatients = (patients: Patient[], meta: ResponseMeta): void => {
    this.patients = patients;
    this.patientsMeta = meta;
  };

  @action
  public setPatient = (patient: Patient | null): void => {
    this.patient = patient;
  };

  @action
  public setPatientContacts = (patient: PatientContacts | null): void => {
    this.patientContacts = patient;
  };

  @action
  public setPatientMedData = (patient: PatientMedData | null): void => {
    this.patientMedData = patient;
  };

  @action
  public setNewPatient = (patient: Patient): void => {
    this.patients = [patient, ...this.patients];
  };

  @action
  public updatePatient = (updatedPatient: Patient): void => {
    const patient = this.patients.find((e) => e.id === updatedPatient.id);
    if (patient) {
      Object.assign(patient, updatedPatient);
    }
    if (this.patient?.id === updatedPatient.id) {
      Object.assign(this.patient, updatedPatient);
    }
  };

  @action
  public deletePatient = (id: number): void => {
    this.patients = this.patients.filter((p) => p.id !== id);

    if (this.patient && this.patient.id === id) {
      this.patient = null;
    }
  };

  @action
  public updatePatientContacts = (updatedPatient: PatientContacts): void => {
    Object.assign(this.patientContacts as PatientContacts, updatedPatient);
  };

  @action
  public updatePatientMedData = (updatedPatient: PatientMedData): void => {
    Object.assign(this.patientMedData as PatientMedData, updatedPatient);
  };

  @action
  public setImportPatients = (patients: ImportPatient[]) => {
    if (!patients.length) {
      this.importPatients = [];
      return;
    }
    this.importPatients = patients.map((e, i) => {
      return this._adaptPatient(e, i + 1);
    });
  };

  @action
  public updateImportPatientsField = (
    id: number,
    field: keyof ImportPatient,
    value: any,
  ) => {
    const user = this.importPatients?.find((e) => e.id === id);
    if (user) {
      user[field] = value as never;
      user.isValid = this._adaptPatient(user).isValid;
    }
  };

  @action
  public deleteImportPatient = (id: number) => {
    this.importPatients = this.importPatients.filter((e) => e.id !== id);
  };

  private _adaptPatient = (patient: ImportPatient, index?: number) => {
    if (index) patient.id = index;
    const keys = Object.keys(patient).filter(
      (e) => e !== 'comment' && e !== 'isValid',
    );
    patient.isValid = keys.every((e) => {
      return !!patient[e as keyof ImportPatient];
    });
    return patient;
  };
}
