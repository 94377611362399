import React from 'react';
import './sidebar.scss';
import {
  NavBar,
  UserInfo,
  HealthFacility,
} from '@components/Sidebar/components';

export default function Sidebar() {
  return (
    <aside className="app-sidebar">
      <div className="content">
        <div>
          <UserInfo />
          <HealthFacility />
          <NavBar />
        </div>
      </div>
    </aside>
  );
}
