import { observable, action, makeObservable } from 'mobx';

export default class ErrorStore {
  @observable public errors: Errors = {};

  constructor() {
    makeObservable(this);
  }

  @action
  public setError = (key: ErrorKeys, value: string): void => {
    this.errors[key] = value;
  };

  @action
  public clearError = (key: ErrorKeys) => {
    this.errors[key] = undefined;
  };
}
