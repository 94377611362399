import {
  appointmentToCalendarEvent,
  convertDateEvent,
} from '@modules/Calendar/utils';

export class CalendarListener {
  constructor(protected _store: Store) {}

  public subscribe = () => {
    const { on } = this._store.WsService;

    on('appointments.create', this.#create);
    on('appointments.update', this.#update);
    on('appointments.change_status', this.#changeStatus);
    on('appointments.outside_approve', this.#update);

    on('calendar.get_calendar_table', this.#get);

    on('calendar.unsubscribe', this.#unsubscribe);

    on('calendar.create', this.#calendarCreate);
    on('calendar.update', this.#calendarUpdate);
    on('calendar.change_status', this.#calendarChangeStatus);
  };

  #unsubscribe = () => {
    const { off } = this._store.WsService;
    const { calendar } = this._store.CalendarService;

    off('appointments.create', this.#create);
    off('appointments.update', this.#update);
    off('appointments.change_status', this.#changeStatus);
    off('appointments.outside_approve', this.#update);

    off('calendar.get_calendar_table', this.#get);
    off('calendar.unsubscribe', this.#unsubscribe);

    off('calendar.create', this.#calendarCreate);
    off('calendar.update', this.#calendarUpdate);
    off('calendar.change_status', this.#calendarChangeStatus);
    calendar.store.clear();
  };

  #calendarCreate = ({ result }: WsResponse<CalendarTableResponse>): void => {
    const { store } = this._store.CalendarService.calendar;
    if (result.success) {
      result.data.events.forEach((event) => {
        store.createEvent(convertDateEvent(event));
      });
    }
  };

  #calendarUpdate = ({ result }: WsResponse<CalendarTableResponse>): void => {
    const { store, getIntervals } = this._store.CalendarService.calendar;

    if (result.success) {
      result.data.events.forEach((event) => {
        store.updateEvent(convertDateEvent(event));
      });

      const args: GetAvailableIntervalsReq[] = result.data.events.map(
        (event) => ({
          user_ids: [event.user.id],
          start_date: event.date,
          end_date: event.date,
          health_facility_ids: [event.health_facility_id],
        }),
      );

      args.forEach((arg) => getIntervals(arg));
    }
  };

  #calendarChangeStatus = ({
    result,
  }: WsResponse<CalendarTableResponse>): void => {
    const { store, getIntervals } = this._store.CalendarService.calendar;
    if (result.success) {
      result.data.events.forEach((event) => {
        store.updateEvent(convertDateEvent(event));
      });

      const { events } = result.data;
      const cancelled = events.filter((event) => event.status === 'canceled');
      if (!cancelled.length) return;

      const args: GetAvailableIntervalsReq[] = cancelled.map((event) => ({
        user_ids: [event.user.id],
        start_date: event.date,
        end_date: event.date,
        health_facility_ids: [event.health_facility_id],
      }));

      args.forEach((arg) => getIntervals(arg));
    }
  };

  #create = ({ result }: WsResponse<AppointmentType>): void => {
    const { store } = this._store.CalendarService.calendar;
    if (result.success) {
      const event = appointmentToCalendarEvent(result.data);
      store.createEvent(event);
    }
  };

  #update = ({ result }: WsResponse<AppointmentType>) => {
    const { calendar } = this._store.CalendarService;
    if (result.success) {
      const event = appointmentToCalendarEvent(result.data);
      calendar.store.updateEvent(event);

      calendar.getIntervals({
        user_ids: [result.data.user.id],
        start_date: result.data.date,
        end_date: result.data.date,
        health_facility_ids: [result.data.health_facility_id],
      });
    }
  };

  #changeStatus = ({ result }: WsResponse<AppointmentType>): void => {
    const { calendar } = this._store.CalendarService;

    if (result.success) {
      const event = appointmentToCalendarEvent(result.data);
      calendar.store.changeStatus(event);

      if (result.data.status !== 'canceled') return;
      calendar.getIntervals({
        user_ids: [result.data.user.id],
        start_date: result.data.date,
        end_date: result.data.date,
        health_facility_ids: [result.data.health_facility_id],
      });
    }
  };

  #get = ({ result }: WsResponse<CalendarTableResponse>): void => {
    const { store } = this._store.CalendarService.calendar;
    if (result.success) store.getEvents(result.data);
  };
}
