import { PreloadLazy } from '@router/utils';

export const SETTINGS_ROUTES: PrivateRoute = {
  group: 'SETTINGS',
  name: 'settings',
  path: '/settings',
  exact: false,
  sidebar: true,
  icon: 'OutlineSettings',
  activeIcon: 'Settings',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'settings',
      path: '/settings',
      exact: true,
      component: PreloadLazy(() => import('@views/Settings')),
    },
    {
      group: 'SETTINGS_MIS',
      name: 'management',
      path: '/settings/mis',
      exact: false,
      imagePath: () => import('@assets/img/preview/settings_mis.svg'),
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      bgColor: 'bg-blue-50',
      routes: [
        {
          name: 'management',
          path: '/settings/mis',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis')),
        },
        {
          name: 'employees',
          path: '/settings/mis/users',
          exact: true,
          bgColor: 'bg-blue-50',
          imagePath: () => import('@assets/img/preview/users.svg'),
          component: PreloadLazy(
            () => import('@views/Settings/Mis/Users/UsersList'),
          ),
        },
        {
          name: 'Import',
          path: '/settings/mis/users/import',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/Users/ImportUsers'),
          ),
        },
        {
          name: 'Користувач',
          path: '/settings/mis/users/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/Users/User'),
          ),
        },
        {
          name: 'roles',
          path: '/settings/mis/roles',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Roles')),
          imagePath: () => import('@assets/img/preview/roles.svg'),
        },
        {
          name: 'Роль',
          path: '/settings/mis/roles/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Role')),
        },
        {
          name: 'Сутності',
          path: '/settings/mis/entities',
          exact: true,
          hidden: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Entities')),
        },
        {
          name: 'Модулі',
          path: '/settings/mis/ui-modules',
          exact: true,
          hidden: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/UiModules')),
        },
        {
          name: 'specializations',
          path: '/settings/mis/specializations',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/Specializations'),
          ),
          imagePath: () => import('@assets/img/preview/specializations.svg'),
        },
        {
          name: 'positions',
          path: '/settings/mis/positions',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Positions')),
          imagePath: () => import('@assets/img/preview/positions.svg'),
          bgColor: 'bg-blue-50',
        },
        {
          name: 'cabinets',
          path: '/settings/mis/cabinets',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Cabinets')),
          imagePath: () => import('@assets/img/preview/cabinets.svg'),
        },
        {
          name: 'Кабінет',
          path: '/settings/mis/cabinets/:id',
          hidden: true,
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Cabinet')),
        },
        {
          name: 'Типи кабінетів',
          path: '/settings/mis/cabinet-type',
          hidden: true,
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/CabinetType'),
          ),
        },
        {
          name: 'subdivisions',
          path: '/settings/mis/health-facility',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/HealthFacilities'),
          ),
          imagePath: () => import('@assets/img/preview/hf.svg'),
        },
        {
          name: 'Підрозділ',
          path: '/settings/mis/health-facility/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/HealthFacility'),
          ),
        },
        {
          name: 'Типи підрозділів',
          path: '/settings/mis/health-facility-type',
          hidden: true,
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/HealthFacilityType'),
          ),
        },
        {
          name: 'schedule-staff',
          path: '/settings/mis/schedule-staff',
          exact: true,
          ws: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/ScheduleStaff'),
          ),
          imagePath: () => import('@assets/img/preview/schedule.svg'),
          bgColor: 'bg-blue-50',
        },
        {
          name: 'insurance',
          path: '/settings/mis/insurance',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/Insurances'),
          ),
          imagePath: () => import('@assets/img/preview/insurance.svg'),
        },
        {
          name: 'cash_places',
          path: '/settings/mis/cashplaces',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/CashPlaces'),
          ),
          imagePath: () => import('@assets/img/preview/cashplace.svg'),
        },
        {
          name: 'payers',
          path: '/settings/mis/payers',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Mis/Payers')),
          imagePath: () => import('@assets/img/preview/cashplace.svg'),
        },
        {
          name: 'referral-doctors',
          path: '/settings/mis/referral-doctors',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Mis/ReferralDoctors'),
          ),
          imagePath: () => import('@assets/img/preview/positions.svg'),
          bgColor: 'bg-blue-50',
        },
      ],
    },
    {
      group: 'SETTINGS_MEDICAL',
      name: 'med-settings',
      path: '/settings/medical',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/settings_medical.svg'),
      routes: [
        {
          name: 'med-settings',
          path: '/settings/medical',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Medical')),
        },
        {
          name: 'services',
          path: '/settings/medical/services',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Services'),
          ),
          imagePath: () => import('@assets/img/preview/med_services.svg'),
          bgColor: 'bg-blue-50',
        },
        {
          name: 'Import',
          path: '/settings/medical/services/import',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/ServicesImport'),
          ),
        },
        {
          name: 'services',
          path: '/settings/medical/services/:id',
          hidden: true,
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Service'),
          ),
        },
        {
          name: 'Категорії послуг',
          path: '/settings/medical/service-categories',
          hidden: true,
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/ServiceCategory'),
          ),
        },
        {
          name: 'Типи виконавців',
          path: '/settings/medical/service-performer-type',
          hidden: true,
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/ServicePerformerType'),
          ),
        },
        {
          name: 'prices',
          path: '/settings/medical/prices',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Prices'),
          ),
          imagePath: () => import('@assets/img/preview/prices.svg'),
        },
        {
          name: 'prices',
          path: '/settings/medical/prices/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(() => import('@views/Settings/Medical/Price')),
        },
        {
          name: 'medical_cards',
          path: '/settings/medical/medical_cards',
          exact: true,
          component: PreloadLazy(
            () =>
              import(
                '@views/Settings/Medical/MedicalCardTypes/MedicalCardTypes'
              ),
          ),
          imagePath: () => import('@assets/img/preview/medcard_type.svg'),
        },
        {
          name: 'certificates',
          path: '/settings/medical/certificates',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Certificates'),
          ),
          imagePath: () => import('@assets/img/preview/medcard_type.svg'),
        },
        {
          name: 'preferential',
          path: '/settings/medical/preferential',
          exact: true,
          component: PreloadLazy(
            () =>
              import('@views/Settings/Medical/Preferential/PreferentialList'),
          ),
          imagePath: () => import('@assets/img/preview/medcard_type.svg'),
        },
        {
          name: 'preferential',
          path: '/settings/medical/preferential/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Preferential/Preferential'),
          ),
        },
        {
          name: 'counterparty',
          path: '/settings/medical/counterparty',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Medical/Counterparty'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
        {
          name: 'service_packages',
          path: '/settings/medical/packages',
          exact: true,
          component: PreloadLazy(
            () =>
              import(
                '@views/Settings/Medical/ServicePackages/ServicePackagesList'
              ),
          ),
          imagePath: () => import('@assets/img/preview/medcard_type.svg'),
        },
        {
          name: 'service_package',
          path: '/settings/medical/packages/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () =>
              import('@views/Settings/Medical/ServicePackages/ServicePackage'),
          ),
        },
      ],
    },
    {
      group: 'SETTINGS_DOCUMENTS',
      name: 'doc-settings',
      path: '/settings/documents',
      exact: false,
      imagePath: () => import('@assets/img/preview/settings_documents.svg'),
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      routes: [
        {
          name: 'doc-settings',
          path: '/settings/documents',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Documents')),
        },
        // ELEMENTS
        {
          name: 'doc-elements',
          path: '/settings/documents/elements',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Elements'),
          ),
        },
        {
          name: 'doc-elements',
          path: '/settings/documents/elements/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Elements/Element'),
          ),
        },
        {
          name: 'doc-elements',
          path: '/settings/documents/element-groups',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Elements/Groups'),
          ),
        },
        // CHAPTERS
        {
          name: 'doc-chapters',
          path: '/settings/documents/chapters',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Chapters'),
          ),
        },
        {
          name: 'doc-chapters',
          path: '/settings/documents/chapters/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Chapters/Chapter'),
          ),
        },
        {
          name: 'doc-chapters',
          path: '/settings/documents/chapters/:id/preview',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () =>
              import(
                '@views/Settings/Documents/Chapters/Chapter/DocChapterPreview'
              ),
          ),
        },
        {
          name: 'doc-chapters',
          path: '/settings/documents/chapter-groups',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Chapters/Groups'),
          ),
        },
        // TEMPLATES
        {
          name: 'doc-templates',
          path: '/settings/documents/templates',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Templates'),
          ),
        },
        {
          name: 'doc-templates',
          path: '/settings/documents/templates/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Templates/Template'),
          ),
        },
        {
          name: 'doc-templates',
          path: '/settings/documents/templates/:id/preview',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Templates/Preview'),
          ),
        },
        {
          name: 'doc-templates',
          path: '/settings/documents/templates/:id/print',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Templates/Template/Print'),
          ),
        },
        {
          name: 'doc-templates',
          path: '/settings/documents/template-groups',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Settings/Documents/Templates/Groups'),
          ),
        },
      ],
    },
    {
      group: 'DEVICE_SETTINGS',
      name: 'device-settings',
      path: '/settings/devices',
      exact: false,
      imagePath: () => import('@assets/img/preview/settings_documents.svg'),
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      routes: [
        {
          name: 'device-settings',
          path: '/settings/devices',
          exact: true,
          component: PreloadLazy(() => import('@views/Settings/Devices')),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
        {
          name: 'device-printers',
          path: '/settings/devices/printers',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Devices/Printers'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
        {
          name: 'device-analysers',
          path: '/settings/devices/analysers',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Settings/Devices/Analysers'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
        },
      ],
    },
    // {
    //   name: 'Ваш профіль',
    //   path: '/settings/profile',
    //   exact: true,
    //   component: PreloadLazy(() => import('@views/Settings/Profile')),
    // },
  ],
};
