import { FetchService } from '@modules/Api';

export class PackageServiceApi extends FetchService {
  public getPackageServices = (query?: GetPackageServiceQuery) => {
    return this.request<
      'PACKAGE_SERVICE',
      PackageServiceShort[],
      null,
      GetPackageServiceQuery
    >('/package_service', 'GET_PACKAGE_SERVICES', {
      query,
    });
  };

  public getPackageService = (id: number) => {
    return this.request<'PACKAGE_SERVICE', PackageService>(
      '/package_service',
      'GET_PACKAGE_SERVICE',
      {
        pathParam: id,
      },
    );
  };

  public createPackageService = (params: CreatePackageService) => {
    return this.request<
      'PACKAGE_SERVICE',
      PackageServiceShort,
      CreatePackageService
    >('/package_service', 'CREATE_PACKAGE_SERVICE', {
      method: 'POST',
      params,
    });
  };

  public updatePackageService = (id: number, params: UpdatePackageService) => {
    return this.request<
      'PACKAGE_SERVICE',
      PackageServiceShort,
      UpdatePackageService
    >('/package_service', 'UPDATE_PACKAGE_SERVICE', {
      method: 'PUT',
      pathParam: id,
      params,
    });
  };

  public activatePackageService = (id: number) => {
    return this.request<'PACKAGE_SERVICE', PackageServiceShort>(
      '/package_service',
      'ACTIVATE_PACKAGE_SERVICE',
      {
        method: 'PUT',
        pathParam: `${id}/activate`,
      },
    );
  };

  public deactivatePackageService = (id: number) => {
    return this.request<'PACKAGE_SERVICE', PackageServiceShort>(
      '/package_service',
      'DEACTIVATE_PACKAGE_SERVICE',
      {
        method: 'PUT',
        pathParam: `${id}/deactivate`,
      },
    );
  };

  public addServiceToPackageService = (
    id: number,
    params: AddServiceToPackageService,
  ) => {
    return this.request<'PACKAGE_SERVICE', PackageService>(
      '/package_service',
      'ADD_SERVICE_TO_PACKAGE_SERVICE',
      {
        method: 'POST',
        pathParam: `${id}/service`,
        params,
      },
    );
  };

  public deletePackageService = (id: number) => {
    return this.request<'PACKAGE_SERVICE'>(
      '/package_service',
      'DELETE_PACKAGE_SERVICE',
      {
        method: 'DELETE',
        pathParam: id,
      },
    );
  };

  public updatePackagePrice = (
    packageServiceId: number,
    params: UpdatePackageServiceInterest,
  ) => {
    return this.request<'PACKAGE_SERVICE', PackageService>(
      '/package_service',
      'UPDATE_PACKAGE_PRICE',
      {
        method: 'PUT',
        pathParam: `${packageServiceId}/percentage`,
        params,
      },
    );
  };

  public updatePackageGroupPrice = (
    packageServiceId: number,
    serviceCatId: number,
    params: UpdatePackageServiceInterest,
  ) => {
    return this.request<'PACKAGE_SERVICE', PackageService>(
      '/package_service',
      'UPDATE_PACKAGE_GROUP_PRICE',
      {
        method: 'PUT',
        pathParam: `${packageServiceId}/percentage/group/${serviceCatId}`,
        params,
      },
    );
  };

  public updatePackageGroupServicePrice = (
    packageServiceId: number,
    serviceId: number,
    params: UpdatePackageServiceInterest,
  ) => {
    return this.request<'PACKAGE_SERVICE', PackageService>(
      '/package_service',
      'UPDATE_PACKAGE_GROUP_SERVICE_PRICE',
      {
        method: 'PUT',
        pathParam: `${packageServiceId}/percentage/service/${serviceId}`,
        params,
      },
    );
  };

  public updatePackageGroupServiceCount = (
    packageId: number,
    params: UpdatePackageGroupServiceCount,
  ) => {
    return this.request<
      'PACKAGE_SERVICE',
      PackageService,
      UpdatePackageGroupServiceCount
    >('/package_service', 'UPDATE_PACKAGE_GROUP_SERVICE_COUNT', {
      method: 'PUT',
      pathParam: `${packageId}/service`,
      params,
    });
  };

  public deleteServiceFromPackageService = (
    packageId: number,
    serviceId: number,
  ) => {
    return this.request<'PACKAGE_SERVICE'>(
      '/package_service',
      'DELETE_SERVICE_FROM_PACKAGE_SERVICE',
      {
        method: 'DELETE',
        pathParam: `${packageId}/service/${serviceId}`,
      },
    );
  };

  public calcPackageService = (
    packageId: number,
    params: GetCalcPackageService,
  ) => {
    return this.request<'PACKAGE_SERVICE', CalcPackageService>(
      '/package_service',
      'CALC_PACKAGE_SERVICE',
      {
        method: 'PUT',
        pathParam: `${packageId}/calculate`,
        params,
      },
    );
  };

  public calcGroupPackageService = (
    packageId: number,
    catId: number,
    params: GetCalcPackageService,
  ) => {
    return this.request<'PACKAGE_SERVICE', CalcPackageService>(
      '/package_service',
      'CALC_GROUP_PACKAGE_SERVICE',
      {
        method: 'PUT',
        pathParam: `${packageId}/calculate/group/${catId}`,
        params,
      },
    );
  };

  public calcServicePackageService = (
    packageId: number,
    serviceId: number,
    params: GetCalcPackageService,
  ) => {
    return this.request<'PACKAGE_SERVICE', CalcPackageService>(
      '/package_service',
      'CALC_SERVICE_PACKAGE_SERVICE',
      {
        method: 'PUT',
        pathParam: `${packageId}/calculate/service/${serviceId}`,
        params,
      },
    );
  };
}
