import { LStorage } from '@modules/Storage';
import { action, observable, makeObservable, autorun } from 'mobx';

// TMP: Should be deleted later
type PossibleHfSelectType = HFSelectType | SelectType<number>;

export type HFSelectType = HealthFacility | null;

class HealthFacilityFilterState {
  @observable public healthFacility: HFSelectType = null;

  constructor() {
    makeObservable(this);
    this._setInitial();

    autorun(() => {
      LStorage.setItem('HEALTH_FACILITY_FILTER', this.healthFacility);
    });
  }

  @action
  private _setInitial = () => {
    try {
      const storage = LStorage.getItem<PossibleHfSelectType>(
        'HEALTH_FACILITY_FILTER',
        true,
      );

      const isValid = (data: PossibleHfSelectType): data is HealthFacility => {
        if (!data) return false;
        return Object.hasOwn(data, 'id') && Object.hasOwn(data, 'title');
      };

      this.setHealthFacility(isValid(storage) ? storage : null);
    } catch {
      this.setHealthFacility(null);
    }
  };

  @action
  public setIfEmpty = (data: HFSelectType) => {
    if (this.healthFacility) return;
    this.setHealthFacility(data);
  };

  @action
  public setHealthFacility = (data: HFSelectType) => {
    this.healthFacility = data;
  };
}

export default new HealthFacilityFilterState();
