export const KANBAN_COLUMNS: KanbanColumn[] = [
  {
    label: 'kanban.statuses.waiting',
    isOpen: true,
    status: 'planned',
    arrowType: 'success',
    arrowIcon: 'ChevronNext',
    events: [],
  },
  {
    label: 'kanban.statuses.in_clinic',
    isOpen: true,
    status: 'in_clinic',
    arrowType: 'warning',
    arrowIcon: 'ChevronNext',
    events: [],
  },
  {
    label: 'kanban.statuses.on_appoinment',
    isOpen: true,
    status: 'active_visit',
    arrowType: 'primary',
    arrowIcon: 'ChevronNext',
    events: [],
  },
  {
    label: 'kanban.statuses.payment',
    isOpen: true,
    status: 'on_payment',
    arrowType: 'success',
    arrowIcon: 'Done',
    events: [],
  },
  {
    label: 'kanban.statuses.completed',
    isOpen: true,
    status: 'done',
    arrowType: 'secondary',
    arrowIcon: 'Done',
    events: [],
  },
];
