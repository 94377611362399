import { ROUTES } from '@router';

/**
 * GetRoutesBy
 */
type GetRoutesBy = (params: {
  field: keyof AppRouter;
  value: ValueOf<BaseRoute> | RouteGroup;
  routes?: readonly AppRouter[];
  deep?: boolean;
  exact?: boolean;
}) => AppRouter[];

/**
 * baseGetRouteBy
 * @param field
 * @param value
 * @param routes
 * @param deep
 * @param exact
 */
export const baseGetRouteBy: GetRoutesBy = ({
  field,
  value,
  routes,
  deep,
  exact,
}) => {
  const result = [];

  for (const route of routes || []) {
    if (exact && route[field] === value) {
      result.push(route);
    }
    if (!exact && route[field]) {
      const _val = value.split('/').filter(Boolean);
      const [val] = _val.length ? _val : ['/'];

      const _routePath = route[field].split('/').filter(Boolean);
      const [routePath] = _routePath.length ? _routePath : ['/'];

      if (routePath === val) {
        result.push(route);
      }
    }
    if (deep && route.routes?.length) {
      const nestedRoute = getRouteBy({
        field,
        value,
        routes: route.routes,
        deep,
        exact,
      });
      result.push(...nestedRoute);
    }
  }

  return result;
};

/**
 * getRouteBy
 * @param params
 */
export const getRouteBy: GetRoutesBy = (params) =>
  baseGetRouteBy({
    routes: params.routes || ROUTES,
    deep: params.deep || true,
    exact: params.exact || true,
    ...params,
  });
