function initWidget(e: Document, o: any) {
  !window.deskie ? (window.deskie = []) : '';
  window.deskie.push(o);
  o.g_config = { widget_id: '80-wr14yfuc' };
  o.type_widget = 'messengers_mail';
  o.email_widget = o.email_widget || {};
  const w = o.email_widget;
  w.readyQueue = [];
  o.config = function (e: any) {
    this.g_config.user = e;
  };
  w.ready = function (e: any) {
    this.readyQueue.push(e);
  };
  const r = e.getElementsByTagName('script')[0];
  const c = e.createElement('script');
  c.type = 'text/javascript';
  c.async = !0;
  c.src = 'https://deskie.io/bundles/acmesite/js/cwidget0.2.min.js';
  r.parentNode?.insertBefore(c, r);
}

if (!__DEV__) {
  initWidget(document, []);
}
