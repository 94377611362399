import { action, makeObservable, observable } from 'mobx';

export class AppStore {
  @observable public serverError = false;
  @observable public initialized = false;
  @observable public sysInitialized = false;
  @observable public appInitialized = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public setServerError = (hasError: boolean) => {
    this.serverError = hasError;
  };

  @action
  public setInitialization = (
    value: boolean,
    key: 'initialized' | 'sysInitialized' | 'appInitialized' = 'initialized',
  ): void => {
    this[key] = value;
  };
}
