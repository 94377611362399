import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronLast = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      fill="none"
      viewBox="0 0 13 12"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.59 1.41 5.18 6 .59 10.59 2 12l6-6-6-6zM11 0h2v12h-2z"
      />
    </svg>
  );
};
export default SvgChevronLast;
