import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';
import { Role } from '@modules/Roles/mapper/Role';
import { User } from '@modules/Users/mappers/User';

export default class RoleStore {
  @observable public roles: Role[] = [];
  @observable public role: Role | null = null;
  @observable public rolesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public roleUsers: User[] = [];
  @observable public roleUsersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setRoles = (roles: RoleType[], meta: ResponseMeta): void => {
    this.roles = sortBy(roles, ['name'], ['asc']).map(
      (e) => new Role(e as Role),
    ) as Role[];
    this.rolesMeta = meta;
  };

  @action
  public setRole = (role: RoleType | null): void => {
    this.role = role ? new Role(role) : null;
  };

  @action
  public addRole = (role: RoleType): void => {
    this.roles = [new Role(role), ...this.roles];
  };

  @action
  public removeRole = (id: number): void => {
    this.roles = this.roles.filter((e) => e.id !== id);
  };

  @action
  public updateRole = (
    roleId: number,
    key: keyof Role,
    data: unknown,
  ): void => {
    const role = this.roles.find((e) => e.id === roleId);
    if (role) {
      role.setValue(key, data);
    }
    // single
    if (this.role?.id === roleId) {
      this.role.setValue(key, data);
    }
  };

  @action
  public setRoleLoading = (
    roleId: number,
    key: 'uiModules' | 'permissions',
    value: boolean,
  ): void => {
    const role = this.roles.find((e) => e.id === roleId);
    if (role) {
      role.setLoading(key, value);
    }
  };

  @action
  public setPermission = (data: CreatePermissionResponse) => {
    if (!this.role) return;
    this.role.permissions = [
      {
        id: data.id,
        c: data.c,
        r: data.r,
        u: data.u,
        d: data.d,
        entity: data.entity,
      },
      ...this.role.permissions,
    ];
  };

  @action
  public updatePermission = (updatedPermission: Permission): void => {
    if (!this.role) return;
    const permission = this.role?.permissions.find(
      (e) => e.id === updatedPermission.id,
    );
    if (!permission) return;
    permission.c = updatedPermission.c;
    permission.r = updatedPermission.r;
    permission.u = updatedPermission.u;
    permission.d = updatedPermission.d;
  };

  @action
  public removePermission = (permissionId: number): void => {
    if (!this.role) return;
    this.role.permissions = this.role.permissions.filter(
      (e) => e.id !== permissionId,
    );
  };

  @action
  public connectNewUiModule = (data: SetRoleUiModuleResponse) => {
    if (this.role?.id === data.role.id) {
      const addedModule = {
        ...data.ui_module,
        offset: data.ui_module.name.split('/').filter(Boolean).length - 1,
        record_id: data.id,
      } as unknown as UiModule;

      const index = this.role.uiModules.findIndex(
        (e) => e.id === data.ui_module.parent?.id,
      );

      if (index && index >= 0) {
        const uiModules = [...this.role.uiModules];
        uiModules.splice(index + 1, 0, addedModule);
        this.role.uiModules = uiModules;
        return;
      }

      this.role.setValue('uiModules', [...this.role.uiModules, addedModule]);
    }
  };

  @action
  public removeUiModule = (recordId: number) => {
    if (!this.role) return;
    this.role.uiModules = this.role.uiModules.filter(
      (e) => e.record_id !== recordId,
    );
  };

  @action
  public setUsers = (users: UserResponse[], meta: ResponseMeta) => {
    this.roleUsers = users.map((e) => new User(e));
    this.roleUsersMeta = meta;
  };

  @action
  public addUser = (user: UserResponse) => {
    this.roleUsers = [new User(user), ...this.roleUsers];
  };

  @action
  public removeUser = ({ user_id }: RoleToUserReq) => {
    this.roleUsers = this.roleUsers.filter((e) => e.id !== user_id);
  };
}
