import { FetchService } from '@modules/Api';

export class AppealApi extends FetchService {
  public getAppeals = (query?: GetAppealsQuery) => {
    return this.request<'APPEALS', Appeal[], null, GetAppealsQuery>(
      '/appeals',
      'GET_APPEALS',
      { query, permit: 'appeals' },
    );
  };

  public getAppeal = (appealId: number) => {
    return this.request<'APPEALS', Appeal>('/appeals', 'GET_APPEAL', {
      pathParam: appealId,
      permit: 'appeals',
    });
  };

  public createAppeal = (params: CreateAppealReq) => {
    return this.request<'APPEALS', Appeal, CreateAppealReq>(
      '/appeals',
      'CREATE_APPEAL',
      {
        method: 'POST',
        params,
        permit: 'appeals',
      },
    );
  };

  public updateAppeal = (appealId: number, params: UpdateAppealReq) => {
    return this.request<'APPEALS', Appeal, UpdateAppealReq>(
      '/appeals',
      'UPDATE_APPEAL',
      {
        method: 'PUT',
        params,
        pathParam: appealId,
        permit: 'appeals',
      },
    );
  };
}
