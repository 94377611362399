import React from 'react';
import { Text } from '@components/UI/Text';
import { Icon } from '@components/UI/Icon';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import './Drawer.scss';

function DrawerError() {
  const { clearError, error } = useService('DrawerService');
  if (!error || !error.message) return null;

  return (
    <div className="drawer-error">
      <Text type="danger" tag="blockquote" className="drawer-error__text">
        <Text tag="h4">{error.message}</Text>
        {error.detail && (
          <Text tag="p" className="drawer-error-detail">
            {error.detail}
          </Text>
        )}
      </Text>
      <button className="drawer-error-close" onClick={clearError}>
        <Icon name="Close" className="text-coolGray-500" />
      </button>
    </div>
  );
}

export default observer(DrawerError);
