import React from 'react';
import './AddSuccess.scss';

export default function AddSuccess({
  title = 'AddSuccess',
  message = 'message',
}: NotifyParams) {
  return (
    <div className="text-white">
      <div className="text-white text-lg font-600 mb-1">{title}</div>
      <div className="text-coolGray-200 font-400">{message}</div>
    </div>
  );
}
