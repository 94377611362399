import { FetchService } from '@modules/Api';

export class PatientPkgServiceApi extends FetchService {
  public getPatientPkgServices = (query?: GetPatientPkgServicesQuery) => {
    return this.request<
      'PATIENT_PACKAGE_SERVICE',
      PatientPkgService[],
      null,
      GetPatientPkgServicesQuery
    >('/patient_package_service', 'GET_PATIENT_PACKAGE_SERVICES', {
      query,
    });
  };

  public getPatientPkgService = (patientPkgServiceId: number) => {
    return this.request<'PATIENT_PACKAGE_SERVICE', PatientPkgService>(
      '/patient_package_service',
      'GET_PATIENT_PACKAGE_SERVICE',
      {
        pathParam: patientPkgServiceId,
      },
    );
  };

  public addPkgServiceToPatient = (params: AddPkgServiceToPatient) => {
    return this.request<
      'PATIENT_PACKAGE_SERVICE',
      PatientPkgService[],
      AddPkgServiceToPatient
    >('/patient_package_service', 'ADD_PACKAGE_SERVICE_TO_PATIENT', {
      method: 'POST',
      params,
    });
  };

  public removePkgServiceFromPatient = (patientPkgServiceId: number) => {
    return this.request<'PATIENT_PACKAGE_SERVICE'>(
      '/patient_package_service',
      'REMOVE_PACKAGE_SERVICE_FROM_PATIENT',
      {
        method: 'DELETE',
        pathParam: patientPkgServiceId,
      },
    );
  };
}
