import { PaymentStore } from '@modules/Payment/payment.store';
import { PAYMENT_TYPE } from '@modules/Payment/consts';
import i18next from '@lib/i18next';

export class PaymentService extends PaymentStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPaymentsReq = async (query?: GetPaymentsQuery) => {
    const res = await this._store.PaymentApi.getPayments(query);
    if (res.success && res.data) {
      this.setPayments(res.data, res.meta);
    }
    return res;
  };

  public getCancelPaymentReq = async (id: number) => {
    const res = await this._store.PaymentApi.getCancelPayment(id);
    if (res.success && res.data) {
      this.updatePayments(res.data);
    }
    return res;
  };

  public getProcessPaymentReq = async (id: number) => {
    const res = await this._store.PaymentApi.getProcessPayment(id);
    if (res.success && res.data) {
      this.updatePayment(res.data);
    }
    return res;
  };

  public getPaymentReportsDataReq = async (query?: GetPaymentReportQuery) => {
    const res = await this._store.PaymentApi.getPaymentReportsData(query);
    if (res.success && res.data) {
      this.setPaymentReportData(res.data, res.meta);
    }
    return res;
  };

  public getPaymentReportsHeaderReq = async (query?: GetPaymentReportQuery) => {
    const res = await this._store.PaymentApi.getPaymentReportsHeader(query);
    if (res.success && res.data) {
      this.setPaymentReportHeader(res.data);
    }
    return res;
  };

  public createPaymentRequest = async (params: CreatePayment) => {
    const res = await this._store.PaymentApi.createPayment(params);
    if (res.success && res.data) {
      this.setCreatedPayment(res.data);
    }
    return res;
  };

  public createBatchPaymentRequest = async (params: CreateBatchPayment) => {
    return await this._store.PaymentApi.createBatchPayment(params);
  };

  public createBalancePaymentReq = async (params: CreateBalancePayment) => {
    return await this._store.PaymentApi.createBalancePayment(params);
  };

  public createRefundPaymentReq = async (params: CreateRefundPayment) => {
    return await this._store.PaymentApi.createRefundPayment(params);
  };

  public getPaymentType = (
    val: PaymentType,
  ): SelectType<PaymentType> | null => {
    const type = Object.entries(PAYMENT_TYPE).find(
      ([_label, value]) => value === val,
    );
    if (type?.length) {
      return {
        label: i18next.t(`layout:payment_type.${type[0]}`),
        value: type[1],
      };
    }
    return null;
  };

  public getGroupedReportReq = async (query: GetGroupedReport) => {
    const res = await this._store.PaymentApi.getGroupedPaymentReport(query);
    if (res.success && res.data) {
      this.setGroupedReport(res.data);
    }
    return res;
  };

  public downloadGroupedReportReq = (query: GetGroupedReport) => {
    return this._store.PaymentApi.downloadGroupedPaymentReport(query);
  };
}
