import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPuzzle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={19}
      fill="none"
      viewBox="0 0 19 19"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.083 9.167h-1.25V5.833c0-.916-.75-1.666-1.666-1.666H9.833v-1.25a2.084 2.084 0 0 0-4.166 0v1.25H2.333c-.916 0-1.658.75-1.658 1.666V9h1.242a2.251 2.251 0 0 1 0 4.5H.667v3.167c0 .916.75 1.666 1.666 1.666H5.5v-1.25a2.25 2.25 0 0 1 2.25-2.25 2.25 2.25 0 0 1 2.25 2.25v1.25h3.167c.916 0 1.666-.75 1.666-1.666v-3.334h1.25a2.084 2.084 0 0 0 0-4.166"
      />
    </svg>
  );
};
export default SvgPuzzle;
