import { action, makeObservable, observable } from 'mobx';

export default class LabServicesStore {
  @observable public service: ServiceResponse | null = null;
  @observable public services: ServiceResponse[] = [];
  @observable public servicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public serviceCategories: ServiceCategory[] = [];
  @observable public serviceCategoriesMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };

  @observable public serviceSetting: LabServiceSetting | null = null;
  @observable public servicesSettings: LabServiceSetting[] = [];
  @observable public servicesSettingsMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };

  @observable public serviceProperties: ServiceProperty[] = [];
  @observable public servicePropertiesMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };

  @observable public serviceCabinets: CabinetResponse[] = [];
  @observable public serviceCabinetsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  //
  // SERVICES
  //
  @action
  public setServices = (data: ServiceResponse[], meta: ResponseMeta): void => {
    this.services = data;
    this.servicesMeta = meta;
  };

  @action
  public setService = (data: ServiceResponse | null): void => {
    this.service = data;
  };

  @action
  public clearService = (): void => {
    this.service = null;
  };

  @action
  public addService = (data: ServiceResponse): void => {
    this.services = [data, ...this.services];
  };

  @action
  public updateService = (data: ServiceResponse): void => {
    const service = this.services.find((c) => c.id === data.id);
    if (service) {
      Object.assign(service, data);
    }
    if (this.service && this.service.id === data.id) {
      this.setService(data);
    }
  };

  @action
  public removeService = (id: number): void => {
    this.services = this.services.filter((c) => c.id !== id);
  };

  //
  // SERVICE CATEGORIES
  //
  @action
  public setServiceCategories = (
    data: ServiceCategory[],
    meta: ResponseMeta,
  ): void => {
    this.serviceCategories = data;
    this.serviceCategoriesMeta = meta;
  };

  @action
  public addServiceCategory = (data: ServiceCategory): void => {
    this.serviceCategories = [data, ...this.serviceCategories];
  };

  @action
  public updateServiceCategory = (data: ServiceCategory): void => {
    this.serviceCategories = this.serviceCategories.map((c) =>
      c.id === data.id ? { ...c, ...data } : c,
    );
  };

  @action
  public removeServiceCategory = (id: number): void => {
    this.serviceCategories = this.serviceCategories.filter((c) => c.id !== id);
  };

  //
  // SERVICES SETTINGS
  //
  @action
  public setServicesSettings = (
    data: LabServiceSetting[],
    meta: ResponseMeta,
  ): void => {
    this.servicesSettings = data;
    this.servicesMeta = meta;
  };

  @action
  public setServiceSetting = (data: LabServiceSetting): void => {
    this.serviceSetting = data;
  };

  @action
  public clearServiceSetting = (): void => {
    this.service = null;
  };

  @action
  public addServiceSetting = (data: LabServiceSetting): void => {
    this.servicesSettings = [data, ...this.servicesSettings];
  };

  @action
  public updateServiceSetting = (data: LabServiceSetting): void => {
    const s = this.servicesSettings.find((c) => c.id === data.id);

    if (s) {
      Object.assign(s, data);
    }

    if (this.serviceSetting && this.serviceSetting.id === data.id) {
      this.setServiceSetting(data);
    }
  };

  @action
  public removeServiceSetting = (id: number): void => {
    this.servicesSettings = this.servicesSettings.filter((c) => c.id !== id);
  };

  // SERVICE PROPERTIES
  @action
  public setServiceProperties = (data: ServiceProperty[]) => {
    this.serviceProperties = data;
  };

  @action
  public addServiceProperty = (data: ServiceProperty) => {
    this.serviceProperties = this.serviceProperties.concat(data);
  };

  @action
  public updateServiceProperty = (data: ServiceProperty) => {
    const item = this.serviceProperties.find((e) => e.id === data.id);
    if (item) {
      Object.assign(item, data);
    }
  };

  @action
  public deleteServiceProperty = (index: number) => {
    this.serviceProperties = this.serviceProperties.filter(
      (_, idx) => idx !== index,
    );
  };

  @action
  public updateServicePropertyPosition = (
    data: ServicePropertyPosition,
    prevPosition?: number,
  ) => {
    const serviceProperty = this.serviceProperties.find(
      (property) =>
        property.id == data.service_property_id &&
        property.position === prevPosition,
    );
    if (serviceProperty) {
      serviceProperty.position = data.position;
    }
  };

  // SERVICE CABINETS
  @action
  public setCabinets = (data: CabinetResponse[], meta: ResponseMeta) => {
    this.serviceCabinets = data;
    this.servicesMeta = meta;
  };

  @action
  public addCabinet = (data: CabinetResponse) => {
    this.serviceCabinets = this.serviceCabinets.concat(data);
  };

  @action
  public removeCabinet = (id: number) => {
    this.serviceCabinets = this.serviceCabinets.filter(
      (cabinet) => cabinet.id !== id,
    );
  };
}
