import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import PageLoader from '@components/UI/PageLoader';

type Props = {
  children: React.JSX.Element;
};

const InitProvider: React.FC<Props> = ({ children }) => {
  const { initialized, appInitialized, init, appInit, serverError } =
    useService('AppService');
  const { isConnected } = useService('WsService');
  const { isAuth } = useService('AuthService');

  useEffect(() => {
    if (!initialized) {
      init();
    }
  }, [init, initialized]);

  useEffect(() => {
    if (!appInitialized && isAuth && isConnected) {
      appInit();
    }
  }, [appInit, isAuth, appInitialized, isConnected]);

  return initialized && !serverError ? children : <PageLoader />;
};

export default observer(InitProvider);
