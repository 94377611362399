import React, { useCallback, useEffect, useRef } from 'react';
import Portal from '../Portal';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import useOutsideClick from '@utils/hooks/useOutsideClick';
import ModalSlot from './ModalSlot';
import './Modal.scss';

function ModalProvider(): React.JSX.Element | null {
  const { isOpen, close, modalProps } = useService('ModalService');
  const pRef = useRef<HTMLDivElement>(null);
  useOutsideClick(pRef, close);

  const closeOnKey = useCallback(
    (e: KeyboardEvent) => {
      if (isOpen && e.key === 'Escape') {
        close();
      }
    },
    [close, isOpen],
  );

  useEffect(() => {
    document.addEventListener('keydown', closeOnKey);
    return () => {
      document.removeEventListener('keydown', closeOnKey);
    };
  }, [closeOnKey]);

  return (
    <Portal>
      <div className={cn(['rm-modal-wrap'])}>
        <div className={cn({ overlay: isOpen })} />
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="transition"
          unmountOnExit
        >
          <div
            ref={pRef}
            style={{ width: modalProps?.width }}
            className={cn([
              'rm-modal',
              modalProps?.classNames,
              modalProps.component,
            ])}
            data-testid="modal"
          >
            {ModalSlot(modalProps as ModalProps)}
          </div>
        </CSSTransition>
      </div>
    </Portal>
  );
}

export default observer(ModalProvider);
