import { VisitStore } from '@modules/Visit/visit.store';

export class VisitService extends VisitStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getVisitsReq = async (query?: GetVisitsQuery) => {
    const res = await this._store.VisitApi.getVisits(query);
    if (res.success && res.data) {
      this.setVisits(res.data, res.meta);
    }
    return res;
  };

  public getVisitReq = async (id: number) => {
    const res = await this._store.VisitApi.getVisit(id);
    if (res.success && res.data) {
      this.setVisit(res.data);
    }
  };

  public updateVisitReq = async (id: number, params: UpdateVisitReq) => {
    const res = await this._store.VisitApi.updateVisit(id, params);
    if (res.success && res.data) {
      this.updateVisit(res.data);
    }
  };
}
