import React, { memo } from 'react';
import cn from 'classnames';
import './Loader.scss';

export type Props = {
  className?: string;
  size?: 'large' | 'medium' | 'small' | 'mini';
  color?: 'white' | 'dark' | 'blue';
};

function Loader({ className, size = 'medium', color = 'dark' }: Props) {
  return (
    <div className={cn('loader', className)} data-testid="loader">
      <div
        aria-label="Content is loading..."
        aria-live="polite"
        role="progressbar"
        id="preview-loader"
        className={cn('css-cfvyep', size, color)}
      />
    </div>
  );
}

export default memo(Loader);
