import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPrinterWarnWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <mask
        id="a"
        width={16}
        height={16}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 0h16v16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#fff"
          d="M4 14v-2.667H1.333v-4q0-.849.583-1.425a1.94 1.94 0 0 1 1.417-.575h9.333q.667 0 1.175.375.509.375.709.959H12q-.55 0-.942.391a1.28 1.28 0 0 0-.392.942v2H5.333v2.667h5.333V14zm0-9.333V2h8v2.667zM12.666 14a.65.65 0 0 1-.475-.192.65.65 0 0 1-.191-.475q0-.283.191-.475a.65.65 0 0 1 .475-.191q.285 0 .475.191a.65.65 0 0 1 .192.475.65.65 0 0 1-.192.475.65.65 0 0 1-.475.192M12 11.333V8h1.333v3.333z"
        />
      </g>
    </svg>
  );
};
export default SvgPrinterWarnWhite;
