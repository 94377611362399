import { action, computed, makeObservable, observable } from 'mobx';

type PackageServiceAdapterProps = {
  service: PatientPkgServiceService;
  pkg: DoctorWorkplace.PackageAdapterT;
};

export class PackageServiceAdapter {
  @observable private _pkg: DoctorWorkplace.PackageAdapterT;
  @observable public service: PatientPkgServiceService;
  @observable public availableCount = 0;
  @observable public usedCount: number;
  @observable public totalCount: number;
  @observable public inSelection: boolean;

  constructor({ service, pkg }: PackageServiceAdapterProps) {
    this._pkg = pkg;
    this.service = service;
    this.availableCount = service.total_count - service.frizzed_count;
    this.usedCount = service.frizzed_count;
    this.totalCount = service.total_count;
    this.inSelection = service.participates_in_the_selection;

    makeObservable(this);
  }

  @computed
  public get limitForUse() {
    return this._pkg.serviceLimit - this._pkg.serviceLimitUsed;
  }

  @computed
  public get canAdd() {
    if (this.inSelection) {
      return this.limitForUse > 0 && this.availableCount > 0;
    }

    return this.availableCount > 0;
  }

  @action
  public increaseCount = (count = 1) => {
    this.availableCount += count;

    if (this.inSelection) {
      this._pkg.setServiceLimit('remove', 1);
    }
  };

  @action
  public decreaseCount = (count = 1) => {
    this.availableCount -= count;

    if (this.inSelection) {
      this._pkg.setServiceLimit('add', 1);
    }
  };
}
