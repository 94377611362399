import React from 'react';
import './topbar-right.scss';
import WsIndicator from '@components/Header/components/TopbarRight/WsIndicator';
import LanguageSwitcher from '@components/Header/components/TopbarRight/LanguageSwitcher';
import HistoryAppointment from '@components/Header/components/TopbarRight/HistoryAppointment';
import ActiveVisit from '@components/Header/components/TopbarRight/ActiveVisit';
import ActiveAppeal from '@components/Header/components/TopbarRight/ActiveAppeal';
import RouteTitle from '@components/Header/components/TopbarRight/RouteTitle/RouteTitle';
import LogoutBtn from '@components/Header/components/TopbarRight/LogoutBtn';
import { BackButton } from './BackButton';

function TopbarRight() {
  return (
    <div className="topbar-right">
      <div className="flex items-center">
        <BackButton />
        <RouteTitle />
      </div>
      <div className="actions">
        <ActiveAppeal />
        <ActiveVisit />
        <WsIndicator />
        <HistoryAppointment />
        <LanguageSwitcher />
        <LogoutBtn />
      </div>
    </div>
  );
}

export default TopbarRight;
