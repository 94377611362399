import { OrderStore } from '@modules/Order/order.store';

export class OrderService extends OrderStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getOrdersReq = async (query?: GetOrdersQuery) => {
    const res = await this._store.OrdersApi.getOrders(query);
    if (res.success && res.data) {
      this.setOrders(res.data, res.meta);
    }
    return res;
  };

  public getOrderReq = async (id: number) => {
    const res = await this._store.OrdersApi.getOrder(id);
    if (res.success && res.data) {
      this.setOrder(res.data, id);
    }
    return res;
  };

  public downloadOrderReportReq = (query?: GetOrdersReportsParams) => {
    return this._store.OrdersApi.downloadOrderReport(
      query as GetOrdersReportsParams,
    );
  };

  public getOrderReportDataReq = async (query?: GetOrderReportDataQuery) => {
    const res = await this._store.OrdersApi.getOrderReportData(query);
    if (res.success && res.data) {
      this.setOrderReportData(res.data, res.meta);
    }
    return res;
  };

  public getOrderReportHeaderReq = async (
    query?: GetOrderReportHeaderQuery,
  ) => {
    const res = await this._store.OrdersApi.getOrderReportHeader(query);
    if (res.success && res.data) {
      this.setOrderReportHeader(res.data);
    }
    return res;
  };
}
