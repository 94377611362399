import { FetchService } from '@modules/Api';

export class PricesApi extends FetchService {
  public getPrices = (query?: GetPricesQuery) => {
    return this.request<'PRICE', PriceResponse[], GetPricesQuery>(
      '/price',
      'GET_PRICES',
      { query, permit: 'price' },
    );
  };

  public getPriceList = (query?: GetPriceListQuery) => {
    return this.request<'PRICE', PriceResponse[], GetPriceListQuery>(
      '/price',
      'GET_PRICE_LIST',
      { query, permit: 'price' },
    );
  };

  public getPrice = (id: number) => {
    return this.request<'PRICE', PriceResponse>('/price', 'GET_PRICE', {
      pathParam: id,
      permit: 'price',
    });
  };

  public getPriceServices = (priceId: number, query?: GetPriceServiceQuery) => {
    return this.request<'PRICE', PriceService[], GetPriceServiceQuery>(
      '/price',
      'GET_PRICE_SERVICES',
      {
        pathParam: `${priceId}/services`,
        query,
        // PERMIT ??
      },
    );
  };

  public createPrice = (params: CreatePrice) => {
    return this.request<'PRICE', PriceResponse, CreatePrice>(
      '/price',
      'CREATE_PRICE',
      { method: 'POST', params, permit: 'price' },
    );
  };

  public updatePrice = (id: number, params: UpdatePrice) => {
    return this.request<'PRICE', PriceResponse>('/price', 'UPDATE_PRICE', {
      method: 'PUT',
      pathParam: id,
      params,
      permit: 'price',
    });
  };

  public deletePrice = (id: number) => {
    return this.request<'PRICE'>('/price', 'DELETE_PRICE', {
      method: 'DELETE',
      pathParam: id,
      permit: 'price',
    });
  };

  public activatePrice = (id: number) => {
    return this.request<'PRICE', PriceResponse>('/price', 'ACTIVATE_PRICE', {
      method: 'PUT',
      pathParam: `${id}/activate`,
      permit: 'price',
    });
  };

  public deactivatePrice = (id: number) => {
    return this.request<'PRICE', PriceResponse>('/price', 'DEACTIVATE_PRICE', {
      method: 'PUT',
      pathParam: `${id}/deactivate`,
      permit: 'price',
    });
  };

  public setCostPrice = (id: number, params: SetCostPrice) => {
    return this.request<'PRICE', PriceService[], SetCostPrice>(
      '/price',
      'SET_COST_PRICE',
      {
        method: 'POST',
        pathParam: `${id}/set_cost`,
        params,
        permit: 'price',
      },
    );
  };

  public getPriceBlankFile = () => {
    return this.request<'PRICE', BlobResponse>(
      '/price/blank',
      'GET_PRICE_BLANK_FILE',
      {
        blob: true,
        permit: 'price',
      },
    );
  };

  public exportPrice = (priceId: number) => {
    return this.request<'PRICE', BlobResponse>('/price', 'EXPORT_PRICE', {
      pathParam: `${priceId}/export`,
      blob: true,
    });
  };

  public importPrice = (params: FormData) => {
    return this.request<'PRICE', PriceResponse, FormData>(
      '/price/import',
      'IMPORT_PRICE',
      {
        method: 'POST',
        params,
        permit: 'price',
      },
    );
  };

  public exportPrintVersion = (priceId: number) => {
    return this.request<'PRICE', BlobResponse>(
      '/price',
      'EXPORT_PRINT_VERSION',
      {
        pathParam: `${priceId}/export_print_version`,
        blob: true,
      },
    );
  };

  public importPriceById = (id: number, params: FormData) => {
    return this.request<'PRICE', PriceResponse, FormData>(
      `/price`,
      'IMPORT_PRICE_BY_ID',
      {
        method: 'POST',
        params,
        pathParam: `${id}/import`,
        permit: 'price',
      },
    );
  };

  public copyPriceCost = async (priceId: number, params: CopyCostPrice) => {
    return this.request<'PRICE', PriceService[], CopyCostPrice>(
      '/price',
      'COPY_COST_PRICE',
      {
        method: 'POST',
        pathParam: `${priceId}/copy_cost`,
        params,
        permit: 'price',
      },
    );
  };

  public removePriceServices = async (
    priceId: number,
    params: RemovePriceServices,
  ) => {
    return this.request<'PRICE', PriceService, RemovePriceServices>(
      '/price',
      'REMOVE_PRICE_SERVICE',
      {
        method: 'PUT',
        params,
        pathParam: `${priceId}/remove_costs`,
        // PERMIT ??
      },
    );
  };
}
