import React, { useMemo } from 'react';
import cm from 'classnames';
import { Icon } from '@components/UI/Icon';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useService } from '@stores/hooks';
import { useTranslation } from 'react-i18next';
import './NavBar.scss';

function NavBar() {
  const { config, location } = useService('RoutingService');
  const { userCanRoute } = useService('PermissionsService');
  const { t } = useTranslation('layout');

  const menu = useMemo(() => {
    return config.private.routes.filter((e) => e.sidebar);
  }, [config.private.routes]);

  const [topKey] = location.pathname.split('/').filter(Boolean);

  let current = menu.find((e) => e.path === '/');
  const [routes] = menu.filter((e) => e.path === `/${topKey}`);

  if (routes) {
    current = routes;
  }

  return (
    <nav className="nav-bar" data-testid="navbar">
      <ul className="menu">
        {menu.map((route) => {
          if (!userCanRoute(route)) return null;
          const isActive = route.path === current?.path;
          const icon =
            route.path === current?.path
              ? route.activeIcon
                ? route.activeIcon
                : route.icon
              : route.icon;

          return (
            <React.Fragment key={`${route.name}-${route.path}`}>
              <li
                className={cm({ active: isActive })}
                data-testid={`menu-${route.name}`}
              >
                <Link to={route.path}>
                  <Icon name={icon as IconType} />
                  <span className="name">{t(`menu.${route.name}`)}</span>
                </Link>
              </li>
              {route.divider && <li className="divider my-3" />}
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
}

export default observer(NavBar);
