import React from 'react';
import { Icon } from '@components/UI/Icon';
import { Text } from '@components/UI/Text';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';

function Timer() {
  const { state } = useService('DoctorWorkplace');
  const { secondsToHHMMSS } = useService('DateService');

  if (!state.activeEvent) return null;

  return (
    <div data-testid="active-visit-timer" className="flex items-center">
      <div className="mr-2 rounded-full bg-blue-300 p-1">
        <Icon name="AssignmentInd" size="mini" className=" text-blueGray-700" />
      </div>
      <div>
        <Text tag="span" className="block text-lg">
          {secondsToHHMMSS(state.activeEvent.timer.value)}
        </Text>
      </div>
    </div>
  );
}

export default observer(Timer);
