import React from 'react';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';
import { Text } from '@components/UI/Text';
import { Icon } from '@components/UI/Icon';

function ActiveAppeal(): React.JSX.Element | null {
  const { activeAppeal } = useService('AppealService');
  const { secondsToHHMMSS } = useService('DateService');
  const { goToRoute } = useService('RoutingService');

  if (!activeAppeal) return null;

  const Timer = observer(() => {
    return <>{secondsToHHMMSS(activeAppeal?.timer || 0)}</>;
  });

  function onSelect() {
    goToRoute('/call-center/incoming');
  }

  return (
    <div
      className="cursor-pointer flex items-center bg-blueGray-700 -10 p-2 rounded-10"
      onClick={onSelect}
    >
      <Icon
        name="Call"
        className="text-blueGray-700 bg-red-300 rounded-10 p-1 mr-1"
      />
      <Text className="mr-2" tag="h4" style={{ width: '95px' }}>
        <Timer />
      </Text>
      <Text tag="h4">{activeAppeal.patient_fullname}</Text>
    </div>
  );
}

export default observer(ActiveAppeal);
