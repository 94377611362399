import { FetchService } from '@modules/Api';

export class VisitApi extends FetchService {
  public getVisits = (query?: GetVisitsQuery) => {
    return this.request<'VISITS', Visit[], null, GetVisitsQuery>(
      '/visits',
      'GET_VISITS',
      { query, permit: 'visits' },
    );
  };

  public getVisit = (id: number) => {
    return this.request<'VISITS', Visit>('/visits', 'GET_VISIT', {
      pathParam: id,
      permit: 'visits',
    });
  };

  public updateVisit = (id: number, params: UpdateVisitReq) => {
    return this.request<'VISITS', Visit, UpdateVisitReq>(
      '/visits',
      'UPDATE_VISIT',
      {
        params,
        pathParam: id,
        permit: 'visits',
      },
    );
  };
}
