import React from 'react';
import * as classes from './Debug.module.scss';
import cn from 'classnames';

type Props = {
  data: any;
  className?: string;
};

export default function Debug({ data, className }: Props) {
  if (!data) return null;
  return (
    <pre className={cn(classes.debug, className)}>
      {JSON.stringify(data, null, 2)}
    </pre>
  );
}
