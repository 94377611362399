import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSecurity = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 .833 2.5 4.167v5c0 4.625 3.2 8.95 7.5 10 4.3-1.05 7.5-5.375 7.5-10v-5zm0 9.159h5.833c-.441 3.433-2.733 6.491-5.833 7.45V10H4.167V5.25L10 2.66z"
      />
    </svg>
  );
};
export default SvgSecurity;
