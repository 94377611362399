import React, { Suspense } from 'react';
import { Provider } from 'mobx-react';
import { Store } from '@stores';
import ToastifyContainer from '@lib/Toastify';
import { InitProvider } from '@modules/App';
import DrawerProvider from '@components/UI/Drawer';
import ModalProvider from '@components/UI/Modal';
import ServiceWorker from '@modules/Pwa';
import { Router } from '@router';
import '@assets/styles/index.scss';
import '@reactions';
import '@lib';

export default function App() {
  return (
    <Suspense fallback={''}>
      <Provider {...Store}>
        <ServiceWorker />
        <ToastifyContainer />
        <ModalProvider />
        <DrawerProvider />
        <InitProvider>
          <Router />
        </InitProvider>
      </Provider>
    </Suspense>
  );
}
