import { FetchService } from '@modules/Api';

export class OrdersApi extends FetchService {
  public getOrders = (query?: GetOrdersQuery) => {
    return this.request<'ORDERS', Order[], null, GetOrdersQuery>(
      '/orders',
      'GET_ORDERS',
      { query, permit: 'orders' },
    );
  };

  public getOrder = (id: number) => {
    return this.request<'ORDERS', OrderDetailed>('/orders', 'GET_ORDER', {
      pathParam: id,
      permit: 'orders',
    });
  };

  public downloadOrderReport = (query: GetOrdersReportsParams) => {
    return this.request<'ORDERS', BlobResponse>(
      '/orders/download_report',
      'DOWNLOAD_ORDER_REPORT',
      {
        method: 'GET',
        query: query,
        blob: true,
      },
    );
  };

  public downloadOrdersByPatientsReport = (
    query: DownloadOrdersByPatientsParams,
  ) => {
    return this.request<'ORDERS', BlobResponse>(
      '/orders/by_patients/download',
      'DOWNLOAD_ORDER_BY_PATIENTS_REPORT',
      {
        method: 'GET',
        query: query,
        blob: true,
      },
    );
  };

  public getOrderReportData = (query?: GetOrderReportDataQuery) => {
    return this.request<
      'ORDERS',
      OrderReportItem[],
      null,
      GetOrderReportDataQuery
    >('/orders/get_report/data', 'GET_ORDER_REPORT_DATA', { query });
  };

  public getOrderReportHeader = (query?: GetOrderReportHeaderQuery) => {
    return this.request<
      'ORDERS',
      OrderReportSummary,
      null,
      GetOrderReportHeaderQuery
    >('/orders/get_report/header', 'GET_ORDER_REPORT_HEADER', { query });
  };
}
