import { action, computed, makeObservable, observable } from 'mobx';

export class Role {
  public readonly id: number;
  public expander = 0; // table column helper
  @observable public name = '';
  @observable public uiModules: UiModule[] = [];
  @observable public permissions: Permission[] = [];
  @observable public loadings = { uiModules: false, permissions: false };

  constructor(role: RoleType) {
    makeObservable(this);
    this.id = role.id;
    this.name = role.name;
    this.expander = role.id;
  }

  @computed
  public get isLading(): boolean {
    return this.loadings.permissions || this.loadings.uiModules;
  }

  @action
  public setValue(key: keyof Role, value: unknown): void {
    if (key !== 'id' && key !== 'isLading') this[key] = value as never;
  }

  @action
  public setLoading(key: 'uiModules' | 'permissions', value: boolean): void {
    this.loadings[key] = value;
  }
}
