import { PatientsStore } from '@modules/Patients/patients.store';

/**
 * Patients Service
 */
export class PatientsService extends PatientsStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPatientsReq = async (query?: GetPatientsQuery) => {
    const res = await this._store.PatientsApi.getPatients(query);
    if (res.success && res.data) {
      this.setPatients(res.data, res.meta);
    }
    return res;
  };

  public getPatientReq = async (id: number) => {
    const res = await this._store.PatientsApi.getPatient(id);
    if (res.success && res.data) {
      this.setPatient(res.data);
    }
    return res;
  };

  public getPatientContactsReq = async (id: number) => {
    const res = await this._store.PatientsApi.getPatientContacts(id);
    if (res.success && res.data) {
      this.setPatientContacts(res.data);
    }
    return res;
  };

  public getPatientMedDataReq = async (id: number) => {
    const res = await this._store.PatientsApi.getPatientMedData(id);
    if (res.success && res.data) {
      this.setPatientMedData(res.data);
    }
    return res;
  };

  public createPatientReq = async (params: CreatePatient) => {
    const res = await this._store.PatientsApi.createPatient(params);
    if (res.success && res.data) {
      this.setNewPatient(res.data);
    }
    return res;
  };

  public updatePatientReq = async (id: number, params: UpdatePatient) => {
    const res = await this._store.PatientsApi.updatePatient(id, params);
    if (res.success && res.data) {
      this.updatePatient(res.data);
    }
    return res;
  };

  public deletePatientReq = async (id: number) => {
    const res = await this._store.PatientsApi.deletePatient(id);
    if (res.success) {
      this.deletePatient(id);
    }
    return res;
  };

  public updatePatientContactsReq = async (
    id: number,
    params: UpdatePatientContacts,
  ) => {
    const res = await this._store.PatientsApi.updatePatientContacts(id, params);
    if (res.success && res.data) {
      this.updatePatientContacts(res.data);
    }
    return res;
  };

  public updatePatientMedDataReq = async (
    id: number,
    params: UpdatePatientMedData,
  ) => {
    const res = await this._store.PatientsApi.updatePatientMedData(id, params);
    if (res.success && res.data) {
      this.updatePatientMedData(res.data);
    }
    return res;
  };

  public importPatientsReq = async (params: FormData) => {
    const res = await this._store.PatientsApi.importPatients(params);
    if (res.success && res.data) {
      this.setImportPatients(res.data);
    }
    return res;
  };

  public saveImportPatientsReq = async (params: SaveImportPatients) => {
    const res = await this._store.PatientsApi.saveImportPatients(params);
    if (res.success && res.data) {
      console.log(res.data);
    }
    return res;
  };

  public validateImportPatients = () => {
    return this.importPatients.every(
      (e) =>
        e.last_name && e.first_name && e.patronymic && e.date_of_birth && e.sex,
    );
  };

  public updatePatientCreditTrustReq = async (
    id: number,
    params: UpdateCreditTrust,
  ) => {
    const res = await this._store.PatientsApi.updatePatientCreditTrust(
      id,
      params,
    );
    if (res.success && res.data) {
      this.updatePatient(res.data);
    }
    return res;
  };
}
