import ReconnectingWebSocket from 'reconnecting-websocket';
import { makeObservable, observable } from 'mobx';
import uniqueId from 'lodash/uniqueId';

export class WsConnection {
  @observable public isConnected: boolean = false;
  #connection: ReconnectingWebSocket | null = null;
  #store: Store;

  constructor(store: Store) {
    this.#store = store;
    makeObservable(this);
  }

  public connect = (): Promise<WsOrdersResponse> => {
    if (this.#connection) return Promise.resolve({} as WsOrdersResponse);

    return new Promise((resolve, reject) => {
      this.#loading(true);
      const connection = new ReconnectingWebSocket(
        this.#store.EnvService.env.ws.core,
        [],
        { connectionTimeout: 1000 },
      );

      const connect = (event: MessageEvent) => {
        try {
          const data = JSON.parse(event.data);
          resolve(data);
        } catch (err) {
          reject(err);
        }

        connection.removeEventListener('message', connect);
        this.#loading(false);
        this.#connection = connection;
      };

      connection.addEventListener('open', this.#open);
      connection.addEventListener('close', this.#close);
      connection.addEventListener('message', this.#message);
      connection.addEventListener('message', connect);
    });
  };

  public disconnect = (): void => {
    if (!this.isConnected) return;
    if (!this.#connection) return;

    this.#connection.close();
  };

  #open = () => {
    const { send } = this.#store.LoggerService;

    this.isConnected = true;
    send.wsCrumb('WS Orders: CONNECTION OPEN', {
      url: this.#store.EnvService.env.ws.core,
    });
  };

  #close = () => {
    const { send } = this.#store.LoggerService;

    this.isConnected = false;
    this.#connection = null;
    send.wsCrumb('WS Orders: CONNECTION CLOSE', {
      url: this.#store.EnvService.env.ws.core,
    });
  };

  #message = (event: MessageEvent) => {
    const { send } = this.#store.LoggerService;
    const { wsException } = this.#store.ErrorService;

    try {
      const message = JSON.parse(event.data) as WsResponse<never>;
      send.wsCrumb(`WS: RESPONSE [GET_CASH_ORDERS]`, {
        method: message.method,
        result: JSON.stringify(message.result),
      });
    } catch (err) {
      console.warn('ERROR_JSON_PARSE', err);
      wsException('UNKNOWN_KEY', ['ERROR_JSON_PARSE'], err);
    }
  };

  #send = () => {
    const { send } = this.#store.LoggerService;
    const { wsError } = this.#store.ErrorService;

    if (!this.#connection) {
      const message = 'WS_CONNECTION_IS_NOT_READY';
      wsError('GET_CASH_ORDERS', [{ message }]);
      return;
    }

    send.wsCrumb('WS: SEND', { key: 'GET_CASH_ORDERS' });
  };

  #loading = (value: boolean) => {
    const { setLoading } = this.#store.LoadingService;
    setLoading('GET_CASH_ORDERS', value);
  };

  public send<T>(data: WsRequest<T>): Promise<{ all_orders_sorted: Order[] }> {
    this.#send();
    return new Promise((resolve, reject) => {
      if (!this.#connection) {
        reject(new Error('WS Orders connection is not ready'));
        return;
      }

      this.#loading(true);
      const request = JSON.stringify({
        id: +uniqueId(),
        ...data,
      });

      const message = (event: MessageEvent) => {
        try {
          const data = JSON.parse(event.data) as { all_orders_sorted: Order[] };
          resolve(data);
        } catch (err) {
          reject(err);
        }

        this.#connection?.removeEventListener('message', message);
        this.#loading(false);
      };

      this.#connection.addEventListener('message', message);
      this.#connection.send(request);
    });
  }

  public subscribe = (
    cb: (error: Error | null, data: { all_orders_sorted: Order[] }) => void,
  ) => {
    const listener = (message: MessageEvent) => {
      try {
        const data = JSON.parse(message.data);
        cb(null, data);
      } catch (err) {
        cb(err as Error, { all_orders_sorted: [] });
      }
    };

    this.#connection?.addEventListener('message', listener);
    return () => {
      this.#connection?.removeEventListener('message', listener);
    };
  };
}
