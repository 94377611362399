import { CabinetsStore } from '@modules/Cabinets/cabinets.store';

export class CabinetsService extends CabinetsStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getCabinetsReq = async (query?: GetCabinetsQuery) => {
    const res = await this._store.CabinetsApi.getCabinets(query);
    if (res.success && res.data) {
      this.setCabinets(res.data, res.meta);
    }
    return res;
  };

  public getCabinetReq = async (id: number) => {
    const res = await this._store.CabinetsApi.getCabinet(id);
    if (res.success && res.data) {
      this.setCabinet(res.data);
    }
    return res;
  };

  public getCabinetServicesReq = async (
    id: number,
    query?: GetCabinetServicesQuery,
  ) => {
    const res = await this._store.CabinetsApi.getCabinetServices(id, query);
    if (res.success && res.data) {
      this.setCabinetServices(res.data, res.meta);
    }
    return res;
  };

  public createCabinetReq = async (params: CreateCabinetReq) => {
    const res = await this._store.CabinetsApi.createCabinet(params);
    if (res.success && res.data) {
      this.addCabinet(res.data);
    }
    return res;
  };

  public updateCabinetRequest = async (
    id: number,
    params: UpdateCabinetReq,
  ) => {
    const response = await this._store.CabinetsApi.updateCabinet(id, params);
    if (response.success && response.data) {
      this.updateCabinet(response.data);
    }
    return response;
  };

  public activateCabinetRequest = async (id: number) => {
    const response = await this._store.CabinetsApi.activateCabinet(id);
    if (response.success && response.data) {
      this.updateCabinet({ id, active: true });
    }
    return response;
  };

  public deactivateCabinetRequest = async (id: number) => {
    const response = await this._store.CabinetsApi.deactivateCabinet(id);
    if (response.success) {
      this.updateCabinet({ id, active: false });
    }
    return response;
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET TYPE
  |----------------------------------------------------------------------------
  */
  public getCabinetsTypeRequest = async (query?: GetCabinetsTypeQuery) => {
    const response = await this._store.CabinetsApi.getCabinetsType(query);
    if (response.success && response.data) {
      this.setCabinetsType(response.data, response.meta);
    }
    return response;
  };

  public getCabinetTypeRequest = async (id: number) => {
    const response = await this._store.CabinetsApi.getCabinetType(id);
    if (response.success && response.data) {
      this.setCabinetType(response.data);
    }
    return response;
  };

  public createCabinetTypeRequest = async (params: CreateCabinetTypeReq) => {
    const response = await this._store.CabinetsApi.createCabinetType(params);
    if (response.success && response.data) {
      this.addCabinetType(response.data);
    }
    return response;
  };

  public updateCabinetTypeRequest = async (
    id: number,
    params: UpdateCabinetTypeReq,
  ) => {
    const response = await this._store.CabinetsApi.updateCabinetType(
      id,
      params,
    );
    if (response.success && response.data) {
      this.updateCabinetType(response.data);
    }
    return response;
  };

  public deleteCabinetTypeRequest = async (id: number) => {
    const response = await this._store.CabinetsApi.deleteCabinetType(id);
    if (response.success) {
      this.deleteCabinetType(id);
    }
    return response;
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET SERVICE
  |----------------------------------------------------------------------------
  */
  public removeCabinetServiceReq = async (params: RemoveServiceCabinetReq) => {
    const response = await this._store.ServicesApi.removeServiceCabinet(params);
    if (response.success) {
      this.deleteCabinetService(params.service_id);
    }
    return response;
  };

  public addCabinetServiceReq = async (params: AddServiceCabinetReq) => {
    const response = await this._store.ServicesApi.addServiceCabinet(params);
    if (response.success && response.data) {
      this.addServiceCabinet(response.data.service);
    }
    return response;
  };
}
