import { FetchService } from '@modules/Api';

export class RemedEpisodeApi extends FetchService {
  public getRemedEpisodes = (query?: GetRemedEpisodes) => {
    return this.request<'REMED_EPISODE', RemedEpisodes, GetRemedEpisodes>(
      '/remed_episode',
      'GET_REMED_EPISODES',
      { query },
    );
  };

  public getBindingRemedEpisode = (params: GetBindingRemedEpisode) => {
    return this.request<
      'REMED_EPISODE',
      BindingRemedEpisode,
      GetBindingRemedEpisode
    >('/remed_episode', 'GET_BINDING_REMED_EPISODE', {
      method: 'POST',
      params,
    });
  };

  public deleteBindingRemedEpisode = (episodeId: number) => {
    return this.request<'REMED_EPISODE'>(
      '/remed_episode',
      'DELETE_BINDING_REMED_EPISODE',
      {
        method: 'DELETE',
        pathParam: episodeId,
      },
    );
  };

  public getRemedEpisodeConsultations = (episodeId: number) => {
    return this.request<'REMED_EPISODE', RemedEpisodeConsultation[]>(
      '/remed_episode',
      'GET_REMED_EPISODE_CONSULTATIONS',
      {
        pathParam: `${episodeId}/consultations`,
      },
    );
  };

  public getRemedEpisodeConsultationDetail = ({
    episodeId,
    consultationId,
    ...query
  }: GetRemedEpisodeConsultationDetail) => {
    return this.request<'REMED_EPISODE', RemedEpisodeConsultationDetail>(
      '/remed_episode',
      'GET_REMED_EPISODE_CONSULTATIONS_DETAILS',
      {
        pathParam: `${episodeId}/consultations/${consultationId}`,
        query,
      },
    );
  };
}
