import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDialogSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      fill="none"
      viewBox="0 0 21 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#334155"
          d="M18.3 5h-1.667v7.5H5.8v1.667c0 .458.375.833.833.833H15.8l3.333 3.333v-12.5A.836.836 0 0 0 18.3 5m-3.333 5V2.5a.836.836 0 0 0-.834-.833H3.3a.836.836 0 0 0-.833.833v11.667L5.8 10.833h8.333a.836.836 0 0 0 .834-.833"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.8 0h20v20H.8z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDialogSecondary;
