import { action, makeObservable, observable } from 'mobx';

export default class DrawerStore {
  @observable public isOpen = false;
  @observable public disableClose = false;
  @observable public drawerProps: DrawerProps = {} as DrawerProps;
  @observable public error: DrawerErrorType | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setOpen(value: boolean): void {
    this.isOpen = value;
  }

  @action
  public setDisableClose = (value: boolean): void => {
    this.disableClose = value;
  };

  @action
  public setDrawerProps(props: DrawerProps): void {
    this.drawerProps = props;
  }

  @action
  public showError = (message: string, errors?: ServerError[]) => {
    const compose = (error: ServerError) => `${error.title}: ${error.detail}`;
    const details = errors?.map(compose);
    this.error = { message, detail: details?.join(';') };
  };

  @action
  public clearError = () => {
    this.error = null;
  };
}
