import * as React from 'react';
import type { SVGProps } from 'react';
const SvgColors = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      role="img"
      {...props}
    >
      <title>{'illustration/colors'}</title>
      <g fill="none" fillRule="evenodd">
        <circle
          cx={23.763}
          cy={16.192}
          r={13.271}
          fill="#FC521F"
          opacity={0.6}
        />
        <circle
          cx={15.468}
          cy={32.308}
          r={13.271}
          fill="#66BF3C"
          opacity={0.6}
        />
        <path
          fill="#FF5F95"
          d="M15.468 19.036c6.075 0 11.197 4.082 12.772 9.653a13.2 13.2 0 0 1-4.477.775c-6.075 0-11.197-4.082-12.772-9.653a13.2 13.2 0 0 1 4.477-.775"
        />
        <circle
          cx={32.532}
          cy={32.308}
          r={13.271}
          fill="#1EA7FD"
          opacity={0.6}
        />
        <path
          fill="#87E6E5"
          d="M24 22.142a13.24 13.24 0 0 1 4.74 10.166c0 4.08-1.842 7.731-4.74 10.166a13.24 13.24 0 0 1-4.74-10.166c0-4.026 1.793-7.634 4.624-10.068Z"
        />
        <path
          fill="#FBD178"
          d="M32.532 19.036c1.41 0 2.768.22 4.043.627-1.526 5.647-6.684 9.8-12.812 9.8-1.41 0-2.77-.22-4.044-.627 1.526-5.646 6.684-9.8 12.813-9.8"
        />
        <path
          fill="#FFF"
          d="m24 22.142.012.01q.245.205.477.421l-.1-.091q.117.105.23.214l-.13-.123q.12.112.237.225l-.106-.102q.106.102.21.205l-.104-.103q.129.127.255.256l-.15-.153.227.232q.384.402.735.835l-.07-.085q.114.137.223.278l-.153-.193q.105.13.205.26l-.052-.067.182.239-.13-.171q.102.133.2.268l-.07-.097q.116.156.227.315l-.156-.218q.104.142.203.286l-.047-.068q.087.124.172.252l-.125-.184q.098.144.194.29.21.323.403.659l-.058-.1q.084.144.165.292L27 25.732q.083.144.162.292.163.303.31.615a13 13 0 0 1 .396.926l-.063-.161q.053.13.102.264l-.039-.103q.06.154.115.31l-.076-.207q.06.162.117.326l-.041-.118.095.279-.054-.16q.058.168.112.34l-.058-.18q.051.155.099.313l.065.221-.037.014.036-.014q-.433.155-.88.281h.001q-.437.123-.886.216l.064-.013q-.223.047-.448.087l.384-.074q-.207.045-.417.08l.033-.006a13 13 0 0 1-.465.074l.432-.068q-.234.041-.47.073l.038-.005q-.2.028-.4.05l.361-.045q-.231.032-.466.056l.105-.01a13 13 0 0 1-.509.045l.404-.035q-.232.024-.467.04l.063-.005q-.233.017-.47.025l.407-.02q-.216.013-.434.021h.027q-.24.008-.484.008l-.45-.008a13 13 0 0 1-.97-.067l.046.004a13 13 0 0 1-.424-.05l.379.046a13 13 0 0 1-.455-.057l.076.011a13 13 0 0 1-.485-.075l.409.064a13 13 0 0 1-.458-.073l.05.01q-.195-.035-.388-.074l.338.064a13 13 0 0 1-.458-.089l.12.025a13 13 0 0 1-.496-.111l.376.086a13 13 0 0 1-.44-.102l.064.016q-.206-.051-.41-.108l.346.092q-.21-.052-.416-.111l-.398-.12.07-.248q.042-.147.088-.292l-.029.093q.06-.199.129-.395l-.1.302q.052-.165.108-.326v-.001q.195-.564.44-1.105l-.08.179a13 13 0 0 1 .138-.31l-.059.13.113-.244-.054.115q.077-.166.158-.328l-.104.213q.075-.157.153-.312l-.05.1q.062-.124.126-.246a13 13 0 0 1 .88-1.448l-.117.17q.088-.13.18-.259l-.063.09q.08-.116.163-.23l-.1.14q.11-.156.226-.308l-.126.169q.095-.13.193-.257.475-.618 1.017-1.176l-.19.2q.112-.12.225-.235l-.035.035.196-.197-.161.162q.115-.118.235-.234l-.074.072q.1-.1.204-.197l-.13.125q.144-.14.294-.277l-.164.152q.108-.102.22-.202l-.056.05q.133-.122.27-.24l.117-.1z"
        />
      </g>
    </svg>
  );
};
export default SvgColors;
