import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMeetingRoom = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.333 4v10H2v-1.333h1.333V2h6v.667h3.334v10H14V14h-2.667V4zM6.667 7.333v1.334H8V7.333z"
      />
    </svg>
  );
};
export default SvgMeetingRoom;
