import React from 'react';
import { Tooltip } from '@components/UI/Tooltip';
import { Icon } from '@components/UI/Icon';
import { useTranslation } from 'react-i18next';
import { useService } from '@stores/hooks';

export default function LogoutBtn() {
  const { t } = useTranslation('layout');
  const { logoutReq } = useService('AuthService');
  const { modal } = useService('ModalService');

  function onLogout() {
    modal.confirm({
      title: t('topbar_right.notifications.log_out_from_account'),
      message: t('topbar_right.notifications.shut_down_and_log_out'),
      options: {
        confirmButton: {
          children: t('topbar_right.notifications.log_out'),
          type: 'warning',
        },
        cancelButton: {
          children: t('topbar_right.notifications.cancel'),
          type: 'info',
        },
      },
      callbacks: {
        onConfirm: async () => {
          modal.config.setLoading(true);
          await logoutReq();
          modal.config.setLoading(false);
          modal.actions.close();
        },
        onCancel: modal.actions.close,
      },
    });
  }

  return (
    <div data-testid="logoutBtn" onClick={onLogout} className="logout">
      <Tooltip content={t('topbar_right.log_out')} position="left">
        <Icon name="ExitToApp" className="text-coolGray-300" />
      </Tooltip>
    </div>
  );
}
