import { action, makeObservable, observable } from 'mobx';

export class CompTemplateStore {
  @observable public compCurrentDocTemplate: CompDocTemplate | null = null;
  @observable public compDocTemplate: CompDocTemplate | null = null;
  @observable public compDocTemplates: CompDocTemplate[] = [];
  @observable public compDocTemplatesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setCompDocTemplates = (
    documents: CompDocTemplate[],
    meta: ResponseMeta,
  ) => {
    this.compDocTemplates = documents;
    this.compDocTemplatesMeta = meta;
  };

  @action
  public setCompDocTemplate = (
    template: CompDocTemplate | null,
    isNew = false,
  ) => {
    if (!isNew) {
      this.compDocTemplate = template;
    }
    if (isNew && template) {
      this.compDocTemplates = [template, ...this.compDocTemplates];
    }
  };

  @action
  public setCurrentDocTemplate = (template: CompDocTemplate) => {
    this.compCurrentDocTemplate = template;
  };
}
