import React from 'react';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import { history } from '@modules/Routing';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

type RoutesProps = {
  paths: RoutePath | RoutePath[];
  rootPath: RoutePath;
  component: any;
};

type AppRouterProps = {
  initRoutes: RoutesProps;
  needChangePass: RoutesProps;
  publicRoutes: RoutesProps;
  privateRoutes: RoutesProps;
  staticRoutes?: RoutesProps;
};

export const AppRouter: React.FC<AppRouterProps> = observer(
  ({
    initRoutes,
    needChangePass,
    privateRoutes,
    publicRoutes,
    staticRoutes,
  }) => {
    const { sysInitialized } = useService('AppService');
    const { isAuth, isFirstEntry } = useService('AuthService');

    const { paths, component, rootPath } = !sysInitialized
      ? initRoutes
      : isFirstEntry
        ? needChangePass
        : isAuth
          ? privateRoutes
          : publicRoutes;

    return (
      <Router history={history}>
        <Switch>
          {staticRoutes && (
            <Route
              exact
              path={staticRoutes.paths}
              component={staticRoutes.component}
            />
          )}
          <Route exact path={paths} render={component} />
          <Redirect to={rootPath} />
        </Switch>
      </Router>
    );
  },
);
