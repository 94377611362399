import { action, makeObservable, observable } from 'mobx';

export class Service {
  public readonly id: number;
  public expander = 0; // table column helper
  @observable public code = '';
  @observable public title = '';
  @observable public short_title = '';
  @observable public type: ServiceType = 'clinic_service';
  @observable public service_category: ServiceCategory | null = null;
  @observable public counterparty: Counterparty | null = null;
  @observable public status = false;
  @observable public delete_marked = false;
  @observable public duration_independent = 0;
  @observable public duration_combined = 0;
  @observable public description = '';
  @observable public short_description = '';
  @observable public specialization: Specialization | null = null;
  @observable public prices: ServicePrice[] = [];
  @observable public basePrice: ServicePrice | undefined = undefined;
  @observable public price = 0;
  @observable public basePriceCost = 0;
  @observable public has_dependency = false;
  @observable public participation_commissions_enabled = false;

  constructor(service: ServiceResponse) {
    makeObservable(this);
    this.id = service.id;
    this.code = service.code;
    this.title = service.title;
    this.short_title = service.short_title;
    this.type = service.type;
    this.service_category = service.service_category;
    this.status = service.status;
    this.delete_marked = service.delete_marked;
    this.basePriceCost = service.base_price_cost;
    this.duration_independent = service.duration_independent;
    this.duration_combined = service.duration_combined;
    this.description = service.description;
    this.short_description = service.short_description;
    this.specialization = service.specialization;
    this.prices = service.prices;
    this.has_dependency = service.has_dependency;
    this.participation_commissions_enabled =
      service.participation_commissions_enabled;
    this.counterparty = service.counterparty ?? null;
    this._serPrice(service);
  }

  @action
  public setValue(key: keyof Service, value: unknown): void {
    if (key !== 'id') this[key] = value as never;
  }

  private _serPrice(service: ServiceResponse) {
    if (!service.prices) return;
    this.basePrice = service.prices.find((e) => e.base);
    if (!this.basePrice) return;
    this.price = this.basePrice.cost;
  }
}
