import { isEqual, isNull, isString } from 'lodash';

/**
 * Sleep
 * @param ms time
 */
export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const onlyUnique = (
  value: string,
  index: number,
  self: any[],
): boolean => {
  return self.indexOf(value as never) === index;
};

export const nameFirstLetters = (name: string): string => {
  if (!name) return '';
  let initials = 'RM';
  const [_name, _lastName] = name.split(' ').map((e) => e.toUpperCase());

  if (_name && _lastName) {
    initials = `${_name[0]}${_lastName[0]}`;
  }

  return initials;
};

export function getObjectDiff(obj1: any, obj2: any) {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));
}

export function getMaxOfArray(numArray: []) {
  return Math.max.apply(null, numArray);
}

export function filterObjValue<T>(obj: T) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.entries(obj)
    .filter(([_, value]) => {
      if (isString(value) && !value.length) return false;
      if (isNull(value)) return false;

      return true;
    })
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {} as T,
    );
}

export function downloadFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();
}
