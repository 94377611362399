import React, { memo, useEffect, useState } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import { Loader } from '@components/UI/Loader';
import './ContentLoader.scss';

type Props = {
  delay?: number;
  className?: string;
};

function ContentLoader({ delay = 150, className }: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  if (!show) return null;

  return (
    <div className={className}>
      <div className="content-loader">
        <TopBarProgress />
        <Loader />
      </div>
    </div>
  );
}

export default memo(ContentLoader);
