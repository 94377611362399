import React from 'react';
import { toast } from 'react-toastify';
import { AddSuccess } from '@modules/Notify/components/AddSuccess';

const components = {
  AddSuccess,
};

const component = (name: keyof typeof components, props: NotifyParams) => {
  return React.createElement(components[name], props);
};

export const registration = {
  addSuccess(params: NotifyParams) {
    return toast(component('AddSuccess', params), {
      className: 'rm-notify-add-success',
      autoClose: 4000,
    });
  },
};
