export class SchedulerListener {
  constructor(protected _store: Store) {}

  public subscribe = () => {
    const { on } = this._store.WsService;
    on('schedule_interval.get_available', this.#getExecutors);
    on('schedule_interval.unsubscribe', this.unsubscribe);
  };

  public unsubscribe = () => {
    const { off } = this._store.WsService;
    const { scheduler } = this._store.CalendarService;

    off('schedule_interval.get_available', this.#getExecutors);
    off('schedule_interval.unsubscribe', this.unsubscribe);

    scheduler.store.clear();
  };

  #getExecutors = (data: WsResponse<AvailableExecutor[]>) => {
    const { store } = this._store.CalendarService.scheduler;

    if (data.result.success) {
      store.setExecutors(data.result.data);
    }
  };
}
