import { LStorage } from '@modules/Storage';
import { action, observable } from 'mobx';

export class UIModuleMapper {
  @observable public title: string;
  @observable public name: string;
  @observable public isSelected: boolean;
  @observable public children: UIModuleMapper[];
  @observable public parents: UIModuleMapper[];
  @observable public allChildren: UIModuleMapper[];

  constructor(
    { title, name, children = [] }: InitUIModule,
    parents: UIModuleMapper[] = [],
  ) {
    this.name = name;
    this.title = title;
    this.parents = parents;
    this.isSelected =
      LStorage.getItem<string[]>('UI_MODULES', true)?.includes(name) || false;

    this.children = children.map(
      (c) => new UIModuleMapper(c, [...parents, this]),
    );

    this.allChildren = this.children.reduce(
      (a, c) => {
        c.children.forEach((e) => a.push(e));
        return a;
      },
      [...this.children],
    );
  }

  @action
  public check = () => {
    this.isSelected = true;

    if (this.parents) {
      this.parents.forEach((p) => p.check());
    }
  };

  @action
  public uncheck = () => {
    this.isSelected = false;

    if (this.children) {
      this.children.forEach((child) => child.uncheck());
    }
  };

  @action
  public toggle = () => {
    if (this.isSelected) {
      this.uncheck();
      return;
    }

    this.check();
  };

  @action
  public base = () => {
    if (!this.isSelected) {
      return null;
    }

    const model: InitUIModule = {
      name: this.name,
      title: this.title,
    };

    if (this.children.length) {
      const children = this.children
        .map((child) => child.base())
        .filter((child) => !!child) as InitUIModule[];

      if (children.length) {
        model.children = children;
      }
    }

    return model;
  };
}
