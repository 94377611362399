import { action, makeObservable, observable } from 'mobx';

export class OrderStore {
  @observable public order: OrderDetailed | null = null;
  @observable public orders: Order[] = [];
  @observable public ordersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public orderReport: OrderReportItem[] = [];
  @observable public orderReportSummary: OrderReportSummary = {
    distinct_daily_patients_count: 0,
    total_card: 0,
    total_cash: 0,
    total_lack: 0,
    total_paid: 0,
    total_amount: 0,
    total_balance: 0,
    total_refunds: 0,
    total_discount: 0,
    total_cashless: 0,
    total_insurance: 0,
    total_other_payer: 0,
    total_included_pref_category_discount: 0,
    total_amount_to_pay: 0,
    total_included_custom_discount: 0,
  };

  @observable public orderReportMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setOrders = (orders: Order[], meta: ResponseMeta) => {
    this.orders = orders;
    this.ordersMeta = meta;
  };

  @action
  public setOrder = (order: OrderDetailed | null, id?: number) => {
    this.order = order;

    if (this.orderReport.length && order) {
      const _order = this.orderReport.find((e) => e.order_id === id);
      if (_order) {
        Object.assign(_order, { services: order.services });
      }
    }
  };

  @action
  public setOrderReportData = (data: OrderReportItem[], meta: ResponseMeta) => {
    this.orderReport = data;
    this.orderReportMeta = meta;
  };

  @action
  public setOrderReportHeader = (data: OrderReportSummary) => {
    this.orderReportSummary = data;
  };
}
