import React, { useCallback, useEffect, useId, useState } from 'react';
import { Icon } from '@components/UI/Icon';
import { Text } from '@components/UI/Text';
import './HistoryAppointment.scss';
import OverlayPanel, {
  OverlayOptions,
} from '@components/UI/OverlayPanel/OverlayPanel';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';
import { sleep } from '@utils/helpers';
import NoResults from '@components/UI/NoResults';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/UI/Tooltip';
import { Loader } from '@components/UI/Loader';

function SelectedPatient({ close }: OverlayOptions) {
  const { t } = useTranslation('layout');
  const { goToRoute } = useService('RoutingService');
  const { patientFullName } = useService('StringService');
  const { calendarStore } = useService('CalendarService');

  const goToPatient = useCallback(
    (id: number) => {
      goToRoute('/patients/table/:id', { id });
    },
    [goToRoute],
  );

  if (!calendarStore.patient) return null;

  return (
    <div className="current-user" data-testid="record-history-patient">
      <div className="label flex items-center mb-2">
        <div className="logo">
          <Icon name={'AccountBox'} className="accountBox" />
        </div>
        <Text tag="h4">{t('topbar_right.selected_patient')}</Text>
      </div>

      <div className="patient">
        <Text
          onClick={() => {
            if (calendarStore.patient) goToPatient(calendarStore.patient.id);
            close();
          }}
          className="cursor-pointer"
          tag="h4"
        >
          {patientFullName(calendarStore.patient)}
        </Text>
        <Icon
          className="cursor-pointer icon-close"
          onClick={async () => {
            close();
            await sleep(300);
            calendarStore.setPatient(null);
          }}
          name="Close"
        />
      </div>
    </div>
  );
}

function Appointments({
  close,
  appointments,
}: OverlayOptions & { appointments: AppointmentType[] }) {
  const { t } = useTranslation('layout');

  const { goToRoute } = useService('RoutingService');
  const { formatDate } = useService('DateService');
  const { loadings } = useService('LoadingService');
  const { patientFullName } = useService('StringService');

  const goToPatient = useCallback(
    (id: number) => {
      goToRoute('/patients/table/:id', { id });
    },
    [goToRoute],
  );

  const render = () => {
    if (loadings.GET_APPOINTMENTS) {
      return (
        <div className="h-[200px]">
          <Loader className="bg-white rounded-2 z-1" />
        </div>
      );
    }

    if (!appointments.length) {
      return (
        <div className="pb-7 px-4 pt-4">
          <hr className="text-coolGray-300" />
          <NoResults
            title={t('topbar_right.no_results_title')}
            description={t('topbar_right.no_results_description')}
          />
        </div>
      );
    }

    return appointments.map((appointment) => (
      <div
        data-testid="record-history-appointment"
        onClick={() => {
          if (appointment.patient) goToPatient(appointment.patient.id);
          close();
        }}
        key={appointment.id}
        className="item"
      >
        <hr className="text-coolGray-300" />
        <div className="py-4">
          <Text tag="h5" className="text-black mb-1">
            {patientFullName(appointment.patient)}
          </Text>
          <Text className="text-coolGray-500">
            {formatDate(appointment.created, 'DD_MM_YYYY')} /{' '}
            {formatDate(appointment.created, 'HH_mm')}
          </Text>
        </div>
        <Icon name="ChevronRight" className="icon" />
      </div>
    ));
  };

  return (
    <div className="px-4 pt-4">
      <Text tag="h5" className="text-coolGray-500 mb-4">
        {t('topbar_right.reception_history')}
      </Text>

      <div className="relative">{render()}</div>
    </div>
  );
}

const Panel = (props: OverlayOptions) => {
  const generatedId = useId();

  const [appointments, setAppointments] = useState<AppointmentType[]>([]);
  const { on, off } = useService('WsService');

  const {
    appointments: { getAppointments },
  } = useService('WsApi');

  const { profile } = useService('ProfileService');
  const { createDate } = useService('DateService');
  const { isConnected } = useService('WsService');

  function getAppointmentsReq() {
    if (!isConnected) return;
    if (!profile) return;

    getAppointments(
      {
        start_time: createDate(new Date(), {
          hour: 0,
          minute: 0,
          second: 0,
        }).unix(),
        end_time: createDate().add(1, 'month').unix(),
        statuses: ['planned'],
        creator_ids: [profile.id],
        page_number: 1,
        page_size: 5,
        order_by_created: true,
      },
      { id: generatedId },
    );
  }

  const setAppointmentsRes = (data: WsResponse<AppointmentType[]>) => {
    if (
      data.result.success &&
      data.storage &&
      data.storage.id &&
      data.storage.id === generatedId
    ) {
      setAppointments(data.result.data);
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      on('appointments.get_list', setAppointmentsRes);
      getAppointmentsReq();
    }

    return () => {
      off('appointments.get_list', setAppointmentsRes);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div data-testid="record-history-panel">
      <SelectedPatient {...props} />
      <Appointments {...props} appointments={appointments} />
    </div>
  );
};

const Trigger = ({ toggle }: OverlayOptions) => {
  const { t } = useTranslation('layout');

  return (
    <div onClick={toggle} className="history-icon p-2 rounded-1">
      <Tooltip content={t('topbar_right.record_history')} position="left">
        <Icon name="History" color="#fff" data-testid="record-history" />
      </Tooltip>
    </div>
  );
};

function HistoryAppointment() {
  return (
    <>
      <div className="HistoryOrders">
        <OverlayPanel
          position="center"
          trigger={(options) => <Trigger {...options} />}
          panel={(options) => <Panel {...options} />}
        />
      </div>
      <div className="devider" />
    </>
  );
}

export default observer(HistoryAppointment);
