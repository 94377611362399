import { DocChapterService } from '@modules/Documents/DocChapter';
import { DocElementService } from '@modules/Documents/DocElement';
import { DocTemplateService } from '@modules/Documents/DocTemplate';
import { DocumentService } from '@modules/Documents/Document/Document.service';
import { CompTemplateService } from '@modules/Documents/CompTemplate';
import { UserDocTemplatesService } from '@modules/Documents/UserDocTemplates';

export class DocumentsService {
  public readonly chapter: DocChapterService;
  public readonly element: DocElementService;
  public readonly template: DocTemplateService;
  public readonly compTmpl: CompTemplateService;
  public readonly document: DocumentService;
  public readonly userDocTemplate: UserDocTemplatesService;

  constructor(store: Store) {
    this.chapter = new DocChapterService(store);
    this.element = new DocElementService(store);
    this.template = new DocTemplateService(store);
    this.compTmpl = new CompTemplateService(store);
    this.document = new DocumentService(store);
    this.userDocTemplate = new UserDocTemplatesService(store);
  }
}
