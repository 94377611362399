import { FetchService } from '@modules/Api';

export class CabinetsApi extends FetchService {
  public getCabinets = (query?: GetCabinetsQuery) => {
    return this.request<'CABINET', CabinetResponse[], GetCabinetsQuery>(
      '/cabinet',
      'GET_CABINETS',
      {
        query,
        permit: 'cabinets',
      },
    );
  };

  public getCabinet = (id: number) => {
    return this.request<'CABINET', CabinetResponse>('/cabinet', 'GET_CABINET', {
      pathParam: id,
      permit: 'cabinets',
    });
  };

  public createCabinet = (params: CreateCabinetReq) => {
    return this.request<'CABINET', CabinetResponse, CreateCabinetReq>(
      '/cabinet',
      'CREATE_CABINET',
      {
        method: 'POST',
        params,
        permit: 'cabinets',
      },
    );
  };

  public updateCabinet = (id: number, params: UpdateCabinetReq) => {
    return this.request<'CABINET', CabinetResponse, UpdateCabinetReq>(
      '/cabinet',
      'UPDATE_CABINET',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'cabinets',
      },
    );
  };

  public activateCabinet = (id: number) => {
    return this.request<'CABINET', CabinetResponse>(
      '/cabinet',
      'ACTIVATE_CABINET',
      {
        method: 'PUT',
        pathParam: `${id}/activate`,
        permit: 'cabinets',
      },
    );
  };

  public deactivateCabinet = (id: number) => {
    return this.request<'CABINET'>('/cabinet', 'DEACTIVATE_CABINET', {
      method: 'DELETE',
      pathParam: id,
      permit: 'cabinets',
    });
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET TYPE
  |----------------------------------------------------------------------------
  */
  public getCabinetsType = (query?: GetCabinetsTypeQuery) => {
    return this.request<'CABINET', CabinetType[], GetCabinetsTypeQuery>(
      '/cabinetType',
      'GET_CABINETS_TYPE',
      {
        query,
        permit: 'cabinet_type',
      },
    );
  };

  public getCabinetType = (id: number) => {
    return this.request<'CABINET', CabinetType>(
      '/cabinetType',
      'GET_CABINET_TYPE',
      {
        pathParam: id,
        permit: 'cabinet_type',
      },
    );
  };

  public createCabinetType = (params: CreateCabinetTypeReq) => {
    return this.request<'CABINET', CabinetType, CreateCabinetTypeReq>(
      '/cabinetType',
      'CREATE_CABINET_TYPE',
      {
        method: 'POST',
        params,
        permit: 'cabinet_type',
      },
    );
  };

  public updateCabinetType = (id: number, params: UpdateCabinetTypeReq) => {
    return this.request<'CABINET', CabinetType, UpdateCabinetTypeReq>(
      '/cabinetType',
      'UPDATE_CABINET_TYPE',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'cabinet_type',
      },
    );
  };

  public deleteCabinetType = (id: number) => {
    return this.request<'CABINET'>('/cabinetType', 'DELETE_CABINET_TYPE', {
      method: 'DELETE',
      pathParam: id,
      permit: 'cabinet_type',
    });
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET SERVICE
  |----------------------------------------------------------------------------
  */
  public getCabinetServices = (id: number, query?: GetCabinetServicesQuery) => {
    return this.request<
      'CABINET',
      ServiceResponse[],
      null,
      GetCabinetServicesQuery
    >('/cabinet', 'GET_CABINET_SERVICES', {
      query,
      pathParam: `${id}/services`,
      // PERMIT ??
    });
  };
}
