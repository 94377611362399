import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPackage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.167 15.5q-.688 0-1.177-.49a1.6 1.6 0 0 1-.49-1.177V3.438q0-.292.094-.563.093-.27.281-.5l1.042-1.27q.228-.293.573-.449Q2.833.5 3.208.5h9.584q.376 0 .718.156.344.156.573.448l1.042 1.271q.188.23.281.5t.094.563v10.395q0 .688-.49 1.177-.489.49-1.177.49zM2.5 3h11l-.708-.833H3.208zm8.833 1.667H4.667v6.666L8 9.667l3.333 1.666z"
      />
    </svg>
  );
};
export default SvgPackage;
