import React from 'react';
import App from './App';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

if (!__IS_VITE_BUILDER__ && module.hot) {
  module.hot.accept();
  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') console.clear();
  });
}

function renderApp() {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container, {
      onRecoverableError: Sentry.reactErrorHandler(),
    });
    const AppWithProfiler = Sentry.withProfiler(App);
    root.render(<AppWithProfiler />);
  }
}

renderApp();
