import { AppointmentStore } from './appointment.store';

export class AppointmentBridge {
  constructor(
    protected _store: AppointmentStore,
    protected _storeId: string,
  ) {}

  public getAppointments = (data: WsResponse<AppointmentType[]>) => {
    if (
      data.result.success &&
      data.storage &&
      data.storage.id &&
      data.storage.id === this._storeId
    ) {
      this._store.setAppointments(data.result.data, data.result.meta);
    }
  };

  public getAppointmentHistory = (
    data: WsResponse<AppointmentHistoryResponse[]>,
  ) => {
    if (data.result.success) {
      this._store.setAppointmentHistory(data.result.data);
    }
  };

  public updateAppointment = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.updateAppointment(data.result.data);
    }
  };

  public updateBatchAppointment = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.updateBatchAppointment(data.result.data);
    }
  };

  public createAppointment = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.createAppointment(data.result.data);
    }
  };

  public changeStatus = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.changeStatus(data.result.data);
    }
  };
}
