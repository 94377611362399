import { FetchService } from '@modules/Api';

export class BiomaterialApi extends FetchService {
  // BIOMATERIALS
  public getBiomaterials = (query?: GetBiomaterialsQuery) => {
    return this.request<'BIOMATERIAL', Biomaterial[]>(
      '/biomaterial',
      'GET_BIOMATERIALS',
      // ?? PERMIT
      { query },
    );
  };

  public getBiomaterial = (id: number) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial',
      'GET_BIOMATERIAL',
      // ?? PERMIT
      { pathParam: id },
    );
  };

  public sentBiomaterial = (params: SendBiomaterialParams[]) => {
    return this.request<
      'BIOMATERIAL',
      Array<Biomaterial | SendBiomaterialErrorRes>
    >(
      '/biomaterial/sent',
      'BIOMATERIAL_SENT',
      // ?? PERMIT
      { method: 'PUT', params },
    );
  };

  public sortedBiomaterial = (id: number, params: SortBiomaterialParams) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial',
      'BIOMATERIAL_SORTED',
      // ?? PERMIT
      { pathParam: `${id}/sorted`, method: 'PUT', params },
    );
  };

  public sortBiomaterialByServiceNumber = (
    serviceNumber: string | number,
    params: SortByBiomaterialServiceNumberParams,
  ) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial/set_sorted_by_bms_number',
      'BIOMATERIAL_SORTED',
      // ?? PERMIT
      { pathParam: String(serviceNumber), method: 'PUT', params },
    );
  };

  public takenBiomaterial = (id: number, params?: TakenBiomaterial) => {
    return this.request<'BIOMATERIAL', Biomaterial, TakenBiomaterial>(
      '/biomaterial',
      'BIOMATERIAL_TAKEN',
      // ?? PERMIT
      { pathParam: `${id}/taken`, method: 'PUT', params },
    );
  };

  public invalidBiomaterial = (id: number, params: GetBiomaterialInvalid) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial',
      'BIOMATERIAL_INVALID',
      // ?? PERMIT
      { pathParam: `${id}/invalid`, method: 'PUT', params },
    );
  };

  public forciblySendBiomaterial = (id: number) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial',
      'BIOMATERIAL_FORCIBLY_SEND',
      { pathParam: `${id}/sent_ignore_paid`, method: 'PUT' },
    );
  };

  public spoiledBiomaterial = (
    id: number,
    params: SpoiledBiomaterialParams,
  ) => {
    return this.request<'BIOMATERIAL', Biomaterial>(
      '/biomaterial',
      'BIOMATERIAL_SPOILED',
      // ?? PERMIT
      { method: 'PUT', pathParam: `${id}/spoiled`, params },
    );
  };

  // BIOMATERIAL TYPES
  public getBiomaterialTypes = (query?: GetBiomaterialTypesQuery) => {
    return this.request<
      'BIOMATERIAL',
      BiomaterialType[],
      null,
      GetBiomaterialTypesQuery
    >(
      '/biomaterial_type',
      'GET_BIOMATERIAL_TYPES',
      // ?? PERMIT
      { query },
    );
  };

  public getBiomaterialType = (id: number) => {
    return this.request<'BIOMATERIAL', BiomaterialType>(
      '/biomaterial_type',
      'GET_BIOMATERIAL_TYPE',
      // ?? PERMIT
      {
        pathParam: id,
      },
    );
  };

  public createBiomaterialType = (params: CreateBiomaterialTypeParams) => {
    return this.request<'BIOMATERIAL', BiomaterialType>(
      '/biomaterial_type',
      'CREATE_BIOMATERIAL_TYPE',
      // ?? PERMIT
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateBiomaterialType = (
    id: number,
    params: UpdateBiomaterialTypeParams,
  ) => {
    return this.request<'BIOMATERIAL', BiomaterialType>(
      '/biomaterial_type',
      'UPDATE_BIOMATERIAL_TYPE',
      // ?? PERMIT
      {
        method: 'PUT',
        params,
        pathParam: id,
      },
    );
  };

  public deleteBiomaterialType = (id: number) => {
    return this.request<'BIOMATERIAL', BiomaterialType>(
      '/biomaterial_type',
      'DELETE_BIOMATERIAL_TYPE',
      // ?? PERMIT
      {
        method: 'DELETE',
        pathParam: id,
      },
    );
  };
}
