import { PreloadLazy } from '@router/utils';

export const REPORTS_ROUTES: PrivateRoute = {
  group: 'REPORTS',
  name: 'reports',
  path: '/reports',
  exact: false,
  sidebar: true,
  icon: 'OutlineArticle',
  activeIcon: 'Article',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'reports',
      path: '/reports',
      exact: true,
      component: PreloadLazy(() => import('@views/Reports')),
    },
    {
      group: 'REPORTS_OPERATIONAL',
      name: 'reports_operational',
      path: '/reports/operational',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/operation_report.svg'),
      routes: [
        {
          name: 'reports_operational',
          path: '/reports/operational',
          exact: true,
          component: PreloadLazy(() => import('@views/Reports/Operational')),
        },
        {
          name: 'appointments',
          path: '/reports/operational/appointments',
          exact: false,
          ws: true,
          component: PreloadLazy(
            () => import('@views/Reports/Operational/AppointmentsReport'),
          ),
          imagePath: () => import('@assets/img/preview/settings_documents.svg'),
          bgColor: 'bg-blue-50',
        },
        {
          name: 'visits',
          path: '/reports/operational/visits',
          exact: false,
          component: PreloadLazy(
            () => import('@views/Reports/Operational/Visits'),
          ),
          imagePath: () => import('@assets/img/preview/specializations.svg'),
        },
        {
          name: 'reports_schedules',
          path: '/reports/operational/schedules',
          exact: false,
          ws: true,
          component: PreloadLazy(
            () => import('@views/Reports/Operational/Schedules'),
          ),
          imagePath: () => import('@assets/img/preview/schedule.svg'),
        },
      ],
    },
    {
      group: 'REPORTS_FINANCIAL',
      name: 'reports_financial',
      path: '/reports/financial',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/financial_report.svg'),
      routes: [
        {
          name: 'reports_financial',
          path: '/reports/financial',
          exact: true,
          component: PreloadLazy(() => import('@views/Reports/Financial')),
        },
        {
          name: 'orders',
          path: '/reports/financial/orders',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Orders'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
        {
          name: 'payments',
          path: '/reports/financial/payments',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Payments'),
          ),
          imagePath: () => import('@assets/img/preview/cashplace.svg'),
        },
        {
          name: 'insurance',
          path: '/reports/financial/insurance',
          exact: true,
          ws: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Insurance'),
          ),
          imagePath: () => import('@assets/img/preview/security.png'),
        },
        {
          name: 'cash_operation',
          path: '/reports/financial/cash',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/CashOperations'),
          ),
          imagePath: () => import('@assets/img/preview/security.png'),
        },
        {
          name: 'balance',
          path: '/reports/financial/balance',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Balance'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
        // !!! disabled until redesign of the backend
        // {
        //   name: 'provided_services_report',
        //   path: '/reports/financial/services',
        //   exact: true,
        //   component: PreloadLazy(
        //     () => import('@views/Reports/Financial/Services'),
        //   ),
        //   imagePath: () => import('@assets/img/preview/financial_report.svg'),
        // },
        {
          name: 'download',
          path: '/reports/financial/download',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Download'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
        {
          name: 'payers',
          path: '/reports/financial/payers',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Financial/Payers'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
      ],
    },
    {
      group: 'REPORTS_MANAGEMENT',
      name: 'reports_management',
      path: '/reports/management',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/financial_report.svg'),
      routes: [
        {
          name: 'reports_management',
          path: '/reports/management',
          exact: true,
          component: PreloadLazy(() => import('@views/Reports/Management')),
        },
        {
          name: 'salary',
          path: '/reports/management/salary',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Management/Salary'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
        {
          name: 'salary',
          path: '/reports/management/salary/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Reports/Management/SalaryDetailed'),
          ),
        },
        {
          name: 'external_directions',
          path: '/reports/management/referrals',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Management/Referrals'),
          ),
          imagePath: () => import('@assets/img/preview/import_med-cards.svg'),
        },
        {
          name: 'external_directions',
          path: '/reports/management/referrals/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/Reports/Management/Referral'),
          ),
        },
      ],
    },
    {
      group: 'REPORTS_COUNTERPARTY',
      name: 'reports_counterparty',
      path: '/reports/counterparty',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/business_deal.svg'),
      routes: [
        {
          name: 'reports_counterparty',
          path: '/reports/counterparty',
          exact: true,
          component: PreloadLazy(() => import('@views/Reports/Counterparty')),
        },
        {
          name: 'reports_counterparty_general',
          path: '/reports/counterparty/general',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Counterparty/General'),
          ),
        },
        {
          name: 'reports_counterparty_by_patients',
          path: '/reports/counterparty/by_patients',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Reports/Counterparty/ByPatients'),
          ),
        },
      ],
    },
  ],
};
