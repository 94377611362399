import { makeAutoObservable } from 'mobx';

export class CompletedAppointmentStore {
  public readonly supportedStatuses: AppointmentStatus[] = [
    'waiting_results',
    'on_payment',
    'done',
  ];

  public patient: Patient | null = null;
  public appointment: AppointmentType | null = null;

  public appointments: AppointmentType[] = [];
  public documents: MedDocument[] = [];
  public biomaterials: Biomaterial[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get documentsByEpisode() {
    const grouped = new Map<
      number,
      { episode: MedEpisode; documents: MedDocument[] }
    >();

    for (const document of this.documents) {
      const saved = grouped.get(document.episode.id);

      grouped.set(document.episode.id, {
        episode: document.episode,
        documents: saved ? saved.documents.concat(document) : [document],
      });
    }

    return Array.from(grouped.values());
  }

  public setAppointments = (appointments: AppointmentType[]) => {
    this.appointments = appointments;
  };

  public setAppointment = (appointment: AppointmentType | null) => {
    this.appointment = appointment;
  };

  public setDocuments = (data: MedDocument[]) => {
    this.documents = data;
  };

  public setBiomaterials = (data: Biomaterial[]) => {
    this.biomaterials = data;
  };

  public setPatient = (patient: Patient | null) => {
    this.patient = patient;
  };

  public changeStatus = (appointment: AppointmentType) => {
    const isSelected = this.appointment?.id === appointment.id;
    const isSupported = this.supportedStatuses.includes(appointment.status);
    const isExist = this.appointments.some((a) => a.id === appointment.id);

    if (isSelected) this.setAppointment(appointment);

    if (isSupported) {
      if (isExist) this.updateAppointment(appointment);
      else this.addAppointment(appointment);
    } else {
      this.deleteAppointment(appointment);
    }
  };

  public updateAppointment = (appointment: AppointmentType) => {
    this.appointments = this.appointments.map((app) => {
      if (app.id === appointment.id) return appointment;
      return app;
    });
  };

  public addAppointment = (appointment: AppointmentType) => {
    this.appointments = this.appointments.concat(appointment);
  };

  public deleteAppointment = (appointment: AppointmentType) => {
    this.appointments = this.appointments.filter((a) => {
      return a.id !== appointment.id;
    });
  };
}
