import { reaction } from 'mobx';
import { Store } from '@stores';

export const appReactions = reaction(
  () => Store.AppService.serverError,
  (serverError) => {
    if (serverError) {
      Store.AuthService.setLogout();
    }
  },
);
