import { action, makeObservable, observable } from 'mobx';
import { AnalysesService } from './AnalysesService';

type AnalysesServices = {
  primary: AnalysesService;
  secondary: AnalysesService[];
};

export class Analyse {
  @observable public list: AppointmentType[] = [];
  @observable public services: AnalysesServices[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (data: AppointmentType[]) => {
    this.list = data;
  };

  @action
  public add = (data: AppointmentType) => {
    this.list.unshift(data);
  };

  @action
  public addService = (
    primary: ServiceResponse,
    secondary: ServiceResponse,
    biomaterialType: BiomaterialType | null,
    secondaryPackage: PatientPkgServiceService | null,
    primaryPackage: PatientPkgServiceService | null,
  ) => {
    const groupedServices = this.services.find((group) => {
      return group.primary.service.id === primary.id;
    });
    if (!groupedServices) {
      this.services.push({
        primary: new AnalysesService({
          service: primary,
          biomaterialType,
          packageService: primaryPackage ?? null,
        }),
        secondary: [
          new AnalysesService({
            service: secondary,
            biomaterialType,
            packageService: secondaryPackage,
          }),
        ],
      });
    }
    if (groupedServices) {
      groupedServices.secondary.push(
        new AnalysesService({
          service: secondary,
          biomaterialType,
          packageService: secondaryPackage,
        }),
      );
    }
  };

  @action
  public removeService = (id: number) => {
    const services: AnalysesServices[] = [];
    const removed: AnalysesService[] = [];

    for (const { primary, secondary } of this.services) {
      for (const service of secondary) {
        const _secondary = [];

        if (service.service.id === id) {
          removed.push(service);
        } else {
          _secondary.push(service);
        }

        if (!_secondary.length) removed.push(primary);
        else {
          services.push({ primary, secondary: _secondary });
        }
      }
    }

    this.services = services;
    return removed;
  };

  @action
  public clearServices = () => {
    this.services = [];
  };
}
