import { action, autorun, makeObservable, observable } from 'mobx';
import { LStorage } from '@modules/Storage';
import { onlyUnique } from '@utils/helpers';

export default class PermissionsStore {
  @observable public isAdmin = false;
  @observable public allowedRoutes: RoutePath[] | null = LStorage.getItem(
    'UI',
    true,
  );
  @observable public crudPermissions: Permission[] | null = LStorage.getItem(
    'CRUD',
    true,
  );

  constructor() {
    makeObservable(this);
    autorun(() => {
      LStorage.sync('CRUD', this.crudPermissions);
      LStorage.sync('UI', this.allowedRoutes);
    });
  }

  @action
  public setIsAdmin(isRoot: boolean): void {
    this.isAdmin = isRoot;
  }

  @action
  public setAllowedRoutes(iuModules: ProfileUiModules[] = []): void {
    const pathList = iuModules
      ?.map((e) => e.ui_module.name)
      .filter(onlyUnique) as RoutePath[];
    this.allowedRoutes = ['/', ...pathList];
  }

  @action
  public setCrudPermissions(permission: Permission[]): void {
    this.crudPermissions = permission;
  }

  @action
  public logout(): void {
    LStorage.removeItem('UI');
    LStorage.removeItem('CRUD');
  }
}
