import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPicErrorConnect = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={240}
      height={240}
      fill="none"
      role="img"
      {...props}
    >
      <path
        fill="#EF4444"
        d="M39.317 146.707s12 27.36 42.34 36.307 62.741-2.976 89.218-11.937c26.477-8.962 40.402-31.877 34.503-55.373s-28.205-21.806-49.008-42.672-23.175-29.573-52.004-35.736-60.384 18.1-68.275 52.464 3.226 56.947 3.226 56.947"
      />
      <path
        fill="#fff"
        d="M39.317 146.707s12 27.36 42.34 36.307 62.741-2.976 89.218-11.937c26.477-8.962 40.402-31.877 34.503-55.373s-28.205-21.806-49.008-42.672-23.175-29.573-52.004-35.736-60.384 18.1-68.275 52.464 3.226 56.947 3.226 56.947"
        opacity={0.8}
      />
      <path
        fill="#EF4444"
        d="M193.44 109.728s11.002 1.09 16.575 7.91c0 0 5.894-7.9 16.473-7.737a21.38 21.38 0 0 0 4.747-10.927 21.376 21.376 0 0 0-21.111-24.104 21.386 21.386 0 0 0-21.334 23.907 21.4 21.4 0 0 0 4.645 10.97z"
      />
      <path
        fill="#fff"
        d="M193.44 109.728s11.002 1.09 16.575 7.91c0 0 5.894-7.9 16.473-7.737a21.38 21.38 0 0 0 4.747-10.927 21.376 21.376 0 0 0-21.111-24.104 21.386 21.386 0 0 0-21.334 23.907 21.4 21.4 0 0 0 4.645 10.97z"
        opacity={0.5}
      />
      <path fill="#9CA3AF" d="m209.645 76.378-.37 71.299h1.858z" />
      <path
        fill="#9CA3AF"
        d="M210.207 115.56s3.71-6.686 10.396-8.731c0 0-7.056 1.675-10.56 7.43zm0-18.94s3.71-6.687 10.396-8.732c0 0-7.056 1.67-10.56 7.43zm0 9.46s3.71-6.686 10.396-8.731c0 0-7.056 1.675-10.56 7.43zm-.553-4.637s-3.585-6.681-10.036-8.726c0 0 6.811 1.67 10.214 7.43zm0 9.288s-3.585-6.686-10.036-8.726c0 0 6.811 1.67 10.214 7.425z"
      />
      <path
        fill="#EF4444"
        d="M13.378 109.728s11.001 1.09 16.574 7.91c0 0 5.894-7.9 16.474-7.737a21.383 21.383 0 0 0-16.364-35.03 21.384 21.384 0 0 0-16.69 34.876z"
      />
      <path
        fill="#fff"
        d="M13.378 109.728s11.001 1.09 16.574 7.91c0 0 5.894-7.9 16.474-7.737a21.383 21.383 0 0 0-16.364-35.03 21.384 21.384 0 0 0-16.69 34.876z"
        opacity={0.5}
      />
      <path fill="#9CA3AF" d="m29.592 76.378-.374 71.299h1.857z" />
      <path
        fill="#9CA3AF"
        d="M30.149 115.56s3.71-6.686 10.397-8.731c0 0-7.056 1.675-10.56 7.43zm0-18.94s3.71-6.687 10.397-8.732c0 0-7.056 1.67-10.56 7.43zm0 9.46s3.71-6.686 10.397-8.731c0 0-7.056 1.675-10.56 7.43zm-.552-4.637s-3.586-6.681-10.037-8.726c0 0 6.811 1.67 10.215 7.43zm0 9.288s-3.586-6.686-10.037-8.726c0 0 6.811 1.67 10.215 7.425z"
      />
      <path
        fill="#EF4444"
        d="M148.738 116.64s9.005.888 13.565 6.47c0 0 4.824-6.465 13.483-6.336a17.5 17.5 0 1 0-27.053-.12z"
      />
      <path
        fill="#fff"
        d="M148.738 116.64s9.005.888 13.565 6.47c0 0 4.824-6.465 13.483-6.336a17.5 17.5 0 1 0-27.053-.12z"
        opacity={0.5}
      />
      <path fill="#9CA3AF" d="m162.005 89.318-.302 58.359h1.521z" />
      <path
        fill="#9CA3AF"
        d="M162.461 121.392s3.043-5.472 8.51-7.147c0 0-5.76 1.368-8.64 6.081zm0-15.504s3.043-5.472 8.51-7.142c0 0-5.76 1.368-8.64 6.076zm0 7.752s3.043-5.472 8.51-7.147c0 0-5.76 1.368-8.64 6.081zm-.451-3.802s-2.933-5.472-8.213-7.142c0 0 5.573 1.368 8.361 6.077zm0 7.599s-2.933-5.472-8.213-7.143c0 0 5.573 1.368 8.361 6.082z"
      />
      <path
        fill="#EF4444"
        d="M61.613 116.64s9.005.888 13.565 6.47c0 0 4.8-6.465 13.483-6.336a17.498 17.498 0 0 0-4.048-25.908 17.501 17.501 0 0 0-23.005 25.788z"
      />
      <path
        fill="#fff"
        d="M61.613 116.64s9.005.888 13.565 6.47c0 0 4.8-6.465 13.483-6.336a17.498 17.498 0 0 0-4.048-25.908 17.501 17.501 0 0 0-23.005 25.788z"
        opacity={0.5}
      />
      <path fill="#9CA3AF" d="m74.88 89.318-.302 58.359h1.521z" />
      <path
        fill="#9CA3AF"
        d="M75.36 121.392s3.043-5.472 8.51-7.147c0 0-5.76 1.368-8.64 6.081zm0-15.504s3.043-5.472 8.51-7.142c0 0-5.76 1.368-8.64 6.076zm0 7.752s3.043-5.472 8.51-7.147c0 0-5.76 1.368-8.64 6.081zm-.48-3.802s-2.928-5.472-8.208-7.118c0 0 5.573 1.368 8.362 6.077zm0 7.599s-2.933-5.472-8.213-7.143c0 0 5.573 1.368 8.362 6.082z"
      />
      <path
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={1.047}
        d="M16.69 147.931h207.139"
      />
      <path
        fill="#000"
        d="M73.243 171.096c0 1.44-8.846 2.587-19.766 2.587s-19.767-1.157-19.767-2.587 8.851-2.587 19.767-2.587 19.766 1.161 19.766 2.587m41.381 0c0 1.44-8.851 2.587-19.766 2.587-10.916 0-19.767-1.157-19.767-2.587s8.851-2.587 19.767-2.587 19.766 1.161 19.766 2.587m54.816 0c0 1.44-8.847 2.587-19.762 2.587s-19.766-1.157-19.766-2.587 8.846-2.587 19.766-2.587 19.762 1.161 19.762 2.587m45.792 0c0 1.44-8.851 2.587-19.766 2.587-10.916 0-19.786-1.157-19.786-2.587s8.851-2.587 19.766-2.587c10.916 0 19.786 1.161 19.786 2.587"
        opacity={0.3}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M151.814 95.41s-.811 3.36-.811 3.926c-.001.35-.039.7-.115 1.042 0 0-1.733 2.078-1.503 2.654a2.8 2.8 0 0 0 1.157 1.037s.922 3.36 1.848 3.581a22 22 0 0 0 2.88.345l.346 2.77 4.617-.691-1.152-4.157.48-3.581s.807-1.498 0-3.922c-.806-2.424-2.539-2.539-3.696-2.539a40 40 0 0 1-4.051-.465"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M153.893 93.682s-4.042-.12-3.927.921c.115 1.042 4.272 2.4 4.272 2.4s-1.387.922-.696 1.848c.691.927 2.309 1.733 2.309 1.733s-.48 1.963.235 2.078c.715.116 1.152-.926 1.267-1.272.116-.345 1.388-.23 1.733.692.196.478.312.986.346 1.502 0 0 1.387-4.157 1.617-6.005.231-1.848-4.963-3.897-7.156-3.897"
      />
      <path
        fill="#fff"
        d="M156.451 102.72s1.397-3.072 2.232-1.56.48 3.086-.341 3.254c-.82.168-1.507.332-1.507.332"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M156.451 102.72s1.397-3.072 2.232-1.56.48 3.086-.341 3.254c-.82.168-1.507.332-1.507.332"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M150.773 134.779c-.226.264-3.927 8.199-4.388 10.853s-3.46 18.475-3.58 19.166c-.12.692-6.461 2.079-6.927 2.194s.48 1.387 1.848 1.733c1.368.345 5.314 1.963 6.24 1.037s2.194-6.12 2.539-6.696 6.845-15.888 6.845-15.888 3.331-7.349 4.023-8.045-.821-3.773-1.402-4.699c-.581-.927-3.581-1.503-5.198.345"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M150.082 134.88s1.617 7.738 2.424 10.507a22.1 22.1 0 0 0 3.36 6.466c.926 1.272 5.428 12.48 5.428 12.48l.692 1.733s-.231.691-1.498 1.612-4.853 2.424-3.926 2.775c.926.35 4.617.345 6.35.23a6 6 0 0 0 3.922-1.848c.696-.811-1.152-3.12-1.152-3.581s-4.042-14.544-4.272-15.24-1.388-5.654-1.388-5.654v-5.64s.581-1.037.581-3.36-1.733-2.774-1.733-2.774l-9.47.926z"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M149.28 134.203s.235 1.963.926 2.079c.691.115 7.848 2.078 10.968-.231 0 0 .576-1.733.231-2.193-.346-.461-1.157-.231-5.309-.231s-6.816 0-6.816.576"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M159.893 108.341s-4.272.576-6.12 2.4-3.461 5.198-4.268 8.313-1.387 14.549-.235 15.125 10.853 1.157 12.48.48-.115-6.12-.115-8.198 1.848-9.12 1.502-13.042c-.345-3.921-3.244-5.078-3.244-5.078"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M144.48 120.341c-.053-4.752-1.992-8.808-4.57-10.56v-.96l-7.104.072c-3.998.043-7.2 5.28-7.118 11.664s3.36 11.553 7.363 11.52l7.104-.072v-.96c2.496-1.858 4.349-5.952 4.325-10.704"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M147.667 120.307c-.053-4.992-1.978-9.254-4.685-11.107v-1.022l-3.043.033c-4.205.043-7.55 5.53-7.478 12.255.072 6.724 3.532 12.139 7.737 12.096l3.043-.034v-1.018c2.645-1.91 4.479-6.23 4.426-11.203"
      />
      <path
        fill="#6B7280"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M150.72 120.274c.072 6.72-3.278 12.211-7.478 12.254s-7.68-5.371-7.738-12.091 3.278-12.211 7.478-12.259 7.656 5.371 7.738 12.096"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M148.973 120.067c.033 3.183-1.555 5.784-3.547 5.808s-3.634-2.549-3.668-5.76c-.033-3.211 1.556-5.76 3.548-5.808s3.633 2.573 3.667 5.76M205.536 144c.396-2.959.565-5.944.504-8.928-1.56.72-10.661.984-11.496 1.003s-9.936-.283-11.496-1.003a58 58 0 0 0 .48 8.928c.629 3.893 6.158 19.478 6.283 20.232q.243 1.092.629 2.141s-3.144 2.261-4.526 3.139c-1.383.878-.375 1.258 1.008 1.258 1.382 0 4.272-.38 5.15-.38.778 0 2.131-.676 2.448-.84.317.164 1.67.84 2.443.84.883 0 3.773.38 5.155.38s2.4-.38 1.008-1.258-4.526-3.139-4.526-3.139q.386-1.05.629-2.141c.149-.768 5.678-16.354 6.307-20.232"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M192.643 110.635s-4.862 1.589-7.373 3.183c-2.51 1.593-5.03 10.224-5.03 10.56-.144.601-.34 1.189-.586 1.756 0 0-2.515-.585-3.014 1.508s2.51 4.776 4.104 4.862c1.593.086 2.093-.418 2.093-.418s-1.003 2.012-.586 2.847c.418.835 3.941 3.941 10.896 3.84s12-1.258 12.734-2.261c.735-1.003.84-3.101.84-3.101s2.597-.672 3.519-2.347c.921-1.675.753-3.437.249-5.194-.504-1.756-3.1-6.859-4.857-9.71s-5.448-4.44-7.2-4.944-4.618-.581-5.789-.581"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M194.117 117.691c.696-.23 2.88-2.169 3.72-4.109a9.4 9.4 0 0 0 .696-2.328l-.111-.033c-1.756-.504-4.608-.586-5.76-.586 0 0-1.017.331-2.361.845a8.6 8.6 0 0 0 .576 3.034c.758 1.939 2.563 3.412 3.24 3.177"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M202.109 121.608s2.011 10.728 4.291 11.832m-23.563-1.354s2.515-9.47 2.683-10.392"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M205.358 126.456s-1.392.312-1.392 1.32m-22.257-1.166s1.55 1.008 1.397 2.558"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M192.096 170.4c.777 0 2.131-.677 2.448-.84.317.163 1.67.84 2.443.84.883 0 3.773.379 5.155.379s2.4-.379 1.008-1.257c-1.205-.764-3.729-2.568-4.373-3.029-1.483 1.353-4.416 1.017-4.416 1.017a15 15 0 0 1-1.761-.374c-.879-.254-2.136-.754-2.136-.754s-3.144 2.261-4.527 3.14c-1.382.878-.374 1.257 1.008 1.257s4.272-.379 5.151-.379"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M194.362 166.402v-21.917"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="m194.544 169.536-.182-2.05v-1.084"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M192.6 143.731h3.643"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M187.838 98.06s-2.611 1.564-2.222 2.937 1.569 1.637 1.31 2.4-1.166 1.243-.134 1.723a8.8 8.8 0 0 0 1.833.59s.96 3.332 2.612 3.917c1.651.586 4.512.197 6.537-.062a3.385 3.385 0 0 0 2.938-2.487c.197-.715-.85.135-1.373-.652-.523-.788.264-2.808.85-4.575.585-1.766-1.176-5.097-3.461-6.206s-6.648-.528-8.89 2.414Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M192.226 109.44a17.6 17.6 0 0 0-.672 3.36c-.087 1.44 1.089 3.019 2.347 2.933 1.257-.087 2.342-2.597 2.51-3.84s-.417-4.608-.417-4.608-1.92.086-2.684.753c-.448.394-.816.87-1.084 1.402"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M190.522 99.888s.062 1.958 2.025 3.84a12.1 12.1 0 0 0 4.114 2.616 3.6 3.6 0 0 1-.327 1.238.9.9 0 0 1-.912.48h-.657s-.96 2.252-2.122 2.578-4.017.101-4.411-2.774-.456-5.228-.13-5.818c.327-.59 2.42-2.16 2.42-2.16m20.539 25.296c.298-.6.478-1.252.532-1.92 0-.874-1.622-2.126-1.92-2.438s-.595-.279-.657.158a1.78 1.78 0 0 0 .561 1.31c.244.192.42.456.504.754.029.216-.537 1.277-.254 1.963s1.08.893 1.234.173"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.142}
        d="M146.054 120.283s7.066 2.261 11.314 7.541 7.037 4.522 12.317 2.765 7.291-8.045 14.582-2.765 8.798 7.286 14.832 4.272 9.298-10.306 14.832-7.795 3.014 15.36 3.518 21.6-.254 21.873 1.004 23.885c1.257 2.011 14.078.48 14.078.48"
      />
      <path
        fill="#EF4444"
        d="M159.288 111.893s-1.406-2.295-3.48-1.695c-2.194.634-3.326 3.768-3.84 5.722s-.859 4.066-1.8 5.885c0 0-4.157-.072-6.475-.744a55 55 0 0 1-5.213-1.92s-.821-1.147-1.402-1.373c-.58-.226-2.088-.667-2.198-.322s1.747 1.484 1.747 1.484-3.456.48-3.801.849c-.346.37 2.443 1.474 2.443 1.474s1.041.336 3.576-.269c0 0 9.297 5.904 11.15 6.581s2.88.893 4.143-1.546 5.452-8.486 6.12-10.92"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M159.288 111.893s-1.406-2.295-3.48-1.695c-2.194.634-3.326 3.768-3.84 5.722s-.859 4.066-1.8 5.885c0 0-4.157-.072-6.475-.744a55 55 0 0 1-5.213-1.92s-.821-1.147-1.402-1.373c-.58-.226-2.088-.667-2.198-.322s1.747 1.484 1.747 1.484-3.456.48-3.801.849c-.346.37 2.443 1.474 2.443 1.474s1.041.336 3.576-.269c0 0 9.297 5.904 11.15 6.581s2.88.893 4.143-1.546 5.452-8.486 6.12-10.92"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M140.894 120.12c-1.358-.509-2.4-.96-2.4-.96s-.82-1.147-1.401-1.373-2.088-.667-2.199-.321c-.11.345 1.748 1.483 1.748 1.483s-3.456.48-3.802.849c-.346.37 2.443 1.474 2.443 1.474s1.042.336 3.576-.269l1.301.821zm35.837 5.947a5.3 5.3 0 0 1-.533-1.92c0-.873 1.627-2.121 1.92-2.433s.591-.279.653.158a1.78 1.78 0 0 1-.562 1.31 1.48 1.48 0 0 0-.504.754c-.028.211.538 1.277.255 1.963s-1.075.883-1.229.168"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M177.85 123.432s-1.34 2.472-.562 3.36 1.531-1.997 1.675-2.794-.619-1.598-1.113-.566"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M178.954 124.32s-1.34 2.472-.562 3.36 1.531-1.997 1.675-2.794-.619-1.627-1.113-.566"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M180.168 124.526s-1.339 2.472-.562 3.36c.778.888 1.532-1.996 1.676-2.793s-.62-1.603-1.114-.567"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M181.723 124.546s-1.248 1.92-.566 2.644c.681.725 1.416-1.555 1.56-2.212s-.533-1.296-.994-.432m28.445-1.018s1.339 2.472.562 3.36c-.778.888-1.532-2.002-1.676-2.798s.62-1.599 1.114-.562"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M209.064 124.392s1.339 2.472.562 3.36c-.778.888-1.532-2.002-1.676-2.798s.62-1.594 1.114-.562"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M207.84 124.622s1.339 2.472.561 3.36-1.531-1.996-1.67-2.798.629-1.598 1.109-.562"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M206.294 124.642s1.248 1.944.567 2.644c-.682.701-1.416-1.555-1.556-2.212-.139-.658.528-1.296.989-.432m-165.149 27.35v2.726a8.64 8.64 0 0 0-1.982 3.639c-.58 2.227-2.4 11.813-1.92 12.062.48.25 4.997 1.282 6.437 1.066 1.651-.25 3.057-.163 2.227-1.075s-5.193-3.461-5.193-3.461 4.8-6.941 5.865-9.504 1.239-6.528 1.239-6.528l-6.442-.245-.23 1.32Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M43.68 171.485c1.651-.25 3.057-.163 2.227-1.075s-5.194-3.461-5.194-3.461l-.163.989-3.144-.384c-.23 1.603-.331 2.779-.163 2.88.514.23 4.997 1.267 6.437 1.051"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="m56.928 153.403.744 1.325a4.84 4.84 0 0 0-.25 3.883c.744 2.314 2.482 10.824 2.482 11.319 0 .494 1.07.825 1.92.825s3.201.163 3.533.163c.33 0 2.726.413 3.552-.081.825-.495-1.052-1.488-2.29-2.064s-3.965-2.064-3.965-2.064.658-6.778.658-9.754.662-3.965-.245-5.784-6.61.831-6.61.831z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M66.595 168.773c-1.238-.581-3.965-2.064-3.965-2.064l-.33 1.238-2.655.48c.158.826.259 1.392.259 1.507 0 .48 1.07.826 1.92.826s3.202.163 3.533.163 2.726.413 3.552-.081c.825-.495-1.075-1.493-2.314-2.069"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M52.478 98.4a23 23 0 0 0-2.212-3.14c-1.138-1.28-4.704-1.209-6.27.48-1.564 1.69-1.07 4.705-.48 5.559s1.782 4.848 1.782 4.848l.283 2.851 6.628 2.352-1.353-4.632s2.568-.48 2.78-1.281c.21-.802-.428-3.495-.428-3.495s.854-.571.782-1.07-1.512-2.472-1.512-2.472"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M44.851 101.472s1.133.96 1.752-.139c.62-1.099 1.09-2.175 1.09-3.106s-2.328-1.704-.778-2.328 2.669.763 3.36-.629.062-1.54-3.662-1.54c-3.725 0-5.588 1.862-4.8 4.19.63 1.72 1.408 3.38 2.328 4.963z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M52.69 100.046a1.781 1.781 0 1 1-3.563.001 1.781 1.781 0 0 1 3.563-.001m-.26-1.579a1.676 1.676 0 0 1 1.464 3.01m-4.766-1.431-4.277 1.426"
      />
      <path
        fill="#fff"
        d="M46.08 102.398s-.855-1.78-1.709-1.44-.427 3.14.427 3.423a1.86 1.86 0 0 0 1.637-.427s.715 1.497 1.57 1.92"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M46.08 102.398s-.855-1.78-1.709-1.44-.427 3.14.427 3.423a1.86 1.86 0 0 0 1.637-.427s.715 1.497 1.57 1.92"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="m40.474 134.4-.533 6.826-1.594 10.104s3.725 2.923 7.2 2.923 4.43-.442 4.43-.442l1.95-10.368 1.948 10.901s3.84-.619 6.384-1.243a16.8 16.8 0 0 0 4.963-2.304l-5.14-13.383-1.114-6.902s-5.76.533-9.22.71c-3.462.178-8.065-.71-8.065-.71z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M44.126 107.126s8.16 2.847 10.68 5.612c1.392 1.536 2.952 12.048 3.514 14.616s1.54 7.68 1.54 7.68-4.977 2.246-8.827 2.328-11.236-1.685-11.236-1.685.878-11.391.878-16.685.082-8.75.96-10.435c.005.014 1.45-1.594 2.491-1.431"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M41.98 136.502s.442 7.445 0 10.196a21.1 21.1 0 0 1-1.593 5.14"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M42.158 140.05a4.07 4.07 0 0 0 2.66-3.015m-6.029 12.855s4.435 3.811 11.347 2.4m3.547-.188s7.267-.796 10.46-2.923"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M86.189 103.68s-.701.518-3.144 2.304-.519 4.483-.519 4.483-1.747.274-4.08 2.727c-2.332 2.452 1.042 6.662 4.195 7.32 3.154.657 6.84-3.245 7.944-6.864 1.105-3.62-.355-9.077-1.09-10.205-.734-1.128-3.306.235-3.306.235"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M95.443 103.2s1.215-1.877.115-4.598c-1.099-2.722-4.382-3.965-7.579-1.992a5.63 5.63 0 0 0-2.54 6.369c.313 1.258 1.887 3.192 2.219 3.221.33.029 3.206 1.075 5.17.158 1.962-.916 2.615-3.158 2.615-3.158"
      />
      <path
        fill="#6B7280"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M81.317 166.56s2.28 3.547 6.24 4.152.398-2.947-.27-3.485c-.666-.537-5.433-1.699-5.97-.667m21.096.912s4.353 1.07 4.017 2.011-3.883.869-5.956.331c-2.074-.537-4.023-1.339-3.754-2.208.269-.868 5.693-.134 5.693-.134"
      />
      <path
        fill="#EF4444"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M90.034 110.256s-5.828.854-6.893 2.914c-1.066 2.059.436 13.276.436 13.276s-2.486 5.487-2.52 9.02 1.541 9.748 1.416 11.299c-.124 1.55-1.718 19.2-1.718 19.68s3.36 1.555 8.203 2.131c4.844.576 14.108-1.147 14.108-1.147s-.183-10.416.302-16.627c.485-6.212-.206-11.794-1.44-14.112-1.234-2.319-6.98-10.666-6.98-10.666s2.785-5.05 2.958-7.258c.172-2.208-.725-3.36-2.828-5.899a7.32 7.32 0 0 0-5.044-2.611"
      />
      <path
        fill="#6B7280"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M94.963 126.029s2.784-5.05 2.957-7.258-.725-3.36-2.827-5.899c-.101-.125-.207-.235-.312-.35l-.077.072c-2.16 3.528-9.302 9.556-11.429 11.313.163 1.503.303 2.539.303 2.539s-2.487 5.487-2.52 9.02c.04 1.609.2 3.214.48 4.8 5.044-2.88 11.793-8.271 15.446-11.276a412 412 0 0 0-2.02-2.961Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M88.958 106.138q.242 1.977.264 3.969c-.076.984.24 3.231 1.92 3.936s2.736-.278 2.731-1.267a10 10 0 0 0-.48-2.4l.048-2.712s-2.899-1.814-4.483-1.526"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M87.451 104.376s-1.234-1.085-1.272.48 1.402 2.083 1.718 2.189c.268.036.539-.031.759-.187 0 0 2.237 1.92 4.032 2.044s2.525-.705 2.947-3.964-2.942-6.567-2.942-6.567-2.88 5.309-5.242 6.005M60 134.966a4.3 4.3 0 0 1 .269-1.632c.297-.652 1.92-1.027 2.28-1.152.36-.124.542 0 .437.341a1.49 1.49 0 0 1-.87.787c-.248.06-.47.196-.638.389-.09.154-.033 1.138-.48 1.555s-1.147.303-.998-.288"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.142}
        d="M96.787 120.533s-5.76-.754-8.3 3.269c-2.538 4.022-7.285 18.096-16.084 20.361s-11.314-15.84-16.594-15.336c-5.03.48-8.543 0-11.563 5.53-1.613 2.961-9.677 35.299-13.488 35.63-12.139 1.061-19.953.37-19.953.37"
      />
      <path
        fill="#1E293B"
        d="M44.5 107.357s-1.329-.533-3.105.528-6.557 12.677-6.201 13.92c.355 1.243 5.404 3.547 8.241 4.344s7.978 1.776 7.978 1.776 1.593 2.481 2.126 2.568a11.6 11.6 0 0 0 2.304-.264s.96.797 1.33-.091-.355-2.036-1.152-2.924-4.167-1.329-4.167-1.329l-9.134-6.298 3.84-7.089"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M44.5 107.357s-1.329-.533-3.105.528-6.557 12.677-6.201 13.92c.355 1.243 5.404 3.547 8.241 4.344s7.978 1.776 7.978 1.776 1.593 2.481 2.126 2.568a11.6 11.6 0 0 0 2.304-.264s.96.797 1.33-.091-.355-2.036-1.152-2.924-4.167-1.329-4.167-1.329l-9.134-6.298 3.84-7.089"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M53.54 130.488a11.6 11.6 0 0 0 2.303-.264s.96.797 1.33-.091-.355-2.035-1.152-2.923-4.167-1.33-4.167-1.33l-9.134-6.293 1.363-2.539c-3.413-2.126-5.678-1.968-6.902-1.541-1.224 2.938-2.155 5.722-1.992 6.293.355 1.243 5.405 3.547 8.241 4.344 2.837.797 7.978 1.776 7.978 1.776s1.598 2.482 2.131 2.568Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M110.635 120.73c-.389-4.191-2.4-7.632-4.8-9l-.077-.855-6.269.581c-3.528.326-5.961 5.17-5.438 10.814s3.806 9.951 7.334 9.6l6.269-.58-.081-.855c2.112-1.766 3.451-5.515 3.062-9.705"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M113.472 120.48c-.408-4.402-2.4-8.026-4.939-9.466l-.087-.897-2.683.249c-3.705.346-6.264 5.429-5.717 11.362s4.004 10.459 7.709 10.114l2.683-.25-.081-.898c2.217-1.891 3.523-5.827 3.115-10.214"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M116.16 120.216c.552 5.933-2.006 11.016-5.717 11.362-3.71.345-7.161-4.186-7.723-10.114s2.006-11.04 5.712-11.362c3.706-.321 7.171 4.186 7.728 10.114"
      />
      <path
        fill="#6B7280"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="m119.04 116.75-10.411.96a1.12 1.12 0 0 1-.817-.251 1.1 1.1 0 0 1-.398-.757 1.11 1.11 0 0 1 .59-1.091c.13-.068.272-.11.418-.123l10.411-.96a1.12 1.12 0 0 1 .817.252q.17.141.274.338c.069.13.111.272.124.418.014.146-.001.293-.045.433a1.1 1.1 0 0 1-.545.658c-.13.068-.272.11-.418.123m.773 8.252-10.411.96a1.11 1.11 0 0 1-1.091-.591 1.1 1.1 0 0 1-.124-.417 1.11 1.11 0 0 1 .59-1.091c.13-.069.272-.111.418-.124l10.411-.96a1.122 1.122 0 0 1 1.17 1.442 1.1 1.1 0 0 1-.545.657c-.13.068-.272.11-.418.124"
      />
      <path
        fill="#EF4444"
        d="M86.433 111.038s-2.505.48-3.69 1.92-3.62 12.552-3.058 13.829c.561 1.277 9.561 2.88 12.34 3.111a40 40 0 0 0 6.692-.298s2.4-.062 3.057-.173c.658-.11 1.867-.758 1.887-1.003.019-.245-.101-.83-.999-.902-.897-.072-2.822-.716-3.067-.735a16 16 0 0 1 1.752-.355s.125-.48-.864-.48-1.886-.067-2.26.48a8.2 8.2 0 0 0-.682 1.344s-5.544.389-8.554-1.08a257 257 0 0 1-3.96-1.958l2.16-8.64"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M86.433 111.038s-2.505.48-3.69 1.92-3.62 12.552-3.058 13.829c.561 1.277 9.561 2.88 12.34 3.111a40 40 0 0 0 6.692-.298s2.4-.062 3.057-.173c.658-.11 1.867-.758 1.887-1.003.019-.245-.101-.83-.999-.902-.897-.072-2.822-.716-3.067-.735a16 16 0 0 1 1.752-.355s.125-.48-.864-.48-1.886-.067-2.26.48a8.2 8.2 0 0 0-.682 1.344s-5.544.389-8.554-1.08a257 257 0 0 1-3.96-1.958l2.16-8.64"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M101.76 129.442c.667-.111 1.867-.759 1.886-1.004.02-.244-.1-.83-.998-.902s-2.822-.715-3.067-.734a16 16 0 0 1 1.752-.356s.125-.48-.864-.48-1.887-.067-2.261.48a8.3 8.3 0 0 0-.682 1.344s-5.544.389-8.553-1.08a242 242 0 0 1-3.96-1.958l1.315-5.28a18.8 18.8 0 0 0-5.362-1.33c-.897 3.62-1.632 7.882-1.296 8.64.557 1.277 9.562 2.88 12.341 3.111a40 40 0 0 0 6.691-.298s2.405-.043 3.058-.153m-40.54 4.593s-1.854 1.387-1.57 2.304c.283.917 1.828-.96 2.212-1.512s.082-1.387-.643-.792Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M61.584 135.278s-1.848 1.388-1.57 2.304c.279.917 1.834-.96 2.213-1.512.38-.552.082-1.396-.643-.792"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M62.462 136.085s-1.848 1.387-1.565 2.304 1.83-.96 2.213-1.512c.384-.552.082-1.397-.648-.792"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.047}
        d="M63.187 136.738s-1.598 1.027-1.33 1.785c.27.759 1.59-.677 1.92-1.118.332-.442.063-1.152-.59-.667"
      />
    </svg>
  );
};
export default SvgPicErrorConnect;
