import React from 'react';
import Text from '@components/UI/Text/Text';
import picture from '@assets/img/preview/no-data.png';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

export default function LostConnection() {
  const { t } = useTranslation('layout');
  return (
    <div className="p-5 my-5 flex items-center justify-center flex-1 h-full">
      <div className="max-w-[350px] w-full flex items-start justify-start flex-col">
        <Text className="text-coolGray-9008" tag="h1">
          {t('lost_connection_preview.title')}
        </Text>
        <Text body="body-2" className="mt-3">
          {t('lost_connection_preview.description')}
        </Text>
        <img src={picture} className="mt-4" alt="pic" />
        <Button full className="mt-4" onClick={() => window.location.reload()}>
          {t('lost_connection_preview.action_btn')}
        </Button>
      </div>
    </div>
  );
}
