import { DateService } from '@modules/Format';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { HealthFacilityFilterState as subdivisionState$ } from '@modules/Filters';

class KanbanState {
  @observable public dataFilter = this._dateNow;
  @observable public hFFilter: HealthFacility | null = null;
  @observable public cabinetFilter: CabinetResponse | null = null;
  @observable public doctorFilter: UserResponse | null = null;
  @observable public specFilter: Specialization | null = null;

  constructor() {
    makeObservable(this);
    autorun(() => {
      this.setHfFilter(subdivisionState$.healthFacility);
    });
  }

  @action
  public init = () => {
    this.dataFilter = this._dateNow;
  };

  @computed
  public get query() {
    const _query: GetAppointmentsReq = {
      appointment_type: 'outpatient',
      start_time: DateService.dateToUnix(this.dataFilter.start),
      end_time: DateService.dateToUnix(this.dataFilter.end),
    };
    if (this.hFFilter) {
      _query['health_facility_ids'] = [+this.hFFilter.id];
    }
    if (this.cabinetFilter) {
      _query['cabinet_ids'] = [+this.cabinetFilter.id];
    }
    if (this.specFilter) {
      _query['specialization_ids'] = [+this.specFilter.id];
    }
    if (this.doctorFilter) {
      _query['user_ids'] = [this.doctorFilter.id];
    }
    return _query;
  }

  @action
  public resetAll = () => {
    this.setDataRange(this._dateNow.start);
    this.setHfFilter(subdivisionState$.healthFacility);
    this.setCabinetFilter(null);
    this.setDoctorFilter(null);
    this.setSpecFilter(null);
  };

  @action
  public clear = () => {
    this.dataFilter = this._dateNow;
    this.hFFilter = subdivisionState$.healthFacility;
    this.doctorFilter = null;
    this.cabinetFilter = null;
    this.specFilter = null;
  };

  @action
  public setDataRange = (date: Date): void => {
    this.dataFilter.start = date;
    this.dataFilter.end = new Date(new Date(date).setHours(23, 59, 59));
  };

  @action
  public setCabinetFilter = (selected: CabinetResponse | null) => {
    this.cabinetFilter = selected;
  };

  @action
  public setDoctorFilter = (selected: UserResponse | null) => {
    this.doctorFilter = selected;
  };

  @action
  public setSpecFilter = (selected: Specialization | null) => {
    this.specFilter = selected;
  };

  @action
  public setHfFilter = (selected: HealthFacility | null) => {
    this.hFFilter = selected;
  };

  private get _dateNow() {
    return {
      start: new Date(new Date().setHours(0, 0, 0)),
      end: new Date(new Date().setHours(23, 59, 0)),
    };
  }
}

export default new KanbanState();
