import { HealthFacilityStore } from '@modules/HealthFacility/health-facility.store';
import { HF_COLOR_CONFIG } from '@modules/HealthFacility/consts';

export class HealthFacilityService extends HealthFacilityStore {
  public colorConfig = HF_COLOR_CONFIG;
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  /*
   |----------------------------------------------------------------------------
   | HEALTH FACILITY
   |----------------------------------------------------------------------------
   */
  public getHealthFacilitiesReq = async (query?: GetHealthFacilityQuery) => {
    const response =
      await this._store.HealthFacilityApi.getHealthFacilities(query);
    if (response.success && response.data) {
      this.setHealthFacilities(response.data, response.meta);
    }
    return response;
  };

  public getHealthFacilityReq = async (id: number) => {
    const response = await this._store.HealthFacilityApi.getHealthFacility(id);
    if (response.success && response.data) {
      this.setHealthFacility(response.data);
    }
    return response;
  };

  public createHealthFacilityRequest = async (params: CreateHealthFacility) => {
    const response =
      await this._store.HealthFacilityApi.createHealthFacility(params);
    if (response.success && response.data) {
      this.addHealthFacility(response.data);
    }
    return response;
  };

  public updateHealthFacilityReq = async (
    id: number,
    params: UpdateHealthFacility,
  ) => {
    const response = await this._store.HealthFacilityApi.updateHealthFacility(
      id,
      params,
    );
    if (response.success && response.data) {
      this.updateHealthFacility(response.data);
    }
    return response;
  };

  public deactivateHealthFacilityRequest = async (id: number) => {
    const response =
      await this._store.HealthFacilityApi.deactivateHealthFacility(id);
    if (response.success) {
      this.updateHealthFacility({ active: false, id });
    }
    return response;
  };

  public activateHealthFacilityRequest = async (id: number) => {
    const response =
      await this._store.HealthFacilityApi.activateHealthFacility(id);
    if (response.success) {
      this.updateHealthFacility({ active: true, id });
    }
    return response;
  };

  /*
   |----------------------------------------------------------------------------
   | HEALTH FACILITY TYPES
   |----------------------------------------------------------------------------
   */
  public getHealthFacilityTypesRequest = async (
    query?: GetHealthFacilityTypeQuery,
  ) => {
    const response = await this._store.HealthFacilityApi.getHealthFacilityTypes(
      {
        //  'filter[delete_marked]': false,
        ...query,
      },
    );
    if (response.success && response.data) {
      this.setHealthFacilityTypes(response.data, response.meta);
    }
    return response;
  };

  public createHealthFacilityTypeRequest = async (
    params: CreateHealthFacilityType,
  ) => {
    const response =
      await this._store.HealthFacilityApi.createHealthFacilityType(params);
    if (response.success && response.data) {
      this.addHealthFacilityType(response.data);
    }
    return response;
  };

  public updateHealthFacilityTypeRequest = async (
    id: number,
    params: UpdateHealthFacilityType,
  ) => {
    const response =
      await this._store.HealthFacilityApi.updateHealthFacilityType(id, params);
    if (response.success && response.data) {
      this.updateHealthFacilityType(response.data);
    }
    return response;
  };

  public deleteHealthFacilityTypeRequest = async (id: number) => {
    const response =
      await this._store.HealthFacilityApi.deleteHealthFacilityType(id);
    if (response.success) {
      this.deleteHealthFacilityType(id);
    }
    return response;
  };
}
