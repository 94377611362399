import { action, computed, makeObservable, observable } from 'mobx';

type PackageServiceFactoryProps = {
  pkg: CalendarPackage;
  service: PatientPkgServiceService;
};

export class PackageServiceFactory {
  public id: number;

  @observable private _pkg: CalendarPackage;

  @observable public service: ServiceResponse;
  @observable public packageService: PatientPkgServiceService;
  @observable public availableCount: number;
  @observable public usedCount: number;
  @observable public totalCount: number;
  @observable public inSelection: boolean;

  constructor(data: PackageServiceFactoryProps) {
    this._pkg = data.pkg;
    this.id = data.service.id;
    this.service = data.service.service;
    this.packageService = data.service;
    this.availableCount = data.service.total_count - data.service.frizzed_count;
    this.usedCount = data.service.frizzed_count;
    this.totalCount = data.service.total_count;
    this.inSelection = data.service.participates_in_the_selection;

    makeObservable(this);
  }

  @computed
  public get limitForUse() {
    return this._pkg.serviceLimit - this._pkg.serviceLimitUsed;
  }

  @computed
  public get canAdd() {
    if (this.inSelection) {
      return this.limitForUse > 0 && this.availableCount > 0;
    }

    return this.availableCount > 0;
  }

  @action
  public increaseCount = (count = 1) => {
    this.availableCount += count;

    if (this.inSelection) {
      this._pkg.setServiceLimit('remove', count);
    }
  };

  @action
  public decreaseCount = (count = 1) => {
    this.availableCount -= count;

    if (this.inSelection) {
      this._pkg.setServiceLimit('add', count);
    }
  };
}
