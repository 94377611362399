import { action, makeObservable, observable, runInAction } from 'mobx';

export class AppealStore {
  @observable public appeals: Appeal[] = [];
  @observable public appealsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public activeAppeal: ActiveAppeal | null = null;
  @observable public foundPatients: Patient[] = [];
  private _timerId: NodeJS.Timer | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  public setAppeals = (appeals: Appeal[], meta: ResponseMeta) => {
    this.appeals = appeals;
    this.appealsMeta = meta;
  };

  @action
  public setFoundPatients = (patients: Patient[]) => {
    this.foundPatients = patients;
  };

  @action
  public setActiveAppeal = (appeal: ActiveAppeal | null) => {
    clearInterval(<NodeJS.Timeout>this._timerId);
    this.activeAppeal = appeal;

    if (appeal && this.activeAppeal) {
      this.activeAppeal.timer = 1;
      this._timerId = setInterval(() => {
        runInAction(() => {
          if (this.activeAppeal?.timer) {
            this.activeAppeal.timer++;
          }
        });
      }, 1000);
    }
  };

  @action
  public updateActiveAppeal = (
    appeal: Omit<ActiveAppeal, 'timer' | 'datetime_start'>,
  ) => {
    if (!this.activeAppeal) return;
    Object.assign(this.activeAppeal, { ...this.activeAppeal, ...appeal });
  };
}
