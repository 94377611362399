import LostConnection from '@components/UI/LostConnection';
import React, {
  ComponentType,
  createElement,
  forwardRef,
  lazy,
  useRef,
} from 'react';

export type PreloadLazyComponent<T extends ComponentType<object>> = T & {
  preload: () => Promise<T>;
};

async function preload(
  factory: () => Promise<{ default: ComponentType<object> }>,
) {
  try {
    return await factory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // Console here
    return Promise.resolve({ default: LostConnection });
  }
}

/**
 * https://www.npmjs.com/package/react-lazy-with-preload
 */
export default function PreloadLazy<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): PreloadLazyComponent<T> {
  // Load component using React.laze and custom async function for catch error
  const ReactLazyComponent: React.LazyExoticComponent<T> = lazy(async () => {
    const res = await preload(factory);
    return res as { default: T };
  });

  let PreloadedComponent: T | undefined;
  let factoryPromise: Promise<T> | undefined;

  const LazyWithPreload = forwardRef(function LazyWithPreload(props, ref) {
    // Once one of these is chosen, we must ensure that it continues to be
    // used for all subsequent renders, otherwise it can cause the
    // underlying component to be unmounted and remounted.

    const ComponentToRender = useRef(PreloadedComponent ?? ReactLazyComponent);

    return createElement(
      ComponentToRender.current,
      Object.assign(ref ? { ref } : {}, props) as any,
    );
  }) as unknown as PreloadLazyComponent<T>;

  LazyWithPreload.preload = async () => {
    if (factoryPromise) return factoryPromise;

    const module = await preload(factory);
    PreloadedComponent = module.default as T;
    return PreloadedComponent;
  };

  return LazyWithPreload;
}
