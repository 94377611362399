import { DocElementStore } from '@modules/Documents/DocElement/DocElement.store';

export class DocElementService extends DocElementStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getDocElementsReq = async (query?: GetDocElementsQuery) => {
    const res = await this._store.DocTemplateApi.getDocElements(query);
    if (res.success && res.data) {
      this.setElements(res.data, res.meta);
    }
    return res;
  };

  public getDocElementReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocElement(id);
    if (res.success && res.data) {
      this.setElement(res.data);
    }
    return res;
  };

  public getDocElementTreeReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocElementTree(id);
    if (res.success && res.data) {
      this.setElementTree(res.data);
    }
    return res;
  };

  public updateDocElementReq = async (
    id: number,
    params: UpdateDocElementReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateDocElement(id, params);
    if (res.success && res.data) {
      this.updateElement(res.data);
      if (this.docElementTree && this.docElementTree.id) {
        this.getDocElementTreeReq(this.docElementTree.id);
      }
    }
    return res;
  };

  public createDocElementReq = async (params: CreateDocElementReq) => {
    const res = await this._store.DocTemplateApi.createDocElement(params);
    if (res.success && res.data) {
      this.addElement(res.data);
    }
    return res;
  };

  public deleteDocElementReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocElement(id);
    if (res.success) {
      this.deleteElement(id);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | DOC TYPES
   |----------------------------------------------------------------------------
   */
  public getElementTypesReq = async () => {
    const res = await this._store.DocTemplateApi.getElementTypes();
    if (res.success && res.data) {
      this.setElementTypes(res.data);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | DOC VALUE
   |----------------------------------------------------------------------------
   */
  public createElementValueReq = async (params: CreateDocElementValueReq) => {
    const res = await this._store.DocTemplateApi.createElementValue(params);
    if (res.success && res.data) {
      if (this.docElementTree && this.docElementTree.id) {
        this.getDocElementTreeReq(this.docElementTree.id);
      }
    }
    return res;
  };

  public updateElementValueReq = async (
    id: number,
    params: UpdateDocElementValueReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateElementValue(id, params);
    if (res.success && res.data) {
      if (this.docElementTree && this.docElementTree.id) {
        this.getDocElementTreeReq(this.docElementTree.id);
      }
    }
    return res;
  };

  public deleteElementValueReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteElementValue(id);
    if (res.success) {
      if (this.docElementTree && this.docElementTree.id) {
        this.getDocElementTreeReq(this.docElementTree.id);
      }
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | DOC GROUPS
   |----------------------------------------------------------------------------
   */
  public getDocElementGroupsReq = async (query?: GetDocElementGroupsQuery) => {
    const res = await this._store.DocTemplateApi.getDocElementGroups(query);
    if (res.success && res.data) {
      this.setElementGroups(res.data, res.meta);
    }
    return res;
  };

  public createElementGroupsReq = async (params: CreateElementGroupReq) => {
    const res = await this._store.DocTemplateApi.createElementGroups(params);
    if (res.success && res.data) {
      this.setElementGroup(res.data);
    }
    return res;
  };

  public updateElementGroupReq = async (
    id: number,
    params: UpdateElementGroupReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateElementGroup(id, params);
    if (res.success && res.data) {
      this.updateElementGroup(res.data);
    }
    return res;
  };

  public deleteElementGroupReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteElementGroup(id);
    if (res.success) {
      this.deleteElementGroup(id);
    }
    return res;
  };
}
