import WebSocketStore from '@modules/WebSocket/ws.store';
import {
  WS_CONNECTION_CONFIG,
  WS_RESPONSE_KEY_MAPPER,
} from '@modules/WebSocket/consts';
import uniqueId from 'lodash/uniqueId';
import * as Events from 'reconnecting-websocket/events';

export class WsService extends WebSocketStore {
  public connection: WebSocket | null = null;
  protected _config: typeof WS_CONNECTION_CONFIG;
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._config = WS_CONNECTION_CONFIG;
    this._store = store;
  }

  public connect = ({ callback }: WsConnectProps): void => {
    this._store.LoadingService.setLoading('WS_CONNECTION', true);
    this.connection = new WebSocket(this._config.url);
    if (this.connection) {
      this.connection.onopen = () => this.handleOpen(callback);
      this.connection.onmessage = (e) => this._notify(e);
      this.connection.onerror = (e) => this._handleError(e as any);
      this.connection.onclose = () => this._handleClose();
    }
  };

  public handleOpen = (callback?: WsConnectProps['callback']): void => {
    if (callback && this.connection) {
      this.setConnectionState(true);
      this._store.LoadingService.setLoading('WS_CONNECTION', false);
      this._store.LoggerService.send.wsCrumb(`✅ OPEN ${this._config.url}`);
      callback(this.connection);
    }
  };

  public disconnect = (): void => {
    if (this.connection && this.isConnected) {
      this.connection.close();
      this.connection = null;
      this.setConnectionState(false);
    }
  };

  public send<T>(data: WsRequest<T>, loadingKey: LoadingKey): void {
    this._store.LoadingService.setLoading(loadingKey, true);
    const requestData = JSON.stringify({ id: +uniqueId(), ...data });

    const { setLoading } = this._store.LoadingService;
    const { wsError } = this._store.ErrorService;
    const { wsCrumb } = this._store.LoggerService.send;

    const request = () => {
      if (this.connection?.readyState === WebSocket.OPEN) {
        try {
          this.connection.send(requestData);
          wsCrumb(`🟧 REQUEST: [${loadingKey}]: ${data.method}`);
        } catch (error: any) {
          console.log(error);
          setLoading(loadingKey, false);
          wsError('WS_CONNECTION', [
            { message: `❌ WS ERROR: ${error?.message || ''}` },
          ]);
        }
      } else {
        console.log('❌ Socket is not open', this.connection);
        this._store.ErrorService.wsError(loadingKey, [
          { message: 'Socket is not open' },
        ]);
      }
    };
    request();
  }

  private _notify = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as WsResponse<never>;
      const { method, result, error: errors } = message;
      const loadingKey = WS_RESPONSE_KEY_MAPPER[method];

      this._store.LoadingService.setLoading(loadingKey, false);
      this.emit(method, message);

      this._store.LoggerService.send.wsCrumb(
        `🟩 RESPONSE: [${loadingKey}]: ${method}`,
      );

      if (!result.success) {
        this._store.ModalService.modal.wsError(errors);
        this._store.ErrorService.wsError(loadingKey, errors, message);
      }
    } catch (err) {
      console.error(err);
      this._store.ErrorService.wsException('UNKNOWN_KEY', err, event);
    }
  };

  private _handleClose = () => {
    this.emit('ws_close' as any);
    this.setConnectionState(false);
    this._store.LoadingService.setLoading('WS_CONNECTION', false);
    if (this.connection && this.isConnected) {
      this._store.LoggerService.send.wsCrumb(
        `🔒 WS_CONNECTION_CLOSED ${this._config.url}`,
      );
    }
  };

  private _handleError = (event: Events.ErrorEvent) => {
    this.emit('ws_close' as any);
    this.setConnectionState(false);
    this._store.ErrorService.wsError(
      'WS_CONNECTION',
      [{ message: `${event?.message || 'Error'}` }],
      { event },
    );
  };

  public sendAuthentication() {
    const token = this._store.AuthService.accessToken;
    if (!token) {
      this._store.ErrorService.wsError('AUTH_WS_CONNECTION', [
        { message: 'Token not found!' },
      ]);
      return;
    }
    this.send(
      { method: 'authentication', params: { token } },
      'AUTH_WS_CONNECTION',
    );
  }
}
