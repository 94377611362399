import React from 'react';
import { MobXProviderContext } from 'mobx-react';

export const useService = <T extends keyof Store>(key: T): Store[T] => {
  const store = React.useContext(MobXProviderContext) as Store;

  if (!store) {
    throw new Error('Store not found');
  }

  return store[key];
};
