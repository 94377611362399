export type LangOption = {
  label: string;
  labelShot: string;
  code: string;
};

export const langOptions: LangOption[] = [
  {
    label: 'Українська',
    labelShot: 'Укр',
    code: 'uk',
  },
  {
    label: 'Русский',
    labelShot: 'Рус',
    code: 'ru',
  },
  {
    label: 'English',
    labelShot: 'Eng',
    code: 'en',
  },
];
