import { InsuranceCompanyMapper } from './mapper/InsuranceCompany';
import { action, makeObservable, observable } from 'mobx';

export default class InsuranceCompanyStore {
  protected _store: Store;
  @observable public company: InsuranceCompanyMapper | null = null;
  @observable public companies: InsuranceCompanyMapper[] = [];
  @observable public companiesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor(store: Store) {
    this._store = store;
    makeObservable(this);
  }

  // COMPANIES
  @action
  public setCompanies = (companies: InsuranceCompany[], meta: ResponseMeta) => {
    this.companies = companies.map(
      (company) => new InsuranceCompanyMapper(company),
    );

    this.companiesMeta = meta;
  };

  @action
  public setCompany = (company: InsuranceCompany) => {
    this.company = new InsuranceCompanyMapper(company);
  };

  @action
  public addCompany = (company: InsuranceCompany) => {
    this.companies = [new InsuranceCompanyMapper(company), ...this.companies];
  };

  @action
  public updateCompany = (company: InsuranceCompany) => {
    const foundedCompany = this.companies.find((c) => c.id === company.id);

    if (foundedCompany) {
      foundedCompany.setCompany(company);
    }
  };

  // GUARANTORS
  @action
  public setCompanyGuarantors = (id: number, guarantors: Guarantor[]) => {
    const company = this.companies.find((c) => c.id === id);

    if (company) {
      company.setGuarantors(guarantors);
    }
  };

  @action
  public addGuarantor = (guarantor: Guarantor) => {
    const company = this.companies.find(
      (c) => c.id === guarantor.insurance_company.id,
    );

    if (company) {
      company.addGuarantors(guarantor);
    }
  };

  @action
  public updateGuarantor = (guarantor: Guarantor) => {
    const company = this.companies.find(
      (company) => company.id === guarantor.insurance_company.id,
    );

    if (company) {
      company.updateGuarantor(guarantor);
    }
  };
}
