import { action, makeObservable, observable } from 'mobx';

export class Directions {
  @observable public list: DocDirection[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (data: DocDirection[]) => {
    this.list = data;
  };

  @action
  public delete = (id: number) => {
    this.list = this.list.filter((direction) => {
      return direction.id !== id;
    });
  };

  @action
  public add = (data: DocDirection) => {
    this.list = this.list.concat(data);
  };

  @action
  public update = (data: DocDirection) => {
    this.list = this.list.map((direction) => {
      if (direction.id === data.id) return data;
      return direction;
    });
  };
}
