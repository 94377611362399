import { FetchService } from '@modules/Api';

export class CounterpartyReportApi extends FetchService {
  public getCounterpartyReport = (query: GetCounterpartyReportQuery) => {
    return this.request<
      'COUNTERPARTY_REPORT',
      {
        rows: CounterpartyReportRow[];
        total: CounterpartyReportTotal;
      },
      null,
      GetCounterpartyReportQuery
    >('/counterparty/detailed_general/data', 'GET_COUNTERPARTY_REPORT', {
      query,
    });
  };

  public getCounterpartyByPatientsReport = (
    query: GetCounterpartyByPatientsReportQuery,
  ) => {
    return this.request<
      'COUNTERPARTY_REPORT',
      CounterpartyByPatientsReport,
      null,
      GetCounterpartyByPatientsReportQuery
    >(
      '/counterparty/detailed_by_patients/data',
      'GET_COUNTERPARTY_BY_PATIENTS_REPORT',
      {
        query,
      },
    );
  };

  public downloadCounterpartyReport = (
    query: DownloadCounterpartyReportQuery,
  ) => {
    return this.request<'COUNTERPARTY_REPORT', BlobResponse>(
      '/counterparty/detailed_general/download',
      'DOWNLOAD_COUNTERPARTY_REPORT',
      {
        query,
        blob: true,
      },
    );
  };

  public downloadCounterpartyByPatientsReport = (
    query: DownloadCounterpartyByPatientsReportQuery,
  ) => {
    return this.request<'COUNTERPARTY_REPORT', BlobResponse>(
      '/counterparty/detailed_by_patients/download',
      'DOWNLOAD_COUNTERPARTY_REPORT_BY_PATIENTS',
      {
        query,
        blob: true,
      },
    );
  };
}
