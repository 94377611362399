import { action, computed, makeObservable, observable } from 'mobx';

type VisitDocument = {
  episode: MedEpisode;
  documents: MedDocument[];
  patient: Patient;
};

export class Documents {
  @observable private _list: MedDocument[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (docs: MedDocument[]) => {
    this._list = docs;
  };

  @computed
  public get list() {
    if (!this._list.length) return [];
    const result = this._list.reduce(
      (acc, doc) => {
        acc[doc.episode.id]?.documents.push(doc);
        if (!acc[doc.episode.id]) {
          acc[doc.episode.id] = {
            episode: doc.episode,
            documents: [doc],
            patient: doc.patient,
          };
          return acc;
        }
        return acc;
      },
      {} as Record<string, VisitDocument>,
    );
    return Object.values(result);
  }
}
