import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path
        fill="#000"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"
        opacity={0.3}
      />
      <path fill="#000" d="M2 12A10 10 0 0 1 12 2v2a8 8 0 0 0-8 8z" />
    </svg>
  );
};
export default SvgSpinner;
