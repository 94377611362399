import React, { memo, NamedExoticComponent, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import ErrorBoundary from '@components/UI/ErrorBoundary';
import { ContentLoader } from '@components/UI/ContentLoader';

type Props = {
  routes: AppRouter[];
  layout?: NamedExoticComponent<any>;
};

function Routes({ routes }: { routes: AppRouter[] }) {
  return (
    <ErrorBoundary width={500}>
      <Switch>
        {routes?.map((route) => (
          <RouteWithSubRoutes key={`${route.path}-${route.name}`} {...route} />
        ))}
      </Switch>
    </ErrorBoundary>
  );
}

function LayoutRoutes({ routes, layout: Layout }: Props) {
  return Layout ? (
    <Layout>
      <Suspense fallback={<ContentLoader />}>
        <Routes routes={routes} />
      </Suspense>
    </Layout>
  ) : (
    <Suspense fallback={<ContentLoader />}>
      <Routes routes={routes} />
    </Suspense>
  );
}

export default memo(LayoutRoutes);
