import * as React from 'react';
import type { SVGProps } from 'react';
const SvgError = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 0 24 24"
      fill="none"
      role="img"
      {...props}
    >
      <path
        d="M14.25 0H2.75C1.23 0 0 1.23 0 2.75v15.5C0 19.77 1.23 21 2.75 21h4.9c.1-.4.28-.78.51-1.13l5.22-8.48c.67-1.15 1.97-1.89 3.37-1.89.08 0 .17 0 .25.01V2.75C17 1.23 15.77 0 14.25 0M4 4h4c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1m5 10H4c-.55 0-1-.45-1-1s.45-1 1-1h5c.55 0 1 .45 1 1s-.45 1-1 1m4-4H4c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1s-.45 1-1 1M23.707 21.024l-5.278-8.574c-.345-.586-.989-.95-1.679-.95s-1.334.364-1.669.934L9.815 20.99a1.9 1.9 0 0 0-.315 1.055A1.96 1.96 0 0 0 11.462 24h10.576A1.96 1.96 0 0 0 24 22.045c0-.384-.109-.749-.293-1.021M16.75 22a1 1 0 1 1 0-2 1 1 0 0 1 0 2m1-3.5a1 1 0 1 1-2 0v-2a1 1 0 1 1 2 0z"
        fill="currentColor"
      />
    </svg>
  );
};
export default SvgError;
