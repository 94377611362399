import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHomeWork = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <g fill="#9CA3AF" clipPath="url(#a)">
        <path d="M1.167 12.833V24.5H7v-7h4.667v7H17.5V12.833L9.333 7z" />
        <path d="M11.667 3.5v2.298l8.166 5.834v1.201h2.334v2.334h-2.334V17.5h2.334v2.333h-2.334V24.5h7v-21zm10.5 7h-2.334V8.167h2.334z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgHomeWork;
