import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastifyContainer() {
  return (
    <ToastContainer
      rtl={false}
      limit={1}
      autoClose={4000}
      transition={Slide}
      position="top-right"
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
      icon={false}
    />
  );
}
