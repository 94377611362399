import { action, makeObservable, observable } from 'mobx';

export class InsuranceCompanyMapper implements InsuranceCompany {
  public readonly id: number;
  @observable public active = false;
  @observable public agreement = '';
  @observable public created = '';
  @observable public name = '';
  @observable public phone = '';
  @observable public code = 0;
  @observable public discount = 0;
  @observable public address: string | null = null;
  @observable public payment_details: string | null = null;
  @observable public guarantors: Guarantor[] = [];
  @observable public price: PriceResponse | null = null;

  constructor(company: InsuranceCompany) {
    makeObservable(this);
    this.id = company.id;
    this.setCompany(company);
  }

  @action
  public setCompany = (company: InsuranceCompany) => {
    this.active = company.active;
    this.address = company.address;
    this.agreement = company.agreement;
    this.code = company.code;
    this.created = company.created;
    this.discount = company.discount;
    this.name = company.name;
    this.payment_details = company.payment_details;
    this.phone = company.phone;
    this.price = company.price;
  };

  @action
  public setGuarantors = (guarantors: Guarantor[]) => {
    this.guarantors = guarantors;
  };

  @action
  public addGuarantors = (guarantor: Guarantor) => {
    this.guarantors = [guarantor, ...this.guarantors];
  };

  @action
  public updateGuarantor = (guarantor: Guarantor) => {
    const fondedGuarantor = this.guarantors.find((g) => g.id === guarantor.id);

    if (fondedGuarantor) {
      Object.assign(fondedGuarantor, guarantor);
    }
  };
}
