import { KanbanService } from './Kanban';
import { AppointmentService } from './Appointment';
import { CompletedAppointmentService } from './CompletedAppointment';

export class AdministratorService {
  protected _store: Store;
  public readonly kanban: KanbanService;
  public readonly appointment: AppointmentService;
  public readonly completedAppointments: CompletedAppointmentService;

  constructor(store: Store) {
    this._store = store;
    this.kanban = new KanbanService(store);
    this.appointment = new AppointmentService(store);
    this.completedAppointments = new CompletedAppointmentService(store);
  }
}
