import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronPrevious = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={12}
      fill="none"
      viewBox="0 0 8 12"
      role="img"
      {...props}
    >
      <path fill="currentColor" d="M7.41 1.41 6 0 0 6l6 6 1.41-1.41L2.83 6z" />
    </svg>
  );
};
export default SvgChevronPrevious;
