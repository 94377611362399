import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocument4 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={20}
      fill="none"
      viewBox="0 0 18 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.5 1.5 15 0l-1.5 1.5L12 0l-1.5 1.5L9 0 7.5 1.5 6 0 4.5 1.5 3 0v14H0v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V0zM16 17c0 .55-.45 1-1 1s-1-.45-1-1v-3H5V3h11z"
      />
    </svg>
  );
};
export default SvgDocument4;
