import { action } from 'mobx';
import InsuranceCompanyStore from './insurance-company.store';

export default class InsuranceCompanyService extends InsuranceCompanyStore {
  protected _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  @action
  public getCompaniesRequest = async (query?: GetInsuranceCompaniesParams) => {
    const response =
      await this._store.InsuranceApi.getInsuranceCompanies(query);

    if (response.success && response.data) {
      this.setCompanies(response.data, response.meta);
    }

    return response;
  };

  @action
  public getCompanyRequest = async (id: number) => {
    const response = await this._store.InsuranceApi.getInsuranceCompany(id);

    if (response.success && response.data) {
      this.setCompany(response.data);
    }

    return response;
  };

  @action
  public updateCompanyRequest = async (
    id: number,
    params: UpdateInsuranceCompanyParams,
  ) => {
    const response = await this._store.InsuranceApi.updateInsuranceCompany(
      id,
      params,
    );

    if (response.success && response.data) {
      this.updateCompany(response.data);
    }

    return response;
  };

  @action
  public createCompanyRequest = async (
    params: CreateInsuranceCompanyParams,
  ) => {
    const response =
      await this._store.InsuranceApi.createInsuranceCompany(params);

    if (response.success && response.data) {
      this.addCompany(response.data);
    }

    return response;
  };

  @action
  public activateCompanyRequest = async (id: number) => {
    const response =
      await this._store.InsuranceApi.activateInsuranceCompany(id);

    if (response.success && response.data) {
      this.updateCompany(response.data);
    }

    return response;
  };

  @action
  public deactivateCompanyRequest = async (id: number) => {
    const response =
      await this._store.InsuranceApi.deactivateInsuranceCompany(id);
    if (response.success && response.data) {
      this.updateCompany(response.data);
    }

    return response;
  };
}
