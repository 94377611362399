import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import PopoverTabs from './PopoverTabs/PopoverTabs';
import { ContentLoader } from '../ContentLoader';
import { useHistory } from 'react-router';
import { Tab } from './Tab';

export type TabsProps = {
  className?: string;
  tabs: UiTab[];
  contentSeparator?: boolean;
  onSelect?: (tab?: UiTab) => void;
};

export default function Tabs({
  tabs: defaultTabs,
  onSelect,
  className,
  contentSeparator = true,
}: TabsProps) {
  const [activeTab, setActiveTab] = useState<UiTab | undefined>(undefined);
  const { push, replace, listen, location } = useHistory();

  const tabs = useMemo(() => {
    return defaultTabs.filter((tab) => {
      if (typeof tab.permit === 'boolean') return tab.permit;
      return true;
    });
  }, [defaultTabs]);

  const getActiveTab = useCallback(
    (hash: string) => {
      const id = hash.replace('#', '');
      return tabs.find((e) => e.id === id);
    },
    [tabs],
  );

  const setActive = (tab: UiTab | undefined) => {
    setActiveTab(tab);
    onSelect?.(tab);
  };

  const selectTab = (tab: UiTab) => {
    push({ ...location, hash: tab.id });
  };

  const init = () => {
    const active = getActiveTab(location.hash);
    replace({
      ...location,
      hash: active ? active.id : tabs[0].id,
    });
  };

  const subscribe = () => {
    return listen((location) => {
      const active = getActiveTab(location.hash);
      setActive(active);
    });
  };

  const isActive = (tab: UiTab) => {
    if (!activeTab) return false;
    return activeTab.id === tab.id;
  };

  useEffect(() => {
    const unsubscribe = subscribe();
    init();
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui-tabs" data-testid="ui-tabs">
      <ul className={cn('flex relative', className)} data-testid="ui-tabs-nav">
        <PopoverTabs
          tabs={tabs}
          className="flex items-center"
          accessor={(tab) => tab.id}
        >
          {({ tab, actions }) => (
            <Tab
              tab={tab}
              isActive={isActive(tab)}
              onMouseOver={tab.component.preload}
              onClick={() => {
                if (actions.isOpen) actions.close();
                selectTab(tab);
              }}
            />
          )}
        </PopoverTabs>
      </ul>
      {contentSeparator && <hr className="mb-4 mt-5 border-coolGray-300" />}
      <div className="tabs-content" data-testid="ui-tabs-content">
        <Suspense fallback={<ContentLoader />}>
          {activeTab && (
            <div data-testid={activeTab.id} className={`tab-${activeTab.id}`}>
              <activeTab.component {...activeTab.props} />
            </div>
          )}
        </Suspense>
      </div>
    </div>
  );
}
