import React, { useEffect, useState } from 'react';
import * as serviceWorker from '@modules/Pwa';
import { Button } from '@components/UI/Button';
import { Text } from '@components/UI/Text';
import './SWMessage.scss';
import { Icon } from '@components/UI/Icon';
import { useTranslation } from 'react-i18next';

export default function ServiceWorker(): React.JSX.Element | null {
  const { t } = useTranslation('layout');
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null,
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  if (!showReload) return null;

  return (
    <div className="sw-message">
      <div className="outer">
        <div className="info">
          <Icon name="Info" color="#fff" className="mr-4" />
          <Text tag="h6">{t('pwa.new_version_available')}</Text>
        </div>
        <div className="action">
          <Button
            className="ml-5"
            onClick={reloadPage}
            type="success"
            style={{ backgroundColor: '#0f766e' }}
          >
            {t('pwa.install_update')}
          </Button>
        </div>
      </div>
    </div>
  );
}
