import Sentry from '@lib/sentry';
import { crumbs, details } from './registration';

class LoggerService {
  private _logger: typeof Sentry;
  private _crumbs = crumbs;
  private _details = details;
  // protected _isAuth = LStorage.getItem('REFRESH_TOKEN');

  constructor() {
    this._logger = Sentry;
  }

  public get send() {
    return {
      // FETCH
      fetchError: (title: string, details: Partial<LoggerErrorDetails>) => {
        this._sendMessage(title, this._details.fetchDetails(details));
      },
      fetchException: (error: any, details: Partial<LoggerErrorDetails>) => {
        this._sendException(error, this._details.fetchDetails(details));
      },
      // WS
      wsError: (title: string, details: Partial<LoggerErrorDetails>) => {
        this._sendMessage(title, this._details.wsDetails(details));
      },
      wsException: (error: any, details: Partial<LoggerErrorDetails>) => {
        this._sendException(error, this._details.wsDetails(details));
      },
      wsCrumb: (message?: string, data?: any) => {
        const params = this._crumbs.wsLog(message, data);
        this._sendCrumb(params);
      },
      // SYNTAX ERROR
      syntaxError: (error: any, details: Partial<LoggerErrorDetails>) => {
        this._sendException(error, this._details.syntaxError(details));
      },
      // PERMISSION
      permissionCrumb: (message?: string, data?: any) => {
        const params = this._crumbs.permission(message, data);
        this._sendCrumb(params);
      },
      // BOUNDARY
      boundaryException: (error: any, details: Partial<LoggerErrorDetails>) => {
        this._sendException(error, this._details.errorBoundaryDetails(details));
      },
      // i18n
      i18Error: (title: string, details: Partial<LoggerErrorDetails>) => {
        this._sendMessage(title, this._details.i18Details(details));
      },
      i18Crumb: (message?: string, data?: any) => {
        const params = this._crumbs.i18Info(message, data);
        this._sendCrumb(params);
      },
    };
  }

  private _sendCrumb = ({ level, category, data, message }: LoggerCrumb) => {
    this._logger.sendBreadcrumb({
      level,
      category,
      data,
      message,
    });
  };

  private _sendMessage = (title: string, details: LoggerErrorDetails) => {
    this._logger.sendMessage(title, details);
  };

  private _sendException = (error: Error, details: LoggerErrorDetails) => {
    this._logger.sendException(error, details);
  };
}

export default new LoggerService();
