export const PAYMENT_TYPE = <const>{
  cash: 'CASH',
  creditCard: 'CARD',
  balance: 'BALANCE',
  insurance: 'INSURANCE',
  refund_cash: 'REFUND_CASH',
  refund_card: 'REFUND_CARD',
  refund_balance: 'REFUND_BALANCE',
  cashless: 'CASHLESS',
  other_payer: 'OTHER_PAYER',
};

export const PAYMENT_OPERATION: PaymentType[] = [
  PAYMENT_TYPE.balance,
  PAYMENT_TYPE.cash,
  PAYMENT_TYPE.creditCard,
  PAYMENT_TYPE.insurance,
  PAYMENT_TYPE.cashless,
  PAYMENT_TYPE.other_payer,
];

export const PAYMENT_INSURANCE_OPERATION: PaymentType[] = [
  PAYMENT_TYPE.insurance,
];

export const PAYMENT_PAYER: PaymentType[] = [PAYMENT_TYPE.other_payer];

export const REFUND_OPERATION: PaymentType[] = [
  PAYMENT_TYPE.refund_balance,
  PAYMENT_TYPE.refund_card,
  PAYMENT_TYPE.refund_cash,
];

export const PAYMENT_STATUS = <const>{
  CREATED: 'created',
  PROCESSED: 'processed',
  CANCELLED: 'cancelled',
  SUSPENDED: 'suspended',
};
