import { FetchService } from '@modules/Api';

export class UsersApi extends FetchService {
  public getUsers = (query?: GetUsersQuery) => {
    return this.request<'USERS', UserResponse[], GetUsersQuery>(
      '/users',
      'GET_USERS',
      { query, delay: 12, permit: 'user' },
    );
  };

  public getUserServices = (id: number, query?: GetUserServicesQuery) => {
    return this.request<'USERS', UserServiceResponse[], GetUserServicesQuery>(
      '/users',
      'GET_USER_SERVICES',
      {
        query,
        pathParam: `${id}/services`,
      },
    );
  };

  public getUser = (id: number) => {
    return this.request<'USERS', UserResponse>('/users', 'GET_USER', {
      pathParam: id,
      permit: 'user',
    });
  };

  public updateUser = (id: number, data: UpdateUserReq) => {
    return this.request<'USERS', UserResponse, UpdateUserReq>(
      '/users',
      'UPDATE_USER',
      {
        method: 'PUT',
        params: data,
        pathParam: id,
        permit: 'user',
      },
    );
  };

  public updateUserOutsideRequest = (
    id: number,
    params: UpdateOutsideRequestParams,
  ) => {
    return this.request<'USERS', UserResponse>(
      '/users',
      'UPDATE_USER_OUTSIDE_REQUEST',
      {
        params,
        pathParam: `${id}/outsideRequests`,
        method: 'PUT',
      },
    );
  };

  public changeUserLogin = (id: number, data: ChangeUserLoginReq) => {
    return this.request<'USERS', UserResponse, ChangeUserLoginReq>(
      '/users',
      'CHANGE_USER_LOGIN',
      {
        method: 'PUT',
        params: data,
        pathParam: `${id}/loginChange`,
        permit: 'user',
      },
    );
  };

  public createUser = (data: CreateUserReq) => {
    return this.request<'USERS', UserResponse, CreateUserReq>(
      '/users',
      'CREATE_USER',
      {
        method: 'POST',
        params: data,
        permit: 'user',
      },
    );
  };

  public fireUser = (id: number) => {
    return this.request<'USERS', UserResponse>('/users', 'FIRE_USER', {
      method: 'PUT',
      pathParam: `${id}/fire`,
    });
  };

  public renewalUser = (id: number) => {
    return this.request<'USERS', UserResponse>('/users', 'RENEWAL_USER', {
      method: 'PUT',
      pathParam: `${id}/renewal`,
    });
  };

  public removeUser = (userId: number) => {
    return this.request<'USERS'>('/users', 'REMOVE_USERS', {
      method: 'DELETE',
      pathParam: userId,
      permit: 'user',
    });
  };

  public forceLogoutUsers = (params: ForceLogoutUsers) => {
    return this.request<'USERS', null, ForceLogoutUsers>(
      '/users/logout_for_roles',
      'FORCE_LOGOUT_USERS',
      {
        method: 'PUT',
        params,
        permit: 'user',
      },
    );
  };

  public setUserSpecializations = (params: SetUserSpecializationReq) => {
    return this.request<'USERS', Specialization, SetUserSpecializationReq>(
      '/users/specialization',
      'SET_USER_SPECIALIZATION',
      {
        method: 'POST',
        params,
        // PERMIT ??
      },
    );
  };

  public removeUserSpecializations = (params: RemoveUserSpecializationReq) => {
    return this.request<'USERS', null, RemoveUserSpecializationReq>(
      `/users/specialization`,
      'REMOVE_USER_SPECIALIZATION',
      {
        method: 'DELETE',
        pathParam: `${params.user_id}/${params.specialization_id}`,
        // PERMIT ??
      },
    );
  };

  public setUserPosition = ({ userId, positionId }: SetUserPositionReq) => {
    return this.request<'USERS', UserResponse>('/users', 'SET_USER_POSITION', {
      method: 'PUT',
      pathParam: `${userId}/position/${positionId}`,
      // PERMIT ??
    });
  };

  public setUserRole = (params: RoleToUserReq) => {
    return this.request<'USERS', UserResponse, RoleToUserReq>(
      '/users/roles',
      'SET_ROLE_TO_USER',
      {
        method: 'POST',
        params,
        // PERMIT ??
      },
    );
  };

  public removeUserRole = ({ user_id, role_id }: RoleToUserReq) => {
    return this.request<'USERS'>('/users/roles', 'SET_ROLE_TO_USER', {
      method: 'DELETE',
      pathParam: `${user_id}/${role_id}`,
      // PERMIT ??
    });
  };

  public removeAllUserRoles = (userId: number) => {
    return this.request<'USERS', UserResponse>(
      '/users/roles',
      'REMOVE_ALL_USER_ROLES',
      {
        method: 'DELETE',
        pathParam: userId,
        // PERMIT ??
      },
    );
  };

  public setUserLock = (userId: number) => {
    return this.request<'USERS', UserResponse>('/users', 'SET_USER_LOCK', {
      method: 'PUT',
      pathParam: `${userId}/lock`,
      permit: 'user',
    });
  };

  public setUserUnLock = (userId: number) => {
    return this.request<'USERS', UserResponse>('/users', 'SET_USER_UNLOCK', {
      method: 'PUT',
      pathParam: `${userId}/unlock`,
      permit: 'user',
    });
  };

  public grantingUserAccess = (
    userId: number,
    params: GrantingUserAccessReq,
  ) => {
    return this.request<'USERS', UserResponse, GrantingUserAccessReq>(
      '/users',
      'GRANTING_USER_ACCESS',
      {
        method: 'PUT',
        params,
        pathParam: `${userId}/grantingAccess`,
        permit: 'user',
      },
    );
  };

  public importUsers = (params: FormData) => {
    return this.request<'USERS', ImportUsersResponse, FormData>(
      '/users/import',
      'IMPORT_USERS',
      {
        method: 'POST',
        params,
      },
    );
  };

  public saveImportUsers = (params: SaveImportUsers) => {
    return this.request<'USERS', UserResponse[]>(
      '/users/import/save',
      'IMPORT_USERS_SAVE',
      {
        method: 'POST',
        params,
      },
    );
  };

  public getUserSalary = (userId: number) => {
    return this.request<'USERS', UserSalary>(
      '/user_salary',
      'GET_USER_SALARY',
      {
        pathParam: userId,
      },
    );
  };

  public updateUserSalary = (params: UpdateUserSalary) => {
    return this.request<'USERS', UserSalary, UpdateUserSalary>(
      '/user_salary',
      'UPDATE_USER_SALARY',
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateUserSalaryCommissions = (
    params: UpdateUserSalaryCommissions,
  ) => {
    return this.request<
      'USERS',
      UserSalaryCommission[],
      UpdateUserSalaryCommissions
    >('/user_salary/commissions', 'UPDATE_USER_SALARY_COMMISSIONS', {
      method: 'POST',
      params,
    });
  };

  public getSalaryReport = (query: GetMonthlySalaryReport) => {
    return this.request<'USERS', GetMonthlySalaryReportRes>(
      '/user_salary',
      'GET_SALARY_REPORT',
      {
        query,
        pathParam: 'get_report/monthly',
      },
    );
  };

  public getUserSalaryReport = (id: number, query?: GetUserSalaryReport) => {
    return this.request<'USERS', GetUserSalaryReportRes>(
      '/user_salary',
      'GET_USER_SALARY_REPORT',
      {
        query,
        pathParam: `${id}/get_report/detailed`,
      },
    );
  };

  public getPersonalSalaryReport = (query: GetMonthlySalaryReport) => {
    return this.request<'USERS', MonthlyReport>(
      '/user_salary_personal',
      'GET_PERSONAL_SALARY_REPORT',
      {
        query,
        pathParam: 'get_report/monthly',
      },
    );
  };

  public getPersonalSalaryDetailedReport = (query?: GetUserSalaryReport) => {
    return this.request<'USERS', GetUserSalaryReportRes>(
      '/user_salary_personal',
      'GET_PERSONAL_SALARY_DETAILED_REPORT',
      {
        query,
        pathParam: 'get_report/detailed',
      },
    );
  };

  public downloadSalaryReport = (query: GetMonthlySalaryReport) => {
    return this.request<'USERS', BlobResponse>(
      '/user_salary',
      'DOWNLOAD_SALARY_REPORT',
      {
        query,
        pathParam: 'download_report/monthly',
        blob: true,
      },
    );
  };

  public downloadUserSalaryReport = (
    id: number,
    query?: GetUserSalaryReport,
  ) => {
    return this.request<'USERS', BlobResponse>(
      '/user_salary',
      'DOWNLOAD_USER_SALARY_REPORT',
      {
        query,
        pathParam: `${id}/download_report/detailed`,
        blob: true,
      },
    );
  };
}
