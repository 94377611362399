import { FetchService } from '@modules/Api';

export class ServicesApi extends FetchService {
  /*
  |----------------------------------------------------------------------------
  | SERVICE
  |----------------------------------------------------------------------------
  */
  public getServices = (query?: GetServicesQuery) => {
    return this.request<'SERVICE', ServiceResponse[], GetServicesQuery>(
      '/service',
      'GET_SERVICES',
      {
        query,
        permit: 'services',
      },
    );
  };

  public getService = (id: number, query?: GetServiceQuery) => {
    return this.request<'SERVICE', ServiceResponse>('/service', 'GET_SERVICE', {
      pathParam: id,
      permit: 'services',
      query,
    });
  };

  public getServiceBlank = () => {
    return this.request<'SERVICE', BlobResponse>(
      '/service',
      'GET_SERVICE_BLANK',
      {
        blob: true,
        pathParam: 'blank',
        permit: 'services',
      },
    );
  };

  public getServiceUsers = (id: number, query?: GetServiceUsersQuery) => {
    return this.request<
      'SERVICE',
      ServiceUserResponse[],
      null,
      GetServiceUsersQuery
    >('/service', 'GET_SERVICE_USERS', {
      query,
      pathParam: `${id}/users`,
      // PERMIT ???
    });
  };

  public getServiceCabinets = (id: number, query?: GetServiceCabinetsQuery) => {
    return this.request<
      'SERVICE',
      CabinetResponse[],
      null,
      GetServiceCabinetsQuery
    >('/service', 'GET_SERVICE_CABINETS', {
      query,
      pathParam: `${id}/cabinets`,
      // PERMIT ???
    });
  };

  public addServiceCabinet = (params: AddServiceCabinetReq) => {
    return this.request<
      'SERVICE',
      ServiceCabinetResponse,
      AddServiceCabinetReq
    >('/service/cabinet', 'ADD_SERVICE_CABINET', {
      method: 'POST',
      params,
      // PERMIT ???
    });
  };

  public removeServiceCabinet = (params: RemoveServiceCabinetReq) => {
    return this.request<'SERVICE'>(
      '/service/cabinet',
      'REMOVE_SERVICE_CABINET',
      {
        method: 'DELETE',
        pathParam: `${params.service_id}/${params.cabinet_id}`,
        // PERMIT ???
      },
    );
  };

  // Related Service
  public getServiceRelatedService = (
    id: number,
    query?: GetServiceRelatedServicesQuery,
  ) => {
    return this.request<
      'SERVICE',
      ServiceResponse[],
      null,
      GetServiceRelatedServicesQuery
    >('/service', 'GET_RELATED_SERVICES', {
      query,
      pathParam: `${id}/related_service`,
      // PERMIT ???
    });
  };

  public addServiceRelatedService = (params: AddServiceRelatedServiceReq) => {
    return this.request<'SERVICE', ServiceResponse>(
      '/service',
      'ADD_SERVICE_RELATED_SERVICE',
      {
        method: 'POST',
        pathParam: `${params.serviceId}/${params.relatedServiceId}`,
        // PERMIT ???
      },
    );
  };

  public removeServiceRelatedService = (
    params: RemoveServiceRelatedServiceReq,
  ) => {
    return this.request<'SERVICE'>(
      '/service',
      'REMOVE_SERVICE_RELATED_SERVICE',
      {
        method: 'DELETE',
        pathParam: `${params.serviceId}/${params.relatedServiceId}`,
        // PERMIT ???
      },
    );
  };

  public createService = (params: CreateServiceReq) => {
    return this.request<'SERVICE', ServiceResponse, CreateServiceReq>(
      '/service',
      'CREATE_SERVICE',
      {
        method: 'POST',
        params,
        permit: 'services',
      },
    );
  };

  public createCertificate = (params: CreateCertificate) => {
    return this.request<'SERVICE', ServiceResponse, CreateCertificate>(
      '/service/product',
      'CREATE_SERVICE',
      {
        method: 'POST',
        params,
        permit: 'services',
      },
    );
  };

  public updateService = (id: number, params: UpdateServiceReq) => {
    return this.request<'SERVICE', ServiceResponse, UpdateServiceReq>(
      '/service',
      'UPDATE_SERVICE',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'services',
      },
    );
  };

  public deleteService = (id: number) => {
    return this.request<'SERVICE'>('/service', 'DELETE_SERVICE', {
      method: 'DELETE',
      pathParam: id,
      permit: 'services',
    });
  };

  public addServiceUser = (params: AddServiceUserReq) => {
    return this.request<'SERVICE', AddServiceUserResponse, AddServiceUserReq>(
      '/service/user',
      'ADD_SERVICE_USER',
      {
        method: 'POST',
        params,
        permit: 'services',
      },
    );
  };

  public updateServiceUser = ({
    service_id,
    user_id,
    is_online_service,
  }: UpdateServiceUserReq) => {
    return this.request<'SERVICE', UpdateServiceUserRes>(
      '/service',
      'UPDATE_SERVICE_USER',
      {
        method: 'PUT',
        params: { is_online_service },
        pathParam: `${service_id}/user/${user_id}`,
        permit: 'services',
      },
    );
  };

  public removeServiceUser = (service_id: number, user_id: number) => {
    return this.request<'SERVICE'>('/service', 'REMOVE_SERVICE_USER', {
      method: 'DELETE',
      pathParam: `${service_id}/user/${user_id}`,
      permit: 'services',
    });
  };

  public activateService = (params: GetActivateService) => {
    return this.request<'SERVICE', ServiceResponse[]>(
      '/service',
      'ACTIVATE_SERVICE',
      {
        method: 'PUT',
        pathParam: `activate`,
        params,
        permit: 'services',
      },
    );
  };

  public deactivateService = (params: GetActivateService) => {
    return this.request<'SERVICE', ServiceResponse[]>(
      '/service',
      'DEACTIVATE_SERVICE',
      {
        method: 'PUT',
        pathParam: `deactivate`,
        params,
        permit: 'services',
      },
    );
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE CATEGORIES
  |----------------------------------------------------------------------------
  */
  public getServiceCategories = (query?: GetServiceCategoriesQuery) => {
    return this.request<
      'SERVICE',
      ServiceCategory[],
      GetServiceCategoriesQuery
    >('/service_category', 'GET_SERVICE_CATEGORIES', {
      query,
      permit: 'service_category',
    });
  };

  public getServiceCategory = (id: number) => {
    return this.request<'SERVICE', ServiceCategory>(
      '/service_category',
      'GET_SERVICE_CATEGORY',
      {
        pathParam: id,
        permit: 'service_category',
      },
    );
  };

  public getServiceCategoriesTree = (query?: GetServiceCategoriesQuery) => {
    return this.request<'SERVICE', ServiceCategoryTree[]>(
      '/service_category_tree/',
      'GET_SERVICE_CATEGORIES_TREE',
      { query, permit: 'service_category' },
    );
  };

  public getServicePossibleParents = (id: number) => {
    return this.request<'SERVICE', ServiceCategory[]>(
      '/service_category',
      'GET_SERVICE_POSSIBLE_PARENTS',
      { pathParam: `${id}/possible_parent_list` },
    );
  };

  public createServiceCategory = (params: CreateServiceCategoryQuery) => {
    return this.request<'SERVICE', ServiceCategory, CreateServiceCategoryQuery>(
      '/service_category',
      'CREATE_SERVICE_CATEGORY',
      {
        method: 'POST',
        params,
        permit: 'service_category',
      },
    );
  };

  public updateServiceCategory = (
    id: number,
    params: UpdateServiceCategory,
  ) => {
    return this.request<'SERVICE', ServiceCategory, UpdateServiceCategory>(
      '/service_category',
      'UPDATE_SERVICE_CATEGORY',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'service_category',
      },
    );
  };

  public deleteServiceCategory = (id: number) => {
    return this.request<'SERVICE'>(
      '/service_category',
      'DELETE_SERVICE_CATEGORY',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'service_category',
      },
    );
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE RELATION TYPE
  |----------------------------------------------------------------------------
  */
  public getServiceRelationTypes = () => {
    return this.request<'SERVICE', ServiceRelationType[]>(
      '/service_relation_type',
      'GET_SERVICE_RELATION_TYPES',
      {
        permit: 'service_relation_type',
      },
    );
  };

  public getServiceRelationType = (id: number) => {
    return this.request<'SERVICE', ServiceRelationType>(
      '/service_relation_type',
      'GET_SERVICE_RELATION_TYPE',
      {
        pathParam: id,
        permit: 'service_relation_type',
      },
    );
  };

  public createServiceRelationType = (params: CreateServiceRelationTypeReq) => {
    return this.request<'SERVICE', ServiceRelationType>(
      '/service_relation_type',
      'CREATE_SERVICE_RELATION_TYPE',
      {
        method: 'POST',
        params,
        permit: 'service_relation_type',
      },
    );
  };

  public updateServiceRelationType = (
    id: number,
    params: UpdateServiceRelationTypeReq,
  ) => {
    return this.request<'SERVICE', ServiceRelationType>(
      '/service_relation_type',
      'UPDATE_SERVICE_RELATION_TYPE',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'service_relation_type',
      },
    );
  };

  public deleteServiceRelationType = (id: number) => {
    return this.request<'SERVICE'>(
      '/service_relation_type',
      'DELETE_SERVICE_RELATION_TYPE',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'service_relation_type',
      },
    );
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE PROPERTIES
  |----------------------------------------------------------------------------
  */
  public getServiceProperties = (id: number) => {
    return this.request<'SERVICE', ServiceProperty[]>(
      '/service',
      'GET_SERVICE_PROPERTIES',
      {
        pathParam: `${id}/properties`,
      },
    );
  };

  public deleteServiceProperty = (
    serviceId: number,
    propertyId: number,
    position: number,
  ) => {
    return this.request<'SERVICE'>(
      '/service/property',
      'DELETE_PROPERTY_FROM_SERVICE',
      {
        method: 'DELETE',
        pathParam: `${serviceId}/${propertyId}/${position}`,
      },
    );
  };

  public addServiceProperty = (params: AddPropertyToServiceParams) => {
    return this.request<'SERVICE', ServiceResponse>(
      '/service/property',
      'ADD_PROPERTY_TO_SERVICE',
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateServicePropertyPosition = (
    params: UpdateServicePropertyPosition,
  ) => {
    return this.request<'SERVICE', ServicePropertyPosition>(
      '/service/property',
      'UPDATE_SERVICE_PROPERTY_POSITION',
      { method: 'PUT', params },
    );
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE PROPERTIES
  |----------------------------------------------------------------------------
  */
  public importClinicServices = (params: FormData) => {
    return this.request<'SERVICE', ImportClinicServicesRes, FormData>(
      '/service',
      'IMPORT_CLINIC_SERVICES',
      {
        method: 'POST',
        params,
        pathParam: 'import',
      },
    );
  };

  public saveImportClinicServices = (params: SaveImportClinicServices) => {
    return this.request<
      'SERVICE',
      SaveImportClinicServices,
      SaveImportClinicServices
    >('/service', 'SAVE_IMPORT_CLINIC_SERVICES', {
      method: 'POST',
      params,
      pathParam: 'import/save',
    });
  };
}
