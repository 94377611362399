import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWindows = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.5 5.125v6.25h8.75V4.031zM12.5 3.875v7.5h10v-8.75zM12.5 12.625v7.5l10 1.25v-8.75zM2.5 12.625v6.25l8.75 1.094v-7.344z"
      />
    </svg>
  );
};
export default SvgWindows;
