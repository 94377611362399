import { DateService } from '@modules/Format';
import { action, makeObservable, observable } from 'mobx';

export class KanbanEvent {
  public readonly id: number;
  @observable public status: AppointmentStatus;
  @observable public urgent: boolean;
  @observable public withInsurance: boolean;
  @observable public patient: KanbanPatient | null = null;
  @observable public doctor = '';
  @observable public duration = '';
  @observable public start_time = 0;
  @observable public notify = false;
  @observable public confirmed = false;
  public user: AppointmentUser;
  public end_time: number;
  public cabinet: AppointmentCabinet;
  public base: AppointmentType;

  constructor(event: AppointmentType, notify = false) {
    makeObservable(this);
    this.id = event.id;
    this.status = event.status;
    this.urgent = event.urgent;
    this.withInsurance = event.with_insurance;
    this.user = event.user;
    this.start_time = event.start_time;
    this.end_time = event.end_time;
    this.cabinet = event.cabinet;
    this.confirmed = event.confirmed;
    this._setPatient(event);
    this._setDoctor(event);
    this._setDuration(event);
    this.notify = notify;
    this.base = event;
  }

  private _setPatient(e: AppointmentType) {
    if (!e.patient) return;

    const medCards = e.patient.medical_cards ?? [];
    const openMedCards = medCards.filter((m) => m.isOpen);
    const needsPrinting =
      !!openMedCards.length && openMedCards.every((m) => m.needs_printing);

    this.patient = {
      full: `${e.patient.last_name} ${e.patient.first_name} ${e.patient.patronymic}`,
      name: `${e.patient.last_name}`,
      first_patronymic: `${e.patient.first_name} ${e.patient.patronymic}`,
      medical_cards: e.patient.medical_cards || [],
      birthday: e.patient.date_of_birth || '',
      hasOpenMedCard: !!openMedCards.length,
      needsPrintingMedCards: needsPrinting,
      ...e.patient,
    };
  }

  private _setDoctor(e: AppointmentType) {
    this.doctor = `${e.user.last_name} ${e.user.first_name[0]}. ${e.user.patronymic[0]}.`;
  }

  private _setDuration(event: AppointmentType) {
    const start = DateService.formatDate(event.start_time, 'HH_mm');
    const end = DateService.formatDate(event.end_time, 'HH_mm');
    this.duration = `${start} - ${end}`;
  }

  @action
  public setNotify = (val: boolean) => {
    this.notify = val;
  };
}
