import { makeAutoObservable } from 'mobx';

export class SchedulerStore {
  public availableExecutors: AvailableExecutor[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public setExecutors = (executors: AvailableExecutor[]): void => {
    this.availableExecutors = executors;
  };

  public clear = (): void => {
    this.availableExecutors = [];
  };
}
