import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapsHomeWork = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M.833 9.167V17.5H5v-5h3.333v5H12.5V9.167L6.667 5z" />
        <path d="M8.333 2.5v1.642l5.834 4.166v.859h1.666v1.666h-1.666V12.5h1.666v1.667h-1.666V17.5h5v-15zm7.5 5h-1.666V5.833h1.666z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgMapsHomeWork;
