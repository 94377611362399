import { BaseEnv } from '@modules/Env/typings';
import { defaultEnv } from '@modules/Env/config';

class EnvService {
  private readonly _env: IEnv = $ENV;
  private readonly _cachedEnv: Partial<IEnv> = {};

  public readonly isDev = __DEV__;
  public readonly isProd = __PROD__;
  public readonly isUnitTest = process.env.NODE_ENV === 'test';
  public readonly BUILD_DATE = __BUILD_DATE__;
  public readonly COMMIT_HASH = __COMMIT_HASH__;

  constructor() {
    this._validateEnv();
    this._printAppVersion();
  }

  private _validateEnv(): void {
    if (typeof $ENV === 'undefined') {
      console.error('.env file not found!');
    }
  }

  private _printAppVersion = () => {
    if (this.isDev) return;
    if (!this.isUnitTest) console.log(this.appVersion);
  };

  private _check(variable: keyof IEnv): string {
    if (variable in this._cachedEnv) {
      return this._cachedEnv[variable] as string;
    }

    const value = this._env[variable];
    const defaultValue = defaultEnv[variable] || 'NOT_DEFINED';
    if (!value) {
      console.warn(
        `❌ Environment variable ${variable} is missing! Using default value: ${defaultValue}`,
      );
      this._cachedEnv[variable] = defaultValue;
      return defaultValue;
    }

    this._cachedEnv[variable] = value;
    return value;
  }

  public get env(): BaseEnv {
    return {
      appName: this._check('APP_NAME'),
      api: {
        url: this._check('API_CORE_URI'),
        scheduler: this._check('API_SCHEDULER_URI'),
      },
      ws: {
        core: this._check('WS_CORE_URI'),
        url: this._check('WS_SCHEDULER_URI'),
      },
      tinymce: {
        key: this._check('TINYMCE_KEY'),
      },
      sentry: {
        env: this._check('APP_NAME'),
        dsn: this._check('SENTRY_DSN'),
      },
      // eusign: {
      //   host: this._check('EU_SIGN_HOST'),
      //   url: this._check('EU_SIGN_URL'),
      //   verifyUrl: this._check('EU_VERIFY_URL'),
      // },
      // eHealth: {
      //   security_redirect_uri: this._check('E_HEALTH_SECURITY_REDIRECT_URI'),
      // },
    };
  }

  public get isDevApp() {
    return this.env.sentry.env === 'dev';
  }

  public get appVersion() {
    return `[${$ENV?.APP_NAME || 'NO_NAME'} ${this.COMMIT_HASH}]: ${
      this.BUILD_DATE
    }`;
  }
}

const service = new EnvService();
window.envService = service;

export default service;
