import React, { memo } from 'react';
import cn from 'classnames';
import { Text } from '@components/UI/Text';
import { Icon } from '@components/UI/Icon';
import { DrawerButtons } from '@components/UI/Drawer/components';
import DrawerError from '@components/UI/Drawer/DrawerError';
import { Tooltip } from '@components/UI/Tooltip';
import i18n from '@lib/i18next';
import './Drawer.scss';

type DrawerBaseProps = {
  config: DrawerProps;
  className?: string;
  children?: React.ReactNode;
  testID?: string;
};

type PartProps = {
  children: React.ReactNode;
  className?: string;
};

type HeaderProps = {
  config: DrawerProps;
  children?: React.ReactNode;
  className?: string;
  closable?: boolean;
  title?: string;
  icon?: IconProps | false;
  iconTooltip?: string;
};

export const DrawerPart = {
  header: function header({
    children,
    closable = true,
    config,
    title,
    className,
    icon,
    iconTooltip,
  }: HeaderProps) {
    const _title = title ? title : config.title.children;

    return (
      <div className={cn(['drawer-header', className])}>
        {!children && (
          <div className="flex justify-between items-center">
            <Text {...config.title}>{i18n.t(`drawers:${_title}`)}</Text>

            {icon && (
              <Tooltip
                disabled={!iconTooltip}
                content={i18n.t(`drawers:${iconTooltip}`)}
              >
                <Icon className="cursor-pointer" {...icon} />
              </Tooltip>
            )}

            {closable && !icon && (
              <Icon
                onClick={config.actions.close}
                name="Close"
                size="medium"
                className="cursor-pointer"
              />
            )}
          </div>
        )}
        {children}
      </div>
    );
  },
  body: function header({ children, className }: PartProps) {
    return (
      <div className={cn('drawer-body', className)}>
        <DrawerError />
        {children}
      </div>
    );
  },
  footer: function footer({ children }: PartProps) {
    return <div className="drawer-footer">{children}</div>;
  },
};

function Drawer({ className, children, config, testID }: DrawerBaseProps) {
  const { message, buttons, imageSrc, icon } = config;
  if (children) {
    return (
      <div className={cn(['drawer-slot', className])} data-testid={testID}>
        {children}
      </div>
    );
  }

  return (
    <div className={cn(['drawer-slot', className])} data-testid={testID}>
      <DrawerPart.header config={config} />

      <DrawerPart.body>
        <DrawerError />
        {icon && (
          <div className="modal-image">
            <Icon name={icon} size="modal" />
          </div>
        )}
        {imageSrc && (
          <div className="modal-image mb-4">
            <img src={imageSrc} alt="pic" />
          </div>
        )}
        {message && <Text {...message} />}
      </DrawerPart.body>

      <DrawerPart.footer>
        {buttons && <DrawerButtons buttons={buttons} />}
      </DrawerPart.footer>
    </div>
  );
}

export default memo(Drawer);
