import * as React from 'react';
import type { SVGProps } from 'react';
const SvgActiveVisit = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <rect width={28} height={28} fill="#2563EB" rx={4} />
      <path
        fill="currentColor"
        d="M15.667 9v12.5H6.5v-1.667h1.667V6.5h7.5v.833h4.166v12.5H21.5V21.5h-3.333V9zm-3.334 4.167v1.666H14v-1.666z"
      />
    </svg>
  );
};
export default SvgActiveVisit;
