import { FetchService } from '@modules/Api';

export class BiomaterialServicePrintTaskApi extends FetchService {
  public getBiomaterialServicePrintTask = (
    params: GetBiomaterialServicePrintTask,
  ) => {
    return this.request<
      'BIOMATERIAL_SERVICE_PRINT_TASK',
      BiomaterialServicePrintTask,
      GetBiomaterialServicePrintTask
    >('/biomaterial_service_print_task', 'BIOMATERIAL_SERVICE_PRINT_TASK', {
      method: 'POST',
      params,
    });
  };
}
