import { action, autorun, makeObservable, observable } from 'mobx';
import './consts';
import { breakpointList } from '@modules/Layout/consts';
import { LStorage } from '@modules/Storage';

export default class LayoutStore {
  @observable public sidebarIsOpen = !!LStorage.getItem('SIDEBAR_OPEN', true);
  @observable public size: Breakpoint;

  constructor() {
    makeObservable(this);
    window.addEventListener('resize', this._onResize, true);
    this.size = this._onResize();

    autorun(() => {
      LStorage.sync('SIDEBAR_OPEN', this.sidebarIsOpen);
      const root = document.getElementById('root');
      this.sidebarIsOpen
        ? root?.classList.remove('nav-close')
        : root?.classList.add('nav-close');
    });
  }

  public get breakpoint(): BreakpointFn {
    const _size = this.size;
    const is = (size: Breakpoint[] | Breakpoint) => {
      const breakpoints = Array.isArray(size) ? size : [size];
      return breakpoints.includes(_size);
    };

    return {
      isMobile: true, // FIXME is(['xs', 'sm'])
      isDesktop: true, // FIXME is(['2xl', 'lg'])
      is,
      not(size) {
        const list = Array.isArray(size) ? size : [size];
        return !list.includes(_size);
      },
      get size() {
        return _size;
      },
    };
  }

  @action
  public toggleSidebar = (): void => {
    this.sidebarIsOpen = !this.sidebarIsOpen;
  };

  private _onResize(): Breakpoint {
    const { innerWidth } = window;
    const current = this.size;

    for (const [breakpoint, [from, to]] of breakpointList) {
      if (innerWidth >= from && innerWidth <= to) {
        if (this.size !== breakpoint) {
          return (this.size = breakpoint as Breakpoint);
        }
      }
    }

    return current;
  }
}
