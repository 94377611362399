import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTelegram = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m9.848 14.545-.331 4.467c.473 0 .678-.195.924-.43l2.22-2.035 4.598 3.232c.843.452 1.437.214 1.665-.744l3.018-13.578h.001c.268-1.197-.45-1.665-1.272-1.372l-17.742 6.52c-1.211.452-1.193 1.1-.206 1.394l4.536 1.354 10.536-6.329c.495-.315.946-.14.575.175z"
      />
    </svg>
  );
};
export default SvgTelegram;
