import TopBarProgress from 'react-topbar-progress-indicator';

TopBarProgress.config({
  barColors: {
    '0': '#1cb9b4',
    '0.5': '#1b9995',
    '1.0': '#1d7f77',
  },
  shadowBlur: 0,
  barThickness: 2,
});
