import { action, makeObservable, observable } from 'mobx';

export class CertificatesStore {
  @observable public certificates: ServiceResponse[] = [];
  @observable public certificatesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setCertificates = (
    certificates: ServiceResponse[],
    meta?: ResponseMeta,
  ) => {
    this.certificates = certificates;
    if (meta) {
      this.certificatesMeta = meta;
    }
  };

  @action
  public addCertificate = (service: ServiceResponse) => {
    this.certificates = [service, ...this.certificates];
  };

  @action
  public setStatus = (service: ServiceResponse) => {
    const item = this.certificates.find((e) => e.id === service.id);
    if (item) {
      item.status = service.status;
    }
  };

  @action
  public deleteCertificate = (id: number) => {
    this.certificates = this.certificates.filter((e) => e.id !== id);
  };
}
