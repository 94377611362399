import { action, computed, makeObservable, observable } from 'mobx';
import { Episodes } from '@modules/DoctorWorkplace/adapter/Episodes';
import { Analyse } from '@modules/DoctorWorkplace/adapter/Analyse';
import { EventTimer } from '@modules/DoctorWorkplace/adapter/EventTimer';
import { BiomaterialTypes } from '@modules/DoctorWorkplace/adapter/BiomaterialTypes';
import { Documents } from '@modules/DoctorWorkplace/adapter/Documents';
import { Directions } from '@modules/DoctorWorkplace/adapter/Directions';
import { Guarantees } from '@modules/DoctorWorkplace/adapter/Guarantees';
import { Packages } from '@modules/DoctorWorkplace/adapter/Packages';
import { LStorage } from '@modules/Storage';
import orderBy from 'lodash/orderBy';

type Analyze = {
  id: number;
  secondary: Array<{
    service: AppointmentService;
    biomaterial?: BiomaterialType;
  }>;
  primary: AppointmentService;
};

type GroupedDirectionWithGuarantee = {
  service: DocDirectionService;
  directions: DocDirection[];
  guarantees: Guarantee[];
};

export class DoctorVisitAdapter {
  public timer: EventTimer;
  public analyses: Analyse;
  public episodes: Episodes;
  public documents: Documents;
  public directions: Directions;
  public guarantees: Guarantees;
  public biomaterialTypes: BiomaterialTypes;
  public packages: Packages;
  @observable public visit: AppointmentType;

  constructor(event: AppointmentType) {
    makeObservable(this);
    this.visit = event;
    this.timer = new EventTimer();
    this.episodes = new Episodes();
    this.analyses = new Analyse();
    this.documents = new Documents();
    this.directions = new Directions();
    this.guarantees = new Guarantees();
    this.biomaterialTypes = new BiomaterialTypes();
    this.packages = new Packages();
  }

  @action
  public updateVisit = (event: AppointmentType) => {
    this.visit = event;
  };

  @action
  public startVisit = (episodes: MedEpisode[], episode: MedEpisode) => {
    if (!this.visit.active_visit) return;
    this.episodes.setEpisode(episode);
    this.episodes.setEpisodes(episodes);
    this.timer.start(this.visit.active_visit.data_start);
    LStorage.setItem('ACTIVE_EPISODE', episode.id);
  };

  @action
  public endVisit = () => {
    this.timer.stop();
    LStorage.removeItem('ACTIVE_EPISODE');
  };

  @computed
  public get services() {
    return this.visit.services;
  }

  @computed
  public get servicesWithGuarantee() {
    const services: Array<{
      service: AppointmentService;
      guarantees: Guarantee[];
    }> = [];

    for (const service of this.services) {
      const confirmed = this.guarantees.confirmed.get(service.code) || [];
      const available = this.guarantees.available.get(service.code) || [];

      const guarantees = [...confirmed, ...available].filter((guarantee) => {
        const isSameAppointment = guarantee.appointment_id === this.visit.id;
        return isSameAppointment || !guarantee.appointment_id;
      });
      services.push({ service, guarantees });
    }
    return orderBy(services, (list) => list.service.title);
  }

  @computed
  public get groupedGuaranteeByServiceCode() {
    const guaranteesByServiceCode = new Map<string, Array<Guarantee>>();
    for (const guarantee of this.guarantees.list) {
      const guarantees =
        guaranteesByServiceCode.get(guarantee.service.code) || [];
      guaranteesByServiceCode.set(
        guarantee.service.code,
        guarantees.concat(guarantee),
      );
    }
    return guaranteesByServiceCode;
  }

  @computed
  public get groupedDirectionAndGuarantees() {
    const directions = new Map<
      string,
      Omit<GroupedDirectionWithGuarantee, 'guarantees'>
    >();
    for (const direction of this.directions.list) {
      const key = `${direction.service.code}`;
      const data = directions.get(key);
      if (data) {
        directions.set(key, {
          ...data,
          directions: data.directions.concat(direction),
        });
      } else {
        directions.set(key, {
          service: direction.service,
          directions: [direction],
        });
      }
    }
    const directionsWithGuaranteesByService = [];
    for (const grouped of directions.values()) {
      const data = this.groupedGuaranteeByServiceCode.get(grouped.service.code);
      directionsWithGuaranteesByService.push({
        ...grouped,
        guarantees: data || [],
      });
    }
    return directionsWithGuaranteesByService;
  }

  @computed
  public get groupedAnalyzes() {
    const grouped: Analyze[] = [];

    const findBiomaterial = (id: number | null) => {
      return this.biomaterialTypes.list.find((b) => b.id === id);
    };

    for (const analyze of this.analyses.list) {
      const [primary] = analyze.services.filter(
        (service) => service.type === 'lab_primary',
      );
      const secondary = analyze.services.filter(
        (service) => service.type === 'lab_secondary',
      );
      grouped.push({
        id: analyze.id,
        secondary: secondary.map((service) => ({
          service,
          biomaterial: findBiomaterial(service.biomaterial_type_id),
        })),
        primary,
      });
    }
    return grouped;
  }
}
