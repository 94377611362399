import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import { ContentLoader } from '@components/UI/ContentLoader';
import { DELAYED_RENDER } from '@modules/App/consts';

type Props = {
  isApp?: boolean;
  delayedRenderKey?: DelayedRenderKeys;
  showLoader?: boolean;
  children: ReactNode;
};

const InitLoader: React.FC<Props> = ({
  children,
  isApp = true,
  delayedRenderKey = 'DEFAULT',
  showLoader = true,
}) => {
  const { initialized, appInitialized } = useService('AppService');
  const { isConnected } = useService('WsService');
  const [ready, setReady] = useState(false);

  const setReadyState = useCallback(() => {
    const delay = DELAYED_RENDER[delayedRenderKey];
    setTimeout(() => {
      setReady(true);
    }, delay);
  }, [delayedRenderKey]);

  useEffect(() => {
    if (!isApp) {
      if (initialized) setReadyState();
    } else {
      const shouldRenderContent = initialized && appInitialized && isConnected;

      if (shouldRenderContent) {
        setReadyState();
      }
    }
  }, [initialized, appInitialized, isConnected, isApp, setReadyState]);

  const loader = showLoader ? <ContentLoader /> : null;

  return !ready ? loader : <Fragment>{children}</Fragment>;
};

export default observer(InitLoader);
