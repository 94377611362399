import { action, makeObservable, observable } from 'mobx';

export class DocumentStore {
  @observable public medDocument: MedDocument | null = null;
  @observable public documents: MedDocument[] = [];
  @observable public documentsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public docMacros: DocMacros[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setMedDocuments = (documents: MedDocument[], meta: ResponseMeta) => {
    this.documents = documents;
    this.documentsMeta = meta;
  };

  @action
  public setMedDocument = (doc: MedDocument | null, isNew = false) => {
    if (!isNew) {
      this.medDocument = doc;
    }
    if (isNew && doc) {
      this.documents = [doc, ...this.documents];
    }
  };

  @action
  public updateMedDocument = (doc: MedDocument) => {
    const document = this.documents.find((e) => e.id === doc.id);
    if (document) {
      Object.assign(document, doc);
    }
    if (this.medDocument?.id === doc.id) {
      Object.assign(this.medDocument, doc);
    }
  };

  @action
  public clearDocuments = () => {
    this.medDocument = null;
    this.documents = [];
    this.documentsMeta = { totalRecords: 0, totalPages: 0 };
  };

  @action
  public setDocMacros = (macros: DocMacros[]) => {
    this.docMacros = macros;
  };

  @action
  public deleteDocument = (id: number) => {
    this.documents = this.documents.filter((d) => d.id !== id);
    if (this.medDocument?.id === id) {
      this.medDocument = null;
    }
  };
}
