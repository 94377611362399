import { observable, action, makeObservable } from 'mobx';
import { INITIAL_LOADINGS } from './consts';

export default class LoadingStore {
  @observable public loadings: LoadingList = INITIAL_LOADINGS;

  constructor() {
    makeObservable(this);
  }

  @action public setLoading = (key: LoadingKey, value: boolean): void => {
    this.loadings[key] = value;
  };
}
