import { PricesStore } from '@modules/Prices/prices.store';

export class PricesService extends PricesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPricesReq = async (query?: GetPricesQuery) => {
    const res = await this._store.PricesApi.getPrices(query);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setPrices(res.data, res.meta);
    }

    return res;
  };

  public getPriceReq = async (id: number) => {
    const res = await this._store.PricesApi.getPrice(id);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setPrice(res.data);
    }

    return res;
  };

  public createPriceReq = async (params: CreatePrice) => {
    const res = await this._store.PricesApi.createPrice(params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setNewPrice(res.data);
    }

    return res;
  };

  public updatePriceReq = async (id: number, params: UpdatePrice) => {
    const res = await this._store.PricesApi.updatePrice(id, params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      const price = res.data;
      this.updatePrice(price.id, 'name', price.name);
      this.updatePrice(price.id, 'description', price.description);
      this.updatePrice(price.id, 'activationDate', price.activation_date);
    }

    return res;
  };

  public deletePriceReq = async (id: number) => {
    const res = await this._store.PricesApi.deletePrice(id);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success) {
      this.deletePrice(id);
    }

    return res;
  };

  public activatePriceReq = async (id: number) => {
    const res = await this._store.PricesApi.activatePrice(id);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      const price = res.data;
      this.updatePrice(price.id, 'active', price.active);
    }

    return res;
  };

  public deactivatePriceReq = async (id: number) => {
    const res = await this._store.PricesApi.deactivatePrice(id);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      const price = res.data;
      this.updatePrice(price.id, 'active', price.active);
    }

    return res;
  };

  public getPriceBlankFileReq = async () => {
    const res = await this._store.PricesApi.getPriceBlankFile();

    if (!res.success) this._store.ModalService.modal.error(res.errors);

    return res;
  };

  public exportPriceReq = async (priceId: number) => {
    const res = await this._store.PricesApi.exportPrice(priceId);

    if (!res.success) this._store.ModalService.modal.error(res.errors);

    return res;
  };

  public exportPrintVersionReq = async (priceId: number) => {
    const res = await this._store.PricesApi.exportPrintVersion(priceId);

    if (!res.success) this._store.ModalService.modal.error(res.errors);

    return res;
  };

  public importPriceRequest = async (params: FormData) => {
    const res = await this._store.PricesApi.importPrice(params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setNewPrice(res.data);
    }

    return res;
  };

  public importPriceByIdReq = async (id: number, params: FormData) => {
    const res = await this._store.PricesApi.importPriceById(id, params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);

    return res;
  };

  public importBasePriceByIdReq = async (params: FormData) => {
    const basePricesRes = await this._store.PricesApi.getPriceList({
      'filter[base]': true,
      'filter[active]': true,
    });
    if (!basePricesRes.success)
      this._store.ModalService.modal.error(basePricesRes.errors);
    if (!basePricesRes.data?.length) return null;

    const res = await this._store.PricesApi.importPriceById(
      basePricesRes.data[0].id,
      params,
    );

    if (!res.success) this._store.ModalService.modal.error(res.errors);

    return res;
  };

  public getPriceServicesReq = async (
    id: number,
    query?: GetPriceServiceQuery,
  ) => {
    const res = await this._store.PricesApi.getPriceServices(id, query);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setPriceServices(res.data, res.meta);
    }

    return res;
  };

  public setCostPriceReq = async (id: number, params: SetCostPrice) => {
    const res = await this._store.PricesApi.setCostPrice(id, params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setCost(res.data);
    }

    return res;
  };

  public setBaseCostPriceReq = async (params: SetCostPrice) => {
    const basePricesRes = await this._store.PricesApi.getPriceList({
      'filter[base]': true,
      'filter[active]': true,
    });
    if (!basePricesRes.success)
      this._store.ModalService.modal.error(basePricesRes.errors);
    if (!basePricesRes.data?.length) return null;

    const res = await this._store.PricesApi.setCostPrice(
      basePricesRes.data[0].id,
      params,
    );

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setBaseCost(res.data);
    }

    return res;
  };

  public copyPriceCostReq = async (priceId: number, params: CopyCostPrice) => {
    const res = await this._store.PricesApi.copyPriceCost(priceId, params);

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.isBasePrice ? this.setBaseCost(res.data) : this.setCost(res.data);
    }

    return res;
  };

  public copyBasePriceCostReq = async (
    params: Omit<CopyCostPrice, 'price_depends_on'>,
  ) => {
    const basePricesRes = await this._store.PricesApi.getPriceList({
      'filter[base]': true,
      'filter[active]': true,
    });
    if (!basePricesRes.success)
      this._store.ModalService.modal.error(basePricesRes.errors);
    if (!basePricesRes.data?.length) return null;

    const res = await this._store.PricesApi.copyPriceCost(
      basePricesRes.data[0].id,
      {
        price_depends_on: basePricesRes.data[0].id,
        ...params,
      },
    );

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success && res.data) {
      this.setBaseCost(res.data);
    }

    return res;
  };

  public removePriceServicesReq = async (
    priceId: number,
    params: RemovePriceServices,
  ) => {
    const res = await this._store.PricesApi.removePriceServices(
      priceId,
      params,
    );

    if (!res.success) this._store.ModalService.modal.error(res.errors);
    if (res.success) {
      this.removePriceServices(params);
    }

    return res;
  };
}
