import RoleStore from '@modules/Roles/roleStore';
import { DEFAULT_ROLE } from '@modules/Api/methods/Role/consts';

export class RoleService extends RoleStore {
  protected readonly _store: Store;
  public readonly defaultRoles = DEFAULT_ROLE;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getRolesReq = async (query?: GetRolesQuery) => {
    const res = await this._store.RoleApi.getRoles(query);
    if (res.success && res.data) {
      this.setRoles(res.data, res.meta);
    }
    return res;
  };

  public getRoleReq = async (roleId: number) => {
    const res = await this._store.RoleApi.getRole(roleId);
    if (res.success && res.data) {
      this.setRole(res.data);
    }
    return res;
  };

  public deleteRoleReq = async (roleId: number) => {
    const res = await this._store.RoleApi.deleteRole(roleId);
    if (res.success) {
      this.removeRole(roleId);
    }
    return res;
  };

  public createRoleReq = async (role: CreateRoleReq) => {
    const res = await this._store.RoleApi.createRole(role);
    if (res.success && res.data) {
      this.addRole(res.data);
    }
    return res;
  };

  public updateRoleReq = async (roleId: number, params: UpdateRoleReq) => {
    const res = await this._store.RoleApi.updateRole(roleId, params);
    if (res.success && res.data) {
      this.updateRole(roleId, 'name', res.data.name);
    }
    return res;
  };

  public getRolePermissionsReq = async (roleId: number) => {
    const res = await this._store.RoleApi.getRolePermissions(roleId);
    if (res.success && res.data) {
      this.updateRole(roleId, 'permissions', res.data.permissions);
    }
    this.setRoleLoading(roleId, 'permissions', false);
    return res;
  };

  private _getUiModulesDeepHelper = (uiMods: UiModule[]): UiModule[] => {
    return uiMods.reduce((prev, next) => {
      if (next.id) {
        prev.push({
          ...next,
          offset: next.name.split('/').filter(Boolean).length - 1,
        });
      }
      if (next.children?.length) {
        return prev.concat(this._getUiModulesDeepHelper(next.children));
      }
      return prev;
    }, [] as UiModule[]);
  };

  public getRoleUiModulesReq = async (roleId: number) => {
    const res = await this._store.RoleApi.getRoleUiModules(roleId);
    if (res.success && res.data && res.data.ui_module_right) {
      const uiModules = this._getUiModulesDeepHelper(res.data.ui_module_right);
      this.updateRole(roleId, 'uiModules', uiModules);
    }
    this.setRoleLoading(roleId, 'uiModules', false);
    return res;
  };

  public createPermissionReq = async (params: CreatePermission) => {
    const res = await this._store.PermissionApi.createPermission(params);
    if (res.success && res.data) {
      this.setPermission(res.data);
    }
    return res;
  };

  public updateRolePermissionReq = async (
    permissionId: number,
    params: UpdatePermission,
  ) => {
    const res = await this._store.PermissionApi.updateRolePermission(
      permissionId,
      params,
    );
    if (res.success && res.data) {
      this.updatePermission(res.data);
    }
    return res;
  };

  public removeRolePermissionReq = async (permissionId: number) => {
    const res =
      await this._store.PermissionApi.removeRolePermission(permissionId);
    if (res.success) {
      this.removePermission(permissionId);
    }
    return res;
  };

  public addRoleUiModuleReq = async (params: AddUiModuleToRoleReq) => {
    const res = await this._store.RoleApi.addRoleUiModule(params);
    if (res.success && res.data) {
      this.connectNewUiModule(res.data);
    }
    return res;
  };

  public removeRoleUiModuleReq = async (recordId: number) => {
    const res = await this._store.RoleApi.removeRoleUiModule(recordId);
    if (res.success) {
      this.removeUiModule(recordId);
    }
    return res;
  };

  public getRoleUsersReq = async (query?: GetUsersQuery) => {
    const res = await this._store.UsersApi.getUsers(query);
    if (res.success && res.data) {
      this.setUsers(res.data, res.meta);
    }
    return res;
  };

  public setRoleUserReq = async (params: RoleToUserReq) => {
    const res = await this._store.UsersApi.setUserRole(params);
    if (res.success && res.data) {
      this.addUser(res.data);
    }
    return res;
  };

  public removeRoleUserReq = async (params: RoleToUserReq) => {
    const res = await this._store.UsersApi.removeUserRole(params);
    if (res.success) {
      this.removeUser(params);
    }
    return res;
  };
}
