import LabServicesStore from './lab-services.store';

export default class LabServicesService extends LabServicesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  //----------------------------------------------------------------------------
  //  SERVICES
  //----------------------------------------------------------------------------
  public getServicesReq = async (query?: GetServicesQuery) => {
    const res = await this._store.ServicesApi.getServices(query);
    if (res.success && res.data) {
      this.setServices(res.data, res.meta);
    }
    return res;
  };

  public getServiceReq = async (id: number) => {
    const res = await this._store.ServicesApi.getService(id);
    if (res.success && res.data) {
      this.setService(res.data);
    }
    return res;
  };

  public updateServiceReq = async (
    id: number,
    params: UpdateLabServiceParams,
  ) => {
    const res = await this._store.LabServicesApi.updateLabService(id, params);
    if (res.success && res.data) {
      this.updateService(res.data);
    }
    return res;
  };

  public createServiceReq = async (data: CreateLabServiceParams) => {
    const res = await this._store.LabServicesApi.createLabService(data);
    if (res.success && res.data) {
      this.addService(res.data);
    }
    return res;
  };

  public removeServiceReq = async (id: number) => {
    const res = await this._store.ServicesApi.deleteService(id);
    if (res.success) {
      this.removeService(id);
    }
    return res;
  };

  public activateServiceReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.activateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      res.data.forEach((service) => this.updateService(service));
    }
    return res;
  };

  public deactivateServiceReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.deactivateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      res.data.forEach((service) => this.updateService(service));
    }
    return res;
  };

  //----------------------------------------------------------------------------
  //  SERVICE CATEGORIES
  //----------------------------------------------------------------------------
  public getServiceCategoriesReq = async (
    query?: GetServiceCategoriesQuery,
  ) => {
    const res = await this._store.ServicesApi.getServiceCategories(query);
    if (res.success && res.data) {
      this.setServiceCategories(res.data, res.meta);
    }
    return res;
  };

  public updateServiceCategoryReq = async (
    id: number,
    data: UpdateServiceCategory,
  ) => {
    const res = await this._store.ServicesApi.updateServiceCategory(id, data);
    if (res.success && res.data) {
      this.updateServiceCategory(res.data);
    }
    return res;
  };

  public createServiceCategoryReq = async (
    data: CreateServiceCategoryQuery,
  ) => {
    const res = await this._store.ServicesApi.createServiceCategory(data);
    if (res.success && res.data) {
      this.addServiceCategory(res.data);
    }
    return res;
  };

  public removeServiceCategoryReq = async (id: number) => {
    const res = await this._store.ServicesApi.deleteServiceCategory(id);
    if (res.success) {
      this.removeServiceCategory(id);
    }
    return res;
  };

  //----------------------------------------------------------------------------
  //  SERVICES SETTINGS
  //----------------------------------------------------------------------------
  public getServicesSettingsReq = async (
    query?: GetLabServicesSettingsQuery,
  ) => {
    const res = await this._store.LabServicesApi.getServicesSettings(query);
    if (res.success && res.data) {
      this.setServicesSettings(res.data, res.meta);
    }
    return res;
  };

  public updateServiceSettingReq = async (
    id: number,
    data: UpdateLabServiceSetting,
  ) => {
    const res = await this._store.LabServicesApi.updateServiceSetting(id, data);
    if (res.success && res.data) {
      this.updateServiceSetting(res.data);
    }
    return res;
  };

  public createServiceSettingReq = async (data: CreateLabServiceSetting) => {
    const res = await this._store.LabServicesApi.createServiceSetting(data);
    if (res.success && res.data) {
      this.addServiceSetting(res.data);
    }
    return res;
  };

  public removeServiceSettingReq = async (id: number) => {
    const res = await this._store.LabServicesApi.deleteServiceSetting(id);
    if (res.success) {
      this.removeServiceSetting(id);
    }
    return res;
  };

  //----------------------------------------------------------------------------
  //  SERVICE PROPERTIES
  //----------------------------------------------------------------------------
  public getServicePropertiesReq = async (id: number) => {
    const res = await this._store.ServicesApi.getServiceProperties(id);
    if (res.success && res.data) {
      this.setServiceProperties(res.data);
    }
    return res;
  };

  public addPropertyToServiceReq = async (
    params: AddPropertyToServiceParams,
  ) => {
    return await this._store.ServicesApi.addServiceProperty(params);
  };

  public deletePropertyFromServiceReq = async (
    serviceId: number,
    propertyId: number,
    position: number,
    index: number,
  ) => {
    const res = await this._store.ServicesApi.deleteServiceProperty(
      serviceId,
      propertyId,
      position,
    );
    if (res.success) {
      this.deleteServiceProperty(index);
    }
    return res;
  };

  public updateServicePropertyPositionReq = async (
    params: UpdateServicePropertyPosition,
  ) => {
    const res =
      await this._store.ServicesApi.updateServicePropertyPosition(params);
    if (res.success && res.data) {
      this.updateServicePropertyPosition(res.data, params.prev_position);
    }
    return res;
  };

  //----------------------------------------------------------------------------
  //  SERVICE CABINETS
  //----------------------------------------------------------------------------
  public getCabinetsReq = async (
    id: number,
    query?: GetServiceCabinetsQuery,
  ) => {
    const res = await this._store.ServicesApi.getServiceCabinets(id, query);
    if (res.success && res.data) {
      this.setCabinets(res.data, res.meta);
    }
    return res;
  };

  public removeCabinetReq = async (params: RemoveServiceCabinetReq) => {
    const res = await this._store.ServicesApi.removeServiceCabinet(params);
    if (res.success) {
      this.removeCabinet(params.cabinet_id);
    }
    return res;
  };

  public addCabinetReq = async (params: AddServiceCabinetReq) => {
    const res = await this._store.ServicesApi.addServiceCabinet(params);
    if (res.success && res.data) {
      this.addCabinet(res.data.cabinet);
    }
    return res;
  };
}
