import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArchive = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m17.117 4.358-1.159-1.4A1.21 1.21 0 0 0 15 2.5H5c-.392 0-.733.175-.967.458l-1.15 1.4A1.63 1.63 0 0 0 2.5 5.417v10.416c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V5.417c0-.4-.142-.775-.383-1.059M10 14.583 5.417 10h2.916V8.333h3.334V10h2.916zM4.267 4.167l.675-.834h10l.783.834z"
      />
    </svg>
  );
};
export default SvgArchive;
