import React, { useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useService } from '@stores/hooks';
import { InitLoaderProvider } from '@modules/App';

export default function RouteWithSubRoutes(route: AppRouter) {
  const { goToRoute } = useService('RoutingService');

  useEffect(() => {
    if (route.routes && route.routes.length) {
      route.routes.forEach((e) => {
        if (e.preload) e.component.preload();
      });
    }
    if (route.redirect) goToRoute(route.redirect);
  }, [goToRoute, route.path, route.redirect, route.routes]);

  function component(props: RouteComponentProps) {
    return route.ws ? (
      <InitLoaderProvider delayedRenderKey="DEFAULT">
        <route.component {...props} routes={route.routes} />
      </InitLoaderProvider>
    ) : (
      <route.component {...props} routes={route.routes} />
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Route path={route.path} render={component} />;
}
