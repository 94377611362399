import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPeople = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.333 9.167a2.49 2.49 0 0 0 2.492-2.5 2.497 2.497 0 1 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5m-6.666 0a2.49 2.49 0 0 0 2.491-2.5 2.497 2.497 0 1 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5m0 1.666c-1.942 0-5.834.975-5.834 2.917v2.083H12.5V13.75c0-1.942-3.892-2.917-5.833-2.917m6.666 0c-.241 0-.516.017-.808.042.967.7 1.642 1.642 1.642 2.875v2.083h5V13.75c0-1.942-3.892-2.917-5.834-2.917"
      />
    </svg>
  );
};
export default SvgPeople;
