import React, { ChangeEvent } from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  GroupBase,
  MultiValueGenericProps,
} from 'react-select';
import cx from 'classnames';
import { Props as OriginProps } from 'react-select/dist/declarations/src/Select';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue';
import { MenuProps } from 'react-select/dist/declarations/src/components/Menu';
import { InputProps } from 'react-select/dist/declarations/src/components/Input';
import { Icon } from '@components/UI/Icon';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import { OptionWithTooltip } from './components/OptionWithTooltip';
import './Select.scss';

export type SelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = {
  full?: boolean;
  size?: 'medium' | 'mini';
  label?: string;
  touched?: boolean;
  error?: string | string[] | undefined;
  icon?: IconType;
  formatSelectedOption?: (data: any) => React.ReactNode;
  renderMenuFooter?: (props: MenuProps) => React.ReactNode;
  getTooltipLabel?: (data: any) => React.ReactNode;
  optionWithTooltip?: boolean;
  testID?: string;
} & Partial<OriginProps<Option, IsMulti, Group>>;

interface ICustomControlProps {
  selectProps: {
    size?: string;
    label?: string;
    touched?: boolean;
    error?: string;
    icon?: IconType;
  };
}

interface ICustomSingleValueProps {
  selectProps: {
    optionWithTooltip?: boolean;
    formatSelectedOption?: (data: any) => React.ReactNode;
    getTooltipLabel?: (data: any) => React.ReactNode;
  };
}

interface ICustomMenuProps {
  selectProps: {
    renderMenuFooter?: (props: MenuProps) => React.ReactNode;
  };
}

const Control = (props: ICustomControlProps & ControlProps) => {
  const { label, error, touched, icon, size } = props.selectProps;

  return (
    <components.Control
      {...props}
      className={cx([size], {
        hasLabel: !!label,
        hasValue: props.hasValue,
        error: touched && error && !props.hasValue,
        icon: !!icon,
      })}
    >
      {icon && (
        <span className="svg-icon">
          <Icon name={icon} className="text-coolGray-500" />
        </span>
      )}
      {label && <span className="label">{label}</span>}
      {props.children}
    </components.Control>
  );
};

const SingleValue = (props: ICustomSingleValueProps & SingleValueProps) => {
  const getTooltipLabel = (option: any) => {
    return props.selectProps.getOptionLabel(option);
  };

  const label = props.selectProps.getTooltipLabel
    ? props.selectProps.getTooltipLabel(props.selectProps.value)
    : getTooltipLabel(props.selectProps.value);

  const children = props.selectProps.formatSelectedOption
    ? props.selectProps.formatSelectedOption(props.data)
    : props.children;

  if (props.selectProps.optionWithTooltip) {
    return (
      <components.SingleValue {...props}>
        <OptionWithTooltip label={label}>{children}</OptionWithTooltip>
      </components.SingleValue>
    );
  }

  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

const MultiValueLabel = (
  props: ICustomSingleValueProps & MultiValueGenericProps,
) => {
  const getTooltipLabel = (option: any) => {
    return props.selectProps.getOptionLabel(option);
  };

  const label = props.selectProps.getTooltipLabel
    ? props.selectProps.getTooltipLabel(props.selectProps.value)
    : getTooltipLabel(props.selectProps.value);

  const children = props.selectProps.formatSelectedOption
    ? props.selectProps.formatSelectedOption(props.data)
    : props.children;

  if (props.selectProps.optionWithTooltip) {
    return (
      <components.MultiValueLabel {...props}>
        <OptionWithTooltip label={label}>{children}</OptionWithTooltip>
      </components.MultiValueLabel>
    );
  }

  return (
    <components.MultiValueLabel {...props}>
      {children}
    </components.MultiValueLabel>
  );
};

const Menu = (props: ICustomMenuProps & MenuProps) => {
  return (
    <components.Menu {...props}>
      {props.children}
      {props.selectProps.renderMenuFooter &&
        props.selectProps.renderMenuFooter(props)}
    </components.Menu>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => (
  <div onMouseDown={(e) => e.stopPropagation()}>
    <components.ClearIndicator {...props}>
      {props.children}
    </components.ClearIndicator>
  </div>
);

const Input = (props: InputProps) => {
  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = upperFirst(e.target.value);
    props.onChange?.(e);
  };

  return (
    <components.Input
      {...props}
      title={props.selectProps.placeholder as string}
      onChange={_onChange}
    >
      {props.children}
    </components.Input>
  );
};

export default function Select({
  className,
  full,
  error,
  touched,
  noOptionsMessage,
  testID,
  components,
  ...rest
}: SelectProps<any, boolean, any>) {
  const { t } = useTranslation('layout');

  return (
    <div
      className={cx(['rm-select', className], {
        full,
        disabled: rest?.isDisabled,
      })}
      data-testid={testID}
    >
      <ReactSelect<SelectProps<any, boolean, any>>
        error={error}
        touched={touched}
        classNamePrefix="rm"
        components={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Control,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          SingleValue,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          MultiValueLabel,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Menu,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Input,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ClearIndicator,
          IndicatorSeparator: () => null,
          ...components,
        }}
        noOptionsMessage={
          noOptionsMessage ? noOptionsMessage : () => t('select.not_found')
        }
        {...rest}
      />

      {touched && error && <div className="message-error">{error}</div>}
    </div>
  );
}
