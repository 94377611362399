import { FetchService } from '@modules/Api';

export class DocTemplateApi extends FetchService {
  /*
   |----------------------------------------------------------------------------
   | ELEMENTS
   |----------------------------------------------------------------------------
   */
  public getDocElements = (query?: GetDocElementsQuery) => {
    return this.request<
      'DOC_ELEMENTS',
      DocElement[],
      null,
      GetDocElementsQuery
    >('/elements', 'GET_DOC_ELEMENTS', { query, permit: 'elements' });
  };

  public getDocElement = (id: number) => {
    return this.request<'DOC_ELEMENTS', DocElement>(
      '/elements',
      'GET_DOC_ELEMENT',
      { pathParam: id, permit: 'elements' },
    );
  };

  public getDocElementTree = (id: number) => {
    return this.request<'DOC_ELEMENTS', DocElementTree>(
      '/elements/tree',
      'GET_DOC_ELEMENT_TREE',
      { pathParam: id, permit: 'elements' },
    );
  };

  public updateDocElement = (id: number, params: UpdateDocElementReq) => {
    return this.request<'DOC_ELEMENTS', DocElement, UpdateDocElementReq>(
      '/elements',
      'UPDATE_DOC_ELEMENT',
      { method: 'PUT', pathParam: id, params, permit: 'elements' },
    );
  };

  public createDocElement = (params: CreateDocElementReq) => {
    return this.request<'DOC_ELEMENTS', DocElement, CreateDocElementReq>(
      '/elements',
      'CREATE_DOC_ELEMENT',
      { method: 'POST', params, permit: 'elements' },
    );
  };

  public deleteDocElement = (id: number) => {
    return this.request<'DOC_ELEMENTS'>('/elements', 'DELETE_DOC_ELEMENT', {
      method: 'DELETE',
      pathParam: id,
      permit: 'elements',
    });
  };

  /*
   |----------------------------------------------------------------------------
   | DOC TYPES
   |----------------------------------------------------------------------------
   */
  public getElementTypes = () => {
    return this.request<'DOC_ELEMENTS', DocElementType[]>(
      '/elements_type',
      'GET_DOC_ELEMENT_TYPES',
      { permit: 'elements_type' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | DOC VALUE
   |----------------------------------------------------------------------------
   */
  public createElementValue = (params: CreateDocElementValueReq) => {
    return this.request<
      'DOC_ELEMENTS',
      DocElementValueOpt,
      CreateDocElementValueReq
    >('/element/value', 'CREATE_DOC_ELEMENT_VALUE', {
      method: 'POST',
      params,
      permit: 'elements_values',
    });
  };

  public updateElementValue = (
    id: number,
    params: UpdateDocElementValueReq,
  ) => {
    return this.request<
      'DOC_ELEMENTS',
      DocElementValueOpt,
      UpdateDocElementValueReq
    >('/element/value', 'UPDATE_DOC_ELEMENT_VALUE', {
      method: 'PUT',
      pathParam: id,
      params,
      permit: 'elements_values',
    });
  };

  public deleteElementValue = (id: number) => {
    return this.request<'DOC_ELEMENTS'>(
      '/element/value',
      'DELETE_DOC_ELEMENT_VALUE',
      { method: 'DELETE', pathParam: id, permit: 'elements_values' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | DOC GROUPS
   |----------------------------------------------------------------------------
   */
  public getDocElementGroups = (query?: GetDocElementGroupsQuery) => {
    return this.request<
      'DOC_ELEMENTS',
      DocElementGroup[],
      null,
      GetDocElementGroupsQuery
    >('/element_groups', 'GET_DOC_ELEMENT_GROUPS', {
      query,
      permit: 'element_groups',
    });
  };

  public createElementGroups = (params: CreateElementGroupReq) => {
    return this.request<'DOC_ELEMENTS', DocElementGroup, CreateElementGroupReq>(
      '/element_groups',
      'CREATE_DOC_ELEMENT_GROUP',
      {
        method: 'POST',
        params,
        permit: 'element_groups',
      },
    );
  };

  public updateElementGroup = (id: number, params: UpdateElementGroupReq) => {
    return this.request<'DOC_ELEMENTS', DocElementGroup, UpdateElementGroupReq>(
      '/element_groups',
      'UPDATE_DOC_ELEMENT_GROUP',
      { method: 'PUT', params, pathParam: id, permit: 'element_groups' },
    );
  };

  public deleteElementGroup = (id: number) => {
    return this.request<'DOC_ELEMENTS'>(
      '/element_groups',
      'DELETE_DOC_ELEMENT_GROUP',
      { method: 'DELETE', pathParam: id, permit: 'element_groups' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | CHAPTERS
   |----------------------------------------------------------------------------
   */
  public getDocChapters = (query?: GetDocChaptersQuery) => {
    return this.request<
      'DOC_CHAPTERS',
      DocChapter[],
      null,
      GetDocChaptersQuery
    >('/chapters', 'GET_DOC_CHAPTERS', { query, permit: 'chapters' });
  };

  public getDocChapter = (id: number) => {
    return this.request<'DOC_CHAPTERS', DocChapter>(
      '/chapters',
      'GET_DOC_CHAPTER',
      { pathParam: id, permit: 'chapters' },
    );
  };

  public getDocChaptersByElement = (elementId: number) => {
    return this.request<'DOC_CHAPTERS', DocChapter[]>(
      '/chapters',
      'GET_DOC_CHAPTERS_BY_EL_ID',
      { pathParam: `/elements/${elementId}`, permit: 'chapters' },
    );
  };

  public addDocChapterElem = (id: number, params: AddDocChapterElemReq) => {
    return this.request<'DOC_CHAPTERS', DocChapter, AddDocChapterElemReq>(
      '/chapters',
      'ADD_DOC_CHAPTER_ELEMENTS',
      {
        method: 'POST',
        pathParam: `${id}/elements`,
        params,
        permit: 'chapters',
      },
    );
  };

  public createDocChapter = (params: CreateDocChapterReq) => {
    return this.request<'DOC_CHAPTERS', DocChapter, CreateDocChapterReq>(
      '/chapters',
      'CREATE_DOC_CHAPTER',
      { method: 'POST', params, permit: 'chapters' },
    );
  };

  public updateDocChapter = (id: number, params: UpdateDocChapterReq) => {
    return this.request<'DOC_CHAPTERS', DocChapter, UpdateDocChapterReq>(
      '/chapters',
      'UPDATE_DOC_CHAPTER',
      { method: 'PUT', pathParam: id, params, permit: 'chapters' },
    );
  };

  public deleteDocChapter = (id: number) => {
    return this.request<'DOC_CHAPTERS'>('/chapters', 'DELETE_DOC_CHAPTER', {
      method: 'DELETE',
      pathParam: id,
      permit: 'chapters',
    });
  };

  /*
   |----------------------------------------------------------------------------
   | CHAPTER GROUPS
   |----------------------------------------------------------------------------
   */
  public getDocChapterGroups = (query?: GetDocChapterGroupsQuery) => {
    return this.request<
      'DOC_CHAPTERS',
      DocChapterGroup[],
      GetDocChapterGroupsQuery
    >('/chapter_groups', 'GET_DOC_CHAPTER_GROUPS', {
      query,
      permit: 'chapter_groups',
    });
  };

  public getDocChapterGroup = (id: number) => {
    return this.request<'DOC_CHAPTERS', DocChapterGroup>(
      '/chapter_groups',
      'GET_DOC_CHAPTER_GROUP',
      { pathParam: id, permit: 'chapter_groups' },
    );
  };

  public createDocChapterGroup = (params: CreateDocChapterGroupReq) => {
    return this.request<
      'DOC_CHAPTERS',
      DocChapterGroup,
      CreateDocChapterGroupReq
    >('/chapter_groups', 'CREATE_DOC_CHAPTER_GROUP', {
      method: 'POST',
      params,
      permit: 'chapter_groups',
    });
  };

  public updateDocChapterGroup = (
    id: number,
    params: UpdateDocChapterGroupReq,
  ) => {
    return this.request<
      'DOC_CHAPTERS',
      DocChapterGroup,
      UpdateDocChapterGroupReq
    >('/chapter_groups', 'UPDATE_DOC_CHAPTER_GROUP', {
      method: 'PUT',
      pathParam: id,
      params,
      permit: 'chapter_groups',
    });
  };

  public deleteDocChapterGroup = (id: number) => {
    return this.request<'DOC_CHAPTERS'>(
      '/chapter_groups',
      'DELETE_DOC_CHAPTER_GROUP',
      { method: 'DELETE', pathParam: id, permit: 'chapter_groups' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | TEMPLATES
   |----------------------------------------------------------------------------
   */
  public getDocTemplates = (query?: GetDocTemplatesQuery) => {
    return this.request<
      'DOC_TEMPLATES',
      DocTemplateType[],
      null,
      GetDocTemplatesQuery
    >('/templates', 'GET_DOC_TEMPLATES', { query, permit: 'templates' });
  };

  public getDocTemplate = (id: number) => {
    return this.request<'DOC_TEMPLATES', DocTemplateType>(
      '/templates',
      'GET_DOC_TEMPLATE',
      { pathParam: id, permit: 'templates' },
    );
  };

  public createDocTemplate = (params: CreateDocTemplate) => {
    return this.request<'DOC_TEMPLATES', DocTemplateType>(
      '/templates',
      'CREATE_DOC_TEMPLATE',
      { method: 'POST', params, permit: 'templates' },
    );
  };

  public updateDocTemplate = (id: number, params: UpdateDocTemplateReq) => {
    return this.request<'DOC_TEMPLATES', DocTemplateType>(
      '/templates',
      'UPDATE_DOC_TEMPLATE',
      { method: 'PUT', params, pathParam: id, permit: 'templates' },
    );
  };

  public deleteDocTemplate = (id: number) => {
    return this.request<'DOC_TEMPLATES'>('/templates', 'DELETE_DOC_TEMPLATE', {
      method: 'DELETE',
      pathParam: id,
      permit: 'templates',
    });
  };

  public activateDocTemplate = (id: number) => {
    return this.request<'DOC_TEMPLATES', DocTemplateType>(
      '/templates',
      'ACTIVATE_DOC_TEMPLATE',
      {
        method: 'PUT',
        pathParam: `${id}/activate`,
        permit: 'templates',
      },
    );
  };

  public deactivateDocTemplate = (id: number) => {
    return this.request<'DOC_TEMPLATES', DocTemplateType>(
      '/templates',
      'DEACTIVATE_DOC_TEMPLATE',
      {
        method: 'PUT',
        pathParam: `${id}/deactivate`,
        permit: 'templates',
      },
    );
  };

  public setDocTemplateService = (params: SetTemplateServiceReq) => {
    return this.request<'DOC_TEMPLATES', CreateTemplateService>(
      '/templates/services',
      'SET_DOC_TEMPLATE_SERVICE',
      { method: 'POST', params, permit: 'template_to_service' },
    );
  };

  public deleteDocTemplateService = (id: number) => {
    return this.request<'DOC_TEMPLATES'>(
      '/templates/services',
      'DELETE_DOC_TEMPLATE_SERVICE',
      { method: 'DELETE', pathParam: id, permit: 'template_to_service' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | TEMPLATE GROUP
   |----------------------------------------------------------------------------
   */
  public getDocTemplateGroups = (query?: GetDocTemplateGroupsQuery) => {
    return this.request<
      'DOC_TEMPLATES',
      DocTemplateGroup[],
      null,
      GetDocTemplateGroupsQuery
    >('/template_groups', 'GET_DOC_TEMPLATE_GROUPS', {
      query,
      permit: 'template_groups',
    });
  };

  public getDocTemplateGroup = (id: number) => {
    return this.request<'DOC_TEMPLATES', DocTemplateGroup>(
      '/template_groups',
      'GET_DOC_TEMPLATE_GROUP',
      { pathParam: id, permit: 'template_groups' },
    );
  };

  public createDocTemplateGroup = (params: CreateDocTemplateGroup) => {
    return this.request<
      'DOC_TEMPLATES',
      DocTemplateGroup,
      CreateDocTemplateGroup
    >('/template_groups', 'CREATE_DOC_TEMPLATE_GROUP', {
      method: 'POST',
      params,
      permit: 'template_groups',
    });
  };

  public updateDocTemplateGroup = (
    id: number,
    params: UpdateDocTemplateGroup,
  ) => {
    return this.request<
      'DOC_TEMPLATES',
      DocTemplateGroup,
      UpdateDocTemplateGroup
    >('/template_groups', 'UPDATE_DOC_TEMPLATE_GROUP', {
      method: 'PUT',
      params,
      pathParam: id,
      permit: 'template_groups',
    });
  };

  public deleteDocTemplateGroup = (id: number) => {
    return this.request<'DOC_TEMPLATES'>(
      '/template_groups',
      'DELETE_DOC_TEMPLATE_GROUP',
      { method: 'DELETE', pathParam: id, permit: 'template_groups' },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | COMPILED TEMPLATES
   |----------------------------------------------------------------------------
   */
  public getCompDocTemplates = (query?: GetCompDocTemplatesQuery) => {
    return this.request<
      'DOC_COMPILED_TEMPLATES',
      CompDocTemplate[],
      null,
      GetCompDocTemplatesQuery
    >('/compiled_templates', 'GET_COMP_DOC_TEMPLATES', {
      query,
      permit: 'compiled_templates',
    });
  };

  public getCompTemplate = (id: number) => {
    return this.request<'DOC_COMPILED_TEMPLATES', CompDocTemplate>(
      '/compiled_templates',
      'GET_COMP_DOC_TEMPLATE',
      { pathParam: id, permit: 'compiled_templates' },
    );
  };

  public getCurrentCompTemplate = (id: number) => {
    return this.request<'DOC_COMPILED_TEMPLATES', CompDocTemplate>(
      '/compiled_templates',
      'GET_CURRENT_COMP_DOC_TEMPLATE',
      { pathParam: `current/${id}`, permit: 'compiled_templates' },
    );
  };

  public createCompTemplate = (params: CreateCompDocTemplate) => {
    return this.request<
      'DOC_COMPILED_TEMPLATES',
      CompDocTemplate,
      CreateCompDocTemplate
    >('/compiled_templates', 'CREATE_COMP_DOC_TEMPLATE', {
      method: 'POST',
      params,
      permit: 'compiled_templates',
    });
  };
}
