import * as React from 'react';
import type { SVGProps } from 'react';
const SvgComment = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19 4h-2v9H4v2c0 .55.45 1 1 1h11l4 4V5c0-.55-.45-1-1-1m-4 6V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v14l4-4h10c.55 0 1-.45 1-1"
      />
    </svg>
  );
};
export default SvgComment;
