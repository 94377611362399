import { SpecializationStore } from '@modules/Specialization/specializationStore';

export class SpecializationService extends SpecializationStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getSpecializationsReq = async (query?: GetSpecializationsQuery) => {
    const response =
      await this._store.SpecialisationApi.getSpecializations(query);
    if (response.success && response.data) {
      this.setSpecializations(response.data, response.meta);
    }
    return response;
  };

  public createSpecializationReq = async (params: CreateSpecializationReq) => {
    const response =
      await this._store.SpecialisationApi.createSpecialization(params);
    if (response && response.data) {
      this.addSpecialization(response.data);
    }
    return response;
  };

  public removeSpecializationReq = async (specId: number) => {
    const response =
      await this._store.SpecialisationApi.removeSpecialization(specId);
    if (response.success) {
      this.removeSpecialization(specId);
    }
    return response;
  };

  public updateSpecializationReq = async (specId: number, title: string) => {
    const response = await this._store.SpecialisationApi.updateSpecialization(
      specId,
      title,
    );
    if (response.success && response.data) {
      this.updateSpecialization(response.data);
    }
    return response;
  };
}
