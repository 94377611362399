import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckBox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.833 2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.925 0 1.667-.75 1.667-1.667V4.167c0-.917-.742-1.667-1.667-1.667m-7.5 11.667L4.167 10l1.175-1.175 2.991 2.983 6.325-6.325 1.175 1.184z"
      />
    </svg>
  );
};
export default SvgCheckBox;
