import { CalendarStore } from '@modules/Calendar/calendar.store';
import { CalendarFilter } from '@modules/Calendar/calendar.filter';
import { Calendar } from './modules/calendar';
import { Scheduler } from './modules/scheduler';

export class CalendarService {
  public calendarStore: CalendarStore;
  public calendarFilter: CalendarFilter;

  public calendar: Calendar;
  public scheduler: Scheduler;

  constructor(protected _store: Store) {
    this.calendarStore = new CalendarStore(_store);
    this.calendarFilter = new CalendarFilter(_store);

    this.calendar = new Calendar(_store);
    this.scheduler = new Scheduler(_store);
  }

  public getDirections = async (patientId?: number) => {
    const res = await this._store.DocDirectionsApi.getDocDirections({
      'filter[patient_id]': patientId,
      'filter[status]': 'new',
      'filter[service_type]': 'clinic_service',
    });

    if (res.success && res.data) {
      this.calendarStore.setDirections(res.data);
    }

    return res;
  };

  public getGuarantees = async (patientId?: number) => {
    const res = await this._store.InsuranceApi.getGuarantees({
      'filter[patient_id]': patientId,
      'filter[status]': ['current'],
      'filter[service_type]': 'clinic_service',
    });

    if (res.success && res.data) {
      this.calendarStore.setGuarantees(res.data);
    }

    return res;
  };

  public getPackages = async (patientId?: number) => {
    const res = await this._store.PatientPkgServiceApi.getPatientPkgServices({
      'filter[patient_id]': patientId,
      'filter[status]': 'active',
    });

    if (res.success && res.data) {
      this.calendarStore.setPackages(res.data);
    }

    return res;
  };

  public findPackageServiceById = (id: number) => {
    for (const pkg of this.calendarStore.packages) {
      for (const service of pkg.services) {
        if (service.id === id) return service;
      }
    }
    return null;
  };

  public findServiceById = (id: number) => {
    for (const pkg of this.calendarStore.packages) {
      for (const service of pkg.services) {
        if (service.service.id === id) return service;
      }
    }
    return null;
  };

  // Check is service in package and can de added to appointment
  public checkServiceInPackages = (id: number) => {
    return this.calendarStore.packages.some((pkg) => {
      return pkg.services.some((service) => {
        return service.service.id === id && service.canAdd;
      });
    });
  };
}
