import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTransport = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={16}
      fill="none"
      viewBox="0 0 22 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19 4h-3V0H2C.9 0 0 .9 0 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2V8zM5 14.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m13.5-9L20.46 8H16V5.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5"
      />
    </svg>
  );
};
export default SvgTransport;
