import queryString from 'query-string';
import {
  BLOB_RESPONSE,
  DEFAULT_ERROR,
  isHiddenErrors,
  isHiddenParams,
  patch405Error,
  PERMISSION_DENIED,
  SUCCESS_204_RESPONSE,
} from '@modules/Api/consts';
import { EnvService } from '@modules/Env';
import { PermissionInterceptor, TokenInterceptor } from './interceptors';

export class FetchService {
  protected _store: Store;
  protected readonly _url = EnvService.env.api.url;
  private readonly _headers = { 'Content-Type': 'application/json' };
  private readonly _activeRequests: ActiveRequests = {};
  private _timeoutId: NodeJS.Timeout | undefined;

  constructor(store: Store) {
    this._store = store;
  }

  public get request(): RequestExecutor {
    return async (path, loadingKey, config = {}) => {
      const { authRequired = true, permit, method = 'GET' } = config;
      if (authRequired) {
        const res = await TokenInterceptor.run();
        if (!res.success) {
          this._store.ErrorService.fetchError(loadingKey, res.errors, config);
          return res;
        }
      }
      if (permit) {
        if (!PermissionInterceptor.run(method, permit)) {
          this._store.LoggerService.send.permissionCrumb(
            `Permission denied: [${method}:${permit}]`,
            { method, permit, loadingKey, config },
          );
          return PERMISSION_DENIED(loadingKey);
        }
      }
      return this._request(path, loadingKey, config);
    };
  }

  private async _request<
    S extends ServicesKeys,
    R = BaseObject,
    B = BaseObject,
    Q = BaseObject,
  >(
    path: ServicePath<S>,
    loadingKey: LoadingKey,
    config: RequestConfig<B, Q> = {},
  ): Promise<HttpResponse<R>> {
    const {
      method = 'GET',
      overrideHeaders,
      headers = {},
      query = {},
      pathParam,
      params,
      blob,
      delay,
      version,
    } = config;

    const { signal } = this._registerRequest(loadingKey, delay);
    const queryString = this._getQueryString(query as Record<string, unknown>);
    const urlPath = this._configurePath(path, pathParam);
    const URL = this._configureUrl(urlPath, queryString, version);
    const isFormData = params instanceof FormData;

    const requestConfig: RequestInit = {
      method,
      signal,
      mode: 'cors' as RequestMode,
      headers: this._configureHeaders(headers, overrideHeaders, isFormData),
      body: this._configureBody(method, params, isFormData),
    };

    try {
      const response = await fetch(URL, requestConfig);

      if (this._timeoutId) {
        clearTimeout(this._timeoutId);
      }
      if (response.status === 401) {
        this._store.AuthService.setLogout();
      }
      if (response.status === 204) {
        this._unregisterRequest(loadingKey);
        return SUCCESS_204_RESPONSE;
      }

      if (blob && response.ok) {
        this._unregisterRequest(loadingKey);
        const file = await response.blob();
        const filename = this._getFilename(response);
        return BLOB_RESPONSE(file, filename);
      }

      const json = (await response.json()) as HttpResponse<R>;
      this._unregisterRequest(loadingKey);

      if (response.status === 405) {
        json.errors = patch405Error(json);
      }

      if (json.errors?.length) {
        json.success = false;
        if (!isHiddenErrors(loadingKey, response.status)) {
          this._store.ErrorService.fetchError(loadingKey, json.errors, {
            method,
            URL,
            pathParam,
            query,
            params: isHiddenParams(loadingKey) ? undefined : params,
            isAuth: this._store.AuthService.isAuth,
          });
        }
      } else {
        json.success = true;
        this._store.ErrorService.clearError(loadingKey);
      }

      return json;
    } catch (error) {
      console.error(error);
      this._unregisterRequest(loadingKey);
      this._store.ErrorService.fetchException(error, loadingKey, {
        error,
        URL,
        method,
        query,
        params: isHiddenParams(loadingKey) ? undefined : params,
        isAuth: this._store.AuthService.isAuth,
      });
      return DEFAULT_ERROR(error);
    }
  }

  private _configurePath = (path: string, pathParameter?: string | number) => {
    if (!pathParameter) return path;
    return `${path}/${pathParameter}`;
  };

  private _configureUrl = (
    urlPath: string,
    queryString: string,
    version?: number,
  ) => {
    let url = this._url;
    if (version) {
      const segments = url.split('/');
      segments[segments.length - 1] = `v${version}`;
      url = segments.join('/');
    }
    return queryString ? `${url + urlPath}?${queryString}` : `${url + urlPath}`;
  };

  private _configureBody(
    method: HttpMethods,
    params: any,
    isFormData: boolean,
  ) {
    // POST|PUT can't be without body
    if (method === 'POST' && !params) {
      return JSON.stringify({});
    }
    if (method === 'PUT' && !params) {
      return JSON.stringify({});
    }
    if (isFormData) {
      return params;
    }
    return JSON.stringify(params);
  }

  private _configureHeaders = (
    customHeaders: BaseObject<string, string>,
    override?: boolean,
    isFormData?: boolean,
  ): RequestInit['headers'] => {
    if (override) return customHeaders;
    const token = this._store.AuthService.accessToken;
    if (isFormData) {
      return { ...(token && { Authorization: token }) };
    }
    return {
      ...this._headers,
      ...customHeaders,
      ...(token && { Authorization: token }),
    };
  };

  private _getQueryString = <Q extends Record<string, unknown>>(query: Q) => {
    const _query = queryString.stringify(query, { arrayFormat: 'comma' });
    if (_query) return _query;
    return '';
  };

  private _registerRequest = (
    loadingKey: LoadingKey,
    delay?: number,
  ): AbortController => {
    const controller = this._activeRequests[loadingKey];
    if (controller) this._unregisterController(loadingKey, delay);
    this._activeRequests[loadingKey] = new AbortController();
    this._store.LoadingService.setLoading(loadingKey, true);
    return <AbortController>this._activeRequests[loadingKey];
  };

  private _unregisterController = (loadingKey: LoadingKey, delay?: number) => {
    const controller = this._activeRequests[loadingKey];
    if (delay && controller) {
      this._timeoutId = setTimeout(() => controller.abort(), delay);
      return;
    }
    if (controller) controller.abort();
  };

  private _unregisterRequest = (loadingKey: LoadingKey) => {
    this._store.LoadingService.setLoading(loadingKey, false);
    this._activeRequests[loadingKey] = undefined;
  };

  private _getFilename = (response: Response) => {
    let fileName = 'unknown';

    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    const disposition = response.headers.get('Content-Disposition');
    if (!disposition) return fileName;

    if (utf8FilenameRegex.test(disposition)) {
      const name = utf8FilenameRegex.exec(disposition)?.[1];
      if (name) fileName = decodeURIComponent(name);
    } else {
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) fileName = matches[2];
      }
    }
    return fileName;
  };
}
