import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBallot = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <path
        d="M10 6.5h5v-2h-5zm0 7h5v-2h-5zm6 4.5H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2M3 8h5V3H3zm1-4h3v3H4zM3 15h5v-5H3zm1-4h3v3H4z"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};
export default SvgBallot;
