import { AppointmentStore } from '@modules/Administrator/Appointment/appointment.store';
import { AppointmentBridge } from '@modules/Administrator/Appointment/appointment.bridge';
import uniqueId from 'lodash/uniqueId';

export class AppointmentService {
  public store: AppointmentStore;

  public readonly storeId = uniqueId('appointment-');
  private readonly _bridge: AppointmentBridge;

  constructor(protected _store: Store) {
    this.store = new AppointmentStore();
    this._bridge = new AppointmentBridge(this.store, this.storeId);
  }

  public subscribe = () => {
    this._store.WsService.on(
      'appointments.get_list',
      this._bridge.getAppointments,
    );
    this._store.WsService.on(
      'appointments.get_history',
      this._bridge.getAppointmentHistory,
    );
    this._store.WsService.on(
      'appointments.change_status',
      this._bridge.changeStatus,
    );
    this._store.WsService.on(
      'appointments.create',
      this._bridge.createAppointment,
    );
    this._store.WsService.on(
      'appointments.update',
      this._bridge.updateAppointment,
    );
    this._store.WsService.on(
      'appointments.confirm_batch',
      this._bridge.updateBatchAppointment,
    );
    this._store.WsService.on('appointments.unsubscribe', this.unsubscribe);
  };

  public unsubscribe = (data: WsResponse<StatusOnly>) => {
    if (!data.storage || data.storage !== this.storeId) return;

    this._store.WsService.off(
      'appointments.get_list',
      this._bridge.getAppointments,
    );
    this._store.WsService.off(
      'appointments.get_history',
      this._bridge.getAppointmentHistory,
    );
    this._store.WsService.off(
      'appointments.change_status',
      this._bridge.changeStatus,
    );
    this._store.WsService.off(
      'appointments.create',
      this._bridge.createAppointment,
    );
    this._store.WsService.off(
      'appointments.update',
      this._bridge.updateAppointment,
    );
    this._store.WsService.off('appointments.unsubscribe', this.unsubscribe);
  };

  public getAppointmentsReq = (params?: GetAppointmentsReq) => {
    this._store.WsApi.appointments.getAppointments(params, {
      id: this.storeId,
    });
  };

  public getAppointmentReq = (id: number) => {
    this._store.WsApi.appointments.getAppointment(id);
  };

  public getAppointmentHistoryReq = (params: GetAppointmentHistoryReq) => {
    this._store.WsApi.appointments.getAppointmentHistory(params);
  };

  public updateAppointmentsReq = (id: number, params: UpdateAppointmentReq) => {
    this._store.WsApi.appointments.updateAppointment(params, {
      id,
    });
  };

  public changeStatusReq = (
    params: ChangeStatusAppointmentReq,
    id?: number,
  ) => {
    this._store.WsApi.appointments.changeStatusAppointment(params, id);
  };

  public getAppointmentReportReq = (query?: GetAppointmentReportQuery) => {
    return this._store.AppointmentApi.schedulerApi.getAppointmentReport(
      query as GetAppointmentReportQuery,
    );
  };

  public unsubscribeReq = () => {
    this._store.WsApi.appointments.unsubscribeAppointments(this.storeId);
  };
}
