import { action, makeObservable, observable } from 'mobx';
import { Service } from '@modules/Services/mapper/Service';
import { ServiceStaff } from '@modules/Services/mapper/ServiceStaff';
import { Cabinet } from '@modules/Cabinets/mapper/Cabinet';
import { ServicesTree } from './mapper/ServiceTree';

export class ServicesStore {
  protected readonly _store: Store;
  @observable public service: Service | null = null;
  @observable public services: Service[] = [];
  @observable public servicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public serviceCategories: ServiceCategory[] = [];
  @observable public serviceCategoriesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public serviceRelationTypes: ServiceRelationType[] = [];
  @observable public serviceRelationType: ServiceRelationType | null = null;

  @observable public serviceUsers: ServiceStaff[] = [];
  @observable public serviceUsersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public serviceCabinets: Cabinet[] = [];
  @observable public serviceCabinetsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public serviceRelatedServices: Service[] = [];
  @observable public serviceRelatedServicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public serviceDocTemplates: DocTemplateType[] = [];
  @observable public serviceDocTemplatesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public servicesTree: ServicesTree = new ServicesTree();

  constructor(store: Store) {
    makeObservable(this);
    this._store = store;
  }

  @action
  public setServices = (services: ServiceResponse[], meta: ResponseMeta) => {
    this.services = services.map((e) => new Service(e));
    this.servicesMeta = meta;
  };

  @action
  public setService = (service: ServiceResponse | null) => {
    this.service = service ? new Service(service) : null;

    if (service) {
      this.setDocTmplToService(service.templates);
    }
  };

  @action
  public setServiceUsers = (
    users: ServiceUserResponse[],
    meta: ResponseMeta,
  ) => {
    this.serviceUsers = users.map((e) => new ServiceStaff(e));
    this.serviceUsersMeta = meta;
  };

  @action
  public setServiceRelatedServices = (
    services: ServiceResponse[],
    meta: ResponseMeta,
  ) => {
    this.serviceRelatedServices = services.map((e) => new Service(e));
    this.serviceRelatedServicesMeta = meta;
  };

  @action
  public addServiceRelatedService = (service: ServiceResponse) => {
    this.serviceRelatedServices = [
      new Service(service),
      ...this.serviceRelatedServices,
    ];
  };

  @action
  public removeServiceRelatedService = (relatedServiceId: number) => {
    this.serviceRelatedServices = this.serviceRelatedServices.filter(
      (e) => e.id !== relatedServiceId,
    );
  };

  @action
  public addServices = (services: ServiceResponse[]) => {
    this.services = [...services.map((e) => new Service(e)), ...this.services];
  };

  @action
  public updateService = (
    updatedService: ServiceResponse,
    keys: (keyof Service)[],
  ) => {
    const service = this.services.find((e) => e.id === updatedService.id);
    if (service) {
      keys.forEach((key) => {
        service.setValue(key, updatedService[key as keyof ServiceResponse]);
      });
    }
    if (this.service?.id === updatedService.id) {
      keys.forEach((key) => {
        this.service?.setValue(
          key,
          updatedService[key as keyof ServiceResponse],
        );
      });
    }
  };

  @action
  public updateServiceStatus = (data: ServiceResponse[]) => {
    data.forEach((service) => {
      this.updateService(service, ['status']);
    });
  };

  @action
  public addServiceUser = (data: AddServiceUserResponse) => {
    this.serviceUsers = [new ServiceStaff(data.user), ...this.serviceUsers];
  };

  @action
  public removeServiceUser = (id: number) => {
    this.serviceUsers = this.serviceUsers.filter((e) => e.id !== id);
  };

  @action
  public deleteService = (id: number) => {
    this.services = this.services.filter((e) => e.id !== id);
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE CABINETS
  |----------------------------------------------------------------------------
  */
  @action
  public setServiceCabinets = (
    cabinets: CabinetResponse[],
    meta: ResponseMeta,
  ) => {
    this.serviceCabinets = cabinets.map((e) => new Cabinet(e));
    this.serviceCabinetsMeta = meta;
  };

  @action
  public removeServiceCabinet = (cabinetId: number) => {
    this.serviceCabinets = this.serviceCabinets.filter(
      (e) => e.id !== cabinetId,
    );
  };

  @action
  public addServiceCabinet = (data: ServiceCabinetResponse) => {
    this.serviceCabinets = [...this.serviceCabinets, new Cabinet(data.cabinet)];
  };

  @action
  public clearServiceCabinets = () => {
    this.serviceCabinets = [];
    this.serviceCabinetsMeta = {
      totalPages: 0,
      totalRecords: 0,
    };
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE CATEGORIES
  |----------------------------------------------------------------------------
  */

  @action
  public setServiceCategories = (
    categories: ServiceCategory[],
    meta: ResponseMeta,
  ) => {
    this.serviceCategories = categories;
    this.serviceCategoriesMeta = meta;
  };

  @action
  public setServiceCategoriesTree = (categories: ServiceCategoryTree[]) => {
    this.servicesTree.setCategories(categories);
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE RELATION TYPE
  |----------------------------------------------------------------------------
  */
  @action
  public setServiceRelationTypes = (relationTypes: ServiceRelationType[]) => {
    this.serviceRelationTypes = relationTypes;
  };

  @action
  public setServiceRelationType = (
    relationType: ServiceRelationType | null,
  ) => {
    this.serviceRelationType = relationType;
  };

  @action
  public addServiceRelationType = (relationType: ServiceRelationType) => {
    this.serviceRelationTypes = [...this.serviceRelationTypes, relationType];
  };

  @action
  public updateServiceRelationType = (
    updatedRelationType: ServiceRelationType,
  ) => {
    const type = this.serviceRelationTypes.find(
      (e) => e.id === updatedRelationType.id,
    );
    if (type) {
      type.title = updatedRelationType.title;
      type.default_commission = updatedRelationType.default_commission;
    }
  };

  @action
  public deleteServiceRelationType = (id: number) => {
    this.serviceRelationTypes = this.serviceRelationTypes.filter(
      (e) => e.id !== id,
    );
  };

  /*
  |----------------------------------------------------------------------------
  | TEMPLATE TO SERVICE
  |----------------------------------------------------------------------------
  */
  @action
  public setDocTmplToService = (templates: DocTemplateType[] | null) => {
    this.serviceDocTemplates = templates ? templates : [];
  };

  @action
  public addDocTmplToService = (params: AddTemplateToService) => {
    const templates = this._store.DocumentsService.template.docTemplates;
    const template = templates.find((t) => t.id === params.template_id);

    if (template) {
      this.serviceDocTemplates = [
        { ...template, record_id: params.record_id },
        ...this.serviceDocTemplates,
      ];
    }
  };

  @action
  public deleteDocTmplToService = (id: number) => {
    this.serviceDocTemplates = this.serviceDocTemplates.filter(
      (t) => t.record_id !== id,
    );
  };
}
