import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLine = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#6B7280" d="M20 9H4v2h16zM4 15h16v-2H4z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgLine;
