import { FetchService } from '@modules/Api';

export class DeviceApi extends FetchService {
  public getDevices = (query?: GetDevices) => {
    return this.request<'DEVICE', Device[], null, GetDevices>(
      '/device',
      'GET_DEVICES',
      { query },
    );
  };

  public getDevice = (deviceId: number) => {
    return this.request<'DEVICE', Device>('/device', 'GET_DEVICE', {
      pathParam: deviceId,
    });
  };

  public createDevice = (params: CreateDevice) => {
    return this.request<'DEVICE', Device, CreateDevice>(
      '/device',
      'CREATE_DEVICE',
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateDevice = (deviceId: number, params: UpdateDevice) => {
    return this.request<'DEVICE', Device, UpdateDevice>(
      '/device',
      'UPDATE_DEVICE',
      {
        method: 'PUT',
        pathParam: deviceId,
        params,
      },
    );
  };

  public activateDevice = (deviceId: number) => {
    return this.request<'DEVICE', Device>('/device', 'ACTIVATE_DEVICE', {
      method: 'PUT',
      pathParam: `${deviceId}/activate`,
    });
  };

  public deactivateDevice = (deviceId: number) => {
    return this.request<'DEVICE', Device>('/device', 'DEACTIVATE_DEVICE', {
      method: 'PUT',
      pathParam: `${deviceId}/deactivate`,
    });
  };

  public createDeviceApiKey = (deviceId: number) => {
    return this.request<'DEVICE', DeviceApiKey>(
      '/device',
      'CREATE_DEVICE_API_KEY',
      {
        method: 'PUT',
        pathParam: `${deviceId}/api_key/create`,
      },
    );
  };

  public deleteDeviceApiKey = (deviceId: number, apiKey: number) => {
    return this.request<'DEVICE', Device>('/device', 'DELETE_DEVICE_API_KEY', {
      method: 'PUT',
      pathParam: `${deviceId}/api_key/${apiKey}/delete`,
    });
  };

  public downloadDeviceProgram = (type: DeviceType) => {
    return this.request<'DEVICE', BlobResponse>(
      '/device',
      'DOWNLOAD_DEVICE_PROGRAM',
      {
        pathParam: `${type}/download_latest`,
        blob: true,
      },
    );
  };
}
