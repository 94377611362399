import { reaction } from 'mobx';
import { Store } from '@stores';

const TIME_INTERVAL = 30e3; // 60sec

class HeartbeatManager {
  private _ws = Store.WsService;
  private _pingPongId?: NodeJS.Timeout;
  private _reconnectId?: NodeJS.Timeout;

  public handleHeartbeat = () => {
    this.clearTimeout();
    if (this._ws.connection?.readyState !== 1) return;
    this._ws.sendAuthentication();
    this._pingPongId = setInterval(this.handleHeartbeat, TIME_INTERVAL);
  };

  public reconnect = () => {
    if (this._ws.connection?.readyState !== 1) {
      this.clearTimeout();
      this.clearReconnectTimeout();
      this._reconnectId = setTimeout(() => {
        this._ws.connect({ callback: this.handleHeartbeat });
      }, 1e3);
    }
  };

  public clearTimeout() {
    if (!this._pingPongId) return;
    clearTimeout(this._pingPongId);
    this._pingPongId = undefined;
  }

  public clearReconnectTimeout() {
    if (!this._reconnectId) return;
    clearTimeout(this._reconnectId);
    this._reconnectId = undefined;
  }
}

const heartbeatManager = new HeartbeatManager();

export const profileReactions = reaction(
  () => Store.ProfileService.profile,
  (profile) => {
    if (profile) {
      heartbeatManager.clearTimeout();
      Store.WsService.connect({ callback: heartbeatManager.handleHeartbeat });
      Store.WsService.on('ws_close' as any, heartbeatManager.reconnect);
    }
    if (!profile) {
      console.log('!profile');
      Store.WsService.disconnect();
      heartbeatManager.clearTimeout();
      heartbeatManager.clearReconnectTimeout();
      Store.WsService.off('ws_close' as any, heartbeatManager.reconnect);
    }
  },
);
