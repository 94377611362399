import React, { memo } from 'react';
import Logo from '@assets/img/logo/Logo.svg';
import LanguageSwitcher from '@components/Header/components/TopbarRight/LanguageSwitcher';
import { Link } from 'react-router-dom';
import { Text } from '@components/UI/Text';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation('init');
  return (
    <div className="py-4 flex items-center justify-between">
      <div className="pr-8 border-r border-blueGray-500">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="flex-1 py-2 pl-8">
        <Text className="text-white text-2xl font-600">{t('title')}</Text>
      </div>
      <div className="py-2">
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default memo(Header);
