import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataReport = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M146.863 28.1834C126.305 13.4193 91.2115 9.60128 72.9048 15.7738C39.89 26.9136 34.5956 48.5605 34.9528 67.9132C35.3101 87.2658 60.1164 103.885 66.7194 120.802C73.3224 137.718 85.4307 160.458 108.817 153.494C132.202 146.529 131.2 121.348 138.285 104.346C145.37 87.3433 163.9 69.9104 162.893 54.3026C162.191 43.4038 155.946 34.7305 146.863 28.1834Z"
        fill="#3B82F6"
      />
      <path
        opacity="0.7"
        d="M146.863 28.1834C126.305 13.4193 91.2115 9.60128 72.9048 15.7738C39.89 26.9136 34.5956 48.5605 34.9528 67.9132C35.3101 87.2658 60.1164 103.885 66.7194 120.802C73.3224 137.718 85.4307 160.458 108.817 153.494C132.202 146.529 131.2 121.348 138.285 104.346C145.37 87.3433 163.9 69.9104 162.893 54.3026C162.191 43.4038 155.946 34.7305 146.863 28.1834Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M90.8112 42.6936H34.2641C32.2316 42.6936 30.5839 44.3413 30.5839 46.3739V87.1797C30.5839 89.2123 32.2316 90.86 34.2641 90.86H90.8112C92.8438 90.86 94.4915 89.2123 94.4915 87.1797V46.3739C94.4915 44.3413 92.8438 42.6936 90.8112 42.6936Z"
        fill="black"
      />
      <path
        d="M89.2274 40.1584H32.6803C30.6477 40.1584 29 41.8062 29 43.8387V84.6446C29 86.6771 30.6477 88.3248 32.6803 88.3248H89.2274C91.2599 88.3248 92.9076 86.6771 92.9076 84.6446V43.8387C92.9076 41.8062 91.2599 40.1584 89.2274 40.1584Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6612 62.1885L35.999 66.622C34.8215 62.9963 35.1323 59.0513 36.8629 55.6546C38.5935 52.258 41.6023 49.6877 45.2277 48.509C46.6565 48.0306 48.1546 47.7921 49.6612 47.8031V62.1885Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6611 62.1885L58.1107 73.8104C55.027 76.0495 51.1802 76.9723 47.4163 76.3758C43.6524 75.7793 40.2795 73.7123 38.0392 70.6294C37.1425 69.4155 36.4531 68.0613 35.9989 66.622L49.6611 62.1885Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6611 62.1883L63.3319 57.7461C64.3056 60.6283 64.3243 63.7475 63.3853 66.6412C62.4464 69.5348 60.5997 72.0488 58.1193 73.8102L49.6611 62.1883Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6611 62.1884V47.8117C52.7052 47.7771 55.6794 48.725 58.1421 50.5146C60.6048 52.3043 62.4248 54.8403 63.3319 57.7463L49.6611 62.1884Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.683 71.1416C54.6324 71.1416 58.6448 67.1293 58.6448 62.1798C58.6448 57.2303 54.6324 53.218 49.683 53.218C44.7335 53.218 40.7212 57.2303 40.7212 62.1798C40.7212 67.1293 44.7335 71.1416 49.683 71.1416Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7767 46.9336H68.084V48.565H87.7767V46.9336Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.5411 50.4243H68.084V52.0557H83.5411V50.4243Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.4433 53.9153H68.084V55.5467H79.4433V53.9153Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.2255 57.4062H68.084V59.0376H84.2255V57.4062Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.7544 60.897H68.084V62.5283H75.7544V60.897Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7767 64.3921H68.084V66.0235H87.7767V64.3921Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5856 67.8831H68.084V69.5144H82.5856V67.8831Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7562 71.374H68.084V73.0054H80.7562V71.374Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7767 74.8647H68.084V76.4961H87.7767V74.8647Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3521 79.6211H57.6155"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1914 81.7734H59.7765"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.609 79.6211H83.8725"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.4484 81.7734H86.0334"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M91.1256 110.949H42.5632C40.5307 110.949 38.8829 112.596 38.8829 114.629V149.417C38.8829 151.45 40.5307 153.098 42.5632 153.098H91.1256C93.1582 153.098 94.8059 151.45 94.8059 149.417V114.629C94.8059 112.596 93.1582 110.949 91.1256 110.949Z"
        fill="black"
      />
      <path
        d="M89.2231 108.736H40.6607C38.6282 108.736 36.9805 110.384 36.9805 112.417V147.205C36.9805 149.237 38.6282 150.885 40.6607 150.885H89.2231C91.2557 150.885 92.9034 149.237 92.9034 147.205V112.417C92.9034 110.384 91.2557 108.736 89.2231 108.736Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 128.773H48.2711"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 130.461H52.6099"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 132.144H48.2711"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 133.827H51.5726"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 135.514H48.2711"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8115 137.197H53.9916"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 128.773H66.952"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 130.461H71.2908"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 132.144H66.952"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 133.827H70.2535"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 135.514H66.952"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4924 137.197H72.6726"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 128.773H85.6333"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 130.461H89.9678"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 132.144H85.6333"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 133.827H88.9304"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 135.514H85.6333"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1737 137.197H91.3538"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.7705 123.238H40.3335V126.126H54.7705V123.238Z"
        fill="#1E293B"
      />
      <path
        d="M73.7789 123.238H59.3419V126.126H73.7789V123.238Z"
        fill="#1E293B"
      />
      <path
        d="M92.7874 123.238H78.3503V126.126H92.7874V123.238Z"
        fill="#1E293B"
      />
      <path
        d="M57.0216 123.07V137.684"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.8752 123.07V137.684"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.2175 116.394L49.3425 115.378C49.543 115.996 49.5558 116.659 49.3793 117.284C49.2029 117.909 48.8451 118.468 48.3512 118.889C47.8574 119.311 47.2496 119.577 46.6046 119.653C45.9597 119.729 45.3066 119.613 44.728 119.318C44.1493 119.023 43.671 118.564 43.3535 117.997C43.036 117.431 42.8937 116.783 42.9444 116.135C42.995 115.488 43.2366 114.87 43.6383 114.36C44.0401 113.85 44.5842 113.47 45.2017 113.269C45.5292 113.16 45.8724 113.106 46.2175 113.11V116.394Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M46.2177 116.394V113.11C46.9133 113.101 47.5931 113.317 48.1561 113.726C48.7191 114.135 49.1352 114.714 49.3427 115.378L46.2177 116.394Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M77.8549 116.394L80.9799 115.378C81.1807 115.996 81.1937 116.659 81.0173 117.285C80.8409 117.91 80.4831 118.469 79.989 118.891C79.495 119.312 78.8869 119.578 78.2418 119.654C77.5966 119.731 76.9433 119.614 76.3645 119.319C75.7858 119.024 75.3076 118.564 74.9903 117.997C74.6731 117.43 74.5311 116.781 74.5824 116.134C74.6336 115.486 74.8758 114.868 75.2783 114.358C75.6807 113.848 76.2254 113.469 76.8434 113.269C77.1694 113.16 77.5112 113.106 77.8549 113.11V116.394Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M77.8551 116.394V113.11C78.5509 113.1 79.231 113.316 79.7942 113.725C80.3573 114.134 80.7733 114.714 80.9801 115.378L77.8551 116.394Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M56.7719 116.394V119.678C55.9009 119.678 55.0655 119.332 54.4496 118.716C53.8337 118.1 53.4877 117.265 53.4877 116.394C53.4877 115.523 53.8337 114.687 54.4496 114.072C55.0655 113.456 55.9009 113.11 56.7719 113.11V113.11V116.394Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M56.7719 116.394V113.11C57.6429 113.11 58.4783 113.456 59.0942 114.072C59.7101 114.687 60.0561 115.523 60.0561 116.394C60.0561 117.265 59.7101 118.1 59.0942 118.716C58.4783 119.332 57.6429 119.678 56.7719 119.678V116.394Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M67.3094 116.394L70.4344 117.41C70.301 117.82 70.0881 118.2 69.8078 118.528C69.5275 118.856 69.1853 119.126 68.8008 119.322C68.4163 119.518 67.997 119.636 67.5668 119.67C67.1366 119.704 66.7039 119.652 66.2936 119.519C65.8832 119.385 65.5031 119.173 65.175 118.892C64.8469 118.612 64.5772 118.27 64.3814 117.885C64.1855 117.501 64.0673 117.081 64.0335 116.651C63.9997 116.221 64.051 115.788 64.1844 115.378C64.3919 114.714 64.808 114.135 65.371 113.726C65.934 113.317 66.6138 113.101 67.3094 113.11V116.394Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M67.3093 116.394V113.11C67.741 113.109 68.1685 113.194 68.5675 113.358C68.9664 113.523 69.329 113.765 69.6344 114.07C69.9399 114.375 70.1822 114.737 70.3475 115.136C70.5128 115.535 70.5979 115.962 70.5979 116.394C70.5996 116.739 70.5444 117.082 70.4343 117.41L67.3093 116.394Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M88.401 116.394L91.526 117.41C91.3926 117.82 91.1796 118.2 90.8993 118.528C90.619 118.856 90.2769 119.126 89.8924 119.322C89.5079 119.518 89.0885 119.636 88.6583 119.67C88.2281 119.704 87.7955 119.652 87.3851 119.519C86.9747 119.385 86.5947 119.173 86.2666 118.892C85.9385 118.612 85.6688 118.27 85.4729 117.885C85.2771 117.501 85.1589 117.081 85.1251 116.651C85.0913 116.221 85.1426 115.788 85.276 115.378C85.4834 114.714 85.8995 114.135 86.4625 113.726C87.0255 113.317 87.7053 113.101 88.401 113.11V116.394Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M88.4009 116.394V113.11C88.8323 113.109 89.2597 113.194 89.6584 113.358C90.0571 113.523 90.4194 113.765 90.7245 114.07C91.0296 114.375 91.2715 114.738 91.4363 115.136C91.6011 115.535 91.6857 115.962 91.6851 116.394C91.6884 116.739 91.6345 117.082 91.5259 117.41L88.4009 116.394Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M89.0467 146.21H86.1714V147.183H89.0467V146.21Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.8529 141.351H82.9775V147.183H85.8529V141.351Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.6632 143.447H79.7878V147.188H82.6632V143.447Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.4693 140.529H76.594V147.183H79.4693V140.529Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2755 145.466H73.4001V147.188H76.2755V145.466Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0817 144.941H70.2063V147.183H73.0817V144.941Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8874 142.698H67.0121V147.188H69.8874V142.698Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.6936 146.439H63.8182V147.188H66.6936V146.439Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4997 141.351H60.6244V147.183H63.4997V141.351Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.3059 142.474H57.4305V147.187H60.3059V142.474Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1163 146.959H54.241V147.183H57.1163V146.959Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9225 146.886H51.0471V147.183H53.9225V146.886Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.7286 143.447H47.8533V147.188H50.7286V143.447Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5348 146.439H44.6594V147.188H47.5348V146.439Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3409 145.69H41.4656V147.188H44.3409V145.69Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M166.72 74.4561H110.173C108.14 74.4561 106.492 76.1038 106.492 78.1363V118.942C106.492 120.975 108.14 122.622 110.173 122.622H166.72C168.752 122.622 170.4 120.975 170.4 118.942V78.1363C170.4 76.1038 168.752 74.4561 166.72 74.4561Z"
        fill="black"
      />
      <path
        d="M164.503 71.9248H107.956C105.923 71.9248 104.276 73.5725 104.276 75.6051V116.411C104.276 118.443 105.923 120.091 107.956 120.091H164.503C166.535 120.091 168.183 118.443 168.183 116.411V75.6051C168.183 73.5725 166.535 71.9248 164.503 71.9248Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.159 109.61H160.844V113.286H164.159V109.61Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.474 91.2302H157.16V113.282H160.474V91.2302Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.789 99.1504H153.475V113.286H156.789V99.1504Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.109 88.1226H149.795V113.286H153.109V88.1226Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.425 106.782H146.11V113.286H149.425V106.782Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.74 104.802H142.426V113.282H145.74V104.802Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.06 96.3223H138.745V113.286H142.06V96.3223Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.375 110.458H135.061V113.286H138.375V110.458Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.691 91.2302H131.376V113.282H134.691V91.2302Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.01 95.4744H127.696V113.286H131.01V95.4744Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.326 112.434H124.011V113.282H127.326V112.434Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.641 112.154H120.327V113.286H123.641V112.154Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.957 99.1504H116.642V113.286H119.957V99.1504Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.276 110.458H112.962V113.286H116.276V110.458Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.592 107.63H109.277V113.286H112.592V107.63Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.322831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.374 81.9888H109.708V84.7866H164.374V81.9888Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeMiterlimit="10"
      />
      <path
        d="M132.977 82.1909H124.11V84.5799H132.977V82.1909Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeMiterlimit="10"
      />
      <path
        d="M149.988 82.1909H133.334V84.5799H149.988V82.1909Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M159.747 22.4199H115.394C113.361 22.4199 111.714 24.0676 111.714 26.1002V57.7161C111.714 59.7487 113.361 61.3964 115.394 61.3964H159.747C161.779 61.3964 163.427 59.7487 163.427 57.7161V26.1002C163.427 24.0676 161.779 22.4199 159.747 22.4199Z"
        fill="black"
      />
      <path
        d="M157.844 19.8889H113.491C111.459 19.8889 109.811 21.5366 109.811 23.5692V55.1851C109.811 57.2177 111.459 58.8654 113.491 58.8654H157.844C159.877 58.8654 161.524 57.2177 161.524 55.1851V23.5692C161.524 21.5366 159.877 19.8889 157.844 19.8889Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.213 55.2153H160.767"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M112.213 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M115.45 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M118.687 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M121.924 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M125.161 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M128.397 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M131.635 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M134.871 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M138.109 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M141.345 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M144.582 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M147.819 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M151.056 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M154.293 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M157.53 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M160.767 55.2153V55.9342"
        stroke="#1E293B"
        strokeWidth="0.215221"
        strokeMiterlimit="10"
      />
      <path
        d="M113.831 54.6298H159.148V48.3668L155.942 42.1685C155.921 42.1248 155.906 42.0785 155.898 42.0307L153.049 23.6466C153.049 23.5553 153.013 23.4677 152.948 23.4031C152.884 23.3385 152.796 23.3022 152.705 23.3022C152.613 23.3022 152.526 23.3385 152.461 23.4031C152.397 23.4677 152.36 23.5553 152.36 23.6466L149.459 46.0898C149.445 46.1973 149.392 46.2957 149.308 46.3653L146.515 48.5175C146.475 48.5474 146.428 48.5677 146.379 48.5768C146.329 48.5858 146.278 48.5833 146.23 48.5696C146.182 48.5559 146.137 48.5312 146.1 48.4976C146.063 48.4639 146.034 48.4221 146.016 48.3755L143.2 42.5688C143.18 42.5143 143.144 42.4665 143.099 42.4304C143.053 42.3943 142.998 42.3712 142.94 42.3637C142.883 42.3562 142.824 42.3645 142.77 42.3878C142.717 42.411 142.671 42.4483 142.637 42.4956L139.727 46.262L136.49 50.4373L133.253 46.262L130.399 42.5817C130.362 42.5303 130.31 42.4909 130.251 42.4683C130.191 42.4458 130.126 42.4411 130.064 42.4548C130.002 42.4685 129.945 42.5 129.901 42.5455C129.856 42.591 129.826 42.6484 129.814 42.7108L126.801 52.1074L123.564 35.3589L120.327 53.7775L117.09 51.2723L114.507 43.9979C114.361 43.5674 113.836 43.7052 113.836 44.1528L113.831 54.6298Z"
        fill="#3B82F6"
      />
      <path
        d="M159.148 54.6428H113.831V49.2752L117.034 43.9464C117.056 43.9096 117.071 43.8686 117.077 43.8259L119.926 28.0157C119.942 27.9353 119.985 27.8628 120.048 27.8106C120.112 27.7585 120.191 27.73 120.273 27.73C120.355 27.73 120.434 27.7585 120.498 27.8106C120.561 27.8628 120.604 27.9353 120.619 28.0157L123.516 47.3167C123.535 47.4131 123.59 47.4982 123.671 47.5535L126.465 49.413C126.505 49.4399 126.55 49.4582 126.598 49.4667C126.645 49.4752 126.694 49.4738 126.741 49.4624C126.788 49.4511 126.832 49.4301 126.87 49.4009C126.909 49.3716 126.941 49.3347 126.964 49.2925L129.775 44.2993C129.802 44.2511 129.841 44.21 129.887 44.1796C129.933 44.1491 129.986 44.1302 130.041 44.1243C130.097 44.1184 130.152 44.1257 130.204 44.1457C130.256 44.1657 130.302 44.1977 130.339 44.2391L133.253 47.4674L136.49 51.0573L139.727 47.4674L142.576 44.3036C142.617 44.2593 142.668 44.2261 142.725 44.2072C142.782 44.1882 142.843 44.1841 142.902 44.1953C142.961 44.2066 143.017 44.2327 143.063 44.2712C143.109 44.3097 143.144 44.3594 143.166 44.4156L146.179 52.4949H149.416L152.653 53.9326L155.89 51.7804L158.473 45.5261C158.501 45.4481 158.557 45.3828 158.629 45.3421C158.701 45.3014 158.786 45.2878 158.867 45.3039C158.949 45.32 159.022 45.3647 159.074 45.4299C159.125 45.4951 159.152 45.5765 159.148 45.6595V54.6428Z"
        fill="#1E293B"
      />
      <path
        d="M138.371 25.0842H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.371 27.4346H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.371 29.7803H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.371 32.1306H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.371 34.4766H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.371 36.8267H148.4"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 25.0842H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 27.4346H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 29.7803H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 32.1306H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 34.4766H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.09 36.8267H137.346"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9468 185.294C113.077 185.294 124.532 179.609 124.532 172.596C124.532 165.584 113.077 159.898 98.9468 159.898C84.8163 159.898 73.3613 165.584 73.3613 172.596C73.3613 179.609 84.8163 185.294 98.9468 185.294Z"
        fill="#3B82F6"
      />
      <path
        opacity="0.5"
        d="M98.9468 185.294C113.077 185.294 124.532 179.609 124.532 172.596C124.532 165.584 113.077 159.898 98.9468 159.898C84.8163 159.898 73.3613 165.584 73.3613 172.596C73.3613 179.609 84.8163 185.294 98.9468 185.294Z"
        fill="white"
      />
      <path
        d="M90.351 76.875C90.351 76.875 83.7825 100.304 83.7825 102.107C83.7825 103.644 85.9347 106.713 88.3107 105.482C90.6867 104.251 91.2248 100.42 91.2248 100.42L90.351 76.875Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.922 172.846C107.922 172.846 104.56 177.387 104.306 179.303C104.052 181.218 106.652 181.369 107.797 180.478C108.942 179.587 111.326 173.776 111.326 173.776L107.922 172.846Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.5762 161.913C89.5762 161.913 83.8212 164.332 83.8212 165.714C83.8212 167.096 86.8127 166.747 88.9865 165.942C91.1602 165.137 94.6252 163.755 94.6252 163.755C94.6252 163.755 91.3022 161.569 89.5762 161.913Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.3796 107.247L91.1859 114.728C91.1859 114.728 89.6923 128.425 88.9993 135.794C88.3063 143.163 90.4972 160.079 90.4972 160.079L89.4598 161.112V162.15L94.6251 163.759C94.8554 162.697 95.0093 161.62 95.0857 160.535V158.814L100.496 126.475C100.496 126.475 104.753 159.162 105.446 162.959C106.139 166.755 107.168 170.784 107.168 170.784L106.824 171.934L107.28 174.008L110.848 174.929C111.452 173.998 111.808 172.929 111.886 171.822C112.114 169.747 112.23 164.913 112.23 164.913C112.23 164.913 112.002 148.126 112.807 136.719C113.286 129.704 113.209 122.661 112.575 115.658L112.002 108.065C105.27 110.4 97.9053 110.108 91.3796 107.247Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.509 126.466L99.9326 122.209L97.634 119.794"
        stroke="white"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.567 111.392C108.567 111.392 106.953 114.384 111.326 115.765"
        stroke="white"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.326 117.147V170.315"
        stroke="white"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.8247 112.081L97.2853 114.844C97.2853 114.844 96.136 116.914 96.5966 117.836"
        stroke="white"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.1318 73.8876C99.1318 73.8876 90.5015 76.4143 90.3508 76.8749C90.2002 77.3355 91.3796 107.247 91.3796 107.247C91.3796 107.247 93.6824 110.776 99.9884 111.315C106.294 111.853 112.265 108.551 112.265 108.551L116.38 81.3171C116.38 81.3171 106.479 74.5634 105.791 74.4085C105.102 74.2535 101.052 73.3496 99.1318 73.8876Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.5377 79.6383L100.587 84.6659L104.09 80.6972C104.09 80.6972 103.828 78.1835 101.508 77.9209C100.891 77.8209 100.258 77.9372 99.7166 78.2502C99.1754 78.5632 98.7589 79.0536 98.5377 79.6383V79.6383Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.132 73.8876L95.4948 79.6383C95.4948 79.6383 97.6125 79.1088 98.6025 79.9697C99.5926 80.8306 100.72 81.756 100.72 81.756C100.72 81.756 102.442 80.3657 103.828 80.8306C104.777 81.1799 105.668 81.6704 106.471 82.2855C106.471 82.2855 107.332 76.5994 106.208 74.021C105.085 71.4427 101.512 70.5129 99.132 73.8876Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.057 74.5462C99.9467 76.2198 99.9252 77.898 99.9928 79.5738C100.122 81.6916 100.785 82.1564 101.577 80.7661C102.369 79.3758 104.16 75.1445 104.16 73.9522C104.162 73.2015 104.205 72.4514 104.289 71.7053C104.289 71.7053 101.512 74.6711 100.057 74.5462Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.587 84.666L97.1476 102.189L99.4634 108.009L102.309 103.248L100.587 84.666Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.34 100.648C107.34 100.648 99.5924 95.9693 98.9037 95.3581C98.215 94.7468 98.2107 95.2031 97.6726 95.5087C97.1346 95.8143 95.1416 98.1947 94.7586 98.9523C94.3755 99.7098 93.7599 100.795 94.5304 100.95C95.3009 101.104 95.9121 99.6582 95.9121 99.4128C95.9121 99.1675 97.0614 97.8805 97.0614 97.8805C97.0614 97.8805 95.9121 100.64 96.2952 100.872C96.6783 101.104 99.2868 102.163 99.8248 102.163C100.363 102.163 100.742 101.165 100.742 101.165C100.742 101.165 101.512 102.163 102.278 102.086C102.739 102.035 103.181 101.877 103.57 101.625L106.178 103.696L107.34 100.648Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.34 100.648C107.34 100.648 106.376 100.067 105.102 99.2881L103.966 101.953L106.191 103.717L107.34 100.648Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.2855 101.419C97.2855 101.419 97.518 98.9608 98.5166 97.5791"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.5894 101.724C98.5894 101.724 99.1274 99.5721 100.204 98.3496"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.169 82.6987C111.328 84.3261 110.063 86.5048 109.562 88.91L114.396 102.65C114.396 102.65 108.641 100.269 107.939 100.196C107.237 100.123 104.792 103.493 105.485 104.26C106.178 105.026 119.69 112.623 120.521 111.624C121.351 110.626 117.077 83.4692 116.608 82.4706C116.139 81.472 116.457 80.9382 114.843 80.7058"
        fill="white"
      />
      <path
        d="M113.169 82.6987C111.328 84.3261 110.063 86.5048 109.562 88.91L114.396 102.65C114.396 102.65 108.641 100.269 107.939 100.196C107.237 100.123 104.792 103.493 105.485 104.26C106.178 105.026 119.69 112.623 120.521 111.624C121.351 110.626 117.077 83.4692 116.608 82.4706C116.139 81.472 116.457 80.9382 114.843 80.7058"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.396 102.65C114.396 102.65 117.159 103.265 117.925 104.72Z"
        fill="white"
      />
      <path
        d="M114.396 102.65C114.396 102.65 117.159 103.265 117.925 104.72"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.5069 91.3334L89.6708 105.107C89.7348 105.244 89.8465 105.353 89.985 105.413L98.8048 109.029C98.9102 109.074 99.0266 109.088 99.1396 109.068C99.2526 109.048 99.3574 108.995 99.441 108.917C99.5246 108.838 99.5835 108.737 99.6104 108.625C99.6373 108.514 99.6311 108.397 99.5925 108.288L94.6553 94.9448C94.5687 94.7106 94.4322 94.4981 94.2553 94.322C94.0784 94.1458 93.8654 94.0102 93.6309 93.9246L84.2558 90.5284C84.1454 90.4844 84.0242 90.4749 83.9082 90.5009C83.7922 90.527 83.6868 90.5875 83.6059 90.6746C83.5249 90.7616 83.4721 90.8711 83.4544 90.9887C83.4368 91.1062 83.455 91.2264 83.5069 91.3334V91.3334Z"
        fill="#3B82F6"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.8183 98.6767C85.7321 98.6589 85.643 98.6615 85.558 98.6843C85.473 98.7071 85.3945 98.7495 85.3289 98.8081C85.2632 98.8666 85.2121 98.9398 85.1798 99.0216C85.1474 99.1034 85.1346 99.1917 85.1425 99.2793C85.1406 99.4779 85.2162 99.6695 85.3534 99.8131C85.6935 100.054 88.246 100.244 88.147 99.7614C88.0824 99.3869 86.7653 98.8618 85.8183 98.6767Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0219 97.747C84.9196 97.7259 84.8137 97.7289 84.7127 97.7559C84.6118 97.7829 84.5185 97.8331 84.4403 97.9025C84.3621 97.9719 84.3013 98.0585 84.2625 98.1556C84.2237 98.2527 84.2082 98.3574 84.217 98.4616C84.2149 98.6977 84.3062 98.9252 84.471 99.0943C84.8713 99.3784 87.9145 99.6065 87.7854 99.0341C87.695 98.5907 86.1325 97.9666 85.0219 97.747Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7811 96.3781C84.6707 96.358 84.5571 96.3634 84.4492 96.3942C84.3412 96.4249 84.2418 96.4801 84.1586 96.5554C84.0754 96.6307 84.0106 96.7242 83.9693 96.8285C83.9279 96.9329 83.9112 97.0454 83.9203 97.1572C83.9184 97.414 84.0173 97.6612 84.1957 97.8459C84.6262 98.1559 87.9234 98.4055 87.7985 97.7857C87.6909 97.2993 85.9907 96.6235 84.7811 96.3781Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.2859 95.0739C84.1755 95.0538 84.0619 95.0592 83.954 95.09C83.846 95.1207 83.7466 95.1759 83.6633 95.2512C83.5801 95.3265 83.5153 95.42 83.474 95.5243C83.4327 95.6287 83.4159 95.7412 83.425 95.853C83.422 95.9805 83.4448 96.1072 83.4922 96.2256C83.5395 96.344 83.6104 96.4515 83.7005 96.5417C84.1309 96.8517 87.4281 97.1013 87.3033 96.4815C87.1914 95.9951 85.4954 95.3322 84.2859 95.0739Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.8605 64.5988C95.8605 64.5988 96.377 69.8674 97.5306 72.308C98.6842 74.7486 99.0716 75.9022 100.229 75.9022C101.387 75.9022 103.57 74.2321 104.594 72.6911C105.108 71.9467 105.58 71.1751 106.01 70.3796L106.264 69.7383C106.264 69.7383 108.063 68.447 108.451 67.5559C108.838 66.6649 108.063 66.015 106.652 66.7854C106.652 66.7854 107.164 62.8039 106.135 60.3633C105.106 57.9227 97.2766 60.2341 95.8605 64.5988Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeMiterlimit="10"
      />
      <path
        d="M94.4615 66.6003L103.139 68.3695C103.139 68.3695 103.2 69.7167 102.278 70.1429C101.826 70.3367 101.328 70.3991 100.841 70.323C100.355 70.2468 99.8996 70.0352 99.5278 69.7124C98.8564 69.1012 98.8564 67.939 98.8564 67.939C98.8564 67.939 98.3054 67.5086 98.2451 67.939C98.1832 68.34 97.9764 68.7043 97.6638 68.9629C97.3512 69.2215 96.9546 69.3565 96.5492 69.3423C96.1443 69.3225 95.7544 69.1826 95.4293 68.9403C95.1043 68.6981 94.8588 68.3645 94.7241 67.9821C94.5744 67.5355 94.4861 67.0707 94.4615 66.6003V66.6003Z"
        fill="white"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.139 68.3695L106.746 66.4756"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.4615 66.6003L95.8648 66.0493"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.31 68.3091L97.6987 70.5732L98.4348 70.754L98.6156 72.5274L99.7778 72.2218"
        fill="white"
      />
      <path
        d="M98.31 68.3091L97.6987 70.5732L98.4348 70.754L98.6156 72.5274L99.7778 72.2218"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.5926 56.1234C99.5926 56.1234 94.4531 60.105 93.9409 62.5456C93.4287 64.9862 96.7646 66.015 98.9512 65.6276C101.138 65.2402 104.327 63.187 104.327 63.187C104.327 63.187 102.528 66.015 103.94 66.1398C104.58 66.2321 105.229 66.2321 105.868 66.1398C105.868 66.1398 105.098 67.6851 105.739 67.6851C106.381 67.6851 106.639 66.7855 106.639 66.7855C106.639 66.7855 108.438 62.5456 107.93 58.6932C107.422 54.8407 102.67 53.6828 99.5926 56.1234Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="0.430441"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
export default SvgDataReport;
