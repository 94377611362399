import { Default } from '@components/UI/Drawer/components';
import PreloadLazy from '@router/utils/preload-lazy';

export const Components = {
  Default,
  CreatePatientDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreatePatientDrawer'),
  ),
  CreateUserDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateUser'),
  ),
  TableColumnSettingsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/TableColumnSettingsDrawer'),
  ),
  CreateRoleDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateRoleDrawer'),
  ),
  CreateSpecializationDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateSpecializationDrawer'),
  ),
  CreatePositionDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreatePositionDrawer'),
  ),
  CreateHFTDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateHFTDrawer'),
  ),
  CreateHFDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateHFDrawer'),
  ),
  CreateCabinetTypeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCabinetTypeDrawer'),
  ),
  CreateCabinetDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCabinetDrawer'),
  ),
  CreateServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateServiceDrawer'),
  ),
  CreateServicePerformerDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateServicePerformerDrawer'),
  ),
  CreateServiceCategoryDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateServiceCategoryDrawer'),
  ),
  AddServiceUserDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServiceUserDrawer'),
  ),
  AddServiceCabinetDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServiceCabinetDrawer'),
  ),
  AddServiceRelatedServiceDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/AddServiceRelatedServiceDrawer'),
  ),
  CreateScheduleEventsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateScheduleEventsDrawer'),
  ),
  AddUserServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServiceSingleUserDrawer'),
  ),
  AddServicesToReceptionDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/AddServicesToReceptionDrawer'),
  ),
  AddServicesToOrderDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServiceToOrderDrawer'),
  ),
  CreateCalendarEvent: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCalendarEvent'),
  ),
  CreateCalendarEventEdit: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateCalendarEvent/CreateCalendarEventEdit'
      ),
  ),
  CreatePrice: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreatePrice'),
  ),
  AddUserRole: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddUserRole'),
  ),
  AddIUModuleToRole: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddIUModuleToRole'),
  ),
  AddEntityToRoleDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddEntityToRoleDrawer'),
  ),
  AddUserToRoleDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddUserToRoleDrawer'),
  ),
  ImportPriceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/ImportPriceDrawer'),
  ),
  AddServiceToDesktopEvent: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddServicesToReceptionDrawer/AddServiceToDesktopEventDrawer'
      ),
  ),
  AddServicesToCabinetDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServicesToCabinetDrawer'),
  ),
  AddServicesToPriceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServicesToPriceDrawer'),
  ),
  CreateDocChaptersDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDocChaptersDrawer'),
  ),
  CreateDocElementGroupDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateDocElementGroupDrawer'),
  ),
  CreateDocTemplatesDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDocTemplatesDrawer'),
  ),
  CreateDocElementsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDocElementsDrawer'),
  ),
  CreateDocElementListDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateDocElementsDrawer/CreateDocElementListDrawer'
      ),
  ),
  CreateDocElementStringDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateDocElementsDrawer/CreateDocElementStringDrawer'
      ),
  ),
  CreateDocElementMKX: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateDocElementsDrawer/CreateDocElementMKX'
      ),
  ),
  CreateDocElementICPC2: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateDocElementsDrawer/CreateDocElementICPC2'
      ),
  ),
  CreateDocChapterGroupDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateDocChapterGroupDrawer'),
  ),
  AddDocChapterElementsDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/AddDocChapterElementsDrawer'),
  ),
  CreateDocTemplateGroup: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDocTemplateGroup'),
  ),
  AddDocTemplateChapters: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddDocTemplateChapters'),
  ),
  AddDocToServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddDocToServiceDrawer'),
  ),
  CreateMedCardTypeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateMedCardTypeDrawer'),
  ),
  CreateMedCardDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateMedCardDrawer'),
  ),
  CreateMedEpisodeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateMedEpisodeDrawer'),
  ),
  AddMedDiagnoseToEpisode: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddMedDiagnoseToEpisode'),
  ),
  CreateDocumentDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDocumentDrawer'),
  ),
  CreateUrgentEventDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateCalendarEvent/CreateUrgentEventDrawer'
      ),
  ),
  ViewAppointmentInfoDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/ViewAppointmentInfoDrawer'),
  ),
  CreateInsuranceCompanyDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateInsuranceCompanyDrawer'),
  ),
  CreateGuarantorDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/GuaranteeDrawer/CreateGuarantorDrawer'
      ),
  ),
  CreateInsurancePolicyDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateInsurancePolicyDrawer'),
  ),
  CreateGuaranteeDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/GuaranteeDrawer/CreateGuaranteeDrawer'
      ),
  ),
  CreateConsumableDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateConsumableDrawer'),
  ),
  CreateConsumableTypeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateConsumableTypeDrawer'),
  ),
  CreateBiomaterialTypeDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateBiomaterialTypeDrawer'),
  ),
  AddLabServiceToAnalysis: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddLabServiceToAnalysis'),
  ),
  AnalysisDetailedDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AnalysisDetailedDrawer'),
  ),
  CreateDirectionDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/DirectionDrawer/CreateDirectionDrawer'
      ),
  ),
  EditDocDirectionDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/DirectionDrawer/EditDocDirectionDrawer'
      ),
  ),
  CreateTownDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateTownDrawer'),
  ),
  CreateUserDocTemplateDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateUserDocTemplateDrawer'),
  ),
  XReportDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/XReportDrawer'),
  ),
  ZReportDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/ZReportDrawer'),
  ),
  OfficialRemovalDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OfficialRemovalDrawer'),
  ),
  OfficialEntryDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OfficialEntryDrawer'),
  ),
  CreateCertificateDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCertificateDrawer'),
  ),
  EditCertificateDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/EditCertificateDrawer'),
  ),
  CreateCashPlaceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCashPlaceDrawer'),
  ),
  RefundDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OrderDrawers/RefundDrawer'),
  ),
  OrderInfoDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/OrderDrawers/OrderInfoDrawer'),
  ),
  CreateLabServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateLabServiceDrawer'),
  ),
  CreateClinicServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateClinicServiceDrawer'),
  ),
  CreateCollectionServiceDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateCollectionServiceDrawer'),
  ),
  AddConsumableDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddConsumableDrawer'),
  ),
  CreatePreferentialCategory: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreatePreferentialCategory'),
  ),
  AddPreferentialServiceDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/AddPreferentialServiceDrawer'),
  ),
  CreateServicePropertyDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateServicePropertyDrawer'),
  ),
  CreateServicePropertyNumberDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateServicePropertyDrawer/CreateServicePropertyNumberDrawer'
      ),
  ),
  CreateServicePropertyTextDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateServicePropertyDrawer/CreateServicePropertyTextDrawer'
      ),
  ),
  CreateServicePropertySelectDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateServicePropertyDrawer/CreateServicePropertySelectDrawer'
      ),
  ),
  CreateServicePropertyCalcDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateServicePropertyDrawer/CreateServicePropertyCalcDrawer'
      ),
  ),
  CreateServicePropertySeparatorDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/CreateServicePropertyDrawer/CreateServicePropertySeparatorDrawer'
      ),
  ),
  RefIntervalsNumDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/RefIntervalsDrawer/RefIntervalsNumDrawer'
      ),
  ),
  RefIntervalsTextDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/RefIntervalsDrawer/RefIntervalsTextDrawer'
      ),
  ),
  AddPropertyToServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddPropertyToServiceDrawer'),
  ),
  AddUserLabServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddUserLabServiceDrawer'),
  ),
  UploadArchiveDocDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/UploadArchiveDocDrawer'),
  ),
  StartAppointmentDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/StartAppointmentDrawer'),
  ),
  CombineOrdersDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CombineOrdersDrawer'),
  ),
  InsurancePriceServicesDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/InsurancePriceServicesDrawer'),
  ),
  SettingLabWorkplaceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/SettingLabWorkplaceDrawer'),
  ),
  SortSamplesByBarcodeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/SortSamplesByBarcodeDrawer'),
  ),
  CreateDeviceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateDeviceDrawer'),
  ),
  PatientDailyPaymentsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/PatientDailyPaymentsDrawer'),
  ),
  ConfirmGuaranteeDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/GuaranteeDrawer/ConfirmGuaranteeDrawer'
      ),
  ),
  ChooseGuaranteeDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/GuaranteeDrawer/ChooseGuaranteeDrawer'
      ),
  ),
  CreateDirectionWithGuaranteeDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/DirectionDrawer/CreateDirectionWithGuaranteeDrawer'
      ),
  ),
  ReplaceServiceDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/ReplaceServiceDrawer'),
  ),
  CreateNotExistImportDataDrawer: PreloadLazy(
    () =>
      import('@components/UI/Drawer/components/CreateNotExistImportDataDrawer'),
  ),
  AddPatientConfidantDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/PatientConfidantDrawer/AddPatientConfidantDrawer'
      ),
  ),
  AddConfidantPersonForDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/PatientConfidantDrawer/AddConfidantPersonForDrawer'
      ),
  ),
  CreatePayerDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreatePayerDrawer'),
  ),
  CreateCounterpartyDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateCounterpartyDrawer'),
  ),
  CreateReferralDoctorDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateReferralDoctorDrawer'),
  ),
  CombinePatientsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CombinePatientsDrawer'),
  ),
  BindingRemedEpisodeDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/BindingRemedEpisodeDrawer'),
  ),
  OnlineRecordsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OnlineRecordsDrawer'),
  ),
  CreateServicePackageDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/CreateServicePackageDrawer'),
  ),
  AddServiceToPackageDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddServiceToPackageDrawer'),
  ),
  AddPackageServiceToPatientDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddPackageServiceToPatientDrawer'
      ),
  ),
  AddScheduledCallDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/AddScheduledCallDrawer'),
  ),
  AddServicesToCollectionPointDrawer: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddServicesToCollectionPointDrawer'
      ),
  ),
  OnlineVisitsTipDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OnlineVisitsTipDrawer'),
  ),
  OnSendAllBioResultsDrawer: PreloadLazy(
    () => import('@components/UI/Drawer/components/OnSendAllBioResultsDrawer'),
  ),
  AddPackageServicesOnCalendar: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddPackageServicesToAppointmentDrawer/AddPackageServicesOnCalendar'
      ),
  ),
  AddPackageServicesOnWorkplace: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddPackageServicesToAppointmentDrawer/AddPackageServicesOnWorkplace'
      ),
  ),
  AddPackageServicesOnLabPlanning: PreloadLazy(
    () =>
      import(
        '@components/UI/Drawer/components/AddPackageServicesToAppointmentDrawer/AddPackageServicesOnLabPlanning'
      ),
  ),
};
