import { action, makeObservable, observable } from 'mobx';

export default class GuaranteeStore {
  @observable public guarantees: Guarantee[] = [];
  @observable public guarantee: Guarantee | null = null;
  @observable public guaranteesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public guaranteeReport: GuaranteeReportData = {
    report_data: [],
    total_guarantees_count: 0,
    total_insurance_check: 0,
    total_patient_check: 0,
    total_service_cost: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setGuarantees = (guarantees: Guarantee[], meta: ResponseMeta) => {
    this.guarantees = guarantees;
    this.guaranteesMeta = meta;
  };

  @action
  public setGuarantee = (guarantee: Guarantee) => {
    this.guarantee = guarantee;
  };

  @action
  public addGuarantee = (guarantee: Guarantee[]) => {
    this.guarantees = this.guarantees.concat(guarantee);
  };

  @action
  public updateGuarantee = (guarantee: Guarantee) => {
    this.guarantees.forEach((g) => {
      if (g.id === guarantee.id) {
        Object.assign(g, guarantee);
      }
    });
  };

  @action public setGuaranteeReport = (data: GuaranteeReportData) => {
    this.guaranteeReport = data;
  };
}
