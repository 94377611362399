import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowsCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      fill="none"
      viewBox="0 0 21 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.5 0C4.98 0 .5 4.48.5 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10m.06 17v-2.01h-.06c-1.28 0-2.56-.49-3.54-1.46a5.006 5.006 0 0 1-.64-6.29l1.1 1.1c-.71 1.33-.53 3.01.59 4.13.7.7 1.62 1.03 2.54 1.01v-2.14l2.83 2.83zm4.11-4.24-1.1-1.1c.71-1.33.53-3.01-.59-4.13A3.48 3.48 0 0 0 10.5 6.5h-.06v2.15L7.61 5.83 10.44 3v2.02c1.3-.02 2.61.45 3.6 1.45 1.7 1.7 1.91 4.35.63 6.29"
      />
    </svg>
  );
};
export default SvgArrowsCircle;
