import React, { memo, useEffect, useState } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import './PageLoader.scss';
import Logo from '@assets/img/logo/loading-logo.png';

/**
 * https://nguyenbathanh.github.io/
 * https://github.com/nguyenbathanh/react-loading-screen/tree/master/src
 * https://reactjsexample.com/css-only-spinners-of-loading-io-for-react/
 */
function PageLoader() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 250);
    return () => {
      clearTimeout(timeout);
      document.body.classList.remove('loading');
    };
  }, []);

  function Loader() {
    if (show) {
      return (
        <div className="full-page-loader" data-testid="page-loader">
          <span className="main-logo">
            <img src={Logo} alt="spinner" />
            <div className="loader">Loading...</div>
          </span>
        </div>
      );
    }
    return <TopBarProgress />;
  }

  return <Loader />;
}

export default memo(PageLoader);
