import { MedCardStore } from '@modules/MedicalCard/med-card.store';

export class MedCardService extends MedCardStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  /*
  |----------------------------------------------------------------------------
  | MED CARDS
  |----------------------------------------------------------------------------
  */
  public getMedCardsReq = async (query?: GetMedCardsQuery) => {
    const res = await this._store.MedCardApi.getMedCards(query);
    if (res.success && res.data) {
      this.setMedCards(res.data, res.meta);
    }
    return res;
  };

  public getMedCardRequest = async (id: number) => {
    const res = await this._store.MedCardApi.getMedCard(id);
    if (res.success && res.data) {
      // ...
    }
    return res;
  };

  public createMedCardReq = async (params: CreateMedCard) => {
    const res = await this._store.MedCardApi.createMedCard(params);
    if (res.success && res.data) {
      this.addMedCard(res.data);
    }
    return res;
  };

  public updateMedCardReq = async (id: number, params: UpdateMedCard) => {
    const res = await this._store.MedCardApi.updateMedCard(id, params);
    if (res.success && res.data) {
      this.updateMedCard(res.data);
    }
    return res;
  };

  public closeMedCardReq = async (id: number) => {
    const res = await this._store.MedCardApi.closeMedCard(id);
    if (res.success && res.data) {
      this.updateMedCard(res.data);
    }
    return res;
  };

  public deleteMedCardReq = async (id: number) => {
    const res = await this._store.MedCardApi.deleteMedCard(id);
    if (res.success) this.deleteMedCard(id);
    return res;
  };

  /*
  |----------------------------------------------------------------------------
  | TYPES
  |----------------------------------------------------------------------------
  */
  public getMedCardTypesReq = async (query?: GetMedCardTypesQuery) => {
    const res = await this._store.MedCardApi.getMedCardTypes(query);
    if (res.success && res.data) {
      this.setMedCardTypes(res.data);
    }
    return res;
  };

  public createMedCardTypeReq = async (params: CreateMedCardType) => {
    const res = await this._store.MedCardApi.createMedCardType(params);
    if (res.success && res.data) {
      this.addMedCardType(res.data);
    }
    return res;
  };

  public updateMedCardTypeReq = async (
    id: number,
    params: UpdateMedCardType,
  ) => {
    const res = await this._store.MedCardApi.updateMedCardType(id, params);
    if (res.success && res.data) {
      this.updateMedCardType(res.data);
    }
    return res;
  };

  public deleteMedCardTypeReq = async (id: number) => {
    const res = await this._store.MedCardApi.deleteMedCardType(id);
    if (res.success) {
      this.deleteMedCardTypes(id);
    }
    return res;
  };

  /*
  |----------------------------------------------------------------------------
  | EPISODES
  |----------------------------------------------------------------------------
  */
  public getMedEpisodesReq = async (query?: GetMedEpisodesQuery) => {
    const res = await this._store.MedCardApi.getMedEpisodes(query);
    if (res.success && res.data) {
      this.setEpisodes(res.data, res.meta);
    }
    return res;
  };

  public getMedEpisodeReq = async (id: number) => {
    const res = await this._store.MedCardApi.getMedEpisode(id);
    if (res.success && res.data) {
      this.setEpisode(res.data);
    }
    return res;
  };

  public createMedEpisodeReq = async (params: CreateMedEpisode) => {
    const res = await this._store.MedCardApi.createMedEpisode(params);
    if (res.success && res.data) {
      this.addEpisode(res.data);
    }
    return res;
  };

  public updateMedEpisodeReq = async (
    id: number,
    params: UpdateMedEpisodes,
  ) => {
    const res = await this._store.MedCardApi.updateMedEpisode(id, params);
    if (res.success && res.data) {
      this.updateEpisode(res.data);
    }
    return res;
  };

  public closeMedEpisodeReq = async (id: number, params: CloseMedEpisode) => {
    const res = await this._store.MedCardApi.closeMedEpisode(id, params);
    if (res.success && res.data) {
      this.updateEpisode(res.data);
    }
    return res;
  };

  public importMedCardsReq = async (params: FormData) => {
    const res = await this._store.MedCardApi.importMedCards(params);
    if (res.success && res.data) {
      this.setImportMedCards(res.data);
    }
    return res;
  };

  public saveImportMedCardsReq = async (params: SaveImportMedCards) => {
    this.validateImportMedCards();
    const res = await this._store.MedCardApi.saveImportMedCards(params);
    if (res.success && res.data) {
      console.log(res.data);
    }
    return res;
  };

  public validateImportMedCards = () => {
    return this.importMedCards.every(
      (e) => e.patient?.id && e.code && e.type?.id,
    );
  };
}
