import { CashPlaceStore } from '@modules/CashPlace/cash-place.store';
import { OperationReportService } from './OperationReport';
import { CashOperationService } from './CashOperations';
import { WsConnection } from './cash-place.connection';

export class CashPlaceService extends CashPlaceStore {
  protected _store: Store;
  public connection: WsConnection;
  public readonly operationReport: OperationReportService;
  public readonly cashOperations: CashOperationService;

  constructor(store: Store) {
    super();
    this._store = store;
    this.connection = new WsConnection(store);
    this.operationReport = new OperationReportService(store);
    this.cashOperations = new CashOperationService(store);
  }

  /*
  |----------------------------------------------------------------------------
  | METHODS
  |----------------------------------------------------------------------------
  */

  public subscribeOrders = () => {
    return this.connection.subscribe((err, { all_orders_sorted }) => {
      if (!err) this.setCashOrders(all_orders_sorted);
    });
  };

  public getOrdersReq = async () => {
    const res = await this.connection.connect();
    if (res.all_orders_sorted) {
      this.setCashOrders(res.all_orders_sorted);
    }
    return res;
  };

  public getCashPlacesReq = async (query?: GetCashPlaceQuery) => {
    const res = await this._store.CashPlaceApi.getCashPlaces(query);
    if (res.success && res.data) {
      this.setCashPlaces(res.data, res.meta);
    }
    return res;
  };

  public getCashPlaceReq = async (id: number) => {
    const res = await this._store.CashPlaceApi.getCashPlace(id);
    if (res.success && res.data) {
      this.setCashPlace(res.data);
    }
    return res;
  };

  public createCashPlaceReq = async (params: CreateCashPlaceParams) => {
    const res = await this._store.CashPlaceApi.createCashPlace(params);
    if (res.success && res.data) {
      this.addCashPlace(res.data);
    }
    return res;
  };

  public updateCashPlaceReq = async (
    id: number,
    params: UpdateCashPlaceParams,
  ) => {
    const res = await this._store.CashPlaceApi.updateCashPlace(id, params);
    if (res.success && res.data) {
      this.updateCashPlace(res.data);
    }
    return res;
  };

  public activateCashPlaceReq = async (id: number) => {
    const res = await this._store.CashPlaceApi.activateCashPlace(id);
    if (res.success && res.data) {
      this.updateCashPlace(res.data);
    }
    return res;
  };

  public deactivateCashPlaceReq = async (id: number) => {
    const res = await this._store.CashPlaceApi.deactivateCashPlace(id);
    if (res.success && res.data) {
      this.updateCashPlace(res.data);
    }
    return res;
  };

  public deleteCashPlaceReq = async (id: number) => {
    const res = await this._store.CashPlaceApi.deleteCashPlace(id);
    if (res.success) {
      this.deleteCashPlace(id);
    }
    return res;
  };

  public createInoutReq = async (id: number, params: CreateCashPlaceInout) => {
    return await this._store.CashPlaceApi.createInout(id, params);
  };

  public createServicesReportReq = async (id: number) => {
    return await this._store.CashPlaceApi.createServicesReport(id);
  };

  public createXReportRequest = async (id: number) => {
    return await this._store.CashPlaceApi.createXReport(id);
  };

  public createZReportReq = async (id: number) => {
    return await this._store.CashPlaceApi.createZReport(id);
  };

  public createZeroCheckReq = async (id: number) => {
    return await this._store.CashPlaceApi.createZeroCheck(id);
  };

  public getXReportReq = async (id: number, query?: GetXReportQueries) => {
    const res = await this._store.CashPlaceApi.getXReport(id, query);
    if (res.success && res.data) {
      this.setXReport(res.data);
    }
    return res;
  };

  public printNonFiscalCheckReq = (id: number) => {
    return this._store.CashPlaceApi.printNonFiscalCheck(id);
  };
}
