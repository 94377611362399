import { PreloadLazy } from '@router/utils';

export const ADMINISTRATOR_ROUTES: PrivateRoute = {
  group: 'ADMINISTRATOR',
  name: 'administrator',
  path: '/administrator',
  exact: false,
  sidebar: true,
  icon: 'OutlineAssignment',
  activeIcon: 'AssignmentInd',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'sections',
      path: '/administrator',
      exact: true,
      component: PreloadLazy(() => import('@views/Administrator')),
    },
    {
      name: 'kanban',
      path: '/administrator/kanban',
      exact: false,
      ws: true,
      component: PreloadLazy(() => import('@views/Administrator/Kanban')),
      imagePath: () => import('@assets/img/preview/group_therapy.svg'),
    },
    // {
    //   name: 'patient-card',
    //   path: '/administrator/patient-card',
    //   exact: false,
    //   component: PreloadLazy(() => import('@views/Administrator/PatientCard')),
    // },
    {
      name: 'appointments',
      path: '/administrator/appointments',
      exact: false,
      ws: true,
      component: PreloadLazy(() => import('@views/Administrator/Appointments')),
      imagePath: () => import('@assets/img/preview/settings_documents.svg'),
    },
    {
      name: 'lab_planning',
      path: '/administrator/lab-planning',
      exact: false,
      ws: true,
      component: PreloadLazy(() => import('@views/Biomaterials/Planning')),
    },
    {
      name: 'completed_appointments',
      path: '/administrator/completed_appointments',
      exact: false,
      ws: true,
      component: PreloadLazy(
        () => import('@views/Administrator/CompletedAppointments'),
      ),
    },
    {
      name: 'insurance_price',
      path: '/administrator/prices',
      exact: false,
      ws: true,
      component: PreloadLazy(() => import('@views/Administrator/Price')),
    },
  ],
};
