import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.667 9.167H6.525l4.658-4.659L10 3.333 3.333 10 10 16.667l1.175-1.175-4.65-4.659h10.142z"
      />
    </svg>
  );
};
export default SvgArrowBack;
