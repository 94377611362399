import { FetchService } from '@modules/Api';

export class PositionsApi extends FetchService {
  public getPositions = (query?: GetPositionsQuery) => {
    return this.request<'POSITION', Position[], GetPositionsQuery>(
      '/position',
      'GET_POSITIONS',
      { query, permit: 'positions' },
    );
  };

  public createPosition = (params: CreatePosition) => {
    return this.request<'POSITION', Position, CreatePosition>(
      '/position',
      'CREATE_POSITION',
      {
        method: 'POST',
        params,
        permit: 'positions',
      },
    );
  };

  public deletePosition = (positionId: number) => {
    return this.request<'POSITION'>('/position', 'DELETE_POSITION', {
      method: 'DELETE',
      pathParam: positionId,
      permit: 'positions',
    });
  };

  public updatePosition = (positionId: number, params: UpdatePosition) => {
    return this.request<'POSITION', Position, UpdatePosition>(
      '/position',
      'UPDATE_POSITION',
      {
        method: 'PUT',
        pathParam: positionId,
        params,
        permit: 'positions',
      },
    );
  };
}
