import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSubdirectoryArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="m15.834 12.5-5 5-1.184-1.183 2.992-2.984H3.334v-10H5v8.334h7.642L9.65 8.683 10.834 7.5z"
      />
    </svg>
  );
};
export default SvgSubdirectoryArrowRight;
