import { DOC_ELEMENT_TYPE } from '@modules/Documents/consts';

export const DOC_CHAPTER_TYPE = <const>{
  interactive: 'interactive',
  text: 'text',
};

export const CREATE_DOC_SEPARATOR: DocElement = Object.freeze({
  id: 10000,
  title: 'Separator',
  element_group: Object.freeze({
    id: 1000,
    title: 'System group',
  }),
  type: Object.freeze({
    id: DOC_ELEMENT_TYPE.separator,
    title: 'separator',
  }),
  unit: null,
  children: [],
  choose_mode: 0,
  value: undefined,
});
