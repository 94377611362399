import React, { memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const FullLayout: React.FC<Props> = ({ children }) => {
  return <div className="page-layout">{children}</div>;
};

export default memo(FullLayout);
