import ErrorStore from './error.store';
import { NotifyService } from '@modules/Notify';
import { StringService } from '@modules/String';
import { LoggerService } from '@modules/Logger';
import { Extras } from '@sentry/types';

export class ErrorService extends ErrorStore {
  private readonly _notifyService = NotifyService;
  private readonly _logger = LoggerService;

  private _notify = (
    key: ErrorKeys | 'UNKNOWN_KEY',
    message: string | string[],
  ) => {
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        this._notifyService.notify.error(
          key + '\r\n' + StringService.cutString(msg, 45),
        );
      });
      return;
    }

    this._notifyService.notify.error(
      key + '\r\n' + StringService.cutString(message, 45),
    );
  };

  private _save = (key: ErrorKeys, message: string | string[]) => {
    if (Array.isArray(message)) {
      message.forEach((ms) => this.setError(key, ms));
      return;
    }
    this.setError(key, message);
  };

  public syntaxError = (
    key: ErrorKeys | 'UNKNOWN_KEY',
    error: SyntaxError,
    extra?: Extras,
  ) => {
    this._logger.send.fetchException(error, {
      tags: { key },
      extra,
      fingerprint: [key].filter(Boolean),
    });
    if (key !== 'UNKNOWN_KEY') this._notify(key, error?.message);
  };

  public wsError = (
    key: ErrorKeys,
    errors: Partial<WsError>[],
    data?: Extras,
  ) => {
    const title = `❌ WS: ERROR ${errors.map((e) => `[${key}]: ${e.message}`)}`;

    this._logger.send.wsError(title, {
      tags: { key },
      extra: { data, errors },
      fingerprint: [
        key,
        ...errors.map((e) => String(e.message)),
        ...errors.map((e) => String(e.code)),
      ].filter(Boolean),
    });

    const messages = errors.map((e) => e.message || '');

    this._notify(key, messages);
    this._save(key, messages);
  };

  public wsException = (
    key: ErrorKeys | 'UNKNOWN_KEY',
    error: any,
    extra: any,
  ) => {
    this._logger.send.wsException(error, {
      tags: { key },
      extra,
      fingerprint: [key, extra?.type, String(extra?.target)].filter(Boolean),
    });

    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);

    this._notify(key, [message]);

    if (key !== 'UNKNOWN_KEY') {
      this._save(key, [message]);
    }
  };

  public fetchError = (key: ErrorKeys, errors: ServerError[], data: Extras) => {
    const title = `❌ FETCH ERROR: ${errors.map(
      (e) => `[${key}]: ${e.title} | ${e.detail} | ${e.status}`,
    )}`;

    this._logger.send.fetchError(title, {
      tags: { key },
      extra: { errors, data },
      fingerprint: [
        key,
        ...errors.map((e) => e.title),
        ...errors.map((e) => e.detail),
        ...errors.map((e) => String(e.status)),
      ].filter(Boolean),
    });

    const messages = errors.map((e) => e.title);

    this._notify(key, messages);
    this._save(key, messages);
  };

  public fetchException = (error: any, key: LoadingKey, extra: Extras) => {
    this._logger.send.fetchException(error, {
      extra,
      tags: { key },
      fingerprint: [error, key, extra],
    });

    let message;
    if (error instanceof Error) message = error.message;
    if (error.message) message = error.message;
    else message = String(error);

    this._notify(key, message);
    this._save(key, message);
  };

  public i18Error = (key: string, extra: Extras) => {
    this._logger.send.i18Error(key, extra);
  };

  public reactBoundaryException = (error: any, extra: Extras) => {
    this._logger.send.boundaryException(error, extra);
  };
}
