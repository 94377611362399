import UiModulesStore from '@modules/UiModules/ui-modules.store';

export class UiModulesService extends UiModulesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getUiModulesRequest = async () => {
    const response = await this._store.UiModulesApi.getUiModules();
    if (response && response.data) {
      this.setUiModules(response.data);
    }
    return response;
  };

  public getUiModulesTreeRequest = async () => {
    const response = await this._store.UiModulesApi.getUiModulesTree();
    if (response && response.data) {
      this.setUiModulesTree(response.data);
    }
    return response;
  };

  public removeUiModuleRequest = async (uiModuleId: number) => {
    const response = await this._store.UiModulesApi.removeUiModule(uiModuleId);
    if (response.success) {
      this.removeUiModule(uiModuleId);
    }
    return response;
  };
}
