import { FetchService } from '@modules/Api/fetch.service';

export class ApiService extends FetchService {
  /*
   |----------------------------------------------------------------------------
   | INIT
   |----------------------------------------------------------------------------
   */
  public checkInitSystem = (): Promise<HttpResponse<InitRes>> => {
    return this.request<'INIT', InitRes>('/init', 'CHECK_INIT', {
      authRequired: false,
    });
  };

  public initSystemReq = (params: InitReq): Promise<HttpResponse<void>> => {
    return this.request<'INIT', void, InitReq>('/init', 'INIT', {
      method: 'POST',
      params,
      authRequired: false,
    });
  };

  /*
   |----------------------------------------------------------------------------
   | PROFILE
   |----------------------------------------------------------------------------
   */
  public changePassword = (
    params: ChangePasswordReq,
  ): Promise<HttpResponse<ChangePasswordRes>> => {
    return this.request<'PROFILE', ChangePasswordRes, ChangePasswordReq>(
      '/profile/changePassword',
      'CHANGE_PASSWORD',
      {
        method: 'POST',
        params,
      },
    );
  };

  public getProfile = (): Promise<HttpResponse<Profile>> => {
    return this.request<'PROFILE', Profile>('/profile', 'GET_PROFILE');
  };

  /*
   |----------------------------------------------------------------------------
   | MODULES
   |----------------------------------------------------------------------------
   */
  public getEntities = (): Promise<HttpResponse<InitEntities>> => {
    return this.request<'INIT', InitEntities>(
      '/init/module_available',
      'GET_INIT_ENTITIES',
    );
  };

  public getUIModules = (): Promise<HttpResponse<InitUIModules>> => {
    return this.request<'INIT', InitUIModules>(
      '/init/ui_module_available',
      'GET_INIT_UI_MODULES',
    );
  };
}
