import { toast } from 'react-toastify';
import { EnvService } from '@modules/Env';
import { registration } from './registration';

class NotifyService {
  private _isDev = EnvService.isDev;

  public get notify() {
    return {
      error: (message: string) => {
        if (this._isDev) toast.error(message);
      },
      success(params: NotifyParams) {
        registration.addSuccess(params);
      },
      close: toast.dismiss,
    };
  }
}

export default new NotifyService();
