export const TIME_FORMATS = {
  'DD_MM_YYYY': 'DD-MM-YYYY',
  'DD_MMMM_YYYY': 'DD MMMM YYYY',
  'DD_MMMM_dddd': 'DD MMMM dddd',
  'DD_MM_YYYY_HH_mm': 'DD-MM-YYYY HH:mm',
  'HH_mm': 'HH:mm',
  'HH_mm_ss': 'HH:mm:ss',
  'HH_mm_DD_MM_YYYY': 'HH:mm, DD-MM-YYYY',
  'YYYY_MM_DD': 'YYYY-MM-DD',
  'YYYY_MM_DD_HH_mm': 'YYYY-MM-DD HH:mm',
  'YYYY_MM_DD_HH_mm_ss': 'YYYY-MM-DD HH:mm:ss',
  'DD_MM_YYYY_HH_mm_ss': 'DD-MM-YYYY HH:mm:ss',
  'MM_DD_YYYY_HH_mm_ss': 'MM-DD-YYYY HH:mm:ss',
  'DD.MM.YYYY': 'DD.MM.YYYY',
  'DD.MM.YYYY.HH.mm': 'DD.MM.YYYY HH:mm',
  'HH.mm.DD.MM.YYYY': 'HH:mm DD.MM.YYYY',
  'DD.MM.YYYY/HH.mm': 'DD.MM.YYYY / HH:mm',
  'dd_DD_MMMM_YYYY': 'dd, DD MMMM YYYY',
  'DDDD': 'dddd',
  'dd.DD_MMM': 'dd. DD MMM.',
  'DD.MMM.dd': 'DD MMM dd',
};
