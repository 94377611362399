import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBarcode2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <rect width={27} height={27} x={0.5} y={0.5} fill="#fff" rx={3.5} />
      <g fill="#6B7280" clipPath="url(#a)">
        <path d="M6 6v5.333h1.333v-4h4V6zM20.667 16.667v4h-4V22H22v-5.333zM7.333 20.667v-4H6V22h5.333v-1.333zM16.667 6v1.333h4v4H22V6z" />
        <path d="M9.667 8.667h-1v10.666h1zM12.333 8.667h-1.667v10.666h1.667zM14.333 8.667h-1v10.666h1zM16.333 8.667h-1v10.666h1zM19.334 8.667h-2v10.666h2z" />
      </g>
      <rect width={27} height={27} x={0.5} y={0.5} stroke="#CBD5E1" rx={3.5} />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6 6h16v16H6z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgBarcode2;
