import { FetchService } from '@modules/Api';

export class ReferralDoctorApi extends FetchService {
  public getReferralDoctors = (query?: GetReferralDoctor) => {
    return this.request<'REFERRAL_DOCTOR', ReferralDoctor[], GetReferralDoctor>(
      '/referral_doctor',
      'GET_REFERRAL_DOCTORS',
      { query },
    );
  };

  public getReferralDoctor = (id: number) => {
    return this.request<'REFERRAL_DOCTOR', ReferralDoctor>(
      '/referral_doctor',
      'GET_REFERRAL_DOCTOR',
      { pathParam: id },
    );
  };

  public createReferralDoctor = (params: CreateReferralDoctor) => {
    return this.request<
      'REFERRAL_DOCTOR',
      ReferralDoctor,
      CreateReferralDoctor
    >('/referral_doctor', 'CREATE_REFERRAL_DOCTOR', { method: 'POST', params });
  };

  public updateReferralDoctor = (id: number, params: UpdateReferralDoctor) => {
    return this.request<
      'REFERRAL_DOCTOR',
      ReferralDoctor,
      UpdateReferralDoctor
    >('/referral_doctor', 'UPDATE_REFERRAL_DOCTOR', {
      method: 'PUT',
      pathParam: id,
      params,
    });
  };

  public downloadReferralsReportData = (query?: DownloadReferralsReport) => {
    return this.request<'REFERRAL_DOCTOR', BlobResponse>(
      '/referral_doctor/download_report_summary',
      'DOWNLOAD_REFERRALS_REPORT',
      { query, blob: true },
    );
  };

  public getReferralsReportData = (query?: GetReferralsReportData) => {
    return this.request<'REFERRAL_DOCTOR', ReferralsReportData[]>(
      '/referral_doctor/report_data_summary',
      'GET_REFERRALS_REPORT_DATA',
      { query },
    );
  };

  public getReferralsReportHeader = (query?: GetReferralsReportHeader) => {
    return this.request<'REFERRAL_DOCTOR', ReferralsReportHeader>(
      '/referral_doctor/report_header_summary',
      'GET_REFERRALS_REPORT_HEADER',
      { query },
    );
  };

  public downloadReferralReportData = (
    id: number,
    query?: DownloadReferralReport,
  ) => {
    return this.request<'REFERRAL_DOCTOR', BlobResponse>(
      '/referral_doctor',
      'DOWNLOAD_REFERRAL_REPORT',
      { query, pathParam: `${id}/download_report`, blob: true },
    );
  };

  public getReferralReportData = (
    id: number,
    query?: GetReferralReportData,
  ) => {
    return this.request<'REFERRAL_DOCTOR', ReferralReportData[]>(
      '/referral_doctor',
      'GET_REFERRAL_REPORT_DATA',
      { query, pathParam: `${id}/report_data` },
    );
  };

  public getReferralReportHeader = (
    id: number,
    query?: GetReferralReportHeader,
  ) => {
    return this.request<'REFERRAL_DOCTOR', ReferralReportHeader>(
      '/referral_doctor',
      'GET_REFERRAL_REPORT_HEADER',
      { query, pathParam: `${id}/report_header` },
    );
  };
}
