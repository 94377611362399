import { RouterStore as ReactRouterMobxStore } from 'mobx-react-router';
import { makeObservable, observable } from 'mobx';
import { ROUTER_CONFIG } from '@router';

export class RoutingStore extends ReactRouterMobxStore {
  public config = ROUTER_CONFIG;
  @observable public helper = 'helper';

  constructor() {
    super();
    makeObservable(this);
  }
}
