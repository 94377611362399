import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPdf = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M20.5 2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M12 9.5c0 .83-.67 1.5-1.5 1.5h-1v2H8V7h2.5c.83 0 1.5.67 1.5 1.5zm5 2c0 .83-.67 1.5-1.5 1.5H13V7h2.5c.83 0 1.5.67 1.5 1.5zm4-3h-1.5v1H21V11h-1.5v2H18V7h3zm-11.5 1h1v-1h-1zM4.5 6h-2v14c0 1.1.9 2 2 2h14v-2h-14zm10 5.5h1v-3h-1z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPdf;
