// const resolveConfig = require('tailwindcss/resolveConfig');
// const tailwindConfig = require('./../../../tailwind.config.js');
//
// export const cssConfig = resolveConfig(tailwindConfig);

declare global {
  type Breakpoint = keyof typeof breakpoint;
  type BreakpointMap = { [key in Breakpoint]: number[] };
}

const screens = {
  'xs': 320,
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
};

const breakpoint = {
  'xs': screens.xs,
  'sm': screens.sm,
  'md': screens.md,
  'lg': screens.lg,
  'xl': screens.xl,
  '2xl': screens['2xl'],
};

const breakpointMap: BreakpointMap = {
  'xs': [0, breakpoint.xs],
  'sm': [breakpoint.sm + 1, breakpoint.md],
  'md': [breakpoint.md + 1, breakpoint.lg],
  'lg': [breakpoint.lg + 1, breakpoint.xl],
  'xl': [breakpoint.xl + 1, breakpoint['2xl']],
  '2xl': [breakpoint['2xl'] + 1, Infinity],
};

export const breakpointList = Object.entries(breakpointMap);
