import { AddressStore } from '@modules/Address/address.store';

export class AddressService extends AddressStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getRegionsReq = async (query?: GetRegionsQuery) => {
    const res = await this._store.AddressApi.getRegions(query);
    if (res.success && res.data) {
      this.setRegions(res.data, res.meta);
    }
    return res;
  };

  public getPlacesReq = async (query?: GetPlacesQuery) => {
    const res = await this._store.AddressApi.getPlaces(query);
    if (res.success && res.data) {
      this.setPlaces(res.data, res.meta);
    }
    return res;
  };

  public createPlaceReq = async (params: CreatePlaceParams) => {
    const res = await this._store.AddressApi.createPlace(params);
    if (res.success && res.data) {
      this.addPlace(res.data);
    }
    return res;
  };
}
