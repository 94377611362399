import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="current"
          d="M16.667 1.667H3.333c-.916 0-1.658.75-1.658 1.666l-.008 15L5 15h11.667c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666m-1.667 10H5V10h10zm0-2.5H5V7.5h10zm0-2.5H5V5h10z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgMessage;
