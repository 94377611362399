import { FetchService } from '@modules/Api';

export class PreferentialCategoriesApi extends FetchService {
  public getPreferentialCategories = (query?: GetPreferentialCategories) => {
    return this.request<
      'PREFERENTIAL_CATEGORIES',
      PreferentialCategory[],
      GetPreferentialCategories
    >('/preferential_categories', 'GET_PREFERENTIAL_CATEGORIES', { query });
  };

  public getPreferentialCategory = (id: number) => {
    return this.request<'PREFERENTIAL_CATEGORIES', PreferentialCategory>(
      '/preferential_categories',
      'GET_PREFERENTIAL_CATEGORY',
      { pathParam: id },
    );
  };

  public updatePreferentialCategory = (
    id: number,
    params: UpdatePreferentialCategory,
  ) => {
    return this.request<
      'PREFERENTIAL_CATEGORIES',
      PreferentialCategory,
      UpdatePreferentialCategory
    >('/preferential_categories', 'UPDATE_PREFERENTIAL_CATEGORY', {
      method: 'PUT',
      params,
      pathParam: id,
    });
  };

  public createPreferentialCategory = (params: CreatePreferentialCategory) => {
    return this.request<
      'PREFERENTIAL_CATEGORIES',
      PreferentialCategory,
      CreatePreferentialCategory
    >('/preferential_categories', 'CREATE_PREFERENTIAL_CATEGORY', {
      method: 'POST',
      params,
    });
  };

  public deletePreferentialCategory = (id: number) => {
    return this.request<'PREFERENTIAL_CATEGORIES', PreferentialCategory>(
      '/preferential_categories',
      'CREATE_PREFERENTIAL_CATEGORY',
      {
        method: 'DELETE',
        pathParam: id,
      },
    );
  };

  public addPreferentialService = (
    id: number,
    params: AddPreferentialService,
  ) => {
    return this.request<
      'PREFERENTIAL_CATEGORIES',
      PreferentialCategory,
      AddPreferentialService
    >('/preferential_categories', 'ADD_PREFERENTIAL_SERVICE', {
      method: 'POST',
      pathParam: `${id}/add`,
      params,
    });
  };

  public patientsPreferentialService = (id: number) => {
    return this.request<'PREFERENTIAL_CATEGORIES', Patient[]>(
      '/preferential_categories',
      'PATIENTS_PREFERENTIAL_SERVICE',
      {
        method: 'GET',
        pathParam: `${id}/patients`,
      },
    );
  };

  public removePreferentialService = (
    id: number,
    params: RemovePreferentialService,
  ) => {
    return this.request<'PREFERENTIAL_CATEGORIES', PreferentialCategory>(
      '/preferential_categories',
      'REMOVE_PREFERENTIAL_SERVICE',
      {
        method: 'POST',
        pathParam: `${id}/remove`,
        params,
      },
    );
  };
}
