import { ScheduleStaffStore } from '@modules/ScheduleStaff/schedule-staff.store';

export class ScheduleStaffService extends ScheduleStaffStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public subscribe = () => {
    this._store.WsService.on('schedule_interval.get_list', this._getIntervals);
    this._store.WsService.on(
      'schedule_interval.get_list_events_only',
      this._getIntervalEvents,
    );
    this._store.WsService.on(
      'schedule_interval.get_available',
      this._getExecutors,
    );
    this._store.WsService.on(
      'schedule_interval.mass_create',
      this._createMassInterval,
    );
    this._store.WsService.on(
      'schedule_interval.confirm',
      this._confirmInterval,
    );
    this._store.WsService.on(
      'schedule_interval.unconfirm',
      this._unconfirmInterval,
    );
    this._store.WsService.on('schedule_interval.update', this._updateInterval);
    this._store.WsService.on('schedule_interval.delete', this._deleteInterval);
    this._store.WsService.on('schedule_interval.unsubscribe', this.unsubscribe);
  };

  public unsubscribe = () => {
    this._store.WsService.off('schedule_interval.get_list', this._getIntervals);
    this._store.WsService.off(
      'schedule_interval.get_list_events_only',
      this._getIntervalEvents,
    );
    this._store.WsService.off(
      'schedule_interval.get_available',
      this._getExecutors,
    );
    this._store.WsService.off(
      'schedule_interval.mass_create',
      this._createMassInterval,
    );
    this._store.WsService.off(
      'schedule_interval.confirm',
      this._confirmInterval,
    );
    this._store.WsService.off(
      'schedule_interval.unconfirm',
      this._unconfirmInterval,
    );
    this._store.WsService.off('schedule_interval.update', this._updateInterval);
    this._store.WsService.off('schedule_interval.delete', this._deleteInterval);
    this._store.WsService.off(
      'schedule_interval.unsubscribe',
      this.unsubscribe,
    );

    this.clearScheduleData();
  };

  public getScheduleStaffUnSubscribe = () => {
    this._store.WsApi.schedule.getScheduleStaffUnSubscribe();
  };

  public getScheduleStaffReq = (params?: GetScheduleStaffReq) => {
    this._store.WsApi.schedule.getScheduleStaff(params);
  };

  public getScheduleStaffEventsReq = (params: GetScheduleStaffEvents) => {
    this._store.WsApi.schedule.getScheduleStaffEvents(params);
  };

  public getAvailableIntervalsRequests = (
    params?: GetAvailableIntervalsReq,
  ) => {
    this._store.WsApi.schedule.getAvailableIntervals(params);
  };

  public createMassScheduleStaffReq = (
    params: CreateMassScheduleEventsReq,
    storage?: any,
  ) => {
    this._store.WsApi.schedule.createMassScheduleStaff(params, storage);
  };

  public updateScheduleStaffReq = (
    params: UpdateScheduleStaffReq,
    storage?: any,
  ) => {
    this._store.WsApi.schedule.updateScheduleStaff(params, storage);
  };

  public confirmScheduleStaffReq = (
    params: ConfirmScheduleStaffReq,
    storage?: any,
  ) => {
    this._store.WsApi.schedule.confirmScheduleStaff(params, storage);
  };

  public unconfirmScheduleStaffReq = (
    params: UnconfirmScheduleStaffReq,
    storage?: any,
  ) => {
    this._store.WsApi.schedule.unconfirmScheduleStaff(params, storage);
  };

  public deleteScheduleStaffReq = (
    params: DeleteScheduleStaffReq,
    storage?: any,
  ) => {
    this._store.WsApi.schedule.deleteScheduleStaff(params, storage);
  };

  public getScheduleIntervalReportReq = (
    params: GetScheduleIntervalReportType,
  ) => {
    return this._store.ScheduleApi.getScheduleIntervalReport(params);
  };

  private _getIntervals = (data: WsResponse<ScheduleStaffResponse[]>) => {
    if (data.result && data.result.success) {
      this.setScheduleStaff(data.result.data);
    }
  };

  private _getIntervalEvents = (data: WsResponse<ScheduleStaffEvent[]>) => {
    if (data.result && data.result.success) {
      this.setScheduleStaffEvents(data.result.data);
    }
  };

  private _getExecutors = (data: WsResponse<AvailableExecutor[]>) => {
    if (data.result.success) {
      this.setAvailableExecutors(data.result.data);
    }
  };

  private _createMassInterval = (data: WsResponse<ScheduleStaffResponse>) => {
    if (data.result && data.result.success) {
      this.addScheduleStaff(data.result.data);
    }
  };

  private _confirmInterval = (data: WsResponse<ScheduleStaffEvent[]>) => {
    if (data.result && data.result.success) {
      this.updateScheduleStaff(data.result.data, true);
    }
  };

  private _unconfirmInterval = (data: WsResponse<ScheduleStaffEvent[]>) => {
    if (data.result && data.result.success) {
      this.updateScheduleStaff(data.result.data, true);
    }
  };

  private _updateInterval = (data: WsResponse<ScheduleStaffEvent[]>) => {
    if (data.result.success && data.result.data) {
      this.updateScheduleStaff(data.result.data);
    }
  };

  private _deleteInterval = (data: WsResponse<ScheduleStaffEvent[]>) => {
    if (data.result && data.result.success) {
      this.deleteScheduleStaff(data.result.data);
    }
  };
}
