import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocument3 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={18}
      fill="none"
      viewBox="0 0 14 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.667.667H2c-.917 0-1.658.75-1.658 1.666L.333 15.667c0 .916.742 1.666 1.659 1.666H12c.917 0 1.667-.75 1.667-1.666v-10zM2 15.667V2.333h5.833V6.5H12v9.167z"
      />
    </svg>
  );
};
export default SvgDocument3;
