import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';

export class SpecializationStore {
  @observable public specializations: Specialization[] = [];
  @observable public specializationMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setSpecializations = (
    specializations: Specialization[],
    meta: ResponseMeta,
  ): void => {
    this.specializations = sortBy(
      specializations,
      ['title'],
      ['asc'],
    ) as Specialization[];
    this.specializationMeta = meta;
  };

  @action
  public addSpecialization = (spec: Specialization): void => {
    this.specializations = [...this.specializations, spec];
  };

  @action
  public removeSpecialization = (specId: number): void => {
    this.specializations = this.specializations.filter((e) => e.id !== specId);
  };

  @action
  public updateSpecialization = (updated: Specialization): void => {
    const specialization = this.specializations.find(
      (e) => e.id === updated.id,
    );
    if (specialization) {
      specialization.title = updated.title;
    }
  };
}
