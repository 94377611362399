import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddMore = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#6B7280"
          d="M9.333 6.667H2V8h7.333zm0-2.667H2v1.333h7.333zM12 9.333V6.667h-1.333v2.666H8v1.334h2.667v2.666H12v-2.666h2.667V9.333zM2 10.667h4.667V9.333H2z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgAddMore;
