import React from 'react';
import { Icon } from '@components/UI/Icon';
import { useService } from '@stores/hooks';

function BackButton() {
  const { goBack } = useService('RoutingService');
  return (
    <Icon className="cursor-pointer mr-4" name="ArrowBack" onClick={goBack} />
  );
}

export default BackButton;
