import { observable, action, makeObservable } from 'mobx';

export default class ModalStore {
  @observable public isOpen = false;
  @observable public disableClose = false;
  @observable public modalProps: ModalProps = {} as ModalProps;
  @observable public isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public setOpen(value: boolean): void {
    this.isOpen = value;
  }

  @action
  public setModalProps(props: ModalProps): void {
    this.modalProps = props;
  }

  @action
  public setDisableClose(value: boolean): void {
    this.disableClose = value;
  }

  @action
  public setLoading = (value: boolean, index = 0) => {
    this.isLoading = value;
    if (!this.modalProps.buttons?.length) return;
    this.modalProps.buttons[index].loading = value;
  };

  @action
  public setDisable = (val: boolean, index = 0) => {
    if (!this.modalProps.buttons?.length) return;
    this.modalProps.buttons[index].disabled = val;
  };
}
