import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';
import { HealthFacilityFilterState } from '@modules/Filters';

export class HealthFacilityStore {
  @observable public healthFacility: HealthFacility | null = null;
  @observable public healthFacilities: HealthFacility[] = [];
  @observable public healthFacilitiesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public healthFacilityTypes: HealthFacilityType[] = [];
  @observable public healthFacilityTypesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  /*
  |----------------------------------------------------------------------------
  | HEALTH FACILITY
  |----------------------------------------------------------------------------
  */
  @action
  public setHealthFacilities = (
    data: HealthFacility[],
    meta: ResponseMeta,
  ): void => {
    this.healthFacilities = sortBy(
      data,
      ['title'],
      ['asc'],
    ) as HealthFacility[];
    this.healthFacilitiesMeta = meta;

    if (this.healthFacilities.length) {
      HealthFacilityFilterState.setIfEmpty(this.healthFacilities[0]);
    }
  };

  @action
  public setHealthFacility = (data: HealthFacility | null): void => {
    this.healthFacility = data;
  };

  @action
  public addHealthFacility = (data: HealthFacility): void => {
    this.healthFacilities = [...this.healthFacilities, data];
  };

  @action
  public updateHealthFacility = (updated: Partial<HealthFacility>): void => {
    const item = this.healthFacilities.find((e) => e.id === updated.id);
    if (item) {
      Object.assign(item, updated);
    }
    if (this.healthFacility?.id === updated.id) {
      Object.assign(this.healthFacility as HealthFacility, updated);
    }
  };

  @action
  public deleteHealthFacility = (id: number): void => {
    this.healthFacilities = this.healthFacilities.filter((e) => e.id !== id);
  };

  /*
   |----------------------------------------------------------------------------
   | HEALTH FACILITY TYPES
   |----------------------------------------------------------------------------
   */
  @action
  public setHealthFacilityTypes = (
    data: HealthFacilityType[],
    mata: ResponseMeta,
  ): void => {
    this.healthFacilityTypes = sortBy(
      data,
      ['title'],
      ['asc'],
    ) as HealthFacilityType[];
    this.healthFacilityTypesMeta = mata;
  };

  @action
  public addHealthFacilityType = (data: HealthFacilityType): void => {
    this.healthFacilityTypes = [...this.healthFacilityTypes, data];
  };

  @action
  public updateHealthFacilityType = (updated: HealthFacilityType): void => {
    const item = this.healthFacilityTypes.find((e) => e.id === updated.id);
    if (item) {
      Object.assign(item, updated);
    }
  };

  @action
  public deleteHealthFacilityType = (id: number): void => {
    this.healthFacilityTypes = this.healthFacilityTypes.filter(
      (e) => e.id !== id,
    );
  };
}
