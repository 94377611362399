import { PreloadLazy } from '@router/utils';

export const CALL_CENTER_ROUTES: PrivateRoute = {
  group: 'CALL_CENTER',
  name: 'call-center',
  path: '/call-center',
  exact: false,
  sidebar: true,
  icon: 'OutlineCall',
  activeIcon: 'Call',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'appeals_sections',
      path: '/call-center',
      exact: true,
      component: PreloadLazy(() => import('@views/CallCenter')),
    },
    {
      name: 'incoming_calls',
      path: '/call-center/incoming',
      exact: true,
      component: PreloadLazy(() => import('@views/CallCenter/Incoming')),
      imagePath: () => import('@assets/img/preview/incoming.svg'),
    },
    {
      name: 'outgoing_calls',
      path: '/call-center/outgoing',
      exact: true,
      component: PreloadLazy(() => import('@views/CallCenter/Outgoing')),
      imagePath: () => import('@assets/img/preview/outgoing.svg'),
    },
    {
      name: 'call_log',
      path: '/call-center/history',
      exact: true,
      component: PreloadLazy(() => import('@views/CallCenter/History')),
      imagePath: () => import('@assets/img/preview/desktop.svg'),
    },
    {
      name: 'scheduled_calls',
      path: '/call-center/scheduled-calls',
      exact: true,
      component: PreloadLazy(() => import('@views/CallCenter/ScheduledCalls')),
      imagePath: () => import('@assets/img/preview/outgoing.svg'),
    },
  ],
};
