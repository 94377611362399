import { action, computed, makeObservable, observable } from 'mobx';
import { PackageServiceFactory } from './PackageServiceFactory';

type PackageFactoryProps = {
  pkg: PatientPkgService;
};

export class PackageFactory {
  @observable public pkg: PatientPkgService;
  @observable public services: CalendarPackageService[];
  @observable public serviceLimit: number;
  @observable public serviceLimitUsed: number;
  @observable public hasServiceWithLimit: boolean;

  constructor(data: PackageFactoryProps) {
    this.pkg = data.pkg;
    this.hasServiceWithLimit = Boolean(this.pkg.service_limit_for_use);
    this.serviceLimit = this.pkg.service_limit_for_use;

    this.serviceLimitUsed = this.pkg.services
      .filter((s) => s.participates_in_the_selection)
      .reduce((sum, s) => (sum += s.frizzed_count), 0);

    this.services = data.pkg.services.map(
      (service) =>
        new PackageServiceFactory({
          pkg: this,
          service,
        }),
    );

    makeObservable(this);
  }

  @computed
  public get clinicServices() {
    return this.services.filter(
      ({ service }) => service.type === 'clinic_service',
    );
  }

  @computed
  public get selectionGroupServices() {
    return this.services.filter(({ inSelection }) => inSelection);
  }

  @action
  public setServiceLimit = (action: 'add' | 'remove', value = 1) => {
    this.serviceLimitUsed =
      action === 'add'
        ? this.serviceLimitUsed + value
        : this.serviceLimitUsed - value;
  };
}
