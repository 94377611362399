import { OperationReportStore } from './operation-report.store';

export class OperationReportService extends OperationReportStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getOperationReportReq = async (
    query?: CashPlaceOperation.GetReports,
  ) => {
    const res = await this._store.CashPlaceApi.getOperationReport(query);
    if (res.success && res.data) {
      this.setOperationReport(res.data);
    }
    return res;
  };

  public downloadCashPlaceReportReq = (
    query?: CashPlaceOperation.GetReports,
  ) => {
    return this._store.CashPlaceApi.downloadCashPlaceReport(query);
  };
}
