import React, { memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from '../Loader';
import './LazyImage.scss';

export type ImageProps = {
  size?: 'mini' | 'small' | 'medium' | 'large';
  className?: string;
  preload: () => Promise<{ default: string }>;
};

function LazyImage({ preload, size = 'small', className }: ImageProps) {
  const [src, setSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadImage = useCallback(() => {
    const controller = new AbortController();

    new Promise<{ default: string }>((resolve, reject) => {
      controller.signal.addEventListener('abort', (event) => {
        reject(event.target);
      });

      preload().then(resolve).catch(reject);
    })
      .then((response) => {
        setSrc(response.default);
        setIsLoading(false);
      })
      .catch(() => {
        setSrc('');
        setIsLoading(true);
      });

    return controller;
  }, [preload]);

  useEffect(() => {
    const controller = loadImage();
    return () => controller.abort();
  }, [loadImage]);

  return (
    <div
      className={cn('lazy-image relative', className, size)}
      data-testid="lazy-image"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <img className="w-full" src={src} alt="preloaded_image" />
      )}
    </div>
  );
}

export default memo(LazyImage);
