import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDetail = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.5 10.5H.5v1.667h10zm0-6.667H.5V5.5h10zm-10 5h15V7.167H.5zm0 6.667h15v-1.667H.5zm0-15v1.667h15V.5z"
      />
    </svg>
  );
};
export default SvgDetail;
