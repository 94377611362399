import { action, makeObservable, observable } from 'mobx';

export default class GuarantorStore {
  @observable public guarantors: Guarantor[] = [];
  @observable public guarantorMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setGuarantors = (guarantors: Guarantor[], meta: ResponseMeta) => {
    this.guarantors = guarantors;
    this.guarantorMeta = meta;
  };

  @action
  public addGuarantor = (guarantor: Guarantor) => {
    this.guarantors = this.guarantors.concat(guarantor);
  };

  @action
  public updateGuarantor = (guarantor: Guarantor) => {
    this.guarantors.forEach((g) => {
      if (g.id === guarantor.id) {
        Object.assign(g, guarantor);
      }
    });
  };
}
