import { action, makeObservable, observable } from 'mobx';

export class AddressStore {
  @observable public regions: RegionType[] = [];
  @observable public regionsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public places: PlaceType[] = [];
  @observable public placesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setRegions = (regions: RegionType[], meta: ResponseMeta) => {
    this.regions = regions;
    this.regionsMeta = meta;
  };

  @action
  public setPlaces = (places: PlaceType[], meta: ResponseMeta) => {
    this.places = places;
    this.placesMeta = meta;
  };

  @action
  public addPlace = (place: PlaceType) => {
    this.places.push(place);
  };
}
