import { action, computed, makeObservable, observable } from 'mobx';
import { Role } from '@modules/Roles/mapper/Role';
import { USER_STATUS } from '@modules/Users';

export class User {
  public readonly id: number;
  @observable public outlink_id: string | null = null;
  @observable public first_name = '';
  @observable public last_name = '';
  @observable public patronymic = '';
  @observable public activeStatus = false;
  @observable public canBeLoggedIn = false;
  @observable public is_fired = false;
  @observable public isFirstEntry = true;
  @observable public date_of_birth = '';
  @observable public delete_marked = false;
  @observable public email = '';
  @observable public position?: Position | null = null;
  @observable public specializations: Specialization[] = [];
  @observable public roles: Role[] = [];
  @observable public status: UserStatus = USER_STATUS.NO_ACCESS;
  @observable public phone_number;
  @observable public phone_code;
  @observable public is_outside_requests_on;

  constructor(user: UserResponse) {
    makeObservable(this);
    this.id = user.id;
    this.outlink_id = user.outlink_id;
    this.email = user.email;
    this.activeStatus = user.activeStatus;
    this.canBeLoggedIn = user.canBeLoggedIn;
    this.isFirstEntry = user.isFirstEntry;
    this.position = user.position;
    this.date_of_birth = user.date_of_birth;
    this.delete_marked = user.delete_marked;
    this.specializations = user.specializations;
    this.phone_number = user.phone_number;
    this.phone_code = user.phone_code;
    this.is_outside_requests_on = user.is_outside_requests_on;
    this._setName(user);
    this._setRoles(user);
    this.setUserStatus(user);
  }

  @computed
  public get name(): string {
    return `${this.last_name} ${this.first_name} ${this.patronymic}`;
  }

  @action
  public setValue(key: keyof User, value: unknown): void {
    if (key !== 'id' && key !== 'name') this[key] = value as never;
  }

  @action
  public removedMarked = (): void => {
    this.delete_marked = true;
    this.setUserStatus(this as unknown as UserResponse);
  };

  @action
  public setUserStatus = (user: UserResponse): void => {
    this.activeStatus = user.activeStatus;
    this.delete_marked = user.delete_marked;
    this.canBeLoggedIn = user.canBeLoggedIn;
    this.isFirstEntry = user.isFirstEntry;
    this.is_fired = user.is_fired;

    switch (true) {
      case user.delete_marked: {
        this.status = USER_STATUS.REMOVED; // 5
        break;
      }
      case user.activeStatus &&
        user.canBeLoggedIn &&
        !user.isFirstEntry &&
        !user.is_fired: {
        this.status = USER_STATUS.ACTIVE; // 1
        break;
      }
      case user.is_fired && !user.delete_marked: {
        this.status = USER_STATUS.FIRED; // 6
        break;
      }
      case !user.canBeLoggedIn || !user.activeStatus: {
        this.status = USER_STATUS.NO_ACCESS; // 3
        break;
      }
      case user.activeStatus && user.canBeLoggedIn && user.isFirstEntry: {
        this.status = USER_STATUS.NOT_ACTIVATED; // 2
        break;
      }
      default:
        this.status = USER_STATUS.NO_ACCESS; // 3
        break;
    }
  };

  private _setName(user: UserResponse) {
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.patronymic = user.patronymic;
  }

  private _setRoles = (user: UserResponse) => {
    this.roles = user.roles.map((e) => new Role(e));
  };
}
