import React from 'react';
import { useService } from '@stores/hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

function RouteTitle() {
  const { location, config } = useService('RoutingService');
  const { t } = useTranslation('layout');

  const [app] = config.getRouteBy({ field: 'group', value: 'ROOT' });
  const [route] = config.getRouteBy({
    field: 'path',
    value: location.pathname,
    routes: app.routes,
    exact: false,
  });

  return <h3>{t(`menu.${route.name}` as any)}</h3>;
}

export default observer(RouteTitle);
