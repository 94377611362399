import { FetchService } from '@modules/Api';

export class RemedDoctorApi extends FetchService {
  public getRemedDoctor = (id: number) => {
    return this.request<'REMED_DOCTOR', RemedDoctor>(
      '/remed_doctor',
      'GET_REMED_DOCTOR',
      { pathParam: id },
    );
  };

  public getRemedDoctorByPhone = (phone: string) => {
    return this.request<'REMED_DOCTOR', FindRemedDoctorResult>(
      '/remed_doctor',
      'GET_REMED_DOCTOR_BY_PHONE',
      {
        pathParam: `phone/${phone}`,
      },
    );
  };

  public remedDoctorSetup = (params: GetRemedDoctorSetup) => {
    return this.request<'REMED_DOCTOR', RemedDoctor>(
      '/remed_doctor',
      'REMED_DOCTOR_SETUP',
      {
        method: 'POST',
        pathParam: 'setup',
        params,
      },
    );
  };

  public deleteRemedDoctor = (userId: number) => {
    return this.request<'REMED_DOCTOR', RemedDoctor>(
      '/remed_doctor',
      'DELETE_REMED_DOCTOR',
      {
        method: 'DELETE',
        pathParam: userId,
      },
    );
  };
}
