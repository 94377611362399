import { Components } from '@components/Docs/RenderDocElement/registration';
import { TextareaProps } from '@components/UI/Textarea';
import { InputProps } from '@components/UI/Input';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { DocTemplateApi } from '@modules/Api/methods';
import { DOC_ELEMENT_TYPE } from '@modules/Documents/consts';
import { ActionMeta } from 'react-select/dist/declarations/src/types';
import { TextProps } from '@components/UI/Text';
import uniqueId from 'lodash/uniqueId';
import { SelectAsyncProps } from '@components/UI/SelectAsync';

type Selected = { label: string; value: number; link?: number; index: number };
type DocComponentType = keyof typeof Components;

export type DocCompProps<T> = {
  component: DocComponentType | null;
  compProps: T;
  defClass?: string[];
};

export class CElement implements DocElement, DocCompProps<unknown> {
  private readonly _getDocElemApi?: DocTemplateApi['getDocElement'];
  public id: Readonly<number>;
  @observable public title;
  public type;
  public choose_mode;
  public element_group;
  public values;
  public unit;
  public component: DocComponentType | null = null;
  public compProps: any;
  public defClass: string[] = [];
  public metaId;
  public position;
  @observable public value: any;
  @observable public children: CElement[] = [];
  @observable public isLoading = false;
  @observable public isDone = false;

  constructor(
    element: DocElement,
    api?: DocTemplateApi['getDocElement'],
    meta?: { id: number; position: number },
  ) {
    makeObservable(this);
    this._getDocElemApi = api;
    this.id = element.id;
    this.title = element.title;
    this.type = element.type;
    this.choose_mode = element.choose_mode;
    this.element_group = element.element_group;
    this.values = element.values?.map((e, index) => ({ ...e, index }));
    this.unit = element.unit;
    this.metaId = element.metaId;
    this.position = meta?.position;
    this._setValue(element);
    this._setComponent(element);
    this._setChildren(element);
  }

  private _setValue(element: DocElement) {
    this.value = element.value;
    if (
      element.type.id === DOC_ELEMENT_TYPE.string ||
      element.type.id === DOC_ELEMENT_TYPE.number
    ) {
      this.value = element.value || '';
    }
  }

  private _setChildren(element: DocElement) {
    this.children =
      element.children && element.children.length
        ? element.children.map((e) => new CElement(e, this._getDocElemApi))
        : [];
  }

  private _setComponentProps = <T>(props: DocCompProps<T>) => {
    this.component = props.component;
    this.compProps = props.compProps;
    runInAction(() => {
      if (props.defClass && props.defClass.length) {
        this.defClass = ['item', ...props.defClass];
      }
    });
  };

  private _setComponent = (element: DocElement) => {
    switch (element.type.id) {
      case DOC_ELEMENT_TYPE.list: {
        return this._setComponentProps<SelectPropsType>({
          defClass: [element.type.title],
          component: 'DocSelect',
          compProps: {
            name: `${element.id}`,
            label: element.title,
            placeholder: element.title,
            value: element.value,
            options: element.values?.map((e, index) => ({
              label: e.value,
              value: e.id,
              link: e.link,
              index,
            })),
            onChange: this._onChangeHandler as any,
            isClearable: true,
            isMulti: element.choose_mode === 1,
            isDisabled: element.isDone,
          },
        });
      }
      case DOC_ELEMENT_TYPE.mkx10: {
        return this._setComponentProps<SelectAsyncProps>({
          defClass: [element.type.title],
          component: 'DocSelectMKX10',
          compProps: {
            name: `${element.id}`,
            label: element.title,
            placeholder: element.title,
            value: element.value,
            onChange: this._onChangeHandler as any,
            isClearable: true,
            isMulti: true,
            isDisabled: element.isDone,
          },
        });
      }
      case DOC_ELEMENT_TYPE.icpc2: {
        return this._setComponentProps<SelectPropsType>({
          defClass: [element.type.title],
          component: 'DocSelectICPC2',
          compProps: {
            name: `${element.id}`,
            label: element.title,
            placeholder: element.title,
            value: element.value,
            onChange: this._onChangeHandler as any,
            isClearable: true,
            isMulti: true,
            isDisabled: element.isDone,
          },
        });
      }
      case DOC_ELEMENT_TYPE.string: {
        return this._setComponentProps<TextareaProps>({
          defClass: [element.type.title],
          component: 'DocTextarea',
          compProps: {
            name: `${element.id}`,
            label: element.title,
            placeholder: element.title,
            value: this.value || '',
            full: true,
            onChange: ({ target: { value } }) => {
              runInAction(() => {
                this.value = value;
                this.compProps.value = value;
              });
            },
            disabled: element.isDone,
          },
        });
      }
      case DOC_ELEMENT_TYPE.number: {
        return this._setComponentProps<InputProps>({
          defClass: [element.type.title],
          component: 'DocInput',
          compProps: {
            name: `${element.id}`,
            label: element.title,
            placeholder: element.title,
            prefix: element.unit,
            value: element.value || '',
            onChange: ({ target: { value } }) => {
              runInAction(() => {
                this.value = value;
                this.compProps.value = value;
              });
            },
            disabled: element.isDone,
          },
        });
      }
      case DOC_ELEMENT_TYPE.text: {
        return this._setComponentProps<TextProps>({
          defClass: [element.type.title],
          component: 'DocText',
          compProps: {
            tag: 'h4',
            children: element.title,
          },
        });
      }
      case DOC_ELEMENT_TYPE.separator: {
        return this._setComponentProps({
          defClass: [element.type.title],
          component: 'DocSeparator',
          compProps: undefined,
        });
      }
      default: {
        return null;
      }
    }
  };

  @action
  private _onChangeHandler = async (
    value: Selected,
    meta: ActionMeta<Selected>,
    id: number,
  ) => {
    const findEl = (els: CElement[]) => {
      if (this.id === id) return this;
      return els.reduce((p, n) => {
        if (n.id === id) return n;
        if (n.children.length) p = findEl(n.children);
        return p;
      }, {} as CElement);
    };

    const element = findEl(this.children);
    const { action, option, removedValue } = meta;
    const genId = +uniqueId();

    element.value = value;
    element.compProps.value = value;

    const getElementReq = async (linkId: number) => {
      return await new Promise<DocElement>(async (resolve, reject) => {
        runInAction(() => (element.isLoading = true));
        const res = await this._getDocElemApi?.(linkId);
        if (res?.success && res.data) resolve(res.data);
        if (res?.errors.length) reject(res.errors);
        runInAction(() => (element.isLoading = false));
      });
    };

    if (
      action === 'select-option' &&
      value &&
      !value.link &&
      !element.compProps.isMulti
    ) {
      runInAction(() => (element.children = []));
    }

    if (
      action === 'select-option' &&
      !element.compProps.isMulti &&
      value &&
      value.link
    ) {
      const newElement = await getElementReq(value.link);
      runInAction(() => {
        element.children = [new CElement(newElement, this._getDocElemApi)];
      });
    }

    if (action === 'select-option' && option?.link) {
      const newElement = await getElementReq(option.link);
      const metaId = `${meta.option?.index}-${newElement.id}`;

      // const TextElement = {
      //   id: genId + +new Date(),
      //   metaId,
      //   choose_mode: DOC_ELEMENT_MODE.single,
      //   element_group: { id: 1, title: '' },
      //   title: startCase(
      //     toLower(
      //       element.values?.find((e) => {
      //         return `${e.index}-${e.link}` === metaId;
      //       })?.value || '',
      //     ),
      //   ),
      //   type: { id: DOC_ELEMENT_TYPE.text, title: 'text' },
      //   values: undefined,
      //   value: undefined,
      //   children: [],
      // };

      runInAction(() => {
        element.children = [
          ...element.children,
          //  new CElement(TextElement),
          new CElement(
            { ...newElement, id: genId, metaId },
            this._getDocElemApi,
          ),
        ];
      });
    }

    if (action === 'remove-value') {
      const metaId = `${removedValue?.index}-${removedValue?.link}`;
      runInAction(() => {
        element.children = element.children.filter((e) => e?.metaId !== metaId);
        // .filter((e) => e.metaId !== metaId);
      });
    }

    if (action === 'clear') {
      runInAction(() => (element.children = []));
    }
  };
}
