import { FetchService } from '@modules/Api';

export class HealthFacilityApi extends FetchService {
  public getHealthFacilities = (query?: GetHealthFacilityQuery) => {
    return this.request<
      'HEALTH_FACILITY',
      HealthFacility[],
      GetHealthFacilityQuery
    >('/healthFacility', 'GET_HEALTH_FACILITIES', {
      query,
      permit: 'health_facility',
    });
  };

  public getHealthFacility = (id: number) => {
    return this.request<'HEALTH_FACILITY', HealthFacility>(
      '/healthFacility',
      'GET_HEALTH_FACILITY',
      { pathParam: id, permit: 'health_facility' },
    );
  };

  public createHealthFacility = (params: CreateHealthFacility) => {
    return this.request<
      'HEALTH_FACILITY',
      HealthFacility,
      CreateHealthFacility
    >('/healthFacility', 'CREATE_HEALTH_FACILITY', {
      method: 'POST',
      params,
      permit: 'health_facility',
    });
  };

  public updateHealthFacility = (id: number, params: UpdateHealthFacility) => {
    return this.request<
      'HEALTH_FACILITY',
      HealthFacility,
      UpdateHealthFacility
    >('/healthFacility', 'UPDATE_HEALTH_FACILITY', {
      method: 'PUT',
      params,
      pathParam: id,
      permit: 'health_facility',
    });
  };

  public deactivateHealthFacility = (id: number) => {
    return this.request<'HEALTH_FACILITY'>(
      '/healthFacility',
      'DEACTIVATE_HEALTH_FACILITY',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'health_facility',
      },
    );
  };

  public activateHealthFacility = (id: number) => {
    return this.request<'HEALTH_FACILITY'>(
      '/healthFacility',
      'ACTIVATE_HEALTH_FACILITY',
      {
        method: 'PUT',
        pathParam: `${id}/activate`,
        permit: 'health_facility',
      },
    );
  };

  /*
 |----------------------------------------------------------------------------
 | HEALTH FACILITY TYPE
 |----------------------------------------------------------------------------
 */
  public getHealthFacilityTypes = (query: GetHealthFacilityTypeQuery) => {
    return this.request<
      'HEALTH_FACILITY_TYPE',
      HealthFacilityType[],
      GetHealthFacilityTypeQuery
    >('/healthFacilityType', 'GET_HEALTH_FACILITY_TYPE', {
      query,
      permit: 'health_facility_type',
    });
  };

  public createHealthFacilityType = (params: CreateHealthFacilityType) => {
    return this.request<
      'HEALTH_FACILITY_TYPE',
      HealthFacilityType,
      CreateHealthFacilityType
    >('/healthFacilityType', 'CREATE_HEALTH_FACILITY_TYPE', {
      method: 'POST',
      params,
      permit: 'health_facility_type',
    });
  };

  public updateHealthFacilityType = (
    id: number,
    params: UpdateHealthFacilityType,
  ) => {
    return this.request<
      'HEALTH_FACILITY_TYPE',
      HealthFacilityType,
      UpdateHealthFacilityType
    >('/healthFacilityType', 'UPDATE_HEALTH_FACILITY_TYPE', {
      method: 'PUT',
      params,
      pathParam: id,
      permit: 'health_facility_type',
    });
  };

  public deleteHealthFacilityType = (id: number) => {
    return this.request<'HEALTH_FACILITY_TYPE'>(
      '/healthFacilityType',
      'DELETE_HEALTH_FACILITY_TYPE',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'health_facility_type',
      },
    );
  };
}
