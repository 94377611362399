import { action, makeObservable, observable } from 'mobx';

export class Episodes {
  @observable public episode: MedEpisode | undefined;
  @observable public list: MedEpisode[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setEpisode = (episode: MedEpisode) => {
    this.episode = episode;
  };

  @action
  public setEpisodes = (episodes: MedEpisode[]) => {
    this.list = episodes;
  };
}
