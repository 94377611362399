import { ButtonProps } from '@components/UI/Button/Button';
import { TextProps } from '@components/UI/Text';
import PicError from '@assets/img/preview/fail.png';
import { DocChapterItem } from '@modules/Documents/DocChapter';
import { isString } from 'lodash';

export const modalTitle = {
  default: (props: TextProps): TextProps => {
    return {
      tag: 'h2',
      className: 'mb-3',
      ...props,
    };
  },
  subtitle: (props: TextProps): TextProps => {
    return {
      subtitle: 'subtitle-1',
      className: 'mb-3',
      ...props,
    };
  },
};

export const modalMessage = {
  default: (props: TextProps): TextProps => {
    return {
      body: 'body-2',
      className: 'mb-3',
      ...props,
    };
  },
  expander: (props: TextProps): TextProps => {
    return {
      tag: 'h6',
      type: 'secondary',
      ...props,
    };
  },
};

export const modalButton = {
  close: ({ close }: ModalActions): ButtonProps => {
    return {
      type: 'primary',
      children: 'OK',
      onClick: () => close(),
      className: 'w-4/12',
    };
  },
};

export const modalComponent = {
  default: (params: ModalDefaultProps, actions: ModalActions): ModalProps => {
    const {
      title = { children: 'Info' },
      message,
      buttons,
      image,
      svgIcon,
      closable,
      debug,
      onClose,
      BodyComponent,
    } = params;

    return {
      component: 'Default',
      title: modalTitle.default(isString(title) ? { children: title } : title),
      message: modalMessage.default({
        children: message,
      }),
      svgIcon,
      image,
      BodyComponent,
      buttons: buttons ?? [modalButton.close(actions)],
      closable,
      actions,
      debug,
      onClose,
    };
  },

  error: (params: ModalErrorProps, actions: ModalActions): ModalProps => {
    const {
      title,
      image = PicError,
      message,
      onClose,
      closable = true,
      buttons,
      showDetails = true,
    } = params;

    return {
      component: 'ErrorModal',
      title: modalTitle.subtitle({
        children: title ?? 'System Error',
      }),
      message: modalMessage.expander({
        children: message,
      }),
      image,
      buttons: buttons || [
        {
          type: 'primary',
          children: 'OK',
          onClick: () => {
            actions.close();
          },
          className: 'w-4/12',
        },
      ],
      showDetails,
      closable,
      actions,
      onClose,
    };
  },

  confirm: (props: ModalConfirmProps, actions: ModalActions): ModalProps => {
    const {
      title,
      message,
      callbacks,
      buttons,
      options,
      closable = true,
      bodyComponent,
      width,
      image,
      svgIcon = 'PicSecurity',
      onClose,
    } = props;

    return {
      testID: 'modal_confirm',
      component: 'Default',
      title: modalTitle.default({
        children: title ?? 'Confirm?',
      }),
      message: modalMessage.default({
        children: message,
      }),
      image,
      svgIcon,
      buttons: buttons ?? [
        {
          children: 'Так',
          className: 'mr-2',
          onClick: () => callbacks?.onConfirm(),
          testID: 'modal_confirm-confirm',
          ...options?.confirmButton,
        },
        {
          children: 'Нi',
          type: 'warning',
          onClick: () => callbacks?.onCancel(),
          testID: 'modal_confirm-cancel',
          ...options?.cancelButton,
        },
      ],
      closable,
      actions,
      BodyComponent: bodyComponent,
      width,
      onClose,
    };
  },

  grantingAccess: (
    props: ModalGrantingAccessProps,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'GrantingAccessModal',
      title: modalTitle.default({
        children: 'granting_access.title',
      }),
      actions,
      data: props,
    };
  },

  changeUserEmail: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'ChangeUserEmailModal',
      title: modalTitle.default({
        children: 'change_user_email.title',
      }),
      message: modalMessage.default({
        children: 'change_user_email.message',
      }),
      actions,
    };
  },

  copyPriceCost: (
    data: DrawerPriceParams,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'CopyPriceModal',
      title: modalTitle.default({
        children: 'copy_price.title',
      }),
      message: modalMessage.default({
        children: 'copy_price.message',
      }),
      data,
      actions,
    };
  },

  copyPriceCostPercent: (
    data: DrawerPriceParams,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'CopyPriceCostPercentModal',
      title: modalTitle.default({
        children: 'copy_price_cost_percent.title',
      }),
      data,
      actions,
    };
  },

  copyPriceCostFixed: (
    data: DrawerPriceParams,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'CopyPriceFixedModal',
      title: modalTitle.default({
        children: 'copy_price_fixed.title',
      }),
      data,
      actions,
    };
  },

  copyPriceCostRounding: (
    data: DrawerPriceParams,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'CopyPriceRoundingModal',
      title: modalTitle.default({
        children: 'copy_price_rounding.title',
      }),
      data,
      actions,
    };
  },
  priceCostRemove: (
    data: DrawerPriceParams,
    actions: ModalActions,
  ): ModalProps => {
    return <ModalProps>{
      component: 'PriceCostRemoveModal',
      title: modalTitle.default({
        children: 'price_cost_remove.title',
      }),
      data,
      actions,
    };
  },
  previewDocChapter: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'PreviewDocChapterModal',
      title: modalTitle.default({
        children: 'preview_doc_chapter.title',
      }),
      width: 1024,
      actions,
    };
  },
  previewDocTemplate: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'PreviewDocTemplateModal',
      title: modalTitle.default({
        children: 'preview_doc_template.title',
      }),
      width: 1024,
      actions,
    };
  },
  closeMedEpisode: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'CloseMedEpisodeModal',
      title: modalTitle.default({
        children: 'close_med_episode.title',
      }),
      actions,
    };
  },
  updateMedEpisode: (
    actions: ModalActions,
    field: 'title' | 'doctor',
  ): ModalProps => {
    return <ModalProps>{
      component: 'UpdateMedEpisodeModal',
      title: modalTitle.default({
        children:
          field === 'title'
            ? 'update_med_episode.edit_name_title'
            : 'update_med_episode.change_doctor_title',
      }),
      actions,
      data: { field },
    };
  },
  selectLabServices: (
    props: ModalSelectLabSecondaryProps,
    actions: ModalActions,
  ): ModalProps => {
    return {
      actions: actions,
      component: 'SelectLabSecondaryModal',
      title: modalTitle.default({
        children: 'select_lab_service.title',
      }),
      message: modalMessage.default({
        children: 'select_lab_service.message',
      }),
      buttons: [],
      data: props,
      onClose: props.onClose,
    };
  },
  createTemplate: (
    props: ModalCreateTemplate,
    actions: ModalActions,
  ): ModalProps => {
    return {
      actions: actions,
      component: 'CreateTemplateModal',
      title: modalTitle.default({
        children: 'create_template.title',
      }),
      message: modalMessage.default({
        children: 'create_template.message',
      }),
      buttons: [],
      data: props,
    };
  },
  zReport: (actions: ModalActions, data: ModalZReportProps): ModalProps => ({
    actions: actions,
    component: 'ZReportModal',
    title: modalTitle.default({
      children: 'z_report.title',
    }),
    message: modalMessage.default({
      children: '',
    }),
    buttons: [],
    data,
  }),
  createPatient: (
    actions: ModalActions,
    props: ModalCreatePatientProps,
  ): ModalProps => ({
    actions: actions,
    component: 'CreatePatientModal',
    title: modalTitle.default({
      children: 'create_patient.title',
    }),
    message: modalMessage.default({
      children: '',
    }),
    buttons: [],
    data: props,
    onClose: props.onClose,
  }),
  updatePatientCreditTrust: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'UpdatePatientCreditTrustModal',
      title: modalTitle.default({
        children: 'update_med_episode.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
    };
  },
  printOrder: (
    actions: ModalActions,
    { onClose, ...data }: ModalPrintOrderProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'PrintOrderModal',
      title: modalTitle.default({
        children: null,
      }),
      message: modalMessage.default({
        children: null,
      }),
      actions,
      data,
      onClose,
    };
  },
  printBarCodes: (
    actions: ModalActions,
    data: ModalPrintBarCodesProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'PrintBarCodesModal',
      title: modalTitle.default({
        children: null,
      }),
      message: modalMessage.default({
        children: null,
      }),
      classNames: 'print-barcode-modal',
      actions,
      data,
    };
  },
  appointmentProgress: (
    actions: ModalActions,
    data: ModalAppointmentProgressProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'AppointmentProgressModal',
      title: modalTitle.default({
        children: null,
      }),
      message: modalMessage.default({
        children: null,
      }),
      actions,
      data,
      onClose: data.onClose,
    };
  },
  selectLabServiceError: (
    actions: ModalActions,
    data?: ModalSelectLabServiceErrorProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'SelectLabServiceErrorModal',
      title: modalTitle.default({
        type: 'danger',
        children: 'select_lab_service_error.title',
      }),
      message: modalMessage.default({
        children: null,
      }),
      actions,
      data,
      onClose: data?.onClose,
    };
  },
  updateDocChapterName: (actions: ModalActions, data: DocChapterItem) => {
    return <ModalProps>{
      component: 'UpdateDocChapterNameModal',
      title: modalTitle.default({
        children: 'update_doc_chapter_name.title',
      }),
      actions,
      data,
    };
  },
  payModalConfirmation: (
    actions: ModalActions,
    data: ModalPayOrderConfirmation,
  ) => {
    return <ModalProps>{
      component: 'PayOrderConfirmationModal',
      title: modalTitle.default({
        children: 'pay_order_confirmation.title',
      }),
      actions,
      data,
    };
  },
  redirectBiomaterial: (
    actions: ModalActions,
    data: ModalRedirectBiomaterialProps,
  ) => {
    return <ModalProps>{
      component: 'RedirectBiomaterialModal',
      title: modalTitle.default({
        children: 'redirect_biomaterial.title',
      }),
      actions,
      data,
    };
  },
  invalidBiomaterial: (
    actions: ModalActions,
    data: ModalInvalidBiomaterialProps,
  ) => {
    return <ModalProps>{
      component: 'InvalidBiomaterialModal',
      title: modalTitle.default({
        children: 'invalid_biomaterial.title',
        type: 'warning',
      }),
      actions,
      data,
    };
  },
  printBioBarCodes: (
    actions: ModalActions,
    data: ModalPrintBioBarCodesProps,
  ) => {
    return <ModalProps>{
      component: 'PrintBioBarCodeModal',
      title: modalTitle.default({
        children: 'print_bar_code.title',
      }),
      actions,
      data,
    };
  },
  labIncorrectTest: (actions: ModalActions) => {
    return <ModalProps>{
      component: 'LabIncorrectTestModal',
      title: modalTitle.default({
        children: 'lab_incorrect_test.title',
      }),
      message: modalMessage.default({
        children: 'lab_incorrect_test.message',
      }),
      actions,
    };
  },
  biomaterialServiceUploadFile: (
    actions: ModalActions,
    data: BioWorkplace.BioService,
  ) => {
    return <ModalProps>{
      component: 'BiomaterialServiceUploadFileModal',
      title: modalTitle.default({
        children: 'biomaterial_service_upload_file.title',
      }),
      message: modalMessage.default({
        children: 'biomaterial_service_upload_file.message',
      }),
      actions,
      data,
    };
  },
  noPatientEmail: (actions: ModalActions, patientId: number) => {
    return <ModalProps>{
      component: 'NoPatientEmailModal',
      title: modalTitle.default({
        children: 'no_patient_email.title',
        type: 'danger',
      }),
      message: modalMessage.default({
        children: 'no_patient_email.message',
      }),
      actions,
      data: patientId,
    };
  },
  addBioPregnancyPeriod: (actions: ModalActions) => {
    return <ModalProps>{
      component: 'AddBioPregnancyPeriodModal',
      title: modalTitle.default({
        children: 'add_bio_pregnancy_period.title',
      }),
      actions,
    };
  },
  removeOrderService: (
    actions: ModalActions,
    { onClose, ...data }: ModalRemoveOrderServiceProps,
  ) => {
    return <ModalProps>{
      component: 'RemoveOrderServiceModal',
      title: modalTitle.default({
        children: 'remove_order_service.title',
      }),
      actions,
      data,
      onClose,
    };
  },
  finishAppointmentModal: (
    actions: ModalActions,
    data: ModalFinishAppointmentProps,
  ) => {
    return <ModalProps>{
      component: 'FinishAppointmentModal',
      title: modalTitle.default({
        children: 'finish_appointment.title',
      }),
      actions,
      data,
    };
  },
  downloadPrinter: (
    actions: ModalActions,
    props: ModalDownloadPrinterProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'DownloadPrinterModal',
      title: modalTitle.default({
        children: 'download_printer.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data: props,
    };
  },
  downloadAnalyser: (
    actions: ModalActions,
    props: ModalDownloadAnalyserProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'DownloadAnalyserModal',
      title: modalTitle.default({
        children: 'download_analyser.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data: props,
    };
  },
  addRemedAccount: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'AddRemedAccountModal',
      title: modalTitle.default({
        children: 'add_remed_account.title',
      }),
      width: 450,
      message: modalMessage.default({
        children: 'add_remed_account.description',
      }),
      actions,
    };
  },
  addPatientRemedAccount: (actions: ModalActions): ModalProps => {
    return <ModalProps>{
      component: 'AddPatientRemedAccount',
      title: modalTitle.default({
        children: 'add_patient_remed_account.title',
      }),
      width: 450,
      message: modalMessage.default({
        children: 'add_patient_remed_account.description',
      }),
      actions,
    };
  },
  addDirectionComment: (
    actions: ModalActions,
    props: ModalAddCommentToDirectionProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'AddCommentToDirectionModal',
      title: modalTitle.default({
        children: 'add_comment_to_direction.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data: props,
    };
  },
  selectBiomaterialCabinet: (
    actions: ModalActions,
    props: ModalSelectBioCabinetProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'SelectBiomaterialCabinetModal',
      title: modalTitle.default({
        children: 'select_biomaterial_cabinet.title',
      }),
      actions,
      data: props,
    };
  },
  activateCounterparty: (
    actions: ModalActions,
    props: ModalActivateCounterpartyProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'ActivationCounterpartyModal',
      title: modalTitle.default({ children: '' }),
      actions,
      data: props,
    };
  },
  onReFinalizeComment: (
    actions: ModalActions,
    data: ModalOnReFinalizeProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'OnReFinalizeCommentModal',
      title: modalTitle.default({
        children: 'on_refinalize_comment.title',
      }),
      message: modalMessage.default({
        children: 'on_refinalize_comment.description',
      }),
      width: 450,
      actions,
      data,
    };
  },
  updateConfirmedScheduleStaff: (
    actions: ModalActions,
    data: ModalUpdateConfirmedScheduleStaffProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'UpdateConfirmedScheduleStaffModal',
      title: modalTitle.default({
        children: 'update_confirmed_schedule_staff.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data,
    };
  },
  onDeleteScheduleEvent: (
    actions: ModalActions,
    data: ModalDeleteScheduleEventProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'OnDeleteScheduleEventModal',
      title: modalTitle.default({
        children: 'on_delete_schedule_event.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      width: 450,
      actions,
      data,
    };
  },
  createExternalDirection: (
    actions: ModalActions,
    props: ModalExternalDirectionProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'CreateExternalDirectionModal',
      title: modalTitle.default({
        children: 'create_external_direction.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data: props,
    };
  },
  sendingBiomaterialError: (
    actions: ModalActions,
    props: ModalSendingBiomaterialErrorProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'SendingBiomaterialErrorModal',
      title: modalTitle.default({
        children:
          props.biomaterials.length > 1
            ? 'sending_biomaterial_error.many_title'
            : 'sending_biomaterial_error.single_title',
        type: 'warning',
      }),
      actions,
      data: props,
    };
  },
  getDocRefinalization: (
    actions: ModalActions,
    data: ModalDocRefinalizationProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'DocRefinalizationModal',
      title: modalTitle.default({
        children: 'modals:doc_refinalization.title',
      }),
      message: modalMessage.default({
        children: 'modals:doc_refinalization.description',
      }),
      actions,
      data,
    };
  },
  editDateTimeScheduledCall: (
    actions: ModalActions,
    data: ScheduledCall,
  ): ModalProps => {
    return <ModalProps>{
      component: 'EditDateTimeScheduledCallModal',
      title: modalTitle.default({
        children: 'edit_date_time_scheduled_call.title',
      }),
      message: modalMessage.default({
        children: '',
      }),
      actions,
      data,
    };
  },
  updatePackageServicePrice: (
    actions: ModalActions,
    data: ModalUpdatePackageServicePrice,
  ): ModalProps => {
    return <ModalProps>{
      component: 'UpdatePackageServicePriceModal',
      title: modalTitle.default({
        children: 'update_service_package_price.title',
      }),
      message: modalMessage.default({
        children: 'update_service_package_price.description',
      }),
      actions,
      data,
    };
  },
  updatePackageServiceLimit: (
    actions: ModalActions,
    data?: ModalUpdatePackageServiceLimit,
  ): ModalProps => {
    return <ModalProps>{
      component: 'UpdatePackageServiceLimitModal',
      title: modalTitle.default({
        children: 'update_package_service_limit.title',
      }),

      actions,
      data,
    };
  },
  updatePackageServiceCount: (
    actions: ModalActions,
    data: ModalUpdatePackageServiceCount,
  ): ModalProps => {
    return <ModalProps>{
      component: 'UpdatePackageServiceCountModal',
      title: modalTitle.default({
        children: 'update_package_service_count.title',
      }),
      message: modalMessage.default({
        children: data.title,
      }),
      actions,
      data,
    };
  },
  serviceInCalendarPackage: (
    actions: ModalActions,
    data: ModalServiceInPackageProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'ServiceInPackageCalendarModal',
      title: modalTitle.default({
        children: 'service_in_package_calendar.title',
      }),
      actions,
      data,
      onClose: data.onClose,
    };
  },
  serviceInAnalysesPackage: (
    actions: ModalActions,
    data: ModalServiceInLabPackageProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'ServiceInPackageAnalysesModal',
      title: modalTitle.default({
        children: 'service_in_package_analyses.title',
      }),
      actions,
      data,
      onClose: data.onClose,
    };
  },
  serviceInWorkplacePackage: (
    actions: ModalActions,
    data: ModalServiceInPackageWorkplaceProps,
  ): ModalProps => {
    return <ModalProps>{
      component: 'ServiceInPackageWorkplaceModal',
      title: modalTitle.default({
        children: 'service_in_package_workplace.title',
      }),
      actions,
      data,
      onClose: data.onClose,
    };
  },
};
