import { FetchService } from '@modules/Api';

export class ReferenceValuesApi extends FetchService {
  public getReferentValues = (query?: GetReferenceValuesQuery) => {
    return this.request<
      'REFERENCE_VALUES',
      ReferenceValue[],
      GetReferenceValuesQuery
    >('/reference_values', 'GET_REFERENCE_VALUES', { query });
  };

  public getReferentValue = (id: number) => {
    return this.request<'REFERENCE_VALUES', ReferenceValue>(
      '/reference_values',
      'GET_REFERENCE_VALUE',
      { pathParam: id },
    );
  };

  public createReferentValue = (params?: CreateReferenceValue) => {
    return this.request<'REFERENCE_VALUES', ReferenceValue>(
      '/reference_values',
      'CREATE_REFERENCE_VALUE',
      { method: 'POST', params },
    );
  };

  public updateReferentValue = (
    id: number,
    params: UpdateReferenceValueParams,
  ) => {
    return this.request<'REFERENCE_VALUES', ReferenceValue>(
      '/reference_values',
      'UPDATE_REFERENCE_VALUE',
      { method: 'PUT', params, pathParam: id },
    );
  };

  public deleteReferentValue = (id: number) => {
    return this.request<'REFERENCE_VALUES'>(
      '/reference_values',
      'DELETE_REFERENCE_VALUE',
      { method: 'DELETE', pathParam: id },
    );
  };
}
