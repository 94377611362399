import { LStorage } from '@modules/Storage';
import { action, autorun, makeObservable, observable } from 'mobx';

export class ProfileStore {
  protected readonly _lStorage = LStorage;
  @observable public profile?: Profile | null = null;
  @observable public profileMeta?: JwtData | null = this._lStorage.getItem(
    'PROFILE_META',
    true,
  );

  constructor() {
    makeObservable(this);
    autorun(() => {
      this._lStorage.sync('PROFILE_META', this.profileMeta);
    });
  }

  @action
  public logout = () => {
    this.profile = null;
    this.profileMeta = null;
  };

  @action
  public setProfile = (profile: Profile): void => {
    this.profile = profile;
    const { last_name, first_name, patronymic } = profile;
    this.profile.fullName = `${last_name} ${first_name} ${patronymic}`;
  };

  @action
  public setUserMeta = (userMeta: JwtData): void => {
    this.profileMeta = userMeta;
  };
}
