import { action } from 'mobx';
import InsurancePolicyStore from './insurance-policy.store';

export default class InsurancePolicyService extends InsurancePolicyStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  @action
  public getPoliciesReq = async (query?: GetInsurancePolicyParams) => {
    const res = await this._store.InsuranceApi.getInsurancePolicies(query);
    if (res.success && res.data) {
      this.setPolicies(res.data, res.meta);
    }
    return res;
  };

  @action
  public getPolicyReq = async (id: number) => {
    const res = await this._store.InsuranceApi.getInsurancePolicy(id);
    if (res.success && res.data) {
      this.setPolicy(res.data);
    }
    return res;
  };

  @action
  public updatePolicyReq = async (
    id: number,
    params: UpdateInsurancePolicyParams,
  ) => {
    const res = await this._store.InsuranceApi.updateInsurancePolicy(
      id,
      params,
    );
    if (res.success && res.data) {
      this.updatePolicy(res.data);
    }
    return res;
  };

  @action
  public createPolicyReq = async (params: CreateInsurancePolicyParams) => {
    const res = await this._store.InsuranceApi.createInsurancePolicy(params);
    if (res.success && res.data) {
      this.addPolicy(res.data);
    }
    return res;
  };

  @action
  public activatePolicyReq = async (id: number) => {
    const res = await this._store.InsuranceApi.activateInsurancePolicy(id);
    if (res.success && res.data) {
      this.activatePolicy(res.data);
    }
    return res;
  };

  @action
  public deactivatePolicyReq = async (id: number) => {
    const res = await this._store.InsuranceApi.deactivateInsurancePolicy(id);
    if (res.success && res.data) {
      this.deactivatePolicy(res.data);
    }
    return res;
  };
}
