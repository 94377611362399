export const DOC_ELEMENT_TYPE = <const>{
  list: 1,
  string: 2,
  number: 3,
  text: 44,
  icpc2: 4,
  mkx10: 5,
  separator: 6,
};

export const DOC_ELEMENT_MODE = <const>{
  single: 0,
  multi: 1,
};

export const DOC_TEMPLATE_STATUS = <const>{
  draft: 0,
  active: 1,
};
