import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';

export default class EntityStore {
  @observable public entities: Entity[] = [];
  @observable public entitiesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setEntities = (entities: Entity[], meta: ResponseMeta): void => {
    this.entities = sortBy(entities, ['name'], ['asc']) as Entity[];
    this.entitiesMeta = meta;
  };
}
