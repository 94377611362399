import { ProfileStore } from '@modules/Profile/profile.store';

export class ProfileService extends ProfileStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getProfileReq = async () => {
    const res = await this._store.ApiService.getProfile();
    const isRoot = this._store.StorageService.LStorage.getItem('IS_ROOT');
    if (res.success && res.data) {
      this.setProfile(res.data);
      this._store.PermissionsService.setIsAdmin(res.data.isRoot || !!isRoot);
      this._store.PermissionsService.setAllowedRoutes(res.data.ui);
      if (res.data.permissions?.length) {
        this._store.PermissionsService.setCrudPermissions(res.data.permissions);
      }
    }
    return res;
  };
}
