import React, { memo } from 'react';
import { Icon } from '@components/UI/Icon';
import Text from '@components/UI/Text/Text';
import Debug from '@components/UI/Debug';
import ModalButtons from '@components/UI/Modal/components/ModalButtons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const ModalPart = {
  header: function header({ children, className }: Props) {
    return <div className={cn('modal-header', className)}>{children}</div>;
  },
  body: function header({ children, className }: Props) {
    return <div className={cn('modal-body', className)}>{children}</div>;
  },
  footer: function footer({ children, className }: Props) {
    return <div className={cn('modal-footer', className)}>{children}</div>;
  },
};

export type ModalBaseProps = {
  config: ModalProps;
  className?: string;
  header?: () => React.JSX.Element;
  body?: () => React.JSX.Element;
  footer?: () => React.JSX.Element;
  children?: React.ReactNode;
};

function Modal({
  className,
  header,
  body,
  footer,
  children,
  config: {
    closable = true,
    actions,
    svgIcon,
    image,
    title,
    debug,
    message,
    buttons,
    BodyComponent,
    testID,
  },
}: ModalBaseProps) {
  const { t } = useTranslation('modals');
  if (children) {
    return (
      <div className={cn(['modal-slot', className])} data-testid={testID}>
        {closable && (
          <div onClick={actions.close} className="closable">
            <Icon name="Close" size="small" className="text-coolGray-500" />
          </div>
        )}
        {children}
      </div>
    );
  }

  return (
    <div className={cn(['modal-slot', className])} data-testid={testID}>
      {closable && (
        <div onClick={actions.close} className="closable">
          <Icon name="Close" size="small" className="text-coolGray-500" />
        </div>
      )}

      <ModalPart.header>
        <>
          {!header && svgIcon && (
            <div className="modal-image">
              <Icon name={svgIcon} size="modal" />
            </div>
          )}
          {!header && image && (
            <div className="modal-image mb-4">
              <img src={image} className="my-0 mx-auto" alt="pic" />
            </div>
          )}
          {!header && <Text {...title}>{t(title.children as any)}</Text>}
          {debug && <Debug data={debug} className="modal-debug" />}
          {header && header()}
        </>
      </ModalPart.header>

      {!BodyComponent && (
        <ModalPart.body>
          {message && <Text {...message} />}
          {body && body()}
        </ModalPart.body>
      )}

      {BodyComponent && (
        <div>
          {message && <Text {...message} />}
          <BodyComponent />
        </div>
      )}

      <ModalPart.footer>
        {buttons && !footer && <ModalButtons buttons={buttons} />}
        {footer && footer()}
      </ModalPart.footer>
    </div>
  );
}

export default memo(Modal);
