import { FetchService } from '@modules/Api';

export class ArchiveDocsApi extends FetchService {
  public getArchiveDocs = (query?: GetArchiveDocsQuery) => {
    return this.request<
      'ARCHIVE_DOCUMENT',
      ArchiveDoc[],
      null,
      GetArchiveDocsQuery
    >('/archive_document', 'GET_ARCHIVE_DOCUMENTS', { query });
  };

  public getArchiveDoc = (id: number) => {
    return this.request<'ARCHIVE_DOCUMENT', ArchiveDoc>(
      '/archive_document',
      'GET_ARCHIVE_DOCUMENT',
      { pathParam: id },
    );
  };

  public updateArchiveDoc = (id: number, params: UpdateArchiveDoc) => {
    return this.request<'ARCHIVE_DOCUMENT', ArchiveDoc, UpdateArchiveDoc>(
      '/archive_document',
      'UPDATE_ARCHIVE_DOCUMENT',
      { method: 'PUT', pathParam: id, params },
    );
  };

  public uploadArchiveDoc = (params: FormData) => {
    return this.request<'ARCHIVE_DOCUMENT', ArchiveDoc, FormData>(
      '/archive_document',
      'UPLOAD_ARCHIVE_DOCUMENT',
      {
        method: 'POST',
        params,
      },
    );
  };

  public downloadArchiveDoc = (id: number) => {
    return this.request<'ARCHIVE_DOCUMENT', BlobResponse>(
      '/archive_document',
      'DOWNLOAD_ARCHIVE_DOCUMENT',
      { pathParam: `${id}/download`, blob: true },
    );
  };
}
