import React, { useState, forwardRef, memo, useRef } from 'react';
import cn from 'classnames';
import { Popover, ArrowContainer, PopoverPosition } from 'react-tiny-popover';
import { Text } from '@components/UI/Text';
import './Tooltip.scss';

export type TooltipPopoverProps = {
  content?: string;
  contentElement?: React.ReactNode;
  children: React.ReactNode | null;
  className?: string;
  contentClassName?: string;
  timeout?: number;
  position?: PopoverPosition | PopoverPosition[];
  testID?: string;
  disabled?: boolean;
};

const CustomComponent = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(function Custom(props, ref) {
  return (
    <div className="size-full" ref={ref}>
      {props.children}
    </div>
  );
});

const Tooltip: React.FC<TooltipPopoverProps> = ({
  content,
  contentElement,
  children,
  className,
  contentClassName,
  testID,
  timeout = 200,
  position = 'bottom',
  disabled = false,
}: TooltipPopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  function onOpen() {
    if (timer.current) return;
    timer.current = setTimeout(open, timeout);
  }

  function onClose() {
    close();

    if (!timer.current) return;
    clearTimeout(timer.current);
    timer.current = null;
  }

  if (disabled) {
    return (
      <div
        className={cn('rm-tooltip', className)}
        data-testid="tooltip-disabled"
      >
        <div className="rm-tooltip__trigger">{children}</div>
      </div>
    );
  }

  return (
    <div className={cn('rm-tooltip', className)} data-testid={testID}>
      <Popover
        positions={position}
        align="center"
        isOpen={isOpen}
        padding={10}
        containerClassName="rm-tooltip__container"
        onClickOutside={onClose}
        content={({ childRect, popoverRect, position }) => {
          return (
            <ArrowContainer
              arrowColor="#2563EB"
              arrowSize={8}
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowClassName={cn(
                'rm-tooltip__arrow',
                childRect.width ? 'visible' : 'hidden',
              )}
            >
              <div
                className={cn(
                  'rm-tooltip__content',
                  position,
                  contentClassName,
                )}
                onMouseOut={onClose}
              >
                {contentElement ? contentElement : null}

                {content && (
                  <Text tag="p" className="text-white text-sm">
                    {content}
                  </Text>
                )}
              </div>
            </ArrowContainer>
          );
        }}
      >
        <CustomComponent>
          <div
            className="rm-tooltip__trigger"
            onMouseOut={onClose}
            onMouseOver={onOpen}
          >
            {children}
          </div>
        </CustomComponent>
      </Popover>
    </div>
  );
};

export default memo(Tooltip);
