export const UNAUTHORIZED_ERROR: ServerErrorResponse = {
  data: null,
  meta: {},
  links: { self: '' },
  errors: [
    {
      status: 401,
      title: 'Authentication',
      detail: '401 Unauthorized',
    },
  ],
  success: false,
};

export const DEFAULT_ERROR = (error: any): ServerErrorResponse => ({
  data: null,
  meta: {},
  links: { self: '' },
  errors: [
    {
      status: 500,
      title: 'DEFAULT_ERROR_MESSAGE',
      detail: JSON.stringify(error),
    },
  ],
  success: false,
});

export const BLOB_RESPONSE = async (
  file: Blob,
  filename: string,
): Promise<HttpResponse<any>> => ({
  data: { file, filename },
  errors: [],
  links: { self: '' },
  meta: {
    totalPages: undefined,
    totalRecords: undefined,
  },
  success: true,
});

export const SUCCESS_204_RESPONSE: HttpResponse<any> = {
  data: null,
  errors: [],
  links: { self: '' },
  meta: {
    totalPages: undefined,
    totalRecords: undefined,
  },
  success: true,
};

export const PERMISSION_DENIED = (key: LoadingKey): HttpResponse<any> => ({
  data: null,
  errors: [
    {
      status: 403,
      title: 'PERMISSION_ERROR',
      detail: `You do not have access to the resources. [${key}]`,
    },
  ],
  links: { self: '' },
  meta: {
    totalPages: undefined,
    totalRecords: undefined,
  },
  success: false,
});

export const ERROR_REFRESH_TOKEN: HttpResponse<any> = {
  success: false,
  errors: [
    {
      status: 401,
      title: 'REFRESH TOKEN',
      detail: 'Can`t refresh token',
    },
  ],
  links: { self: '' },
  meta: {},
  data: null,
};

export const SUCCESS_REFRESH_TOKEN: HttpResponse<any> = {
  success: true,
  errors: [],
  links: { self: '' },
  meta: {},
  data: null,
};

export const patch405Error = (json: any): ServerError[] => {
  return [
    {
      title: 'METHOD NOT ALLOWED',
      detail: `Error: 405. ${json?.message}`,
      status: 405,
    },
  ];
};

export const isHiddenParams = (key: LoadingKey) => {
  const keys: LoadingKey[] = ['LOGIN', 'CHANGE_PASSWORD', 'REFRESH_TOKEN'];
  return keys.includes(key);
};

export const isHiddenErrors = (key: LoadingKey, _code: number) => {
  const keys: LoadingKey[] = ['REFRESH_TOKEN'];
  return keys.includes(key);
};
