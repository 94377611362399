import { action, makeObservable, observable } from 'mobx';

export class MedCardStore {
  @observable public medCards: MedCard[] = [];
  @observable public medCardsMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };

  @observable public medCardTypes: MedCardType[] = [];
  @observable public medEpisode: MedEpisode | null = null;
  @observable public medEpisodes: MedEpisode[] = [];
  @observable public medEpisodesMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };
  @observable public importMedCards: ImportMedCard[] = [];

  constructor() {
    makeObservable(this);
  }

  /*
  |----------------------------------------------------------------------------
  | MED CARDS
  |----------------------------------------------------------------------------
  */
  @action
  public setMedCards = (data: MedCard[], meta: ResponseMeta) => {
    this.medCards = data;
    this.medCardsMeta = meta;
  };

  @action
  public addMedCard = (data: MedCard) => {
    this.medCards = [data, ...this.medCards];
  };

  @action
  public updateMedCard = (data: MedCard) => {
    const medCard = this.medCards.find((e) => e.id === data.id);
    if (medCard) {
      Object.assign(medCard, data);
    }
  };

  @action
  public deleteMedCard = (id: number) => {
    this.medCards = this.medCards.filter((el) => el.id !== id);
  };

  @action
  public clearMedCards = () => {
    this.medCards = [];
    this.medCardsMeta = { totalPages: 0, totalRecords: 0 };
  };

  /*
  |----------------------------------------------------------------------------
  | TYPES
  |----------------------------------------------------------------------------
  */
  @action
  public setMedCardTypes = (data: MedCardType[]) => {
    this.medCardTypes = data;
  };

  @action
  public addMedCardType = (data: MedCardType) => {
    this.medCardTypes = [data, ...this.medCardTypes];
  };

  @action
  public updateMedCardType = (data: MedCardType) => {
    const medCardType = this.medCardTypes.find((e) => e.id === data.id);
    if (medCardType) {
      Object.assign(medCardType, data);
    }
  };

  @action
  public deleteMedCardTypes = (id: number) => {
    this.medCardTypes = this.medCardTypes.filter((e) => e.id !== id);
  };

  /*
  |----------------------------------------------------------------------------
  | EPISODES
  |----------------------------------------------------------------------------
  */
  @action
  public setEpisodes = (medEpisodes: MedEpisode[], meta: ResponseMeta) => {
    this.medEpisodes = medEpisodes;
    this.medEpisodesMeta = meta;
  };

  @action
  public setEpisode = (episode: MedEpisode | null) => {
    this.medEpisode = episode;
  };

  @action
  public addEpisode = (data: MedEpisode) => {
    this.medEpisodes = [data, ...this.medEpisodes];
  };

  @action
  public updateEpisode = (data: MedEpisode) => {
    const medEpisode = this.medEpisodes.find((e) => e.id === data.id);
    if (medEpisode) {
      Object.assign(medEpisode, data);
    }
    if (this.medEpisode?.id === data.id) {
      Object.assign(this.medEpisode, data);
    }
  };

  @action
  public clearEpisodes = () => {
    this.medEpisodes = [];
    this.medEpisodesMeta = { totalRecords: 0, totalPages: 0 };
  };

  @action public setImportMedCards = (cards: ImportMedCard[]) => {
    this.importMedCards = cards.map((e, i) => {
      return this._adaptMedCard(e, i + 1);
    });
  };

  @action public updateImportField = (
    id: number,
    field: keyof ImportMedCard,
    value: any,
  ) => {
    const card = this.importMedCards?.find((e) => e.id === id);
    if (card) {
      card[field] = value as never;
      card.isValid = this._adaptMedCard(card).isValid;
    }
  };

  @action
  public deleteImportMedCard = (id: number) => {
    this.importMedCards = this.importMedCards.filter((e) => e.id !== id);
  };

  private _adaptMedCard = (card: ImportMedCard, index?: number) => {
    if (index) card.id = index;
    const keys = Object.keys(card).filter(
      (e) => e !== '_patient' && e !== 'isValid' && e !== '_type',
    );
    card.isValid = keys.every((e) => {
      return !!card[e as keyof ImportMedCard];
    });
    return card;
  };
}
