import { action, makeObservable, observable } from 'mobx';

export class Cabinet {
  public readonly id: number;
  public expander = 0; // table column helper
  @observable public active = false;
  // for add User to Service Helper
  @observable public added = false;
  @observable public title = '';
  @observable public tel = '';
  @observable public location = '';
  public description = '';
  @observable public cabinet_number = '';
  @observable public number_simultaneous_receptions = 0;
  @observable public number_workplaces = 0;
  @observable public manager: UserResponse | null = null;
  @observable public cabinet_type;
  @observable public health_facility;

  constructor(cabinet: CabinetResponse) {
    makeObservable(this);
    this.id = cabinet.id;
    this.expander = cabinet.id;
    this.active = cabinet.active;
    this.cabinet_number = cabinet.cabinet_number;
    this.number_simultaneous_receptions =
      cabinet.number_simultaneous_receptions;
    this.number_workplaces = cabinet.number_workplaces;
    this.title = cabinet.title;
    this.tel = cabinet.tel;
    this.location = cabinet.location;
    this.manager = cabinet.manager;
    this.description = cabinet.description;
    this.cabinet_type = cabinet.cabinet_type;
    this.health_facility = cabinet.health_facility;
  }

  @action
  public setValue(key: keyof Cabinet, value: unknown): void {
    if (key !== 'id') this[key] = value as never;
  }
}
