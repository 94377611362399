import { PreloadLazy } from './utils';
import {
  SETTINGS_ROUTES,
  WORKPLACE_ROUTES,
  CASHPLACE_ROUTES,
  CALL_CENTER_ROUTES,
  ADMINISTRATOR_ROUTES,
  PATIENTS_ROUTES,
  CALENDAR_ROUTES,
  DESKTOP_ROUTES,
  REPORTS_ROUTES,
  LABORATORIES_ROUTES,
  BIOMATERIALS_ROUTES,
  LABORATORY_ROUTES,
  PROFILE_ROUTES,
} from '@router/routes';

/**
 * PRIVATE
 */
const PRIVATE: PrivateRoute[] = [
  {
    group: 'ROOT',
    name: 'desktop',
    path: '/',
    exact: true,
    preload: true,
    component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
    routes: [
      DESKTOP_ROUTES,
      CALL_CENTER_ROUTES,
      CALENDAR_ROUTES,
      ADMINISTRATOR_ROUTES,
      PATIENTS_ROUTES,
      SETTINGS_ROUTES,
      WORKPLACE_ROUTES,
      CASHPLACE_ROUTES,
      REPORTS_ROUTES,
      LABORATORIES_ROUTES,
      BIOMATERIALS_ROUTES,
      LABORATORY_ROUTES,
      PROFILE_ROUTES,
    ],
  },
];

/**
 * INIT
 */
const INIT: PublicRoute[] = [
  {
    name: 'Init system',
    path: '/init',
    exact: true,
    preload: true,
    component: PreloadLazy(() => import('../app/Pages/Init')),
  },
];

/**
 * PUBLIC
 */
const PUBLIC: PublicRoute[] = [
  {
    name: 'Логин',
    path: '/login',
    exact: true,
    preload: true,
    component: PreloadLazy(() => import('../app/Pages/Login')),
  },
];

/**
 * CHANGE_PASSWORD
 */
const CHANGE_PASSWORD: StaticRoute[] = [
  {
    name: 'Change Password',
    path: '/change-password',
    exact: true,
    preload: true,
    component: PreloadLazy(() => import('../app/Pages/ChangePassword')),
  },
];

/**
 * STATIC
 */
const STATIC: StaticRoute[] = [];

export const CONFIG = {
  INIT,
  CHANGE_PASSWORD,
  PRIVATE,
  PUBLIC,
  STATIC,
};
