import { PreloadLazy } from '@router/utils';

export const BIOMATERIALS_ROUTES: PrivateRoute = {
  group: 'BIOMATERIALS',
  name: 'biomaterials',
  path: '/biomaterials',
  exact: false,
  sidebar: true,
  icon: 'BloodtypeOutlined',
  activeIcon: 'BloodtypeBlackFilled',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'biomaterials',
      path: '/biomaterials',
      exact: true,
      component: PreloadLazy(() => import('@views/Biomaterials')),
    },
    {
      name: 'biomaterials_planning',
      path: '/biomaterials/planing',
      exact: true,
      ws: true,
      component: PreloadLazy(() => import('@views/Biomaterials/Planning')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      group: 'BIOMATERIALS_PLANNED',
      name: 'biomaterials_planned',
      path: '/biomaterials/planned',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/schedule.svg'),
      routes: [
        {
          name: 'biomaterials_planned',
          path: '/biomaterials/planned',
          exact: true,
          component: PreloadLazy(() => import('@views/Biomaterials/Planned')),
        },
        {
          name: 'biomaterials_planned_visits',
          path: '/biomaterials/planned/visits',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Planned/Visits'),
          ),
          ws: true,
          imagePath: () => import('@assets/img/preview/schedule.svg'),
        },
      ],
    },
    {
      name: 'biomaterials_taking',
      path: '/biomaterials/taking',
      ws: true,
      exact: true,
      component: PreloadLazy(() => import('@views/Biomaterials/Taking')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      group: 'BIOMATERIALS_TAKEN',
      name: 'biomaterials_taken',
      path: '/biomaterials/taken',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/biomaterial_types.svg'),
      routes: [
        {
          name: 'biomaterials_taken',
          path: '/biomaterials/taken',
          exact: true,
          component: PreloadLazy(() => import('@views/Biomaterials/Taken')),
        },
        {
          name: 'biomaterials_taken_successful',
          path: '/biomaterials/taken/successful',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Taken/TakenSuccessful'),
          ),
          imagePath: () => import('@assets/img/preview/biomaterial_types.svg'),
        },
        {
          name: 'biomaterials_taken_unsuccessful',
          path: '/biomaterials/taken/unsuccessful',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Taken/TakenUnsuccessful'),
          ),
          imagePath: () => import('@assets/img/preview/no-data.png'),
        },
      ],
    },
    {
      group: 'BIOMATERIALS_SENT',
      name: 'biomaterials_sent',
      path: '/biomaterials/sent',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/mail_sent.svg'),
      routes: [
        {
          name: 'biomaterials_sent',
          path: '/biomaterials/sent',
          exact: true,
          component: PreloadLazy(() => import('@views/Biomaterials/Sent')),
        },
        {
          name: 'biomaterials_sent_samples',
          path: '/biomaterials/sent/samples',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Sent/Samples'),
          ),
          imagePath: () => import('@assets/img/preview/mail_sent.svg'),
        },
      ],
    },
  ],
};
