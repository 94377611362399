import React from 'react';
import './header.scss';

type Props = {
  children: React.JSX.Element;
};

export default function Header({ children }: Props) {
  return <header className="app-header print:hidden">{children}</header>;
}
