import { FetchService } from '@modules/Api';

export class UiModulesApi extends FetchService {
  public getUiModules = () => {
    return this.request<'IU_MODULE', UiModule[]>(
      '/ui_module',
      'GET_IU_MODULES',
      {
        permit: 'ui_modules',
      },
    );
  };

  public getUiModulesTree = () => {
    return this.request<'IU_MODULE', UiModule[]>(
      '/ui_module/tree',
      'GET_IU_MODULES_TREE',
      {
        permit: 'ui_modules',
      },
    );
  };

  public removeUiModule = (uiModuleId: number) => {
    return this.request<'IU_MODULE'>('/ui_module', 'REMOVE_IU_MODULE', {
      method: 'DELETE',
      pathParam: uiModuleId,
      permit: 'ui_modules',
    });
  };
}
