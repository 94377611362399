import { action, observable, makeObservable } from 'mobx';

export default class PhoneCodesStore {
  @observable public codes: PhoneCode[] = [];
  @observable public codesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setCodes = (data: PhoneCode[], meta: ResponseMeta): void => {
    this.codes = data;
    this.codesMeta = meta;
  };
}
