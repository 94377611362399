import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCashIn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={14}
      fill="none"
      viewBox="0 0 20 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.833 8.667V2c0-.917-.75-1.667-1.666-1.667H2.5C1.583.333.833 1.083.833 2v6.667c0 .916.75 1.666 1.667 1.666h11.667c.916 0 1.666-.75 1.666-1.666m-7.5-.834a2.497 2.497 0 0 1-2.5-2.5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5-1.116 2.5-2.5 2.5m10.834-5V12c0 .917-.75 1.667-1.667 1.667H3.333V12H17.5V2.833z"
      />
    </svg>
  );
};
export default SvgCashIn;
