import { PreloadLazy } from '@router/utils';

export const CALENDAR_ROUTES: PrivateRoute = {
  group: 'CALENDAR',
  name: 'calendar',
  path: '/calendar',
  exact: true,
  sidebar: true,
  ws: true,
  icon: 'OutlineDateRange',
  activeIcon: 'DateRangeIcon',
  component: PreloadLazy(() => import('@views/Calendar')),
};
