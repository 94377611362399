import PositionStore from '@modules/Position/position.store';

export class PositionService extends PositionStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPositionsRequest = async (query?: GetPositionsQuery) => {
    const res = await this._store.PositionsApi.getPositions(query);
    if (res.success && res.data) {
      this.setPositions(res.data, res.meta);
    }
    return res;
  };

  public createPositionRequest = async (params: CreatePosition) => {
    const res = await this._store.PositionsApi.createPosition(params);
    if (res.success && res.data) {
      this.addPosition(res.data);
    }
    return res;
  };

  public deletePositionRequest = async (positionId: number) => {
    const res = await this._store.PositionsApi.deletePosition(positionId);
    if (res.success) {
      this.deletePosition(positionId);
    }
    return res;
  };

  public updatePositionRequest = async (
    positionId: number,
    params: UpdatePosition,
  ) => {
    const res = await this._store.PositionsApi.updatePosition(
      positionId,
      params,
    );
    if (res.success && res.data) {
      this.updatePosition(res.data);
    }
    return res;
  };
}
