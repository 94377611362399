import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPhone = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <path
        fill="#FECACA"
        d="M0 4a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
      />
      <path
        fill="#DC2626"
        d="M14 11.5c-1.333 0-2.625.209-3.833.6v2.584a.84.84 0 0 1-.467.75 9.6 9.6 0 0 0-2.217 1.541.82.82 0 0 1-.583.233.83.83 0 0 1-.592-.241L4.242 14.9A.8.8 0 0 1 4 14.317a.83.83 0 0 1 .242-.592A14.14 14.14 0 0 1 14 9.834c3.783 0 7.217 1.483 9.758 3.891a.83.83 0 0 1 .242.592.83.83 0 0 1-.242.591l-2.066 2.067a.83.83 0 0 1-.592.242.86.86 0 0 1-.583-.233 9.4 9.4 0 0 0-2.225-1.542.83.83 0 0 1-.467-.75v-2.584c-1.2-.4-2.492-.608-3.825-.608"
      />
    </svg>
  );
};
export default SvgPhone;
