import { makeAutoObservable } from 'mobx';

type ServiceNodeProps = {
  id: number;
  title: string;
  position?: number;
  default_commission?: number;
  parent: number | null;
  subRows: number[];
};

export class ServiceNode {
  public id: number;
  public title: string;
  public position?: number;
  public default_commission?: number;
  public parent: number | null;
  public subRows: number[];

  constructor(props: ServiceNodeProps) {
    this.id = props.id;
    this.title = props.title;
    this.position = props.position;
    this.default_commission = props.default_commission;
    this.parent = props.parent;
    this.subRows = props.subRows;

    makeAutoObservable(this);
  }

  public changeTitle = (value: string) => {
    this.title = value;
  };

  public changeCommission = (value: number) => {
    this.default_commission = value;
  };
}
