import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={100}
      height={100}
      fill="none"
      fillRule="evenodd"
      aria-label="Provide a description that will be used as the title"
      style={{
        marginBottom: '3rem',
      }}
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <title>{'Provide a description that will be used as the title'}</title>
      <path
        d="M.75 16a.75.75 0 0 1-.67-1.085L7.33.415a.75.75 0 0 1 1.34 0l7.25 14.5A.75.75 0 0 1 15.25 16zm6.5-10v5h1.5V6zM8 13.5A.75.75 0 1 0 8 12a.75.75 0 0 0 0 1.5"
        fill="currentColor"
      />
    </svg>
  );
};
export default SvgWarning;
