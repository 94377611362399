import PermissionsStore from '@modules/Permissions/permissions.store';

export class PermissionsService extends PermissionsStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public userCan = (entity: PermissionEntity): PermissionCrud => {
    if (this.isAdmin) {
      return {
        canCreate: true,
        canRead: true,
        canUpdate: true,
        canDelete: true,
      };
    }

    const _entity = this.crudPermissions?.find((e) => {
      return e.entity.sys_name === entity;
    });

    if (!_entity) {
      // throw new Error('[USER_CAN]: `' + entity + '` not found in permissions');
      console.error('[USER_CAN]: `' + entity + '` not found in permissions');
      this._store.LoggerService.send.permissionCrumb(
        '❌ [USER_CAN]: `' + entity + '` not found in permissions',
      );
      return {
        canCreate: false,
        canRead: false,
        canUpdate: false,
        canDelete: false,
      };
    }

    return {
      canCreate: _entity?.c ?? false,
      canRead: _entity?.r ?? false,
      canUpdate: _entity?.u ?? false,
      canDelete: _entity?.d ?? false,
    };
  };

  public userCanRoute = (route: BaseRoute) => {
    if (this.isAdmin) return true;
    if (!route) return false;
    if (!this.allowedRoutes?.length) return false;
    return this.allowedRoutes.includes(route?.path as RoutePath);
  };
}
