import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddBox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      fill="none"
      viewBox="0 0 13 12"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.875.375h-8.75a1.25 1.25 0 0 0-1.25 1.25v8.75c0 .688.556 1.25 1.25 1.25h8.75c.688 0 1.25-.562 1.25-1.25v-8.75c0-.687-.562-1.25-1.25-1.25m-1.25 6.25h-2.5v2.5h-1.25v-2.5h-2.5v-1.25h2.5v-2.5h1.25v2.5h2.5z"
      />
    </svg>
  );
};
export default SvgAddBox;
