class StorageService {
  public storage: Storage;

  constructor(type: StorageType) {
    this.storage = type === 'session' ? sessionStorage : localStorage;
  }

  public getItem = <T = string>(
    key: StorageKey,
    shouldParse = false,
  ): T | null => {
    const data = this.storage.getItem(key);

    return shouldParse && data ? JSON.parse(data) : data;
  };

  public setItem = (key: StorageKey, data: unknown) => {
    const newData = data;
    const strData =
      typeof newData !== 'string' ? JSON.stringify(newData) : newData;
    this.storage.setItem(key, strData);
  };

  public removeItem = (key: StorageKey) => {
    this.storage.removeItem(key);
  };

  public sync = (key: StorageKey, newValue: unknown) => {
    // accepts only null as value to remove data from storage
    // undefined means property isn't initialized
    if (newValue === undefined) return;
    // null means remove items
    if (newValue === null) {
      // eslint-disable-next-line consistent-return
      return this.removeItem(key);
    }
    this.setItem(key, newValue);
  };
}

export const SStorage = new StorageService('session');
export const LStorage = new StorageService('local');
