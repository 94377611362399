import { action, makeObservable, observable } from 'mobx';

export class DocElementStore {
  @observable public docElement?: DocElement;
  @observable public docElements: DocElement[] = [];
  @observable public docElementsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public docElementTree?: DocElementTree;
  @observable public docElementTypes: DocElementType[] = [];
  @observable public docElementGroups: DocElementGroup[] = [];
  @observable public docElementGroupsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setElements = (elements: DocElement[], meta: ResponseMeta) => {
    this.docElements = elements;
    this.docElementsMeta = meta;
  };

  @action
  public addElement = (element: DocElement) => {
    this.docElements = [element, ...this.docElements];
  };

  @action
  public setElement = (element?: DocElement) => {
    this.docElement = element;
  };

  @action
  public setElementTree = (element?: DocElementTree) => {
    this.docElementTree = element;
  };

  @action
  public updateElement = (element: DocElement) => {
    const item = this.docElements.find((e) => e.id === element.id);
    if (item) {
      Object.assign(item, element);
    }
  };

  @action
  public deleteElement = (id: number) => {
    this.docElements = this.docElements.filter((e) => e.id !== id);
  };

  /*
   |----------------------------------------------------------------------------
   | TYPES
   |----------------------------------------------------------------------------
   */
  @action
  public setElementTypes = (types: DocElementType[]) => {
    this.docElementTypes = types;
  };

  /*
   |----------------------------------------------------------------------------
   | GROUPS
   |----------------------------------------------------------------------------
   */
  @action
  public setElementGroups = (
    elements: DocElementGroup[],
    meta: ResponseMeta,
  ) => {
    this.docElementGroups = elements;
    this.docElementGroupsMeta = meta;
  };

  @action
  public setElementGroup = (el: DocElementGroup) => {
    this.docElementGroups = [el, ...this.docElementGroups];
  };

  @action
  public updateElementGroup = (el: DocElementGroup) => {
    const group = this.docElementGroups.find((e) => e.id === el.id);
    if (group) {
      Object.assign(group, el);
    }
  };

  @action
  public deleteElementGroup = (id: number) => {
    this.docElementGroups = this.docElementGroups.filter((e) => e.id !== id);
  };
}
