import React, { CSSProperties, useMemo } from 'react';
import Text from '@components/UI/Text/Text';
import * as style from './NoResults.module.scss';
import { LazyImage } from '../LazyImage';

export type NoResultType = {
  title: string;
  size?: 'mini' | 'small' | 'medium' | 'large';
  description?: string;
  style?: CSSProperties;
  className?: string;
  image?: () => Promise<{ default: string }>;
};

export default function NoResults({
  title,
  description,
  image,
  size = 'medium',
  ...rest
}: NoResultType) {
  const preload = useMemo(() => {
    if (image) return image;

    return () =>
      import('@assets/img/preview/no-data.png') as Promise<{ default: string }>;
  }, [image]);

  return (
    <div {...rest}>
      <div className={style.content}>
        <LazyImage preload={preload} size={size} />
        <Text className="text-coolGray-900 mb-3" tag="h3">
          {title}
        </Text>
        {description && (
          <Text body="body-1" className="text-center px-10" tag="h4">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
}
