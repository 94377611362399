import { FetchService } from '@modules/Api';

export class CashPlaceApi extends FetchService {
  public getCashPlaces = (query?: GetCashPlaceQuery) => {
    return this.request<'CASH_PLACE', CashPlace[], null, GetCashPlaceQuery>(
      '/cash_places',
      'GET_CASH_PLACES',
      { query },
    );
  };

  public getCashPlace = (id: number) => {
    return this.request<'CASH_PLACE', CashPlace>(
      '/cash_places',
      'GET_CASH_PLACE',
      { pathParam: `${id}` },
    );
  };

  public createCashPlace = (params: CreateCashPlaceParams) => {
    return this.request<'CASH_PLACE', CashPlace>(
      '/cash_places',
      'CREATE_CASH_PLACE',
      { method: 'POST', params },
    );
  };

  public deleteCashPlace = (id: number) => {
    return this.request<'CASH_PLACE'>('/cash_places', 'DELETE_CASH_PLACE', {
      method: 'DELETE',
      pathParam: `${id}`,
    });
  };

  public updateCashPlace = (id: number, params: UpdateCashPlaceParams) => {
    return this.request<'CASH_PLACE', CashPlace>(
      '/cash_places',
      'UPDATE_CASH_PLACE',
      { method: 'PUT', pathParam: `${id}`, params },
    );
  };

  public activateCashPlace = (id: number) => {
    return this.request<'CASH_PLACE', CashPlace>(
      '/cash_places',
      'ACTIVATE_CASH_PLACE',
      { method: 'PUT', pathParam: `${id}/activate` },
    );
  };

  public deactivateCashPlace = (id: number) => {
    return this.request<'CASH_PLACE', CashPlace>(
      '/cash_places',
      'DEACTIVATE_CASH_PLACE',
      { method: 'PUT', pathParam: `${id}/deactivate` },
    );
  };

  public createInout = (id: number, params: CreateCashPlaceInout) => {
    return this.request<'CASH_PLACE', string>(
      '/cash_place_operations',
      'CREATE_INOUT',
      {
        method: 'POST',
        params,
        pathParam: `${id}/inout`,
      },
    );
  };

  public createServicesReport = (id: number) => {
    return this.request<'CASH_PLACE', BlobResponse>(
      '/cash_place_operations',
      'CREATE_SERVICES_REPORT',
      {
        method: 'POST',
        pathParam: `${id}/services_report`,
      },
    );
  };

  public createXReport = (id: number) => {
    return this.request<'CASH_PLACE', string>(
      '/cash_place_operations',
      'CREATE_X_REPORT',
      {
        method: 'POST',
        pathParam: `${id}/x_report`,
      },
    );
  };

  public createZReport = (id: number) => {
    return this.request<'CASH_PLACE', string>(
      '/cash_place_operations',
      'CREATE_Z_REPORT',
      {
        method: 'POST',
        pathParam: `${id}/z_report`,
      },
    );
  };

  public createZeroCheck = (id: number) => {
    return this.request<'CASH_PLACE', BlobResponse>(
      '/cash_place_operations',
      'CREATE_ZERO',
      {
        method: 'POST',
        pathParam: `${id}/zero`,
      },
    );
  };

  public getXReport = (id: number, query?: GetXReportQueries) => {
    return this.request<'CASH_PLACE', XReport>(
      '/cash_place_operations',
      'GET_X_REPORT',
      {
        pathParam: `${id}/inside_x_report`,
        query,
      },
    );
  };

  public printNonFiscalCheck = (id: number) => {
    return this.request<'CASH_PLACE', null, number>(
      '/cash_place_operations',
      'PRINT_NON_FISCAL_CHECK',
      {
        method: 'POST',
        pathParam: `${id}/test`,
      },
    );
  };

  public getOperationReport = (query?: CashPlaceOperation.GetReports) => {
    return this.request<
      'CASH_PLACE',
      CashPlaceOperation.Report[],
      CashPlaceOperation.GetReports
    >(
      '/cash_place_operations/get_operation_report',
      'GET_CASH_PLACE_OPERATIONS_REPORT',
      { query, permit: 'cash_place_operations' },
    );
  };

  public downloadCashPlaceReport = (query?: CashPlaceOperation.GetReports) => {
    return this.request<
      'CASH_PLACE',
      BlobResponse,
      CashPlaceOperation.GetReports
    >(
      '/cash_place_operations/download_report',
      'GET_CASH_PLACE_DOWNLOAD_REPORT',
      { query, blob: true },
    );
  };

  public getCashOrders = (query?: GetCashOperationQuery) => {
    return this.request<
      'CASH_PLACE',
      CashOperationOrder[],
      GetCashOperationQuery
    >('/cash_orders', 'GET_CASH_OPERATION_ORDERS', { query });
  };

  public downloadCashOperationOrder = (id: number) => {
    return this.request<'CASH_PLACE', BlobResponse>(
      '/cash_orders',
      'DOWNLOAD_CASH_OPERATION_ORDER',
      {
        pathParam: `${id}/download`,
        blob: true,
      },
    );
  };
}
