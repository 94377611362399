import { action, makeObservable, observable } from 'mobx';
import { sortBy } from 'lodash';

export default class InsurancePolicyStore {
  @observable public policies: InsurancePolicy[] = [];
  @observable public policy: InsurancePolicy | null = null;
  @observable public policiesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setPolicies(policies: InsurancePolicy[], meta: ResponseMeta): void {
    this.policies = sortBy(policies, ['created']);
    this.policiesMeta = meta;
  }

  @action
  public setPolicy(policy: InsurancePolicy): void {
    this.policy = policy;
  }

  @action
  public addPolicy(policy: InsurancePolicy): void {
    this.policies = [policy, ...this.policies];
  }

  @action
  public updatePolicy(policy: InsurancePolicy): void {
    this.policies.forEach((p) => {
      if (p.id === policy.id) {
        Object.assign(p, policy);
      }
    });
  }

  @action
  public activatePolicy(policy: InsurancePolicy): void {
    this.updatePolicy(policy);
  }

  @action
  public deactivatePolicy(policy: InsurancePolicy): void {
    this.updatePolicy(policy);
  }
}
