import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSell = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.842 9.508 10.483 2.15a1.67 1.67 0 0 0-1.175-.483H3.333c-.917 0-1.666.75-1.666 1.666v5.975c0 .442.174.867.491 1.175l7.359 7.359c.65.65 1.708.65 2.358 0l5.975-5.975c.65-.65.65-1.7-.009-2.359M5.416 6.667c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .691-.559 1.25-1.25 1.25"
      />
    </svg>
  );
};
export default SvgSell;
