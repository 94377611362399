import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPregnant = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M7.5 3.333a1.66 1.66 0 0 1 1.666-1.666 1.66 1.66 0 0 1 1.667 1.666A1.66 1.66 0 0 1 9.166 5 1.66 1.66 0 0 1 7.5 3.333m5.833 7.5a2.74 2.74 0 0 0-1.667-2.5c0-1.383-1.116-2.5-2.5-2.5a2.497 2.497 0 0 0-2.5 2.5v5.834h1.667v4.166h2.5v-4.166h2.5z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPregnant;
