import { FetchService } from '@modules/Api';

export class ScheduledCallApi extends FetchService {
  public getScheduledCalls = (query?: GetScheduledCallsQuery) => {
    return this.request<
      'SCHEDULED_CALLS',
      ScheduledCall[],
      null,
      GetScheduledCallsQuery
    >('/scheduled_calls', 'GET_SCHEDULED_CALLS', {
      query,
      permit: 'scheduled_calls',
    });
  };

  public createScheduledCall = (params: CreateScheduledCall) => {
    return this.request<'SCHEDULED_CALLS', ScheduledCall, CreateScheduledCall>(
      '/scheduled_calls',
      'CREATE_SCHEDULED_CALL',
      {
        method: 'POST',
        params,
        permit: 'scheduled_calls',
      },
    );
  };

  public updateScheduledCall = (id: number, params: UpdateScheduledCall) => {
    return this.request<'SCHEDULED_CALLS', ScheduledCall, UpdateScheduledCall>(
      '/scheduled_calls',
      'UPDATE_SCHEDULED_CALL',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'scheduled_calls',
      },
    );
  };

  public deleteScheduledCall = (id: number) => {
    return this.request<'SCHEDULED_CALLS'>(
      '/scheduled_calls',
      'DELETE_SCHEDULED_CALL',
      {
        method: 'DELETE',
        pathParam: id,
      },
    );
  };

  public markDoneScheduledCalls = (id: number) => {
    return this.request<'SCHEDULED_CALLS', ScheduledCall>(
      '/scheduled_calls',
      'MARK_DONE_SCHEDULED_CALL',
      {
        method: 'PUT',
        pathParam: `${id}/done`,
        permit: 'scheduled_calls',
      },
    );
  };

  public createScheduledCallComment = (
    id: number,
    params: CreateScheduledCallComment,
  ) => {
    return this.request<'SCHEDULED_CALLS', ScheduledCallMessage>(
      '/scheduled_calls',
      'CREATE_SCHEDULED_CALL_COMMENT',
      {
        method: 'POST',
        pathParam: `${id}/comments`,
        params,
        permit: 'scheduled_calls',
      },
    );
  };

  public getScheduledCallsV2 = (query?: GetScheduledCallsQuery) => {
    return this.request<
      'SCHEDULED_CALLS',
      ScheduledCall[],
      null,
      GetScheduledCallsQuery
    >('/scheduled_calls', 'GET_SCHEDULED_CALLS', {
      query,
      permit: 'scheduled_calls',
      version: 2,
    });
  };
}
