import { action, makeObservable, observable } from 'mobx';

export class ServicePropertiesStore {
  @observable public property: ServiceProperty | null = null;
  @observable public properties: ServiceProperty[] = [];
  @observable public propertiesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setProperties = (data: ServiceProperty[], meta: ResponseMeta) => {
    this.properties = data;
    this.propertiesMeta = meta;
  };

  @action
  public setProperty = (data: ServiceProperty | null) => {
    this.property = data;
  };

  @action
  public createProperty = (data: ServiceProperty) => {
    this.properties = this.properties.concat(data);
  };

  @action
  public updateProperty = (data: ServiceProperty) => {
    if (this.property && this.property.id === data.id) {
      this.setProperty(data);
    }

    this.properties = this.properties.map((property) => {
      return property.id === data.id ? data : property;
    });
  };

  @action
  public deleteProperty = (id: number) => {
    if (this.property && this.property.id === id) {
      this.setProperty(null);
    }

    this.properties = this.properties.filter((property) => property.id !== id);
  };
}
