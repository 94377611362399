import React, { memo } from 'react';
import { Header } from '@components/Layout/InitLayout/Header';
import './InitLayout.scss';

type Props = {
  children: React.ReactNode;
};

export const InitLayoutParts = {
  Header: ({ children }: Props) => (
    <div className="init-layout__header">
      <div className="init-layout__container">
        <Header />
        {children}
      </div>
    </div>
  ),
  Content: ({ children }: Props) => (
    <div className="init-layout__content">{children}</div>
  ),
  Footer: ({ children }: Props) => (
    <div className="init-layout__footer">{children}</div>
  ),
};

const InitLayout: React.FC<Props> = ({ children }) => {
  return <div className="init-layout">{children}</div>;
};

export default memo(InitLayout);
