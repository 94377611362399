import { DateService } from '@modules/Format';
import { StringService } from '@modules/String';
import { action, makeObservable, observable } from 'mobx';

export class Appointment {
  public readonly id: number;
  @observable public status!: AppointmentStatus;
  @observable public urgent = false;
  @observable public med_card = false;
  @observable public confirmed = false;
  @observable public patient = '';
  @observable public patientPhone = '';
  @observable public doctor = '';
  @observable public cabinet = '';
  @observable public created: { date: string; time: string } = {
    date: '',
    time: '',
  };
  @observable public creator = '';
  @observable public reception_time: { date: string; time: string } = {
    date: '',
    time: '',
  };
  @observable public services: AppointmentService[] = [];
  @observable public health_facility!: HealthFacility;
  @observable public base!: AppointmentType;

  constructor(appointment: AppointmentType) {
    makeObservable(this);
    this.id = appointment.id;
    this.setData(appointment);
  }

  @action
  public setData(appointment: AppointmentType) {
    this.status = appointment.status;
    this.urgent = appointment.urgent;
    this.services = appointment.services;
    this.confirmed = appointment.confirmed;
    this.base = appointment;
    this._setPatient(appointment);
    this._setPatientPhone(appointment);
    this._setDoctor(appointment);
    this._setCabinet(appointment);
    this._seHealthFacility(appointment);
    this._setReceptionTime(appointment);
    this._setMedCard(appointment);
    this._setCreator(appointment);
    this._setCreatedTime(appointment);
  }

  @action
  private _setPatient(e: AppointmentType) {
    if (e.patient) {
      this.patient = `${e.patient.last_name} ${e.patient.first_name} ${e.patient.patronymic}`;
    }
  }

  @action
  private _setDoctor(e: AppointmentType) {
    this.doctor = `${e.user.last_name} ${e.user.first_name[0]}. ${e.user.patronymic[0]}.`;
  }

  @action
  private _setCabinet(e: AppointmentType) {
    if (e.cabinet) {
      this.cabinet = `${e.cabinet.cabinet_number} - ${e.cabinet.title}`;
    }
  }

  private _seHealthFacility(e: AppointmentType) {
    if (e.cabinet) {
      this.health_facility = e.cabinet.health_facility;
    }
  }

  @action
  private _setReceptionTime(e: AppointmentType) {
    this.reception_time = {
      date: DateService.formatDate(e.date, 'DD.MM.YYYY'),
      time: `${e.start_time_str} - ${e.end_time_str}`,
    };
  }

  @action
  private _setCreator(e: AppointmentType) {
    this.creator = `${e.creator.last_name} ${e.creator.first_name[0]}. ${e.creator.patronymic[0]}.`;
  }

  @action
  private _setCreatedTime(e: AppointmentType) {
    this.created = {
      date: DateService.formatDate(e.created, 'DD.MM.YYYY'),
      time: DateService.formatDate(e.created, 'HH_mm'),
    };
  }

  @action
  private _setMedCard(e: AppointmentType) {
    if (e.patient?.medical_cards?.length) {
      this.med_card = e.patient.medical_cards.some((m) => m.isOpen);
      return;
    }

    this.med_card = false;
  }

  @action
  private _setPatientPhone(e: AppointmentType) {
    if (!e.patient) return;
    if (!e.patient.phone_number) return;
    if (!e.patient.phone_code) return;

    const number = StringService.constructTel({
      code: e.patient.phone_code,
      phone: e.patient.phone_number,
    });

    this.patientPhone = number ?? '';
  }
}
