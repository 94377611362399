import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="none"
      viewBox="0 0 14 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 9.5V12H2V9.5H.333V12c0 .917.75 1.667 1.667 1.667h10c.917 0 1.667-.75 1.667-1.667V9.5zm-9.167-5 1.175 1.175 2.159-2.15v6.809h1.666V3.525l2.159 2.15L11.167 4.5 7 .333z"
      />
    </svg>
  );
};
export default SvgDownload;
