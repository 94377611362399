import React, {
  createElement,
  CSSProperties,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  memo,
  ReactNode,
  SyntheticEvent,
} from 'react';
import classNames from 'classnames';
import './Button.scss';
import { Icon } from '@components/UI/Icon';

export type ButtonProps = {
  children?: ReactNode;
  size?: 'large' | 'medium' | 'small' | 'mini';
  type?:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'text'
    | 'loaded'
    | 'black'
    | 'special'
    | 'details';
  align?: 'left' | 'center' | 'right';
  target?: '_self' | '_blank';
  disabled?: boolean;
  icon?: IconType;
  endIcon?: IconType;
  loading?: boolean;
  full?: boolean;
  href?: string;
  className?: string;
  onClick?: (event: SyntheticEvent) => void | Promise<void>;
  submit?: boolean;
  reset?: boolean;
  id?: string;
  style?: CSSProperties;
  testID?: string;
  onFocus?: FocusEventHandler;
  tabIndex?: number;
  onKeyUp?: KeyboardEventHandler;
  enableSmall?: boolean;
};

const RenderTag = forwardRef(function RenderTag(
  { submit, reset, ...props }: ButtonProps,
  ref,
) {
  let type = 'button';
  if (submit) type = 'submit';
  if (reset) type = 'reset';
  return createElement(
    props.href ? 'a' : 'button',
    {
      ...{ href: props.href ? props.href : undefined },
      type,
      ...props,
      ref,
    },
    props.children,
  );
});

const Button = forwardRef(function Button(
  {
    children,
    onClick,
    className,
    disabled,
    loading,
    full,
    icon,
    endIcon,
    size = 'small',
    type = 'primary',
    target = '_self',
    align,
    id,
    style,
    enableSmall = false,
    testID,
    ...rest
  }: ButtonProps,
  ref,
) {
  const classes = classNames(['btn', className, `type-${type}`, size, align], {
    disabled: disabled,
    isLoading: loading,
    iconOnly: !children,
    full,
  });

  const renderIcon = (icon?: IconType, left?: boolean) => {
    if (loading) {
      icon = 'Spinner';
      left = false;
      disabled = true;
    }
    if (!icon) return null;
    const nonSmallSize = size === 'small' ? 'medium' : size;
    return (
      <span className={classNames(['icon', icon, !left ? 'mr-2' : 'ml-2'])}>
        <Icon size={enableSmall ? size : nonSmallSize} name={icon} />
      </span>
    );
  };

  return (
    <RenderTag
      ref={ref}
      onClick={onClick}
      className={classes}
      disabled={disabled || loading}
      id={id}
      style={style}
      target={target}
      data-testid={testID}
      {...rest}
    >
      <span className="btn-wrap">
        {renderIcon(icon)}
        <span>
          {children}
          {!children && !endIcon && !icon && <div>?</div>}
        </span>
        {!loading && renderIcon(endIcon, !!endIcon)}
      </span>
    </RenderTag>
  );
});

export default memo(Button);
