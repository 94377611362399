import { action } from 'mobx';
import GuarantorStore from './guarantor.store';

export default class GuarantorService extends GuarantorStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  @action
  public getGuarantorsRequest = async (params?: GetGuarantorParams) => {
    const response = await this._store.InsuranceApi.getGuarantors(params);

    if (response.success && response.data) {
      this.setGuarantors(response.data, response.meta);
    }

    return response;
  };

  @action
  public createGuarantorsRequest = async (params: CreateGuarantorParams) => {
    const response = await this._store.InsuranceApi.createGuarantor(params);

    if (response.success && response.data) {
      this.addGuarantor(response.data);
    }

    return response;
  };

  @action
  public updateGuarantorsRequest = async (
    id: number,
    params: UpdateGuarantorParams,
  ) => {
    const response = await this._store.InsuranceApi.updateGuarantor(id, params);

    if (response.success && response.data) {
      this.updateGuarantor(response.data);
    }

    return response;
  };

  @action
  public activateGuarantorsRequest = async (id: number) => {
    const response = await this._store.InsuranceApi.activateGuarantor(id);

    if (response.success && response.data) {
      this.updateGuarantor(response.data);
    }

    return response;
  };

  @action
  public deactivateGuarantorsRequest = async (id: number) => {
    const response = await this._store.InsuranceApi.deactivateGuarantor(id);

    if (response.success && response.data) {
      this.updateGuarantor(response.data);
    }

    return response;
  };
}
