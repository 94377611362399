import { ServicePropertiesStore } from './service-properties.store';

export class ServicePropertiesService extends ServicePropertiesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPropertiesReq = async (query?: GetServicePropertiesQuery) => {
    const res =
      await this._store.ServicePropertyApi.getServiceProperties(query);

    if (res.data && res.success) {
      this.setProperties(res.data, res.meta);
    }

    return res;
  };

  public getPropertyReq = async (id: number) => {
    const res = await this._store.ServicePropertyApi.getServiceProperty(id);

    if (res.data && res.success) {
      this.setProperty(res.data);
    }

    return res;
  };

  public createPropertyReq = async (params: CreateServiceProperty) => {
    const res =
      await this._store.ServicePropertyApi.createServiceProperty(params);

    if (res.data && res.success) {
      this.createProperty(res.data);
    }

    return res;
  };

  public updatePropertyReq = async (
    id: number,
    params: UpdateServiceProperty,
  ) => {
    const res = await this._store.ServicePropertyApi.updateServiceProperty(
      id,
      params,
    );

    if (res.data && res.success) {
      this.updateProperty(res.data);
    }

    return res;
  };

  public deletePropertyReq = async (id: number) => {
    const res = await this._store.ServicePropertyApi.deleteServiceProperty(id);

    if (res.data && res.success) {
      this.deleteProperty(id);
    }

    return res;
  };
}
