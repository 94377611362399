import { action, makeObservable, observable } from 'mobx';

export class DocDirectionsStore {
  @observable public docDirections: DocDirection[] = [];
  @observable public docDirection: DocDirection | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setDocDirections = (directions: DocDirection[]) => {
    this.docDirections = directions;
  };

  @action
  public setDocDirection = (direction: DocDirection | null) => {
    this.docDirection = direction;
  };

  @action
  public addDocDirection = (direction: DocDirection) => {
    this.docDirections = [direction, ...this.docDirections];
  };

  @action
  public updateDocDirection = (direction: DocDirection) => {
    const item = this.docDirections.find((e) => e.id === direction.id);
    if (item) {
      Object.assign(item, direction);
    }
  };

  @action
  public deleteDocDirection = (id: number) => {
    this.docDirections = this.docDirections.filter((e) => e.id !== id);
  };
}
