import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCachedCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#92400E"
          d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667m.05 14.166v-1.675H10a4.18 4.18 0 0 1-2.95-1.216A4.17 4.17 0 0 1 6.517 7.7l.916.917c-.591 1.108-.441 2.508.492 3.441.583.584 1.35.859 2.117.842v-1.783l2.358 2.358zm3.425-3.533-.917-.917c.592-1.108.442-2.508-.491-3.441A2.9 2.9 0 0 0 10 7.083h-.05v1.792l-2.358-2.35L9.95 4.167V5.85a4.1 4.1 0 0 1 3 1.208c1.417 1.417 1.592 3.625.525 5.242"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCachedCircle;
