import { DocChapterStore } from './DocChapter.store';
import { CElement } from '@modules/Documents/DocChapter/mapper/CElement';
import { observable, toJS } from 'mobx';
import { DOC_ELEMENT_TYPE } from '@modules/Documents/consts';
import isEmpty from 'lodash/isEmpty';

export class DocChapterService extends DocChapterStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  public getDocChaptersReq = async (query?: GetDocChaptersQuery) => {
    const res = await this._store.DocTemplateApi.getDocChapters(query);
    if (res.success && res.data) {
      this.setDocChapters(res.data, res.meta);
    }
    return res;
  };

  public getDocChapterReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocChapter(id);
    if (res.success && res.data) {
      this.setDocChapter(res.data);
    }
    return res;
  };

  public createDocChapterReq = async (params: CreateDocChapterReq) => {
    const res = await this._store.DocTemplateApi.createDocChapter(params);
    if (res.success && res.data) {
      this.setDocChapter(res.data, true);
    }
    return res;
  };

  public updateDocChapterReq = async (
    id: number,
    params: UpdateDocChapterReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateDocChapter(id, params);
    if (res.success && res.data) {
      this.updateDocChapter(res.data);
    }
    return res;
  };

  public deleteDocChapterReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocChapter(id);
    if (res.success) {
      this.deleteDocChapter(id);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | CHAPTER GROUPS
   |----------------------------------------------------------------------------
   */
  public getDocChapterGroupsReq = async (query?: GetDocChapterGroupsQuery) => {
    const res = await this._store.DocTemplateApi.getDocChapterGroups(query);
    if (res.success && res.data) {
      this.setDocChapterGroups(res.data, res.meta);
    }
    return res;
  };

  public getDocChapterGroupReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.getDocChapterGroup(id);
    if (res.success && res.data) {
      this.setDocChapterGroup(res.data);
    }
    return res;
  };

  public createDocChapterGroupReq = async (
    params: CreateDocChapterGroupReq,
  ) => {
    const res = await this._store.DocTemplateApi.createDocChapterGroup(params);
    if (res.success && res.data) {
      this.setDocChapterGroup(res.data, true);
    }
    return res;
  };

  public updateDocChapterGroupReq = async (
    id: number,
    params: UpdateDocChapterGroupReq,
  ) => {
    const res = await this._store.DocTemplateApi.updateDocChapterGroup(
      id,
      params,
    );
    if (res.success && res.data) {
      this.updateDocChapterGroup(res.data);
    }
    return res;
  };

  public deleteDocChapterGroupReq = async (id: number) => {
    const res = await this._store.DocTemplateApi.deleteDocChapterGroup(id);
    if (res.success) {
      this.deleteDocChapterGroup(id);
    }
    return res;
  };

  /*
   |----------------------------------------------------------------------------
   | RENDER
   |----------------------------------------------------------------------------
   */
  public getDeepElementsValue = (elements: CElement[], hideEmpty = false) => {
    const SYMBOL = '--';

    const getValue = (element: CElement) => {
      switch (element.type.id) {
        case DOC_ELEMENT_TYPE.mkx10:
        case DOC_ELEMENT_TYPE.icpc2:
        case DOC_ELEMENT_TYPE.list: {
          if (Array.isArray(element.value)) {
            if (!element.value.length) return SYMBOL;
            return element.value.map((e) => e.label).join(', ');
          }
          if (element.value) {
            return element.value.label;
          }
          return SYMBOL;
        }
        case DOC_ELEMENT_TYPE.string: {
          return element.value ? element.value : SYMBOL;
        }
        case DOC_ELEMENT_TYPE.number: {
          if (!element.value) return SYMBOL;
          return `${element.value} ${element.unit}`;
        }
        case DOC_ELEMENT_TYPE.text: {
          return null;
        }
        default:
          return SYMBOL;
      }
    };

    const deepElements = (elements: CElement[]): ValForPrint[] =>
      elements.reduce<ValForPrint[]>((prev, next) => {
        if (hideEmpty ? !isEmpty(next.value) : next) {
          prev.push(
            observable({
              type: next.type.id,
              label: next.title,
              value: getValue(next),
              isBig: false,
            }),
          );
        }
        if (next.children && next.children.length) {
          return prev.concat(deepElements(next.children));
        }
        return prev;
      }, []);

    return deepElements(toJS(elements));
  };
}
