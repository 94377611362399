import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBloodtypeBlackFilled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      role="img"
      {...props}
    >
      <path
        d="M12 2c-5.33 4.55-8 8.48-8 11.8 0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8m3 16H9v-2h6zm0-5h-2v2h-2v-2H9v-2h2V9h2v2h2z"
        fill="currentColor"
      />
    </svg>
  );
};
export default SvgBloodtypeBlackFilled;
