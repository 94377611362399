import { FetchService } from '@modules/Api';

export class PackageOrderApi extends FetchService {
  public getPackageOrders = (query?: GetPackageOrderQuery) => {
    return this.request<
      'PACKAGE_ORDERS',
      PackageOrder[],
      null,
      GetPackageOrderQuery
    >('/package_service_orders', 'GET_PACKAGE_ORDERS', {
      query,
    });
  };

  public getPackageOrder = (id: number) => {
    return this.request<'PACKAGE_ORDERS', PackageOrderDetailed>(
      '/package_service_orders',
      'GET_PACKAGE_ORDER',
      {
        pathParam: id,
      },
    );
  };
}
