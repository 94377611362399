import { action, makeObservable, observable } from 'mobx';

export class BiomaterialTypes {
  @observable public list: BiomaterialType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (data: BiomaterialType[]) => {
    this.list = data;
  };
}
