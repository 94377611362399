import { action, computed, makeObservable, observable } from 'mobx';
import { DURATION_STEP } from '../consts';

export class Candidate {
  @observable public groupId: number;

  @observable public duration: number;
  @observable private _services: Map<number, CandidateService> = new Map();
  @observable private _packageServices: Map<number, CandidateService> =
    new Map();

  constructor(data: CandidateConstructorArguments) {
    this.groupId = data.groupId;
    this.duration = data.duration;

    data.services.forEach((service) => {
      if (service.servicePackageId) {
        this._packageServices.set(service.id, service);
      } else {
        this._services.set(service.id, service);
      }
    });

    makeObservable(this);
  }

  @computed
  public get services() {
    return Array.from(this._services.values());
  }

  @computed
  public get packageServices() {
    return Array.from(this._packageServices.values());
  }

  @computed
  public get allServices() {
    return [...this.services, ...this.packageServices];
  }

  @computed
  public get price() {
    let cost = 0;

    for (const service of this.allServices) {
      cost += service.price * service.count;
    }

    return cost;
  }

  @computed
  public get originDuration() {
    let duration = 0;

    for (const service of this.allServices) {
      duration += service.duration * service.count;
    }

    return duration;
  }

  // Return only unique service ids
  @computed
  public get candidateServices() {
    const ids = this.allServices.map((service) => service.id);
    return Array.from(new Set([...ids]).values());
  }

  @action
  public changeDuration = (action: 'add' | 'remove') => {
    if (action === 'remove' && this.duration <= DURATION_STEP) return;
    if (action === 'add' && this.duration >= 60 * 12) return;

    if (action === 'add') {
      this.duration += DURATION_STEP;
    }

    if (action === 'remove') {
      this.duration -= DURATION_STEP;
    }
  };

  @action
  public resetDuration = () => {
    this.duration = this.originDuration;
  };

  @action
  public removeReferralDoctor = (
    serviceId: number,
    type: 'clinic' | 'package' = 'clinic',
  ) => {
    const storage = type === 'clinic' ? this._services : this._packageServices;
    const service = storage.get(serviceId);

    if (service) {
      service.referralDoctorId = null;
    }
  };

  @action
  public setReferralDoctor = (
    serviceId: number,
    referralDoctorId: number,
    type: 'clinic' | 'package' = 'clinic',
  ) => {
    const storage = type === 'clinic' ? this._services : this._packageServices;
    const service = storage.get(serviceId);

    if (service) {
      service.referralDoctorId = referralDoctorId;
    }
  };

  @action
  public addService = (service: CandidateService) => {
    const storage = service.servicePackageId
      ? this._packageServices
      : this._services;

    const addedService = storage.get(service.id);

    if (addedService) {
      addedService.count += 1;

      this.duration += addedService.duration;
      storage.set(addedService.id, addedService);
    } else {
      storage.set(service.id, service);
      this.duration += service.duration;
    }
  };

  @action
  private _removePackageService = (id: number) => {
    const service = this._packageServices.get(id);
    if (!service) return;

    if (service.count > 1) {
      service.count -= 1;

      this._packageServices.set(service.id, service);
      this.duration -= service.duration;
    }
  };

  @action
  private _removeService = (id: number) => {
    const service = this._services.get(id);
    if (!service) return;

    if (service.count > 1) {
      service.count -= 1;

      this._services.set(service.id, service);
      this.duration -= service.duration;
    }
  };

  @action
  public removeService = (
    id: number,
    type: 'clinic' | 'package' = 'clinic',
  ) => {
    type === 'clinic'
      ? this._removeService(id)
      : this._removePackageService(id);

    if (this.duration <= DURATION_STEP) {
      this.duration = DURATION_STEP;
    }
  };

  @action
  public clearService = (id: number, type: 'clinic' | 'package' = 'clinic') => {
    const storage = type === 'clinic' ? this._services : this._packageServices;

    const service = storage.get(id);
    if (!service) return;

    const serviceDuration = service.count * service.duration;

    storage.delete(id);
    this.duration -= serviceDuration;

    if (this.duration <= DURATION_STEP) {
      this.duration = DURATION_STEP;
    }
  };

  @action
  public clearDirections = () => {
    for (const service of this.allServices) {
      service.directionId = null;
    }
  };

  @action
  public clearPackageServices = () => {
    this._packageServices.clear();
  };
}
