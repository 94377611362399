import React from 'react';
import cn from 'classnames';
import { observer, useLocalObservable } from 'mobx-react';
import { useService } from '@stores/hooks';
import Select from '@components/UI/Select';
import {
  HealthFacilityFilterState as filterState,
  HFSelectType,
} from '@modules/Filters';
import { useTranslation } from 'react-i18next';
import './HealthFacility.scss';

type HfColorMarkProps = {
  background?: string;
  className?: string;
};

type HfOptionProps = {
  option: HFSelectType;
  children?: React.JSX.Element;
};

function HfColorMark({ background, className }: HfColorMarkProps) {
  return (
    <div
      data-testid="sidebar-hf-color"
      style={{ background }}
      className={cn('hf__color-mark', className)}
    />
  );
}

function HfOption({ option, children }: HfOptionProps) {
  return (
    <div className="hf__option" data-testid="sidebar-hf-option">
      {children}
      <p className="hf__option-title">{option?.title}</p>
    </div>
  );
}

function HealthFacility() {
  const { getHealthFacilities } = useService('HealthFacilityApi');
  const { t } = useTranslation('layout');

  const state = useLocalObservable(() => ({
    loading: false,
    data: [] as HealthFacility[],
    setData(data: HealthFacility[]) {
      this.data = data;
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
  }));

  const onMenuOpen = async () => {
    if (state.data.length) return;
    state.setLoading(true);

    const res = await getHealthFacilities({ 'filter[active]': true });
    if (res.success && res.data) {
      state.setData(res.data);
    }
    state.setLoading(false);
  };

  return (
    <div className="health-facility" data-testid="sidebar-hf">
      <div className="relative">
        <HfColorMark
          className="hf__color-mark--abs"
          background={
            filterState.healthFacility?.color_code
              ? filterState.healthFacility?.color_code
              : '#fff'
          }
        />
        <Select
          full
          name="hf"
          options={state.data}
          value={filterState.healthFacility}
          onChange={filterState.setHealthFacility}
          onMenuOpen={onMenuOpen}
          isLoading={state.loading}
          label={t('menu.subdivisions')}
          placeholder={t('menu.subdivisions')}
          className="hf-select"
          getOptionLabel={(o) => o.title}
          getOptionValue={(o) => o.id}
          formatOptionLabel={(o: HealthFacility) => (
            <HfOption option={o}>
              <HfColorMark background={o.color_code} className="mr-3" />
            </HfOption>
          )}
          formatSelectedOption={(o: HealthFacility) => <HfOption option={o} />}
          menuPortalTarget={document.getElementById('modal')}
          classNames={{ menuPortal: () => 'hf-select__portal' }}
          isSearchable={false}
        />
      </div>
      <div className="divider mt-5 mb-3" />
    </div>
  );
}

export default observer(HealthFacility);
