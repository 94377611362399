import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDemography = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13 13q.52 0 .885-.365.366-.364.365-.885 0-.52-.365-.885A1.2 1.2 0 0 0 13 10.5q-.52 0-.885.365a1.2 1.2 0 0 0-.365.885q0 .52.365.885.364.366.885.365m0 2.5q.626 0 1.167-.292.541-.291.896-.812a3.7 3.7 0 0 0-1-.427 4.2 4.2 0 0 0-2.126 0q-.52.135-1 .427.355.52.896.812.542.292 1.167.292m0 1.667q-1.729 0-2.948-1.22Q8.834 14.73 8.833 13q0-1.729 1.22-2.948Q11.27 8.834 13 8.833q1.729 0 2.948 1.22Q17.167 11.27 17.167 13t-1.22 2.948Q14.73 17.167 13 17.167M3.833 5.5h8.334V3.833H3.833zm3.896 10H2.167q-.688 0-1.177-.49a1.6 1.6 0 0 1-.49-1.177V2.167Q.5 1.479.99.99 1.479.5 2.167.5h11.666q.688 0 1.177.49.49.489.49 1.177V7.75a6 6 0 0 0-1.219-.437A5.5 5.5 0 0 0 13 7.167q-.23 0-.427.01a3 3 0 0 0-.406.052v-.062H3.833v1.666h5.105q-.375.354-.678.771a5.8 5.8 0 0 0-.53.896H3.832v1.667H7.23a3 3 0 0 0-.052.406q-.01.198-.01.427 0 .687.125 1.281t.437 1.219"
      />
    </svg>
  );
};
export default SvgDemography;
