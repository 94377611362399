import { GuarantorService } from './Guarantor';
import { InsuranceCompanyService } from './InsuranceCompany';
import { InsurancePolicyService } from './InsurancePolicies';
import { GuaranteeService } from './Guarantee';

export default class InsuranceService {
  public guarantor: GuarantorService;
  public companies: InsuranceCompanyService;
  public policies: InsurancePolicyService;
  public guarantee: GuaranteeService;

  constructor(store: Store) {
    this.guarantor = new GuarantorService(store);
    this.companies = new InsuranceCompanyService(store);
    this.policies = new InsurancePolicyService(store);
    this.guarantee = new GuaranteeService(store);
  }
}
