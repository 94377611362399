import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDragIndicator = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.167 15c0 .917-.75 1.667-1.667 1.667S5.834 15.917 5.834 15s.75-1.667 1.666-1.667c.917 0 1.667.75 1.667 1.667M7.5 8.333c-.917 0-1.666.75-1.666 1.667s.75 1.667 1.666 1.667c.917 0 1.667-.75 1.667-1.667S8.417 8.333 7.5 8.333m0-5c-.917 0-1.666.75-1.666 1.667s.75 1.667 1.666 1.667c.917 0 1.667-.75 1.667-1.667S8.417 3.333 7.5 3.333m5 3.334c.917 0 1.667-.75 1.667-1.667s-.75-1.667-1.667-1.667c-.916 0-1.666.75-1.666 1.667s.75 1.667 1.666 1.667m0 1.666c-.916 0-1.666.75-1.666 1.667s.75 1.667 1.666 1.667c.917 0 1.667-.75 1.667-1.667s-.75-1.667-1.667-1.667m0 5c-.916 0-1.666.75-1.666 1.667s.75 1.667 1.666 1.667c.917 0 1.667-.75 1.667-1.667s-.75-1.667-1.667-1.667"
      />
    </svg>
  );
};
export default SvgDragIndicator;
