import { AppealStore } from '@modules/Appeals/appeal.store';

export class AppealService extends AppealStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public startAppealReq = (appeal: ActiveAppeal) => {
    this.setActiveAppeal(appeal);
  };

  public createAppealReq = async (appeal: CreateAppealReq) => {
    const res = await this._store.AppealApi.createAppeal(appeal);
    if (res.success && res.data) {
      this.setActiveAppeal(null);
    }
    return res;
  };

  public getAppealsReq = async (query?: GetAppealsQuery) => {
    const res = await this._store.AppealApi.getAppeals(query);
    console.log(res);
    if (res.success && res.data) {
      this.setAppeals(res.data, res.meta);
    }
    return res;
  };
}
