import { action, makeObservable, observable } from 'mobx';

export class PaymentStore {
  @observable public payments: Payment[] = [];
  @observable public paymentsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public paymentReportsData: PaymentReport[] = [];
  @observable public paymentReportsHeader: PaymentReportHeader = {
    total_discount: 0,
    total_sum: 0,
  };
  @observable public paymentReportsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public groupedReport: PaymentGroupedReport = {
    report_data: [],
    total_base_cost_sum: 0,
    total_payments_sum: 0,
    total_service_count: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setPayments = (payments: Payment[], meta: ResponseMeta) => {
    this.payments = payments;
    this.paymentsMeta = meta;
  };

  @action
  public updatePayment = (payment: Payment) => {
    const item = this.payments.find((e) => e.id === payment.id);

    if (item) {
      item.payment_status = payment.payment_status;
    }
  };

  @action
  public updatePayments = (payments: Payment[]) => {
    payments.forEach((payment) => this.updatePayment(payment));
  };

  @action
  public setPaymentReportData = (
    { payments }: PaymentReportData,
    meta: ResponseMeta,
  ) => {
    this.paymentReportsData = payments.map((e) => ({ ...e, id: e.order_id }));
    this.paymentReportsMeta = meta;
  };

  @action
  public setPaymentReportHeader = (data: PaymentReportHeader) => {
    this.paymentReportsHeader = data;
  };

  @action
  public setCreatedPayment = (payment: PaymentReport) => {
    this.paymentReportsData = [
      { ...payment, id: payment.order_id },
      ...this.paymentReportsData,
    ];
  };

  @action
  public setGroupedReport = (data: PaymentGroupedReport) => {
    this.groupedReport.report_data = data.report_data;
    this.groupedReport.total_payments_sum = data.total_payments_sum;
    this.groupedReport.total_base_cost_sum = data.total_base_cost_sum;
    this.groupedReport.total_service_count = data.total_service_count;
  };

  @action
  public clearReports = () => {
    this.paymentReportsData = [];
    this.paymentReportsHeader = { total_discount: 0, total_sum: 0 };
    this.paymentReportsMeta = { totalPages: 0, totalRecords: 0 };
  };
}
