import { DocDirectionsStore } from '@modules/DocDirections/doc-directions.store';

export class DocDirectionsService extends DocDirectionsStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getDocDirectionsReq = async (query?: GetDocDirectionsReq) => {
    const res = await this._store.DocDirectionsApi.getDocDirections(query);
    if (res.success && res.data) {
      this.setDocDirections(res.data);
    }
    return res;
  };

  public getDocDirectionReq = async (id: number) => {
    const res = await this._store.DocDirectionsApi.getDocDirection(id);
    if (res.success && res.data) {
      this.setDocDirection(res.data);
    }
    return res;
  };

  public createDocDirectionReq = async (params: CreateDocDirection) => {
    const res = await this._store.DocDirectionsApi.createDocDirection(params);
    if (res.success && res.data) {
      this.addDocDirection(res.data);
    }
    return res;
  };

  public updateDocDirectionReq = async (
    id: number,
    params: UpdateDocDirection,
  ) => {
    const res = await this._store.DocDirectionsApi.updateDocDirection(
      id,
      params,
    );
    if (res.success && res.data) {
      this.updateDocDirection(res.data);
    }
    return res;
  };

  public deleteDocDirectionReq = async (id: number) => {
    const res = await this._store.DocDirectionsApi.deleteDocDirection(id);
    if (res.success) {
      this.deleteDocDirection(id);
    }
    return res;
  };
}
