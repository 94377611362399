import dayjs, { OpUnitType } from 'dayjs';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import { TIME_FORMATS } from '@modules/Format/consts';
import moment from 'moment';
import { Format } from '@modules/Format/typings';
import isNumber from 'lodash/isNumber';
import i18n from '@lib/i18next';

dayjs.extend(relativeTimePlugin);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);

class DateService {
  constructor() {
    dayjs.locale(i18n.language);
    i18n.on('languageChanged', (language) => dayjs.locale(language));
  }

  public getDiffDates = (
    startDate: Date,
    endDate: Date,
    opt?: 'even' | 'odd' | '1x3' | '2x2' | '2x3' | string,
  ): Date[] => {
    const addDays = (_date: Date, days: number) => {
      const date = new Date(_date);
      date.setDate(date.getDate() + days);
      return date;
    };
    const results = [];
    let _startDate = startDate;
    let index = 0;
    while (_startDate <= endDate) {
      index++;
      if (opt === 'even' && _startDate.getDate() % 2 === 0) {
        results.push(new Date(_startDate));
      }
      if (opt === 'odd' && _startDate.getDate() % 2 !== 0) {
        results.push(new Date(_startDate));
      }
      if (opt === '1x3' && index % 4 === 1) {
        results.push(new Date(_startDate));
      }
      if (opt === '2x2' && (index % 4 === 1 || index % 4 === 2)) {
        results.push(new Date(_startDate));
      }
      if (opt === '2x3' && (index % 5 === 1 || index % 5 === 2)) {
        results.push(new Date(_startDate));
      }
      if (
        opt !== 'even' &&
        opt !== 'odd' &&
        opt !== '1x3' &&
        opt !== '2x2' &&
        opt !== '2x3'
      ) {
        results.push(new Date(_startDate));
      }
      _startDate = addDays(_startDate, 1);
    }
    return results;
  };

  public minutesToHHmm(minutes: number): string {
    return moment
      .utc(moment.duration(minutes, 'minutes').asMilliseconds())
      .format('HH:mm');
  }

  public today(format: Format): string {
    return dayjs().format(TIME_FORMATS[format]);
  }

  public dateToUnix = (date: Date) => {
    return +moment(date).format('X');
  };

  public unixToDate = (timestamp: number) => {
    return moment.unix(timestamp).toDate();
  };

  public formatDate = (date: number | string | Date, format: Format) => {
    if (typeof date === 'number') {
      return dayjs(date * 1000).format(TIME_FORMATS[format]);
    }
    return dayjs(date).format(TIME_FORMATS[format]);
  };

  public parseCustomDate = (date: number | string | Date, format: Format) => {
    return dayjs(date, TIME_FORMATS[format]).toDate();
  };

  public minutesTo = (
    min: number,
    format: 'seconds' | 'milliseconds',
  ): number => {
    const value = format === 'milliseconds' ? 60000 : 60;
    return min * value;
  };

  public msToMin = (ms: number) => {
    return ms / 60000;
  };

  public secondsToHHMMSS = (seconds: number): string => {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  };

  public capitalizeFirstLetter(ward: string): string {
    return ward.charAt(0).toUpperCase() + ward.slice(1);
  }

  public parseTokenDate = (dateTime: string) => {
    return {
      origin: dateTime,
      parsed: this.formatDate(moment(dateTime).unix(), 'HH_mm_ss'),
      unix: moment(dateTime).unix(),
    };
  };

  /**
   * DateService.getDiffTimes(
   * new Date('Thu Jun 17 2021 19:00'),
   * new Date('Thu Jun 17 2021 20:00'),
   */
  public getDiffTimes = (
    startTime: Date,
    endTime: Date,
    stepMin = 5,
    mod = false,
  ): Date[] => {
    const addTime = (_date: Date, minutes: number) => {
      return new Date(_date.getTime() + minutes * 60000);
    };
    const results = [];
    let _startTime = startTime;
    const _endTime = mod
      ? new Date(endTime.getTime() + stepMin * 60000)
      : endTime;
    while (_startTime <= _endTime) {
      results.push(new Date(_startTime));
      _startTime = addTime(_startTime, stepMin);
    }
    return results;
  };

  public getAge = (date?: string | null) => {
    if (!date) return String(0);
    if (!this.isValidDate(date)) return String('');

    let age = 0;
    const dateNow = dayjs();
    const birthday = dayjs(date);

    age = dateNow.diff(birthday, 'years');
    if (age) {
      return `${age}${i18n.t('age.y')}`;
    }
    age = dateNow.diff(birthday, 'months');
    if (age) {
      return `${age}${i18n.t('age.m')}`;
    }
    age = dateNow.diff(birthday, 'day');
    if (age) {
      return `${age}${i18n.t('age.d')}`;
    }
    return String(age);
  };

  public isDateExpired = (date: string | Date) => {
    return dayjs(date).isBefore(new Date());
  };

  public isToday = (date: Date | string | number) => {
    return dayjs(date).isToday();
  };

  public isFutureDate = (date: string | Date) => {
    return dayjs(date).isAfter(new Date());
  };

  public createDate = (
    date?: Date | string | number,
    config?: { hour?: number; minute?: number; second?: number },
  ) => {
    let day = dayjs(date);
    if (config) {
      if (isNumber(config.hour)) day = day.hour(config.hour);
      if (isNumber(config.minute)) day = day.minute(config.minute);
      if (isNumber(config.second)) day = day.second(config.second);
    }
    return day;
  };

  public createStartDay = (date: Date | string | number = new Date()) => {
    return dayjs(date).hour(0).minute(0).second(0).millisecond(0);
  };

  public createEndDay = (date: Date | string | number = new Date()) => {
    return dayjs(date).hour(23).minute(59).second(59).millisecond(59);
  };

  public getValue = (date: Date | string | number) => {
    const day = dayjs(date);

    return {
      year: () => day.year(),
      month: () => day.month(),
      weekday: () => day.day(),
      day: () => day.date(),
      hour: () => day.hour(),
      minute: () => day.minute(),
      second: () => day.second(),
    };
  };

  public isSame = (
    date: number | string | Date,
    compare: number | string | Date,
    opt?: OpUnitType,
  ) => {
    return dayjs(date).isSame(compare, opt);
  };

  public isValidDate = (
    date?: string | number | Date | null,
  ): date is string | number | Date => {
    if (!date) return false;
    return dayjs(date).isValid();
  };

  public getBirthday = (
    date: string | null,
    format: Format = 'DD_MMMM_YYYY',
  ) => {
    if (!date || !this.isValidDate(date)) {
      return { date: '--', age: 0, result: '--' };
    }
    const _date = this.formatDate(date, format);
    const _age = this.getAge(date);
    return { date: _date, age: _age, result: `${_date}  (${_age})` };
  };

  public convertTo = (
    from: 'days' | 'weeks' | 'months',
    to: 'days' | 'weeks' | 'months',
    value: number,
  ) => {
    const config = {
      days_to_weeks: (value: number) => {
        return Math.floor(value / 7);
      },
      days_to_months: (value: number) => {
        return Math.floor(value / 30);
      },
      weeks_to_days: (value: number) => {
        return Math.floor(value * 7);
      },
      weeks_to_months: (value: number) => {
        return Math.floor(value / 4);
      },
      months_to_days: (value: number) => {
        return Math.floor(value * 30);
      },
      months_to_weeks: (value: number) => {
        return Math.floor(value * 4);
      },
    };

    if (value) {
      const key = `${from}_to_${to}` as keyof typeof config;
      return config[key](value);
    }

    return value;
  };

  public roundToHighestMinute = (date: Date, interval: number) => {
    const coef = 1000 * 60 * interval;
    return new Date(Math.ceil(date.getTime() / coef) * coef);
  };

  public hoursToDays = (duration: number) => {
    const hours = dayjs.duration(duration, 'hours');
    if (duration < 24) {
      return hours.format(`H[ ${i18n.t('age.h')}]`);
    }
    if (duration % 24 === 0) {
      return hours.format(`D[ ${i18n.t('age.d')}]`);
    }
    return hours.format(`D[ ${i18n.t('age.d')}] H[ ${i18n.t('age.h')}]`);
  };
}

export default new DateService();
