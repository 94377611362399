import { SalaryStore } from '@modules/Users/salary/salary.store';

export class SalaryService extends SalaryStore {
  protected readonly _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getUserSalaryReq = async (userId: number) => {
    const res = await this._store.UsersApi.getUserSalary(userId);
    if (res.success && res.data) {
      this.setUserSalary(res.data);
    }
    return res;
  };

  public updateUserSalaryReq = async (params: UpdateUserSalary) => {
    const res = await this._store.UsersApi.updateUserSalary(params);
    if (res.success && res.data) {
      this.setUserSalary(res.data);
    }
    return res;
  };

  public updateUserSalaryCommissionsReq = async (
    params: UpdateUserSalaryCommissions,
  ) => {
    const res = await this._store.UsersApi.updateUserSalaryCommissions(params);
    if (res.success && res.data) {
      this.updateUserSalaryCommissions(res.data);
    }
    return res;
  };

  public getSalaryReportReq = async (params: GetMonthlySalaryReport) => {
    const res = await this._store.UsersApi.getSalaryReport(params);
    if (res.success && res.data) {
      this.setUsersSalaries(res.data, res.meta);
    }
    return res;
  };

  public getUserSalaryReportReq = async (
    id: number,
    params?: GetUserSalaryReport,
  ) => {
    const res = await this._store.UsersApi.getUserSalaryReport(id, params);
    if (res.success && res.data) {
      this.setUserSalaryDetailed(res.data, res.meta);
    }
    return res;
  };

  public downloadSalaryReportReq = async (params: GetMonthlySalaryReport) => {
    return await this._store.UsersApi.downloadSalaryReport(params);
  };

  public downloadUserSalaryReportReq = async (
    id: number,
    params?: GetUserSalaryReport,
  ) => {
    return await this._store.UsersApi.downloadUserSalaryReport(id, params);
  };
}
