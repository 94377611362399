import React, { memo } from 'react';
import * as icons from '@assets/icons';
import { iconSizes } from '@components/UI/Icon';

function Icon({ name, size = 'medium', color, testId, ...rest }: IconProps) {
  if (!icons[name]) {
    return <div className="text-red-primary p-1">⚠️error "{name}"</div>;
  }

  const Component = icons[name];
  const _size = {
    width: iconSizes[size],
    height: iconSizes[size],
  };

  return (
    <Component
      data-testid={testId || name}
      {..._size}
      {...rest}
      color={color}
    />
  );
}

export default memo(Icon);
