import { FetchService } from '@modules/Api';

export class AddressApi extends FetchService {
  public getPlaces = (query?: GetPlacesQuery) => {
    return this.request<'ADDRESS', PlaceType[], null, GetPlacesQuery>(
      '/address/place',
      'GET_PLACES',
      { query },
    );
  };

  public createPlace = (params: CreatePlaceParams) => {
    return this.request<'ADDRESS', PlaceType>(
      '/address/place',
      'CREATE_PLACE',
      { method: 'POST', params },
    );
  };

  public getRegions = (query?: GetRegionsQuery) => {
    return this.request<'ADDRESS', RegionType[], GetRegionsQuery>(
      '/address/region',
      'GET_REGIONS',
      { query },
    );
  };
}
