import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOffline = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.167 5.809 4.408 2.05A8.26 8.26 0 0 1 9 .667C13.6.667 17.333 4.4 17.333 9c0 1.7-.508 3.275-1.383 4.592l-1.217-1.217A6.53 6.53 0 0 0 15.667 9c0-2.791-1.725-5.183-4.167-6.175v.342c0 .917-.75 1.667-1.667 1.667H8.167zm8.491 10.85-1.175 1.175-1.891-1.892A8.2 8.2 0 0 1 9 17.334 8.336 8.336 0 0 1 .667 9c0-1.7.508-3.275 1.383-4.591L.158 2.517l1.175-1.175zM8.167 14C7.25 14 6.5 13.25 6.5 12.334V11.5L2.508 7.51A6.8 6.8 0 0 0 2.333 9c0 3.4 2.542 6.2 5.834 6.609z"
      />
    </svg>
  );
};
export default SvgOffline;
