import { AppointmentsApi } from './Appointments';
import { CalendarApi } from './Calendar';
import { ScheduleApi } from './Schedule.api';

export class WsApi {
  public appointments: AppointmentsApi;
  public calendar: CalendarApi;
  public schedule: ScheduleApi;

  constructor(store: Store) {
    this.appointments = new AppointmentsApi(store);
    this.calendar = new CalendarApi(store);
    this.schedule = new ScheduleApi(store);
  }
}
