import EntityStore from '@modules/Entity/entity.store';

export class EntityService extends EntityStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getEntitiesRequest = async (query?: GetEntitiesQuery) => {
    const response = await this._store.EntityApi.getEntities(query);
    if (response.success && response.data) {
      this.setEntities(response.data, response.meta);
    }
    return response;
  };
}
