import { action, makeObservable, observable } from 'mobx';

export class ConsumableStore {
  @observable public consumable: Consumable | null = null;
  @observable public consumables: Consumable[] = [];
  @observable public consumablesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public consumableType: ConsumableType | null = null;
  @observable public consumableTypes: ConsumableType[] = [];
  @observable public consumableTypesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setConsumables = (data: Consumable[], meta: ResponseMeta) => {
    this.consumables = data;
    this.consumablesMeta = meta;
  };

  @action
  public setConsumable = (data: Consumable | null) => {
    this.consumable = data;
  };

  @action
  public addConsumable = (data: Consumable) => {
    this.consumables = [data, ...this.consumables];
  };

  @action
  public updateConsumable = (data: Partial<Consumable>) => {
    this.consumables = this.consumables.map((c) =>
      c.id === data.id ? Object.assign(c, data) : c,
    );
  };

  @action
  public deleteConsumable = (id: number) => {
    this.consumables = this.consumables.filter((c) => c.id !== id);
  };

  //

  @action
  public setConsumableTypes = (data: ConsumableType[], meta: ResponseMeta) => {
    this.consumableTypes = data;
    this.consumableTypesMeta = meta;
  };

  @action
  public setConsumableType = (data: ConsumableType | null) => {
    this.consumableType = data;
  };

  @action
  public addConsumableType = (data: ConsumableType) => {
    this.consumableTypes = [data, ...this.consumableTypes];
  };

  @action
  public updateConsumableType = (data: Partial<ConsumableType>) => {
    this.consumableTypes = this.consumableTypes.map((c) =>
      c.id === data.id ? Object.assign(c, data) : c,
    );
  };

  @action
  public deleteConsumableType = (id: number) => {
    this.consumableTypes = this.consumableTypes.filter((c) => c.id !== id);
  };
}
