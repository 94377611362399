import { ServicesStore } from '@modules/Services/services.store';

export class ServicesService extends ServicesStore {
  protected _store: Store;
  protected _type: ServiceType = 'clinic_service';

  constructor(store: Store) {
    super(store);
    this._store = store;
  }

  /*
  |----------------------------------------------------------------------------
  | SERVICES
  |----------------------------------------------------------------------------
  */

  public getServicesReq = async (query?: GetServicesQuery) => {
    const queries: GetServicesQuery = { 'filter[type]': this._type, ...query };
    const response = await this._store.ServicesApi.getServices(queries);

    if (response.success && response.data) {
      this.setServices(response.data, response.meta);
    }

    return response;
  };

  public getServicesReqForPrice = async (query?: GetServicesQuery) => {
    const response = await this._store.ServicesApi.getServices(query);

    if (response.success && response.data) {
      this.setServices(response.data, response.meta);
    }

    return response;
  };

  public getServiceReq = async (id: number, query?: GetServiceQuery) => {
    const response = await this._store.ServicesApi.getService(id, query);
    if (response.success && response.data) {
      this.setService(response.data);
    }
    return response;
  };

  public getServiceBlankReq = () => {
    return this._store.ServicesApi.getServiceBlank();
  };

  public getServiceUsersReq = async (
    id: number,
    query?: GetServiceUsersQuery,
  ) => {
    const response = await this._store.ServicesApi.getServiceUsers(id, query);
    if (response.success && response.data) {
      this.setServiceUsers(response.data, response.meta);
    }
    return response;
  };

  public getServiceCabinetsReq = async (
    id: number,
    query?: GetServiceCabinetsQuery,
  ) => {
    const response = await this._store.ServicesApi.getServiceCabinets(
      id,
      query,
    );
    if (response.success && response.data) {
      this.setServiceCabinets(response.data, response.meta);
    }
    return response;
  };

  public removeServiceCabinetReq = async (params: RemoveServiceCabinetReq) => {
    const response = await this._store.ServicesApi.removeServiceCabinet(params);
    if (response.success) {
      this.removeServiceCabinet(params.cabinet_id);
    }
    return response;
  };

  public addServiceCabinetReq = async (params: AddServiceCabinetReq) => {
    const response = await this._store.ServicesApi.addServiceCabinet(params);
    if (response.success && response.data) {
      this.addServiceCabinet(response.data);
    }
    return response;
  };

  public getServiceRelatedServicesReq = async (
    id: number,
    query?: GetServiceRelatedServicesQuery,
  ) => {
    const response = await this._store.ServicesApi.getServiceRelatedService(
      id,
      query,
    );
    if (response.success && response.data) {
      this.setServiceRelatedServices(response.data, response.meta);
    }
    return response;
  };

  public addServiceRelatedServiceReq = async (
    params: AddServiceRelatedServiceReq,
  ) => {
    const response =
      await this._store.ServicesApi.addServiceRelatedService(params);
    if (response.success && response.data) {
      this.addServiceRelatedService(response.data);
    }
    return response;
  };

  public removeServiceRelatedServiceReq = async (
    params: RemoveServiceRelatedServiceReq,
  ) => {
    const response =
      await this._store.ServicesApi.removeServiceRelatedService(params);
    if (response.success) {
      this.removeServiceRelatedService(params.relatedServiceId);
    }
    return response;
  };

  public createServiceReq = async (params: CreateServiceReq) => {
    const _params = { ...params, type: this._type };

    const response = await this._store.ServicesApi.createService(_params);
    if (response.success && response.data) {
      this.addServices([response.data]);
    }
    return response;
  };

  public updateServiceReq = async (id: number, params: UpdateServiceReq) => {
    const response = await this._store.ServicesApi.updateService(id, params);
    if (response.success && response.data) {
      this.updateService(response.data, [
        'code',
        'status',
        'title',
        'short_title',
        'specialization',
        'duration_independent',
        'duration_combined',
        'has_dependency',
        'description',
        'short_description',
        'participation_commissions_enabled',
        'service_category',
      ]);
    }
    return response;
  };

  public deleteServiceReq = async (id: number) => {
    const response = await this._store.ServicesApi.deleteService(id);
    if (response.success) {
      this.deleteService(id);
    }
    return response;
  };

  public addServiceUserReq = async (params: AddServiceUserReq) => {
    const response = await this._store.ServicesApi.addServiceUser(params);
    if (response.success && response.data) {
      this.addServiceUser(response.data);
    }
    return response;
  };

  public removeServiceUserReq = async (serviceId: number, userId: number) => {
    const response = await this._store.ServicesApi.removeServiceUser(
      serviceId,
      userId,
    );
    if (response.success) {
      this.removeServiceUser(userId);
    }
    return response;
  };

  public deactivateServiceReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.deactivateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      this.updateServiceStatus(res.data);
    }
    return res;
  };

  public activateServiceReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.activateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      this.updateServiceStatus(res.data);
    }
    return res;
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE CATEGORIES
  |----------------------------------------------------------------------------
  */
  public getServiceCategoriesReq = async (
    query?: GetServiceCategoriesQuery,
  ) => {
    const response = await this._store.ServicesApi.getServiceCategories(query);
    if (response.success && response.data) {
      this.setServiceCategories(response.data, response.meta);
    }
    return response;
  };

  public getServiceCategoriesTreeReq = async (
    query?: GetServiceCategoriesQuery,
  ) => {
    const response =
      await this._store.ServicesApi.getServiceCategoriesTree(query);

    if (response.success && response.data) {
      this.setServiceCategoriesTree(response.data);
    }

    return response;
  };

  public createServiceCategoryReq = async (
    params: CreateServiceCategoryQuery,
  ) => {
    return await this._store.ServicesApi.createServiceCategory(params);
  };

  public updateServiceCategoryReq = async (
    id: number,
    params: UpdateServiceCategory,
  ) => {
    return await this._store.ServicesApi.updateServiceCategory(id, params);
  };

  public deleteServiceCategoryReq = async (id: number) => {
    return await this._store.ServicesApi.deleteServiceCategory(id);
  };

  /*
  |----------------------------------------------------------------------------
  | SERVICE RELATION TYPE
  |----------------------------------------------------------------------------
  */
  public getServiceRelationTypesReq = async () => {
    const response = await this._store.ServicesApi.getServiceRelationTypes();
    if (response.success && response.data) {
      this.setServiceRelationTypes(response.data);
    }
    return response;
  };

  public getServiceRelationTypeReq = async (id: number) => {
    const response = await this._store.ServicesApi.getServiceRelationType(id);
    if (response.success && response.data) {
      this.setServiceRelationType(response.data);
    }
    return response;
  };

  public createServiceRelationTypeReq = async (
    params: CreateServiceRelationTypeReq,
  ) => {
    const response =
      await this._store.ServicesApi.createServiceRelationType(params);
    if (response.success && response.data) {
      this.addServiceRelationType(response.data);
    }
    return response;
  };

  public updateServiceRelationTypeReq = async (
    id: number,
    params: UpdateServiceRelationTypeReq,
  ) => {
    const response = await this._store.ServicesApi.updateServiceRelationType(
      id,
      params,
    );
    if (response.success && response.data) {
      this.updateServiceRelationType(response.data);
    }
    return response;
  };

  public deleteServiceRelationTypeReq = async (id: number) => {
    const response =
      await this._store.ServicesApi.deleteServiceRelationType(id);
    if (response.success) {
      this.deleteServiceRelationType(id);
    }
    return response;
  };
}
