import { action, makeObservable, observable } from 'mobx';
import { DocTemplate } from '@modules/Documents/DocTemplate/mapper/DocTemplate';
import isString from 'lodash/isString';

export class DocTemplateStore {
  protected readonly _store: Store;

  @observable public docTemplate: DocTemplate | null = null;
  @observable public docTemplates: DocTemplateType[] = [];
  @observable public docTemplatesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  // GROUPS
  @observable public docTemplateGroups: DocTemplateGroup[] = [];
  @observable public docTemplateGroupsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor(store: Store) {
    makeObservable(this);
    this._store = store;
  }

  /*
   |----------------------------------------------------------------------------
   | TEMPLATE
   |----------------------------------------------------------------------------
   */
  @action
  public setDocTemplates = (
    template: DocTemplateType[],
    meta: ResponseMeta,
  ) => {
    this.docTemplates = template;
    this.docTemplatesMeta = meta;
  };

  @action
  public setDocTemplate = async (
    template: DocTemplateType | null | string,
    isNew = false,
    isDone = false,
  ) => {
    if (!template) {
      this.docTemplate = null;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {});
    }

    const { getDocElement } = this._store.DocTemplateApi;

    if (isNew && !isString(template)) {
      this.docTemplates = [template, ...this.docTemplates];
    }
    if (!isNew && !isString(template)) {
      this.docTemplate = new DocTemplate(template, getDocElement, isDone);
    }
    if (!isNew && isString(template)) {
      try {
        this.docTemplate = new DocTemplate(
          JSON.parse(template),
          getDocElement,
          isDone,
        );
      } catch (e: any) {
        console.error(e);
        this._store.ErrorService.syntaxError('UNKNOWN_KEY', e, e);
        return Promise.reject(e);
      }
    }
    return Promise.resolve();
  };

  @action
  public updateDocTemplate = (
    updatedTemplate: DocTemplateType,
    onlyActive = false,
  ) => {
    const template = this.docTemplates.find((e) => e.id === updatedTemplate.id);
    if (template) {
      Object.assign(template, updatedTemplate);
    }
    if (this.docTemplate?.id === updatedTemplate.id && !onlyActive) {
      this.docTemplate = new DocTemplate(
        updatedTemplate,
        this._store.DocTemplateApi.getDocElement,
      );
    }
    if (this.docTemplate?.id === updatedTemplate.id && onlyActive) {
      this.docTemplate.active = updatedTemplate.active;
    }
  };

  @action
  public deleteDocTemplate = (id: number) => {
    this.docTemplates = this.docTemplates.filter((e) => e.id !== id);
  };

  /*
   |----------------------------------------------------------------------------
   | TEMPLATE GROUP
   |----------------------------------------------------------------------------
   */
  @action
  public setDocTemplateGroups = (
    elements: DocTemplateGroup[],
    meta: ResponseMeta,
  ) => {
    this.docTemplateGroups = elements;
    this.docTemplateGroupsMeta = meta;
  };

  @action
  public setTemplateGroup = (el: DocTemplateGroup) => {
    this.docTemplateGroups = [el, ...this.docTemplateGroups];
  };

  @action
  public updateTemplateGroup = (el: DocTemplateGroup) => {
    const group = this.docTemplateGroups.find((e) => e.id === el.id);
    if (group) {
      Object.assign(group, el);
    }
  };

  @action
  public deleteTemplateGroup = (id: number) => {
    this.docTemplateGroups = this.docTemplateGroups.filter((e) => e.id !== id);
  };
}
