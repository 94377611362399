import { action, makeObservable } from 'mobx';
import { USER_STATUS } from '@modules/Users';

export class ServiceStaff {
  public readonly id: number;
  public activeStatus = false;
  public delete_marked = false;
  public canBeLoggedIn = false;
  public isFirstEntry = false;
  public name = '';
  public position?: Position;
  public specializations: Specialization[] = [];
  public status: UserStatus = USER_STATUS.NO_ACCESS;

  constructor(user: ServiceUserResponse) {
    makeObservable(this);
    this.id = user.id;
    this.activeStatus = user.activeStatus;
    this.delete_marked = user.delete_marked;
    this.canBeLoggedIn = user.canBeLoggedIn;
    this.canBeLoggedIn = user.isFirstEntry;
    this.position = user.position;
    this.specializations = user.specializations;
    this.setStatus(user);
    this._setName(user);
  }

  @action
  public setValue(key: keyof ServiceStaff, value: unknown): void {
    if (key !== 'id') this[key] = value as never;
  }

  @action
  public setStatus = (user: ServiceUserResponse): void => {
    this.activeStatus = user.activeStatus;
    this.delete_marked = user.delete_marked;
    this.canBeLoggedIn = user.canBeLoggedIn;
    this.isFirstEntry = user.isFirstEntry;
    this.status = user.delete_marked
      ? USER_STATUS.REMOVED
      : user.activeStatus && user.canBeLoggedIn && !user.isFirstEntry
        ? USER_STATUS.ACTIVE
        : user.activeStatus && user.canBeLoggedIn && user.isFirstEntry
          ? USER_STATUS.NOT_ACTIVATED
          : !user.canBeLoggedIn || !user.activeStatus
            ? USER_STATUS.NO_ACCESS
            : USER_STATUS.NO_ACCESS;
  };

  private _setName(user: ServiceUserResponse) {
    this.name = `${user.last_name} ${user.first_name} ${user.patronymic}`;
  }
}
