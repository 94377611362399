import { FetchService } from '@modules/Api';

export class EntityApi extends FetchService {
  public getEntities = (query?: GetEntitiesQuery) => {
    return this.request<'ENTITY', Entity[]>('/entity', 'GET_ENTITIES', {
      permit: 'entities',
      query,
    });
  };
}
