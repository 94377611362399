import { DateService } from '@modules/Format';
import { StringService } from '@modules/String';
import { action, makeObservable, observable } from 'mobx';

const formatter = {
  time: (start: number, end: number) => {
    const _start = DateService.formatDate(start, 'HH_mm');
    const _end = DateService.formatDate(end, 'HH_mm');

    return `${_start} - ${_end}`;
  },
  date: (date: number): string => {
    return DateService.formatDate(date, 'DD.MM.YYYY');
  },
  cabinet: (cabinet: CabinetResponse): string => {
    return `${cabinet.cabinet_number} - ${cabinet.title}`;
  },
  doctor: (user: UserResponse): string => {
    return StringService.userShortName(user);
  },
  patient: (patient: Patient | null): string => {
    if (!patient) return '--';
    return StringService.patientShortName(patient);
  },
};

export class AppointmentHistory {
  public readonly id: number;

  @observable public base: AppointmentHistoryResponse;
  @observable public actor: UserResponse;
  @observable public status!:
    | 'appointment_created'
    | 'status_changed'
    | 'appointment_changed';

  @observable public created: { date: string; time: string } = {
    date: '',
    time: '',
  };
  @observable public historyEvents: HistoryEvent[] = [];
  @observable public servicesAdded: AppointmentHistoryService[] = [];
  @observable public servicesRemoved: AppointmentHistoryService[] = [];

  constructor(h: AppointmentHistoryResponse) {
    makeObservable(this);
    this.base = h;
    this.actor = h.actor;
    this.id = h.id;
    this.created = {
      time: DateService.formatDate(h.created, 'HH_mm'),
      date: DateService.formatDate(h.created, 'DD.MM.YYYY'),
    };

    this._setStatus(h);
    this._setEventStatus(h.diff.status);
    this._setReceptionTime(h.diff.start_time, h.diff.end_time);
    this._setDate(h.diff.date);
    this._setCabinet(h.diff.cabinet);
    this._setPatient(h.diff.patient);
    this._setDoctor(h.diff.user);
    this._setConfirmed(h.diff.confirmed);
    this._setServices(h);
  }

  @action
  private _setStatus = (h: AppointmentHistoryResponse) => {
    if (h.diff.status) {
      // Add translation key which is meant that appointment is created
      if (h.diff.status.new_value === 'planned') {
        this.status = 'appointment_created';
        return;
      }

      // Add translation key which is meant that only appointment changed
      if (Object.keys(h.diff).length === 1) {
        this.status = 'status_changed';
        return;
      }
    }

    this.status = 'appointment_changed';
  };

  @action
  private _setReceptionTime = (
    start_time?: HistoryItem<number>,
    end_time?: HistoryItem<number>,
  ) => {
    if (!start_time || !end_time) return;

    const new_value = formatter.time(start_time.new_value, end_time.new_value);
    const old_value =
      start_time.old_value && end_time.old_value
        ? formatter.time(start_time.old_value, end_time.old_value)
        : null;

    this.historyEvents.push({
      key: 'time',
      new_value,
      old_value,
    });
  };

  @action
  private _setServices = (h: AppointmentHistoryResponse) => {
    if (!h.diff.services) return;

    this.servicesAdded = h.diff.services.added;
    this.servicesRemoved = h.diff.services.removed;
  };

  @action
  private _setDate = (date?: HistoryItem<string>) => {
    if (!date) return;

    this.historyEvents.push({
      key: 'date',
      new_value: DateService.formatDate(date.new_value, 'DD.MM.YYYY'),
      old_value: date.old_value
        ? DateService.formatDate(date.old_value, 'DD.MM.YYYY')
        : null,
    });
  };

  @action
  private _setEventStatus = (status?: HistoryItem<AppointmentStatus>) => {
    if (!status) return;

    this.historyEvents.push({
      key: 'status',
      new_value: status.new_value,
      old_value: status.old_value,
    });
  };

  @action
  private _setCabinet = (cabinet?: HistoryItem<CabinetResponse>) => {
    if (!cabinet) return;

    this.historyEvents.push({
      key: 'cabinet',
      new_value: formatter.cabinet(cabinet.new_value),
      old_value: cabinet.old_value
        ? formatter.cabinet(cabinet.old_value)
        : null,
    });
  };

  @action
  private _setPatient = (patient?: HistoryItem<Patient | null>) => {
    if (!patient) return;

    this.historyEvents.push({
      key: 'patient',
      new_value: formatter.patient(patient.new_value),
      old_value: formatter.patient(patient.old_value),
    });
  };

  @action
  private _setDoctor = (doctor?: HistoryItem<UserResponse>) => {
    if (!doctor) return;

    this.historyEvents.push({
      key: 'doctor',
      new_value: formatter.doctor(doctor.new_value),
      old_value: doctor.old_value ? formatter.doctor(doctor.old_value) : null,
    });
  };

  @action
  private _setConfirmed = (confirmed?: HistoryItem<boolean>) => {
    if (!confirmed) return;

    this.historyEvents.push({
      key: 'approve',
      new_value: confirmed.new_value ? 'confirmed' : 'not_confirmed',
      old_value: confirmed.old_value ? 'confirmed' : 'not_confirmed',
      translate: true,
    });
  };
}
