import * as React from 'react';
import type { SVGProps } from 'react';
const SvgScore = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.833 2.5H4.167C3.25 2.5 2.5 3.25 2.5 4.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667M10 4.167h1.25v2.5l1.667-2.5h1.416l-1.666 2.5 1.666 2.5h-1.416l-1.667-2.5v2.5H10zM5.833 6.042h2.084v-.625H5.833v-1.25h3.334v3.125H7.083v.625h2.084v1.25H5.833zm10 4.791-5 5L7.5 12.5l-3.333 3.333V13.75L7.5 10.417l3.333 3.333 5-5z"
      />
    </svg>
  );
};
export default SvgScore;
