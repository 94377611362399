import React from 'react';
import { Button } from '@components/UI/Button';
import { observer } from 'mobx-react';

type ModalButtonsProps = {
  buttons: ModalProps['buttons'];
};

function ModalButtons({
  buttons,
}: ModalButtonsProps): React.JSX.Element | null {
  if (!buttons.length) {
    return null;
  }
  return (
    <>
      {buttons.map(({ testID, ...button }, index) => (
        <Button key={index} data-testid={testID} {...button} />
      ))}
    </>
  );
}

export default observer(ModalButtons);
