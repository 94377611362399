import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDateRangeSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.25 12h-5v5h5zm-1-11v2h-8V1h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1zm3 18h-14V8h14z"
      />
    </svg>
  );
};
export default SvgDateRangeSecondary;
