import { FetchService } from '@modules/Api';

export class AuthApi extends FetchService {
  public login = (params: FormData) => {
    return this.request<'AUTH', LoginRes, FormData>('/auth/login', 'LOGIN', {
      method: 'POST',
      params,
      overrideHeaders: true,
      authRequired: false,
    });
  };

  public logout = (token: string) => {
    return this.request<'AUTH', void>('/auth/logout', 'LOGOUT', {
      headers: { RefreshToken: token },
      authRequired: false,
    });
  };

  public refreshToken = (token: string) => {
    return this.request<'AUTH', RefreshTokenRes>(
      '/auth/refresh',
      'REFRESH_TOKEN',
      {
        method: 'POST',
        overrideHeaders: true,
        headers: { RefreshToken: token },
        authRequired: false,
      },
    );
  };
}
