import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocBlue = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#2563EB"
          d="M11.667 1.667H5c-.917 0-1.658.75-1.658 1.666l-.009 13.334c0 .916.742 1.666 1.659 1.666H15c.917 0 1.667-.75 1.667-1.666v-10zM5 16.667V3.333h5.833V7.5H15v9.167z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDocBlue;
