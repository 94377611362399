import { action, makeObservable, observable } from 'mobx';
import { DocTemplate } from '@modules/Documents/DocTemplate/mapper/DocTemplate';

export class UserDocTemplatesStore {
  protected readonly _store: Store;

  @observable public userDocTemplate: UserDocTemplate | null = null;
  @observable public docTemplate: DocTemplate | null = null;
  @observable public userDocTemplates: UserDocTemplate[] = [];
  @observable public userDocTemplatesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor(store: Store) {
    this._store = store;
    makeObservable(this);
  }

  @action
  public setTemplates = (documents: UserDocTemplate[], meta: ResponseMeta) => {
    this.userDocTemplates = documents;
    this.userDocTemplatesMeta = meta;
  };

  @action
  public setTemplate = (doc: UserDocTemplate | null) => {
    this.userDocTemplate = doc;
  };

  @action
  public setDocTemplate = (template: string | null) => {
    if (template) {
      try {
        this.docTemplate = new DocTemplate(
          JSON.parse(template),
          this._store.DocTemplateApi.getDocElement,
        );
      } catch (e) {
        console.error(e);
        this._store.ModalService.modal.customError({
          title: 'Error template',
          message: JSON.stringify(e),
        });
      }
      return;
    }

    this.docTemplate = null;
  };

  @action
  public addTemplate = (doc: UserDocTemplate) => {
    this.userDocTemplates = [doc, ...this.userDocTemplates];
  };

  @action
  public updateTemplate = (doc: UserDocTemplate) => {
    const document = this.userDocTemplates.find((d) => d.id === doc.id);

    if (document) {
      Object.assign(document, doc);
    }

    if (this.userDocTemplate && this.userDocTemplate.id === doc.id) {
      this.setTemplate(doc);
    }
  };

  @action
  public deleteTemplate = (id: number) => {
    this.userDocTemplates = this.userDocTemplates.filter((d) => d.id !== id);
  };

  @action
  public clearTemplates = () => {
    this.userDocTemplates = [];
    this.docTemplate = null;
    this.userDocTemplate = null;
    this.userDocTemplatesMeta = { totalPages: 0, totalRecords: 0 };
  };
}
