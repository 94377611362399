import { action, makeObservable, observable } from 'mobx';

export default class LabAppointmentsStore {
  @observable public appointment: AppointmentType | null = null;
  @observable public appointments: AppointmentType[] = [];
  @observable public appointmentsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setAppointments = (
    data: AppointmentType[],
    meta: ResponseMeta,
  ): void => {
    this.appointments = data;
    this.appointmentsMeta = meta;
  };

  @action
  public setAppointment = (data: AppointmentType): void => {
    this.appointment = data;
  };

  @action
  public clearAppointment = (): void => {
    this.appointment = null;
  };

  @action
  public addAppointment = (data: AppointmentType): void => {
    this.appointments = [data, ...this.appointments];
  };

  @action
  public updateAppointment = (data: AppointmentType): void => {
    this.appointments = this.appointments.map((appointment) => {
      if (appointment.id === data.id) return data;
      return appointment;
    });

    if (this.appointment && this.appointment.id === data.id) {
      this.setAppointment(data);
    }
  };

  @action
  public removeAppointment = (id: number) => {
    this.appointments = this.appointments.filter(
      (appointment) => appointment.id !== id,
    );
  };
}
