import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOnPayment = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <rect width={28} height={28} fill="#0D9488" rx={4} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M14 5.667A8.336 8.336 0 0 0 5.667 14c0 4.6 3.733 8.333 8.333 8.333S22.333 18.6 22.333 14 18.6 5.667 14 5.667m.733 13.133v1.033h-1.458v-1.075c-.617-.15-1.992-.641-2.517-2.466l1.375-.559c.05.184.484 1.742 2 1.742.775 0 1.65-.4 1.65-1.342 0-.8-.583-1.216-1.9-1.691-.916-.325-2.791-.859-2.791-2.759 0-.083.008-2 2.183-2.466v-1.05h1.458V9.2c1.534.267 2.092 1.492 2.217 1.858l-1.317.559A1.61 1.61 0 0 0 14.05 10.5c-.584 0-1.509.308-1.509 1.158 0 .792.717 1.092 2.2 1.584 2 .691 2.509 1.708 2.509 2.875 0 2.191-2.084 2.608-2.517 2.683"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h20v20H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgOnPayment;
