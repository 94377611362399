import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReceiptLong = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={18}
      fill="none"
      viewBox="0 0 16 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.25 1.917 13 .667l-1.25 1.25L10.5.667l-1.25 1.25L8 .667l-1.25 1.25L5.5.667l-1.25 1.25L3 .667v11.666H.5v2.5c0 1.384 1.117 2.5 2.5 2.5h10c1.383 0 2.5-1.116 2.5-2.5V.667zm-.417 12.916a.836.836 0 0 1-.833.834.836.836 0 0 1-.833-.834v-2.5h-7.5V3.167h9.166z"
      />
      <path
        fill="currentColor"
        d="M10.5 4.833h-5V6.5h5zM13 4.833h-1.666V6.5H13zM10.5 7.333h-5V9h5zM13 7.333h-1.666V9H13z"
      />
    </svg>
  );
};
export default SvgReceiptLong;
