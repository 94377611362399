import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

type PortalPops = {
  children: React.JSX.Element | React.JSX.Element[];
};

export default function Portal({ children }: PortalPops) {
  const element = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const modalRoot = document.getElementById('modal');
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(element);
    };
  }, [element]);

  return createPortal(children, element);
}
