import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClinic = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M10 5.833V2.5H1.667v15h16.666V5.833zm-5 10H3.333v-1.666H5zM5 12.5H3.333v-1.667H5zm0-3.333H3.333V7.5H5zm0-3.334H3.333V4.167H5zm3.333 10H6.667v-1.666h1.666zm0-3.333H6.667v-1.667h1.666zm0-3.333H6.667V7.5h1.666zm0-3.334H6.667V4.167h1.666zm8.333 10H10v-1.666h1.666V12.5H10v-1.667h1.666V9.167H10V7.5h6.666zM15 9.167h-1.667v1.666H15zm0 3.333h-1.667v1.667H15z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgClinic;
