import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Text } from '@components/UI/Text';
import { BorderedTabs } from '@components/UI/Tabs';
import VisitServices from '@components/Header/components/TopbarRight/ActiveVisit/VisitServices';
import VisitDocuments from '@components/Header/components/TopbarRight/ActiveVisit/VisitDocuments';
import OverlayPanel, { OverlayOptions } from '@components/UI/OverlayPanel';
import { Tooltip } from '@components/UI/Tooltip';
import { Icon } from '@components/UI/Icon';
import { useTranslation } from 'react-i18next';
import { useService } from '@stores/hooks';

type Props = {
  event: DoctorWorkplace.VisitEvent;
};

function ActiveVisitDetailed({ event }: Props) {
  const { t } = useTranslation('workplace', { useSuspense: false });
  const { drawer } = useService('DrawerService');

  const tabs = useCallback(
    (props: OverlayOptions): UiTab[] => [
      {
        id: 'services',
        label: t('active_visit.services'),
        component: VisitServices,
        props: { ...props, event },
      },
      {
        id: 'documents',
        label: t('active_visit.documents'),
        component: VisitDocuments,
        props: { ...props, event },
      },
    ],
    [t, event],
  );

  return (
    <OverlayPanel
      position="left"
      preventClose={drawer.config.isOpen}
      trigger={(options: OverlayOptions) => (
        <Tooltip content={t('additional_info')}>
          <Text onClick={options.toggle} tag="span">
            <Icon name="ChevronDown" className="text-white" />
          </Text>
        </Tooltip>
      )}
      panel={(options) => (
        <div
          className="bg-white p-4 border border-coolGray-300 rounded-1 cursor-default"
          style={{ width: '380px' }}
        >
          <BorderedTabs tabs={tabs(options)} />
        </div>
      )}
      className="absolute top-0 right-0 h-full flex justify-center items-center p-2 rounded-r-sidebar bg-blueGray-700 border-l-2 border-sidebar"
    />
  );
}

export default observer(ActiveVisitDetailed);
