import React, { useMemo } from 'react';
import cn from 'classnames';
import { useService } from '@stores/hooks';
import { useTranslation } from 'react-i18next';
import { PopoverTabs } from '@components/UI/Tabs/PopoverTabs';
import { Icon } from '@components/UI/Icon';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

function PageSubNav({ routes }: { routes: BaseRoute[] }) {
  const { t } = useTranslation('layout');

  const { location, config } = useService('RoutingService');
  const { userCanRoute } = useService('PermissionsService');

  const subRoutes = useMemo(() => {
    const subPath = location.pathname.split('/').filter(Boolean);

    if (!subPath.length || subPath.length < 2) return [];

    const [_subMenu] = config.getRouteBy({
      field: 'path',
      value: `/${subPath[0]}/${subPath[1]}`,
      routes: routes,
      deep: false,
    });

    if (!_subMenu || !_subMenu.routes) return [];

    return _subMenu.routes.filter((e) => !e.hidden).filter(userCanRoute);
  }, [location, config, routes, userCanRoute]);

  if (!subRoutes.length) return null;

  return (
    <PopoverTabs
      accessor={(tab) => tab.path}
      className="sub-level"
      popoverContainerClasses="sub-level-dropdown"
      tabs={subRoutes}
      renderPopoverLabel={({ toggle, isOpen }) => (
        <li
          className={cn('tab-btn flex items-center cursor-pointer', {
            'bg-coolGray-300': isOpen,
          })}
          onClick={toggle}
        >
          <a>{t(`menu.more`)}</a>
          <Icon className={cn({ 'rotate-180': isOpen })} name="ArrowDropDown" />
        </li>
      )}
    >
      {({ tab, actions }) => (
        <li
          className={cn('tab-btn whitespace-nowrap', {
            active: location.pathname === tab.path,
          })}
          onClick={() => actions.close()}
          data-testid={tab.name}
        >
          <Link to={tab.path}>{t(`menu.${tab.name}` as any)}</Link>
        </li>
      )}
    </PopoverTabs>
  );
}

export default observer(PageSubNav);
