import { action } from 'mobx';
import GuaranteeStore from './guarantee.store';

export default class GuaranteesService extends GuaranteeStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  @action
  public getGuaranteesRequest = async (params?: GetGuaranteesQuery) => {
    const response = await this._store.InsuranceApi.getGuarantees(params);

    if (response.success && response.data) {
      this.setGuarantees(response.data, response.meta);
    }

    return response;
  };

  @action
  public getGuaranteeRequest = async (id: number) => {
    const response = await this._store.InsuranceApi.getGuarantee(id);

    if (response.success && response.data) {
      this.setGuarantee(response.data);
    }

    return response;
  };

  @action
  public createGuaranteeRequest = async (params: CreateGuaranteeParam) => {
    const response = await this._store.InsuranceApi.createGuarantee(params);

    if (response.success && response.data) {
      this.addGuarantee(response.data);
    }

    return response;
  };

  @action
  public updateGuaranteeRequest = async (
    id: number,
    params: UpdateGuaranteeParams,
  ) => {
    const response = await this._store.InsuranceApi.updateGuarantee(id, params);

    if (response.success && response.data) {
      this.updateGuarantee(response.data);
    }

    return response;
  };

  @action
  public getGuaranteeReportReq = async (query?: GetGuaranteeReport) => {
    const res = await this._store.InsuranceApi.getGuaranteeReport(query);
    if (res.success && res.data) {
      this.setGuaranteeReport(res.data);
    }
    return res;
  };

  @action
  public downloadGuaranteesReportReq = async (
    params: DownloadGuaranteeReport,
  ) => {
    return await this._store.InsuranceApi.downloadGuaranteesReport(params);
  };

  @action
  public cancelGuaranteeRequest = async (id: number) => {
    const response = await this._store.InsuranceApi.cancelGuarantee(id);

    if (response.success && response.data) {
      this.updateGuarantee(response.data);
    }

    return response;
  };

  @action
  public applyGuaranteeRequest = async (
    id: number,
    params: ApplyGuaranteeParams,
  ) => {
    const response = await this._store.InsuranceApi.applyGuarantee(id, params);
    return response;
  };

  public groupGuaranteeByPolicy(guarantees: Guarantee[]) {
    const groupedByPolicy = new Map<
      number,
      { policy: InsurancePolicy; guarantees: Guarantee[] }
    >();

    for (const guarantee of guarantees) {
      const data = groupedByPolicy.get(guarantee.insurance_policy.id);

      if (data) {
        groupedByPolicy.set(guarantee.insurance_policy.id, {
          policy: data.policy,
          guarantees: data.guarantees.concat(guarantee),
        });
      }

      if (!data) {
        groupedByPolicy.set(guarantee.insurance_policy.id, {
          policy: guarantee.insurance_policy,
          guarantees: [guarantee],
        });
      }
    }

    return Array.from(groupedByPolicy.values());
  }

  public groupGuaranteeByValues(
    guarantees: Guarantee[],
    attached: number[] = [],
  ) {
    const groupedByValues = new Map<string, Guarantee[]>();

    for (const guarantee of guarantees) {
      const isAttached = attached.includes(guarantee.id);

      const key = `${guarantee.status} ${guarantee.franchise} ${guarantee.patient_discount} ${isAttached}`;

      const data = groupedByValues.get(key);

      if (data) {
        groupedByValues.set(key, data.concat(guarantee));
      }

      if (!data) {
        groupedByValues.set(key, [guarantee]);
      }
    }

    return Array.from(groupedByValues.values());
  }

  public groupedGuaranteeByService = (guarantees: Guarantee[]) => {
    const map = new Map<
      number,
      { service: GuaranteeService; guarantees: Guarantee[] }
    >();

    for (const guarantee of guarantees) {
      const { service } = guarantee;

      const data = map.get(service.id);

      if (!data) {
        map.set(service.id, { service, guarantees: [guarantee] });
      } else {
        map.set(service.id, {
          service,
          guarantees: data.guarantees.concat(guarantee),
        });
      }
    }

    return Array.from(map.values());
  };

  public calculateExpirationDate = (date: string) => {
    const dateService = this._store.DateService;

    const today = dateService.createDate();
    const policyExpireDate = dateService.createDate(date);
    const isExpired = policyExpireDate.isBefore(today);
    if (isExpired) return policyExpireDate.toDate();
    const monthAhead = dateService.createDate().add(1, 'month');
    const isExpiredMoreThanOneMonth = policyExpireDate.isAfter(monthAhead);
    if (isExpiredMoreThanOneMonth) return monthAhead.toDate();
    return policyExpireDate.toDate();
  };
}
