import { action, makeObservable, observable } from 'mobx';

export class CashOperationStore {
  @observable public cashOrders: CashOperationOrder[] = [];
  @observable public cashOrdersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setCashOrders = (data: CashOperationOrder[], meta: ResponseMeta) => {
    this.cashOrders = data;
    this.cashOrdersMeta = meta;
  };
}
