import { generateNestedMap, getRouteBy } from '@router/utils';
import { CONFIG } from '@router/registration';
export * from './components/Routing';

export const ROUTES = <const>[
  ...CONFIG.PUBLIC,
  ...CONFIG.PRIVATE,
  ...CONFIG.STATIC,
  ...CONFIG.INIT,
  ...CONFIG.CHANGE_PASSWORD,
];

const { pathMap: privatePaths } = generateNestedMap(CONFIG.PRIVATE);
const { pathMap, groupMap } = generateNestedMap(ROUTES);

export const ROUTER_CONFIG = <const>{
  main: ROUTES.find((e) => e.path === '/'),
  routes: ROUTES,
  init: <const>{
    paths: '/init',
    routes: CONFIG.INIT,
    rootPath: '/init',
  },
  public: <const>{
    paths: CONFIG.PUBLIC.map((e) => e.path),
    routes: CONFIG.PUBLIC,
    rootPath: '/login',
  },
  changePassword: <const>{
    paths: '/change-password',
    routes: CONFIG.CHANGE_PASSWORD,
    rootPath: '/change-password',
  },
  private: <const>{
    paths: privatePaths as RoutePath[],
    routes: CONFIG.PRIVATE[0].routes as PrivateRoute[],
    rootPath: '/',
  },
  static: <const>{
    paths: CONFIG.STATIC.map((e) => e.path),
    routes: CONFIG.STATIC,
    rootPath: '/',
  },
  paths: pathMap,
  groups: groupMap,
  getRouteBy,
};
