import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFileCopy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={20}
      fill="none"
      viewBox="0 0 17 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12.333.833h-10C1.417.833.667 1.583.667 2.5v11.667h1.666V2.5h10zM11.5 4.167l5 5V17.5c0 .917-.75 1.667-1.667 1.667H5.658C4.742 19.167 4 18.417 4 17.5l.008-11.667c0-.916.742-1.666 1.659-1.666zM10.667 10h4.583l-4.583-4.583z"
      />
    </svg>
  );
};
export default SvgFileCopy;
