import React, { memo, ReactNode } from 'react';
import { Content } from '@components/Content';
import Sidebar from '@components/Sidebar';
import Header from '@components/Header';
import { PageNav } from '@components/PageNav';
import './layout.scss';
import { TopbarLeft, TopbarRight } from '@components/Header/components';

type Props = {
  children: ReactNode;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="app-layout">
      <Header>
        <>
          <TopbarLeft />
          <TopbarRight />
        </>
      </Header>
      <Sidebar />
      <Content pageNav={<PageNav />}>{children}</Content>
    </div>
  );
};

export default memo(AppLayout);
