import { reaction } from 'mobx';
import { Store } from '@stores';

export const authReactions = reaction(
  () => Store.AuthService.isAuth,
  (isAuth) => {
    if (!isAuth) {
      Store.WsService.disconnect();
      Store.PermissionsService.logout();
      Store.ProfileService.logout();

      const query = {
        backURL: JSON.stringify({
          pathname: Store.RoutingService.location.pathname,
          hash: Store.RoutingService.location.hash,
          search: Store.RoutingService.location.search,
        }),
      };

      setTimeout(() => {
        Store.RoutingService.goToRoute('/login', null, query);
      });
    }
  },
);
