import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBloodtypeOutlined = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path fill="#FFF" d="M15 16H9v2h6zM13 9h-2v2H9v2h2v2h2v-2h2v-2h-2z" />
      <path
        fill="#FFF"
        d="M12 2c-5.33 4.55-8 8.48-8 11.8 0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8m0 18c-3.35 0-6-2.57-6-6.2 0-2.34 1.95-5.44 6-9.14 4.05 3.7 6 6.79 6 9.14 0 3.63-2.65 6.2-6 6.2"
      />
    </svg>
  );
};
export default SvgBloodtypeOutlined;
