import { FetchService } from '@modules/Api';

export class ServicePropertyApi extends FetchService {
  public getServiceProperties = (query?: GetServicePropertiesQuery) => {
    return this.request<
      'SERVICE_PROPERTIES',
      ServiceProperty[],
      GetServicePropertiesQuery
    >('/service_property', 'GET_PROPERTIES', { query });
  };

  public getServiceProperty = (id: number) => {
    return this.request<'SERVICE_PROPERTIES', ServiceProperty>(
      '/service_property',
      'GET_PROPERTY',
      { pathParam: id },
    );
  };

  public createServiceProperty = (params: CreateServiceProperty) => {
    return this.request<'SERVICE_PROPERTIES', ServiceProperty>(
      '/service_property',
      'CREATE_PROPERTY',
      { method: 'POST', params },
    );
  };

  public updateServiceProperty = (
    id: number,
    params: UpdateServiceProperty,
  ) => {
    return this.request<'SERVICE_PROPERTIES', ServiceProperty>(
      '/service_property',
      'UPDATE_PROPERTY',
      { method: 'PUT', params, pathParam: id },
    );
  };

  public deleteServiceProperty = (id: number) => {
    return this.request<'SERVICE_PROPERTIES', ServiceProperty>(
      '/service_property',
      'DELETE_PROPERTY',
      { method: 'DELETE', pathParam: id },
    );
  };

  public checkFormula = (params: CheckServicePropertyFormula) => {
    return this.request<'SERVICE_PROPERTIES'>(
      '/service_property/check_formula',
      'CHECK_FORMULA',
      { method: 'PUT', params },
    );
  };
}
