import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLocationAway = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill="none"
      viewBox="0 0 17 16"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.833 15.5v-1.583q0-.438.219-.823a1.7 1.7 0 0 1 .594-.615 8.4 8.4 0 0 1 2.01-.854 8.1 8.1 0 0 1 2.177-.292q1.125 0 2.177.292a8.4 8.4 0 0 1 2.01.854q.376.23.594.615.22.385.22.823V15.5zm-6.666 0v-10l6.666-5 4.48 3.375A4 4 0 0 0 8.707 5.24 4.03 4.03 0 0 0 7.667 8q0 .646.177 1.219t.51 1.073q-.417.165-.802.343a7 7 0 0 0-.76.407 3.2 3.2 0 0 0-1.188 1.218 3.34 3.34 0 0 0-.437 1.657V15.5zm11.666-5a2.4 2.4 0 0 1-1.77-.73A2.4 2.4 0 0 1 9.332 8q0-1.042.73-1.77a2.4 2.4 0 0 1 1.77-.73 2.4 2.4 0 0 1 1.771.73q.73.728.73 1.77t-.73 1.77a2.4 2.4 0 0 1-1.77.73"
      />
    </svg>
  );
};
export default SvgLocationAway;
