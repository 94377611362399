import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLoading = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="svg-icon-loading"
      viewBox="25 25 50 50"
      fill="none"
      role="img"
      {...props}
    >
      <circle cx={50} cy={50} r={20} fill="none" />
    </svg>
  );
};
export default SvgLoading;
