import React, { isValidElement, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Tooltip } from '@components/UI/Tooltip';
import { Text } from '@components/UI/Text';

function OptionTooltipContent({ children }: { children: React.ReactNode }) {
  const isElement = isValidElement(children);

  return (
    <div className="w-[280px]" data-testid="option-tooltip-content">
      {isElement ? (
        children
      ) : (
        <Text className="text-white text-sm">{children}</Text>
      )}
    </div>
  );
}

export function OptionWithTooltip({
  label,
  children,
}: {
  children: React.ReactNode;
  label: React.ReactNode;
}) {
  const ref = useRef<HTMLParagraphElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const isElementOverflowed = (element: Element) => {
      const isOverflow = element.scrollWidth > element.clientWidth;
      if (isOverflow) return true;

      if (element.firstElementChild) {
        const isChildOverflow = isElementOverflowed(element.firstElementChild);
        if (isChildOverflow) return true;
      }

      if (element.nextElementSibling) {
        const isSiblingOverflow = isElementOverflowed(
          element.nextElementSibling,
        );
        if (isSiblingOverflow) return true;
      }

      return false;
    };

    if (ref.current) {
      const isOverflowing = isElementOverflowed(ref.current);
      setIsOverflowing(isOverflowing);
    }
  }, [label]);

  return (
    <Tooltip
      contentElement={<OptionTooltipContent>{label}</OptionTooltipContent>}
      disabled={!isOverflowing}
    >
      <div
        ref={ref}
        className={cn('truncate', isOverflowing && 'pointer-events-auto')}
        data-testid="option-tooltip-trigger"
      >
        {children}
      </div>
    </Tooltip>
  );
}
