import { action, makeObservable, observable } from 'mobx';

export class CashPlaceStore {
  @observable public orders: Array<Order | PackageOrder> = [];

  @observable public cashPlaces: CashPlace[] = [];
  @observable public cashPlacesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public cashPlace: CashPlace | null = null;
  @observable public xReport: XReport | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setCashOrders = (orders: Array<Order | PackageOrder>) => {
    this.orders = orders;
  };

  @action
  public setCashPlaces = (data: CashPlace[], meta: ResponseMeta) => {
    this.cashPlaces = data;
    this.cashPlacesMeta = meta;
  };

  @action
  public setCashPlace = (data: CashPlace | null) => {
    this.cashPlace = data;
  };

  @action
  public addCashPlace = (data: CashPlace) => {
    this.cashPlaces = [data, ...this.cashPlaces];
  };

  @action
  public deleteCashPlace = (id: number) => {
    if (this.cashPlace && this.cashPlace.id === id) {
      this.cashPlace = null;
    }

    this.cashPlaces = this.cashPlaces.filter((cp) => cp.id !== id);
  };

  @action
  public updateCashPlace = (data: CashPlace) => {
    if (this.cashPlace && this.cashPlace.id === data.id) {
      this.cashPlace = data;
    }

    this.cashPlaces = this.cashPlaces.map((cp) =>
      cp.id === data.id ? data : cp,
    );
  };

  @action
  public setXReport = (data: XReport | null) => {
    this.xReport = data;
  };

  @action
  public clear = () => {
    this.setCashPlace(null);
    this.setCashPlaces([], { totalPages: 0, totalRecords: 0 });
    this.setXReport(null);
  };
}
