import React, { createElement, Suspense } from 'react';
import { Components } from '@components/UI/Modal/registration';
import { Text } from '@components/UI/Text';
import { ContentLoader } from '@components/UI/ContentLoader';
import ErrorBoundary from '@components/UI/ErrorBoundary';

export default function ModalSlot(props: ModalProps) {
  if (props.component && Boolean(Components[props.component])) {
    return (
      <Suspense fallback={<ContentLoader />}>
        <ErrorBoundary>
          {createElement(Components[props.component] as any, props)}
        </ErrorBoundary>
      </Suspense>
    );
  }
  return (
    <Text type="danger" tag="blockquote">
      Error created. Component: {props.component}
    </Text>
  );
}
