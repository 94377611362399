import { action, makeObservable, observable } from 'mobx';

export class ReferenceValuesStore {
  @observable public referentValues: ReferenceValue[] = [];
  @observable public referentValue: ReferenceValue | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setReferentValues = (data: ReferenceValue[]) => {
    this.referentValues = data;
  };

  @action
  public setReferentValue = (data: ReferenceValue | null) => {
    this.referentValue = data;
  };

  @action
  public addReferentValue = (data: ReferenceValue) => {
    this.referentValues = this.referentValues.concat(data);
  };

  @action
  public updateReferentValue = (data: ReferenceValue) => {
    this.referentValues = this.referentValues.map((value) =>
      value.id === data.id ? data : value,
    );

    if (this.referentValue && this.referentValue.id === data.id) {
      this.setReferentValue(data);
    }
  };

  @action
  public removeReferentValue = (id: number) => {
    this.referentValues = this.referentValues.filter(
      (value) => value.id !== id,
    );
  };
}
