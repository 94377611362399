import { FetchService } from '@modules/Api';

export class RemedPatientApi extends FetchService {
  public getRemedPatient = (id: number) => {
    return this.request<'REMED_PATIENT', RemedPatient>(
      '/remed_patient',
      'GET_REMED_PATIENT',
      {
        pathParam: id,
      },
    );
  };

  public getRemedPatientByPhone = (phone: string) => {
    return this.request<'REMED_PATIENT', FindRemedPatientResult>(
      '/remed_patient',
      'GET_REMED_PATIENT_BY_PHONE',
      {
        pathParam: `phone/${phone}`,
      },
    );
  };

  public remedPatientSetup = (params: GetRemedPatientSetup) => {
    return this.request<'REMED_PATIENT', RemedPatientSetup>(
      '/remed_patient',
      'REMED_PATIENT_SETUP',
      {
        method: 'POST',
        pathParam: 'setup',
        params,
      },
    );
  };

  public remedPatientConfirm = (params: GetRemedPatientConfirm) => {
    return this.request<'REMED_PATIENT', RemedPatient>(
      '/remed_patient',
      'REMED_PATIENT_CONFIRM',
      {
        method: 'PUT',
        pathParam: 'setup',
        params,
      },
    );
  };

  public deleteRemedPatient = (userId: number) => {
    return this.request<'REMED_PATIENT'>(
      '/remed_patient',
      'DELETE_REMED_PATIENT',
      {
        method: 'DELETE',
        pathParam: userId,
      },
    );
  };
}
