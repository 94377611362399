import { action, makeObservable, observable } from 'mobx';
import { Cabinet } from '@modules/Cabinets/mapper/Cabinet';
import { Service } from '@modules/Services/mapper/Service';

export class CabinetsStore {
  @observable public cabinet: Cabinet | null = null;
  @observable public cabinets: Cabinet[] = [];
  @observable public cabinetsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public cabinetType: CabinetType | null = null;
  @observable public cabinetsType: CabinetType[] = [];
  @observable public cabinetsTypeMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  @observable public firstLoadingCabinetServices = true;
  @observable public cabinetServices: Service[] = [];
  @observable public cabinetServicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setCabinets = (
    cabinets: CabinetResponse[],
    meta: ResponseMeta,
  ): void => {
    this.cabinets = cabinets.map((e) => new Cabinet(e));
    this.cabinetsMeta = meta;
  };

  @action
  public setCabinet = (cabinet: CabinetResponse | null): void => {
    this.cabinet = cabinet ? new Cabinet(cabinet) : null;
  };

  @action
  public setCabinetServices = (
    services: ServiceResponse[],
    meta: ResponseMeta,
  ): void => {
    this.firstLoadingCabinetServices = true;
    this.cabinetServices = services.map((e) => new Service(e));
    this.cabinetServicesMeta = meta;
    this.firstLoadingCabinetServices = false;
  };

  @action
  public addServiceCabinet = (service: ServiceResponse): void => {
    this.cabinetServices = [...this.cabinetServices, new Service(service)];
  };

  @action
  public addCabinet = (cabinet: CabinetResponse): void => {
    this.cabinets = [...this.cabinets, new Cabinet(cabinet)];
  };

  @action
  public updateCabinet = (updatedCabinet: Partial<CabinetResponse>): void => {
    const cabinet = this.cabinets.find((e) => e.id === updatedCabinet.id);
    if (cabinet) {
      Object.assign(cabinet, updatedCabinet);
    }
    if (this.cabinet && this.cabinet.id === updatedCabinet.id) {
      Object.assign(this.cabinet, updatedCabinet);
    }
  };

  @action
  public deleteCabinet = (id: number): void => {
    this.cabinets = this.cabinets.filter((e) => e.id !== id);
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET TYPE
  |----------------------------------------------------------------------------
  */
  @action
  public setCabinetsType = (
    cabinets: CabinetType[],
    meta: ResponseMeta,
  ): void => {
    this.cabinetsType = cabinets;
    this.cabinetsTypeMeta = meta;
  };

  @action
  public setCabinetType = (cabinet: CabinetType): void => {
    this.cabinetType = cabinet;
  };

  @action
  public addCabinetType = (cabinet: CabinetType): void => {
    this.cabinetsType = [...this.cabinetsType, cabinet];
  };

  @action
  public updateCabinetType = (updatedCabinet: CabinetType): void => {
    const cabinet = this.cabinetsType.find((e) => e.id === updatedCabinet.id);
    if (cabinet) {
      cabinet.title = updatedCabinet.title;
    }
  };

  @action
  public deleteCabinetType = (id: number): void => {
    this.cabinetsType = this.cabinetsType.filter((e) => e.id !== id);
  };

  /*
  |----------------------------------------------------------------------------
  | CABINET SERVICE
  |----------------------------------------------------------------------------
  */
  @action
  public deleteCabinetService = (id: number): void => {
    this.cabinetServices = this.cabinetServices.filter((e) => e.id !== id);
  };
}
