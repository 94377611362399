/**
 * getRouterPaths
 * @param data
 */
export const getRouterPaths = (data: readonly AppRouter[]): string[] => {
  return data.reduce<string[]>((result, current) => {
    result.push(current.path);
    if (current.routes?.length) {
      return result.concat(getRouterPaths(current.routes));
    }
    return result;
  }, []);
};

type GenerateMap = {
  pathMap: RoutePath[];
  groupMap: RouteGroup[];
};

/**
 * generateNestedMap
 * @param routes
 */
export const generateNestedMap = (
  routes: readonly AppRouter[],
): GenerateMap => {
  const pathMap = new Set();
  const groupMap = new Set();

  for (const route of routes) {
    if (route.path) {
      pathMap.add(route.path);
    }
    if (route.group) {
      groupMap.add(route.group);
    }
    if (route.routes?.length) {
      const nestedRoute = generateNestedMap(route.routes);
      for (const path of nestedRoute.pathMap) {
        pathMap.add(path);
      }
      for (const group of nestedRoute.groupMap) {
        groupMap.add(group);
      }
    }
  }

  return <GenerateMap>{
    pathMap: Array.from(pathMap),
    groupMap: Array.from(groupMap),
  };
};
