import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBarcode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <g fill="#6B7280" clipPath="url(#a)">
        <path d="M0 0v5.333h1.333v-4h4V0zM14.667 10.667v4h-4V16H16v-5.333zM1.333 14.667v-4H0V16h5.333v-1.333zM10.667 0v1.333h4v4H16V0z" />
        <path d="M3.667 2.667h-1v10.666h1zM6.333 2.667H4.666v10.666h1.667zM8.333 2.667h-1v10.666h1zM10.333 2.667h-1v10.666h1zM13.334 2.667h-2v10.666h2z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgBarcode;
