import { makeAutoObservable } from 'mobx';
import { ServiceNode } from './ServiceNode';

const flatNodes = (
  categories: ServiceCategoryTree[],
  parentId: number | null,
) => {
  const flatCategories: ServiceNode[] = [];

  for (const tree of categories) {
    const parent = {
      id: tree.id,
      title: tree.title,
      position: tree.position,
      default_commission: tree.default_commission,
      parent: parentId,
      subRows: tree.subRows ? tree.subRows.map((s) => s.id) : [],
    };

    flatCategories.push(new ServiceNode(parent));

    if (tree.subRows) {
      flatCategories.push(...flatNodes(tree.subRows, parent.id));
    }
  }

  return flatCategories;
};

export class ServicesTree {
  private _nodesMap: Map<number, ServiceNode> = new Map();
  private _parentNodes: ServiceNode[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get nodes() {
    const calculateParents = (node: ServiceNode) => {
      let count = 0;

      this._forParentDeep(node, () => {
        count += 1;
      });

      return count;
    };

    const build = (nodes: ServiceNode[]) => {
      const flatNodes: Array<{ node: ServiceNode; offset: number }> = [];

      for (const node of nodes) {
        flatNodes.push({
          node,
          offset: calculateParents(node),
        });

        if (node.subRows.length) {
          const childNodes = node.subRows.map((row) => this._nodesMap.get(row));
          flatNodes.push(...build(childNodes as ServiceNode[]));
        }
      }

      return flatNodes;
    };

    return build(this._parentNodes);
  }

  public setCategories = (categories: ServiceCategoryTree[]) => {
    const nodes = flatNodes(categories, null);

    this._nodesMap = new Map(nodes.map((node) => [node.id, node]));
    this._parentNodes = nodes.filter((node) => !node.parent);
  };

  public updateCommission = (
    category: ServiceCategory,
    updateSubRows?: boolean,
  ) => {
    const node = this._nodesMap.get(category.id);
    if (!node) return;

    node.changeCommission(category.default_commission);

    if (updateSubRows && node.subRows.length) {
      this._forChildrenDeep(node.subRows, (node) => {
        node.changeCommission(category.default_commission);
      });
    }
  };

  public updateTitle = (category: ServiceCategory) => {
    const node = this._nodesMap.get(category.id);
    if (!node) return;

    node.changeTitle(category.title);
  };

  public getParent = (node: ServiceNode) => {
    if (node.parent) {
      return this._nodesMap.get(node.parent) ?? null;
    }

    return null;
  };

  // Iterators methods
  private _forParent = (node: ServiceNode, cb: (node: ServiceNode) => void) => {
    if (node.parent) {
      const parent = this._nodesMap.get(node.parent) as ServiceNode;
      cb(parent);
    }
  };

  private _forParentDeep = (
    node: ServiceNode,
    cb: (node: ServiceNode) => void,
  ) => {
    this._forParent(node, (node) => {
      cb(node);
      if (node.parent) this._forParentDeep(node, cb);
    });
  };

  private _forChildren = (ids: number[], cb: (node: ServiceNode) => void) => {
    return ids.map((id) => this._nodesMap.get(id) as ServiceNode).map(cb);
  };

  private _forChildrenDeep = (
    ids: number[],
    cb: (node: ServiceNode) => void,
  ) => {
    this._forChildren(ids, (node) => {
      cb(node);

      if (node.subRows) {
        this._forChildrenDeep(node.subRows, cb);
      }
    });
  };
}
