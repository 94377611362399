import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRedUser = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <path
        fill="#DC2626"
        d="M0 4a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
      />
      <path
        fill="#fff"
        d="M6.5 8.167v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V8.167c0-.917-.75-1.667-1.667-1.667H8.167c-.925 0-1.667.75-1.667 1.667m10 3.333c0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5M9 18.167c0-1.667 3.333-2.584 5-2.584s5 .917 5 2.584V19H9z"
      />
    </svg>
  );
};
export default SvgRedUser;
