import { action, computed, makeObservable, observable } from 'mobx';
import { Price } from '@modules/Prices/mapper/Price';

export class PricesStore {
  @observable public price: Price | null = null;
  @observable public prices: Price[] = [];
  @observable public pricesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public priceServices: PriceService[] = [];
  @observable public priceServicesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public selectedServiceIds: number[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get isCounterpartyPrice() {
    return !!this.price?.counterpartyId;
  }

  @computed
  public get isBasePrice() {
    return this.price?.base;
  }

  @action
  public setPrices = (prices: PriceResponse[], meta: ResponseMeta): void => {
    this.prices = prices.map((e) => new Price(e));
    this.pricesMeta = meta;
  };

  @action
  public setPrice = (price: PriceResponse | null): void => {
    this.price = price ? new Price(price) : price;
  };

  @action
  public setNewPrice = (price: PriceResponse): void => {
    this.prices = [new Price(price), ...this.prices];
  };

  @action
  public updatePrice = (
    priceId: number,
    key: keyof Omit<Price, 'id' | 'counterpartyId'>,
    data: unknown,
  ) => {
    const price = this.prices.find((e) => e.id === priceId);
    if (price) {
      price.setValue(key, data);
    }
    if (this.price?.id === priceId) {
      this.price.setValue(key, data);
    }
  };

  @action
  public deletePrice = (id: number) => {
    this.prices = this.prices.filter((e) => e.id !== id);
  };

  @action
  public setPriceServices = (services: PriceService[], meta: ResponseMeta) => {
    this.priceServices = services;
    this.priceServicesMeta = meta;
  };

  @action
  public setBaseCost = (services: PriceService[]) => {
    if (services.length) {
      services.forEach((_service) => {
        const service = this.priceServices.find((e) => e.id === _service.id);
        if (service) {
          service.base_price_cost = _service.base_price_cost;
        }
      });
    }
  };

  @action
  public setCost = (services: PriceService[]) => {
    if (services.length) {
      services.forEach((_service) => {
        const service = this.priceServices.find((e) => e.id === _service.id);
        if (service) {
          service.cost = _service.cost;
        }
      });
    }
  };

  @action
  public setSelectedServices = (serviceIds: number[]) => {
    this.selectedServiceIds = serviceIds;
  };

  @action
  public removePriceServices = ({ service_ids }: RemovePriceServices) => {
    this.priceServices = this.priceServices.filter(
      (e) => !service_ids.includes(e.id),
    );
    if (this.price) {
      this.price.servicesIds = this.price.servicesIds.filter(
        (id) => !service_ids.includes(id),
      );
    }
  };
}
