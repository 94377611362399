export const defaultEnv: IEnv = <const>{
  APP_NAME: 'NO_NAME APP',
  API_CORE_URI: 'https://api.stage.remis.tech/api/v1',
  API_SCHEDULER_URI: 'https://scheduler.stage.remis.tech/api/v1',
  WS_CORE_URI: 'wss://api.stage.remis.tech/api/v1/wss/',
  WS_SCHEDULER_URI: 'wss://scheduler.stage.remis.tech/api/v1/wss/',
  TINYMCE_KEY: '8a6d4tn44i3i68waadsxs0clj0qz63b33g09ggzs3arxmn7r',
  SENTRY_DSN:
    'https://3118292bc5754661989cb21ec54b10ac@o1263378.ingest.sentry.io/6465236',
  EU_SIGN_URL: 'https://test.id.gov.ua/sign-widget/v2022testnew/',
  EU_VERIFY_URL: 'https://test.id.gov.ua/verify-widget/v2022testnew/',
  EU_SIGN_HOST: 'https://diia.remis.tech/',
  E_HEALTH_SECURITY_REDIRECT_URI: 'https://auth-ehealth.remis.tech/',
};
