import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronNext = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={12}
      fill="none"
      viewBox="0 0 8 12"
      role="img"
      {...props}
    >
      <path fill="currentColor" d="M2 0 .59 1.41 5.17 6 .59 10.59 2 12l6-6z" />
    </svg>
  );
};
export default SvgChevronNext;
