import { FetchService } from '@modules/Api';

export class DocDirectionsApi extends FetchService {
  public getDocDirections = (query?: GetDocDirectionsReq) => {
    return this.request<
      'DIRECTIONS',
      DocDirection[],
      null,
      GetDocDirectionsReq
    >('/direction', 'GET_DIRECTIONS', { query, permit: 'direction' });
  };

  public getDocDirection = (id: number) => {
    return this.request<'DIRECTIONS', DocDirection, null, GetDocDirectionsReq>(
      '/direction',
      'GET_DIRECTION',
      { pathParam: id, permit: 'direction' },
    );
  };

  public createDocDirection = (params: CreateDocDirection) => {
    return this.request<'DIRECTIONS', DocDirection, CreateDocDirection>(
      '/direction',
      'CREATE_DIRECTION',
      { method: 'POST', params, permit: 'direction' },
    );
  };

  public updateDocDirection = (id: number, params: UpdateDocDirection) => {
    return this.request<'DIRECTIONS', DocDirection, UpdateDocDirection>(
      '/direction',
      'UPDATE_DIRECTION',
      {
        method: 'PUT',
        pathParam: id,
        params,
        permit: 'direction',
      },
    );
  };

  public deleteDocDirection = (id: number) => {
    return this.request<'DIRECTIONS'>('/direction', 'DELETE_DIRECTION', {
      method: 'DELETE',
      permit: 'direction',
      pathParam: id,
    });
  };
}
