import { FetchService } from '@modules/Api';

export class ConsumableApi extends FetchService {
  public getConsumables = (query?: GetConsumableReq) => {
    return this.request<'CONSUMABLE', Consumable[], null, GetConsumableReq>(
      '/consumable',
      'GET_CONSUMABLES',
      { query, permit: 'consumable' },
    );
  };

  public getConsumable = (id: number) => {
    return this.request<'CONSUMABLE', Consumable>(
      '/consumable',
      'GET_CONSUMABLE',
      { pathParam: id, permit: 'consumable' },
    );
  };

  public createConsumable = (params: CreateConsumableReq) => {
    return this.request<'CONSUMABLE', Consumable, CreateConsumableReq>(
      '/consumable',
      'CREATE_CONSUMABLE',
      {
        method: 'POST',
        params,
        permit: 'consumable',
      },
    );
  };

  public updateConsumable = (id: number, params: UpdateConsumableReq) => {
    return this.request<'CONSUMABLE', Consumable, UpdateConsumableReq>(
      '/consumable',
      'UPDATE_CONSUMABLE',
      {
        method: 'PUT',
        pathParam: id,
        params,
        permit: 'consumable',
      },
    );
  };

  public deleteConsumable = (id: number) => {
    return this.request<'CONSUMABLE'>('/consumable', 'DELETE_CONSUMABLE', {
      method: 'DELETE',
      pathParam: id,
      permit: 'consumable',
    });
  };

  public activateConsumable = (id: number) => {
    return this.request<'CONSUMABLE'>('/consumable', 'ACTIVATE_CONSUMABLE', {
      method: 'PUT',
      pathParam: `${id}/activate`,
      permit: 'consumable',
    });
  };

  public deactivateConsumable = (id: number) => {
    return this.request<'CONSUMABLE'>('/consumable', 'DEACTIVATE_CONSUMABLE', {
      method: 'PUT',
      pathParam: `${id}/deactivate`,
      permit: 'consumable',
    });
  };

  //

  public getConsumableTypes = (query?: GetConsumableTypeReq) => {
    return this.request<
      'CONSUMABLE',
      ConsumableType[],
      null,
      GetConsumableTypeReq
    >('/consumable_type', 'GET_CONSUMABLE_TYPES', {
      query,
      permit: 'consumable_type',
    });
  };

  public getConsumableType = (id: number) => {
    return this.request<'CONSUMABLE', ConsumableType>(
      '/consumable_type',
      'GET_CONSUMABLE_TYPE',
      { pathParam: id, permit: 'consumable_type' },
    );
  };

  public createConsumableType = (params: CreateConsumableTypeReq) => {
    return this.request<'CONSUMABLE', ConsumableType, CreateConsumableTypeReq>(
      '/consumable_type',
      'CREATE_CONSUMABLE_TYPE',
      {
        method: 'POST',
        params,
        permit: 'consumable_type',
      },
    );
  };

  public updateConsumableType = (
    id: number,
    params: UpdateConsumableTypeReq,
  ) => {
    return this.request<'CONSUMABLE', ConsumableType, UpdateConsumableTypeReq>(
      '/consumable_type',
      'UPDATE_CONSUMABLE_TYPE',
      {
        method: 'PUT',
        pathParam: id,
        params,
        permit: 'consumable_type',
      },
    );
  };

  public deleteConsumableType = (id: number) => {
    return this.request<'CONSUMABLE'>(
      '/consumable_type',
      'DELETE_CONSUMABLE_TYPE',
      {
        method: 'DELETE',
        pathParam: id,
        permit: 'consumable_type',
      },
    );
  };
}
