import React from 'react';
import { useService } from '@stores/hooks';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageSubNav } from './PageSubNav';
import './PageNav.scss';

function PageNav(): React.JSX.Element | null {
  const { t } = useTranslation('layout');
  const { location, config } = useService('RoutingService');
  const { userCanRoute } = useService('PermissionsService');
  const [root] = config.getRouteBy({ field: 'group', value: 'ROOT' });
  const [topMenu] = config.getRouteBy({
    field: 'path',
    value: location.pathname,
    routes: root.routes,
    deep: false,
    exact: false,
  });

  function isActive(route: string) {
    let active = false;
    const top = location.pathname.split('/').filter(Boolean);
    const subLev = `/${top[0]}/${top[1]}`;
    if (route === location.pathname) {
      active = true;
    } else if (subLev === route) {
      active = true;
    }
    return active;
  }

  if (!topMenu?.routes?.length) {
    return null;
  }

  return (
    <div data-testid="page-nav" className="sub-nav print:hidden">
      <ul className="top-level">
        {topMenu.routes.map((route) => {
          if (!userCanRoute(route)) {
            return null;
          }
          if (route.hidden) {
            return null;
          }
          return (
            <li
              key={route.path}
              className={cn('tab-btn', {
                active: isActive(route.path),
              })}
              data-testid={route.name}
            >
              <Link to={route.path}>{t(`menu.${route.name}` as any)}</Link>
            </li>
          );
        })}
      </ul>

      <PageSubNav routes={topMenu.routes} />
    </div>
  );
}

export default observer(PageNav);
