import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDescription = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.667 1.667H5c-.917 0-1.658.75-1.658 1.666l-.009 13.334c0 .916.742 1.666 1.659 1.666H15c.917 0 1.667-.75 1.667-1.666v-10zM13.334 15H6.667v-1.667h6.667zm0-3.333H6.667V10h6.667zm-2.5-4.167V2.917L15.417 7.5z"
      />
    </svg>
  );
};
export default SvgDescription;
