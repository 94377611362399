import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUrgent = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <path
        fill="#475569"
        d="M0 4a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
      />
      <path
        fill="#fff"
        d="M14 5.667A8.336 8.336 0 0 0 5.667 14c0 4.6 3.733 8.333 8.333 8.333S22.333 18.6 22.333 14 18.6 5.667 14 5.667m.833 12.5h-1.666V16.5h1.666zm0-3.334h-1.666v-5h1.666z"
      />
    </svg>
  );
};
export default SvgUrgent;
