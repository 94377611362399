import { observable, action, makeObservable } from 'mobx';
import { EventEmitter } from 'tsee';

export default class WebSocketStore extends EventEmitter<WsSubscriptions> {
  @observable public isConnected = false;
  protected _timeoutId?: NodeJS.Timeout;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  public setConnectionState = (value: boolean): void => {
    this.isConnected = value;
  };

  protected _setTimeoutId = (id?: any) => {
    this._timeoutId = id;
  };
}
