import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutlinearticle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path
        fill="#FFF"
        d="M19 5v14H5V5zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2"
      />
      <path fill="#FFF" d="M14 17H7v-2h7zm3-4H7v-2h10zm0-4H7V7h10z" />
    </svg>
  );
};
export default SvgOutlinearticle;
