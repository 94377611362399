import { RefObject, useEffect } from 'react';

/**
 * Hook that handles outside click event of the passed refs
 *
 * @param ref array of refs
 * @param handler? a handler function to be called when clicked outside
 */
export default function useOutsideClick(
  ref: RefObject<HTMLElement>,
  handler?: (event: Event) => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!handler) return;

      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}
