export class DoctorWorkplaceApi {
  protected _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public getAppointmentsReq = (params: GetAppointmentsReq) => {
    return new Promise<WsResponse<AppointmentType[]>>((resolve) => {
      this._store.WsService.once('appointments.get_list', resolve);
      this._store.WsApi.appointments.getAppointments(params);
    });
  };

  public getAppointmentReq = (
    id: number,
  ): Promise<WsResponse<AppointmentType>> => {
    return new Promise(async (resolve) => {
      this._store.WsService.once('appointments.get', resolve);
      this._store.WsApi.appointments.getAppointment(id);
    });
  };

  public createAppointmentReq = (
    params: CreateAppointmentReq,
    storage: number,
  ): Promise<WsResponse<AppointmentType>> => {
    return new Promise((resolve) => {
      this._store.WsService.once('appointments.create', (data) => {
        if (data.storage === storage) resolve(data);
      });
      this._store.WsApi.appointments.createAppointment(params, storage);
    });
  };

  public changeStatusAppointmentReq = (
    id: number,
    new_status: AppointmentStatus,
  ): Promise<WsResponse<AppointmentType>> => {
    return new Promise((resolve) => {
      this._store.WsService.once('appointments.change_status', resolve);
      this._store.WsApi.appointments.changeStatusAppointment({
        id,
        new_status,
      });
    });
  };

  public toggleServiceReq = (params: ToggleAppointmentServicesReq) => {
    return new Promise<WsResponse<AppointmentType>>((resolve) => {
      const storage = Date.now();
      this._store.WsService.once(
        'appointments.add_or_remove_service',
        (res) => {
          if (res.storage.id === storage) resolve(res);
        },
      );
      this._store.WsApi.appointments.toggleAppointmentServices(params, {
        id: storage,
      });
    });
  };

  public updateServiceCountReq = (params: UpdateAppointmentServiceCountReq) => {
    const { once } = this._store.WsService;

    return new Promise<WsResponse<AppointmentType>>((resolve) => {
      const storage = Date.now();
      once('appointments.update_service_count', (res) => {
        if (res.storage.id === storage) resolve(res);
      });
      this.updateAppointmentServiceCountReq(params, { id: storage });
    });
  };

  //
  public resetReferralRequest = (
    params: ResetReferringDoctorReq,
    storage: { id: number },
  ) => {
    this._store.WsApi.appointments.resetReferringDoctor(params, storage);
  };

  public updateAppointmentServiceCountReq = (
    params: UpdateAppointmentServiceCountReq,
    storage: { id: number },
  ) => {
    this._store.WsApi.appointments.updateAppointmentServiceCount(
      params,
      storage,
    );
  };

  public replaceServiceRequest = (
    params: ReplaceAppointmentServiceReq,
    storage: { id: number },
  ) => {
    this._store.WsApi.appointments.replaceAppointmentService(params, storage);
  };
}
