import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDoubleCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={12}
      fill="none"
      viewBox="0 0 20 12"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15 1.833 13.825.658 8.542 5.942l1.175 1.175zM18.534.658 9.717 9.475 6.233 6 5.058 7.175l4.659 4.658 10-10zM.341 7.175 5 11.833l1.175-1.175L1.525 6z"
      />
    </svg>
  );
};
export default SvgDoubleCheck;
