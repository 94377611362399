import { useCallback, useRef, useState } from 'react';

export function usePopoverState() {
  const MORE_BTN_WIDTH = 100;
  const resizeObserver = useRef<ResizeObserver>();
  const mutationObserver = useRef<MutationObserver>();

  const [visibleCount, setVisibleCount] = useState(0);

  const calculate = (element: HTMLElement) => {
    const firstChild = element.firstChild as Node;
    const containerWidth = element.offsetWidth;

    const tabWidth = [];
    let tabsWidth = 0;

    for (const child of firstChild.childNodes) {
      const element = child as HTMLElement;

      tabWidth.push(element.offsetWidth);
      tabsWidth += element.offsetWidth;
    }

    let width = 0;
    let visible = 0;

    for (const tab of tabWidth) {
      if (tabsWidth < containerWidth) {
        if (containerWidth >= width + tab) {
          visible += 1;
          width += tab;
        }
      }

      if (tabsWidth >= containerWidth) {
        if (containerWidth - MORE_BTN_WIDTH < width + tab) {
          break;
        }

        visible += 1;
        width += tab;
      }
    }

    setVisibleCount(visible);
  };

  const init = useCallback((element: HTMLElement) => {
    calculate(element);

    resizeObserver.current = new ResizeObserver(() => {
      calculate(element);
    });

    mutationObserver.current = new MutationObserver(() => {
      calculate(element);
    });

    resizeObserver.current.observe(element);
    mutationObserver.current.observe(element, {
      childList: true,
      subtree: true,
    });
  }, []);

  const clear = useCallback(() => {
    resizeObserver.current?.disconnect();
    mutationObserver.current?.disconnect();
  }, []);

  return {
    visibleCount,
    init,
    clear,
  };
}
