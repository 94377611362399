import React from 'react';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';
import { Text } from '@components/UI/Text';
import Timer from '@components/Header/components/TopbarRight/ActiveVisit/Timer';
import ActiveVisitDetailed from '@components/Header/components/TopbarRight/ActiveVisit/ActiveVisitDetailed';

function ActiveVisit() {
  const { goToRoute } = useService('RoutingService');
  const { patientFullName } = useService('StringService');
  const { state } = useService('DoctorWorkplace');

  function goToDesktop() {
    if (!state.activeEvent) return;
    goToRoute(
      '/workplace/desktop',
      null,
      {
        appointmentId: state.activeEvent.visit.id,
        date: state.activeEvent.visit.date,
      },
      null,
      false,
      'services',
    );
  }

  if (!state.activeEvent) return null;

  return (
    <div
      data-testid="active-visit"
      className="cursor-pointer flex items-center bg-blueGray-700 pl-2 pr-10 py-0 relative rounded-10"
    >
      <div onClick={goToDesktop} className="py-2 w-[95px]">
        <Timer />
      </div>
      <Text
        onClick={goToDesktop}
        tag="span"
        className="block text-lg ml-3 py-2 min-w-[70px]"
      >
        {patientFullName(state.activeEvent.visit.patient)}
      </Text>
      <ActiveVisitDetailed event={state.activeEvent} />
    </div>
  );
}

export default observer(ActiveVisit);
