export class ScheduleApi {
  protected _store: Store;

  constructor(store: Store) {
    this._store = store;
  }

  public getAvailableIntervals = (params?: GetAvailableIntervalsReq) => {
    this._store.WsService.send<GetAvailableIntervalsReq>(
      {
        method: 'schedule_interval.get_available',
        params,
      },
      'GET_AVAILABLE_EXECUTORS',
    );
  };

  public getScheduleStaffSubscribe = (): void => {
    this._store.WsService.send(
      {
        method: 'schedule_interval.subscribe',
      },
      'SCHEDULE_STAFF_SUBSCRIBE',
    );
  };

  public getScheduleStaffUnSubscribe = (): void => {
    this._store.WsService.send(
      {
        method: 'schedule_interval.unsubscribe',
      },
      'SCHEDULE_STAFF_UNSUBSCRIBE',
    );
  };

  public getScheduleStaff = (params?: GetScheduleStaffReq): void => {
    this._store.WsService.send<GetScheduleStaffReq>(
      {
        method: 'schedule_interval.get_list',
        params,
      },
      'GET_SCHEDULE_STAFF',
    );
  };

  public getScheduleStaffEvents = (params: GetScheduleStaffEvents): void => {
    this._store.WsService.send<GetScheduleStaffReq>(
      {
        method: 'schedule_interval.get_list_events_only',
        params,
      },
      'GET_SCHEDULE_STAFF_EVENTS',
    );
  };

  public createScheduleStaff = (
    params: CreateScheduleEventsReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<CreateScheduleEventsReq>(
      {
        method: 'schedule_interval.create',
        params,
        storage,
      },
      'CREATE_SCHEDULE_STAFF_EVENT',
    );
  };

  public createMassScheduleStaff = (
    params: CreateMassScheduleEventsReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<CreateMassScheduleEventsReq>(
      {
        method: 'schedule_interval.mass_create',
        params,
        storage,
      },
      'CREATE_MASS_SCHEDULE_STAFF_EVENT',
    );
  };

  public updateScheduleStaff = (
    params: UpdateScheduleStaffReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<UpdateScheduleStaffReq>(
      {
        method: 'schedule_interval.update',
        params,
        storage,
      },
      'UPDATE_SCHEDULE_STAFF_EVENT',
    );
  };

  public confirmScheduleStaff = (
    params: ConfirmScheduleStaffReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<ConfirmScheduleStaffReq>(
      {
        method: 'schedule_interval.confirm',
        params,
        storage,
      },
      'CONFIRM_SCHEDULE_STAFF_EVENT',
    );
  };

  public unconfirmScheduleStaff = (
    params: UnconfirmScheduleStaffReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<UnconfirmScheduleStaffReq>(
      {
        method: 'schedule_interval.unconfirm',
        params,
        storage,
      },
      'UNCONFIRM_SCHEDULE_STAFF_EVENT',
    );
  };

  public deleteScheduleStaff = (
    params: DeleteScheduleStaffReq,
    storage?: any,
  ): void => {
    this._store.WsService.send<DeleteScheduleStaffReq>(
      {
        method: 'schedule_interval.delete',
        params,
        storage,
      },
      'DELETE_SCHEDULE_STAFF_EVENT',
    );
  };
}
