import { action, makeObservable, observable } from 'mobx';

export class BiomaterialStore {
  @observable public bioMaterial: Biomaterial | null = null;
  @observable public bioMaterials: Biomaterial[] = [];
  @observable public bioMaterialsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public bioMaterialType: BiomaterialType | null = null;
  @observable public bioMaterialTypes: BiomaterialType[] = [];
  @observable public bioMaterialTypesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }
  // BIOMATERIALS

  @action
  public setBiomaterials = (data: Biomaterial[], meta: ResponseMeta) => {
    this.bioMaterials = data;
    this.bioMaterialsMeta = meta;
  };

  @action
  public setBiomaterial = (data: Biomaterial | null) => {
    this.bioMaterial = data;
  };

  @action
  public removeBiomaterial = (data: number[]) => {
    this.bioMaterials = this.bioMaterials.filter((e) => !data.includes(e.id));
  };

  // BIOMATERIAL TYPES
  @action
  public setTypes = (types: BiomaterialType[], meta: ResponseMeta) => {
    this.bioMaterialTypes = types;
    this.bioMaterialTypesMeta = meta;
  };

  @action
  public setType = (type: BiomaterialType) => {
    this.bioMaterialType = type;
  };

  @action
  public addType = (type: BiomaterialType) => {
    this.bioMaterialTypes = [type, ...this.bioMaterialTypes];
  };

  @action
  public removeType = (id: number) => {
    this.bioMaterialTypes = this.bioMaterialTypes.filter((t) => t.id !== id);
  };

  @action
  public updateType = (type: BiomaterialType) => {
    this.bioMaterialTypes = this.bioMaterialTypes.map((t) =>
      t.id === type.id ? Object.assign(t, type) : t,
    );
  };
}
