import { convertDateEvents } from '@modules/Calendar/utils';
import { makeAutoObservable } from 'mobx';

export class CalendarStore {
  public events: CalendarMappedEvent[] = [];
  public availableEvents: CalendarMappedAvailableEvent[] = [];
  public executors: CalendarExecutor[] = [];

  constructor(protected _store: Store) {
    makeAutoObservable(this);
  }

  public get minDate() {
    const { unixToDate } = this._store.DateService;

    const MIN = 0;
    const now = new Date();

    const hours = this.executors
      .map(({ work }) => work.map((w) => unixToDate(w.start_time).getHours()))
      .flat(1);

    const min = Math.min(...hours);
    const minHours = Number.isFinite(min) ? min : MIN;
    const minDate = new Date(new Date().setHours(minHours, 0, 0));

    if (now >= minDate) return minDate;
    return new Date(now.setMinutes(0));
  }

  public getEvents = (data: CalendarTableResponse): void => {
    const events = convertDateEvents(data.events);
    const availableEvents = convertDateEvents(data.available_events);

    this.events = events;
    this.availableEvents = availableEvents;
    this.executors = data.executors;
  };

  public createEvent = (event: CalendarMappedEvent) => {
    this.events = this.events.concat(event);
  };

  public updateEvent = (event: CalendarMappedEvent) => {
    this.events = this.events.map((e) => {
      if (event.id === e.id) return event;
      return e;
    });
  };

  public deleteEvent = (id: number) => {
    this.events = this.events.filter((event) => event.id !== id);
  };

  public changeStatus = (appointment: CalendarMappedEvent) => {
    if (appointment.status === 'canceled') {
      this.deleteEvent(appointment.id);
      return;
    }

    this.updateEvent(appointment);
  };

  public updateInterval = (
    userId: number,
    dates: string[],
    events: AvailableExecutorEvent[],
  ) => {
    const intervals = convertDateEvents(events)
      .flat()
      .map((e) => ({ ...e, resourceId: e.user_id }));

    const filtered = this.availableEvents.filter((event) => {
      if (userId !== event.user_id) return true;
      return !dates.includes(event.date);
    });

    this.availableEvents = [...filtered, ...intervals];
  };

  public clear = () => {
    this.events = [];
    this.availableEvents = [];
    this.executors = [];
  };
}
