import { action, makeObservable, observable, toJS } from 'mobx';
import { cloneDeep, isEqual, orderBy, pick, sortBy } from 'lodash';
import { CElement } from './CElement';
import { DocTemplateApi } from '@modules/Api/methods';
import { CREATE_DOC_SEPARATOR } from '@modules/Documents/DocChapter/consts';

type HelperProps = {
  _origin: Readonly<DocChapter>;
  _api: Readonly<DocTemplateApi['getDocElement']>;
  elements: CElement[];
  isOpenPreview: boolean;
  isEdit: boolean;
  isDone: boolean;
  position?: number;
  hasChangedContent: boolean;
};

type Values = ValueOf<DocChapter & HelperProps>;
type ChapterType = keyof DocChapterItem;

export class DocChapterItem implements DocChapter, HelperProps {
  public readonly id: Readonly<number>;
  public readonly _api: DocTemplateApi['getDocElement'];
  public readonly _origin;
  @observable public title;
  @observable public content;
  @observable public isEmail;
  @observable public isArchived;
  @observable public printTitle;
  @observable public type;
  @observable public group;
  @observable public elems;
  @observable public delete_marked;
  @observable public elements: CElement[] = [];
  @observable public isOpenPreview;
  @observable public isDone = false;
  @observable public isEdit = false;
  @observable public position?: number;
  @observable public hasChangedContent = false;
  @observable public isFirstEl = false;
  @observable public isLastEl = false;

  constructor(
    chapter: DocChapter,
    api: DocTemplateApi['getDocElement'],
    meta?: { isDone: boolean; position?: number },
  ) {
    makeObservable(this);
    this._api = api;
    this.id = chapter.id;
    this.title = chapter.title;
    this.content = chapter.content;
    this.isArchived = chapter.isArchived;
    this.isEmail = chapter.isEmail;
    this.printTitle = chapter.printTitle;
    this.type = chapter.type;
    this.group = chapter.group;
    this.elems = orderBy(chapter.elems, 'position');
    this.delete_marked = chapter.delete_marked;
    // helper
    this.position = meta?.position;
    this.isOpenPreview = false;
    this.isDone = meta?.isDone || false;
    this._setElements(chapter.elems);
    this._origin = cloneDeep({
      ...chapter,
      elements: toJS(this.elements),
      isDone: meta?.isDone || false,
    });
  }

  public get isDirty() {
    const onlyProperty = [
      'content',
      'title',
      'elements',
      'isEmail',
      'isDone',
      'isArchived',
      'printTitle',
      'type',
      'group',
    ];

    const filtered = pick(toJS(this), onlyProperty);
    const origin = pick(toJS(this._origin), onlyProperty);
    //  console.log(filtered, origin, !isEqual(origin, filtered));
    return !isEqual(origin, filtered);
  }

  @action
  public update = (keys: ChapterType[] | ChapterType, value: Values) => {
    if (!Array.isArray(keys)) keys = [keys];
    keys.forEach((key) => {
      if (key === 'id') return;
      if (key === 'isDirty') return;
      if (key === '_api') return;
      if (key === '_origin') return;
      if (key === 'elems') {
        this._setElements(value as DocChapterElement[]);
      }
      if (key === 'content') {
        this.hasChangedContent = true;
      }
      this[key] = value as never;
    });
  };

  @action
  public addElement = (
    element: DocElement,
    position = this.elements.length + 1,
  ) => {
    const _new = new CElement({ ...element, isDone: this.isDone }, this._api, {
      id: element.id,
      position,
    });
    this.elements = sortBy([...this.elements, _new], ['position']);
  };

  public addSeparator = () => {
    this.addElement(CREATE_DOC_SEPARATOR);
  };

  @action
  public updateElement = (element: DocElement, force = false) => {
    const items = this.elements.filter((e) => e.id === element.id);
    if (items.length && !force) {
      items.forEach((item) => {
        const updated = new CElement(
          { ...element, isDone: this.isDone },
          this._api,
          { id: item.id, position: item.position || 0 },
        );
        Object.assign(item, updated);
      });
    }
    if (items.length && force) {
      items.forEach((item) => {
        const index = this.elements.indexOf(item);
        if (index === -1) return;
        this.removeElement(index, false);
        this.addElement(element, item.position);
      });
    }
  };

  @action
  public removeElement = (index: number, positionCalc = true) => {
    this.elements.splice(index, 1);
    if (positionCalc) {
      this.elements.forEach((e, index) => (e.position = index));
    }
  };

  private _setElements = (elems?: DocChapterElement[]) => {
    const elements = elems?.map(({ element, id, position }) => {
      return new CElement({ ...element, isDone: this.isDone }, this._api, {
        id,
        position,
      });
    });
    this.elements = sortBy(elements, ['position']);
  };
}
