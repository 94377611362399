import { Store } from '@stores';

class PermissionInterceptor {
  public run = (method: HttpMethods, permit: PermissionEntity) => {
    if (Store.PermissionsService.isAdmin) return true;

    const crudMethods = <const>{
      GET: 'r',
      POST: 'c',
      PATCH: 'u',
      PUT: 'u',
      DELETE: 'd',
    };

    const entity = Store.PermissionsService.crudPermissions?.find(
      ({ entity }) => permit === entity.sys_name,
    );

    if (entity) {
      const crud = crudMethods[method];
      return entity[crud];
    }

    return false;
  };
}

export default new PermissionInterceptor();
