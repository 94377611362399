import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLaboratory = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={19}
      fill="none"
      viewBox="0 0 14 19"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2 17c-1.1 0-2 .9-2 2h14c0-1.1-.9-2-2-2H8v-2h3c1.1 0 2-.9 2-2H5c-1.66 0-3-1.34-3-3a3 3 0 0 1 1.47-2.57c.41.59 1.06 1 1.83 1.06.7.06 1.36-.19 1.85-.62l.59 1.61.94-.34.34.94 1.88-.68-.34-.94.94-.34L8.76.6l-.94.34L7.48 0 5.6.68l.34.94-.94.35.56 1.55c-1.17-.04-2.19.75-2.48 1.86A5.01 5.01 0 0 0 0 10c0 2.76 2.24 5 5 5v2zM7.86 2.52l1.71 4.7-.94.34-1.71-4.7zM5.5 5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1"
      />
    </svg>
  );
};
export default SvgLaboratory;
