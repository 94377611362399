import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';

export default class PositionStore {
  @observable public positions: Position[] = [];
  @observable public positionsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setPositions = (positions: Position[], meta: ResponseMeta): void => {
    this.positions = sortBy(positions, ['title'], ['asc']) as Position[];
    this.positionsMeta = meta;
  };

  @action
  public addPosition = (position: Position): void => {
    this.positions = [...this.positions, position];
  };

  @action
  public updatePosition = (updatedPosition: Position): void => {
    const position = this.positions.find((e) => e.id === updatedPosition.id);
    if (position) {
      position.title = updatedPosition.title;
    }
  };

  @action
  public deletePosition = (positionId: number): void => {
    this.positions = this.positions.filter((e) => e.id !== positionId);
  };
}
