import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDragHandle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={6}
      fill="none"
      viewBox="0 0 14 6"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.667.5H.333v1.667h13.334zM.333 5.5h13.334V3.833H.333z"
      />
    </svg>
  );
};
export default SvgDragHandle;
