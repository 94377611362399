import { CompletedAppointmentStore } from '@modules/Administrator/CompletedAppointment/appointment.store';
import { CompletedAppointmentBridge } from '@modules/Administrator/CompletedAppointment/appointment.bridge';
import uniqueId from 'lodash/uniqueId';

export class CompletedAppointmentService {
  public store: CompletedAppointmentStore;

  public readonly storeId = uniqueId('completed-');
  private readonly _bridge: CompletedAppointmentBridge;

  constructor(protected _store: Store) {
    this.store = new CompletedAppointmentStore();
    this._bridge = new CompletedAppointmentBridge(this.store, this.storeId);
  }

  public subscribe = () => {
    this._store.WsService.on(
      'appointments.get_list',
      this._bridge.getAppointments,
    );

    this._store.WsService.on(
      'appointments.change_status',
      this._bridge.changeStatus,
    );

    this._store.WsService.on(
      'appointments.update',
      this._bridge.updateAppointment,
    );

    this._store.WsService.on('appointments.get', this._bridge.getAppointment);
    this._store.WsService.on('appointments.unsubscribe', this.unsubscribe);
  };

  public unsubscribe = (data: WsResponse<StatusOnly>) => {
    if (!data.storage || data.storage !== this.storeId) return;

    this._store.WsService.off(
      'appointments.get_list',
      this._bridge.getAppointments,
    );

    this._store.WsService.off(
      'appointments.change_status',
      this._bridge.changeStatus,
    );

    this._store.WsService.off(
      'appointments.update',
      this._bridge.updateAppointment,
    );

    this._store.WsService.off('appointments.get', this._bridge.getAppointment);
    this._store.WsService.off('appointments.unsubscribe', this.unsubscribe);
  };

  public getAppointmentsReq = (patientId?: number) => {
    const startDate = this._store.DateService.createDate(new Date(), {
      hour: 0,
      minute: 0,
      second: 0,
    }).unix();

    const endDate = this._store.DateService.createDate(new Date(), {
      hour: 23,
      minute: 59,
      second: 59,
    }).unix();

    const params: GetAppointmentsReq = {
      statuses: ['on_payment', 'done', 'waiting_results'],
      start_time: startDate,
      end_time: endDate,
    };

    if (patientId) {
      params.patient_ids = [patientId];
    }

    this._store.WsApi.appointments.getAppointments(params, {
      id: this.storeId,
    });
  };

  public getAppointmentReq = async (id: number) => {
    return new Promise<WsResponse<AppointmentType>>((resolve) => {
      this._store.WsService.on('appointments.get', resolve);
      this._store.WsApi.appointments.getAppointment(id);
    });
  };

  public updateAppointmentsReq = (id: number, params: UpdateAppointmentReq) => {
    this._store.WsApi.appointments.updateAppointment(params, {
      id,
    });
  };

  public changeStatusReq = (
    params: ChangeStatusAppointmentReq,
    id?: number,
  ) => {
    this._store.WsApi.appointments.changeStatusAppointment(params, id);
  };

  public unsubscribeReq = () => {
    this._store.WsApi.appointments.unsubscribeAppointments(this.storeId);
  };

  public getPatientReq = async (id: number) => {
    const patient = await this._store.PatientsApi.getPatient(id);
    this.store.setPatient(patient.data ?? null);
  };

  public getDocumentsReq = async (appointmentId: number) => {
    // TODO: should be fixed
    const visit = await this._store.VisitApi.getVisits({
      'filter[appointment_id]': appointmentId,
    });

    if (!visit.success) {
      this._store.ModalService.modal.error(visit.errors);
    }

    const [result] = visit.data as Visit[];

    const documents = await this._store.DocumentsApi.getDocuments({
      'filter[visit_id]': result.id,
    });

    if (!documents.success) {
      this._store.ModalService.modal.error(documents.errors);
    }

    if (documents.data) this.store.setDocuments(documents.data);
  };

  public getBiomaterialsReq = async (appointmentId: number) => {
    const biomaterials = await this._store.BiomaterialApi.getBiomaterials({
      'filter[appointment_id]': appointmentId,
    });

    if (biomaterials.data) this.store.setBiomaterials(biomaterials.data);

    return biomaterials;
  };
}
