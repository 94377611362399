import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSaveIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.167 2.5h-10c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667v-10zM10 15.833a2.497 2.497 0 0 1-2.5-2.5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5-1.117 2.5-2.5 2.5M12.5 7.5H4.167V4.167H12.5z"
      />
    </svg>
  );
};
export default SvgSaveIcon;
