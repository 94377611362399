import PhoneCodesStore from '@modules/PhoneCodes/phone-codes.store';

export class PhoneCodesService extends PhoneCodesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getPhoneCodesReq = async (query?: GetPhoneCodesQuery) => {
    const res = await this._store.PhoneCodesApi.getPhoneCodes(query);
    if (res.success && res.data) {
      this.setCodes(res.data, res.meta);
    }
    return res;
  };
}
