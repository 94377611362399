import { ConsumableStore } from '@modules/Consumable/consumable.store';

export class ConsumableService extends ConsumableStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getConsumablesRequest = async (query?: GetConsumableReq) => {
    const response = await this._store.ConsumableApi.getConsumables(query);
    if (response.success && response.data) {
      this.setConsumables(response.data, response.meta);
    }
    return response;
  };

  public getConsumableRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.getConsumable(id);
    if (response.success && response.data) {
      this.setConsumable(response.data);
    }
    return response;
  };

  public createConsumableRequest = async (params: CreateConsumableReq) => {
    const response = await this._store.ConsumableApi.createConsumable(params);
    if (response.success && response.data) {
      this.addConsumable(response.data);
    }
    return response;
  };

  public updateConsumableRequest = async (
    id: number,
    params: UpdateConsumableReq,
  ) => {
    const response = await this._store.ConsumableApi.updateConsumable(
      id,
      params,
    );
    if (response.success && response.data) {
      this.updateConsumable(response.data);
    }
    return response;
  };

  public deleteConsumableRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.deleteConsumable(id);
    if (response.success) {
      this.deleteConsumable(id);
    }
    return response;
  };

  public activateConsumableRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.activateConsumable(id);
    if (response.success && response.data) {
      this.updateConsumable(response.data);
    }
    return response;
  };

  public deactivateConsumableRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.deactivateConsumable(id);
    if (response.success && response.data) {
      this.updateConsumable(response.data);
    }
    return response;
  };

  //

  public getConsumableTypesRequest = async (query?: GetConsumableTypeReq) => {
    const response = await this._store.ConsumableApi.getConsumableTypes(query);
    if (response.success && response.data) {
      this.setConsumableTypes(response.data, response.meta);
    }
    return response;
  };

  public getConsumableTypeRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.getConsumableType(id);
    if (response.success && response.data) {
      this.setConsumableType(response.data);
    }
    return response;
  };

  public createConsumableTypeRequest = async (
    params: CreateConsumableTypeReq,
  ) => {
    const response =
      await this._store.ConsumableApi.createConsumableType(params);
    if (response.success && response.data) {
      this.addConsumableType(response.data);
    }
    return response;
  };

  public updateConsumableTypeRequest = async (
    id: number,
    params: UpdateConsumableTypeReq,
  ) => {
    const response = await this._store.ConsumableApi.updateConsumableType(
      id,
      params,
    );
    if (response.success && response.data) {
      this.updateConsumableType(response.data);
    }
    return response;
  };

  public deleteConsumableTypeRequest = async (id: number) => {
    const response = await this._store.ConsumableApi.deleteConsumableType(id);
    if (response.success) {
      this.deleteConsumableType(id);
    }
    return response;
  };
}
