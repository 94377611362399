import React from 'react';
import { Button, ButtonProps } from '@components/UI/Button';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type DrawerButtonsProps = {
  buttons: ButtonProps[];
  callback?: (props: ButtonProps) => void;
};

function DrawerButtons({
  buttons,
  callback,
}: DrawerButtonsProps): React.JSX.Element | null {
  const { t } = useTranslation('drawers');
  if (!buttons.length) {
    return null;
  }
  return (
    <>
      {buttons.map((button, index) => (
        <Button
          key={index}
          {...button}
          onClick={(e) => {
            button.onClick?.(e);
            if (callback) {
              callback(button);
            }
          }}
        >
          {t(button.children as any)}
        </Button>
      ))}
    </>
  );
}

export default observer(DrawerButtons);
