import { CertificatesStore } from './certificates.store';

export class CertificatesService extends CertificatesStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getCertificatesReq = async (params?: GetServicesQuery) => {
    const res = await this._store.ServicesApi.getServices({
      ...params,
      'filter[type]': 'product',
    });
    if (res.success && res.data) {
      this.setCertificates(res.data, res.meta);
    }
    return res;
  };

  public createCertificateReq = async (params: CreateCertificate) => {
    const res = await this._store.ServicesApi.createCertificate(params);
    if (res.success && res.data) {
      this.addCertificate(res.data);
    }
    return res;
  };

  public updateCertificateReq = async (id: number, params: EditCertificate) => {
    const res = await this._store.ServicesApi.updateService(id, params);
    if (res.success && res.data) {
      const newCertificates = this.certificates.map((certificate) =>
        certificate.id === id ? res.data : certificate,
      );
      this.setCertificates(newCertificates as ServiceResponse[]);
    }
    return res;
  };

  public activateCertificateReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.activateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      res.data.forEach((service) => this.setStatus(service));
    }
    return res;
  };

  public deactivateCertificateReq = async (ids: number[]) => {
    const res = await this._store.ServicesApi.deactivateService({
      services_ids: ids,
    });
    if (res.success && res.data) {
      res.data.forEach((service) => this.setStatus(service));
    }
    return res;
  };

  public deleteCertificateReq = async (id: number) => {
    const res = await this._store.ServicesApi.deleteService(id);
    if (res.success) {
      this.deleteCertificate(id);
    }
    return res;
  };
}
