import { action, makeObservable, observable, runInAction } from 'mobx';
import dayjs from 'dayjs';

export class EventTimer {
  private _timer?: NodeJS.Timeout;
  @observable public value = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  public start = (date: number) => {
    this.stop();

    const startDate = dayjs.unix(date);
    this.value = dayjs().diff(startDate, 'seconds');

    this._timer = setInterval(() => {
      runInAction(() => this.value++);
    }, 1000);
  };

  @action
  public stop = () => {
    this.value = 0;
    clearInterval(this._timer);
  };
}
