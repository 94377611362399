import { action, computed, makeObservable, observable } from 'mobx';

export class Guarantees {
  @observable public list: Guarantee[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (guarantees: Guarantee[]) => {
    this.list = guarantees;
  };

  @action
  public add = (data: Guarantee | Guarantee[]) => {
    this.list = this.list.concat(data);
  };

  @action
  public update = (guarantee: Guarantee) => {
    this.list = this.list.map((it) => {
      if (it.id === guarantee.id) return guarantee;
      return it;
    });
  };

  @computed
  public get confirmed() {
    const guarantees = new Map<string, Array<Guarantee>>();
    for (const guarantee of this.list) {
      if (guarantee.appointment_service) {
        const saved = guarantees.get(guarantee.appointment_service.code);
        guarantees.set(
          guarantee.appointment_service.code,
          saved ? saved.concat(guarantee) : [guarantee],
        );
      }
    }
    return guarantees;
  }

  @computed
  public get available() {
    const guarantees = new Map<string, Array<Guarantee>>();
    for (const guarantee of this.list) {
      if (!guarantee.appointment_service_id) {
        const saved = guarantees.get(guarantee.service.code);
        guarantees.set(
          guarantee.service.code,
          saved ? saved.concat(guarantee) : [guarantee],
        );
      }
    }
    return guarantees;
  }
}
