import { FetchService } from '@modules/Api';

export class RoleApi extends FetchService {
  public getRoles = (query?: GetRolesQuery) => {
    return this.request<'ROLE', RoleType[]>('/role', 'GET_ROLES', {
      permit: 'roles',
      query,
    });
  };

  public getRole = (roleId: number) => {
    return this.request<'ROLE', RoleType>('/role', 'GET_ROLE', {
      pathParam: roleId,
      permit: 'roles',
    });
  };

  public getRolePermissions = (roleId: number) => {
    return this.request<'ROLE', RolePermissions>(
      '/role',
      'GET_ROLE_PERMISSIONS',
      {
        method: 'GET',
        pathParam: `${roleId}/permission`,
        // PERMIT ??
      },
    );
  };

  public getRoleUiModules = (roleId: number) => {
    return this.request<'ROLE', RoleUiModules>('/role', 'GET_ROLE_UI_MODULES', {
      method: 'GET',
      pathParam: `${roleId}/uimoduleright`,
      // PERMIT ???
    });
  };

  public createRole = (name: CreateRoleReq) => {
    return this.request<'ROLE', RoleType, CreateRoleReq>(
      '/role',
      'CREATE_ROLE',
      {
        method: 'POST',
        params: name,
        permit: 'roles',
      },
    );
  };

  public deleteRole = (roleId: number) => {
    return this.request<'ROLE'>('/role', 'DELETE_ROLE', {
      method: 'DELETE',
      pathParam: roleId,
      permit: 'roles',
    });
  };

  public updateRole = (id: number, params: UpdateRoleReq) => {
    return this.request<'ROLE', RoleType, UpdateRoleReq>(
      '/role',
      'UPDATE_ROLE',
      {
        method: 'PUT',
        params,
        pathParam: id,
        permit: 'roles',
      },
    );
  };

  public addRoleUiModule = (params: AddUiModuleToRoleReq) => {
    return this.request<'ROLE', SetRoleUiModuleResponse>(
      '/ui_role_right',
      'ADD_UI_MODULE_TO_ROLE',
      {
        method: 'POST',
        params,
        permit: 'ui_role_right',
      },
    );
  };

  public removeRoleUiModule = (recordId: number) => {
    return this.request<'ROLE'>('/ui_role_right', 'REMOVE_UI_FROM_ROLE', {
      method: 'DELETE',
      pathParam: recordId,
      permit: 'ui_role_right',
    });
  };
}
