import { FetchService } from '@modules/Api';
import { EnvService } from '@modules/Env';

export class ScheduleApi extends FetchService {
  protected readonly _url = EnvService.env.api.scheduler;

  public getScheduleIntervalReport = (
    params: GetScheduleIntervalReportType,
  ) => {
    return this.request<'SCHEDULE', BlobResponse>(
      '/schedule_interval_report',
      'GET_SCHEDULE_INTERVAL_REPORT',
      {
        method: 'POST',
        params,
        blob: true,
      },
    );
  };
}
