import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLaptop = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={14}
      fill="none"
      viewBox="0 0 20 14"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.667 12c.916 0 1.666-.75 1.666-1.667V2c0-.917-.75-1.667-1.666-1.667H3.333c-.916 0-1.666.75-1.666 1.667v8.333c0 .917.75 1.667 1.666 1.667H0v1.667h20V12zM3.333 2h13.334v8.333H3.333z"
      />
    </svg>
  );
};
export default SvgLaptop;
