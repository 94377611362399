import { action, makeObservable, observable } from 'mobx';
import sortBy from 'lodash/sortBy';

export default class UiModulesStore {
  @observable public uiModules: UiModule[] = [];
  @observable public uiModulesTree: UiModule[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setUiModules = (uiModules: UiModule[]): void => {
    this.uiModules = sortBy(uiModules, ['name'], ['asc']) as UiModule[];
  };

  @action
  public setUiModulesTree = (uiModulesTree: UiModule[]): void => {
    this.uiModulesTree = sortBy(uiModulesTree, ['name'], ['asc']) as UiModule[];
  };

  @action
  public removeUiModule = (uiModuleId: number): void => {
    this.uiModules = this.uiModules.filter((e) => e.id !== uiModuleId);
  };
}
