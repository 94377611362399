import { FetchService } from '@modules/Api';
import { EnvService } from '@modules/Env';

class AppointmentSchedulerApi extends FetchService {
  protected readonly _url = EnvService.env.api.scheduler;

  public getAppointmentReport = (params: GetAppointmentReportQuery) => {
    return this.request<'APPOINTMENT', BlobResponse>(
      '/appointment_report',
      'GET_APPOINTMENT_REPORT',
      {
        method: 'POST',
        params,
        blob: true,
      },
    );
  };
}

class AppointmentCoreApi extends FetchService {
  protected readonly _url = EnvService.env.api.url;

  public getAppointmentReportDownload = (
    id: number,
    query: GetAppointmentReportDownloadQuery,
  ) => {
    return this.request<'APPOINTMENT', BlobResponse>(
      '/appointment_report',
      'GET_APPOINTMENT_REPORT_DOWNLOAD',
      {
        pathParam: `${id}/download`,
        query,
        blob: true,
      },
    );
  };

  public getAppointmentCombRepDownload = (
    id: number,
    query: GetAppointmentCombinedReportDownloadQuery,
  ) => {
    return this.request<'APPOINTMENT', BlobResponse>(
      '/appointment_report',
      'GET_APPOINTMENT_COMBINED_REPORT_DOWNLOAD',
      {
        pathParam: `${id}/daily/download`,
        query,
        blob: true,
      },
    );
  };
}

export class AppointmentApi {
  public schedulerApi: AppointmentSchedulerApi;
  public coreApi: AppointmentCoreApi;

  constructor(store: Store) {
    this.schedulerApi = new AppointmentSchedulerApi(store);
    this.coreApi = new AppointmentCoreApi(store);
  }
}
