import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPicDoctors = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={248}
      height={248}
      fill="none"
      role="img"
      {...props}
    >
      <path
        fill="#F59E0B"
        d="M81.934 37.572S44.352 56.722 25.61 86.23c-18.744 29.507-17.261 67.456 5.515 95.618 22.776 28.163 66.31 24.488 102.761 42.344s76.667 14.176 93.903-23.639-8.754-51.966-13.218-95.718S170.66-2.976 81.935 37.572Z"
      />
      <path
        fill="#fff"
        d="M81.934 37.572S44.352 56.722 25.61 86.23c-18.744 29.507-17.261 67.456 5.515 95.618 22.776 28.163 66.31 24.488 102.761 42.344s76.667 14.176 93.903-23.639-8.754-51.966-13.218-95.718S170.66-2.976 81.935 37.572Z"
        opacity={0.7}
      />
      <path
        fill="#1E293B"
        d="M231.731 135.656h-2.058v-.352a3.63 3.63 0 0 0-3.626-3.626h-1.394a3.634 3.634 0 0 0-3.631 3.626v.352h-2.053a2.08 2.08 0 0 0-1.478.61 2.1 2.1 0 0 0-.453.678 2.1 2.1 0 0 0-.157.8v9.513a2.09 2.09 0 0 0 2.088 2.089h12.762a2.096 2.096 0 0 0 2.088-2.089v-9.513a2.087 2.087 0 0 0-2.088-2.088m-9.717-.352a2.64 2.64 0 0 1 2.639-2.634h1.394a2.637 2.637 0 0 1 2.634 2.634v.352h-6.667z"
      />
      <path
        fill="#fff"
        d="M229.153 141.529h-2.629v-2.624h-2.351v2.624h-2.629v2.356h2.629v2.623h2.351v-2.623h2.629z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M227.665 78.963a3.11 3.11 0 0 1-2.875-1.923 3.1 3.1 0 0 1-.235-1.192v-8.392a3.1 3.1 0 0 1 .909-2.202 3.12 3.12 0 0 1 2.201-.913 3.113 3.113 0 0 1 3.11 3.115v8.377a3.12 3.12 0 0 1-.904 2.212 3.1 3.1 0 0 1-2.206.918"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M227.665 78.963a3.11 3.11 0 0 1-2.875-1.923 3.1 3.1 0 0 1-.235-1.192v-4.19h6.22v4.19a3.12 3.12 0 0 1-.91 2.203 3.1 3.1 0 0 1-2.2.912"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M212.02 66.603a3.1 3.1 0 0 1-.661-2.29 3.11 3.11 0 0 1 1.157-2.085l6.532-5.248a3.12 3.12 0 0 1 4.38.497 3.114 3.114 0 0 1-.496 4.374l-6.537 5.243a3.11 3.11 0 0 1-3.447.293 3.1 3.1 0 0 1-.928-.784"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M212.02 66.603a3.1 3.1 0 0 1-.661-2.29 3.11 3.11 0 0 1 1.157-2.085l3.269-2.624 3.893 4.856-3.268 2.619a3.11 3.11 0 0 1-4.39-.476"
      />
      <path
        fill="#1E293B"
        d="M180.916 43.767a1.86 1.86 0 0 0-1.081.347l-7.227-5.61a4.04 4.04 0 0 0-.228-4.215l4.415-5.645a1.873 1.873 0 0 0 2.219-2.811 1.874 1.874 0 0 0-3.42 1.065c-.002.386.118.763.342 1.076l-4.295 5.501a4.054 4.054 0 0 0-5.934.782 4.05 4.05 0 0 0-.722 2.095h-5.02a1.87 1.87 0 0 0-1.81-1.645 1.874 1.874 0 1 0 1.617 2.731h5.307a4.056 4.056 0 0 0 6.904 1.944l7.187 5.59a1.875 1.875 0 0 0 .594 2.136 1.871 1.871 0 0 0 2.939-2.028 1.87 1.87 0 0 0-1.787-1.313"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.115 79.023a2.27 2.27 0 0 1-2.266-2.267V57.908h4.533v18.848a2.267 2.267 0 0 1-2.267 2.267"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.115 79.023a2.27 2.27 0 0 1-2.266-2.267V65.165h4.533v11.591a2.267 2.267 0 0 1-2.267 2.267"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M37.032 56.807h-5.828v1.652h5.828zm-9.787 22.216a2.27 2.27 0 0 1-2.267-2.267V57.908h4.534v18.848a2.267 2.267 0 0 1-2.267 2.267"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M27.245 79.023a2.27 2.27 0 0 1-2.267-2.267V65.165h4.534v11.591a2.267 2.267 0 0 1-2.267 2.267"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M30.162 56.807h-5.828v1.652h5.828z"
      />
      <path
        fill="#1E293B"
        d="m92.643 38.227 1.806-4.335a.38.38 0 0 1 .362-.229.37.37 0 0 1 .337.263l2.187 7.212 1.667-5.312a.37.37 0 0 1 .347-.263.4.4 0 0 1 .357.248l.928 2.669h5.014a6.06 6.06 0 0 0 1.677-3.968 5.62 5.62 0 0 0-11.1-1.28 5.62 5.62 0 0 0-11.091 1.285 6.13 6.13 0 0 0 1.463 3.734z"
      />
      <path
        fill="#1E293B"
        d="M100.371 39.224a.38.38 0 0 1-.352-.248l-.645-1.855-1.691 5.391a.38.38 0 0 1-.357.263.37.37 0 0 1-.353-.268l-2.236-7.375-1.488 3.61a.37.37 0 0 1-.343.229h-5.709c1.225 1.369 2.753 2.579 3.894 3.705 1.726 1.706 3.417 3.472 5.178 5.118 2.257-2.226 4.529-4.463 6.766-6.69.62-.62 1.294-1.236 1.919-1.88z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m16.7 150.086 2.432.844 2.631-7.587-2.432-.843zm-1.153 1.205 3.754 1.302.496-1.429-3.754-1.302z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m16.65 144.452 6.012 2.085 4.768-13.744-6.013-2.085z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m19.573 138.572 4.438 1.54 2.631-7.587-4.437-1.539z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m18.788 144.072 2.432.844 2.631-7.587-2.432-.844z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m16.225 144.308 6.87 2.383.596-1.72-6.87-2.383zm6.658-13.887 3.58 1.242.795-2.291-3.58-1.242z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m20.842 130.992 6.87 2.383.596-1.72-6.87-2.383z"
      />
      <path
        stroke="#1E293B"
        strokeMiterlimit={10}
        d="m25.505 128.439 2.877-8.303"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M185.455 231.632c14.335 0 25.955-2.62 25.955-5.853s-11.62-5.853-25.955-5.853-25.956 2.621-25.956 5.853c0 3.233 11.621 5.853 25.956 5.853"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M165.441 93.486s-3.512 2.148-3.472 3.09c.039.943-1.31 18.129-1.463 20.277s-2.198 6.582-2.356 8.595c-.159 2.014.347 4.033.937 4.96.59.928 2.758 3.894 2.758 3.894l5.828-9.002c.143-1.966.173-3.939.089-5.908-.089-4.29-2.321-25.906-2.321-25.906"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M187.865 50.9s-6.2-.397-9.731 3.903c-3.532 4.3-2.773 10.877-2.798 12.896s-6.021 7.936-5.357 10.347c.665 2.41 2.431 2.683 1.865 4.697-.565 2.014-4.255 3.224-3.72 5.778.536 2.555 2.48 6.175 2.48 6.175l35.712.243s1.275-3.76-.084-6.582-3.289-3.754-2.976-5.098c.312-1.345 2.039-4.569 1.374-6.984s-2.976-3.085-3.219-7.386c-.243-4.3-.878-11.408-4.291-14.88-3.412-3.472-9.255-3.11-9.255-3.11Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M176.883 216.618a31.7 31.7 0 0 1-3.968 5.912c-2.281 2.555-4.493 3.899-4.419 5.243s5.054 1.607 7.688.665c2.634-.943 4.548-2.823 5.079-2.976s2.014 0 2.048-1.885-.252-7.252-.252-7.252z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M176.174 228.438c2.639-.943 4.548-2.823 5.079-2.976s2.014 0 2.049-1.885c0-.992-.055-2.976-.124-4.598a4.7 4.7 0 0 1-4.018 2.48s-.05-.942-1.488-2.549c-.927-1.052-1.865-.224-2.411.496l-.441.664-.035.065a24 24 0 0 1-1.875 2.415c-2.281 2.555-4.493 3.899-4.419 5.243s5.049 1.587 7.683.645m19.087-11.83a37 37 0 0 0 4.613 5.908c2.554 2.549 4.91 3.888 4.96 5.232s-4.881 1.612-7.619.675-4.851-2.817-5.392-2.951c-.54-.134-2.018 0-2.251-1.875s-.526-7.252-.526-7.252z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M197.234 228.423c-2.733-.938-4.851-2.817-5.391-2.951-.541-.134-2.019 0-2.252-1.875-.124-.992-.263-2.976-.367-4.603a5.26 5.26 0 0 0 4.28 2.45s-.049-.942 1.205-2.554c.819-1.047 1.846-.223 2.48.496.164.218.333.436.496.664l.045.065a30 30 0 0 0 2.133 2.415c2.554 2.55 4.91 3.889 4.96 5.233s-4.851 1.597-7.589.66"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M166.096 145.725s.828 27.93 1.929 33.435 6.017 37.061 6.017 37.061c1.502.636 3.12.953 4.751.933h5.372l.496-23.501.184-34.11 2.693 57.74s7.4.263 8.199.129 1.463-.268 1.463-.268l4.757-36.531 2.723-24.304.148-9.667s-22.736-.788-29.343-1.324-9.325-.67-9.325-.67z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M196.798 195.037c-.06.466-.119.898-.189 1.295m.799-34.919s.461 19.9-.347 30.703m-24.032-32.3s.571 13.164 1.136 16.115"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M194.224 151.429v10.049c1.287.346 2.604.574 3.933.679 2.038.134 5.843 0 5.843 0l.273-3.804zm-27.707 7.737s5.163 1.632 7.336 1.766 4.206.407 4.206.407l-.268-2.713-11.547-1.086z"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m182.191 82.867-4.052 4.568-11.24 8.199 2.396 49.694s8.958 2.411 16.646 3.08c7.688.67 17.573-1.091 17.573-1.091l-1.453-52.08s-4.375-6.448-6.617-8.189-3.268-3.353-6.239-3.62c-2.972-.269-7.014-.561-7.014-.561"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m177.489 85.312-12.048 8.199 1.89 30.211-.64 3.036-5.064 5.833 2.158 10.247-.963 14.458s4.787 1.612 8.031 2.009c2.415.259 4.842.393 7.271.402l.417-19.741s1.602-32.771 1.79-34.224c.189-1.453 1.86-22.816 1.86-22.816z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m177.683 86.547-5.833 14.627 6.294 2.614-5.942 1.081 5.262 20.619"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M181.179 78.973s2.093 13.963 3.74 17.186c1.646 3.224 2.113 4.296 2.113 4.296s3.581-10.882 4.3-12.494 1.488-7.386 1.488-7.386-7.138 2.287-11.641-1.602"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M181.179 78.973s.188 1.27.496 3.13c1.046.798 3.819 2.773 5.867 2.877 1.563.079 3.547-.933 4.866-1.741.238-1.464.402-2.664.402-2.664s-7.128 2.287-11.631-1.602"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M178.336 69.32s-1.428-2.033-2.39 0c-.963 2.035 1.488 5.045 2.172 5.457 1.944 1.156 1.429-.992 1.429-.992zm17.743.879s1.205-2.103 2.385-.12c1.181 1.985-.947 5.105-1.582 5.546-1.815 1.255-1.527-.898-1.527-.898z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M178 67.525s5.178-1.21 7.787-2.752a14.3 14.3 0 0 0 4.102-3.735s2.242 4.236 4.216 5.952 2.386 1.815 2.386 1.815-.947 7.415-2.019 9.994c-1.071 2.58-4.568 5.09-7.832 4.365-1.855-.406-5.069-1.746-6.001-4.002-.933-2.257-2.639-11.637-2.639-11.637"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M191.213 73.224c-1.607.04-6.989.05-9.766-1.626a18.4 18.4 0 0 1-3.11-2.277c.099.496.218 1.116.347 1.76l.605.442 1.518 5.734-.709.154q.224.88.54 1.73c.933 2.257 4.147 3.597 6.002 4.003 3.259.72 6.746-1.775 7.827-4.35a2.44 2.44 0 0 1-.213-1.621c.312-1.622.699-4.464.699-4.464l1.106-.992c.06-.362.109-.71.159-1.027-1.339.927-3.849 2.505-5.005 2.534"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M185.305 70.829s-1.731-2.103-4.002-1.91m8.462 2.326a4.9 4.9 0 0 1 4.359-1.458m-13.818-1.919s2.48-.992 4.677-.526m4.985.541a3.57 3.57 0 0 1 4.062.565"
      />
      <path
        fill="#1E293B"
        d="M183.639 70.14a.57.57 0 0 1-.11.435.59.59 0 0 1-.386.229.614.614 0 0 1-.545-.64.57.57 0 0 1 .108-.436.57.57 0 0 1 .388-.228.62.62 0 0 1 .545.64m8.982.644a.56.56 0 0 1-.104.44.57.57 0 0 1-.392.225.61.61 0 0 1-.545-.635.56.56 0 0 1 .105-.44.57.57 0 0 1 .391-.225.61.61 0 0 1 .545.635"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M205.979 90.639s2.891 1.34 3.968 3.472c1.076 2.133 9.681 25.241 9.642 26.987-.04 1.746-2.887 3.76-5.555 4.033-2.669.273-6.558-4.563-7.138-5.367-.58-.803-.684-10.208-.684-10.208z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m192.349 84.469 1.408 46.197.496 29.14a37 37 0 0 0 8.249 1.067 17.6 17.6 0 0 0 6.785-1.211s-.843-18.128-.992-21.085c-.149-2.956-1.131-8.595-1.131-8.595l-1.17-39.343s-10.898-5.099-13.645-6.17"
      />
      <path
        fill="#fff"
        d="m195.444 87.018 4.994 16.294-4.553 1.235 4.429 2.148-5.525 17.553"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m195.444 87.018 4.994 16.294-4.553 1.235 4.429 2.148-5.525 17.553"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m198.926 101.923-6.196 29.681s4.236 1.339 7.331 1.488c3.095.148 9.543.129 9.543.129l6.448-27.667s-12.692-1.215-17.126-3.631"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m201.083 129.585.203-9.275 4.916 1.036s-3.249 7.158-5.119 8.239"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M204.848 116.957s-1.309-1.875-1.731-2.282c-.421-.407-2.504-1.607-2.906-1.607s-2.559-.134-2.976-.134-.888.943-.461 1.344c.426.402 1.909.536 1.909.536l1.017 1.488-4.588-.402s-.764.809-.59 1.488q.195.692.496 1.344-.027 1.482.154 2.952c.178.808.992 1.076 2.108 1.746s4.85.267 4.85.267h1.726z"
      />
      <path
        fill="#fff"
        d="m216.032 117.889-11.904-1.602s-.927 7.658-1.865 10.208-1.18 3.09-1.18 3.09 12.474-.278 15.416-.546c2.941-.267 4.593-2.018 4.464-4.434-.129-2.415-1.602-4.836-1.602-4.836"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m216.032 117.889-11.904-1.602s-.927 7.658-1.865 10.208-1.18 3.09-1.18 3.09 12.474-.278 15.416-.546c2.941-.267 4.593-2.018 4.464-4.434-.129-2.415-1.602-4.836-1.602-4.836"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M166.393 126.252s2.778 1.741 3.472 2.281c.695.541.754 4.028 1.196 4.698.441.669-.08 1.076-.883 1.076-.804 0-2.019-2.55-2.019-2.55l-.05-.942-5.674 1.885-1.488-2.55s1.687-3.898 5.446-3.898"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M125.057 231.632c14.335 0 25.956-2.62 25.956-5.853s-11.621-5.853-25.956-5.853-25.955 2.621-25.955 5.853c0 3.233 11.62 5.853 25.955 5.853"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M145.328 86.696s3.403 17.077 4.008 20.237 1.716 10.669 2.976 13.962c1.26 3.294.848 7.51.218 8.695-.632 1.03-1.357 2-2.167 2.897l-4.226-5.511s-2.515-27.002-2.609-30.826 1.8-9.454 1.8-9.454"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M147.57 126.301s2.678 1.672 3.15 2.381c.471.71.808 1.612.441 2.381s-2.366 3.09-2.872 3.542-.932-1.35-.992-2.897c-.059-1.548.273-5.407.273-5.407m-14.403 93.645s8.397 4.082 8.928 4.876c.53.794-1.384 1.85-6.389 1.85-5.004 0-5.153-.66-7.142-1.185-1.989-.526-5.952-.134-6.488-1.32s.397-5.267.397-5.267z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M122.017 222.054s6.944 4.216 6.334 5.665-5.63 1.448-8.274 1.051-8.263-3.968-8.561-5.267c-.297-1.3.447-3.294 4.519-3.686s5.982 2.237 5.982 2.237m21.08-68.889s-3.968 30.558-4.658 35.037c-.689 4.479-2.877 11.592-4.057 16.993a90 90 0 0 0-1.488 9.618 9 9 0 0 0 .064 2.762c.169 1.32.194 2.371.194 2.371a36 36 0 0 1-5.377.923c-2.237.134-5.267.134-5.267.134l-.992-8.561s.739-12.648 1.175-16.334.903-32.666.883-33.46l-.193-7.772 13.516-1.448s1.845-5.268 4.617-5.005 1.583 4.742 1.583 4.742"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M141.534 148.423c-2.772-.263-4.617 5.005-4.617 5.005l-13.531 1.448.089 3.76c1.443.129 2.778.203 3.918.203a119 119 0 0 0 12.976-.729l.58-6.071.908 6.185.625-.203c.387-2.947.635-4.866.635-4.866s1.19-4.469-1.583-4.732"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M109.27 154.087s0 .189.029.531c.184 3.601 1.275 24.468 1.607 28.317.372 4.216-1.488 8.824-1.393 12.643s.992 24.502.992 24.502l.992 3.423s2.331-1.578 4.568-1.578 5.952.129 5.952.129.193-2.767.173-3.685-.104-4.216-.104-4.216 3.785-17.256 4.013-18.704 3.164-26.477 3.144-27.28c-.019-.804.62-11.988.62-11.988z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m129.725 158.814.143-2.619-20.598-2.108s0 .189.029.531c0 .437.055 1.126.104 1.984 4.817.863 13.174 2.222 17.99 2.222.794.015 1.568.005 2.332-.01"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M124.447 73.21s-2.178.367-3.006 1.339a22 22 0 0 0-2.232 3.035c-.114.367-12.921 8.14-12.921 8.14l.923 37.061s.381 5.709-.298 7.653a39.2 39.2 0 0 0-1.652 11.79c.119 4.737.427 12.4.427 12.4s14.657 2.917 21.705 2.917c4.335-.011 8.667-.258 12.975-.74l.58-6.076.883 6.2 3.011-.992 2.48-18.352s1.726-3.888 1.706-4.741.437-1.945-.064-2.674-1.394-2.187-1.394-2.187-2.242-40.191-2.242-41.287-6.631-7.53-7.122-7.772c-.491-.244-3.517-4.742-7.44-5.953-3.924-1.21-6.319.239-6.319.239"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m120.454 78.8-1.468 13.347 6.032 2.802-1.508 4.529 15.083 26.506m-1.29-45.687 4.523 10.56-1.939 2.585 3.234 4.523-1.295 29.095"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M123.887 75.035c.11 2.604.582 5.18 1.403 7.653 1.305 3.646 9.95 28.798 9.95 28.798l5.59 19.681s-.739-20.048-.759-20.896-4.236-28.679-4.236-28.679-.719-4.494-1.855-6.195-8.749-4.86-10.093-.362"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M123.351 73.09s-3.517.363-2.897 5.71 1.439 8.992 1.439 8.992"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M121.461 94.84s1.488 6.195 2.505 7.897 4.092 3.402 6.279 3.402"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M126.163 93.382s1.637 7.167 1.909 8.506c.273 1.34-.629 3.884-2.147 6.448"
      />
      <path
        fill="#1E293B"
        d="M125.488 109.184a.67.67 0 0 1-.268-.912l.174-.318a.66.66 0 0 1 .903-.243.68.68 0 0 1 .272.913l-.173.312a.66.66 0 0 1-.908.248m5.571-2.911a.65.65 0 0 1-.675.65h-.362a.683.683 0 0 1-.615-.939.64.64 0 0 1 .365-.353.6.6 0 0 1 .255-.043h.362a.69.69 0 0 1 .67.685"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m121.471 95.326-.834-5.282a2.17 2.17 0 0 1 1.642-2.48v0a2.296 2.296 0 0 1 2.728 1.647l1.424 5.257"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M141.077 98.896c1.194-.12 2.05-1.332 1.912-2.709-.138-1.376-1.217-2.395-2.411-2.275s-2.05 1.332-1.912 2.709c.138 1.376 1.217 2.395 2.411 2.275"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M134.977 75.318s4.776 6.72 6.299 20.42"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M141.403 97.324c.652-.06 1.123-.727 1.053-1.488-.071-.76-.657-1.328-1.309-1.268s-1.123.727-1.052 1.488c.071.76.656 1.329 1.308 1.268"
      />
      <path
        fill="#fff"
        d="M148.383 127.348a25 25 0 0 0-.719 2.768c0 .396-.094 1.582-1.022 1.314-.927-.268-.649-5.005-.53-5.53s2.376 1.175 2.376 1.175"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M148.383 127.348a25 25 0 0 0-.719 2.768c0 .396-.094 1.582-1.022 1.314-.927-.268-.649-5.005-.53-5.53s2.376 1.175 2.376 1.175"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.277 123.152a5.9 5.9 0 0 0 2.688.729c1.458 0 4.122-.496 5.099-.243s3.472 3.398 3.472 3.398.744.729.392 1.096-1.072.992-1.568.605-2.976-2.431-2.976-2.431l-6.254 2.555-2.569-1.339z"
      />
      <path
        fill="#fff"
        d="M108.699 84.876s-4.122.496-4.821 1.577c-.7 1.081-11.994 25.881-11.969 26.977s22.583 18.848 22.583 18.848l4.871-9.27-12.187-11.299 5.287-12.27"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M108.699 84.876s-4.122.496-4.821 1.577c-.7 1.081-11.994 25.881-11.969 26.977s22.583 18.848 22.583 18.848l4.871-9.27-12.187-11.299 5.287-12.27m3.84 37.318 11.914.243-.06-7.41m-9.012-.729-3.15 1.175"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M122.736 67.863s1.354 10.575 2.247 12.152 7.241 7.896 7.241 7.896 1.984-7.286 2.207-8.987c.224-1.702.397-8.507.397-8.507l-12.117-3.402z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M122.736 67.863s.158 1.24.401 2.931c.95.885 2.035 1.611 3.215 2.153 1.562.69 5.728 1.076 8.362.769.07-1.781.114-3.299.114-3.299l-12.117-3.402z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M119.224 58.632s-1.622-1.701-2.426.238c-.803 1.94.992 4.861 1.949 4.985a4.2 4.2 0 0 0 1.697-.124s.679 2.797 1.304 3.646a12.7 12.7 0 0 0 4.603 3.64c2.212.992 9.618 1.344 10.555 0s.883-8.506.868-9.111-.119-9.265-.119-9.265.665-3.006-.238-5.075c-.903-2.068-9.965-4.96-12.241-3.764-2.277 1.195-4.901 3.278-5.079 5.952-.179 2.673-.07 7.048-.07 7.048z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M132.616 54.863s1.513 6.944 1.166 7.777c-.348.833-1.434.848-2.793.119-1.359-.73-.65-1.701-.65-1.701m-2.99 1.825s.654 1.82 1.87 1.944c1.215.124 4.265.362 4.265.362m-10.475-10.083.193 2.916s1.111.61 3.661.61 3.387-.61 3.387-.61l-.069-2.916s-3.7-2.188-7.172 0"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m132.626 55.106.923 3.04s.376.496 2.931.496c2.554 0 3.393-.61 3.393-.61l-.075-2.916s-3.705-2.198-7.172-.01m-2.431 0h2.431m-9.602 0-4.792 2.797m5.972-4.256s1.3-1.458 4.246-.243m4.622.243a2.98 2.98 0 0 1 3.15-.362"
      />
      <path
        fill="#1E293B"
        d="M127.309 56.445c0 .535-.194.992-.461.992-.268 0-.496-.437-.496-.992 0-.556.193-.992.466-.992s.481.451.491.992m7.777-.125c0 .536-.193.993-.461.993s-.496-.437-.496-.992c0-.556.193-.992.461-.992s.481.456.496.992Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M126.763 45.081s1.17 2.585-.283 3.527a2.94 2.94 0 0 1-2.951 0 3.27 3.27 0 0 1 .094 3.69 20.5 20.5 0 0 1-2.48 3.319s.263 3.14-.283 3.506c-.545.367-2.033-.183-2.058-1.106-.025-.922-.62-2.768-.645-3.69s-.451-3.323-.451-3.323-3.73-1.662-1.751-3.69c1.979-2.03 6.18-3.69 6.18-3.69s.094-3.691 2.649-4.797c2.554-1.106 8.05-2.768 8.094-.923.07.95-.1 1.902-.496 2.768 0 0 4.608-.183 7.822 2.976 3.214 3.16 3.105 6.09 1.444 6.09s-2.203.368-2.203.368 3.18 1.845 1.543 2.768-3.323-.184-3.323-.184.704-1.29-1.369-2.976-9.533-4.633-9.533-4.633M63.826 231.632c14.335 0 25.955-2.62 25.955-5.853s-11.62-5.853-25.955-5.853-25.956 2.621-25.956 5.853c0 3.233 11.62 5.853 25.956 5.853"
      />
      <path
        fill="#999"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M80.918 104.72v4.742l6.894 3.16s.72-3.16.144-8.765z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M46.699 218.508a49 49 0 0 0-4.464 4.35c-1.984 2.237-2.371 4.464-.526 5.53 1.845 1.067 4.742.789 7.901-1.185 3.16-1.974 3.03-2.768 4.876-3.423 1.845-.654 3.03-1.984 3.03-4.087s-6.72-2.371-8.169-2.48c-1.448-.109-2.648 1.295-2.648 1.295"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M57.502 219.693s-2.976 1.32-4.42 2.52-4.32 5.52-7.683 5.52a10 10 0 0 1-4.89-1.274 2.18 2.18 0 0 0 1.185 1.929c1.845 1.052 4.742.789 7.901-1.185 3.16-1.974 3.031-2.768 4.876-3.423 1.845-.654 3.03-1.979 3.03-4.087Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M80.124 218.508a49 49 0 0 1 4.464 4.35c1.984 2.237 2.371 4.464.526 5.53-1.845 1.067-4.742.789-7.901-1.185-3.16-1.974-3.03-2.768-4.876-3.423-1.845-.654-3.03-1.984-3.03-4.087s6.72-2.371 8.169-2.48c1.448-.109 2.648 1.295 2.648 1.295"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M69.321 219.693s2.976 1.32 4.42 2.52 4.32 5.52 7.683 5.52a10 10 0 0 0 4.89-1.274 2.18 2.18 0 0 1-1.185 1.929c-1.845 1.052-4.742.789-7.901-1.185-3.16-1.974-3.031-2.768-4.876-3.423-1.845-.654-3.03-1.979-3.03-4.087Z"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M42.88 143.691s.788 29.636 1.185 33.986 1.568 14.225 1.568 14.771.654 22.394.654 22.394l-1.185 1.984.397 2.768 5.267 1.052c2.634.525 6.061 1.319 6.587.793s.66-1.582.923-3.03c.263-1.449.788-4.087.263-6.191-.526-2.103.66-24.502.793-25.028.134-.525 3.165-22.161 3.165-22.161s1.984 17.787 3.422 23.183.923 13.437 1.448 16.596c.526 3.16 1.583 13.834 1.717 14.622.133.789.391 1.984.391 1.984s6.587-.922 7.773-1.453a25.4 25.4 0 0 1 3.69-1.051s.392-7.51-.134-10.54a43 43 0 0 1-.66-6.448c0-.923.66-30.162.66-30.162l-.66-28.322s-17.781 1.845-24.631.526-12.5-2.108-12.5-2.108z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M55.523 143.954c-6.85-1.314-12.51-2.098-12.51-2.098l-.133 1.84s.044 1.701.119 4.34c3.14.878 9.518 2.421 15.683 2.421 6.518 0 16.9-1.984 21.572-2.932l-.1-4.092s-17.781 1.84-24.631.521"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M78.403 210.696c.085.907.14 1.428.14 1.428m-2.158-61.479s.496 25.455.496 35.062c0 6.647.803 16.735 1.3 22.32m-28.456-58.82 1.2 27.617m-.243 2.158v4.563"
      />
      <path
        fill="#F59E0B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M56.927 74.122a4.08 4.08 0 0 0-3.309 1.721 23.5 23.5 0 0 1-2.728 3.016l-11.78 6.324 1.151 44.967.432 15.376s9.047 3.016 17.667 3.016 23.992-3.472 23.992-3.472-1.29-59.907-.858-59.907c.431 0-8.477-7.737-12.5-9.771s-12.067-1.27-12.067-1.27"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.568 79.558s4.564 8.408 6.488 12.252 5.516 9.374 5.516 9.374l6.006-16.09m-14.637 47.789-.233-6.711-13.556-.788s.134 9.88.397 10.416m13.556 1.835-.065-1.885m16.135.829-.397-11.061s4.742.392 8.695-2.242M52.85 99.16c.416 1.315 1.686 2.088 2.831 1.721s1.727-1.73 1.305-3.045-1.686-2.088-2.832-1.721-1.726 1.73-1.305 3.045Z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M52.983 76.146s-1.523 5.853 1.94 21.74"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M55.989 98.431a1.2 1.2 0 1 1-.71-1.676 1.295 1.295 0 0 1 .71 1.676"
      />
      <path
        fill="#F59E0B"
        d="M80.918 84.32s2.152 1.582 2.296 2.158 7.47 18.099 7.47 18.099l-10.63 1.567.144-10.347"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M80.918 84.32s2.152 1.582 2.296 2.158 7.47 18.099 7.47 18.099l-10.63 1.567.144-10.347"
      />
      <path
        fill="#F59E0B"
        d="M40.548 84.608s-2.445.287-3.02 2.152-4.737 20.832-4.737 20.832l15.227-1.448-1.438-14.225"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.548 84.608s-2.445.287-3.02 2.152-4.737 20.832-4.737 20.832l15.227-1.448-1.438-14.225"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M69.96 75.253s3.776 2.153 4.148 7.51 0 6.715 0 6.715"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M75.814 96.338s-.337 6.334-1.027 8.164-3.417 3.998-5.58 4.345"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M70.928 95.654s-.302 7.306-.332 8.665 1.34 3.725 3.303 5.991"
      />
      <path
        fill="#1E293B"
        d="m74.463 111.106.058-.047a.63.63 0 0 0 .092-.886l-.278-.343a.63.63 0 0 0-.886-.092l-.058.047a.63.63 0 0 0-.092.886l.278.343a.63.63 0 0 0 .886.092m-6.033-2.027.014.073a.63.63 0 0 0 .736.501l.434-.082a.63.63 0 0 0 .502-.736l-.014-.073a.63.63 0 0 0-.736-.502l-.434.083a.63.63 0 0 0-.502.736"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m75.888 96.82-.144-5.323a2.23 2.23 0 0 0-2.073-2.172v0a2.23 2.23 0 0 0-2.39 2.048l-.447 5.396"
      />
      <path
        fill="#999"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m88.244 109.031-3.428-.719-12.067-2.009-16.924-3.224-4.013-1.771s-3.794-4.96-4.121-5.456-4.618 1.235-4.464 1.895.66 7.251.992 7.668 4.037.412 4.037.412 17.678 8.531 18.412 8.843c10.202 4.301 15.123 4.261 16.864 4.544 1.74.282 2.876-.422 3.878-1.573 1.002-1.15.834-8.61.834-8.61"
      />
      <path
        fill="#999"
        d="M47.175 95.782s-2.059.085-5.109 2.143c-1.136.769-1.731 1.32-1.731 1.652.065.395.176.782.332 1.151a31.7 31.7 0 0 1 6.18-2.222"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M47.175 95.782s-2.059.085-5.109 2.143c-1.136.769-1.731 1.32-1.731 1.652.065.395.176.782.332 1.151a31.7 31.7 0 0 1 6.18-2.222"
      />
      <path
        fill="#999"
        d="M47.999 101.06s-5.6 1.399-6.508 1.567c-.907.169-1.071-.744-1.071-1.071s.248-.828 1.235-1.319 5.952-1.816 5.952-1.816"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M47.999 101.06s-5.6 1.399-6.508 1.567c-.907.169-1.071-.744-1.071-1.071s.248-.828 1.235-1.319 5.952-1.816 5.952-1.816"
      />
      <path
        fill="#999"
        d="M48.578 103.614s-6.512.66-7.008.248a1.59 1.59 0 0 1-.412-1.235l5.853-1.24"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.578 103.614s-6.512.66-7.008.248a1.59 1.59 0 0 1-.412-1.235l5.853-1.24"
      />
      <path
        fill="#999"
        d="m46.764 103.942-5.025.248s.243.744.496.823c1.19.294 2.403.488 3.626.58q1.204.04 2.39.248"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m46.764 103.942-5.025.248s.243.744.496.823c1.19.294 2.403.488 3.626.58q1.204.04 2.39.248"
      />
      <path
        fill="#999"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M35.95 107.305s2.297 9.92 2.872 10.634 5.317 0 10.059-1.439c4.742-1.438 24.855-10.053 24.855-10.053l2.871.57s-1.15-3.591-3.16-3.735c-2.008-.144-25.43 2.862-25.43 2.862zm20.099-39.507v.576c.044 1.89.228 7.89.71 9.34.575 1.72 8.332 16.233 8.927 15.083s4.166-16.81 4.166-16.81v-6.175s-1.87 1.577-5.317 1.577-8.486-3.59-8.486-3.59Z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M67.556 76.31a20 20 0 0 0 2.302-1.637v-4.861s-1.87 1.577-5.318 1.577-8.491-3.59-8.491-3.59v.575c0 .664.05 1.84.109 3.15q.204.326.456.62c.992 1.145 5.744 4.022 6.895 4.31s2.752.57 4.047-.144"
      />
      <path
        fill="#999"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M73.305 59.465s2.296-1.294 2.296 1.151-2.153 5.317-2.153 5.317z"
      />
      <path
        fill="#999"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M52.76 59.61s-3.164-.864-3.164 2.013 2.877 4.737 2.877 4.737h1.983s1.151 2.877 2.158 4.022 5.744 4.023 6.895 4.31c1.15.288 2.728.576 4.022-.143a24 24 0 0 0 4.464-3.591c.575-.715 1.582-5.6 1.582-5.6s.288-8.764-.287-11.78c-.576-3.016-4.31-7.758-9.484-8.333-5.173-.575-10.059 3.591-10.773 6.895s-.273 7.47-.273 7.47"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M62.095 61.91s-.992 2.01.719 2.585 3.878.431 4.31-.432-.288-1.865-.288-1.865m-6.462 3.304s3.591 3.015 8.477.144m-6.468 2.584s3.02 1.726 5.03-.288"
      />
      <path
        fill="#1E293B"
        d="M60.662 60.185c.397 0 .719-.516.719-1.151 0-.636-.322-1.15-.72-1.15s-.719.514-.719 1.15.322 1.15.72 1.15Zm7.757-1.295c0 .635-.322 1.15-.72 1.15-.396 0-.718-.495-.718-1.15s.322-1.146.719-1.146.719.511.719 1.146"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M58.648 57.457s.719-1.151 3.471-.72m4.43.144s1.294-2.014 3.308 0"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M73.305 53.568a8.1 8.1 0 0 0-.958-2.42 9 9 0 0 0-.173 2.42c.208 1.052.947 2.52.947 3.254s-.109 3.675.417 3.258a.6.6 0 0 0 .124-.188 44 44 0 0 0-.357-6.324m-7.222-5.952q1.385-.074 2.748-.332a10.5 10.5 0 0 0-5.01-2.014c-5.173-.576-10.059 3.59-10.773 6.894s-.288 7.47-.288 7.47 1.141.794 1.563-.045c.421-.838 0-2.207.421-3.675.422-1.468 1.047-2.207 1.15-3.363.105-1.156-.629-2.832-.207-3.78.421-.947 3.045-2.727 4.726-2.097a14.7 14.7 0 0 0 5.67.942"
      />
    </svg>
  );
};
export default SvgPicDoctors;
