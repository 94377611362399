import { FetchService } from '@modules/Api';

export class PermissionApi extends FetchService {
  public createPermission = (params: CreatePermission) => {
    return this.request<'PERMISSIONS', CreatePermissionResponse>(
      '/permission',
      'CREATE_PERMISSION',
      {
        method: 'POST',
        params,
        permit: 'permissions',
      },
    );
  };

  public updateRolePermission = (
    permissionId: number,
    params: UpdatePermission,
  ) => {
    return this.request<'PERMISSIONS', Permission, UpdatePermission>(
      '/permission',
      'UPDATE_PERMISSION',
      {
        method: 'PUT',
        pathParam: permissionId,
        params,
        permit: 'permissions',
      },
    );
  };

  public removeRolePermission = (permissionId: number) => {
    return this.request<'PERMISSIONS'>('/permission', 'REMOVE_PERMISSION', {
      method: 'DELETE',
      pathParam: permissionId,
      permit: 'permissions',
    });
  };
}
