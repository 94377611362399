import React, { createElement, CSSProperties, memo, ReactNode } from 'react';
import './Text.scss';
import cn from 'classnames';

export type TextProps = {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'blockquote';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  type?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'third'
    | 'success'
    | 'warning'
    | 'danger';
  subtitle?: 'subtitle-1' | 'subtitle-2' | 'subtitle-3';
  body?: 'body-1' | 'body-2';
  transform?: 'none' | 'lowercase' | 'uppercase';
  href?: string;
  className?: string;
  children: ReactNode | ReactNode[] | undefined;
  style?: CSSProperties;
  onClick?: () => void;
};

const RenderTag = ({ tag = 'p', href, ...props }: TextProps) => {
  return createElement(
    href ? 'a' : tag,
    { href: href ?? undefined, ...props },
    props.children,
  );
};

function Text({
  type = 'default',
  align,
  body,
  subtitle,
  children,
  className,
  transform,
  tag = 'p',
  ...rest
}: TextProps): React.JSX.Element | null {
  return (
    <RenderTag
      {...rest}
      tag={tag}
      className={cn([
        'typography',
        transform,
        subtitle,
        align,
        type,
        body,
        className,
        tag,
      ])}
    >
      {children}
    </RenderTag>
  );
}

export default memo(Text);
