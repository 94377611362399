import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutlinemedicalServices = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path
        fill="#FFF"
        d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2M10 4h4v2h-4zm10 16H4V8h16z"
      />
      <path fill="#FFF" d="M13 10h-2v3H8v2h3v3h2v-3h3v-2h-3z" />
    </svg>
  );
};
export default SvgOutlinemedicalServices;
