import React from 'react';
import { AppRouter } from '../AppRouter';
import AppLayout from '@components/Layout/AppLayout';
import FullLayout from '@components/Layout/FullLayout';
import InitLayout from '@components/Layout/InitLayout';
import LayoutRoutes from './LayoutRoutes';
import { ROUTER_CONFIG } from '@router/index';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';

export const Router = observer(() => {
  const { isAuth } = useService('AuthService');
  const { isAdmin, allowedRoutes } = useService('PermissionsService');
  const { getQuery } = useService('RoutingService');

  const privatePaths = () => {
    return isAdmin ? ROUTER_CONFIG.private.paths : allowedRoutes || [];
  };

  const privateRootPath = () => {
    const query = getQuery();
    const paths = privatePaths();
    if (query.backURL) {
      const { pathname, hash, search } = JSON.parse(query.backURL);
      if (pathname && paths.includes(pathname)) {
        let rootPath = pathname;
        if (search) rootPath += search;
        if (hash) rootPath += hash;
        return rootPath;
      }
    }
    return ROUTER_CONFIG.private.rootPath;
  };

  return (
    <AppRouter
      initRoutes={{
        paths: ROUTER_CONFIG.init.paths,
        rootPath: ROUTER_CONFIG.init.rootPath,
        component: function PublicRoute() {
          return (
            <LayoutRoutes
              layout={InitLayout}
              routes={ROUTER_CONFIG.init.routes}
            />
          );
        },
      }}
      needChangePass={{
        paths: ROUTER_CONFIG.changePassword.paths,
        rootPath: ROUTER_CONFIG.changePassword.rootPath,
        component: function PublicRoute() {
          return (
            <LayoutRoutes
              layout={FullLayout}
              routes={ROUTER_CONFIG.changePassword.routes}
            />
          );
        },
      }}
      publicRoutes={{
        paths: ROUTER_CONFIG.public.paths,
        rootPath: ROUTER_CONFIG.public.rootPath,
        component: function PublicRoute() {
          return (
            <LayoutRoutes
              layout={FullLayout}
              routes={ROUTER_CONFIG.public.routes}
            />
          );
        },
      }}
      privateRoutes={{
        paths: privatePaths(),
        rootPath: privateRootPath(),
        component: function PrivateRoute() {
          return (
            <LayoutRoutes
              layout={AppLayout}
              routes={ROUTER_CONFIG.private.routes}
            />
          );
        },
      }}
      staticRoutes={{
        paths: ROUTER_CONFIG.static.paths,
        rootPath: ROUTER_CONFIG.static.rootPath,
        component: function StaticRoute() {
          return (
            <LayoutRoutes
              layout={isAuth ? AppLayout : FullLayout}
              routes={ROUTER_CONFIG.static.routes}
            />
          );
        },
      }}
    />
  );
});
