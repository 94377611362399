import { KanbanStore } from '@modules/Administrator/Kanban/kanban.store';
import uniqueId from 'lodash/uniqueId';
import kanbanFilters from '@views/Administrator/Kanban/FilterPanel/KanbanState';

export class KanbanService extends KanbanStore {
  protected _store: Store;
  public readonly storeId = uniqueId('kanban-');

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public subscribe = () => {
    this._store.WsService.on('appointments.get_list', this._getAppointments);
    this._store.WsService.on('appointments.change_status', this._changeStatus);
    this._store.WsService.on('appointments.create', this._createAppointment);
    this._store.WsService.on('appointments.update', this._updateAppointment);
    this._store.WsService.on('appointments.unsubscribe', this.unsubscribe);
  };

  public unsubscribe = (data: WsResponse<StatusOnly>) => {
    if (!data.storage || data.storage !== this.storeId) return;

    this._store.WsService.off('appointments.get_list', this._getAppointments);
    this._store.WsService.off('appointments.change_status', this._changeStatus);
    this._store.WsService.off('appointments.create', this._createAppointment);
    this._store.WsService.off('appointments.update', this._updateAppointment);
    this._store.WsService.off('appointments.unsubscribe', this.unsubscribe);
  };

  public getAppointmentsReq = (params?: GetAppointmentsReq) => {
    this._store.WsApi.appointments.getAppointments(params, {
      id: this.storeId,
    });
  };

  public changeStatusReq = (
    params: ChangeStatusAppointmentReq,
    id?: number,
  ) => {
    this._store.WsApi.appointments.changeStatusAppointment(params, id);
  };

  public unsubscribeAppointments = () => {
    this._store.WsApi.appointments.unsubscribeAppointments(this.storeId);
  };

  private _getAppointments = (data: WsResponse<AppointmentType[]>) => {
    if (
      data.result.success &&
      data.storage &&
      data.storage.id &&
      data.storage.id === this.storeId
    ) {
      this.setEvents(data.result.data);
    }
  };

  private _updateAppointment = ({ result }: WsResponse<AppointmentType>) => {
    if (result.success && this._isSelectedHealthFacility(result.data)) {
      this.updateEvent(result.data);
    }
  };

  private _createAppointment = ({ result }: WsResponse<AppointmentType>) => {
    if (result.success && this._isSelectedHealthFacility(result.data)) {
      this.createEvent(result.data);
    }
  };

  private _changeStatus = ({ result }: WsResponse<AppointmentType>) => {
    if (result.success && this._isSelectedHealthFacility(result.data)) {
      this.changeStatus(result.data);
    }
  };

  private _isSelectedHealthFacility = (appointment: AppointmentType) => {
    return (
      appointment.health_facility_id === kanbanFilters.hFFilter?.id &&
      appointment.appointment_type === 'outpatient'
    );
  };
}
