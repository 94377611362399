import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDone2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      role="img"
      {...props}
    >
      <rect width={28} height={28} fill="#9CA3AF" rx={4} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M11.5 17.5 8 14l-1.166 1.167 4.666 4.666 10-10-1.166-1.166z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h20v20H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDone2;
