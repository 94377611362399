import { CurrencyStore } from '@modules/Currency/currency.store';
import isNumber from 'lodash/isNumber';
import i18next from 'i18next';

export class CurrencyService extends CurrencyStore {
  constructor(_store: Store) {
    super();
  }

  public getPrice = (
    price: number,
    currencyDisplay: 'code' | 'symbol' = 'symbol',
  ): string => {
    if (!isNumber(price)) {
      console.warn(`"${price}" Not a number`);
      return '0';
    }
    return this._format(price, currencyDisplay);
  };

  private _format(
    money: number,
    currencyDisplay: 'code' | 'symbol',
    currency = this.currency,
    locales = i18next.language,
  ): string {
    return new Intl.NumberFormat(locales, {
      style: 'currency',
      currency,
      currencyDisplay,
    }).format(money);
  }

  public roundTo2Decimal = (value: number) => {
    return parseInt(String(value * 100)) / 100;
  };
}
