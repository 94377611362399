import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3 13h8V3H3zm0 8h8v-6H3zm10 0h8V11h-8zm0-18v6h8V3z"
      />
    </svg>
  );
};
export default SvgDashboard;
