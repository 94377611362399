import { action, makeObservable, observable } from 'mobx';
import { DocChapterItem } from './mapper/DocChapterItem';
import { DocTemplateApi } from '@modules/Api/methods';

export class DocChapterStore {
  protected readonly _store: Store;
  protected readonly _getDocElement: DocTemplateApi['getDocElement'];

  @observable public docChapter: DocChapterItem | null = null;
  @observable public docChapters: DocChapter[] = [];
  @observable public docChaptersMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };
  // GROUPS
  @observable public docChapterGroup: DocChapterGroup | null = null;
  @observable public docChapterGroups: DocChapterGroup[] = [];
  @observable public docChapterGroupsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor(store: Store) {
    makeObservable(this);
    this._store = store;
    this._getDocElement = store.DocTemplateApi.getDocElement;
  }

  @action
  public setDocChapters = (chapters: DocChapter[], meta: ResponseMeta) => {
    this.docChapters = chapters;
    this.docChaptersMeta = meta;
  };

  @action
  public setDocChapter = (chapter: DocChapter | null, isNew = false) => {
    if (chapter && !isNew) {
      this.docChapter = new DocChapterItem(chapter, this._getDocElement);
    }
    if (chapter && isNew) {
      this.docChapters = [
        new DocChapterItem(chapter, this._getDocElement),
        ...this.docChapters,
      ];
    }
    if (!chapter && !isNew) {
      this.docChapter = chapter;
    }
  };

  @action
  public updateDocChapter = (updatedChapter: DocChapter) => {
    const chapter = this.docChapters.find((e) => e.id === updatedChapter.id);
    if (chapter) {
      const myChapter = new DocChapterItem(updatedChapter, this._getDocElement);
      Object.assign(chapter, myChapter);
    }
    if (this.docChapter?.id === updatedChapter.id) {
      this.docChapter = new DocChapterItem(updatedChapter, this._getDocElement);
    }
  };

  @action
  public deleteDocChapter = (id: number) => {
    this.docChapters = this.docChapters.filter((e) => e.id !== id);
  };

  /*
   |----------------------------------------------------------------------------
   | CHAPTER GROUPS
   |----------------------------------------------------------------------------
   */
  @action
  public setDocChapterGroups = (
    groups: DocChapterGroup[],
    meta: ResponseMeta,
  ) => {
    this.docChapterGroups = groups;
    this.docChapterGroupsMeta = meta;
  };

  @action
  public setDocChapterGroup = (group: DocChapterGroup, isNew = false) => {
    if (!isNew) {
      this.docChapterGroup = group;
    }
    if (isNew) {
      this.docChapterGroups = [group, ...this.docChapterGroups];
    }
  };

  @action
  public updateDocChapterGroup = (updatedGroup: DocChapterGroup) => {
    const item = this.docChapterGroups.find((e) => e.id === updatedGroup.id);
    if (item) {
      Object.assign(item, updatedGroup);
    }
  };

  @action
  public deleteDocChapterGroup = (id: number) => {
    this.docChapterGroups = this.docChapterGroups.filter((e) => e.id !== id);
  };
}
