import React from 'react';
import { useService } from '@stores/hooks';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './WsIndicator.scss';

function WsIndicator() {
  const { isConnected } = useService('WsService');
  const { t } = useTranslation('layout');

  return (
    <>
      <div className="rm-wsindicator">
        {isConnected ? (
          <span className={cn('rm-wsindicator-status', 'online')}>
            {t('wsStatus.online')}
          </span>
        ) : (
          <span className={cn('rm-wsindicator-status', 'offline')}>
            {t('wsStatus.offline')}
          </span>
        )}
      </div>
      <div className="devider" />
    </>
  );
}

export default observer(WsIndicator);
