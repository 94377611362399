import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutlineemail = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={19}
      fill="none"
      viewBox="0 0 21 19"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h9v-2H2V4l8 5 8-5v5h2V2c0-1.1-.9-2-2-2m-8 7L2 2h16zm7 4 4 4-4 4v-3h-4v-2h4z"
      />
    </svg>
  );
};
export default SvgOutlineemail;
