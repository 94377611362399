import { FetchService } from '@modules/Api';

export class InsuranceApi extends FetchService {
  /*
   |------------------------------------------------------------------------------
   | INSURANCE COMPANIES
   |------------------------------------------------------------------------------
   */
  public getInsuranceCompanies = (query?: GetInsuranceCompaniesParams) => {
    return this.request<
      'INSURANCE',
      InsuranceCompany[],
      null,
      GetInsuranceCompaniesParams
    >('/insurance_companies', 'GET_INSURANCE_COMPANIES', {
      permit: 'insurance_companies',
      query,
    });
  };

  public getInsuranceCompany = (id: number) => {
    return this.request<'INSURANCE', InsuranceCompany>(
      '/insurance_companies',
      'GET_INSURANCE_COMPANY',
      {
        permit: 'insurance_companies',
        pathParam: id,
      },
    );
  };

  public createInsuranceCompany = (params: CreateInsuranceCompanyParams) => {
    return this.request<'INSURANCE', InsuranceCompany>(
      '/insurance_companies',
      'CREATE_INSURANCE_COMPANY',
      {
        permit: 'insurance_companies',
        method: 'POST',
        params,
      },
    );
  };

  public updateInsuranceCompany = (
    id: number,
    params: UpdateInsuranceCompanyParams,
  ) => {
    return this.request<'INSURANCE', InsuranceCompany>(
      '/insurance_companies',
      'UPDATE_INSURANCE_COMPANY',
      {
        permit: 'insurance_companies',
        method: 'PUT',
        pathParam: id,
        params,
      },
    );
  };

  public activateInsuranceCompany = (id: number) => {
    return this.request<'INSURANCE', InsuranceCompany>(
      '/insurance_companies',
      'ACTIVATE_INSURANCE_COMPANY',
      {
        permit: 'insurance_companies',
        method: 'PUT',
        pathParam: `${id}/activate`,
      },
    );
  };

  public deactivateInsuranceCompany = (id: number) => {
    return this.request<'INSURANCE', InsuranceCompany>(
      '/insurance_companies',
      'DEACTIVATE_INSURANCE_COMPANY',
      {
        permit: 'insurance_companies',
        method: 'PUT',
        pathParam: `${id}/deactivate`,
      },
    );
  };

  public downloadServicesPrice = (params: DownloadServicesPriceParams) => {
    return this.request<'INSURANCE', BlobResponse>(
      '/insurance_companies/calculate_services/download',
      'DOWNLOAD_SERVICES_PRICE_FOR_COMPANY',
      {
        permit: 'insurance_companies',
        method: 'POST',
        blob: true,
        params,
      },
    );
  };

  /*
   |------------------------------------------------------------------------------
   | INSURANCE POLICIES
   |------------------------------------------------------------------------------
   */
  public getInsurancePolicies = (query?: GetInsurancePolicyParams) => {
    return this.request<
      'INSURANCE',
      InsurancePolicy[],
      null,
      GetInsuranceCompaniesParams
    >('/insurance_policies', 'GET_INSURANCE_POLICIES', {
      permit: 'insurance_policies',
      query,
    });
  };

  public getInsurancePolicy = (id: number) => {
    return this.request<'INSURANCE', InsurancePolicy>(
      '/insurance_policies',
      'GET_INSURANCE_POLICY',
      {
        permit: 'insurance_policies',
        pathParam: id,
      },
    );
  };

  public createInsurancePolicy = (params: CreateInsurancePolicyParams) => {
    return this.request<'INSURANCE', InsurancePolicy>(
      '/insurance_policies',
      'CREATE_INSURANCE_POLICY',
      {
        permit: 'insurance_policies',
        method: 'POST',
        params,
      },
    );
  };

  public updateInsurancePolicy = (
    id: number,
    params: UpdateInsurancePolicyParams,
  ) => {
    return this.request<'INSURANCE', InsurancePolicy>(
      '/insurance_policies',
      'UPDATE_INSURANCE_POLICY',
      {
        permit: 'insurance_policies',
        method: 'PUT',
        pathParam: id,
        params,
      },
    );
  };

  public activateInsurancePolicy = (id: number) => {
    return this.request<'INSURANCE', InsurancePolicy>(
      '/insurance_policies',
      'ACTIVATE_INSURANCE_POLICY',
      {
        permit: 'insurance_policies',
        method: 'PUT',
        pathParam: `${id}/activate`,
      },
    );
  };

  public deactivateInsurancePolicy = (id: number) => {
    return this.request<'INSURANCE', InsurancePolicy>(
      '/insurance_policies',
      'DEACTIVATE_INSURANCE_POLICY',
      {
        permit: 'insurance_policies',
        method: 'PUT',
        pathParam: `${id}/deactivate`,
      },
    );
  };

  /*
   |------------------------------------------------------------------------------
   | GUARANTORS
   |------------------------------------------------------------------------------
   */
  public getGuarantor = (id: number) => {
    return this.request<'INSURANCE', Guarantor>(
      '/guarantors',
      'GET_GUARANTOR',
      {
        permit: 'guarantors',
        pathParam: id,
      },
    );
  };

  public getGuarantors = (query?: GetGuarantorParams) => {
    return this.request<'INSURANCE', Guarantor[]>(
      '/guarantors',
      'GET_GUARANTORS',
      {
        permit: 'guarantors',
        query,
      },
    );
  };

  public createGuarantor = (params: CreateGuarantorParams) => {
    return this.request<'INSURANCE', Guarantor>(
      '/guarantors',
      'CREATE_GUARANTOR',
      {
        permit: 'guarantors',
        method: 'POST',
        params,
      },
    );
  };

  public updateGuarantor = (id: number, params: UpdateGuarantorParams) => {
    return this.request<'INSURANCE', Guarantor>(
      '/guarantors',
      'UPDATE_GUARANTOR',
      {
        permit: 'guarantors',
        method: 'PUT',
        pathParam: id,
        params,
      },
    );
  };

  public activateGuarantor = (id: number) => {
    return this.request<'INSURANCE', Guarantor>(
      '/guarantors',
      'ACTIVATE_GUARANTOR',
      {
        permit: 'guarantors',
        method: 'PUT',
        pathParam: `${id}/activate`,
      },
    );
  };

  public deactivateGuarantor = (id: number) => {
    return this.request<'INSURANCE', Guarantor>(
      '/guarantors',
      'DEACTIVATE_GUARANTOR',
      {
        permit: 'guarantors',
        method: 'PUT',
        pathParam: `${id}/deactivate`,
      },
    );
  };

  /*
   |------------------------------------------------------------------------------
   | GUARANTEES
   |------------------------------------------------------------------------------
   */
  public getGuarantee = (id: number) => {
    return this.request<'GUARANTEES', Guarantee>(
      '/guarantees',
      'GET_GUARANTEE',
      {
        permit: 'guarantees',
        pathParam: id,
      },
    );
  };

  public getGuarantees = (query?: GetGuaranteesQuery) => {
    return this.request<'GUARANTEES', Guarantee[]>(
      '/guarantees',
      'GET_GUARANTEES',
      {
        permit: 'guarantees',
        query,
      },
    );
  };

  public downloadGuaranteesReport = (query?: DownloadGuaranteeReport) => {
    return this.request<'GUARANTEES', BlobResponse>(
      '/guarantees',
      'DOWNLOAD_GUARANTEES_REPORT',
      {
        permit: 'guarantees',
        pathParam: 'download_report',
        query,
        blob: true,
      },
    );
  };

  public createGuarantee = (params: CreateGuaranteeParam) => {
    return this.request<'GUARANTEES', Guarantee[]>(
      '/guarantees',
      'CREATE_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'POST',
        params,
      },
    );
  };

  public updateGuarantee = (id: number, params: UpdateGuaranteeParams) => {
    return this.request<'GUARANTEES', Guarantee>(
      '/guarantees',
      'UPDATE_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'PUT',
        pathParam: id,
        params,
      },
    );
  };

  public cancelGuarantee = (id: number) => {
    return this.request<'GUARANTEES', Guarantee>(
      '/guarantees',
      'CANCEL_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'PUT',
        pathParam: `${id}/cancel`,
      },
    );
  };

  public applyGuarantee = (id: number, params: ApplyGuaranteeParams) => {
    return this.request<'GUARANTEES', ApplyGuaranteeResponse>(
      '/guarantees',
      'APPLY_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'POST',
        pathParam: `${id}/apply`,
        params: params,
      },
    );
  };

  public confirmGuarantee = (id: number, params: ConfirmGuaranteeParam) => {
    return this.request<'GUARANTEES', Guarantee>(
      '/guarantees',
      'CONFIRM_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'PUT',
        pathParam: `${id}/confirm`,
        params: params,
      },
    );
  };

  public unconfirmGuarantee = (id: number) => {
    return this.request<'GUARANTEES', Guarantee>(
      '/guarantees',
      'UNCONFIRM_GUARANTEE',
      {
        permit: 'guarantees',
        method: 'PUT',
        pathParam: `${id}/unconfirm`,
      },
    );
  };

  public getGuaranteeReport = (query?: GetGuaranteeReport) => {
    return this.request<'GUARANTEES', GuaranteeReportData, GetGuaranteeReport>(
      '/guarantees/get_report',
      'GET_GUARANTEE_REPORT',
      { query },
    );
  };

  public updateGuaranteeCosts = () => {
    return this.request<'GUARANTEES', Guarantee[]>(
      '/guarantees/update_costs',
      'UPDATE_GUARANTEE_COSTS',
      { method: 'PUT' },
    );
  };
}
