import { action, makeObservable, observable } from 'mobx';

export class VisitStore {
  @observable public visits: Visit[] = [];
  @observable public visitsMeta: ResponseMeta = {
    totalRecords: 0,
    totalPages: 0,
  };
  @observable public visit: Visit | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setVisits = (visits: Visit[], meta: ResponseMeta) => {
    this.visits = visits;
    this.visitsMeta = meta;
  };

  @action
  public setVisit = (visit: Visit | null) => {
    this.visit = visit;
  };

  @action
  public updateVisit = (updatedVisit: Visit) => {
    const visit = this.visits.find((e) => e.id === updatedVisit.id);
    if (visit) {
      Object.assign(visit, updatedVisit);
    }
    if (this.visit && this.visit.id === updatedVisit.id) {
      Object.assign(this.visit, updatedVisit);
    }
  };
}
