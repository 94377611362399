import { action, computed, makeObservable, observable } from 'mobx';
import { PackageAdapter } from './Package.adapter';

export class Packages {
  @observable public packages: PackageAdapter[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get clinicPackages() {
    return this.packages.filter((pkg) => Boolean(pkg.clinicServices.length));
  }

  @computed
  public get labPackages() {
    return this.packages.filter((pkg) => {
      return Boolean(pkg.laboratoryServices.length);
    });
  }

  @action
  public set = (packages: PatientPkgService[]) => {
    this.packages = packages.map((pkg) => new PackageAdapter({ pkg }));
  };
}
