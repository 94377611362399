import { FetchService } from '@modules/Api';

export class PaymentApi extends FetchService {
  public getPayments = (query?: GetPaymentsQuery) => {
    return this.request<'PAYMENTS', Payment[], null, GetPaymentsQuery>(
      '/payments',
      'GET_PAYMENTS',
      {
        query,
        permit: 'payments',
      },
    );
  };

  public getCancelPayment = (id: number) => {
    return this.request<'PAYMENTS', Payment[]>(
      '/payments',
      'GET_CANCEL_PAYMENT',
      {
        method: 'POST',
        pathParam: `${id}/cancell`,
        permit: 'payments',
      },
    );
  };

  public getProcessPayment = (id: number) => {
    return this.request<'PAYMENTS', Payment>(
      '/payments',
      'GET_PROCESS_PAYMENT',
      {
        method: 'POST',
        pathParam: `${id}/process`,
        permit: 'payments',
      },
    );
  };

  public getPaymentReportsData = (query?: GetPaymentReportQuery) => {
    return this.request<
      'PAYMENTS',
      PaymentReportData,
      null,
      GetPaymentReportQuery
    >('/payments/report/data', 'GET_PAYMENTS_REPORT_DATA', {
      query,
      permit: 'payments',
    });
  };

  public getPaymentReportsHeader = (query?: GetPaymentReportQuery) => {
    return this.request<
      'PAYMENTS',
      PaymentReportHeader,
      null,
      GetPaymentReportQuery
    >('/payments/report/header', 'GET_PAYMENTS_REPORT_HEADER', {
      query,
      permit: 'payments',
    });
  };

  public createPayment = (params: CreatePayment) => {
    return this.request<'PAYMENTS', PaymentReport, CreatePayment>(
      '/payments',
      'CREATE_PAYMENT',
      { method: 'POST', params, permit: 'payments' },
    );
  };

  public createBatchPayment = (params: CreateBatchPayment) => {
    return this.request<
      'PAYMENTS',
      CreateBatchPaymentResponse,
      CreateBatchPayment
    >('/payments/batch', 'CREATE_BATCH_PAYMENT', {
      method: 'POST',
      params,
      permit: 'payments',
    });
  };

  public createBalancePayment = (params: CreateBalancePayment) => {
    return this.request<'PAYMENTS', BalancePayment, CreateBalancePayment>(
      '/payments/balance',
      'CREATE_BALANCE_PAYMENT',
      { method: 'POST', params, permit: 'payments' },
    );
  };

  public createRefundPayment = (params: CreateRefundPayment) => {
    return this.request<'PAYMENTS', BalancePayment>(
      '/payments/refund',
      'CREATE_REFUND_PAYMENT',
      { method: 'POST', params, permit: 'payments' },
    );
  };

  public downloadPaymentReports = (query?: GetPaymentReportsParams) => {
    return this.request<'PAYMENTS', BlobResponse>(
      '/payments/report/download',
      'DOWNLOAD_PAYMENTS_REPORT',
      {
        method: 'GET',
        query: query,
        blob: true,
      },
    );
  };

  public getGroupedPaymentReport = (query: GetGroupedReport) => {
    return this.request<
      'PAYMENTS',
      PaymentGroupedReport,
      null,
      GetGroupedReport
    >('/payments/get_grouped_report', 'GET_PAYMENT_GROUPED_REPORT', {
      query,
      permit: 'payments',
    });
  };

  public downloadGroupedPaymentReport = (query: GetGroupedReport) => {
    return this.request<'PAYMENTS', BlobResponse, null, GetGroupedReport>(
      '/payments/download_grouped_report',
      'DOWNLOAD_GROUPED_PAYMENT_REPORT',
      { query, blob: true },
    );
  };

  public downloadPaymentServiceReport = (query: PaymentServicesReportQuery) => {
    return this.request<
      'PAYMENTS',
      BlobResponse,
      null,
      PaymentServicesReportQuery
    >('/payments', 'DOWNLOAD_PAYMENT_SERVICES_REPORT', {
      pathParam: 'services/download_report',
      query,
      blob: true,
    });
  };

  /*
   |----------------------------------------------------------------------------
   | PAYERS REPORT
   |----------------------------------------------------------------------------
   */
  public getPaymentsPayersReportData = (query: GetPaymentsPayersReportData) => {
    return this.request<
      'PAYMENTS',
      PaymentsPayersReportData[],
      null,
      GetPaymentsPayersReportData
    >('/payments/payers_report/data', 'GET_PAYMENTS_PAYERS_REPORT_DATA', {
      query,
      permit: 'payments',
    });
  };

  public getPaymentsPayersReportHeader = (
    query?: GetPaymentsPayersReportHeader,
  ) => {
    return this.request<
      'PAYMENTS',
      PaymentsPayersReportHeader,
      null,
      GetPaymentsPayersReportData
    >('/payments/payers_report/header', 'GET_PAYMENTS_PAYERS_REPORT_HEADER', {
      query,
      permit: 'payments',
    });
  };

  public downloadPaymentsPayersReport = (
    query?: DownloadPaymentsPayersReport,
  ) => {
    return this.request<
      'PAYMENTS',
      BlobResponse,
      null,
      DownloadPaymentsPayersReport
    >('/payments/payers_report/download', 'DOWNLOAD_PAYMENTS_PAYERS_REPORT', {
      query,
      blob: true,
    });
  };

  /*
   |------------------------------------------------------------------------------
   | BALANCE REPORT
   |------------------------------------------------------------------------------
   */
  public getPaymentsBalanceReportData = (
    query?: GetPaymentsBalanceReportData,
  ) => {
    return this.request<
      'PAYMENTS',
      PaymentsBalanceReportData[],
      null,
      GetPaymentsBalanceReportData
    >('/payments/balance_report/data', 'GET_PAYMENTS_BALANCE_REPORT_DATA', {
      query,
    });
  };

  public getPaymentsBalanceReportHeader = (
    query?: GetPaymentsBalanceReportHeader,
  ) => {
    return this.request<
      'PAYMENTS',
      PaymentsBalanceReportHeader,
      null,
      GetPaymentsBalanceReportHeader
    >('/payments/balance_report/header', 'GET_PAYMENTS_BALANCE_REPORT_HEADER', {
      query,
    });
  };

  public downloadPaymentsBalanceReport = (
    query?: DownloadPaymentsBalanceReport,
  ) => {
    return this.request<
      'PAYMENTS',
      BlobResponse,
      null,
      DownloadPaymentsBalanceReport
    >('/payments/balance_report/download', 'DOWNLOAD_PAYMENTS_BALANCE_REPORT', {
      query,
      blob: true,
    });
  };
}
