import { action, computed, makeObservable, observable } from 'mobx';

export class ScheduleStaff {
  public readonly id: number;
  public user: UserResponse;
  public cabinet: CabinetResponse;
  @observable public events: ScheduleStaffEvent[] = [];

  constructor(data: ScheduleStaffResponse) {
    makeObservable(this);
    this.id = data.id;
    this.user = data.user;
    this.cabinet = data.cabinet;
    this.events = data.events;
  }

  @computed
  public get name() {
    return `${this.user.last_name} ${this.user.first_name[0]}. ${this.user.patronymic[0]}.`;
  }

  @action
  public setValue(key: keyof ScheduleStaff, value: unknown) {
    if (key !== 'id' && key !== 'name') this[key] = value as never;
  }

  @action
  public setEvents(data: ScheduleStaffResponse) {
    if (!data.events.length) return;
    this.events = [...this.events, ...data.events];
  }

  @action
  public updateEvent = (
    updatedEvent: ScheduleStaffEvent,
    onlyConfirm?: boolean,
  ) => {
    const event = this.events.find(
      (e) => e.id === updatedEvent.id,
    ) as ScheduleStaffEvent;

    if (event && onlyConfirm) {
      event.confirmed = updatedEvent.confirmed;
      return;
    }
    Object.assign(event, updatedEvent);
  };

  @action
  public deleteEvent = (event: ScheduleStaffEvent) => {
    if (!event) return;
    this.events = this.events.filter((e) => e.id !== event.id);
  };
}
