import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import useOutsideClick from '@utils/hooks/useOutsideClick';
import { Button } from '@components/UI/Button';
import { Icon } from '@components/UI/Icon';
import { usePopoverState } from './hook';

type Props<T extends BaseObject> = {
  tabs: T[];
  accessor: (tab: T) => string | number;
  className?: string;
  popoverContainerClasses?: string;
  popoverMenuClasses?: string;
  children: (data: TabProps<T>) => React.JSX.Element | null;
  renderPopoverLabel?: (data: LabelProps) => React.JSX.Element | null;
  renderPopoverTab?: (data: TabProps<T>) => React.JSX.Element | null;
};

type TabProps<T extends object = any> = {
  tab: T;
  index: number;
  actions: {
    close: () => void;
    toggle: () => void;
    isOpen: boolean;
  };
};

type LabelProps = {
  isOpen: boolean;
  toggle: () => void;
};

const CustomComponent = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(function Custom(props, ref) {
  return (
    <div style={{ height: '100%' }} ref={ref}>
      {props.children}
    </div>
  );
});

function PopoverTabs<T extends BaseObject>({
  tabs,
  accessor,
  children,
  className,
  popoverContainerClasses,
  popoverMenuClasses,
  renderPopoverLabel,
  renderPopoverTab,
}: Props<T>) {
  const { t } = useTranslation('layout');

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { init, clear, visibleCount } = usePopoverState();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOutsideClick(popoverRef, onClose);

  function onToggle() {
    setIsOpen(!isOpen);
  }

  function onClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (containerRef.current) {
      init(containerRef.current);
    }

    return () => clear();
  }, [init, clear]);

  return (
    <div
      data-testid="popover-ui-tabs"
      className={cn('w-full', className)}
      ref={containerRef}
    >
      <div className="flex items-center">
        {tabs.map((tab, i) => {
          const isVisible = visibleCount >= i + 1;

          return (
            <div
              key={accessor(tab)}
              className={cn({
                'absolute -z-10 opacity-0': !isVisible,
                'relative z-0 opacity-1': isVisible,
              })}
            >
              {children({
                tab,
                index: i,
                actions: { close: onClose, toggle: onToggle, isOpen },
              })}
            </div>
          );
        })}
      </div>

      {visibleCount !== tabs.length && (
        <div>
          <Popover
            isOpen={isOpen}
            positions={['bottom']}
            align="start"
            padding={12}
            containerClassName={cn('z-10', popoverContainerClasses)}
            content={
              <div
                ref={popoverRef}
                className={cn(
                  'p-2 bg-white rounded-2 shadow-md shadow-coolGray-300',
                  popoverMenuClasses,
                )}
                data-testid="popover-tabs-container"
              >
                {tabs.map((tab, index) => {
                  const isVisible = index + 1 > visibleCount;

                  if (renderPopoverTab) {
                    return (
                      <div
                        key={accessor(tab)}
                        className={cn({
                          'absolute -z-10 opacity-0': !isVisible,
                          'relative z-0 opacity-1': isVisible,
                        })}
                      >
                        {renderPopoverTab({
                          tab,
                          index,
                          actions: {
                            close: onClose,
                            toggle: onToggle,
                            isOpen,
                          },
                        })}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={accessor(tab)}
                      className={cn({
                        'absolute -z-10 opacity-0': !isVisible,
                        'relative z-0 opacity-1': isVisible,
                      })}
                    >
                      {children({
                        tab,
                        index,
                        actions: { close: onClose, toggle: onToggle, isOpen },
                      })}
                    </div>
                  );
                })}
              </div>
            }
          >
            <CustomComponent>
              {renderPopoverLabel ? (
                renderPopoverLabel({ isOpen, toggle: onToggle })
              ) : (
                <Button
                  testID="popover-tabs-more-btn"
                  type={isOpen ? 'loaded' : 'text'}
                  onClick={onToggle}
                >
                  <span className="flex items-center">
                    {t('menu.more')}
                    <Icon
                      name="ArrowDropDown"
                      className={cn('ml-2', {
                        'rotate-180': isOpen,
                      })}
                    />
                  </span>
                </Button>
              )}
            </CustomComponent>
          </Popover>
        </div>
      )}
    </div>
  );
}

export default PopoverTabs;
