import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Icon } from '@components/UI/Icon';
import { Store } from '@stores';

type Props = {
  children: ReactNode;
  width?: 'auto' | number;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
    Store.ErrorService.reactBoundaryException(error, errorInfo as any);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div style={{ width: this.props.width }} className="container mt-8">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className=" flex flex-wrap justify-center content-center items-center  z-0">
            <Icon name="Error" size="modal" fill="#123456" />
          </div>
          <div className=" flex flex-wrap justify-center content-end md:content-center items-end md:items-center relative z-10">
            <div className="p-6 text-center ">
              <h2 className="uppercase text-xl lg:text-5xl font-black">
                System Error
              </h2>
              <p className="mt-3 uppercase text-sm lg:text-base text-gray-900">
                You need Technical Administrator
              </p>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
