export const crumbs = {
  i18Info: (message?: string, data?: any): LoggerCrumb => ({
    level: 'info',
    category: 'i18n',
    message,
    data,
  }),
  wsLog: (message?: string, data?: any): LoggerCrumb => ({
    level: 'log',
    category: 'ws',
    message,
    data,
  }),
  permission: (message?: string, data?: any): LoggerCrumb => ({
    level: 'error',
    category: 'permission',
    message,
    data,
  }),
};

export const details = {
  syntaxError: ({
    tags,
    extra,
  }: Partial<LoggerErrorDetails>): LoggerErrorDetails => ({
    level: 'error',
    tags: { from: 'SyntaxError', ...tags },
    extra,
  }),
  wsDetails: ({
    tags,
    extra,
  }: Partial<LoggerErrorDetails>): LoggerErrorDetails => ({
    level: 'error',
    tags: { from: 'WebSocket', ...tags },
    extra,
  }),
  i18Details: ({
    extra,
    tags,
  }: Partial<LoggerErrorDetails>): LoggerErrorDetails => ({
    level: 'error',
    tags: { from: 'I18n', ...tags },
    extra,
  }),
  fetchDetails: ({
    extra,
    tags,
  }: Partial<LoggerErrorDetails>): LoggerErrorDetails => ({
    level: 'error',
    tags: { from: 'Fetch', ...tags },
    extra,
  }),
  errorBoundaryDetails: ({
    extra,
    tags,
  }: Partial<LoggerErrorDetails>): LoggerErrorDetails => ({
    level: 'fatal',
    tags: { from: 'Error Boundary', ...tags },
    extra,
  }),
};
