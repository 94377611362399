import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';
import { autorun } from 'mobx';
import { Store } from '@stores';

/**
 * Options: https://www.i18next.com/overview/configuration-options
 */
i18next
  .use(detector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    // debug: true,
    // lng: 'uk',
    returnNull: false,
    fallbackLng: 'uk',
    supportedLngs: ['uk', 'ru', 'en'],
    defaultNS: 'layout',
    ns: ['layout', 'modals'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${__COMMIT_HASH__}`,
    },
    detection: {
      lookupCookie: 'i18nextLng',
      lookupQuerystring: 'i18nextLng',
      cookieDomain: window.location.origin,
      order: [
        'localStorage',
        // 'querystring',
        // 'path',
        // 'htmlTag',
        'cookie',
        // 'header',
      ],
      caches: ['localStorage', 'cookie'], // cache user language on
    },
    interpolation: {
      format: (value, rawFormat = '', lng) => {
        const [format, ...additionalValues] = rawFormat
          .split(',')
          .map((v) => v.trim());
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'price':
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: additionalValues[0],
            }).format(value);
          default:
            return value;
        }
      },
    },
  })
  .then((t) => {
    autorun(() => {
      const { pathname } = Store.RoutingService.location;
      const routes = Store.RoutingService.config?.getRouteBy({
        field: 'path',
        value: pathname,
      });
      if (routes && routes.length && routes[0] && routes[0].name) {
        document.title = t(`layout:menu.${routes[0].name}`, 'Remis');
      }
    });
  });

i18next.on('languageChanged', (lng) => {
  Store.LoggerService.send.i18Crumb(`LANGUAGE CHANGED: [${lng}]`);
});

i18next.on('failedLoading', (lng, ns, msg) => {
  Store.ErrorService.i18Error(
    `i18next: FAILED LOADING RESOURCES; ${lng}: ${ns}`,
    {
      lng,
      ns,
      msg,
    },
  );
});

export default i18next;
