import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDanger = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_26983_114658)">
        <path
          d="M10 1.6665C5.40002 1.6665 1.66669 5.39984 1.66669 9.99984C1.66669 14.5998 5.40002 18.3332 10 18.3332C14.6 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6 1.6665 10 1.6665ZM10.8334 14.1665H9.16669V12.4998H10.8334V14.1665ZM10.8334 10.8332H9.16669V5.83317H10.8334V10.8332Z"
          fill="#DC2626"
        />
      </g>
      <defs>
        <clipPath id="clip0_26983_114658">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDanger;
