import { action, makeObservable, observable } from 'mobx';
import { ScheduleStaff } from '@modules/ScheduleStaff/mapper/ScheduleStaff';

export class ScheduleStaffStore {
  @observable public scheduleStaffList: ScheduleStaff[] = [];
  @observable public scheduleStaffEvents: ScheduleStaffEvent[] = [];
  @observable public availableExecutors: AvailableExecutor[] = [];
  @observable public loadingExecutors = true;

  constructor() {
    makeObservable(this);
  }

  @action
  public setScheduleStaff = (data: ScheduleStaffResponse[]): void => {
    this.scheduleStaffList = data?.length
      ? data.map((e) => new ScheduleStaff(e))
      : [];
  };

  @action
  public setScheduleStaffEvents = (data: ScheduleStaffEvent[]): void => {
    this.scheduleStaffEvents = data;
  };

  @action
  public setAvailableExecutors = (executors: AvailableExecutor[]): void => {
    this.availableExecutors = executors;
    this.setLoading(false);
  };

  @action
  public addScheduleStaff = (data: ScheduleStaffResponse): void => {
    const item = this.scheduleStaffList.find(
      (e) =>
        e.user.id === data.user.id &&
        e.cabinet.id === data.cabinet.id &&
        e.cabinet.health_facility.id === data.cabinet.health_facility.id,
    );
    if (item) {
      item.setEvents(data);
      return;
    }
    this.scheduleStaffList = [
      ...this.scheduleStaffList,
      new ScheduleStaff(data),
    ];
  };

  @action
  public updateScheduleStaff = (
    events: ScheduleStaffEvent[] = [],
    onlyConfirm?: boolean,
  ): void => {
    this.scheduleStaffList.forEach((item) => {
      events.forEach((event) => {
        if (
          item.user.id === event.user_id &&
          item.cabinet.health_facility.id === event.health_facility_id &&
          item.cabinet.id === event.cabinet_id
        ) {
          item.updateEvent(event, onlyConfirm);
        }
      });
    });
  };

  @action
  public deleteScheduleStaff = (events: ScheduleStaffEvent[]): void => {
    this.scheduleStaffList.forEach((item) => {
      events.forEach((event) => {
        if (
          item.user.id === event.user_id &&
          item.cabinet.health_facility.id === event.health_facility_id &&
          item.cabinet.id === event.cabinet_id
        ) {
          item.deleteEvent(event);
        }
      });
    });
  };

  @action
  public setLoading = (value: boolean) => {
    this.loadingExecutors = value;
  };

  @action
  public clearScheduleData = () => {
    this.scheduleStaffList = [];
    this.scheduleStaffEvents = [];
    this.availableExecutors = [];
    this.loadingExecutors = true;
  };
}
