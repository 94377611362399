import { BiomaterialStore } from '@modules/Biomaterial/biomaterial.store';

export class BiomaterialService extends BiomaterialStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  // BIOMATERIALS
  public getBiomaterialsReq = async (query?: GetBiomaterialsQuery) => {
    const res = await this._store.BiomaterialApi.getBiomaterials(query);
    if (res.success && res.data) {
      this.setBiomaterials(res.data, res.meta);
    }
    return res;
  };

  public getBiomaterialReq = async (id: number) => {
    const res = await this._store.BiomaterialApi.getBiomaterial(id);
    if (res.success && res.data) {
      this.setBiomaterial(res.data);
    }
    return res;
  };

  // BIOMATERIAL TYPES
  public getBiomaterialTypesReq = async (query?: GetBiomaterialTypesQuery) => {
    const res = await this._store.BiomaterialApi.getBiomaterialTypes(query);
    if (res.success && res.data) {
      this.setTypes(res.data, res.meta);
    }
    return res;
  };

  public getBiomaterialTypeReq = async (id: number) => {
    const res = await this._store.BiomaterialApi.getBiomaterialType(id);
    if (res.success && res.data) {
      this.setType(res.data);
    }
    return res;
  };

  public createBiomaterialTypeReq = async (
    data: CreateBiomaterialTypeParams,
  ) => {
    const res = await this._store.BiomaterialApi.createBiomaterialType(data);
    if (res.success && res.data) {
      this.addType(res.data);
    }
    return res;
  };

  public updateBiomaterialTypeReq = async (
    id: number,
    data: UpdateBiomaterialTypeParams,
  ) => {
    const res = await this._store.BiomaterialApi.updateBiomaterialType(
      id,
      data,
    );
    if (res.success && res.data) {
      this.updateType(res.data);
    }
    return res;
  };

  public deleteBiomaterialTypeReq = async (id: number) => {
    const res = await this._store.BiomaterialApi.deleteBiomaterialType(id);
    if (res.success) {
      this.removeType(id);
    }
    return res;
  };
}
