import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutlinelaboratoryManagement = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={20}
      fill="none"
      viewBox="0 0 18 20"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 4h-2.64L16.5.86 14.15 0l-1.46 4H0v2l2 6-2 6v2h18v-2l-2-6 2-6zm-3.9 8.63L15.89 18H2.11l1.79-5.37.21-.63-.21-.63L2.11 6h13.78l-1.79 5.37-.21.63zM10 8H8v3H5v2h3v3h2v-3h3v-2h-3z"
      />
    </svg>
  );
};
export default SvgOutlinelaboratoryManagement;
