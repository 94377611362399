import React, {
  createElement,
  CSSProperties,
  memo,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import useOutsideClick from '@utils/hooks/useOutsideClick';
import cn from 'classnames';
import './OverlayPanel.scss';
import Portal from '@components/UI/Portal';

export type OverlayOptions = {
  isOpen: boolean;
  open: (event: SyntheticEvent) => void;
  close: () => void;
  toggle: (event?: SyntheticEvent) => void;
};

export type OverlayPanelProps = {
  trigger: (options: OverlayOptions) => ReactNode;
  panel: (options: OverlayOptions) => React.JSX.Element;
  isOpenMenu?: boolean;
  position?: 'left' | 'right' | 'center';
  disabled?: boolean;
  top?: number;
  portal?: boolean;
  ref?: any;
  preventClose?: boolean;
  className?: string;
  style?: CSSProperties | undefined;
};

function OverlayPanel({
  top,
  panel,
  trigger,
  position,
  className,
  disabled = false,
  portal = false,
  preventClose = false,
  style,
  isOpenMenu = false,
}: OverlayPanelProps) {
  const [isOpen, setOpen] = useState(isOpenMenu);
  const [coords, setCoords] = useState({});

  const ref = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);

  const close = useCallback(() => {
    if (preventClose) return;
    if (isOpen) {
      setOpen(false);
    }
  }, [isOpen, preventClose]);

  useOutsideClick(ref, close);

  const updateTooltipCoords = useCallback(
    (timeTooltip: HTMLDivElement | null) => {
      const rect = timeTooltip?.getBoundingClientRect();
      if (!rect) return;
      setCoords({
        left: rect.x + rect.width / 2 + 15,
        top: rect.y + window.scrollY,
      });
    },
    [],
  );

  const toggle = useCallback(() => {
    if (disabled) return;
    setOpen(!isOpen);
    if (portal) {
      updateTooltipCoords(triggerRef.current);
    }
  }, [disabled, isOpen, portal, updateTooltipCoords]);

  const open = useCallback(() => {
    if (!isOpen && !disabled) {
      setOpen(true);
      if (portal) {
        updateTooltipCoords(triggerRef.current);
      }
    }
  }, [disabled, isOpen, portal, updateTooltipCoords]);

  const Panel = useMemo(
    () => (
      <CSSTransition
        style={{ ...style, top: top ? `${top}px` : style?.top }}
        in={isOpen}
        timeout={300}
        classNames={cn(position, 'panel animate')}
        unmountOnExit
      >
        {portal ? (
          <Portal>
            <div style={{ ...coords }} className="tooltip-popover portal">
              {panel && createElement(panel, { isOpen, open, close, toggle })}
            </div>
          </Portal>
        ) : (
          <div>{panel({ isOpen, open, close, toggle })}</div>
        )}
      </CSSTransition>
    ),
    [close, coords, isOpen, open, panel, portal, position, style, toggle, top],
  );

  return (
    <div
      ref={ref}
      data-testid="overlay-panel"
      className={cn(['overlay-panel', className], { open: isOpen })}
    >
      <div className="trigger" ref={triggerRef}>
        {trigger({ isOpen, open, close, toggle })}
      </div>
      {Panel}
    </div>
  );
}

export default memo(OverlayPanel);
