import { CompletedAppointmentStore } from './appointment.store';

export class CompletedAppointmentBridge {
  constructor(
    protected _store: CompletedAppointmentStore,
    protected _storeId: string,
  ) {}

  public getAppointments = (data: WsResponse<AppointmentType[]>) => {
    if (
      data.result.success &&
      data.storage &&
      data.storage.id &&
      data.storage.id === this._storeId
    ) {
      this._store.setAppointments(data.result.data);
    }
  };

  public getAppointment = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.setAppointment(data.result.data);
    }
  };

  public updateAppointment = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.updateAppointment(data.result.data);
    }
  };

  public changeStatus = (data: WsResponse<AppointmentType>) => {
    if (data.result.success) {
      this._store.changeStatus(data.result.data);
    }
  };
}
