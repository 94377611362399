import { HealthFacilityFilterState as healthFacilityGlobal } from '@modules/Filters';
import { action, autorun, computed, makeAutoObservable } from 'mobx';

type CalendarView = 'ScheduleTable' | 'CalendarTable';
type DateFilter = {
  start_date: Date;
  end_date: Date;
};

const DEFAULT_COMPONENT = 'CalendarTable';
const COMPONENT_KEY = 'ACTIVE_CALENDAR_TYPE';
const DOCTOR_KEY = 'CALENDAR_DOCTOR_FILTER';

export class CalendarFilter {
  public activeComponent: CalendarView;
  public healthFacilityFilter: HealthFacility | null;
  public dateFilter: DateFilter;
  public cabinetsFilter: CabinetResponse | null = null;
  public doctorsFilter: UserResponse | AppointmentUser | null = null;
  public specFilter: Specialization | null = null;

  constructor(protected _store: Store) {
    this.activeComponent = DEFAULT_COMPONENT;
    this.healthFacilityFilter = healthFacilityGlobal.healthFacility;
    this.dateFilter = {} as DateFilter;

    makeAutoObservable(this);
    autorun(() => {
      this.setHealthFacilityLocal(healthFacilityGlobal.healthFacility);
    });
  }

  @computed
  public get filters() {
    const { dateToUnix } = this._store.DateService;

    const query: GetCalendarTableReq = {
      start_time: dateToUnix(this.dateFilter.start_date),
      end_time: dateToUnix(this.dateFilter.end_date),
      appointment_type: 'outpatient',
    };
    if (this.healthFacilityFilter) {
      query.health_facility_ids = [this.healthFacilityFilter.id];
    }
    if (this.cabinetsFilter) {
      query.cabinet_ids = [this.cabinetsFilter.id];
    }
    if (this.specFilter) {
      query.specialization_ids = [this.specFilter.id];
    }
    if (this.doctorsFilter) {
      query.user_ids = [this.doctorsFilter.id];
    }
    return query;
  }

  @action
  public init = () => {
    const { LStorage } = this._store.StorageService;

    this.setDataRange(new Date(), new Date());
    const component = LStorage.getItem<CalendarView>(COMPONENT_KEY);
    const doctor = LStorage.getItem<UserResponse | AppointmentUser>(
      DOCTOR_KEY,
      true,
    );

    this.activeComponent = component ?? DEFAULT_COMPONENT;

    if (doctor && doctor.id) {
      this.doctorsFilter = doctor;
    }
  };

  @action
  public reset = () => {
    // Uses for manual resetting of filters  ( Reset button )
    // Use this function when you need to sync filters values with localStorage
    this.setDataRange(new Date(), new Date());
    this.setHealthFacilityLocal(healthFacilityGlobal.healthFacility);
    this.setCabinetsFilter(null);
    this.setDoctorFilter(null);
    this.setSpecializationsFilter(null);
  };

  @action
  public clear = () => {
    // Uses for clearing filters when component is unmounting

    this.setDataRange(new Date(), new Date());
    this.cabinetsFilter = null;
    this.doctorsFilter = null;
    this.specFilter = null;
  };

  @action
  public setComponent = (component: CalendarView) => {
    const { LStorage } = this._store.StorageService;

    this.activeComponent = component;
    LStorage.setItem(COMPONENT_KEY, component);
  };

  @action
  public setDataRange = (start: Date, end: Date) => {
    const { createEndDay, createStartDay } = this._store.DateService;

    this.dateFilter = {
      start_date: createStartDay(start).toDate(),
      end_date: createEndDay(end).toDate(),
    };
  };

  @action
  public setHealthFacilityLocal = (selected: HealthFacility | null) => {
    this.healthFacilityFilter = selected;
  };

  @action
  public setHealthFacilityGlobal = (selected: HealthFacility | null) => {
    healthFacilityGlobal.setHealthFacility(selected);
  };

  @action
  public setCabinetsFilter = (selected: CabinetResponse | null) => {
    this.cabinetsFilter = selected;
  };

  @action
  public setDoctorFilter = (
    selected: UserResponse | AppointmentUser | null,
  ) => {
    const { LStorage } = this._store.StorageService;

    this.doctorsFilter = selected;
    LStorage.setItem(DOCTOR_KEY, selected);
  };

  @action
  public setSpecializationsFilter = (selected: Specialization | null) => {
    this.specFilter = selected;
  };
}
