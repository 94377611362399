import DrawerStore from '@modules/Drawer/drawer.store';
import { drawerComponent } from '@modules/Drawer/registeration';

class DrawerService extends DrawerStore {
  public get drawer() {
    const actions: DrawerActions = {
      close: this.close,
      showError: this.showError,
      clearError: this.clearError,
    };

    return {
      actions,
      config: this,
      default: (props: DrawerDefaultProps) => {
        this._open(drawerComponent.default(props, actions));
      },
      createUser: () => {
        this._open(drawerComponent.createUser(actions));
      },
      tableColumnSettingsDrawer: (props: DrawerTableColumnSettings) => {
        this._open(drawerComponent.tableColumnSettingsDrawer(props, actions));
      },
      createRole: () => {
        this._open(drawerComponent.createRole(actions));
      },
      createSpecialization: () => {
        this._open(drawerComponent.createSpecialization(actions));
      },
      createPosition: () => {
        this._open(drawerComponent.createPosition(actions));
      },
      createHFT: () => {
        this._open(drawerComponent.createHFT(actions));
      },
      createHealthFacility: () => {
        this._open(drawerComponent.createHealthFacility(actions));
      },
      createCabinetType: () => {
        this._open(drawerComponent.createCabinetType(actions));
      },
      createCabinet: (props?: DrawerCreateCabinetProps) => {
        this._open(drawerComponent.createCabinet(actions, props));
      },
      createService: () => {
        this._open(drawerComponent.createService(actions));
      },
      createServicePerformer: () => {
        this._open(drawerComponent.createServicePerformer(actions));
      },
      createServiceCategory: (props?: DrawerCreateServiceCategory) => {
        this._open(drawerComponent.createServiceCategory(actions, props));
      },
      addServiceUser: () => {
        this._open(drawerComponent.addServiceUser(actions));
      },
      addServiceCabinet: (props: DrawerAddCabinetServiceProps) => {
        this._open(drawerComponent.addServiceCabinet(actions, props));
      },
      addServiceRelatedService: (props: DrawerAddRelatedServiceProps) => {
        this._open(drawerComponent.addServiceRelatedService(actions, props));
      },
      createScheduleEvents: (props: DrawerCreateScheduleEvents) => {
        this._open(drawerComponent.createScheduleEvents(actions, props));
      },
      updateScheduleInterval: (props: DrawerCreateScheduleEvents) => {
        this._open(drawerComponent.createScheduleEvents(actions, props));
      },
      addUserService: (data: DrawerAddUserServiceProps) => {
        this._open(drawerComponent.addUserService(actions, data));
      },
      addUserLabService: (data: DrawerAddUserLabServiceProps) => {
        this._open(drawerComponent.addUserLabService(actions, data));
      },
      createPatient: (props?: DrawerCreatePatientProps) => {
        this._open(drawerComponent.createPatient(actions, props));
      },
      addServicesToReception: (props: DrawerAddServiceToReceptionProps) => {
        this._open(drawerComponent.addServicesToReception(actions, props));
      },
      addServicesToOrder: (props: DrawerAddServiceToOrderProps) => {
        this._open(drawerComponent.addServicesToOrder(actions, props));
      },
      createCalendarEvent: (props: DrawerCreateAppointmentProps) => {
        this._open(drawerComponent.createCalendarEvent(props, actions));
      },
      createCalendarEventEdit: (props: DrawerCreateAppointmentProps) => {
        this._open(drawerComponent.createCalendarEventEdit(props, actions));
      },
      createPrice: () => {
        this._open(drawerComponent.createPrice(actions));
      },
      addUserRole: () => {
        this._open(drawerComponent.addUserRole(actions));
      },
      addIUModuleToRole: () => {
        this._open(drawerComponent.addIUModuleToRole(actions));
      },
      addEntityToRole: () => {
        this._open(drawerComponent.addEntityToRole(actions));
      },
      addUserToRole: () => {
        this._open(drawerComponent.addUserToRole(actions));
      },
      importPriceById: (props?: DrawerImportPriceProps) => {
        this._open(drawerComponent.importPriceById(actions, props));
      },
      addServiceToDesktopEvent: (
        props: DrawerAddServiceToDesktopEventProps,
      ) => {
        this._open(drawerComponent.addServiceToDesktopEvent(actions, props));
      },
      addServicesToCabinet: (props: DrawerAddServiceToCabinetProps) => {
        this._open(drawerComponent.addServicesToCabinet(actions, props));
      },
      addServicesToPrice: (props: DrawerAddServiceToPriceProps) => {
        this._open(drawerComponent.addServicesToPrice(actions, props));
      },
      createDocElementGroup: () => {
        this._open(drawerComponent.createDocElementGroup(actions));
      },
      createDocChapters: () => {
        this._open(drawerComponent.createDocChapters(actions));
      },
      createDocChapterGroup: () => {
        this._open(drawerComponent.createDocChapterGroup(actions));
      },
      createDocTemplates: () => {
        this._open(drawerComponent.createDocTemplates(actions));
      },
      createDocElement: (
        callback?: DrawerToggleDocElementsProps['onCrudAction'],
      ) => {
        this._open(drawerComponent.createDocElement(actions, callback));
      },
      createDocElementList: (props?: DrawerCreateDocElementProps) => {
        this.close();
        this._open(drawerComponent.createDocElementList(actions, props));
      },
      createDocElementString: (props?: DrawerCreateDocElementProps) => {
        this.close();
        this._open(drawerComponent.createDocElementString(actions, props));
      },
      createDocElementNumber: (props?: DrawerCreateDocElementProps) => {
        this.close();
        this._open(drawerComponent.createDocElementNumber(actions, props));
      },
      createDocElementMKX: (props?: DrawerCreateDocElementProps) => {
        this.close();
        this._open(drawerComponent.createDocElementMKX(actions, props));
      },
      createDocElementICPC2: (props?: DrawerCreateDocElementProps) => {
        this.close();
        this._open(drawerComponent.createDocElementICPC2(actions, props));
      },
      addDocChapterElements: (data: DrawerToggleDocElementsProps) => {
        this._open(drawerComponent.addDocChapterElements(actions, data));
      },
      createDocTemplateGroup: () => {
        this._open(drawerComponent.createDocTemplateGroup(actions));
      },
      addDocTemplateChapters: () => {
        this._open(drawerComponent.addDocTemplateChapters(actions));
      },
      addDocToService: () => {
        this._open(drawerComponent.addDocToService(actions));
      },
      createMedCardType: () => {
        this._open(drawerComponent.createMedCardType(actions));
      },
      createMedCard: (medCard?: MedCard) => {
        this._open(drawerComponent.createMedCard(actions, medCard));
      },
      createMedEpisode: (props: DrawerCreateMedEpisodeProps) => {
        this._open(drawerComponent.createMedEpisode(actions, props));
      },
      addMedDiagnose: () => {
        this._open(drawerComponent.addMedDiagnose(actions));
      },
      createDocument: (props?: DrawerCreateDocumentProps) => {
        this._open(drawerComponent.createDocument(actions, props));
      },
      createUrgentEvent: (props: DrawerCreateAppointmentProps) => {
        this._open(drawerComponent.createUrgentEvent(props, actions));
      },
      viewAppointmentInfo: (props: ViewAppointmentHistoryProps) => {
        this._open(drawerComponent.viewAppointmentInfo(props, actions));
      },
      createInsuranceCompany: () => {
        this._open(drawerComponent.createInsuranceCompany(actions));
      },
      createGuarantor: (props: CreateCompanyGuarantorProps) => {
        this._open(drawerComponent.createGuarantor(props, actions));
      },
      createInsurancePolicy: (props: CreateInsurancePolicyProps) => {
        this._open(drawerComponent.createInsurancePolicy(props, actions));
      },
      createGuarantee: (props: CreateGuaranteeProps) => {
        this._open(drawerComponent.createGuarantee(props, actions));
      },
      createConsumable: () => {
        this._open(drawerComponent.createConsumable(actions));
      },
      createConsumableType: () => {
        this._open(drawerComponent.createConsumableType(actions));
      },
      createBiomaterialType: (props?: DrawerCreateBiomaterialType) => {
        this._open(drawerComponent.createBiomaterialType(actions, props));
      },
      addLabServiceToAnalysis: (props: AddLabServiceToAnalysisProps) => {
        this._open(drawerComponent.addLabServiceToAnalysis(props, actions));
      },
      analysisDetailed: (props: DrawerAnalysisDetailedProps) => {
        this._open(drawerComponent.analysisDetailed(actions, props));
      },
      createTown: (props: DrawerCreateTownProps) => {
        this._open(drawerComponent.createTown(actions, props));
      },
      createUserDocTemplate: () => {
        this._open(drawerComponent.createUserDocTemplate(actions));
      },
      xReport: (props?: DrawerXReportProps) => {
        this._open(drawerComponent.xReport(actions, props));
      },
      zReport: (props?: DrawerZReportProps) => {
        this._open(drawerComponent.zReport(actions, props));
      },
      officialRemoval: (props: DrawerOfficialRemovalProps) => {
        this._open(drawerComponent.officialRemoval(actions, props));
      },
      officialEntry: (props: DrawerOfficialEntryProps) => {
        this._open(drawerComponent.officialEntry(actions, props));
      },
      createCertificate: () => {
        this._open(drawerComponent.createCertificate(actions));
      },
      editCertificate: (props: DrawerEditCertificateProps) => {
        this._open(drawerComponent.editCertificate(actions, props));
      },
      createCashPlace: () => {
        this._open(drawerComponent.createCashPlace(actions));
      },
      refundDrawer: (props: RefundDrawerProps) => {
        this._open(drawerComponent.refundDrawer(actions, props));
      },
      orderInfo: (props: OrderInfoDrawerProps) => {
        this._open(drawerComponent.orderInfo(actions, props));
      },
      createLabService: (props: DrawerCreateLabServiceProps) => {
        this._open(drawerComponent.createLabService(actions, props));
      },
      createClinicService: (props: DrawerCreateClinicServiceProps) => {
        this._open(drawerComponent.createClinicService(actions, props));
      },
      createCollectionService: (props: DrawerCreateCollectionServiceProps) => {
        this._open(drawerComponent.createCollectionService(actions, props));
      },
      addConsumable: (props: DrawerAddConsumablesDrawer) => {
        this._open(drawerComponent.addConsumable(actions, props));
      },
      createPreferentialCategory: () => {
        this._open(drawerComponent.createPreferentialCategory(actions));
      },
      addPreferentialService: (props: DrawerAddPreferentialServiceProps) => {
        this._open(drawerComponent.addPreferentialService(actions, props));
      },
      createServiceProperty: (props?: DrawerCreateServicePropertyProps) => {
        this._open(drawerComponent.createServiceProperty(actions, props));
      },
      createServicePropertyNumber: (
        props?: DrawerCreateServicePropertyProps,
      ) => {
        this.close();
        this._open(drawerComponent.createServicePropertyNumber(actions, props));
      },
      createServicePropertyText: (props?: DrawerCreateServicePropertyProps) => {
        this.close();
        this._open(drawerComponent.createServicePropertyText(actions, props));
      },
      createServicePropertySelect: (
        props?: DrawerCreateServicePropertyProps,
      ) => {
        this.close();
        this._open(drawerComponent.createServicePropertySelect(actions, props));
      },
      createServicePropertyCalc: (props?: DrawerCreateServicePropertyProps) => {
        this.close();
        this._open(drawerComponent.createServicePropertyCalc(actions, props));
      },
      createServicePropertySeparator: (
        props?: DrawerCreateServicePropertyProps,
      ) => {
        this.close();
        this._open(
          drawerComponent.createServicePropertySeparator(actions, props),
        );
      },
      addPropertyToService: (props: DrawerAddPropertyToServiceProps) => {
        this._open(drawerComponent.addPropertyToService(actions, props));
      },
      refIntervalsNumber: (props: DrawerRefIntervalsProps) => {
        this._open(drawerComponent.refIntervalsNumber(actions, props));
      },
      refIntervalsText: (props: DrawerRefIntervalsProps) => {
        this._open(drawerComponent.refIntervalsText(actions, props));
      },
      createDirection: (props: DrawerCreateDirectionProps) => {
        this._open(drawerComponent.createDirection(actions, props));
      },
      uploadArchiveDoc: (props: DrawerUploadArchiveDocProps) => {
        this._open(drawerComponent.uploadArchiveDoc(actions, props));
      },
      startAppointment: (props: DrawerStartAppointmentProps) => {
        this._open(drawerComponent.startAppointment(actions, props));
      },
      combineOrders: (props: DrawerCombineOrdersProps) => {
        this._open(drawerComponent.combineOrders(actions, props));
      },
      insurancePriceServices: (props: DrawerInsurancePriceServicesProps) => {
        this._open(drawerComponent.insurancePriceServices(actions, props));
      },
      settingLabWorkplace: (storageKey: StorageKey) => {
        this._open(drawerComponent.settingLabWorkplace(actions, storageKey));
      },
      sortSampleByBarcode: (props: DrawerSortSamplesByBarcodeProps) => {
        this._open(drawerComponent.sortSampleByBarcode(actions, props));
      },
      createDevice: (props: DrawerDeviceProps) => {
        this._open(drawerComponent.createDevice(actions, props));
      },
      createDocDirection: (props: DrawerCreateDocDirectionProps) => {
        this._open(drawerComponent.createDocDirection(actions, props));
      },
      editDocDirection: (props: DrawerEditDocDirectionProps) => {
        this._open(drawerComponent.editDocDirection(actions, props));
      },
      patientDailyPayments: (props: DrawerPatientDailyPaymentsProps) => {
        this._open(drawerComponent.patientDailyPayments(actions, props));
      },
      confirmGuarantee: (props: DrawerConfirmGuaranteeProps) => {
        this._open(drawerComponent.confirmGuarantee(actions, props));
      },
      chooseGuarantee: (props: DrawerChooseGuaranteeProps) => {
        this._open(drawerComponent.chooseGuarantee(actions, props));
      },
      createDirectionWithGuarantee: (
        props: DrawerCreateDirectionWithGuaranteeProps,
      ) => {
        this._open(
          drawerComponent.createDirectionWithGuarantee(actions, props),
        );
      },
      replaceService: (props: DrawerReplaceServiceProps) => {
        this._open(drawerComponent.replaceService(actions, props));
      },
      createNotExistImportData: (props: DrawerCreateNotExistImportData) => {
        this._open(drawerComponent.createNotExistImportData(actions, props));
      },
      addConfidantPerson: () => {
        this._open(drawerComponent.addPatientConfidant(actions));
      },
      addConfidantPersonFor: () => {
        this._open(drawerComponent.addConfidantPersonFor(actions));
      },
      createPayer: (props: DrawerCreatePayerProps) => {
        this._open(drawerComponent.createPayer(actions, props));
      },
      createCounterparty: (props: DrawerCreateCounterpartyProps) => {
        this._open(drawerComponent.createCounterparty(actions, props));
      },
      createReferralDoctor: (props: DrawerCreateReferralDoctorProps) => {
        this._open(drawerComponent.createReferralDoctor(actions, props));
      },
      combinePatients: (props: DrawerCombinePatientsProps) => {
        this._open(drawerComponent.combinePatients(actions, props));
      },
      bindingRemedEpisode: () => {
        this._open(drawerComponent.bindingRemedEpisode(actions));
      },
      onlineRecordsDrawer: () => {
        this._open(drawerComponent.onlineRecordsDrawer(actions));
      },
      addScheduledCall: () => {
        this._open(drawerComponent.addScheduledCall(actions));
      },
      addServiceToCollectionPoint: (data: DrawerServiceToColPointProps) => {
        this._open(drawerComponent.addServiceToCollectionPoint(actions, data));
      },
      onlineVisitsTip: () => {
        this._open(drawerComponent.onlineVisitTip(actions));
      },
      onSendAllBioResults: (data: DrawerOnSendAllBioResults) => {
        this._open(drawerComponent.onSendAllBioResults(actions, data));
      },
      createServicePackage: (data?: PackageService) => {
        this._open(drawerComponent.createServicePackage(actions, data));
      },
      addServiceToPackage: (props?: DrawerAddServiceToPackage) => {
        this._open(drawerComponent.addServiceToPackage(actions, props));
      },
      addPackageServiceToPatient: (data: DrawerAddServicePackageToPatient) => {
        this._open(drawerComponent.addPackageServiceToPatient(actions, data));
      },
      addPackageServiceOnCalendar: () => {
        this._open(drawerComponent.addPackageServiceOnCalendar(actions));
      },
      addPackageServiceOnWorkplace: () => {
        this._open(drawerComponent.addPackageServiceOnWorkplace(actions));
      },
      addPackageServiceOnLabPlanning: (
        data: DrawerAddPackageServiceToLabProps,
      ) => {
        this._open(
          drawerComponent.addPackageServiceOnLabPlanning(actions, data),
        );
      },
    };
  }

  public close = (): void => {
    if (!this.isOpen) return;
    if (this.disableClose) return;
    this.setOpen(false);
    this.clearError();
  };

  private _open = (options: DrawerProps): void => {
    if (this.isOpen) return;
    this.setOpen(true);
    this.setDrawerProps(options);
  };
}

export default new DrawerService();
