import { action, makeObservable, observable } from 'mobx';

export class SalaryStore {
  @observable
  public userSalary: UserSalary | null = null;

  @observable public userSalariesTotal = 0;
  @observable public usersSalaries: MonthlyReport[] = [];
  @observable public usersSalariesMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  @observable public userDetailedSalaryTotal = 0;
  @observable public userSalaryDetailed: MonthlyReportDetailed[] = [];
  @observable public userSalaryDetailedMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setUserSalary = (data: UserSalary | null) => {
    this.userSalary = data;
  };

  @action
  public updateUserSalaryCommissions = (data: UserSalaryCommission[]) => {
    if (this.userSalary?.commissions) {
      this.userSalary.commissions = data;
    }
  };

  @action
  public setUsersSalaries = (
    data: GetMonthlySalaryReportRes,
    meta: ResponseMeta,
  ) => {
    this.usersSalaries = data.report;
    this.userSalariesTotal = data.total;
    this.usersSalariesMeta = meta;
  };

  @action
  public setUserSalaryDetailed = (
    data: GetUserSalaryReportRes,
    meta: ResponseMeta,
  ) => {
    this.userSalaryDetailed = data.report;
    this.userDetailedSalaryTotal = data.total_commission;
    this.userSalaryDetailedMeta = meta;
  };

  @action
  public clearUserSalaryDetailed = () => {
    this.userSalaryDetailed = [];
    this.userDetailedSalaryTotal = 0;
    this.userSalaryDetailedMeta = {
      totalPages: 0,
      totalRecords: 0,
    };
  };
}
