import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEarth = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.334 8.333 8.334S17.333 13.6 17.333 9 13.6.667 9 .667m-.833 14.942A6.657 6.657 0 0 1 2.333 9c0-.516.067-1.008.175-1.491L6.5 11.5v.834C6.5 13.25 7.25 14 8.167 14zm5.75-2.117a1.65 1.65 0 0 0-1.584-1.158H11.5v-2.5A.836.836 0 0 0 10.667 9h-5V7.334h1.666a.836.836 0 0 0 .834-.834V4.834h1.666c.917 0 1.667-.75 1.667-1.667v-.342c2.442.992 4.167 3.384 4.167 6.175a6.63 6.63 0 0 1-1.75 4.492"
      />
    </svg>
  );
};
export default SvgEarth;
