import { computed, makeObservable } from 'mobx';
import { SchedulerListener } from './listener';
import { SchedulerStore } from './store';

export class Scheduler extends SchedulerListener {
  public store: SchedulerStore;

  constructor(protected _store: Store) {
    super(_store);
    this.store = new SchedulerStore();
    makeObservable(this);
  }

  @computed
  public get columns() {
    const { getDiffDates, formatDate } = this._store.DateService;
    const { dateFilter } = this._store.CalendarService.calendarFilter;

    return getDiffDates(dateFilter.start_date, dateFilter.end_date).map(
      (e) => ({
        header: formatDate(e, 'DD.MMM.dd').toUpperCase(),
        accessor: formatDate(e, 'YYYY_MM_DD'),
      }),
    );
  }

  public getIntervals = () => {
    const { schedule } = this._store.WsApi;
    const { formatDate, dateToUnix } = this._store.DateService;
    const { calendarStore, calendarFilter } = this._store.CalendarService;

    const query: GetAvailableIntervalsReq = {
      start_date: formatDate(
        calendarFilter.dateFilter.start_date,
        'YYYY_MM_DD',
      ),
      end_date: formatDate(calendarFilter.dateFilter.end_date, 'YYYY_MM_DD'),
      cabinet_ids: calendarFilter.filters.cabinet_ids,
      health_facility_ids: calendarFilter.filters.health_facility_ids,
      specialization_ids: calendarFilter.filters.specialization_ids,
      user_ids: calendarFilter.filters.user_ids,
      from_timestamp: dateToUnix(new Date()),
    };

    if (calendarStore.candidateQuery) {
      query.appointment_candidates = calendarStore.candidateQuery;
    }

    schedule.getAvailableIntervals(query);
  };
}
