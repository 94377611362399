import { action, makeObservable, observable } from 'mobx';

export class OperationReportStore {
  @observable public operationReport: CashPlaceOperation.Report[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setOperationReport = (data: CashPlaceOperation.Report[]) => {
    this.operationReport = data;
  };
}
