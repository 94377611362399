import React, { CSSProperties, memo } from 'react';
import './Avatar.scss';
import cx from 'classnames';
import { useService } from '@stores/hooks';

export type AvatarProps = {
  name: string;
  className?: string;
  image?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onlyInitials?: boolean;
};

function Avatar({
  name,
  image,
  className,
  onlyInitials = false,
  ...rest
}: AvatarProps) {
  const { getNameInitials } = useService('StringService');

  return (
    <div className={cx(['rm-avatar', className], { image })} {...rest}>
      <div className="image">
        {image && <img src={image} alt={name} />}
        {!image && <span>{getNameInitials(name)}</span>}
      </div>
      {!onlyInitials && <div className="name">{name}</div>}
    </div>
  );
}

export default memo(Avatar);
