import { action, computed, makeObservable, observable } from 'mobx';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { DoctorVisitAdapter } from '@modules/DoctorWorkplace/adapter';

export class DoctorWorkplaceState {
  protected _store: Store;
  public defaultDate = new Date();

  @observable public initLoading = false;
  @observable private _events: DoctorWorkplace.VisitEvent[] = [];
  @observable public selectedEvent: DoctorWorkplace.VisitEvent | null = null;
  @observable public activeEvent?: DoctorWorkplace.VisitEvent | null = null;
  @observable public filterDate: Date = this.defaultDate;

  constructor(store: Store) {
    makeObservable(this);
    this._store = store;
  }

  @action
  public reset = () => {
    this.filterDate = this.defaultDate;
    this._events = [];
    this.selectedEvent = null;
  };

  @action
  public setInitLoading = (value: boolean) => {
    this.initLoading = value;
  };

  @computed
  public get _eventsQuery(): GetAppointmentsReq {
    const { createStartDay, createEndDay } = this._store.DateService;
    const { profile } = this._store.ProfileService;
    return {
      appointment_type: 'outpatient',
      start_time: createStartDay(this.filterDate).unix(),
      end_time: createEndDay(this.filterDate).unix(),
      user_ids: profile ? [profile.id] : undefined,
      statuses: ['planned', 'in_clinic', 'active_visit', 'on_payment', 'done'],
    };
  }

  @computed
  public get events() {
    const statusList: AppointmentStatus[] = [
      'planned',
      'in_clinic',
      'active_visit',
    ];
    const [first, last] = partition(this._events, (e) =>
      statusList.includes(e.visit.status),
    );
    return [
      ...orderBy(first, (value) => value.visit.start_time),
      ...orderBy(last, (value) => value.visit.start_time),
    ];
  }

  @computed
  public get event() {
    return this.selectedEvent?.visit.id === this.activeEvent?.visit.id
      ? (this.activeEvent as DoctorWorkplace.VisitEvent)
      : (this.selectedEvent as DoctorWorkplace.VisitEvent);
  }

  @computed
  public get canActionEvent() {
    const { isToday } = this._store.DateService;
    if (!this.selectedEvent || !isToday(this.selectedEvent.visit.date)) {
      return false;
    }
    if (this.selectedEvent.visit.id !== this.activeEvent?.visit.id) {
      return false;
    }
    return this.selectedEvent.visit.status === 'active_visit';
  }

  @action
  public setEvents = (events: AppointmentType[]) => {
    this._events = events.map((e) => new DoctorVisitAdapter(e));
  };

  @action
  public setFilterDate = (date: Date) => {
    const { isValidDate } = this._store.DateService;
    if (isValidDate(date)) this.filterDate = date;
  };

  @action
  public setSelectedEvent = (
    event: DoctorWorkplace.VisitEvent | AppointmentType,
  ) => {
    if (!(event instanceof DoctorVisitAdapter)) {
      event = new DoctorVisitAdapter(event);
    }
    this.selectedEvent = event;
  };

  @action
  public setSelectedEventById = (id: string | number) => {
    const eventId = Number(id);
    if (isNaN(eventId)) return;
    const event = this.events.find((e) => e.visit.id === eventId) ?? null;
    if (event) this.selectedEvent = event;
  };

  @action
  public addEvent = (event: AppointmentType) => {
    this._events = this._events.concat(new DoctorVisitAdapter(event));
  };

  @action
  public removeEvent = (id: number) => {
    this._events = this._events.filter((e) => e.visit.id !== id);
  };

  @action
  public setActiveEvent = (
    event: DoctorVisitAdapter,
    episodes: MedEpisode[],
    episode: MedEpisode,
  ) => {
    this.activeEvent = event;
    this.activeEvent.startVisit(episodes, episode);
  };

  @action
  public endActiveEvent = () => {
    this.activeEvent?.endVisit();
    this.activeEvent = null;
  };

  @action
  public updateEvent = (updated: AppointmentType) => {
    const event = new DoctorVisitAdapter(updated);

    this._events = this._events.map((it) =>
      it.visit.id === updated.id ? event : it,
    );
    if (this.activeEvent?.visit.id === updated.id) {
      this.activeEvent.updateVisit(updated);
    }
    if (this.selectedEvent?.visit.id === updated.id) {
      this.selectedEvent.updateVisit(updated);
    }
  };

  @action
  public changeEventStatus = (updated: AppointmentType) => {
    this.updateEvent(updated);

    const STOP_STATUSES: AppointmentStatus[] = [
      'done',
      'canceled',
      'on_payment',
      'patient_did_not_come',
    ];
    if (STOP_STATUSES.includes(updated.status)) {
      if (this.activeEvent?.visit.id === updated.id) {
        this.endActiveEvent();
      }
    }

    const REMOVE_STATUSES: AppointmentStatus[] = [
      'canceled',
      'patient_did_not_come',
    ];
    if (REMOVE_STATUSES.includes(updated.status)) {
      this.removeEvent(updated.id);
    }
  };
}
