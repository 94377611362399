import { FetchService } from '@modules/Api';

export class LabServicesApi extends FetchService {
  // -------------------
  // LABORATORY SERVICES
  // -------------------

  public createLabService = (params: CreateLabServiceParams) => {
    return this.request<
      'LAB_SERVICES',
      ServiceResponse,
      CreateLabServiceParams
    >('/lab_service', 'CREATE_LAB_SERVICE', {
      method: 'POST',
      permit: 'lab_service',
      params,
    });
  };

  public updateLabService = (id: number, params: UpdateLabServiceParams) => {
    return this.request<
      'LAB_SERVICES',
      ServiceResponse,
      UpdateLabServiceParams
    >('/lab_service', 'UPDATE_LAB_SERVICE', {
      method: 'PUT',
      pathParam: id,
      permit: 'lab_service',
      params,
    });
  };

  // -----------------------------
  // LABORATORY SERVICE SETTINGS
  // -----------------------------

  public getServicesSettings = (query?: GetLabServicesSettingsQuery) => {
    return this.request<
      'LAB_SERVICES',
      LabServiceSetting[],
      null,
      GetLabServicesSettingsQuery
    >(
      '/lab_service_setting',
      'GET_LAB_SERVICES_SETTING',
      //  Permit
      {
        query,
      },
    );
  };

  public getServiceSetting = (id: number) => {
    return this.request<'LAB_SERVICES', LabServiceSetting>(
      '/lab_service_setting',
      'GET_LAB_SERVICES_SETTING',
      //  Permit
      { pathParam: id },
    );
  };

  public createServiceSetting = (params: CreateLabServiceSetting) => {
    return this.request<
      'LAB_SERVICES',
      LabServiceSetting,
      CreateLabServiceSetting
    >(
      '/lab_service_setting',
      'CREATE_LAB_SERVICE_SETTING',
      //  Permit
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateServiceSetting = (
    id: number,
    params: UpdateLabServiceSetting,
  ) => {
    return this.request<
      'LAB_SERVICES',
      LabServiceSetting,
      UpdateLabServiceSetting
    >(
      '/lab_service_setting',
      'UPDATE_LAB_SERVICE_SETTING',
      //  Permit
      {
        method: 'PUT',
        pathParam: id,
        params,
      },
    );
  };

  public deleteServiceSetting = (id: number) => {
    return this.request<'LAB_SERVICES'>(
      '/lab_service_setting',
      'DELETE_LAB_SERVICE_SETTING',
      //  Permit
      {
        method: 'DELETE',
        pathParam: id,
      },
    );
  };

  /*
   |----------------------------------------------------------------------------
   | IMPORT
   |----------------------------------------------------------------------------
   */
  public importLabPrimaryServices = (params: FormData) => {
    return this.request<'LAB_SERVICES', ImportLabPrimaryServiceRes, FormData>(
      '/lab_service',
      'IMPORT_LAB_PRIMARY_SERVICES',
      {
        method: 'POST',
        params,
        pathParam: 'import/lab_primary',
        permit: 'services',
      },
    );
  };

  public importLabSecondaryServices = (params: FormData) => {
    return this.request<'LAB_SERVICES', ImportLabSecondaryServiceRes, FormData>(
      '/lab_service',
      'IMPORT_LAB_SECONDARY_SERVICES',
      {
        method: 'POST',
        params,
        pathParam: 'import/lab_secondary',
      },
    );
  };

  public saveImportLabPrimaryServices = (
    params: SaveImportLabPrimaryServices,
  ) => {
    return this.request<
      'LAB_SERVICES',
      SaveImportLabPrimaryServices,
      SaveImportLabPrimaryServices
    >('/lab_service', 'SAVE_IMPORT_LAB_PRIMARY_SERVICES', {
      method: 'POST',
      params,
      pathParam: 'import/lab_primary_save',
    });
  };

  public saveImportLabSecondaryServices = (
    params: SaveImportLabSecondaryServices,
  ) => {
    return this.request<
      'LAB_SERVICES',
      SaveImportLabSecondaryServices,
      SaveImportLabSecondaryServices
    >('/lab_service', 'SAVE_IMPORT_LAB_SECONDARY_SERVICES', {
      method: 'POST',
      params,
      pathParam: 'import/lab_secondary_save',
    });
  };
}
