import { action, autorun, makeObservable, observable } from 'mobx';
import { LStorage } from '@modules/Storage';

export class CurrencyStore {
  private _defaultCurrency = 'UAH';

  @observable public currency =
    LStorage.getItem('CURRENCY') || this._defaultCurrency;

  constructor() {
    makeObservable(this);
    autorun(() => {
      LStorage.sync('CURRENCY', this.currency);
    });
  }

  @action
  public setCurrency = (currency: string): void => {
    this.currency = currency;
  };
}
